import { ThresholdType } from "./ThresholdType";

export default class ThresholdResponseModel {
  constructor(
    public id: number,
    public counterpartyId: number,
    public type: ThresholdType,
    public absoluteValue: number
  ) {}

  static of(model: ThresholdResponseModel): ThresholdResponseModel {
    return new ThresholdResponseModel(
      model.id,
      model.counterpartyId,
      model.type,
      model.absoluteValue
    );
  }

  static ofArray(
    items: Array<ThresholdResponseModel>
  ): Array<ThresholdResponseModel> {
    return items.map((item) => ThresholdResponseModel.of(item));
  }
}
