import * as am5 from "@amcharts/amcharts5";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";

class CustomTheme extends am5.Theme {
  setupDefaultRules() {
    this.rule("Label").setAll({
      fontSize: 12,
    });
  }
}

export abstract class ChartRenderer {
  root: am5.Root;

  constructor(public element: HTMLElement, public isDarkMode: boolean) {
    this.root = am5.Root.new(element);
  }

  init() {
    this.setThemes(this.isDarkMode);
    this.addLegend();
  }

  setThemes(isDarkMode: boolean) {
    this.root.setThemes([
      CustomTheme.new(this.root),
      ...(isDarkMode ? [am5themes_Dark.new(this.root)] : []),
    ]);
  }

  dispose() {
    this.root.dispose();
  }

  abstract addLegend(): void;
}
