









































































































































































import { cloneDeep } from "lodash";
import { Component, Prop, Vue } from "vue-property-decorator";

import { ThemeType } from "@/account-settings/models/AccountSettingType";
import { ReportType } from "@/reports/models";
import { ReportGroupModel, ReportItemModel } from "@/reports/models/ReportMenu";
import ApplicationPicker from "@/shared/components/ApplicationPicker.vue";
import Search from "@/shared/components/Search.vue";
import {
  AppSection,
  Application,
  CurrentUserModel,
  MenuItems,
  MenuModel,
  MenuRoute,
  UserAccess,
} from "@/shared/models";

@Component({
  components: {
    ApplicationPicker,
    Search,
  },
})
export default class ApplicationHeader extends Vue {
  @Prop() reportMenuItems!: Array<ReportGroupModel | ReportItemModel>;
  @Prop() settingMenuItems!: Array<Record<string, any>>;

  readonly MenuItems = MenuItems;
  menuVisible = false;
  userMenuVisible = false;
  archivedAppsIncluded = false;

  get dark(): boolean {
    return this.$vuetify.theme.dark;
  }

  get currentUser(): CurrentUserModel {
    return this.$store.state.userStore.currentUser;
  }

  get currentUserAvatarText(): string {
    const { firstName, lastName } = this.currentUser;

    return firstName && lastName
      ? `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`
      : "";
  }

  get applications(): Array<Application> {
    return this.$store.state.application.apps || [];
  }

  get routeApplicationId(): string | undefined {
    return this.$route.params.id;
  }

  get applicationId(): string {
    return this.$store.state.application.applicationId;
  }

  get defaultApplication(): Application {
    return this.$store.getters.getDefaultApplication;
  }

  get menu(): Array<MenuModel> {
    return this.$store.state.menuStore.menu;
  }

  get hrefForLogout(): string {
    return `${process.env.VUE_APP_API_URL ?? ""}/api/logout`;
  }

  get isCacheReloading(): boolean {
    return this.$store.state.dictionaryStore.isCacheReloading;
  }

  get isNetworksCacheReloading(): boolean {
    return this.$store.state.dictionaryStore.isNetworksCacheReloading;
  }

  get isCacheReloadAvailable(): boolean {
    return !!this.currentUser.viewAccessEntities[this.applicationId]?.includes(
      AppSection.RELOAD_DICTIONARIES_CACHE
    );
  }

  get isNetworkReloadAvailable(): boolean {
    return !!this.currentUser.viewAccessEntities[this.applicationId]?.includes(
      AppSection.RELOAD_NETWORKS_CACHE
    );
  }

  get isEdgeNodeAvailable(): boolean {
    return !!this.currentUser.access[this.applicationId]
      ?.find(({ entity }) => entity === AppSection.EDGE_NODE)
      ?.permissions.includes(UserAccess.RELOAD_CONFIGS);
  }

  get isEdgeNodeStateReseting(): boolean {
    return this.$store.state.application.isEdgeNodeStateReseting;
  }

  get availableEntities(): Array<AppSection | ReportType> {
    return this.currentUser.viewAccessEntities[this.applicationId] ?? [];
  }

  get availableHealthCheckSections(): Array<AppSection | ReportType> {
    return [
      AppSection.HEALTHCHECK_SUMMARY,
      AppSection.HEALTHCHECK_DATA_FLOW,
      AppSection.HEALTHCHECK_PARSING,
      AppSection.HEALTHCHECK_JOBS_QUEUE,
    ].filter((section) => this.availableEntities.includes(section));
  }

  get v3PathForHealthcheck(): string {
    return {
      HEALTHCHECK_SUMMARY: "/v3/healthcheck/summary",
      HEALTHCHECK_DATA_FLOW: `/v3/healthcheck/dataflow/app/${this.applicationId}`,
      HEALTHCHECK_PARSING: `/v3/healthcheck/parsing/app/${this.applicationId}`,
      HEALTHCHECK_JOBS_QUEUE: "/v3/healthcheck/jobs-queue",
    }[
      this.availableHealthCheckSections[0] as
        | AppSection.HEALTHCHECK_SUMMARY
        | AppSection.HEALTHCHECK_DATA_FLOW
        | AppSection.HEALTHCHECK_PARSING
        | AppSection.HEALTHCHECK_JOBS_QUEUE
    ];
  }

  toggleDarkMode() {
    const accountSettingsCloned = cloneDeep(
      this.$store.state.accountSettingsStore.accountSettings
    );

    this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    accountSettingsCloned.theme = this.$vuetify.theme.dark
      ? ThemeType.DARK
      : ThemeType.LIGHT;
    this.$store.dispatch("updateAccountSettings", {
      payload: accountSettingsCloned,
    });
  }

  async reloadDictionariesCache() {
    await this.$store.dispatch(
      "reloadDictionariesCache",
      this.archivedAppsIncluded
    );
    this.userMenuVisible = false;
  }

  async reloadNetworksCache() {
    await this.$store.dispatch("reloadNetworksCache");
    this.userMenuVisible = false;
  }

  getMenuRoute({ name, path }: MenuRoute) {
    if (!name || path) {
      return null;
    }

    return name === MenuItems.HOME && !this.routeApplicationId
      ? {
          name: "initial",
        }
      : {
          name,
          params: { id: this.applicationId },
        };
  }

  async resetEdgeNodeState() {
    await this.$store.dispatch("resetEdgeNodeState");
    this.userMenuVisible = false;
  }
}
