






















































































































import { Component, Prop, Watch, Ref } from "vue-property-decorator";
import { mixins } from "vue-class-component";

import FilterListItem from "../../../reports/components/FilterListItem.vue";
import CustomAutocomplete from "@/shared/components/pickers/CustomAutocomplete.vue";
import PickerDictionaryNotification from "@/shared/components/pickers/PickerDictionaryNotification.vue";
import {
  Dictionary,
  DictionaryType,
  ExcludableMultipleValueFilterModel,
  FilterPreviewId,
  ValidationRule,
} from "@/shared/models";
import { VueAutocomplete } from "@/shared/types/ExtendedVueType";
import AccountSettingsModel from "@/account-settings/models/AccountSettingsModel";
import { FilterBehaviorOption } from "@/account-settings/models/AccountSettingType";
import DictionaryLoadMixin from "@/shared/mixins/DictionaryLoadMixin";

@Component({
  components: {
    CustomAutocomplete,
    FilterListItem,
    PickerDictionaryNotification,
  },
})
export default class GroupedFilter extends mixins(DictionaryLoadMixin) {
  @Prop() value!: ExcludableMultipleValueFilterModel;
  @Prop() label!: string;
  @Prop() dataTypes!: Array<DictionaryType>;
  @Prop({ default: true }) loadData!: boolean;
  @Prop({ default: false }) readonly!: boolean;
  @Prop({ default: false }) autofocus!: boolean;
  @Prop() rules?: Array<ValidationRule>;
  @Prop({ default: () => [] }) errorMessages!: Array<string>;
  @Prop({ default: false }) isAttached!: boolean;
  @Prop() filterPreviewId!: FilterPreviewId;
  @Prop() itemsFilter?: (item: any, currentValue: any) => boolean;

  @Ref("autocomplete") readonly autocomplete!: VueAutocomplete;

  selected: Array<Dictionary> = [];
  localFilter: ExcludableMultipleValueFilterModel =
    ExcludableMultipleValueFilterModel.of(this.value);

  readonly GROUP_ITEMS = 0;
  readonly MAIN_ITEMS = 1;
  dictionaryType = this.dataTypes[this.MAIN_ITEMS];

  get dictionaryData(): Array<Dictionary> {
    return [
      ...this.$store.state.dictionaryStore[
        this.dataTypes[this.GROUP_ITEMS]
      ].values.filter(({ childValues }: Dictionary) => childValues?.length),
      ...(this.$store.state.dictionaryStore[this.dictionaryType]?.values ?? []),
    ];
  }

  get valid(): boolean {
    return !!this.localFilter.values?.length;
  }

  get dark(): boolean {
    return this.$vuetify.theme.dark;
  }

  get groupedItems(): Array<Dictionary> {
    return this.dictionaryData
      .filter((item) => !this.itemsFilter || this.itemsFilter(item, this.value))
      .slice()
      .sort((a: Dictionary, b: Dictionary) => {
        const aSortText = a.text.toLowerCase();
        const bSortText = b.text.toLowerCase();
        const searchText = this.search?.toLowerCase();

        return aSortText.indexOf(searchText) - bSortText.indexOf(searchText);
      });
  }

  get slotName(): string {
    return this.isAttached ? "append-outer" : "prepend";
  }

  get accountSettings(): AccountSettingsModel {
    return this.$store.state.accountSettingsStore.accountSettings;
  }

  get clearSearchValueAfterSelect(): boolean {
    const found = this.accountSettings.specificFiltersBehavior.find(
      ({ value }: any) => value === this.filterPreviewId
    );

    if (found) {
      return found.options.includes(
        FilterBehaviorOption.CLEAR_SEARCH_VALUE_AFTER_SELECT
      );
    }

    return this.accountSettings.defaultFiltersBehavior.includes(
      FilterBehaviorOption.CLEAR_SEARCH_VALUE_AFTER_SELECT
    );
  }

  get closeAfterSelect(): boolean {
    const found = this.accountSettings.specificFiltersBehavior.find(
      ({ value }: any) => value === this.filterPreviewId
    );

    if (found) {
      return found.options.includes(FilterBehaviorOption.CLOSE_AFTER_SELECT);
    }

    return this.accountSettings.defaultFiltersBehavior.includes(
      FilterBehaviorOption.CLOSE_AFTER_SELECT
    );
  }

  @Watch("value", { immediate: true })
  private watchValue() {
    this.localFilter = ExcludableMultipleValueFilterModel.of(this.value);

    if (this.selected.length) {
      this.selected = [];
    }

    const invalidValues: Array<string> = [];

    this.value.values.forEach((value) => {
      const found = this.dictionaryData.find(
        (dictionary) => dictionary.value === value
      );

      if (found) {
        this.handleChoosingItem(found, false);
      } else {
        invalidValues.push(value);
      }
    });

    if (invalidValues.length) {
      this.selected = this.selected.filter(
        ({ value }) => !invalidValues.includes(value)
      );
    }
  }

  @Watch("valid", { immediate: true })
  watchValid(valid: boolean) {
    this.localFilter.valid = valid;
  }

  @Watch("dictionaryData", { immediate: true })
  watchDictionaryDataItems() {
    if (
      !this.localFilter.filled ||
      this.readonly ||
      this.localFilter.values.length
    ) {
      return;
    }

    this.localFilter.values = this.dictionaryData.map((it) => it.value);
  }

  @Watch("selected", { deep: true })
  watchSelected(newDictionaryValues: Array<Dictionary>) {
    const newValues = newDictionaryValues.flatMap(
      ({ childValues, value }: Dictionary) => (childValues ? [] : [value])
    );

    this.setUsedDictionaries(newValues, this.localFilter.values);
    this.localFilter.values = newValues;
  }

  @Watch("localFilter", { deep: true })
  watchLocalFilter(localFilter: ExcludableMultipleValueFilterModel) {
    if (this.readonly) {
      return;
    }

    this.$emit("input", Object.assign(this.value, localFilter));
  }

  created() {
    if (!this.loadData) {
      return;
    }

    const dictionaryTypesToLoad = this.dataTypes.filter(
      (type) => this.$store.state.dictionaryStore[type].unfilled
    );

    if (dictionaryTypesToLoad.length) {
      this.$store
        .dispatch("loadDictionaries", {
          app: this.applicationId,
          dictionaryTypes: dictionaryTypesToLoad,
        })
        .then(() => {
          this.$store.dispatch("loadUsedDictionaries", {
            app: this.applicationId,
            values: {},
          });
          this.watchValue();
        });
    }
  }

  mounted() {
    if (this.value.defaultValues?.length && !this.localFilter.values.length) {
      this.value.defaultValues.forEach((item) => {
        const foundDefaultItem = this.dictionaryData.find(
          (dictionary) => dictionary.value === item
        );

        if (foundDefaultItem) {
          this.handleChoosingItem(foundDefaultItem, false);
        }
      });
    }
  }

  isLastTierOnList(tierItem: Dictionary): boolean {
    const foundIndex = this.groupedItems.findIndex(
      (item: Dictionary) =>
        item.value === tierItem.value && item.name === tierItem.name
    );

    if (foundIndex >= 0) {
      return (
        this.groupedItems.filter((item: Dictionary) => item.childValues)
          .length -
          1 ===
        foundIndex
      );
    }

    return false;
  }

  isSelected(item: Dictionary): boolean {
    return this.selected.some(({ value }: Dictionary) => value === item.value);
  }

  clearSearch() {
    if (this.clearSearchValueAfterSelect) {
      this.search = "";
    }
  }

  handleChoosingItem(value: Dictionary, isFocus = true) {
    const found = this.selected.find(
      (selectedItem: Dictionary) => selectedItem.value === value.value
    );

    if (!found) {
      this.selected.push(value);

      if (this.selected.length) {
        if (value.childValues) {
          const filtered = this.groupedItems.filter(
            (groupedItem: Dictionary) =>
              value.childValues
                ?.map(({ value }) => value)
                .includes(groupedItem.value) && !this.isSelected(groupedItem)
          );
          const groupedItemsWithTheSameChildValues = this.groupedItems.filter(
            (groupedItem: Dictionary) =>
              groupedItem.childValues &&
              !this.isSelected(groupedItem) &&
              groupedItem.childValues.every((childValue) =>
                value.childValues
                  ?.map(({ value }) => value)
                  .includes(childValue.value)
              )
          );

          this.selected.push(
            ...filtered,
            ...groupedItemsWithTheSameChildValues
          );
        } else {
          const foundParent: Dictionary | undefined = this.groupedItems
            .filter((item: Dictionary) => item.childValues)
            .find((item: Dictionary) =>
              item.childValues?.includes(value.value)
            );

          if (foundParent) {
            const countSelectedByParentId = this.groupedItems.filter(
              (item: Dictionary) =>
                this.isSelected(item) &&
                !item.childValues &&
                foundParent.childValues
                  ?.map(({ value }) => value)
                  .includes(item.value)
            ).length;

            if (foundParent.childValues?.length === countSelectedByParentId) {
              const foundParentsWithTheSameChildValues =
                this.groupedItems.filter(
                  (item: Dictionary) =>
                    item.name !== foundParent.name &&
                    item.value !== foundParent.name &&
                    item.childValues
                      ?.map(({ value }) => value)
                      .includes(value.value)
                );

              this.selected.push(
                foundParent,
                ...foundParentsWithTheSameChildValues
              );
            }
          }
        }
      } else {
        if (value.childValues) {
          const filtered = this.groupedItems.filter((item: Dictionary) =>
            value.childValues?.map(({ value }) => value).includes(item.value)
          );
          this.selected.push(...filtered);
        }
      }
    } else {
      this.handleRemoveItem(value);
    }

    this.clearSearch();

    if (isFocus) {
      this.autocomplete.focus();
    }
  }

  handleClickItem(value: Dictionary) {
    this.handleChoosingItem(value);

    if (this.closeAfterSelect) {
      this.autocomplete.blur();
    }
  }

  handleRemoveItem(value: Dictionary) {
    this.selected = this.selected.filter(
      (selectedItem: Dictionary) => selectedItem.value !== value.value
    );

    if (value.childValues) {
      const filtered: Array<Dictionary> = this.groupedItems.filter(
        (item: Dictionary) =>
          value.childValues?.map(({ value }) => value).includes(item.value)
      );
      const groupedItemsWithTheSameChildValues = this.groupedItems.filter(
        (item: Dictionary) =>
          this.isSelected(item) &&
          item.childValues?.every((childValue) =>
            value.childValues
              ?.map(({ value }) => value)
              .includes(childValue.value)
          )
      );

      [...groupedItemsWithTheSameChildValues, ...filtered].forEach(
        (filteredItem: Dictionary) => {
          this.selected = this.selected.filter(
            (selectedItem: Dictionary) =>
              selectedItem.value !== filteredItem.value
          );
        }
      );
    } else {
      const foundParent: Dictionary | undefined = this.groupedItems
        .filter((item: Dictionary) => item.childValues)
        .find((item: Dictionary) =>
          item.childValues?.map(({ value }) => value).includes(value.value)
        );

      if (foundParent) {
        const foundParentsWithTheSameChildValues = this.groupedItems.filter(
          (item: Dictionary) =>
            item.name !== foundParent.name &&
            item.value !== foundParent.name &&
            item.childValues?.map(({ value }) => value).includes(value.value)
        );

        this.selected = this.selected.filter(
          (selectedItem: Dictionary) =>
            selectedItem.value !== foundParent.value &&
            foundParentsWithTheSameChildValues.every(
              (item) => item.value !== selectedItem.value
            )
        );
      }
    }
  }
}
