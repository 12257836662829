import { uniq } from "lodash";

import {
  AccessType,
  FilterModel,
  FilterId,
  FilterPreview,
  FilterPreviewId,
  recordToFilterModel,
  EventParamFilterRangeModel,
} from "@/shared/models";
import { UserAccess } from "@/shared/models/UserModel";
import KeyUtil from "@/shared/utils/KeyUtil";
import DateUtil from "@/shared/utils/DateUtil";

export const FUNNEL_STEP_NAME_MAX_LENGTH = 8;

export enum FunnelType {
  USER_CONVERSION = "USER_CONVERSION",
  REPEATED_CONVERSION = "REPEATED_CONVERSION",
}

export enum FunnelStatus {
  CREATED = "CREATED",
  REQUESTED_FOR_RECALCULATION = "REQUESTED_FOR_RECALCULATION",
  RECALCULATION = "RECALCULATION",
  ACTIVE = "ACTIVE",
  FAILED = "FAILED",
  DELETE = "DELETE",
  MODIFIED = "MODIFIED",
}

export enum FunnelStepPeriodType {
  SECOND = "SECOND",
  MINUTE = "MINUTE",
  HOUR = "HOUR",
  DAY = "DAY",
}

export class FunnelStepModel {
  key: string;
  number: number;
  name?: string;
  filter: Array<FilterModel>;
  excludeFilter: Array<FilterModel>;
  id?: number;
  description?: string;
  periodType?: FunnelStepPeriodType;
  periodValue?: number;

  constructor(
    number: number,
    periodType?: FunnelStepPeriodType,
    periodValue?: number,
    name?: string,
    filter: Array<FilterModel> = [],
    excludeFilter: Array<FilterModel> = [],
    id?: number,
    description?: string
  ) {
    this.key = KeyUtil.generateKey();
    this.number = number;
    this.periodType = periodType;
    this.periodValue = periodValue;
    this.name = name;
    this.filter = filter;
    this.excludeFilter = excludeFilter;
    this.id = id;
    this.description = description;
  }

  static of(model: FunnelStepModel): FunnelStepModel {
    return new FunnelStepModel(
      model.number,
      model.periodType,
      model.periodValue,
      model.name,
      model.filter,
      model.excludeFilter,
      model.id,
      model.description
    );
  }

  static ofSource(model: FunnelStepModel): FunnelStepModel {
    return new FunnelStepModel(
      model.number,
      model.periodType,
      model.periodValue,
      model.name,
      model.filter.map((it) => {
        if (it.id === FilterId.EVENT) {
          return EventParamFilterRangeModel.of({
            ...it,
            valid: true,
            required: true,
          } as EventParamFilterRangeModel);
        }

        return recordToFilterModel({
          ...it,
          required: it.id === FilterId.ACTIVITY_KIND,
          filled: false,
        }) as FilterModel;
      }),
      model.excludeFilter.map((it) => {
        if (it.id === FilterId.EVENT) {
          return EventParamFilterRangeModel.of({
            ...it,
            valid: true,
            required: true,
          } as EventParamFilterRangeModel);
        }

        return recordToFilterModel({
          ...it,
          required: it.id === FilterId.ACTIVITY_KIND,
          filled: false,
        }) as FilterModel;
      }),
      undefined,
      model.description
    );
  }
}

export default class FunnelModel {
  constructor(
    public applicationId: string,
    public filter: Array<FilterModel> = [],
    public type: FunnelType = FunnelType.USER_CONVERSION,
    public accessType: AccessType = AccessType.PRIVATE,
    public eventsFrom: string = DateUtil.daysAgoFromCurrent(30),
    public eventsTo: string = DateUtil.daysAfterCurrent(30),
    public breakdowns: Array<FilterId> = [],
    public steps: Array<FunnelStepModel> = [],
    public name?: string,
    public description?: string,
    public id?: number,
    public ownerId?: string,
    public ownerName?: string,
    public status?: FunnelStatus,
    public lastSuccessfulRecalculationAt?: string,
    public createdAt?: string,
    public updatedAt?: string,
    public features: Array<UserAccess> = []
  ) {}

  get editable(): boolean {
    return this.features.includes(UserAccess.EDIT);
  }

  get deletable(): boolean {
    return this.features.includes(UserAccess.DELETE);
  }

  get previews(): Array<FilterPreview> {
    return [
      {
        id: FilterPreviewId.EVENTS_DATE,
        value: this.getDateString(this.eventsFrom, this.eventsTo),
      },
      ...(this.breakdowns.length
        ? [
            {
              id: FilterPreviewId.ADDITIONAL_GROUPING,
              value: this.breakdowns,
            },
          ]
        : []),
      ...this.filter.reduce(
        (result: Array<FilterPreview>, filter: FilterModel) => {
          if (Array.isArray(filter.preview)) {
            result.push(...filter.preview);
          } else if (filter.preview) {
            result.push(filter.preview);
          }

          return result;
        },
        []
      ),
    ];
  }

  static ofArray(items: Array<FunnelModel>) {
    return items
      .map(
        (item) =>
          new FunnelModel(
            item.applicationId,
            item.filter,
            item.type,
            item.accessType,
            item.eventsFrom,
            item.eventsTo,
            item.breakdowns,
            item.steps,
            item.name,
            item.description,
            item.id,
            item.ownerId,
            item.ownerName,
            item.status === FunnelStatus.MODIFIED
              ? FunnelStatus.CREATED
              : item.status,
            item.lastSuccessfulRecalculationAt,
            item.createdAt,
            item.updatedAt,
            item.features
          )
      )
      .sort(
        (a: FunnelModel, b: FunnelModel) =>
          new Date(b.updatedAt as string).getTime() -
          new Date(a.updatedAt as string).getTime()
      );
  }

  static of(model: FunnelModel, requiredFilter?: Array<FilterId>): FunnelModel {
    return new FunnelModel(
      model.applicationId,
      model.filter?.length > 0
        ? model.filter.map(
            (it: any) =>
              recordToFilterModel({
                ...it,
                required: requiredFilter && requiredFilter.includes(it.id),
              }) as FilterModel
          )
        : [],
      model.type,
      model.accessType,
      model.eventsFrom,
      model.eventsTo,
      [...model.breakdowns],
      model.steps.map((it) => FunnelStepModel.ofSource(it)),
      model.name,
      model.description,
      model.id,
      model.ownerId,
      model.ownerName,
      model.status,
      model.lastSuccessfulRecalculationAt,
      model.createdAt,
      model.updatedAt,
      model.features
    );
  }

  static ofSource(
    model: FunnelModel,
    requiredFilter?: Array<FilterId>
  ): FunnelModel {
    return new FunnelModel(
      model.applicationId,
      model.filter.map(
        (it: any) =>
          recordToFilterModel({
            ...it,
            required: requiredFilter && requiredFilter.includes(it.id),
          }) as FilterModel
      ),
      model.type,
      model.accessType,
      model.eventsFrom,
      model.eventsTo,
      [...model.breakdowns],
      model.steps.map((it) => FunnelStepModel.ofSource(it)),
      `${model.name} copy`,
      model.description,
      model.id,
      model.ownerId,
      model.ownerName,
      model.status,
      model.lastSuccessfulRecalculationAt,
      model.createdAt,
      model.updatedAt,
      model.features
    );
  }

  private getDateString(dateA: string, dateB: string): string {
    return dateA === dateB ? dateA : `${dateA} — ${dateB}`;
  }

  getUsedDictionaryValues(): Record<string, Array<string>> {
    const usedDictionaryValues: Record<string, Array<string>> = {};
    const getFilterValues = (filter: FilterModel) => {
      if (filter.id === FilterId.TRACKER || !filter.getUsedDictionaryValues) {
        return;
      }

      filter.getUsedDictionaryValues().forEach((values, dictionary) => {
        usedDictionaryValues[dictionary] = uniq(
          (usedDictionaryValues[dictionary] || []).concat(values)
        );
      });
    };

    this.filter.forEach(getFilterValues);
    this.steps.forEach((step) => {
      step.filter.forEach(getFilterValues);
    });

    return usedDictionaryValues;
  }

  getUsedTrackerDictionaryValues(): Record<string, Array<string>> {
    const usedTrackerDictionaryValues: Record<string, Array<string>> = {};
    const trackerFilter = this.filter.find(
      ({ id }: FilterModel) => id === FilterId.TRACKER
    );

    if (trackerFilter) {
      trackerFilter.getUsedDictionaryValues().forEach((values, dictionary) => {
        usedTrackerDictionaryValues[dictionary] = uniq(
          (usedTrackerDictionaryValues[dictionary] || []).concat(values)
        );
      });
    }

    return usedTrackerDictionaryValues;
  }
}
