import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { AxiosResponse } from "axios";
import { cloneDeep } from "lodash";

import axios from "@/shared/plugins/axios";
import ConfigurationModel from "@/application-manager/models/ConfigurationModel";
import ConfigurationResponseModel from "@/application-manager/models/ConfigurationResponseModel";

@Module
export default class ConfigurationStore extends VuexModule {
  isLoading = false;
  isSavingInProgress = false;
  configurations: Array<ConfigurationResponseModel> = [];
  configuration: Array<ConfigurationModel> = [];
  configurationValidate: Array<string> = [];

  @Mutation
  setIsLoading(payload: boolean) {
    this.isLoading = payload;
  }

  @Mutation
  setIsSavingInProgress(payload: boolean) {
    this.isSavingInProgress = payload;
  }

  @Mutation
  setConfigurations(payload: Array<ConfigurationResponseModel>) {
    this.configurations = ConfigurationResponseModel.ofArray(payload);
  }

  @Mutation
  setConfiguration(payload: Record<string, string>) {
    this.configuration = ConfigurationModel.ofArray(payload);
  }

  @Mutation
  setConfigurationValidate(payload: Array<string>) {
    this.configurationValidate = cloneDeep(payload);
  }

  @Action({ commit: "setConfigurations" })
  async loadConfigurations() {
    this.context.commit("setIsLoading", true);

    return axios
      .get(`/api/infra/configurations`)
      .then((result: AxiosResponse<Array<ConfigurationResponseModel>>) => {
        return result.data;
      })
      .finally(() => {
        this.context.commit("setIsLoading", false);
      });
  }

  @Action({ commit: "setConfiguration" })
  async loadConfiguration({
    applicationId,
    profileId,
  }: {
    applicationId: number;
    profileId: number;
  }) {
    this.context.commit("setIsLoading", true);

    return axios
      .get(`/api/infra/applications/${applicationId}/profiles/${profileId}`)
      .then((result: AxiosResponse<Record<string, string>>) => {
        return result.data;
      })
      .finally(() => {
        this.context.commit("setIsLoading", false);
      });
  }

  @Action({ commit: "setConfigurationValidate" })
  async loadConfigurationValidate({
    applicationId,
    profileId,
  }: {
    applicationId: number;
    profileId: number;
  }) {
    return axios
      .get(
        `/api/infra/applications/${applicationId}/profiles/${profileId}/validate`
      )
      .then((result: AxiosResponse<Array<string>>) => {
        return result.data;
      });
  }

  @Action
  async updateConfiguration({
    payload,
    applicationId,
    profileId,
  }: {
    payload: Record<string, string>;
    applicationId: number;
    profileId: number;
  }) {
    this.context.commit("setIsSavingInProgress", true);

    return axios
      .put(
        `/api/infra/applications/${applicationId}/profiles/${profileId}`,
        payload
      )
      .then((result: AxiosResponse<any>) => {
        return result.data;
      })
      .finally(() => {
        this.context.commit("setIsSavingInProgress", false);
      });
  }

  @Action
  async deleteConfigurationItem({
    payload,
    applicationId,
    profileId,
  }: {
    payload: Array<string>;
    applicationId: number;
    profileId: number;
  }) {
    return axios
      .delete(
        `/api/infra/applications/${applicationId}/profiles/${profileId}`,
        { data: payload, withCredentials: false }
      )
      .then(() => {
        // this.context.dispatch("loadJurisdictions");
      });
  }
}
