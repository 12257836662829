import { ChartName } from "@/chart/models/ChartModel";
import {
  AdType,
  Application,
  DatesFilterModel,
  DefaultValues,
  FilterId,
  FilterModel,
  FilterPreview,
  FilterPreviewId,
  recordToFilterModel,
} from "@/shared/models";
import { CohortFilter } from "./CohortReport";
import { AggregationPeriod, GroupByFilter } from "./GroupByFilter";
import { PerMinReportType } from "./PerMinReportType";
import {
  AdTypeReportFilterExtension,
  PerminReportTypeReportFilterExtension,
  ProvidedBannerReportFilterExtension,
  RoundSessionsReportFilterExtension,
} from "./ReportFilterExtension";
import { ReportType } from "./ReportType";

export class CohortPerMinFilter
  extends CohortFilter
  implements
    AdTypeReportFilterExtension,
    PerminReportTypeReportFilterExtension,
    ProvidedBannerReportFilterExtension,
    RoundSessionsReportFilterExtension
{
  adType: AdType;
  reportType: PerMinReportType;
  providedBanner: string;
  roundSessions: boolean;
  roundSessionsToSeconds: number;

  constructor(
    app: Application,
    filter?: Array<FilterModel>,
    date?: DatesFilterModel,
    groupByFilter = new GroupByFilter(AggregationPeriod.DAY, [], false),
    dayLimit?: number,
    roundSessions = false,
    roundSessionsToSeconds: number = DefaultValues.ROUND_SESSIONS_TO,
    adType = AdType.BANNER,
    reportType = PerMinReportType.TIME_SPENT,
    providedBanner = DefaultValues.PROVIDED_BANNER
  ) {
    super(
      ReportType.COHORT_PER_MIN,
      app,
      filter,
      date,
      groupByFilter,
      dayLimit
    );
    this.roundSessions = roundSessions;
    this.roundSessionsToSeconds = roundSessionsToSeconds;
    this.adType = adType;
    this.reportType = reportType;
    this.providedBanner = providedBanner;
  }

  get previews(): Array<FilterPreview> {
    return [
      ...super.previews,
      {
        id: FilterPreviewId.AD_TYPE,
        value: this.adType,
      },
      ...(this.isBannerAdType &&
      this.providedBanner !== DefaultValues.PROVIDED_BANNER
        ? [
            {
              id: FilterPreviewId.PROVIDED_BANNER,
              value: this.providedBanner,
            },
          ]
        : []),
      {
        id: FilterPreviewId.REPORT_TYPE_COHORT_PERMIN,
        value: this.reportType,
      },
      ...(this.roundSessions
        ? [
            {
              id: FilterPreviewId.ROUND_SESSIONS_TO_SECONDS,
              value: this.roundSessionsToSeconds,
            },
          ]
        : []),
    ];
  }

  get isBannerProvidedDisabled(): boolean {
    return this.adType !== AdType.BANNER;
  }

  get charts(): Array<ChartName> {
    return this.groupByFilter.isNotEmptyGroupBy
      ? this.getGroupedCohortChartNames("COHORT_PERMIN_REPORT", [1, 7, 14])
      : [ChartName.COHORT_PERMIN_REPORT_CHART];
  }

  get groupedCharts(): Array<ChartName> {
    return this.groupByFilter.isNotEmptyGroupBy
      ? [ChartName.COHORT_PERMIN_REPORT_CHART]
      : [];
  }

  static of(
    app: Application,
    requestQuery?: Record<string, any>
  ): CohortPerMinFilter {
    return new CohortPerMinFilter(
      app,
      requestQuery?.filter?.map((it: any) => recordToFilterModel(it)) || [],
      DatesFilterModel.ofRecord(
        FilterId.ATTRIBUTION_DATE_VALUE,
        requestQuery?.date
      ),
      new GroupByFilter(
        AggregationPeriod.DAY,
        requestQuery?.groupByFilter?.groupBy,
        false
      ),
      !!requestQuery && !!requestQuery.dayLimit
        ? Number.parseInt(requestQuery.dayLimit as string)
        : undefined,
      !!requestQuery && !!requestQuery.roundSessions,
      !!requestQuery && !!requestQuery.roundSessionsToSeconds
        ? Number.parseInt(requestQuery.roundSessionsToSeconds as string)
        : undefined,
      requestQuery?.adType as AdType,
      Object.values(PerMinReportType).find(
        (value: string) => value === requestQuery?.reportType
      ) ?? PerMinReportType.TIME_SPENT,
      requestQuery?.providedBanner
    );
  }

  get isBannerAdType(): boolean {
    return this.adType === AdType.BANNER;
  }

  get invalid(): boolean {
    return !(
      this.app &&
      this.date.valid &&
      this.roundSessionsToSeconds &&
      this.roundSessionsToSeconds > 0
    );
  }

  toRequestQuery(): Record<string, any> {
    const result = super.toRequestQuery();

    result["adType"] = this.adType;
    result["reportType"] = this.reportType;

    if (
      this.isBannerAdType &&
      this.providedBanner !== DefaultValues.PROVIDED_BANNER
    ) {
      result["providedBanner"] = this.providedBanner;
    }

    if (this.roundSessions) {
      result["roundSessions"] = this.roundSessions;
      result["roundSessionsToSeconds"] = this.roundSessionsToSeconds;
    }

    return result;
  }
}
