export default {
  errorPage: {
    "404": {
      title: "Page not found...",
      text: "Sorry, we couldn't find the page you are looking for.",
    },
    "403": {
      title: "Access denied",
      text: `The page you're trying to access has restricted access. <br />
      If you fell this is a mistake, contact your admin.`,
    },
    "500": {
      title: "Whoops!",
      text: `Our servers seem to be taking a break. <br />
      We are doing our best to fix it, so check back soon!`,
    },
    backToHome: "Back to home",
  },
};
