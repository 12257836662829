





































import { Component, Vue } from "vue-property-decorator";

import { NotificationModel } from "@/shared/models";

@Component
export default class NotificationSnackbars extends Vue {
  readonly DEFAULT_TIMEOUT = 5000;

  get notifications(): Record<string, any> {
    return this.$store.state.notificationStore.notifications;
  }

  get notificationCount(): number {
    return this.notifications.length;
  }

  getMessage(notification: NotificationModel) {
    if (typeof notification.message === "string") {
      return notification.message;
    }
    return this.$lang(
      notification.message.key,
      ...(notification.message.params ?? [])
    );
  }

  deleteNotification() {
    this.$store.commit("deleteNotification");
  }
}
