import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { AxiosResponse } from "axios";

import axios from "@/shared/plugins/axios";
import ExecutionChainElementResponseModel from "@/application-manager/models/ExecutionChainElementResponseModel";
import ExecutionRequestModel from "@/application-manager/models/ExecutionRequestModel";
import BatchExecutionChainResponseModel from "@/application-manager/models/BatchExecutionChainResponseModel";
import AppNameValidationResponseModel from "@/application-manager/models/AppNameValidationResponseModel";
import {
  ExecutionParameterModel,
  ExecutionParameterResponseModel,
} from "@/application-manager/models/ExecutionParameterResponseModel";

@Module
export default class ExecutionStore extends VuexModule {
  isLoading = false;
  isSavingInProgress = false;
  executions: Array<BatchExecutionChainResponseModel> = [];
  batchExecutionChain: BatchExecutionChainResponseModel | null = null;
  errorMessageForExecutionStep = "";
  applicationNameValidation = new AppNameValidationResponseModel();
  executionParameters: Array<ExecutionParameterResponseModel> = [];

  @Mutation
  setIsLoading(payload: boolean) {
    this.isLoading = payload;
  }

  @Mutation
  setIsSavingInProgress(payload: boolean) {
    this.isSavingInProgress = payload;
  }

  @Mutation
  setErrorMessageForExecutionStep(payload: string) {
    this.errorMessageForExecutionStep = payload;
  }

  @Mutation
  setExecutions(payload: Array<BatchExecutionChainResponseModel>) {
    this.executions = BatchExecutionChainResponseModel.ofArray(payload);
  }

  @Mutation
  setBatchExecutionChain(payload: BatchExecutionChainResponseModel) {
    this.batchExecutionChain =
      BatchExecutionChainResponseModel.ofSource(payload);
  }

  @Mutation
  setApplicationNameValidation(payload: AppNameValidationResponseModel) {
    this.applicationNameValidation =
      AppNameValidationResponseModel.ofSource(payload);
  }

  @Mutation
  setExecutionParameters(payload: Array<ExecutionParameterResponseModel>) {
    this.executionParameters = ExecutionParameterModel.ofArray(payload);
  }

  @Action({ commit: "setExecutions" })
  async loadExecutions() {
    this.context.commit("setIsLoading", true);

    return axios
      .get(`/api/infra/batches/executions/list`)
      .then(
        (result: AxiosResponse<Array<BatchExecutionChainResponseModel>>) => {
          return result.data;
        }
      )
      .finally(() => {
        this.context.commit("setIsLoading", false);
      });
  }

  @Action({ commit: "setBatchExecutionChain" })
  async loadBatchExecution(batchExecutionId: number) {
    this.context.commit("setIsLoading", true);

    return axios
      .get(`/api/infra/batches/executions/${batchExecutionId}`)
      .then((result: AxiosResponse<BatchExecutionChainResponseModel>) => {
        return result.data;
      })
      .finally(() => {
        this.context.commit("setIsLoading", false);
      });
  }

  @Action({ commit: "setBatchExecutionChain" })
  async createExecutionByBatchId({
    payload,
    batchId,
  }: {
    payload: ExecutionRequestModel;
    batchId: number;
  }) {
    this.context.commit("setIsSavingInProgress", true);

    return axios
      .post(`/api/infra/batches/${batchId}`, payload)
      .then((result: AxiosResponse<BatchExecutionChainResponseModel>) => {
        return result.data;
      })
      .finally(() => {
        this.context.commit("setIsSavingInProgress", false);
      });
  }

  @Action
  async createExecutionByExecutionId({
    payload,
    profileId,
    executionId,
    step,
  }: {
    payload: any;
    profileId: number;
    executionId: number;
    step: number;
  }) {
    this.context.commit("setIsSavingInProgress", true);

    return axios
      .post(
        `/api/infra/executions/${executionId}/steps/${step}?profileId=${profileId}`,
        payload,
        {
          skipErrors: true,
        } as any
      )
      .then((result: AxiosResponse<ExecutionChainElementResponseModel>) => {
        return result.data;
      })
      .catch((error) => {
        this.context.commit(
          "setErrorMessageForExecutionStep",
          error.response.data.msg
        );
        return Promise.reject(error);
      })
      .finally(() => {
        this.context.commit("setIsSavingInProgress", false);
      });
  }

  @Action({ commit: "setApplicationNameValidation" })
  async checkApplicationNameValidation({
    application,
    platform,
  }: {
    application: string;
    platform: string;
  }) {
    if (!application) {
      return true;
    }

    const url = platform
      ? `/api/infra/validations/application/${application}/platform/${platform}`
      : `/api/infra/validations/application/${application}`;

    return axios
      .get(url)
      .then((result: AxiosResponse<AppNameValidationResponseModel>) => {
        return result.data;
      });
  }

  @Action({ commit: "setExecutionParameters" })
  async checkExecutionParameters(executionId: number) {
    return axios
      .get(`/api/infra/executions/${executionId}/parameters/diff`)
      .then((result: AxiosResponse<ExecutionParameterResponseModel>) => {
        return result.data;
      });
  }
}
