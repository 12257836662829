import { Module, VuexModule, Mutation } from "vuex-module-decorators";

import ValidationModel from "@/shared/models/ValidationModel";

@Module
export default class ValidationStore extends VuexModule {
  validations: Array<ValidationModel> = [];

  @Mutation
  setValidations(payload: Array<ValidationModel>) {
    this.validations = ValidationModel.ofArray(payload);
  }

  @Mutation
  clearValidationByField(field: string) {
    this.validations = this.validations.filter((item) => item.field !== field);
  }

  @Mutation
  clearValidations() {
    this.validations = [];
  }
}
