import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import axios from "@/shared/plugins/axios";
import ExportEventsRequestModel from "@/tools/models/export-events/ExportEventsRequestModel";
import { AxiosResponse } from "axios";

@Module
export default class ExportEventsStore extends VuexModule {
  isExportingInProgress = false;

  @Mutation
  setIsExportingInProgress(payload: boolean) {
    this.isExportingInProgress = payload;
  }

  @Action
  async exportEventsFile({
    payload,
    appId,
  }: {
    payload: ExportEventsRequestModel;
    appId: string;
  }) {
    this.context.commit("setIsExportingInProgress", true);
    const params = ExportEventsRequestModel.ofRequest(payload);

    return axios
      .get(`/api/app/${appId}/export/appevents`, {
        params,
        responseType: "blob",
      })
      .then((result: AxiosResponse<any>) => {
        const href = URL.createObjectURL(result.data);
        const link = document.createElement("a");

        link.href = href;
        link.setAttribute("download", `app_events.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .finally(() => {
        this.context.commit("setIsExportingInProgress", false);
      });
  }
}
