import AlertResponseModel, {
  AlertBorder,
  AlertDisplayTime,
  AlertFeature,
  AlertType,
  DetailsModel,
} from "./AlertResponseModel";

export default class AlertRequestModel {
  constructor(
    public id?: number,
    public text = "",
    public displayTime = AlertDisplayTime.CONSTANT,
    public displayDates: Array<string> = [],
    public feature = AlertFeature.REPORTS,
    public subFeatures: Array<string> = [],
    public isDisplayableOnChart = false,
    public enabled = true,
    public details: DetailsModel = new DetailsModel(
      AlertType.WARNING,
      AlertBorder.LEFT
    ),
    public title?: string,
    public applications: Array<string> = []
  ) {}

  static ofSource(
    model: AlertRequestModel | AlertResponseModel
  ): AlertRequestModel {
    return new AlertRequestModel(
      model.id,
      model.text,
      model.displayTime,
      model.displayDates,
      model.feature,
      [...model.subFeatures],
      model.isDisplayableOnChart,
      model.enabled,
      model.details,
      model.title,
      model.applications
    );
  }

  static ofRequest(model: AlertRequestModel): AlertRequestModel {
    return new AlertRequestModel(
      model.id,
      model.text,
      model.displayTime,
      model.displayDates,
      model.feature,
      [...model.subFeatures],
      model.isDisplayableOnChart,
      model.enabled,
      model.details,
      model.title,
      model.applications
    );
  }
}
