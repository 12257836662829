import { CohortFilter, CohortHeaders } from "./CohortReport";
import { GroupByFilter } from "./GroupByFilter";
import { ReportType } from "./ReportType";
import {
  DatesFilterModel,
  FilterId,
  recordToFilterModel,
  FilterModel,
  Application,
  FilterPreviewId,
} from "@/shared/models";
import { LangService } from "@/shared/types/LangType";
import { GroupByType, ReportHeader, ReportHeaderUtil } from "./ReportHeader";
import { ReportFilter, ReportResultItem } from "./Report";
import { TimeSpentReportType } from "./TimeSpentReportType";
import { ReportDataType } from "./ReportVisualization";
import {
  NetRevenueReportFilterExtension,
  TimeSpentTypeReportFilterExtension,
} from "./ReportFilterExtension";
import { ChartName } from "@/chart/models/ChartModel";
import { ReportItemRowObject } from "./ReportItemRowObject";

const DAYS = [3, 7, 30];

export class MetricsSpendHeaders {
  static init(
    lang: LangService,
    report: MetricsSpendFilter,
    reportDataType: ReportDataType,
    groupBy: GroupByType
  ): Array<ReportHeader> {
    return [
      ...(![ReportDataType.TOTAL, ReportDataType.SUB_TOTAL].includes(
        reportDataType
      )
        ? [
            ReportHeaderUtil.createDate(
              lang,
              report.groupByFilter.aggregationPeriod
            ),
          ]
        : []),
      ...(report.groupByFilter.isNotEmptyGroupBy &&
      reportDataType !== ReportDataType.TOTAL
        ? ReportHeaderUtil.createGroupBy(lang, report.groupByFilter, 200)
        : []),
      {
        text: lang("components.baseTable.spend"),
        align: "end",
        value: ReportResultItem.PREFIX + "spend",
        width: 90,
      },
      {
        text: lang("components.baseTable.netwInstalls"),
        align: "end",
        value: ReportResultItem.PREFIX + "netwInstalls",
        fractionDigits: 0,
        width: 90,
      },
      {
        text: lang("components.baseTable.cpiNetw"),
        align: "end",
        value: ReportResultItem.PREFIX + "cpiNetw",
        width: 90,
        hasGradient: true,
        reverseGradient: true,
        fractionDigits: CohortHeaders.PRECISION,
      },
      {
        text: lang("components.baseTable.adjustInstalls"),
        align: "end",
        value: ReportResultItem.PREFIX + "adjustInstalls",
        fractionDigits: 0,
        width: 90,
      },
      {
        text: lang("components.baseTable.ecpiAdjust"),
        align: "end",
        value: ReportResultItem.PREFIX + "ecpiAdjust",
        width: 90,
        hasGradient: true,
        reverseGradient: true,
        fractionDigits: CohortHeaders.PRECISION,
      },
      ...this.generateMetricsSpendHeaders(
        ["returnRate", "timeSpent", "engagement", "value"],
        lang,
        groupBy
      ),
    ];
  }

  static generateMetricsSpendHeaders(
    names: Array<string>,
    lang: LangService,
    groupBy: GroupByType
  ): Array<ReportHeader> {
    const result: Array<ReportHeader> = [];

    if (groupBy === GroupByType.METRICS) {
      names.forEach((name) => {
        result.push(
          ...DAYS.map(
            (day) =>
              ({
                text: lang(`components.baseTable.${name}`, day),
                align: "end",
                value: `${ReportResultItem.PREFIX}${name}_${day}`,
                hasGradient: true,
                groupBy: {
                  border: {
                    left: day === DAYS[0],
                    right:
                      name === names[names.length - 1] &&
                      day === DAYS[DAYS.length - 1],
                  },
                  hasBackground: names
                    .filter((_, index) => index % 2 === 0)
                    .includes(name),
                },
                fractionDigits: CohortHeaders.PRECISION,
                class: "text-no-wrap",
              } as ReportHeader)
          )
        );
      });
    } else {
      DAYS.forEach((day) => {
        result.push(
          ...names.map(
            (name) =>
              ({
                text: lang(`components.baseTable.${name}`, day),
                align: "end",
                value: `${ReportResultItem.PREFIX}${name}_${day}`,
                hasGradient: true,
                groupBy: {
                  border: {
                    left: name === names[0],
                    right:
                      name === names[names.length - 1] &&
                      day === DAYS[DAYS.length - 1],
                  },
                  hasBackground: DAYS.filter(
                    (_, index) => index % 2 === 0
                  ).includes(day),
                },
                fractionDigits: CohortHeaders.PRECISION,
                class: "text-no-wrap",
              } as ReportHeader)
          )
        );
      });
    }

    return result;
  }
}

export class MetricsSpendFilter
  extends CohortFilter
  implements
    TimeSpentTypeReportFilterExtension,
    NetRevenueReportFilterExtension
{
  constructor(
    app: Application,
    filter?: Array<FilterModel>,
    date?: DatesFilterModel,
    groupByFilter?: GroupByFilter,
    public timeSpentType = TimeSpentReportType.TIME_SPENT,
    public netRevenue = true
  ) {
    super(ReportType.METRICS_SPEND, app, filter, date, groupByFilter, 31);
  }

  get previews() {
    return [
      ...super.previews,
      {
        id: FilterPreviewId.TIME_SPENT_TYPE,
        value: this.timeSpentType,
      },
      {
        id: FilterPreviewId.NET_REVENUE,
        value: this.netRevenue,
      },
    ];
  }

  get charts(): Array<ChartName> {
    const days = [3, 7, 30];

    return this.groupByFilter.isNotEmptyGroupBy
      ? [
          ...this.getGroupedCohortChartNames("METRICS_SPEND_RETURN_RATE", days),
          ...this.getGroupedCohortChartNames("METRICS_SPEND_TIME_SPENT", days),
          ...this.getGroupedCohortChartNames("METRICS_SPEND_ENGAGEMENT", days),
          ...this.getGroupedCohortChartNames("METRICS_SPEND_VALUE", days),
        ]
      : [
          ChartName.METRICS_SPEND_RETURN_RATE_CHART,
          ChartName.METRICS_SPEND_TIME_SPENT_CHART,
          ChartName.METRICS_SPEND_ENGAGEMENT_CHART,
          ChartName.METRICS_SPEND_VALUE_CHART,
        ];
  }

  get groupedCharts(): Array<ChartName> {
    return this.groupByFilter.isNotEmptyGroupBy
      ? [
          ChartName.METRICS_SPEND_RETURN_RATE_CHART,
          ChartName.METRICS_SPEND_TIME_SPENT_CHART,
          ChartName.METRICS_SPEND_ENGAGEMENT_CHART,
          ChartName.METRICS_SPEND_VALUE_CHART,
        ]
      : [];
  }

  static of(
    app: Application,
    filter?: Record<string, any>
  ): MetricsSpendFilter {
    return filter
      ? new MetricsSpendFilter(
          app,
          filter.filter && filter.filter.length > 0
            ? filter.filter.map((it: any) => recordToFilterModel(it))
            : [],
          filter.date
            ? DatesFilterModel.ofRecord(
                FilterId.ATTRIBUTION_DATE_VALUE,
                filter.date
              )
            : undefined,
          filter.groupByFilter
            ? GroupByFilter.of(filter.groupByFilter)
            : undefined,
          filter.timeSpentType
          // TODO: NET_REVENUE need to uncomment later
          // typeof filter.netRevenue === "string"
          //   ? filter.netRevenue === "true"
          //   : filter.netRevenue,
        )
      : new MetricsSpendFilter(app);
  }

  toRequestQuery(): Record<string, any> {
    return {
      ...super.toRequestQuery(),
      timeSpentType: this.timeSpentType,
      // TODO: NET_REVENUE need to uncomment later
      // netRevenue: this.netRevenue,
    };
  }
}

export class MetricsSpendResultItem extends ReportResultItem {
  constructor(row: ReportItemRowObject, filter: ReportFilter) {
    super();

    const { groupByFilter } = filter;

    if (row.getByHeader("installDate") || row.getByHeader("date")) {
      this.date = row.getByHeader("installDate") || row.getByHeader("date");
      this.setFormattedDate(
        this.date,
        filter.date.from,
        filter.date.to,
        groupByFilter.aggregationPeriod
      );
    }

    this.setGroupByValue(groupByFilter, row);
    this.data["spend"] = row.getByHeader("spend");
    this.data["netwInstalls"] = row.getByHeader("netwInstalls");
    this.data["cpiNetw"] = super.parseFloat(
      row.getByHeader("cpiNetw"),
      CohortHeaders.PRECISION
    );
    this.data["adjustInstalls"] = row.getByHeader("adjustInstalls") ?? 0;
    this.data["ecpiAdjust"] = super.parseFloat(
      row.getByHeader("ecpiAdjust"),
      CohortHeaders.PRECISION
    );
    this.data = {
      ...this.data,
      ...this.generateResultItemData("timeSpent", row),
      ...this.generateResultItemData("returnRate", row),
      ...this.generateResultItemData("engagement", row),
      ...this.generateResultItemData("value", row),
    };
  }

  private generateResultItemData(
    name: string,
    row: ReportItemRowObject
  ): Record<string, number> {
    return DAYS.reduce(
      (result, number) => ({
        ...result,
        [`${name}_${number}`]: super.parseFloat(
          row.getByHeader(`${name}_${number}`),
          CohortHeaders.PRECISION
        ),
      }),
      {}
    );
  }
}
