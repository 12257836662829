import _, { uniq } from "lodash";
import { DataFlowFullMetricsByApp } from "@/healthcheck/model/DataFlowFullResponse";
import { DataFlowFullTable } from "@/healthcheck/model/DataFlowFullTable";
import { ParsingFullMetricsByApp } from "@/healthcheck/model/ParsingFullResponse";
import {
  ParsingFullTablePerDate,
  ParsingMetricPerDate,
} from "@/healthcheck/model/ParsingFullTablePerDate";
import { ParsingFullTable } from "@/healthcheck/model/ParsingFullTable";
import {
  ParsingFullTablePerMetric,
  ParsingMatchRow,
} from "@/healthcheck/model/ParsingFullTablePerMetric";
import { JobInfo } from "@/healthcheck/model/JobInfo";
import { JobQueueInfoResponse } from "@/healthcheck/model/JobQueueInfoResponse";
import { QueueInfo } from "@/healthcheck/model/QueueInfo";

export default class HealthCheckSummaryUtils {
  static convertParsingFullMetricsByAppToTablePerMetric(
    parsingFullMetricsByApp: ParsingFullMetricsByApp
  ): Array<ParsingFullTablePerMetric> {
    return parsingFullMetricsByApp.metrics.map((it) => {
      const matchRows = it.matchRows.map((it) => {
        return new ParsingMatchRow(
          it.date,
          this.convertValue(it.adjustValue),
          this.convertValue(it.bigQueryValue),
          this.convertValue(it.deltaValue),
          this.convertValue(it.deltaPercent, "%")
        );
      });
      return new ParsingFullTablePerMetric(it.metric, matchRows);
    });
  }

  static convertParsingFullMetricsByAppToTablePerDayData(
    parsingFullMetricsByApp: ParsingFullMetricsByApp
  ): Array<ParsingFullTablePerDate> {
    const parsingFullTables = this.convertParsingFullMetricsByAppFullMetrics(
      parsingFullMetricsByApp
    );
    if (parsingFullMetricsByApp.metrics.length === 0) {
      return new Array<ParsingFullTablePerDate>();
    }
    const dates = uniq(parsingFullTables.map((it) => it.date)).sort(
      (a: string, b: string) => b.localeCompare(a)
    );
    const parsingFullTablePerDateArray: Array<ParsingFullTablePerDate> =
      new Array<ParsingFullTablePerDate>();
    dates.forEach((date) => {
      const parsingMetricsPerDate = parsingFullTables
        .filter((it) => it.date === date)
        .map((it) => {
          return new ParsingMetricPerDate(
            it.metric,
            it.adjustValue,
            it.bigQueryValue,
            it.deltaValue,
            it.deltaPercent
          );
        });
      const parsingFullTablePerDate = new ParsingFullTablePerDate(
        date,
        parsingMetricsPerDate
      );
      parsingFullTablePerDateArray.push(parsingFullTablePerDate);
    });
    return parsingFullTablePerDateArray;
  }

  static convertParsingFullMetricsByAppFullMetrics(
    parsingFullMetricsByApp: ParsingFullMetricsByApp
  ): Array<ParsingFullTable> {
    const parsingFullTables: Array<ParsingFullTable> =
      new Array<ParsingFullTable>();
    if (parsingFullMetricsByApp.metrics.length === 0) {
      return parsingFullTables;
    }
    parsingFullMetricsByApp.metrics.forEach((metric) => {
      metric.matchRows.forEach((matchRow) => {
        const parsingFullTable = new ParsingFullTable(
          metric.metric.toLocaleLowerCase(),
          matchRow.date,
          this.convertValue(matchRow.adjustValue),
          this.convertValue(matchRow.bigQueryValue),
          this.convertValue(matchRow.deltaValue, "-"),
          this.convertValue(matchRow.deltaPercent, "100", "%")
        );
        parsingFullTables.push(parsingFullTable);
      });
    });
    return parsingFullTables.reverse();
  }

  static convertValue(
    value: number,
    defaultValue = "0",
    additional = ""
  ): string {
    return value === -1
      ? defaultValue + additional
      : value.toString() + additional;
  }

  static convertDataFlowFullMetricsByAppToTableData(
    dataFlowFullMetricsByApp: DataFlowFullMetricsByApp
  ): Array<DataFlowFullTable> {
    const dataFlowFullTableDataList: Array<DataFlowFullTable> =
      new Array<DataFlowFullTable>();
    dataFlowFullMetricsByApp.dataFlowMetrics.forEach((dfMetric) => {
      dfMetric.metrics.forEach((metric) => {
        if (metric.value.length > 0) {
          metric.value.forEach((val) => {
            const data = new DataFlowFullTable(
              dfMetric.dataFlow,
              metric.metric,
              val.name,
              val.lastExecutedJob,
              val.updatedAt
            );
            dataFlowFullTableDataList.push(data);
          });
        }
      });
    });
    return dataFlowFullTableDataList;
  }

  static convertJobsQueueInfoToJobsInfo(
    jobsQueInfo: Array<JobQueueInfoResponse>
  ): Array<JobInfo> {
    const dash = "-";
    const failedValue = -1;
    const jobsInfo: Array<JobInfo> = [];
    const jobsQueueInfoByApp = _.groupBy(jobsQueInfo, (it) => it.application);

    for (const app in jobsQueueInfoByApp) {
      const jobsQueueInfoByJobName = _.groupBy(
        jobsQueueInfoByApp[app],
        (it) => it.jobName
      );
      for (const job in jobsQueueInfoByJobName) {
        const list = jobsQueueInfoByJobName[job];
        const processingAvgTimeValue = list[0].processingAverageTimeSeconds;
        const totalAverageTimeValue = list[0].totalAverageTimeSeconds;
        const processingAvgTimeString =
          processingAvgTimeValue === failedValue
            ? dash
            : processingAvgTimeValue.toFixed(2);
        const totalAvgTimeString =
          totalAverageTimeValue === failedValue
            ? dash
            : totalAverageTimeValue.toFixed(2);
        const diffAvgTimeString =
          processingAvgTimeValue === failedValue ||
          totalAverageTimeValue === failedValue
            ? dash
            : Math.abs(totalAverageTimeValue - processingAvgTimeValue).toFixed(
                2
              );
        const jobCount = list
          .map((it) => it.jobCount)
          .reduce((partialSum, a) => partialSum + a, 0);
        const jobInfo = new JobInfo(
          app,
          job,
          jobCount,
          processingAvgTimeString,
          totalAvgTimeString,
          diffAvgTimeString
        );
        jobsInfo.push(jobInfo);
      }
    }
    return jobsInfo;
  }

  static convertJobsQueueInfoToQueuesInfo(
    jobsQueInfo: Array<JobQueueInfoResponse>
  ): Array<QueueInfo> {
    const queuesInfo: Array<QueueInfo> = [];
    const jobsQueueInfoByQueue = _.groupBy(jobsQueInfo, (it) => it.queueName);
    for (const queue in jobsQueueInfoByQueue) {
      const jobsQueueInfoByJobName = _.groupBy(
        jobsQueueInfoByQueue[queue],
        (it) => it.jobName
      );
      for (const job in jobsQueueInfoByJobName) {
        const count = jobsQueInfo
          .filter((it) => it.queueName === queue && it.jobName === job)
          .reduce((sum, current) => sum + current.jobCount, 0);
        const queueInfo = new QueueInfo(queue, job, count);
        queuesInfo.push(queueInfo);
      }
    }
    return queuesInfo;
  }
}
