import { FilterModel, FilterPreview } from "@/shared/models";

export default class DashboardFilterModel {
  constructor(public filters: Array<FilterModel> = []) {}

  get previews(): Array<FilterPreview> {
    return this.filters.reduce(
      (result: Array<FilterPreview>, filter: FilterModel) => {
        if (Array.isArray(filter.preview)) {
          result.push(...filter.preview);
        } else if (filter.preview) {
          result.push(filter.preview);
        }

        return result;
      },
      []
    );
  }
}
