import { AxiosResponse } from "axios";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { PermissionExportRequest } from "@/iam/models/PermissionExportModel";
import {
  PermissionAggregatorModel,
  PermissionAggregatorNames,
  PermissionEntity,
  PermissionEntityNames,
  PermissionFeatures,
  PermissionLink,
  PermissionRequest,
  PermissionResponse,
  UserPermissionsByApplicationModel,
} from "@/iam/models/PermissionModel";
import { PermissionUtil } from "@/iam/utils/PermissionUtil";
import {
  UserAccess,
  UserModel,
  UserResponseModel,
} from "@/shared/models/UserModel";
import axios from "@/shared/plugins/axios";

@Module
export default class UsersStore extends VuexModule {
  users: Array<UserModel> = [];
  loadingUsers = true;

  user: UserResponseModel | null = null;
  isUserLoading = false;
  isUserSaving = false;
  isUserDeleting = false;

  isPermissionAdding = false;
  isPermissionDeleting = false;

  permissionEntityNames: Array<PermissionEntityNames> = [];
  isEntityNamesLoading = false;

  aggregatorNames: Array<PermissionAggregatorNames> = [];
  isAggregatorsNamesLoading = false;

  permissionFeatures: Array<PermissionFeatures> = [];
  isFeaturesLoading = false;

  aggregators: Array<PermissionAggregatorModel> = [];
  isAggregatorsLoading = false;
  isAggregatorSaving = false;
  isAggregatorDeleting = false;

  isPermissionsCopying = false;

  permissions: Array<UserPermissionsByApplicationModel> = [];
  isPermissionsLoading = false;

  get countSuperAdmins(): number {
    return this.users.filter(({ isSuperUser }) => isSuperUser).length;
  }

  get getUsernames(): Array<string> {
    return this.users.map(({ username }) => username.toLowerCase());
  }

  get featuresByName(): Record<string, Array<UserAccess>> {
    return this.permissionFeatures.reduce(
      (result: Record<string, Array<UserAccess>>, { ngacName, features }) => {
        result[ngacName] = features;

        return result;
      },
      {}
    );
  }

  @Mutation
  setUsers(payload: Array<UserModel>) {
    this.users = UserModel.ofArray(payload);
  }

  @Mutation
  updateUsers(payload: UserModel) {
    const userIndex = this.users.findIndex(
      ({ username }) => username === payload.username
    );

    if (userIndex === -1) {
      this.users = [...this.users, UserModel.of(payload)];
    } else {
      this.users.splice(userIndex, 1, UserModel.of(payload));
    }
  }

  @Mutation
  setExtendedUser(payload?: UserResponseModel) {
    this.user = payload ? UserResponseModel.of(payload) : null;
  }

  @Mutation
  updateExtendedUser(payload: UserResponseModel) {
    this.user = UserResponseModel.of(
      this.user ? Object.assign(this.user, payload) : payload
    );
  }

  @Mutation
  deleteUserByUsername(payload: string) {
    const index = this.users.findIndex(({ username }) => username === payload);

    if (index === -1) {
      return;
    }

    this.users.splice(index, 1);
  }

  @Mutation
  setIsUserDeleting(payload: boolean) {
    this.isUserDeleting = payload;
  }

  @Mutation
  setLoadingUsers(payload: boolean) {
    this.loadingUsers = payload;
  }

  @Mutation
  setIsUserSaving(payload: boolean) {
    this.isUserSaving = payload;
  }

  @Mutation
  setSelectedUserLoading(payload: boolean) {
    this.isUserLoading = payload;
  }

  @Mutation
  setPermission(payload: Array<PermissionResponse>) {
    this.user?.permissions.push(...payload);
  }

  @Mutation
  deletePermission(payload: string) {
    if (!this.user) {
      return;
    }

    this.user.permissions = this.user.permissions.filter(
      ({ id }) => id !== payload
    );
  }

  @Mutation
  setIsPermissionDeleting(payload: boolean) {
    this.isPermissionDeleting = payload;
  }

  @Mutation
  setPermissionAdding(payload: boolean) {
    this.isPermissionAdding = payload;
  }

  @Mutation
  setEntityNamesLoading(payload: boolean) {
    this.isEntityNamesLoading = payload;
  }

  @Mutation
  setEntityNames(payload: Array<PermissionEntityNames>) {
    this.permissionEntityNames = payload;
  }

  @Mutation
  setFeaturesLoading(payload: boolean) {
    this.isFeaturesLoading = payload;
  }

  @Mutation
  setPermissionFeatures(payload: Array<PermissionFeatures>) {
    this.permissionFeatures = this.permissionFeatures.concat(payload);
  }

  @Mutation
  resetPermissionFeatures() {
    this.permissionFeatures = [];
  }

  @Mutation
  setAggregatorsNamesLoading(payload: boolean) {
    this.isAggregatorsNamesLoading = payload;
  }

  @Mutation
  setPermissionAggregatorNames(payload: Array<PermissionAggregatorNames>) {
    this.aggregatorNames = payload;
  }

  @Mutation
  setAggregators(payload: Array<PermissionAggregatorModel>) {
    this.aggregators = PermissionAggregatorModel.ofArray(payload);
  }

  @Mutation
  setIsAggregatorsLoading(payload: boolean) {
    this.isAggregatorsLoading = payload;
  }

  @Mutation
  addAggregator(payload: PermissionAggregatorModel) {
    this.aggregators.push(PermissionAggregatorModel.of(payload));
    this.aggregatorNames
      .find(
        ({ ngacEntityName }) =>
          ngacEntityName === PermissionUtil.getAggregatorByEntity(payload.type)
      )
      ?.aggregators.push({ name: payload.name, type: payload.aggregatorType });
  }

  @Mutation
  updateAggregators(payload: PermissionAggregatorModel) {
    const indexOfUpdatedAggregator = this.aggregators.findIndex(
      ({ name }) => name === payload.name
    );

    this.aggregators.splice(
      indexOfUpdatedAggregator,
      1,
      PermissionAggregatorModel.of(payload)
    );
  }

  @Mutation
  filterAggregators(aggregatorName: string) {
    const indexOfDeletedAggregator = this.aggregators.findIndex(
      ({ name }) => name === aggregatorName
    );
    const aggregatorEntity = PermissionUtil.getAggregatorByEntity(
      this.aggregators[indexOfDeletedAggregator].type
    );

    this.aggregatorNames = this.aggregatorNames.map(
      ({ aggregators, ngacEntityName }) => {
        if (ngacEntityName !== aggregatorEntity) {
          return { aggregators, ngacEntityName };
        }

        return {
          aggregators: aggregators.filter(
            ({ name }) =>
              name !== this.aggregators[indexOfDeletedAggregator].name
          ),
          ngacEntityName,
        };
      }
    );
    this.aggregators.splice(indexOfDeletedAggregator);
  }

  @Mutation
  setIsAggregatorSaving(payload: boolean) {
    this.isAggregatorSaving = payload;
  }

  @Mutation
  setIsAggregatorDeleting(payload: boolean) {
    this.isAggregatorDeleting = payload;
  }

  @Mutation
  setIsPermissionsCopying(payload: boolean) {
    this.isPermissionsCopying = payload;
  }

  @Mutation
  setIsPermissionsLoading(payload: boolean) {
    this.isPermissionsLoading = payload;
  }

  @Mutation
  setPermissions(payload: Array<UserPermissionsByApplicationModel>) {
    this.permissions = UserPermissionsByApplicationModel.ofArray(payload);
  }

  @Action({ commit: "setUsers" })
  async fetchUsers() {
    this.context.commit("setLoadingUsers", true);

    return axios
      .get("/api/ngac/sadmin/users")
      .then((result: AxiosResponse<Array<UserModel>>) => result.data)
      .finally(() => {
        this.context.commit("setLoadingUsers", false);
      });
  }

  @Action({ commit: "updateUsers" })
  async createUser(payload: UserModel) {
    this.context.commit("setIsUserSaving", true);

    return axios
      .post(`/api/ngac/sadmin/users/${payload.username}`, payload)
      .then(({ data }: AxiosResponse<UserModel>) => data)
      .finally(() => {
        this.context.commit("setIsUserSaving", false);
      });
  }

  @Action({ commit: "setExtendedUser" })
  async fetchUser(username: string) {
    this.context.commit("setSelectedUserLoading", true);

    return axios
      .get(`/api/ngac/sadmin/users/${username}`)
      .then(({ data }: AxiosResponse<UserResponseModel>) => data)
      .finally(() => {
        this.context.commit("setSelectedUserLoading", false);
      });
  }

  @Action({ commit: "deletePermission" })
  async deleteUserPermission(permissionId: number) {
    this.context.commit("setIsPermissionDeleting", true);

    return axios
      .delete(`/api/ngac/sadmin/permissions/${permissionId}`)
      .then(() => permissionId)
      .finally(() => {
        this.context.commit("setIsPermissionDeleting", false);
      });
  }

  @Action
  async updateUser(payload: UserResponseModel) {
    this.context.commit("setIsUserSaving", true);

    return axios
      .patch(`/api/ngac/sadmin/users/${payload.username}`, payload)
      .then(({ data }: AxiosResponse<UserResponseModel>) => {
        this.context.commit("updateExtendedUser", data);
      })
      .finally(() => {
        this.context.commit("setIsUserSaving", false);
      });
  }

  @Action({ commit: "deleteUserByUsername" })
  async deleteUser(payload: string) {
    this.context.commit("setIsUserDeleting", true);

    return axios
      .delete(`/api/ngac/sadmin/users/${payload}`)
      .then(() => payload)
      .finally(() => {
        this.context.commit("setIsUserDeleting", false);
      });
  }

  @Action({ commit: "setPermission" })
  async addUserPermission(payload: Array<PermissionRequest>) {
    this.context.commit("setPermissionAdding", true);

    return axios
      .post("/api/ngac/sadmin/permissions", payload)
      .then(({ data }: AxiosResponse<Array<PermissionResponse>>) => data)
      .finally(() => {
        this.context.commit("setPermissionAdding", false);
      });
  }

  @Action({ commit: "setEntityNames" })
  async getEntityNames(entities: Array<PermissionEntity>) {
    this.context.commit("setEntityNamesLoading", true);

    return axios
      .get("/api/ngac/sadmin/entities/unique_values", {
        params: {
          ngac_entity_names: entities.join(","),
        },
      })
      .then(({ data }: AxiosResponse<Array<PermissionEntityNames>>) => data)
      .finally(() => {
        this.context.commit("setEntityNamesLoading", false);
      });
  }

  @Action({ commit: "setPermissionAggregatorNames" })
  async getAggregatorsNames(entities: Array<PermissionEntity>) {
    this.context.commit("setAggregatorsNamesLoading", true);

    return axios
      .get("/api/ngac/sadmin/entities/aggregators", {
        params: {
          ngac_entity_names: entities.join(","),
        },
      })
      .then(({ data }: AxiosResponse<Array<any>>) => data)
      .finally(() => {
        this.context.commit("setAggregatorsNamesLoading", false);
      });
  }

  @Action
  async getPermissionFeatures(entities: Array<PermissionEntity>) {
    const entityNamesMap: Record<string, Array<string>> = entities.reduce(
      (result: Record<string, Array<string>>, entity) => {
        const names =
          this.permissionEntityNames.find(
            ({ ngacEntityName }) => ngacEntityName === entity
          )?.ngacNames ||
          this.aggregatorNames
            .find(({ ngacEntityName }) => ngacEntityName === entity)
            ?.aggregators.map(({ name }) => name);

        return names ? Object.assign(result, { [entity]: names }) : result;
      },
      {}
    );

    if (!Object.keys(entityNamesMap).length) {
      return;
    }

    this.context.commit("setFeaturesLoading", true);

    await Promise.all(
      Object.entries(entityNamesMap).map(([entity, names]) =>
        axios
          .get("/api/ngac/sadmin/entities/features", {
            params: {
              ngac_entity_name: entity,
              ngac_names: names.join(","),
            },
          })
          .then(({ data }: AxiosResponse<Array<PermissionFeatures>>) => {
            this.context.commit("setPermissionFeatures", data);
          })
      )
    ).finally(() => {
      this.context.commit("setFeaturesLoading", false);
    });
  }

  @Action({ commit: "setAggregators" })
  async fetchAggregators(entity: PermissionEntity) {
    this.context.commit("setIsAggregatorsLoading", true);

    return axios
      .get(`/api/ngac/sadmin/aggregator/${entity}`)
      .then(({ data }: AxiosResponse<Array<PermissionAggregatorModel>>) => data)
      .finally(() => {
        this.context.commit("setIsAggregatorsLoading", false);
      });
  }

  @Action({ commit: "addAggregator" })
  async createAggregator(aggregator: PermissionAggregatorModel) {
    this.context.commit("setIsAggregatorSaving", true);

    return axios
      .post("/api/ngac/sadmin/aggregator", aggregator)
      .then(({ data }: AxiosResponse<PermissionAggregatorModel>) => data)
      .finally(() => {
        this.context.commit("setIsAggregatorSaving", false);
      });
  }

  @Action({ commit: "updateAggregators" })
  async updateAggregator(aggregator: PermissionAggregatorModel) {
    this.context.commit("setIsAggregatorSaving", true);

    return axios
      .patch(
        `/api/ngac/sadmin/aggregator/${aggregator.type}/${aggregator.name}`,
        aggregator
      )
      .then(({ data }: AxiosResponse<PermissionAggregatorModel>) => data)
      .finally(() => {
        this.context.commit("setIsAggregatorSaving", false);
      });
  }

  @Action({ commit: "filterAggregators" })
  async deleteAggregator({ type, name }: PermissionAggregatorModel) {
    this.context.commit("setIsAggregatorDeleting", true);

    return axios
      .delete(`/api/ngac/sadmin/aggregator/${type}/${name}`)
      .then(() => name)
      .finally(() => {
        this.context.commit("setIsAggregatorDeleting", false);
      });
  }

  @Action
  async exportPermissions(payload: PermissionExportRequest) {
    this.context.commit("setIsPermissionsCopying", true);

    return axios
      .post("/api/ngac/sadmin/permissions/copy", payload)
      .then(() => {
        this.context.commit("addNotification", {
          message: {
            key: "users.permissionsExport.exportSuccess",
          },
        });
      })
      .finally(() => {
        this.context.commit("setIsPermissionsCopying", false);
      });
  }

  @Action({ commit: "setPermissions" })
  async fetchPermissions({ ngacName, ngacEntityName }: PermissionLink) {
    this.context.commit("setIsPermissionsLoading", true);
    this.context.commit("setPermissions", []);

    return axios
      .get(`/api/ngac/sadmin/permissions/${ngacName}/${ngacEntityName}`)
      .then(({ data }) => data)
      .finally(() => {
        this.context.commit("setIsPermissionsLoading", false);
      });
  }
}
