import { ParamType } from "./RequestSpecialParamResponseModel";

export default class AccountParamResponseModel {
  constructor(
    public id: number,
    public accountId: number,
    public paramType: ParamType,
    public key: string,
    public value: string
  ) {}

  static of(model: AccountParamResponseModel) {
    return new AccountParamResponseModel(
      model.id,
      model.accountId,
      model.paramType,
      model.key,
      model.value
    );
  }

  static ofArray(items: Array<AccountParamResponseModel>) {
    return items.map((item) => AccountParamResponseModel.of(item));
  }
}
