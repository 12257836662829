export default {
  login: {
    title: "The BI",
    documentTitle: "Login",
    username: "Login",
    password: "Password",
    btn: "Login",
    btnGoogle: "Sign in with Google",
    btnSignInWithPassword: "Sign in with password",
  },
};
