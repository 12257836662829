import { AdType } from "@/shared/models";

export default class PlacementResponseModel {
  constructor(
    public id: number,
    public placementId: string,
    public networkIntegrationApplicationId: number,
    public networkName: string,
    public adType: AdType,
    public adUnit: string,
    public spotId: string,
    public deviceType: string,
    public lat: boolean | null,
    public waterfallId: string,
    public aliasName: string | null
  ) {}

  static ofSource(model: PlacementResponseModel): PlacementResponseModel {
    return new PlacementResponseModel(
      model.id,
      model.placementId,
      model.networkIntegrationApplicationId,
      model.networkName,
      model.adType,
      model.adUnit,
      model.spotId,
      model.deviceType,
      model.lat,
      model.waterfallId,
      model.aliasName
    );
  }

  static ofArray(placements: Array<PlacementResponseModel>) {
    return placements.map((placement) =>
      PlacementResponseModel.ofSource(placement)
    );
  }
}

export class PlacementIdUniqueReponseModel {
  constructor(public placementId: string, public unique: boolean) {}
}
