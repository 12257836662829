import {
  PermissionEntity,
  PermissionResponse,
  PermissionResponseModel,
} from "@/iam/models/PermissionModel";
import { UserAccess } from "@/shared/models";

const PERMISSION_AGGREGATOR_BY_ENTITY_MAP = new Map([
  [PermissionEntity.APPLICATION, PermissionEntity.APPLICATIONS],
  [PermissionEntity.APP_SECTION, PermissionEntity.APP_SECTIONS],
  [PermissionEntity.AD_NETWORK, PermissionEntity.AD_NETWORKS],
  [PermissionEntity.GENERAL, PermissionEntity.GENERAL],
]);

export class PermissionUtil {
  static getAggregatorByEntity(
    entity: PermissionEntity
  ): PermissionEntity | undefined {
    return PERMISSION_AGGREGATOR_BY_ENTITY_MAP.get(entity);
  }

  static responseToModel({ id, link }: PermissionResponse, username: string) {
    const application = link.find(({ ngacEntityName }) =>
      [PermissionEntity.APPLICATION, PermissionEntity.APPLICATIONS].includes(
        ngacEntityName
      )
    )?.ngacName as string;
    const feature: UserAccess = link.find(
      ({ ngacEntityName }) => ngacEntityName === PermissionEntity.FEATURE
    )?.ngacName as UserAccess;
    const { ngacEntityName, ngacName, isAggregator } = link[link.length - 1];

    return new PermissionResponseModel(
      id,
      username,
      application,
      feature,
      ngacEntityName,
      ngacName,
      isAggregator
    );
  }
}
