
























import { Component, Prop, Vue } from "vue-property-decorator";

import MenuUtil from "@/shared/utils/MenuUtil";
import { MULTI_APP } from "@/shared/models";

@Component
export default class HealthCheckMenu extends Vue {
  @Prop() applicationId!: string;

  get healthCheckMenuItems(): Array<Record<string, string>> {
    return MenuUtil.getHealthcheckMenuItems(
      this.$store.state.userStore.currentUser.viewAccessEntities[
        this.applicationId
      ],
      this.applicationId === MULTI_APP
    );
  }
}
