import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosResponse } from "axios";

import {
  ReportTemplateModel,
  NewReportTemplateModel,
  TemplateModel,
  FunnelTemplateModel,
  NewFunnelTemplateModel,
} from "@/templates/models/TemplateModel";
import { TemplateUniqueNameResponseInterface } from "@/templates/models/TemplateModel";
import axios from "@/shared/plugins/axios";
import UsedDashboardModel from "@/templates/models/UsedDashboardModel";
import { MULTI_APP, NotificationType } from "@/shared/models";

@Module
export default class TemplateStore extends VuexModule {
  isReportTemplatesLoading = false;
  isFunnelTemplatesLoading = false;
  isUsedDashboardsLoading = false;
  reportTemplates: Array<ReportTemplateModel> = [];
  funnelTemplates: Array<FunnelTemplateModel> = [];
  usedDashboards: UsedDashboardModel | null = null;
  isTemplateNameUnique = true;
  isTemplateSaving = false;
  isTemplateDeleting = false;

  @Mutation
  setReportTemplates(payload: Array<ReportTemplateModel>) {
    this.reportTemplates = payload.map((it) => ReportTemplateModel.of(it));
  }

  @Mutation
  setFunnelTemplates(payload: Array<FunnelTemplateModel>) {
    this.funnelTemplates = payload.map((it) => FunnelTemplateModel.of(it));
  }

  @Mutation
  setReportTemplatesLoading(payload: boolean) {
    this.isReportTemplatesLoading = payload;
  }

  @Mutation
  setFunnelTemplatesLoading(payload: boolean) {
    this.isFunnelTemplatesLoading = payload;
  }

  @Mutation
  setUsedDashboardsLoading(payload: boolean) {
    this.isUsedDashboardsLoading = payload;
  }

  @Mutation
  setTemplateNameUniqueness(payload: boolean) {
    this.isTemplateNameUnique = payload;
  }

  @Mutation
  setTemplateSaving(payload: boolean) {
    this.isTemplateSaving = payload;
  }

  @Mutation
  setTemplateDeleting(payload: boolean) {
    this.isTemplateDeleting = payload;
  }

  @Mutation
  setUsedDashboards(payload: UsedDashboardModel) {
    this.usedDashboards = UsedDashboardModel.of(payload);
  }

  @Mutation
  clearAllReportTemplates() {
    this.reportTemplates = [];
  }

  @Mutation
  clearAllFunnelTemplates() {
    this.funnelTemplates = [];
  }

  @Action
  async createReportTemplate(payload: NewReportTemplateModel) {
    const { applicationId } = this.context.rootState.application;

    this.context.commit("setTemplateSaving", true);

    return await axios
      .post(
        `/api/apps/${payload.reportFilter.getApp}/reports/${payload.reportFilter.reportId}/templates`,
        payload
      )
      .then((result: AxiosResponse<ReportTemplateModel>) => {
        this.context.commit("addNotification", {
          message: {
            key: "template.notification.createdSuccess",
            params: [payload.name],
          },
          action: {
            path: `/app/${applicationId}/template`,
            key: "template.notification.route",
          },
        });

        return result.data;
      })
      .finally(() => {
        this.context.commit("setTemplateSaving", false);
      });
  }

  @Action
  async createFunnelTemplate(payload: NewFunnelTemplateModel) {
    const { applicationId } = this.context.rootState.application;

    this.context.commit("setTemplateSaving", true);

    return await axios
      .post(`/api/apps/${applicationId}/templates/funnel`, payload)
      .then((result: AxiosResponse<FunnelTemplateModel>) => {
        this.context.commit("addNotification", {
          message: {
            key: "template.notification.createdSuccess",
            params: [payload.name],
          },
          action: {
            path: `/app/${applicationId}/template/funnel`,
            key: "template.notification.route",
          },
        });

        return result.data;
      })
      .finally(() => {
        this.context.commit("setTemplateSaving", false);
      });
  }

  @Action({ commit: "setReportTemplates" })
  async fetchReportTemplates({
    applicationId,
    showSingleAppOnly,
  }: {
    applicationId?: string;
    showSingleAppOnly?: boolean;
  }) {
    this.context.commit("clearAllReportTemplates", true);
    this.context.commit("setReportTemplatesLoading", true);

    return await axios
      .get(
        `/api/apps/${
          applicationId || this.context.getters.applicationIds
        }/templates/report`,
        {
          params: {
            showSingleAppOnly,
          },
        }
      )
      .then((result: AxiosResponse<Array<ReportTemplateModel>>) => result.data)
      .finally(() => {
        this.context.commit("setReportTemplatesLoading", false);
      });
  }

  @Action({ commit: "setReportTemplates" })
  async fetchCurrentReportTemplates() {
    const { routeName, applicationId } = this.context.rootState.application;

    this.context.commit("clearAllReportTemplates", true);
    this.context.commit("setReportTemplatesLoading", true);

    return axios
      .get(
        `/api/apps/${
          applicationId === MULTI_APP
            ? this.context.getters.applicationIds
            : applicationId
        }/reports/${routeName}/templates`
      )
      .then((result: AxiosResponse<Array<ReportTemplateModel>>) => result.data)
      .finally(() => {
        this.context.commit("setReportTemplatesLoading", false);
      });
  }

  @Action({ commit: "setFunnelTemplates" })
  async fetchFunnelTemplates({
    applicationId,
    showSingleAppOnly,
  }: {
    applicationId?: string;
    showSingleAppOnly?: boolean;
  }) {
    this.context.commit("clearAllFunnelTemplates", true);
    this.context.commit("setFunnelTemplatesLoading", true);

    return await axios
      .get(
        `/api/apps/${
          applicationId || this.context.getters.applicationIds
        }/templates/funnel`,
        {
          params: {
            showSingleAppOnly,
          },
        }
      )
      .then((result: AxiosResponse<Array<FunnelTemplateModel>>) => result.data)
      .finally(() => {
        this.context.commit("setFunnelTemplatesLoading", false);
      });
  }

  @Action({ rawError: true })
  async deleteTemplate(payload: TemplateModel) {
    const { applicationId } = this.context.rootState.application;

    this.context.commit("setTemplateDeleting", true);

    return axios
      .delete(`/api/templates/${payload.id}`, { skipErrors: true } as any)
      .then((result: AxiosResponse<Array<TemplateModel>>) => {
        this.context.commit("addNotification", {
          message: {
            key: "template.notification.deletedSuccess",
            params: [payload.name],
          },
        });

        return result.data;
      })
      .catch((error) => {
        this.context.commit("addNotification", {
          message: error.response.data.msg,
          type: NotificationType.ERROR,
          action: {
            path: `/app/${applicationId}/template/${payload.id}/dashboards?templateName=${payload.name}`,
            key: "template.notification.routeToDependentDashboardsList",
            target: "_blank",
          },
        });

        return Promise.reject(error);
      })
      .finally(() => {
        this.context.commit("setTemplateDeleting", false);
      });
  }

  // TODO: change payload
  @Action({ commit: "setTemplateNameUniqueness" })
  async checkTemplateNameUniqueness(name: string) {
    if (!name) {
      return true;
    }

    return axios
      .post(`/api/templates/uniqueName`, { name })
      .then(
        (result: AxiosResponse<TemplateUniqueNameResponseInterface>) =>
          result.data.unique
      );
  }

  @Action({ commit: "setUsedDashboards" })
  async loadUsedDashboardsByTemplateId(templateId: number) {
    this.context.commit("setUsedDashboardsLoading", true);

    return await axios
      .get(`/api/templates/${templateId}/usage`)
      .then((result: AxiosResponse<UsedDashboardModel>) => {
        return result.data;
      })
      .finally(() => {
        this.context.commit("setUsedDashboardsLoading", false);
      });
  }
}
