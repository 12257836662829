export class GradientSupplier {
  private values: Array<number>;
  private excludedValues: Array<number>;
  private minValue: number | null;
  private maxValue: number | null;
  private colorMap: Record<string, number | null>;

  constructor(initialValues: Array<number>, public reversed = false) {
    this.values = [...new Set(initialValues)]
      .filter((value) => value !== undefined && value !== null)
      .sort((valueA, valueB) => valueA - valueB);
    this.excludedValues = [];
    this.minValue = this.values[0] ?? null;
    this.maxValue = this.values[this.values.length - 1] ?? null;
    this.colorMap = {};
  }

  private calculateColorFactor(value: number): number | null {
    if (
      this.minValue === null ||
      this.maxValue === null ||
      this.minValue === this.maxValue
    ) {
      return null;
    }

    return (
      (this.reversed ? this.maxValue - value : value - this.minValue) /
      (this.maxValue - this.minValue)
    );
  }

  private recalcColorMap() {
    const activeValues = this.values.filter(
      (value) => !this.excludedValues.includes(value)
    );

    this.minValue = activeValues[0];
    this.maxValue = activeValues[activeValues.length - 1];
    this.colorMap = Object.keys(this.colorMap).reduce(
      (result: Record<string, number | null>, key) =>
        Object.assign(result, {
          [key]: this.excludedValues.includes(+key)
            ? null
            : this.calculateColorFactor(+key),
        }),
      {}
    );
  }

  private deleteValue(value: number) {
    this.excludedValues.push(value);
    this.colorMap[value] = null;

    if (this.excludedValues.length === this.values.length) {
      this.minValue = null;
      this.maxValue = null;

      return;
    }

    if (![this.minValue, this.maxValue].includes(value)) {
      return;
    }

    this.recalcColorMap();
  }

  private addValue(value: number) {
    this.excludedValues = this.excludedValues.filter(
      (excludedValue) => excludedValue !== value
    );

    if (
      this.maxValue === null ||
      this.maxValue < value ||
      this.minValue === null ||
      this.minValue > value
    ) {
      this.recalcColorMap();

      return;
    }

    this.colorMap[value] = this.calculateColorFactor(value);
  }

  toggleValue(toggledValue: number) {
    if (this.excludedValues.includes(toggledValue)) {
      this.addValue(toggledValue);
    } else {
      this.deleteValue(toggledValue);
    }
  }

  getGradientFactor(value: number | undefined | null): number | null {
    if (value === undefined || value === null) {
      return null;
    }

    if (this.colorMap[value] === undefined) {
      this.colorMap[value] = this.calculateColorFactor(value);
    }

    return this.values.length - this.excludedValues.length > 1
      ? this.colorMap[value]
      : null;
  }
}
