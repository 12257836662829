import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosResponse } from "axios";

import axios from "@/shared/plugins/axios";
import {
  SubscriptionEventModel,
  SubscriptionEventUpdateRequest,
  SubscriptionEventUniqueTokenRequest,
  SubscriptionEventRequest,
} from "../models/subscription-events/SubscriptionEventModel";

@Module
export default class SubscriptionEventsStore extends VuexModule {
  subscriptionEvents: Array<SubscriptionEventModel> = [];
  subscriptionEvent: SubscriptionEventModel | null = null;
  isSubscriptionEventSaving = false;
  isSubscriptionEventDeleting = false;
  isSubscriptionEventFetching = false;
  isTokenUnique = true;

  @Mutation
  setSubscriptionEvents(payload: Array<SubscriptionEventModel>) {
    this.subscriptionEvents = SubscriptionEventModel.ofArray(payload);
  }

  @Mutation
  setSubscriptionEvent(payload: SubscriptionEventModel) {
    this.subscriptionEvent = SubscriptionEventModel.ofSource(payload);
  }

  @Mutation
  clearSubscriptionEvent() {
    this.subscriptionEvent = null;
  }

  @Mutation
  clearSubscriptionEvents() {
    this.subscriptionEvents = [];
  }

  @Mutation
  setIsSubscriptionEventSaving(payload: boolean) {
    this.isSubscriptionEventSaving = payload;
  }

  @Mutation
  setIsTokenUnique(payload: boolean) {
    this.isTokenUnique = payload;
  }

  @Mutation
  updateSubscriptionEvents(deletedEventId: number) {
    this.subscriptionEvents = this.subscriptionEvents.filter(
      ({ id }) => id !== deletedEventId
    );
  }

  @Mutation
  setIsEventDeleting(payload: boolean) {
    this.isSubscriptionEventDeleting = payload;
  }

  @Mutation
  setIsSubscriptionEventFetching(payload: boolean) {
    this.isSubscriptionEventFetching = payload;
  }

  @Action({ commit: "setSubscriptionEvents" })
  async fetchSubscriptionEvents(applicationId: string) {
    const { data }: AxiosResponse<Array<SubscriptionEventModel>> =
      await axios.get(
        `/api/appadmin/app/${applicationId}/settings/events/subscriptions`
      );

    return data;
  }

  @Action({ commit: "setSubscriptionEvent" })
  async fetchSubscriptionEvent({
    applicationId,
    id,
  }: SubscriptionEventRequest) {
    this.context.commit("setIsSubscriptionEventFetching", true);

    try {
      const { data }: AxiosResponse<SubscriptionEventModel> = await axios.get(
        `/api/appadmin/app/${applicationId}/settings/events/subscriptions/${id}`
      );

      return data;
    } finally {
      this.context.commit("setIsSubscriptionEventFetching", false);
    }
  }

  @Action
  async createSubscriptionEvent({
    applicationId,
    event,
  }: SubscriptionEventUpdateRequest) {
    this.context.commit("setIsSubscriptionEventSaving", true);

    try {
      await axios.post(
        `/api/appadmin/app/${applicationId}/settings/events/subscriptions`,
        event
      );
      this.context.commit("addNotification", {
        message: {
          key: "appAdmin.subscriptionEvents.notification.createdSuccess",
        },
      });
    } finally {
      this.context.commit("setIsSubscriptionEventSaving", false);
    }
  }

  @Action
  async updateSubscriptionEvent({
    applicationId,
    event,
  }: SubscriptionEventUpdateRequest) {
    this.context.commit("setIsSubscriptionEventSaving", true);

    try {
      await axios.put(
        `/api/appadmin/app/${applicationId}/settings/events/subscriptions/${event.id}`,
        event
      );
      this.context.commit("addNotification", {
        message: {
          key: "appAdmin.subscriptionEvents.notification.updatedSuccess",
        },
      });
    } finally {
      this.context.commit("setIsSubscriptionEventSaving", false);
    }
  }

  @Action
  async deleteSubscriptionEvent({
    applicationId,
    id,
  }: SubscriptionEventRequest) {
    this.context.commit("setIsEventDeleting", true);

    try {
      await axios.delete(
        `/api/appadmin/app/${applicationId}/settings/events/subscriptions/${id}`
      );
      this.context.commit("addNotification", {
        message: {
          key: "appAdmin.subscriptionEvents.notification.deletedSuccess",
        },
      });
      this.context.commit("updateSubscriptionEvents", id);
    } finally {
      this.context.commit("setIsEventDeleting", false);
    }
  }

  @Action({ commit: "setIsTokenUnique" })
  async checkIsSubscriptionEventTokenUnique({
    applicationId,
    token,
  }: SubscriptionEventUniqueTokenRequest) {
    if (!token) {
      return true;
    }

    const { data }: AxiosResponse<{ unique: boolean }> = await axios.post(
      `/api/appadmin/app/${applicationId}/settings/events/subscriptions/uniqueToken`,
      { token }
    );

    return data.unique;
  }
}
