import { AxiosResponse } from "axios";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import {
  ApplicationBasicInfo,
  ApplicationValidationError,
} from "@/application-manager/models/ApplicationBasicInfo";
import {
  ApplicationFeatureModel,
  ApplicationFeaturesRequest,
  ApplicationFeaturesResponse,
} from "@/application-manager/models/ApplicationFeatureModel";
import axios from "@/shared/plugins/axios";
import {
  ApplicationConfigKeyRequest,
  ApplicationConfigUpdateRequest,
} from "@/application-manager/models/ApplicationConfigKeyModel";

@Module
export default class ApplicationInfoStore extends VuexModule {
  applicationBasicInfo: ApplicationBasicInfo | null = null;
  isApplicationBasicInfoLoading = false;

  applicationFeatures: Array<ApplicationFeatureModel> = [];
  isApplicationFeaturesLoading = false;

  currentApplicationFeatures: ApplicationFeaturesResponse | null = null;
  isCurrentApplicationFeaturesLoading = false;
  isCurrentApplicationFeaturesSaving = false;

  applicationValidation: Record<
    ApplicationValidationError,
    Array<string>
  > | null = null;
  isApplicationValidationLoading = false;

  isApplicationConfigKeysSaving = false;

  @Mutation
  setApplicationBasicInfo(payload: ApplicationBasicInfo | null) {
    this.applicationBasicInfo = payload;
  }

  @Mutation
  setApplicationBasicInfoLoading(payload: boolean) {
    this.isApplicationBasicInfoLoading = payload;
  }

  @Mutation
  setApplicationFeatures(payload: ApplicationFeatureModel) {
    this.applicationFeatures = [
      new ApplicationFeatureModel(
        payload.feature,
        payload.children,
        payload.parentFeatures,
        payload.missedKeys,
        payload.disabled
      ),
    ];
  }

  @Mutation
  setApplicationFeaturesLoading(payload: boolean) {
    this.isApplicationFeaturesLoading = payload;
  }

  @Mutation
  setCurrentApplicationFeatures(payload: ApplicationFeaturesResponse) {
    this.currentApplicationFeatures = payload;
  }

  @Mutation
  setCurrentApplicationFeaturesLoading(payload: boolean) {
    this.isCurrentApplicationFeaturesLoading = payload;
  }

  @Mutation
  setCurrentApplicationFeaturesSaving(payload: boolean) {
    this.isCurrentApplicationFeaturesSaving = payload;
  }

  @Mutation
  setApplicationValidation(
    payload: Record<ApplicationValidationError, Array<string>>
  ) {
    Object.keys(payload).forEach((key) => {
      payload[key as ApplicationValidationError].sort();
    });

    this.applicationValidation = payload;
  }

  @Mutation
  setApplicationValidationLoading(payload: boolean) {
    this.isApplicationValidationLoading = payload;
  }

  @Mutation
  clearApplicationInfoStore() {
    this.isApplicationFeaturesLoading = false;
    this.isCurrentApplicationFeaturesLoading = false;
    this.isCurrentApplicationFeaturesSaving = false;
    this.applicationBasicInfo = null;
    this.currentApplicationFeatures = null;
    this.applicationFeatures = [];
    this.isApplicationValidationLoading = false;
    this.applicationValidation = null;
  }

  @Mutation
  setApplicationConfigKeysSaving(payload: boolean) {
    this.isApplicationConfigKeysSaving = payload;
  }

  @Mutation
  updateApplicationConfigKeys({
    properties,
  }: {
    properties: Array<ApplicationConfigKeyRequest>;
  }) {
    const addedKeys = properties.map(({ key }) => key);

    function removeAddedKeys(feature: ApplicationFeatureModel) {
      feature.missedKeys = feature.missedKeys.filter(
        ({ key }) => !addedKeys.includes(key)
      );
      feature.children.forEach(removeAddedKeys);
    }

    this.applicationFeatures.forEach(removeAddedKeys);
  }

  @Action({ commit: "setApplicationFeatures" })
  async fetchApplicationFeatures(appId: string) {
    this.context.commit("setApplicationFeaturesLoading", true);

    return axios
      .get(`/api/infra/configs/app/${appId}/features/all`)
      .then(({ data }: AxiosResponse<Record<string, Array<string>>>) => data)
      .finally(() => {
        this.context.commit("setApplicationFeaturesLoading", false);
      });
  }

  @Action({ commit: "setApplicationBasicInfo" })
  async fetchApplicationBasicInfo(appId: string) {
    this.context.commit("setApplicationBasicInfoLoading", true);
    this.context.commit("setApplicationBasicInfo", null);

    return axios
      .get(`/api/infra/configs/app/${appId}/basicinfo`)
      .then(({ data }: AxiosResponse<ApplicationBasicInfo>) => data)
      .finally(() => {
        this.context.commit("setApplicationBasicInfoLoading", false);
      });
  }

  @Action({ commit: "setCurrentApplicationFeatures" })
  async getCurrentApplicationFeatures(appId: string) {
    this.context.commit("setCurrentApplicationFeaturesLoading", true);

    return axios
      .get(`/api/infra/configs/app/${appId}/features`)
      .then(({ data }: AxiosResponse<ApplicationFeaturesResponse>) => data)
      .finally(() => {
        this.context.commit("setCurrentApplicationFeaturesLoading", false);
      });
  }

  @Action({ commit: "setCurrentApplicationFeatures" })
  async updateCurrentApplicationFeatures({
    appId,
    featuresIncluded,
    featuresExcluded,
  }: ApplicationFeaturesRequest) {
    this.context.commit("setCurrentApplicationFeaturesSaving", true);

    return axios
      .put(`/api/infra/configs/app/${appId}/features`, {
        featuresIncluded,
        featuresExcluded,
      })
      .then(({ data }: AxiosResponse<ApplicationFeaturesResponse>) => {
        this.context.commit("addNotification", {
          message: {
            key: "applicationManager.applicationFeaturesControl.updateSuccess",
          },
        });

        return data;
      })
      .finally(() => {
        this.context.commit("setCurrentApplicationFeaturesSaving", false);
      });
  }

  @Action({ commit: "setApplicationValidation" })
  async validateApplicationConfig(appId: string) {
    this.context.commit("setApplicationValidationLoading", true);

    return axios
      .get(`/api/infra/configs/app/${appId}/validate`)
      .then(
        ({
          data,
        }: AxiosResponse<Record<ApplicationValidationError, Array<string>>>) =>
          data
      )
      .finally(() => {
        this.context.commit("setApplicationValidationLoading", false);
      });
  }

  @Action({ commit: "updateApplicationConfigKeys" })
  addApplicationConfigKeys({
    appId,
    properties,
  }: ApplicationConfigUpdateRequest) {
    this.context.commit("setApplicationConfigKeysSaving", true);

    return axios
      .put(`/api/infra/configs/app/${appId}`, { properties })
      .then(({ data }) => data)
      .finally(() => {
        this.context.commit("setApplicationConfigKeysSaving", false);
      });
  }
}
