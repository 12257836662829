

























































































import { cloneDeep, intersection } from "lodash";
import { Component, Vue, Watch } from "vue-property-decorator";

import AbTestMenu from "@/ab-tests/components/AbTestMenu.vue";
import ApplicationManagerMenu from "@/application-manager/components/ApplicationManagerMenu.vue";
import DashboardsMenu from "@/dashboard/components/DashboardsMenu.vue";
import HealthCheckMenu from "@/healthcheck/components/HealthCheckMenu.vue";
import IamMenu from "@/iam/components/IamMenu.vue";
import ReportMenu from "@/reports/components/ReportMenu.vue";
import SegmentsMenu from "@/segments/components/SegmentsMenu.vue";
import SettingsMenu from "@/settings/components/SettingsMenu.vue";
import ApplicationHeader from "@/shared/components/ApplicationHeader.vue";
import NotificationSnackbars from "@/shared/components/NotificationSnackbars.vue";
import UnsavedChangesDialog from "@/shared/components/UnsavedChangesDialog.vue";
import TemplatesMenu from "@/templates/components/TemplatesMenu.vue";
import ToolsMenu from "@/tools/components/ToolsMenu.vue";
import AccountingPortalMenu from "@/accounting-portal/components/AccountingPortalMenu.vue";

import {
  AppSection,
  CurrentUserModel,
  MULTI_APP,
  MULTI_APP_ROUTE_NAMES,
  MenuItems,
  MenuModel,
} from "@/shared/models";
import { getCommonFilter } from "@/shared/utils/CommonFilterUtil";
import KeyUtil from "@/shared/utils/KeyUtil";
import MenuUtil from "@/shared/utils/MenuUtil";
import {
  AdditionalMenuLocationType,
  ThemeType,
} from "./account-settings/models/AccountSettingType";

@Component({
  components: {
    ApplicationHeader,
    ReportMenu,
    SettingsMenu,
    AbTestMenu,
    NotificationSnackbars,
    SegmentsMenu,
    HealthCheckMenu,
    DashboardsMenu,
    ApplicationManagerMenu,
    TemplatesMenu,
    ToolsMenu,
    IamMenu,
    AccountingPortalMenu,
    UnsavedChangesDialog,
  },
})
export default class App extends Vue {
  get isMultiAppMode(): boolean {
    return this.$store.getters.isMultiAppMode;
  }

  get menu(): Array<MenuModel> {
    return this.applicationId
      ? MenuUtil.generateMainMenu(
          this.$lang,
          this.currentUser.viewAccessEntities[this.applicationId],
          this.applicationId
        )
      : [];
  }

  get currentUser(): CurrentUserModel {
    return this.$store.state.userStore.currentUser;
  }

  get isLogin(): boolean {
    return this.$route.name === "login";
  }

  get defaultApplication() {
    return this.$store.getters.getDefaultApplication;
  }

  get routeApplicationId(): string {
    return this.$route.params.id;
  }

  get applicationId(): string | null {
    return this.$store.state.application.applicationId;
  }

  get parentRouteName(): string | undefined {
    return this.$route?.matched[0]?.name;
  }

  get isSettings(): boolean {
    return this.parentRouteName === MenuItems.SETTINGS;
  }

  get isReports(): boolean {
    return this.parentRouteName === MenuItems.REPORT;
  }

  get isAbTests(): boolean {
    return this.parentRouteName === MenuItems.AB_TESTS;
  }

  get isSegments(): boolean {
    return this.parentRouteName === MenuItems.SEGMENT;
  }

  get isTools(): boolean {
    return this.parentRouteName === MenuItems.TOOLS;
  }

  get isIam(): boolean {
    return this.parentRouteName === MenuItems.IAM;
  }

  get isHealthCheck(): boolean {
    return [
      AppSection.HEALTHCHECK_SUMMARY,
      AppSection.HEALTHCHECK_PARSING,
      AppSection.HEALTHCHECK_DATA_FLOW,
      AppSection.HEALTHCHECK_JOBS_QUEUE,
      AppSection.HEALTHCHECK_METRIC_WEIGHT,
    ].includes(this.parentRouteName as AppSection);
  }

  get isDashboards(): boolean {
    return [
      MenuItems.DASHBOARDS,
      MenuItems.DASHBOARD_NEW,
      MenuItems.DASHBOARD_VIEW,
      MenuItems.DASHBOARD_UPDATE,
    ].includes(this.parentRouteName as MenuItems);
  }

  get isApplicationManager(): boolean {
    return this.parentRouteName === MenuItems.APPLICATION_MANAGER;
  }

  get isTemplates(): boolean {
    return this.parentRouteName === MenuItems.TEMPLATE;
  }

  get isAccountingPortal(): boolean {
    return this.parentRouteName === MenuItems.ACCOUNTING_PORTAL;
  }

  get isNavigationVisible(): boolean {
    return [
      this.isSettings,
      this.isReports,
      this.isAbTests,
      this.isSegments,
      this.isDashboards,
      this.isApplicationManager,
      this.isTemplates,
      this.isHealthCheck,
      this.isTools,
      this.isIam,
      this.isAccountingPortal,
    ].some((item) => item);
  }

  get isLoading(): boolean {
    return (
      this.$store.state.userStore.isUserLoading ||
      this.$store.state.application.loadingApps ||
      this.$store.state.accountSettingsStore.isLoading
    );
  }

  get isAdditionalMenuExpanded(): boolean {
    return this.$store.state.accountSettingsStore.accountSettings
      ?.isAdditionalMenuExpanded;
  }

  get expandOnHover(): boolean {
    return !this.isNavigationVisible || !this.isAdditionalMenuExpanded;
  }

  get isShifted(): boolean {
    return !this.isAdditionalMenuExpanded && this.isNavigationVisible;
  }

  @Watch("routeApplicationId", { immediate: true })
  watchRouteAppId(newAppId: string | undefined) {
    if (!newAppId || this.applicationId === newAppId) {
      return;
    }

    this.$store.commit("setApplicationId", newAppId);
  }

  @Watch("applicationId")
  watchApplicationId(id: string) {
    this.$store.commit("clearDictionaries");
    this.$store.commit("setAlertFilter", null);

    if (!this.isMultiAppMode || !this.$route.matched.length) {
      return;
    }

    if (
      !intersection(
        MULTI_APP_ROUTE_NAMES,
        this.$route.matched.map(({ name }) => name)
      ).length
    ) {
      this.$router.push({
        name: MenuItems.HOME,
        params: {
          id,
        },
      });
    }
  }

  @Watch("$route.name", { immediate: true })
  watchRouteName(value: string) {
    this.$store.commit("setRouteName", value);
  }

  @Watch("menu", { immediate: true })
  watchMenu() {
    this.$store.commit("setMenu", this.menu);
  }

  created() {
    this.setApplicationId();
    this.$store.commit("setTabHash", `#${KeyUtil.generateKey()}`);

    if (window.location.hash) {
      const reportFilter = getCommonFilter(window.location.hash);

      this.$store.commit("toggleCommonReportFilter");

      if (reportFilter) {
        this.$store.commit("setCommonReportFilter", reportFilter);
      }
    }
  }

  mounted() {
    this.initDarkMode();
  }

  setApplicationId() {
    const appId =
      this.routeApplicationId || this.defaultApplication?.id || MULTI_APP;

    this.$store.commit("setApplicationId", appId);
  }

  async login(params: Record<string, string>) {
    await this.$store.dispatch("login", params);
    await this.fetchData();
    this.$store.commit("setMenu", this.menu);
    this.initDarkMode();
    this.$router.replace("/app");
  }

  async fetchData() {
    await Promise.all([
      this.$store.dispatch("fetchCurrentUser"),
      this.$store.dispatch("loadAccountSettings"),
    ]);
    await this.$store.dispatch("loadPlatformsAndApps").catch(() => {
      this.$store.dispatch(
        "addError",
        this.$lang("shared.notification.applicationsNotAvailable")
      );
    });

    this.setApplicationId();

    // It needs for migration from local storage to api
    const accountSettingsFromLS = localStorage.getItem("accountSettings");

    if (accountSettingsFromLS) {
      const accountSettingsParsed = JSON.parse(accountSettingsFromLS);

      await this.$store.dispatch("updateAccountSettings", {
        payload: accountSettingsParsed,
      });

      localStorage.removeItem("accountSettings");
    }
  }

  initDarkMode() {
    const accountSettings =
      this.$store.state.accountSettingsStore.accountSettings;

    if (accountSettings === null) {
      return;
    }

    if (accountSettings.theme) {
      this.$vuetify.theme.dark = accountSettings.theme === ThemeType.DARK;
    } else if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      const accountSettingsCloned = cloneDeep(accountSettings);

      this.$vuetify.theme.dark = true;
      accountSettingsCloned.theme = ThemeType.DARK;
      this.$store.dispatch("updateAccountSettings", {
        payload: accountSettingsCloned,
      });
    }
  }

  toggleAdditionalMenuVisibility() {
    const accountSettingsCloned = cloneDeep(
      this.$store.state.accountSettingsStore.accountSettings
    );

    accountSettingsCloned.additionalMenuLocation = this.isAdditionalMenuExpanded
      ? AdditionalMenuLocationType.COLLAPSED
      : AdditionalMenuLocationType.EXPANDED;
    this.$store.dispatch("updateAccountSettings", {
      payload: accountSettingsCloned,
    });
  }
}
