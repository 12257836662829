export default class AccountGroupApplicationResponseModel {
  constructor(
    public id: number,
    public networkIntegrationId: number,
    public accountGroupId: number,
    public networkApplicationId: string,
    public applicationId: string,
    public aliasName: null | string
  ) {}

  static of(
    model: AccountGroupApplicationResponseModel
  ): AccountGroupApplicationResponseModel {
    return new AccountGroupApplicationResponseModel(
      model.id,
      model.networkIntegrationId,
      model.accountGroupId,
      model.networkApplicationId,
      model.applicationId,
      model.aliasName
    );
  }

  static ofArray(items: Array<AccountGroupApplicationResponseModel>) {
    return items.map((item) => AccountGroupApplicationResponseModel.of(item));
  }
}
