export default class AccountGroupResponseModel {
  constructor(
    public id: number,
    public networkIntegrationId: number,
    public accountGroupName: string,
    public description: string
  ) {}

  static of(model: AccountGroupResponseModel) {
    return new AccountGroupResponseModel(
      model.id,
      model.networkIntegrationId,
      model.accountGroupName,
      model.description ?? ""
    );
  }

  static ofArray(items: Array<AccountGroupResponseModel>) {
    return items.map((item) => AccountGroupResponseModel.of(item));
  }
}
