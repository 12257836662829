export enum RequestSpecialParamPlace {
  URL = "URL",
  BODY = "BODY",
  HEADER = "HEADER",
  REPLACE_URL = "REPLACE_URL",
  SIGN_URL = "SIGN_URL",
  OAUTH_1_SIGNATURE = "OAUTH_1_SIGNATURE",
  TIME_SIGN_URL = "TIME_SIGN_URL",
  JWT = "JWT",
}

export enum ParamType {
  START_DATE = "START_DATE",
  END_DATE = "END_DATE",
  AUTH_TOKEN = "AUTH_TOKEN",
  PLACEHOLDER_ID = "PLACEHOLDER_ID",
  PAGINATION_ID = "PAGINATION_ID",
  PLACEHOLDER_SECOND_ID = "PLACEHOLDER_SECOND_ID",
  PLACEHOLDER_THIRD_ID = "PLACEHOLDER_THIRD_ID",
  REFRESH_TOKEN = "REFRESH_TOKEN",
  CUSTOM = "CUSTOM",
}

export default class RequestSpecialParamResponseModel {
  constructor(
    public id: number,
    public requestId: number,
    public place: RequestSpecialParamPlace,
    public paramType: ParamType,
    public paramKey: string,
    public paramValue: string,
    public formatter: string
  ) {}

  static ofSource(
    model: RequestSpecialParamResponseModel
  ): RequestSpecialParamResponseModel {
    return new RequestSpecialParamResponseModel(
      model.id,
      model.requestId,
      model.place,
      model.paramType,
      model.paramKey,
      model.paramValue,
      model.formatter
    );
  }

  static ofArray(
    networkRequestParams: Array<RequestSpecialParamResponseModel>
  ) {
    return networkRequestParams.map((networkRequestParam) =>
      RequestSpecialParamResponseModel.ofSource(networkRequestParam)
    );
  }
}
