import { CohortFilter, CohortHeaders, CohortResultItem } from "./CohortReport";
import { GroupByFilter } from "./GroupByFilter";
import { ReportType } from "./ReportType";
import {
  DatesFilterModel,
  FilterId,
  recordToFilterModel,
  FilterModel,
  Application,
  DefaultValues,
  AdRevenueMethod,
  FilterPreviewId,
  DictionaryType,
  FilterPreview,
  ProvidedBannerType,
} from "@/shared/models";
import { ReportFilter, ReportResultItem } from "./Report";
import { LangService } from "@/shared/types/LangType";
import { ReportHeader, ReportHeaderUtil } from "./ReportHeader";
import { ReportDataType } from "./ReportVisualization";
import {
  AdRevenueMethodReportFilterExtension,
  ProvidedBannerReportFilterExtension,
  RevenueReportFilterExtension,
} from "./ReportFilterExtension";
import { ChartName } from "@/chart/models/ChartModel";
import { ReportItemRowObject } from "./ReportItemRowObject";

export class AdRoasHeaders {
  static readonly PRECISION = 2;
  static readonly PRECISION_FOR_ARPU = 5;

  static createInstalls(
    lang: LangService,
    isAdRoasNetworkPage: boolean
  ): ReportHeader {
    return {
      text: lang(
        isAdRoasNetworkPage
          ? "components.baseTable.paidInstalls"
          : "components.baseTable.installs"
      ),
      align: "end",
      value: ReportResultItem.PREFIX + "installs",
      fractionDigits: 0,
      width: 75,
      class: "px-1",
    };
  }

  static createDay(
    lang: LangService,
    dayLimit: number,
    result: Array<ReportHeader>,
    hasGradient: boolean,
    fractionDigits: number
  ) {
    const days =
      dayLimit > DefaultValues.MAX_DAYS_LIMIT
        ? DefaultValues.MAX_DAYS_LIMIT
        : dayLimit;

    for (let i = 0; i < days; i++) {
      result.push({
        text: lang("components.baseTable.day", i < 10 && i !== 0 ? `0${i}` : i),
        align: "end",
        value: `${ReportResultItem.PREFIX}${i}`,
        fractionDigits: fractionDigits,
        width: 70,
        class: "px-1",
        hasGradient: hasGradient,
      });
    }
  }

  static getUniqueHeaders(
    lang: LangService,
    isAdRoasNetworkPage: boolean,
    isGradientHorizontal = false
  ) {
    return [
      {
        text: lang("components.baseTable.spend"),
        align: "end",
        value: `${ReportResultItem.PREFIX}spend`,
        hasGradient: !isGradientHorizontal,
        width: 85,
      },
      {
        text: isAdRoasNetworkPage
          ? lang("components.baseTable.cpi")
          : lang("components.baseTable.eCPI"),
        align: "end",
        value: `${ReportResultItem.PREFIX}eCPI`,
        reverseGradient: true,
        hasGradient: !isGradientHorizontal,
        width: 80,
      },
    ];
  }

  static init(
    lang: LangService,
    report: AdRoasFilter,
    reportDataType: ReportDataType
  ): Array<ReportHeader> {
    const isAdRoasNetworkPage = report.reportId === ReportType.AD_ROAS_NETWORK;
    const result = [
      ...(reportDataType === ReportDataType.DATA
        ? [ReportHeaderUtil.createDate(lang)]
        : []),
      ...([ReportDataType.DATA, ReportDataType.SUB_TOTAL].includes(
        reportDataType
      )
        ? ReportHeaderUtil.createGroupBy(lang, report.groupByFilter)
        : []),
      this.createInstalls(lang, isAdRoasNetworkPage),
      ...this.getUniqueHeaders(lang, isAdRoasNetworkPage),
    ] as Array<ReportHeader>;

    this.createDay(
      lang,
      report.dayLimit,
      result,
      true,
      CohortHeaders.PRECISION
    );

    return result;
  }
}

export class AdRoasFilter
  extends CohortFilter
  implements
    RevenueReportFilterExtension,
    AdRevenueMethodReportFilterExtension,
    ProvidedBannerReportFilterExtension
{
  constructor(
    reportId: ReportType,
    app: Application,
    filter?: Array<FilterModel>,
    date?: DatesFilterModel,
    groupByFilter?: GroupByFilter,
    dayLimit?: number,
    public revenue: Array<string> = [],
    public providedBanner = DefaultValues.PROVIDED_BANNER,
    public adRevenueMethod: AdRevenueMethod = AdRevenueMethod.PRICED,
    public netRevenue = true
  ) {
    super(reportId, app, filter, date, groupByFilter, dayLimit);
  }

  get isDefaultProvidedBanner(): boolean {
    return this.providedBanner === DefaultValues.PROVIDED_BANNER;
  }

  get previews(): Array<FilterPreview> {
    return [
      ...super.previews,
      ...(this.revenue.length
        ? [
            {
              id: FilterPreviewId.REVENUE,
              value: this.revenue,
              dictionary: DictionaryType.REVENUES,
            },
          ]
        : []),
      {
        id: FilterPreviewId.PROVIDED_BANNER,
        value: this.providedBanner,
      },
      {
        id: FilterPreviewId.AD_REVENUE_METHOD,
        value: this.adRevenueMethod,
      },
      {
        id: FilterPreviewId.NET_REVENUE,
        value: this.netRevenue,
      },
    ];
  }

  get isBannerProvidedDisabled(): boolean {
    return this.adRevenueMethod === AdRevenueMethod.AVERAGE;
  }

  get invalid(): boolean {
    return (
      super.invalid ||
      (this.isBannerProvidedDisabled &&
        this.providedBanner === ProvidedBannerType.FIREBASE)
    );
  }

  get defaultChartNames(): Array<ChartName> {
    return (
      new Map([
        [ReportType.AD_ROAS_COUNTRY, [ChartName.AD_ROAS_COUNTRY_CHART]],
        [ReportType.AD_ROAS_NETWORK, [ChartName.AD_ROAS_NETWORK_CHART]],
      ]).get(this.reportId) ?? []
    );
  }

  get charts(): Array<ChartName> {
    const days = [
      0, 1, 3, 7, 14, 30, 90, 120, 150, 180, 210, 240, 270, 300, 330, 364,
    ];
    const groupedCharts =
      new Map([
        [
          ReportType.AD_ROAS_COUNTRY,
          this.getGroupedCohortChartNames("AD_ROAS_COUNTRY", days),
        ],
        [
          ReportType.AD_ROAS_NETWORK,
          this.getGroupedCohortChartNames("AD_ROAS_NETWORK", days),
        ],
      ]).get(this.reportId) ?? [];

    return this.groupByFilter.isNotEmptyGroupBy
      ? groupedCharts
      : this.defaultChartNames;
  }

  get groupedCharts(): Array<ChartName> {
    return this.groupByFilter.isNotEmptyGroupBy ? this.defaultChartNames : [];
  }

  static of(
    reportId: ReportType,
    app: Application,
    filter?: Record<string, any>
  ): AdRoasFilter {
    return filter
      ? new AdRoasFilter(
          reportId,
          app,
          filter.filter && filter.filter.length > 0
            ? filter.filter.map((it: any) => recordToFilterModel(it))
            : [],
          filter.date
            ? DatesFilterModel.ofRecord(
                FilterId.ATTRIBUTION_DATE_VALUE,
                filter.date
              )
            : undefined,
          GroupByFilter.of(filter?.groupByFilter, filter.reportId),
          filter.dayLimit
            ? Number.parseInt(filter.dayLimit as string)
            : undefined,
          filter.revenue,
          filter.providedBanner,
          filter.adRevenueMethod
          // TODO: NET_REVENUE need to uncomment later
          // typeof filter.netRevenue === "string"
          //   ? filter.netRevenue === "true"
          //   : filter.netRevenue,
        )
      : new AdRoasFilter(reportId, app);
  }

  toRequestQuery(): Record<string, any> {
    return {
      ...super.toRequestQuery(),
      revenue: this.revenue,
      adRevenueMethod: this.adRevenueMethod,
      ...(!this.isDefaultProvidedBanner
        ? { providedBanner: this.providedBanner }
        : {}),
      // TODO: NET_REVENUE need to uncomment later
      // netRevenue: this.netRevenue,
    };
  }
}

export class AdRoasResultItem extends CohortResultItem {
  constructor(
    row: ReportItemRowObject,
    filter: ReportFilter,
    precision: number
  ) {
    super(row, filter, precision);

    this.data["spend"] = row.getByHeader("spend");
    this.data["eCPI"] = row.getByHeader("eCPI");
  }
}
