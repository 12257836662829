// import shared from "~/bi/shared/locales/en";
import login from "./login/en";
import validation from "./validation/en";
import menu from "./menu/en";
import btn from "./button/en";
import errorPage from "./errorPage/en";
import funnel from "@/funnels/locales/en";
import segment from "@/segments/locales/en";
import iam from "@/iam/locales/en";
import appAdmin from "@/settings/locales/en";
import abTests from "@/ab-tests/locales/en";
import template from "@/templates/locales/en";
import dashboard from "@/dashboard/locales/en";
import report from "@/reports/locales/en";
import healthcheck from "@/healthcheck/locales/en";
import applicationManager from "@/application-manager/locales/en";
import chart from "@/chart/locales/en";
import alertsSystem from "@/alerts-system/locales/en";
import tools from "@/tools/locales/en";
import accountSettings from "@/account-settings/locales/en";
import accountingPortal from "@/accounting-portal/locales/en";

export default {
  // ...shared,
  ...login,
  ...validation,
  ...menu,
  ...btn,
  ...funnel,
  ...segment,
  ...iam,
  ...appAdmin,
  ...abTests,
  ...template,
  ...dashboard,
  ...report,
  ...healthcheck,
  ...applicationManager,
  ...chart,
  ...alertsSystem,
  ...tools,
  ...accountSettings,
  ...errorPage,
  ...accountingPortal,
  presets: {
    today: "Today",
    yesterday: "Yesterday",
    lastSeven: "Last 7 Days",
    lastFourteen: "Last 14 Days",
    lastThirty: "Last 30 Days",
    lastNinety: "Last 90 Days",
    thisMonth: "This Month",
    lastMonth: "Last Month",
    year: "1 Year",
    thisYear: "This Year",
    lastYear: "Last Year",
    allTime: "All Time",
  },
  lat: {
    label: "LAT",
    values: {
      any: "Any",
      on: "ON Only",
      off: "OFF Only",
    },
  },
  documentTitleDefault: "The BI Panel",
  documentTitle: "The BI Panel: {0}",
  abTestsTitle: "AB Tests",
  badge: "Badge",
  close: "Close",
  cancel: "Cancel",
  delete: "Delete",
  dataIterator: {
    noResultsText: "No matching records found",
    loadingText: "Loading items...",
  },
  dataTable: {
    itemsPerPageText: "Rows per page:",
    ariaLabel: {
      sortDescending: ": Sorted descending. Activate to remove sorting.",
      sortAscending: ": Sorted ascending. Activate to sort descending.",
      sortNone: ": Not sorted. Activate to sort ascending.",
    },
    sortBy: "Sort by",
  },
  dataFooter: {
    itemsPerPageText: "Items per page:",
    itemsPerPageAll: "All",
    nextPage: "Next page",
    prevPage: "Previous page",
    firstPage: "First page",
    lastPage: "Last page",
    pageText: "{0}-{1} of {2}",
  },
  datePicker: {
    itemsSelected: "{0} selected",
  },
  seeMoreResults: "Type to see more results",
  noDataText: "No data available",
  comboboxNoDataTextOption: `No results matching "<strong>{0}</strong>". Press <kbd>enter</kbd> to create a new one`,
  comboboxNoDataText: "Enter only positive integer numbers",
  carousel: {
    prev: "Previous visual",
    next: "Next visual",
    ariaLabel: {
      delimiter: "Carousel slide {0} of {1}",
    },
  },
  calendar: {
    moreEvents: "{0} more",
  },
  fileInput: {
    counter: "{0} files",
    counterSize: "{0} files ({1} in total)",
  },
  timePicker: {
    am: "AM",
    pm: "PM",
  },
  accessType: {
    title: "Access type",
    private: "Private",
    public: "Public",
  },
  shared: {
    filters: {
      title: "Filters",
      attribution_date_value: "Attribution Date",
      attribution_status: "Attribution Status",
      attribution_date: "Attribution Date",
      country: "Attribution Country",
      ad_revenue: "Ad Revenue",
      iap_revenue: "Iap Revenue",
      source: "Source",
      sub_source: "Sub source",
      campaign: "Campaign",
      ad_set: "Ad set",
      creative: "Creative",
      publisher: "Publisher",
      tracker: "Ua Source",
      device_type: "Device Type",
      device_model: "Device Model",
      install_app_version: "Install App Version",
      app_version: "App Version",
      os_version: "OS Version",
      language: "Language",
      session_number: "Session Number",
      segment: "Segment",
      events_app_version: "Events app version",
      events_os_version: "Events os version",
      events_country: "Events country",
      events_date: "Events date",
      activity_kind: "Activity kind",
      lat: "LAT",
      waterfalls_id: "Waterfall Id",
      ad_network_name: "Revenue Source",
      event: "Event",
      range_based_multiple_event: "Event",
      simple_multiple_event: "Event",
      event_cost: "Event (Events Cost report)",
      adRevenueMethod: {
        priced: "Priced",
        average: "Average",
      },
      attributionStatus: {
        title: "Attribution Status",
      },
      sessionNumberPeriod: {
        first_day: "First Day",
        first_week: "First Week",
        first_30_days: "First 30 Days",
        last_day: "Last Day",
        last_week: "Last Week",
        last_30_days: "Last 30 Days",
      },
      eventCombinations: {
        and: "And",
        or: "Or",
      },
      addFilters: "Add filters",
      addParam: "Add param",
      includeFilter: "Include filter",
      excludeFilter: "Exclude filter",
      targetDay: "Target day",
      targetDayMode: "Target Day Mode",
      maxAvailable: "Max available",
      concreteDay: "Concrete day",
      rangeInfoTextAboutOccurrence:
        "Select event and how many times it should occur",
      eventOccurShouldBe: "Event occurrences should be",
      actual_event_parameter: "Actual Event Parameter",
      user_age: "Days Since Install",
      subscription_renewal_count: "Subscription Renewal Count",
    },
    sources: "Source",
    subSources: "Sub Source",
    campaigns: "Campaign",
    adSets: "Ad Set",
    creatives: "Creative",
    publishers: "Publisher",
    eventName: {
      name: "Event Name",
      params: "Event Params",
      times: "Times",
      value: "Value",
      operator: "Operator",
      equal: "equal",
      not_equal: "not equal",
      in: "in",
      not_in: "not in",
      between: "between",
      not_between: "not between",
      gt: ">",
      lt: "<",
      like: "like",
      not_like: "not like",
      day: "Day",
      btn: {
        add: "Add Event",
        remove: "Remove Event",
      },
    },
    and: "and",
    type: "Type",
    period: "Period",
    range: {
      title: "Range",
      from: "From",
      to: "To",
    },
    combination: "Combination",
    boolNames: {
      any: "Any",
      on: "ON Only",
      off: "OFF Only",
    },
    errors: {
      notUnique: "Not unique",
      required: "Required",
      maxCharacters: "Max {0} characters",
      shouldntEndsWith: "Shouldn't ends with {0}",
    },
    platform: {
      ios: "iOS",
      android: "Android",
    },
    applicationPicker: {
      application: "Application",
      switchToMultiApp: "Switch to MultiApp Mode",
      multiAppMode: "MultiApp Mode",
      addToFavorites: "Add to favorites",
      removeFromFavorites: "Remove from favorites",
      resetDefaultApp: "Reset default app",
      setAppAsDefault: "Set app as default",
      hideArchivedApps: "Hide archived applications",
      showArchivedApps: "Show archived applications",
      archived: "Archived",
    },
    applicationDialog: {
      application: "Application",
      title: "Select active application",
      search: "Search applications",
      name: "Name",
      id: "ID",
      attentionTitle: "Attention!",
      attentionTextForChangedApplication:
        "Are you sure you want to change an application? All entered data will be removed.",
      attentionTextForLeave:
        "Are you sure you want to leave from page? All entered data will be removed.",
    },
    notification: {
      next: `Next ("{0}") more`,
      close: "Close",
      applicationsNotAvailable:
        "Applications aren't available. Need to reload dictionaries cache",
    },
    search: "Search",
    showOnlyMy: "Show only my",
    onlyMy: "Only My",
    features: "Features",
    pages: "Pages",
    pinned: "Pinned",
    pin: "Pin",
    unpin: "Unpin",
    maxPinnedItems: "Max number of pinned items - 5",
    actions: "Actions",
  },
  dictionaryCacheReset: {
    archivedAppsIncluded: "Archived applications included",
    archivedAppsExcluded: "Archived applications excluded",
  },
};
