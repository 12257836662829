export default {
  summaryHealthCheckTable: {
    title: "Healthcheck Summary",
    tableName: "Healthcheck summary",
    updateThirdParty: "Update Third Party",
    updateParsing: "Update Parsing",
    metricWeightsButton: "Metric Weights",
    updateDataflowsButton: "Update Dataflows",
    updateSegmentsButton: "Update Segments",
    tooltip: {
      dataFlowHeader: "DATAFLOW METRICS",
      parsingHeader: "PARSING METRICS",
      segmentHeader: "SEGMENT METRICS",
      thirdPartyHeader: "THIRD PARTY METRICS",
    },
    headers: {
      application: "Application",
      twoDaysStat: "2 days",
      sevenDaysStat: "7 days",
      fourteenDaysStat: "14 days",
    },
    appMenu: {
      healthcheck_data_flow: `Data Flow for "{0}"`,
      healthcheck_parsing: `Parsing for "{0}"`,
    },
  },
  dataFlowTable: {
    title: "Healthcheck Dataflow",
    tableName: "Data Flow Metrics for {0}",
    app: "Application: {0}",
    placeholder:
      "Data flow metrics are fine. There are no failed data flow metrics for the last {0} days for this application.",
    headers: {
      dataFlow: "Data Flow",
      metric: "Metric",
      name: "File name",
      lastExecutedJob: "Last executed job",
      updatedAt: "Last update time",
    },
  },
  parsingTablePerDate: {
    tableName: "Parsing metrics for period: {0} - {1}",
    tableNameForPeriod: "Parsing metrics: {0}",
    headers: {
      metric: "Metric",
      adjustValue: "Adjust",
      bigQueryValue: "Big Query",
      deltaValue: "Delta, count",
      deltaPercent: "Delta, %",
    },
  },
  parsingTablePerMetric: {
    tableName: "Parsing for metrics: {0}.",
    tableNameForMetric: "Parsing for metric: {0}",
    headers: {
      date: "Date",
      adjustValue: "Adjust",
      bigQueryValue: "Big Query",
      deltaValue: "Delta, count",
      deltaPercent: "Delta, %",
    },
  },
  parsingTable: {
    title: "Healthcheck Parsing",
    placeHolderText:
      "Parsing metrics error. There are no parsing metrics for the last {0} days for this application.",
  },
  jobInfoTable: {
    tableName: "Job info",
    headers: {
      app: "Application",
      job: "Job",
      count: "Count",
      processingAvgTimeSec: "Processing average time, seconds",
      totalAvgTimeSec: "Total average time, seconds",
      diffAvgTimeSec: "Difference, seconds",
    },
  },
  queueInfoTable: {
    tableName: "Queue info",
    headers: {
      queue: "Queue",
      job: "Job",
      count: "Count",
    },
  },
  jobsQueueInfo: {
    title: "Healthcheck Jobs Queue",
    pageTitle: "Jobs queue info",
    placeholderText: "There are no jobs in queue.",
  },
  metricWeights: {
    title: "Healthcheck Metric Weights",
    pageTitle: "Metric weights",
    deleteHint: "Delete metric weight",
    createHint: "Create metric weight",
    deleteDialogHeader: "Delete metric weight",
    createDialogHeader: "Create metric weight",
    updateDialogHeader: "Update metric weight",
    headers: {
      metricType: "Type",
      metricId: "Metric",
      application: "Application",
      weight: "Weight",
      actions: "Actions",
    },
    labels: {
      metricType: "Type",
      metricId: "Metric",
      application: "Application",
      weight: "Weight",
    },
    notifications: {
      createdSuccess: "Metric weight was created successfully",
      updatedSuccess: "Metric weight was updated successfully",
      deletedSuccess: "Metric weight was deleted successfully",
    },
  },
};
