export enum EventSourceFormat {
  PLAIN = "PLAIN",
  JSON = "JSON",
}

const FORMATS: Record<
  EventSourceFormat,
  (e: MessageEvent) => Record<string, unknown>
> = {
  PLAIN: (e) => e.data,
  JSON: (e) => JSON.parse(e.data),
};

export default class EventSourceWrapper {
  private readonly url: string;
  private readonly reTryMilliSeconds: number;
  private readonly format: EventSourceFormat;
  private readonly listeners: Map<
    string,
    (data: Record<string, unknown>, id: string) => void
  > = new Map<string, (data: Record<string, unknown>, id: string) => void>();

  private eventSource: EventSource | null;
  private timer: ReturnType<typeof setTimeout> | null;
  private attempt = 0;

  constructor(
    path: string,
    format = EventSourceFormat.JSON,
    reTryMilliSeconds = 100,
    params: any = {}
  ) {
    const basePath = process.env.VUE_APP_API_URL || "";
    const separator = path.startsWith("/") ? "" : "/";
    const urlSearchParams = new URLSearchParams();

    Object.keys(params).forEach((it) => urlSearchParams.append(it, params[it]));

    this.url = `${basePath}${separator}${path}?${urlSearchParams.toString()}`;
    this.reTryMilliSeconds = reTryMilliSeconds;
    this.format = format;
    this.timer = null;

    this.eventSource = this.init();
  }

  private init(): EventSource {
    const result = new EventSource(this.url, {
      withCredentials: true,
    });

    Array.from(this.listeners).forEach(([type, listener]) => {
      result.addEventListener(type, (ev) =>
        listener(
          FORMATS[this.format](ev as MessageEvent),
          (ev as MessageEvent).lastEventId
        )
      );
    });

    result.onerror = () => this.onError();

    return result;
  }

  private onError() {
    if (this.eventSource?.readyState === 2) {
      this.eventSource.close();
      this.eventSource = null;

      this.timer = setTimeout(
        () => (this.eventSource = this.init()),
        ++this.attempt * this.reTryMilliSeconds
      );
    }
  }

  addEventListener(
    type: string,
    listener: (data: Record<string, unknown>, id: string) => void
  ) {
    if (this.eventSource) {
      this.eventSource.addEventListener(type, (ev) =>
        listener(
          FORMATS[this.format](ev as MessageEvent),
          (ev as MessageEvent).lastEventId
        )
      );
    }

    this.listeners.set(type, listener);
  }

  close() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }

    if (this.eventSource) {
      this.eventSource.close();
      this.eventSource = null;
    }

    this.listeners.clear();
    this.attempt = 0;
  }
}
