

























import { Component, Prop, Vue } from "vue-property-decorator";

import MenuUtil from "@/shared/utils/MenuUtil";

@Component
export default class TemplatesMenu extends Vue {
  @Prop() applicationId!: string;

  get templatesMenuItems(): Array<Record<string, string>> {
    return MenuUtil.getTemplateMenuItems(
      this.$store.state.userStore.currentUser.viewAccessEntities[
        this.applicationId
      ]
    );
  }
}
