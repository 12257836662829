import {
  AdType,
  Application,
  DatesFilterModel,
  FilterId,
  FilterModel,
  FilterPreview,
  FilterPreviewId,
  recordToFilterModel,
} from "@/shared/models";
import { LangService } from "@/shared/types/LangType";
import DateUtil from "@/shared/utils/DateUtil";
import { BaseReportFilter, ReportResultItem } from "./Report";
import { ReportHeader, ReportHeaderUtil } from "./ReportHeader";
import { ReportType } from "./ReportType";
import { ReportDataType } from "./ReportVisualization";
import { ReportItemRowObject } from "./ReportItemRowObject";
import { NoMonitoringTableExtension } from "./ReportFilterExtension";

export class MonetizationMonitoringFilter
  extends BaseReportFilter
  implements NoMonitoringTableExtension
{
  constructor(
    app: Application,
    filter?: Array<FilterModel>,
    date?: DatesFilterModel,
    public adType: Array<AdType> = [],
    // Temp flag for optimization report output
    public noMonitoringTable = true
  ) {
    super(ReportType.MONETIZATION_MONITORING, app, filter, date);
  }

  get invalid(): boolean {
    return !(this.app && this.date.valid);
  }

  get previews(): Array<FilterPreview> {
    return [
      ...super.previews,
      ...(this.adType.length
        ? [
            {
              id: FilterPreviewId.AD_TYPE,
              value: this.adType,
            },
          ]
        : []),
    ];
  }

  toRequestQuery(): Record<string, any> {
    return {
      ...super.toRequestQuery(),
      adType: this.adType,
      ...(this.noMonitoringTable
        ? { noMonitoringTable: this.noMonitoringTable }
        : {}),
    };
  }

  static of(
    app: Application,
    requestQuery?: Record<string, any>
  ): MonetizationMonitoringFilter {
    return new MonetizationMonitoringFilter(
      app,
      requestQuery?.filter?.map((it: any) => recordToFilterModel(it)) || [],
      DatesFilterModel.ofRecord(FilterId.EVENTS_DATE, requestQuery?.date),
      requestQuery?.adType,
      typeof requestQuery?.noMonitoringTable === "string"
        ? requestQuery?.noMonitoringTable === "true"
        : requestQuery?.noMonitoringTable
    );
  }
}

export class MonetizationMonitoringHeaders {
  static init(
    lang: LangService,
    reportDataType: ReportDataType
  ): Array<ReportHeader> {
    return [
      ReportHeaderUtil.createDate(lang),
      ...(reportDataType === ReportDataType.MONITORING
        ? [
            {
              text: lang("components.baseTable.adType"),
              align: "end",
              value: `${ReportResultItem.PREFIX}adType`,
            } as ReportHeader,
            {
              text: lang("components.baseTable.adNetworkName"),
              align: "end",
              value: `${ReportResultItem.PREFIX}adNetworkName`,
            } as ReportHeader,
            {
              text: lang("components.baseTable.country"),
              align: "end",
              value: `${ReportResultItem.PREFIX}country`,
            } as ReportHeader,
            {
              text: lang("components.baseTable.diffRevenueDay"),
              align: "end",
              value: `${ReportResultItem.PREFIX}diffRevenueDay`,
              hasFill: true,
              postfix: "%",
            } as ReportHeader,
            {
              text: lang("components.baseTable.diffImpressionsDay"),
              align: "end",
              value: `${ReportResultItem.PREFIX}diffImpressionsDay`,
              hasFill: true,
              postfix: "%",
            } as ReportHeader,
            {
              text: lang("components.baseTable.diffRevenuePrevDay"),
              align: "end",
              value: `${ReportResultItem.PREFIX}diffRevenuePrevDay`,
              hasFill: true,
              postfix: "%",
            } as ReportHeader,
            {
              text: lang("components.baseTable.diffImpressionsPrevDay"),
              align: "end",
              value: `${ReportResultItem.PREFIX}diffImpressionsPrevDay`,
              hasFill: true,
              postfix: "%",
            } as ReportHeader,
            {
              text: lang("components.baseTable.diffRevenuePrevYear"),
              align: "end",
              value: `${ReportResultItem.PREFIX}diffRevenuePrevYear`,
              hasFill: true,
              postfix: "%",
            } as ReportHeader,
            {
              text: lang("components.baseTable.diffImpressionsPrevYear"),
              align: "end",
              value: `${ReportResultItem.PREFIX}diffImpressionsPrevYear`,
              hasFill: true,
              postfix: "%",
            } as ReportHeader,
          ]
        : []),
    ];
  }
}

export class MonetizationMonitoringResultItem extends ReportResultItem {
  constructor(row: ReportItemRowObject) {
    super();

    this.date = row.getByHeader("date");
    this.formattedDate = DateUtil.formatDate(this.date);

    this.data["adType"] = row.getByHeader("adType");
    this.data["country"] = row.getByHeader("country");
    this.data["adNetworkName"] = row.getByHeader("adNetworkName");
    this.data["diffRevenueDay"] = row.getByHeader("diffRevenueDay");
    this.data["diffImpressionsDay"] = row.getByHeader("diffImpressionsDay");
    this.data["diffRevenuePrevDay"] = row.getByHeader("diffRevenuePrevDay");
    this.data["diffRevenuePrevYear"] = row.getByHeader("diffRevenuePrevYear");
    this.data["diffImpressionsPrevDay"] = row.getByHeader(
      "diffImpressionsPrevDay"
    );
    this.data["diffImpressionsPrevYear"] = row.getByHeader(
      "diffImpressionsPrevYear"
    );
  }
}

export class MMRevenueDiffPercentDateCountryResultItem extends ReportResultItem {
  constructor(row: ReportItemRowObject) {
    super();

    this.date = row.getByHeader("date");
    this.formattedDate = DateUtil.formatDate(this.date);
    this.data["country"] = row.getByHeader("country");
    this.data["diffRevenueDay"] = row.getByHeader("diffRevenueDay");
    this.data["diffImpressionsDay"] = row.getByHeader("diffImpressionsDay");
  }
}

export class MMRevenueDiffPercentDateNetworkResultItem extends ReportResultItem {
  constructor(row: ReportItemRowObject) {
    super();

    this.date = row.getByHeader("date");
    this.formattedDate = DateUtil.formatDate(this.date);
    this.data["adNetworkName"] = row.getByHeader("adNetworkName");
    this.data["diffRevenueDay"] = row.getByHeader("diffRevenueDay");
    this.data["diffImpressionsDay"] = row.getByHeader("diffImpressionsDay");
  }
}

export class MMArpdauDiffDateCountryResultItem extends ReportResultItem {
  constructor(row: ReportItemRowObject) {
    super();

    this.date = row.getByHeader("date");
    this.formattedDate = DateUtil.formatDate(this.date);
    this.data["country"] = row.getByHeader("country");
    this.data["diffRevenuePrevDay"] = row.getByHeader("diffRevenuePrevDay");
    this.data["diffRevenuePrevYear"] = row.getByHeader("diffRevenuePrevYear");
    this.data["diffImpressionsPrevDay"] = row.getByHeader(
      "diffImpressionsPrevDay"
    );
    this.data["diffImpressionsPrevYear"] = row.getByHeader(
      "diffImpressionsPrevYear"
    );
  }
}

export class MMArpdauDiffDateNetworkResultItem extends ReportResultItem {
  constructor(row: ReportItemRowObject) {
    super();

    this.date = row.getByHeader("date");
    this.formattedDate = DateUtil.formatDate(this.date);
    this.data["adNetworkName"] = row.getByHeader("adNetworkName");
    this.data["diffRevenuePrevDay"] = row.getByHeader("diffRevenuePrevDay");
    this.data["diffRevenuePrevYear"] = row.getByHeader("diffRevenuePrevYear");
    this.data["diffImpressionsPrevDay"] = row.getByHeader(
      "diffImpressionsPrevDay"
    );
    this.data["diffImpressionsPrevYear"] = row.getByHeader(
      "diffImpressionsPrevYear"
    );
  }
}
