import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { AxiosResponse } from "axios";

import axios from "@/shared/plugins/axios";
import ScenarioResponseModel, {
  ScenarioValidationScriptModel,
} from "@/application-manager/models/ScenarioResponseModel";
import ScenarioRequestModel, {
  ScenarioInfoRequestModel,
} from "@/application-manager/models/ScenarioRequestModel";

@Module
export default class ScenarioStore extends VuexModule {
  isLoading = false;
  isSavingInProgress = false;
  isSavingScriptInProgress = false;
  scenarios: Array<ScenarioResponseModel> = [];
  scenario: ScenarioResponseModel | null = null;
  errorValidationMessage: ScenarioValidationScriptModel | null = null;

  @Mutation
  setScenarioLoading(payload: boolean) {
    this.isLoading = payload;
  }

  @Mutation
  setIsSavingInProgress(payload: boolean) {
    this.isSavingInProgress = payload;
  }

  @Mutation
  setIsSavingScriptInProgress(payload: boolean) {
    this.isSavingScriptInProgress = payload;
  }

  @Mutation
  setScenario(payload: ScenarioResponseModel) {
    this.scenario = ScenarioResponseModel.ofSource(payload);
  }

  @Mutation
  setScenarios(payload: Array<ScenarioResponseModel>) {
    this.scenarios = ScenarioResponseModel.ofArray(payload);
  }

  @Mutation
  setErrorValidationMessage(payload: ScenarioValidationScriptModel) {
    this.errorValidationMessage = payload;
  }

  @Mutation
  clearScenarios() {
    this.scenarios = [];
  }

  @Mutation
  clearErrorValidationMessage() {
    this.errorValidationMessage = null;
  }

  @Action({ commit: "setScenarios" })
  async loadScenarios() {
    this.context.commit("setScenarioLoading", true);
    return axios
      .get("/api/infra/scenarios")
      .then((result: AxiosResponse<Array<ScenarioResponseModel>>) => {
        return result.data;
      })
      .finally(() => {
        this.context.commit("setScenarioLoading", false);
      });
  }

  @Action({ commit: "setScenario" })
  async loadScenario(scenarioId: number) {
    this.context.commit("setScenarioLoading", true);
    return axios
      .get(`/api/infra/scenarios/${scenarioId}`)
      .then((result: AxiosResponse<Array<ScenarioResponseModel>>) => {
        return result.data;
      })
      .finally(() => {
        this.context.commit("setScenarioLoading", false);
      });
  }

  @Action
  async createScenario(payload: ScenarioRequestModel) {
    this.context.commit("setIsSavingInProgress", true);

    return axios
      .post("/api/infra/scenarios", payload)
      .then((result: AxiosResponse<ScenarioResponseModel>) => {
        this.context.commit("addNotification", {
          message: {
            key: "applicationManager.scenario.notification.createdSuccess",
          },
        });

        return result.data;
      })
      .finally(() => {
        this.context.commit("setIsSavingInProgress", false);
      });
  }

  @Action({ commit: "setScenario" })
  async updateScenarioInfo(payload: ScenarioInfoRequestModel) {
    this.context.commit("setIsSavingInProgress", true);

    return axios
      .put("/api/infra/scenarios/info", payload)
      .then((result: AxiosResponse<ScenarioResponseModel>) => {
        this.context.commit("addNotification", {
          message: {
            key: "applicationManager.scenario.notification.updatedSuccess",
          },
        });

        return result.data;
      })
      .finally(() => {
        this.context.commit("setIsSavingInProgress", false);
      });
  }

  @Action
  async updateScenarioScript(payload: ScenarioRequestModel) {
    this.context.commit("setIsSavingScriptInProgress", true);

    return axios
      .put("/api/infra/scenarios/script", payload)
      .then((result: AxiosResponse<ScenarioResponseModel>) => {
        this.context.commit("addNotification", {
          message: {
            key: "applicationManager.scenario.notification.updatedScriptSuccess",
          },
        });

        return result.data;
      })
      .finally(() => {
        this.context.commit("setIsSavingScriptInProgress", false);
      });
  }

  @Action
  async deleteScenario(scenarioId: number) {
    return axios.delete(`/api/infra/scenarios/${scenarioId}`).then(() => {
      this.context.dispatch("loadScenarios");
    });
  }

  @Action
  async validateScenarioScript({
    payload,
    isShowSuccessNotification,
  }: {
    payload: string;
    isShowSuccessNotification?: boolean;
  }) {
    return axios
      .post(`/api/infra/validations/script`, payload, {
        skipErrors: true,
        headers: { "Content-Type": "text/plain" },
      } as any)
      .then(() => {
        if (isShowSuccessNotification) {
          this.context.commit("addNotification", {
            message: {
              key: "applicationManager.scenario.notification.validationSuccessMessage",
            },
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          this.context.commit("setErrorValidationMessage", error.response.data);
        }

        return Promise.reject(error);
      });
  }
}
