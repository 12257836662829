import "@/shared/directives/index";
import "@/shared/plugins/axios";
import { axiosPrototype } from "@/shared/plugins/axios";
import lang from "@/shared/plugins/lang";
import vuetify from "@/shared/plugins/vuetify";
import router from "@/shared/router";
import store from "@/shared/store";
import Maska from "maska";
import Vue from "vue";
import Fragment from "vue-fragment";
import Vue2Editor from "vue2-editor";
import Vuetify from "vuetify/lib";
import App from "./App.vue";
import { INITIAL_PATH, NotificationType } from "./shared/models";

Vue.config.productionTip = false;

Vue.use(Maska);
Vue.use(Fragment.Plugin);
Vue.use(Vue2Editor);
Vue.use(Vuetify);
Vue.use(lang);
Vue.use(axiosPrototype);

(async () => {
  const loginPath = "/app/login";
  const pathname = window.location.pathname + window.location.search;

  if (pathname !== loginPath) {
    await Promise.all([
      store.dispatch("fetchCurrentUser"),
      store.dispatch("loadAccountSettings"),
    ]).catch(() => {
      sessionStorage.setItem(INITIAL_PATH, pathname);
      window.location.href = `${window.location.origin}${loginPath}`;
    });
    await store.dispatch("loadPlatformsAndApps").catch(() => {
      store.commit("addNotification", {
        message: {
          key: "shared.notification.applicationsNotAvailable",
        },
        type: NotificationType.ERROR,
      });
    });

    // It needs for migration from local storage to api
    const accountSettingsFromLS = localStorage.getItem("accountSettings");

    if (accountSettingsFromLS) {
      const accountSettingsParsed = JSON.parse(accountSettingsFromLS);

      await store.dispatch("updateAccountSettings", {
        payload: accountSettingsParsed,
      });

      localStorage.removeItem("accountSettings");
    }
  }

  new Vue({
    store,
    router,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
})();
