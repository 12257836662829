export enum SubscriptionEventType {
  TRIAL_START = "TRIAL_START",
  START = "START",
  RENEWAL = "RENEWAL",
  GRACE_START = "GRACE_START",
  GRACE_FINISH = "GRACE_FINISH",
  PAUSE_START = "PAUSE_START",
  HOLD_START = "HOLD_START",
  CANCEL = "CANCEL",
  FINISH = "FINISH",
}

export class SubscriptionEventModel {
  constructor(
    public token = "",
    public type = SubscriptionEventType.TRIAL_START,
    public appVersionFrom: string | null = null,
    public appVersionTo: string | null = null,
    public id?: number
  ) {}

  static ofArray(revenueEvents: Array<SubscriptionEventModel>) {
    return revenueEvents.map(
      (revenueEvent) =>
        new SubscriptionEventModel(
          revenueEvent.token,
          revenueEvent.type,
          revenueEvent.appVersionFrom,
          revenueEvent.appVersionTo,
          revenueEvent.id
        )
    );
  }

  static ofSource(model: SubscriptionEventModel): SubscriptionEventModel {
    return new SubscriptionEventModel(
      model.token,
      model.type,
      model.appVersionFrom,
      model.appVersionTo,
      model.id
    );
  }
}

export interface SubscriptionEventRequest {
  applicationId: string;
  id: number;
}

export interface SubscriptionEventUpdateRequest {
  applicationId: string;
  event: SubscriptionEventModel;
}

export interface SubscriptionEventUniqueTokenRequest {
  applicationId: string;
  token: string | null;
}
