import { AxiosResponse } from "axios";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import axios, {
  AxiosSkipErrorsConfig,
  handleAxiosError,
} from "@/shared/plugins/axios";

import InvoiceResponseModel, {
  InvoiceStatus,
} from "@/accounting-portal/models/invoices/InvoiceResponseModel";
import InvoiceChangeHistoryResponseModel from "../models/invoices/InvoiceChangeHistoryResponseModel";
import InvoiceCalculationRequestModel from "../models/invoices/InvoiceCalculationRequestModel";
import InvoiceUpdateRequestModel from "../models/invoices/InvoiceUpdateRequestModel";
import InvoiceFiltersModel from "../models/invoices/InvoiceFiltersModel";
import FileUtil from "@/shared/utils/FileUtil";
import { NotificationType } from "@/shared/models";

@Module
export default class InvoiceStore extends VuexModule {
  invoices: Array<InvoiceResponseModel> = [];
  isInvoicesLoading = false;
  isInvoiceSaving = false;
  invoiceChangeHistory: Array<InvoiceChangeHistoryResponseModel> = [];
  isInvoiceChangeHistoryLoading = false;
  isDownloadingPdf = false;

  @Mutation
  setIsInvoicesLoading(payload: boolean) {
    this.isInvoicesLoading = payload;
  }

  @Mutation
  setIsInvoiceSaving(payload: boolean) {
    this.isInvoiceSaving = payload;
  }

  @Mutation
  setInvoices(payload: Array<InvoiceResponseModel>) {
    this.invoices = InvoiceResponseModel.ofArray(payload);
  }

  @Mutation
  setInvoicesAfterUpdate(payload: InvoiceResponseModel) {
    const indexOfUpdatedEntity = this.invoices.findIndex(
      ({ id }) => id === payload.id
    );
    this.invoices.splice(
      indexOfUpdatedEntity,
      1,
      InvoiceResponseModel.of(payload)
    );
  }

  @Mutation
  setIsInvoiceChangeHistoryLoading(payload: boolean) {
    this.isInvoiceChangeHistoryLoading = payload;
  }

  @Mutation
  setIsDownloadingInProgress(payload: boolean) {
    this.isDownloadingPdf = payload;
  }

  @Mutation
  setInvoiceChangeHistory(payload: Array<InvoiceChangeHistoryResponseModel>) {
    this.invoiceChangeHistory =
      InvoiceChangeHistoryResponseModel.ofArray(payload);
  }

  @Action({ commit: "setInvoices" })
  async loadInvoices(params: InvoiceFiltersModel) {
    this.context.commit("setIsInvoicesLoading", true);

    return axios
      .get(`/api/accounting/invoices`, {
        params,
      })
      .then(
        (response: AxiosResponse<Array<InvoiceResponseModel>>) => response.data
      )
      .finally(() => this.context.commit("setIsInvoicesLoading", false));
  }

  @Action
  async createInvoice(payload: InvoiceCalculationRequestModel) {
    this.context.commit("setIsInvoiceSaving", true);

    return axios
      .post(`/api/accounting/invoices`, payload)
      .then(() => {
        this.context.commit("addNotification", {
          message: {
            key: "accountingPortal.invoices.notification.createdSuccess",
          },
        });
      })
      .finally(() => this.context.commit("setIsInvoiceSaving", false));
  }

  @Action({ commit: "setInvoicesAfterUpdate" })
  async updateInvoice({
    id,
    invoice,
  }: {
    id: number;
    invoice: InvoiceUpdateRequestModel;
  }) {
    this.context.commit("setIsInvoiceSaving", true);

    return axios
      .patch(`/api/accounting/invoices/${id}`, invoice)
      .then((result: AxiosResponse<InvoiceResponseModel>) => {
        this.context.commit("addNotification", {
          message: {
            key: "accountingPortal.invoices.notification.updatedSuccess",
          },
        });

        return result.data;
      })
      .finally(() => this.context.commit("setIsInvoiceSaving", false));
  }

  @Action({ commit: "setInvoicesAfterUpdate" })
  async finishInvoice({
    id,
    invoice,
  }: {
    id: number;
    invoice: InvoiceUpdateRequestModel;
  }) {
    this.context.commit("setIsInvoiceSaving", true);

    return axios
      .post(`/api/accounting/invoices/${id}/finish`, invoice)
      .then((result: AxiosResponse<InvoiceResponseModel>) => {
        this.context.commit("addNotification", {
          message: {
            key: "accountingPortal.invoices.notification.finishedSuccess",
          },
        });

        return result.data;
      })
      .finally(() => this.context.commit("setIsInvoiceSaving", false));
  }

  @Action({ commit: "setInvoiceChangeHistory" })
  async loadInvoiceChangeHistory(invoiceId: number) {
    this.context.commit("setIsInvoiceChangeHistoryLoading", true);

    return axios
      .get(`/api/accounting/invoices/${invoiceId}/history`)
      .then(
        (response: AxiosResponse<Array<InvoiceChangeHistoryResponseModel>>) =>
          response.data
      )
      .finally(() =>
        this.context.commit("setIsInvoiceChangeHistoryLoading", false)
      );
  }

  @Action({ commit: "setInvoicesAfterUpdate" })
  async validateInvoice(id: number) {
    this.context.commit("setIsInvoiceSaving", true);

    return axios
      .post(`/api/accounting/invoices/${id}/validate`, {})
      .then((result: AxiosResponse<InvoiceResponseModel>) => {
        this.context.commit("addNotification", {
          message: {
            key: "accountingPortal.invoices.notification.validatedSuccess",
          },
        });

        return result.data;
      })
      .finally(() => this.context.commit("setIsInvoiceSaving", false));
  }

  @Action
  async downloadPdf({ id, status }: { id: number; status: InvoiceStatus }) {
    this.context.commit("setIsDownloadingInProgress", true);
    const url =
      status === InvoiceStatus.OPEN
        ? `/api/accounting/invoices/${id}/invoice-pdf`
        : `/api/accounting/invoices/${id}/financial-note-pdf`;

    return axios
      .get(url, {
        responseType: "blob",
        skipErrors: true,
      } as AxiosSkipErrorsConfig)
      .then(({ data, headers }: AxiosResponse<any>) => {
        const filename = headers["content-disposition"].replace(
          "filename=",
          ""
        );

        FileUtil.download(data, filename, "pdf");
      })
      .catch(async (error) => {
        if (error.response.status === 404) {
          const { errorCode } = JSON.parse(await error.response.data.text());

          if (errorCode) {
            this.context.commit("addNotification", {
              message: {
                key: `accountingPortal.errorCode.${errorCode.toLowerCase()}`,
              },
              timeout: -1,
              type: NotificationType.ERROR,
            });

            return;
          }
        }

        error.config.skipErrors = undefined;
        handleAxiosError(error);
      })
      .finally(() => {
        this.context.commit("setIsDownloadingInProgress", false);
      });
  }
}
