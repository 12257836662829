interface CommonFilter {
  filter: Record<string, any>;
  ttl: number;
}

const TTL = 24 * 60 * 60 * 1e3;
const LOCAL_STORAGE_COMMON_FILTER_KEY = "commonFilter";
const getUpdatedCommonFilter = (): Record<string, CommonFilter> => {
  const lsCommonFilter = localStorage.getItem(LOCAL_STORAGE_COMMON_FILTER_KEY);

  if (!lsCommonFilter) {
    return {};
  }

  return Object.entries(
    JSON.parse(lsCommonFilter) as Record<string, CommonFilter>
  ).reduce(
    (
      result: Record<string, CommonFilter>,
      [hash, item]: [string, CommonFilter]
    ) => {
      if (Date.now() > item.ttl) {
        return result;
      }

      return {
        ...result,
        [hash]: item,
      };
    },
    {}
  );
};

export const setCommonFilter = (hash: string, filter: Record<string, any>) => {
  localStorage.setItem(
    LOCAL_STORAGE_COMMON_FILTER_KEY,
    JSON.stringify({
      ...getUpdatedCommonFilter(),
      [hash]: {
        filter,
        ttl: Date.now() + TTL,
      },
    })
  );
};

export const getCommonFilter = (hash: string): Record<string, any> | null => {
  return getUpdatedCommonFilter()[hash]?.filter ?? null;
};
