import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import moment from "moment";

import { ChartRenderer } from "@/chart/models/ChartRenderer";
import {
  XAxisType,
  XYChartOptions,
  XYRangeAxis,
  XYSeries,
  XYSeriesType,
  YAxis,
} from "@/chart/models/ChartModel";
import { ILineSeriesAxisRange } from "@amcharts/amcharts5/.internal/charts/xy/series/LineSeries";
import { getAlertColorByType } from "@/alerts-system/utils/AlertsSystemUtil";
import AlertResponseModel from "@/alerts-system/models/AlertResponseModel";
import { AggregationPeriod } from "@/reports/models";
import DateUtil from "@/shared/utils/DateUtil";

const aggregationPeriodToTimeUnit: Record<AggregationPeriod, string> = {
  DAY: "day",
  WEEK: "week",
  MONTH: "month",
};

export default class XYChartRenderer extends ChartRenderer {
  chart: am5xy.XYChart;

  constructor(
    public element: HTMLElement,
    public data: Array<Record<string, any>>,
    public options: XYChartOptions,
    public isDarkMode: boolean,
    public alerts: Array<AlertResponseModel>,
    public aggregationPeriod?: AggregationPeriod,
    public dateTo?: string,
    public isPositiveAndNegativeBg?: boolean
  ) {
    super(element, isDarkMode);

    this.chart = this.root.container.children.push(
      am5xy.XYChart.new(this.root, {
        layout: this.root.verticalLayout,
        ...(this.options.legend ? { paddingTop: 0 } : {}),
      })
    );
    this.chart.get("colors")?.set("step", 5);
    this.init();
  }

  init() {
    const xAxis = this.addXAxis();

    this.addYAxes(xAxis);

    if (this.options.hasAdditionalBottomChart) {
      this.addAdditionalBottomChart(xAxis);
    }
    super.init();
    this.addCursor();
    this.addScrollbar();
  }

  addXAxis(): am5xy.ValueAxis<am5xy.AxisRendererX> {
    const hasOnlyLineSeries = this.options.yAxes.some(({ series }) =>
      series.every(({ type }) => type === XYSeriesType.LINE)
    );
    const minAndMaxDate =
      this.data.length === 1
        ? {
            min: moment(this.data[0].date).subtract(1, "days").valueOf(),
            max: moment(this.data[0].date).add(2, "days").valueOf(),
          }
        : {};

    const xAxis = this.chart.xAxes.push(
      {
        [XAxisType.DATE]: () =>
          am5xy.DateAxis.new(this.root, {
            ...minAndMaxDate,
            baseInterval: {
              timeUnit: this.aggregationPeriod
                ? aggregationPeriodToTimeUnit[this.aggregationPeriod]
                : "day",
              count: 1,
            },
            renderer: am5xy.AxisRendererX.new(this.root, {}) as any,
            tooltip: am5.Tooltip.new(this.root, {}),
            tooltipLocation: 0,
            startLocation: -0.5,
            endLocation: 0.5,
          }) as any,
        [XAxisType.CATEGORY]: () =>
          am5xy.CategoryAxis.new(this.root, {
            categoryField: this.options.xAxis.category,
            renderer: am5xy.AxisRendererX.new(this.root, {}) as any,
            tooltip: am5.Tooltip.new(this.root, {}),
            ...(hasOnlyLineSeries
              ? { tooltipLocation: 0, startLocation: -0.5, endLocation: 0.5 }
              : {}),
          }) as any,
      }[this.options.xAxis.type]()
    );

    if (this.options.xAxis.type === XAxisType.CATEGORY && !hasOnlyLineSeries) {
      xAxis.get("renderer").grid.template.setAll({
        location: 0.5,
      });
      xAxis.get("renderer").labels.template.setAll({
        multiLocation: 0.5,
      });
    } else {
      xAxis.get("renderer").labels.template.set("location", 0);
    }

    if (this.options.xAxis.hiddenLabels) {
      xAxis.get("renderer").labels.template.setAll({
        forceHidden: true,
      });
    }

    if (this.options.xAxis.title) {
      xAxis.children.push(
        am5.Label.new(this.root, {
          text: this.options.xAxis.title,
          x: am5.p50,
          centerX: am5.p50,
        })
      );
    }

    if (this.options.sortDataBy) {
      const metric = this.options.sortDataBy;

      xAxis.data.setAll(
        this.data.sort((itemA, itemB) => itemB[metric] - itemA[metric])
      );
    } else {
      xAxis.data.setAll(this.data);
    }

    let centerY = 5;

    this.alerts.forEach((alert) => {
      const found = this.alerts.find(
        (item) =>
          item.id !== alert.id &&
          (moment(alert.displayDates[0]).isSameOrAfter(item.displayDates[0]) ||
            moment(alert.displayDates[1]).isSameOrAfter(item.displayDates[1]))
      );

      this.addRangeXAxis(
        xAxis,
        new Date(alert.displayDates[0]).getTime(),
        moment(alert.displayDates[1]).isAfter(this.dateTo)
          ? new Date(this.dateTo as string).getTime()
          : new Date(alert.displayDates[1]).getTime(),
        getAlertColorByType(alert.details.type),
        alert.title,
        found ? (centerY += 25) : 5
      );
    });

    return xAxis;
  }

  addYAxes(xAxis: am5xy.ValueAxis<am5xy.AxisRendererX>): void {
    this.options.yAxes.forEach((yAxis: YAxis, index: number) => {
      const yRenderer = am5xy.AxisRendererY.new(this.root, {});
      const localYAxis = this.chart.yAxes.push(
        am5xy.ValueAxis.new(this.root, {
          ...(yAxis.numberFormat ? { numberFormat: yAxis.numberFormat } : {}),
          renderer: yRenderer,
          height: am5.percent(this.options.hasAdditionalBottomChart ? 70 : 100),
          min: yAxis.min,
          marginBottom: this.options.hasAdditionalBottomChart ? 20 : 0,
          extraMax: 0.1,
        }) as any
      );

      if (index) {
        localYAxis.set("syncWithAxis", this.chart.yAxes.getIndex(0));
      }

      if (yAxis.title) {
        localYAxis.children.unshift(
          am5.Label.new(this.root, {
            rotation: -90,
            text: yAxis.title,
            y: am5.p50,
            centerX: am5.p50,
          })
        );
      }

      yAxis.series.forEach((series: XYSeries) => {
        this.addSeries(series, xAxis, localYAxis, index, yRenderer);
      });

      yAxis.rangeAxes?.forEach((rangeAxis) => {
        this.addRangeAxis(rangeAxis, localYAxis);
      });

      if (this.isPositiveAndNegativeBg) {
        const max = Math.max(
          ...this.data.map((item) => Object.values(item).splice(1)).flat()
        );
        const rangeDataItemZeroLine = localYAxis.makeDataItem({
          value: 0,
          endValue: undefined,
        });
        const rangeZeroLine = localYAxis.createAxisRange(rangeDataItemZeroLine);
        rangeZeroLine.get("grid")?.setAll({
          stroke: am5.color("#000"),
          strokeWidth: 3,
          strokeOpacity: 1,
        });

        const rangeDataItemNegative = localYAxis.makeDataItem({ endValue: 0 });
        const rangeNegative = localYAxis.createAxisRange(rangeDataItemNegative);
        rangeNegative.get("axisFill")?.setAll({
          fill: am5.color("#ff8d8d"),
          fillOpacity: 0.08,
          visible: true,
        });

        const rangeDataItemPositive = localYAxis.makeDataItem({
          value: 0,
          endValue: max * 1.3,
        });
        const rangePositive = localYAxis.createAxisRange(rangeDataItemPositive);
        rangePositive.get("axisFill")?.setAll({
          fill: am5.color("#9cd69c"),
          fillOpacity: 0.08,
          visible: true,
        });
      }
    });
  }

  addSeries(
    series: XYSeries,
    xAxis: am5xy.Axis<am5xy.AxisRendererX>,
    yAxis: am5xy.ValueAxis<am5xy.AxisRendererY>,
    index: number,
    yRenderer: am5xy.AxisRendererY
  ): void {
    const localSeries = this.chart.series.push(
      {
        [XYSeriesType.COLUMN]: () =>
          am5xy.ColumnSeries.new(this.root, {
            name: series.name,
            xAxis,
            yAxis,
            valueYField: series.y,
            [this.options.xAxis.type === XAxisType.CATEGORY
              ? "categoryXField"
              : "valueXField"]: series.x,
            stacked: series.stacked,
            maskBullets: false,
            tooltip: am5.Tooltip.new(this.root, {
              pointerOrientation: "horizontal",
              labelText: series.tooltipText || "{name}: [bold]{valueY}[/]",
            }),
            locationX: this.options.xAxis.type === XAxisType.CATEGORY ? 0.5 : 0,
          }),
        [XYSeriesType.LINE]: () =>
          am5xy.LineSeries.new(this.root, {
            name: series.name,
            connect: series.connect,
            xAxis,
            yAxis,
            ...(series.color
              ? {
                  stroke: am5.color(series.color),
                  fill: am5.color(series.color),
                }
              : {}),
            valueYField: series.y,
            [this.options.xAxis.type === XAxisType.CATEGORY
              ? "categoryXField"
              : "valueXField"]: series.x,
            stacked: series.stacked,
            tooltip: am5.Tooltip.new(this.root, {
              pointerOrientation: "horizontal",
              labelText: series.tooltipText || "{name}: [bold]{valueY}[/]",
            }),
            minBulletDistance: 30,
            locationX: 0,
          }),
      }[series.type]()
    );

    if (series.type === XYSeriesType.LINE) {
      (localSeries as am5xy.LineSeries).bullets.push((root) =>
        am5.Bullet.new(root, {
          locationX: 0,
          sprite: am5.Circle.new(root, {
            radius: 3,
            fill: (localSeries as am5xy.LineSeries).get("fill"),
          }),
        })
      );
    }

    if (
      series.hasUnfilledData &&
      series.type === XYSeriesType.COLUMN &&
      this.dateTo
    ) {
      const filledDays =
        DateUtil.getDaysDifference(this.dateTo, DateUtil.today()) - 1;
      const reduceColumnOpacity = (
        opacity: number | undefined,
        target: am5.RoundedRectangle
      ) =>
        opacity &&
        (localSeries as am5xy.ColumnSeries).columns.indexOf(target) >=
          filledDays
          ? opacity / 2
          : opacity;

      (localSeries as am5xy.ColumnSeries).columns.template.adapters.add(
        "fillOpacity",
        reduceColumnOpacity
      );
      (localSeries as am5xy.ColumnSeries).columns.template.adapters.add(
        "strokeOpacity",
        reduceColumnOpacity
      );
    }

    if (series?.labelText) {
      (localSeries as am5xy.LineSeries).bullets.push(() => {
        return am5.Bullet.new(this.root, {
          locationY: 1,
          sprite: am5.Label.new(this.root, {
            text: series?.labelText,
            centerY: am5.p100,
            centerX: am5.p50,
            populateText: true,
          }),
        });
      });
    }

    if (series.type === XYSeriesType.LINE) {
      if (series.stacked) {
        (localSeries as am5xy.LineSeries).fills.template.setAll({
          fillOpacity: 0.5,
          visible: true,
        });
      }

      (localSeries as am5xy.LineSeries).strokes.template.setAll({
        strokeWidth: series.strokeWidth ?? 1.5,
      });

      if (this.options.yAxes.length > 1) {
        if (this.options.yAxes[index].series.length > 1) {
          yRenderer.labels.template.set(
            "fill",
            am5.Color.fromString("#000000")
          );
          yRenderer.setAll({
            stroke: am5.Color.fromString("#000000"),
            strokeWidth: 1,
            strokeOpacity: 0,
            opposite: !!index,
          });
        } else {
          yRenderer.labels.template.set(
            "fill",
            (localSeries as am5xy.LineSeries).get("fill")
          );
          yRenderer.setAll({
            stroke: (localSeries as am5xy.LineSeries).get("fill"),
            strokeWidth: 1,
            strokeOpacity: 1,
            opposite: !!index,
          });
        }
      }

      if (series.range) {
        const range = localSeries.createAxisRange(
          yAxis.makeDataItem({
            value: series.range.value,
            endValue: series.range.endValue,
          })
        );

        (range as ILineSeriesAxisRange).strokes?.template.setAll({
          stroke: am5.color(series.range.color),
        });
      }
    }

    localSeries.data.setAll(this.data);
  }

  addRangeXAxis(
    xAxis: any,
    value: number,
    endValue: number,
    color: string,
    text: string,
    centerY: number
  ): void {
    const rangeDataItem = xAxis.makeDataItem({
      value,
      endValue,
    });

    const range = xAxis.createAxisRange(rangeDataItem);

    rangeDataItem.get("label").setAll({
      fill: am5.color(0xffffff),
      text,
      background: am5.RoundedRectangle.new(this.root, {
        fill: am5.color(color),
      }),
      inside: true,
    });

    rangeDataItem
      .get("label")
      .adapters.add(
        "centerY",
        () => this.chart.gridContainer.height() - centerY
      );

    range.get("axisFill")?.setAll({
      fill: am5.color(color),
      fillOpacity: 0.08,
      visible: true,
    });
  }

  addRangeAxis(rangeAxis: XYRangeAxis, yAxis: any): void {
    const range = yAxis.createAxisRange(
      yAxis.makeDataItem({
        value: this.data[0][rangeAxis.value],
        reverseChildren: true,
        affectsMinMax: true,
        above: true,
      })
    );

    range.get("label").setAll({
      text: `${rangeAxis.label} ${this.data[0][rangeAxis.value]}`,
      fill: am5.color(0xffffff),
      background: am5.Rectangle.new(this.root, {
        fill: am5.color(rangeAxis.color),
        opacity: 0.75,
      }),
    });

    range.get("grid").setAll({
      stroke: am5.color(rangeAxis.color),
      strokeOpacity: 0.75,
      location: 1,
    });
  }

  addCursor(): void {
    const cursor = this.chart.set(
      "cursor",
      am5xy.XYCursor.new(this.root, {
        behavior: "zoomX",
      })
    );

    cursor.lineY.set("visible", false);
  }

  addScrollbar(): void {
    if (!this.options.scrollbar) {
      return;
    }

    const scrollbarX = am5.Scrollbar.new(this.root, {
      orientation: "horizontal",
    });

    this.chart.set("scrollbarX", this.chart.children.push(scrollbarX));
  }

  addAdditionalBottomChart(xAxis: am5xy.ValueAxis<am5xy.AxisRendererX>) {
    const additionalAxis = this.chart.yAxes.push(
      am5xy.ValueAxis.new(this.root, {
        renderer: am5xy.AxisRendererY.new(this.root, {}) as any,
        height: am5.percent(30),
      })
    );

    const additionalSeries = this.chart.series.push(
      am5xy.ColumnSeries.new(this.root, {
        name: "Installs",
        clustered: false,
        fill: am5.color("#7CB5D9"),
        valueYField: this.options.additionalChartValue ?? "installs",
        valueXField: "date",
        xAxis,
        yAxis: additionalAxis,
        tooltip: am5.Tooltip.new(this.root, {
          labelText: "Installs: {valueY}",
        }),
        locationX: 0,
      })
    );

    additionalSeries.setAll({
      background: am5.Rectangle.new(this.root, {
        fill: am5.color("#9E9E9E"),
        fillOpacity: 0.2,
      }),
    });

    additionalSeries.data.setAll(this.data);

    this.chart.leftAxesContainer.set("layout", this.root.verticalLayout);
  }

  addLegend(): void {
    if (!this.options.legend) {
      return;
    }

    const legend = this.chart.children.unshift(
      am5.Legend.new(this.root, {
        x: am5.percent(50),
        centerX: am5.percent(50),
      })
    );

    if (this.options.hasToggle) {
      legend.itemContainers.template.events.on("click", ({ target }) => {
        const currentSeries = target.dataItem?.dataContext as am5xy.LineSeries;
        const isCurrentSeriesVisible = currentSeries.get("visible");
        const toggleIndex = this.chart.series.length - 1;
        let hasHiddenSeries = false;

        this.chart.series.each((series, index) => {
          const isToggleIndex = index === toggleIndex;
          const isToggle = isToggleIndex && currentSeries === series;

          if (isToggle) {
            this.chart.series.each((series, index) => {
              if (index === toggleIndex) {
                return;
              }

              if (isCurrentSeriesVisible) {
                series.hide();
              } else {
                series.show();
              }
            });

            hasHiddenSeries = false;

            return;
          }

          if (
            isCurrentSeriesVisible &&
            isToggleIndex &&
            series.get("visible")
          ) {
            series.hide();

            return;
          }

          if (!isCurrentSeriesVisible) {
            if (
              !series.get("visible") &&
              !isToggleIndex &&
              currentSeries !== series
            ) {
              hasHiddenSeries = true;
            }

            if (!hasHiddenSeries && isToggleIndex) {
              series.show();
            }
          }
        });
      });
    }

    legend.data.setAll(this.chart.series.values);
  }
}
