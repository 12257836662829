import { UserAccess } from "@/shared/models";
import {
  AbTestMetricType,
  AbTestResultType,
  AbTestType,
  SegmentStatisticModel,
} from "./AbTestConfigurationModel";
import { ConfigsStatus } from "./ConfigsStatusModel";

export const EXTERNAL_TEST_STATUSES = [
  ConfigsStatus.INIT,
  ConfigsStatus.STARTED,
  ConfigsStatus.CONFIG_DISTRIBUTION_FINISHED,
  ConfigsStatus.STATISTICS_RECALCULATION,
  ConfigsStatus.STATISTICS_RECALCULATED,
];

export class ExternalTestConfigModel {
  constructor(
    public applicationId: string,
    public eventToken?: string,
    public abTestNameParamKey?: string,
    public abTestGroupParamKey?: string
  ) {}

  static of(model: ExternalTestConfigModel) {
    return new ExternalTestConfigModel(
      model.applicationId,
      model.eventToken,
      model.abTestNameParamKey,
      model.abTestGroupParamKey
    );
  }
}

export class ExternalTestGroupModel {
  constructor(
    public externalId?: string,
    public editableId = true,
    public segmentName?: string,
    public control = false
  ) {}

  static of(model: ExternalTestGroupModel) {
    return new ExternalTestGroupModel(
      model.externalId,
      model.editableId,
      model.segmentName,
      model.control
    );
  }
}

export class ExternalTestModel {
  constructor(
    public applicationId: string,
    public abTestType?: AbTestType,
    public name?: string,
    public externalId?: string,
    public activeSince?: string,
    public activeTill?: string,
    public dayNumber?: number,
    public metric?: AbTestMetricType,
    public testGroups: Array<ExternalTestGroupModel> = [],
    public creatorId?: string,
    public creatorName?: string,
    public id?: number,
    public segmentStatistics: Array<SegmentStatisticModel> = [],
    public status?: ConfigsStatus,
    public version?: number,
    public type?: string,
    public deletable = false,
    public features: Array<UserAccess> = [],
    public resultType?: AbTestResultType
  ) {}

  get hasEditAccess(): boolean {
    return this.features.includes(UserAccess.EDIT);
  }

  get hasDeleteAccess(): boolean {
    return this.features.includes(UserAccess.DELETE);
  }

  get controlGroupName(): string {
    return (
      this.testGroups.find(({ control }: ExternalTestGroupModel) => control)
        ?.segmentName || ""
    );
  }

  static of(model: ExternalTestModel) {
    return new ExternalTestModel(
      model.applicationId,
      model.abTestType,
      model.name,
      model.externalId,
      model.activeSince,
      model.activeTill,
      model.dayNumber,
      model.metric,
      model.testGroups,
      model.creatorId,
      model.creatorName,
      model.id,
      model.segmentStatistics?.map((it) => SegmentStatisticModel.of(it)) || [],
      model.status,
      model.version,
      model.type,
      model.deletable,
      model.features,
      model.resultType
    );
  }
}
