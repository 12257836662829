import { ReportType } from "./ReportType";
import { FilterId, FilterPreview, FilterPreviewId } from "@/shared/models";

export enum AggregationPeriod {
  DAY = "DAY",
  WEEK = "WEEK",
  MONTH = "MONTH",
}

export class GroupByFilter {
  constructor(
    public aggregationPeriod?: AggregationPeriod,
    public groupBy: Array<FilterId> = [],
    public changeableAggregationPeriod = true
  ) {}

  static of(
    filter?: Record<string, any>,
    reportType?: ReportType
  ): GroupByFilter | undefined {
    let groupBy: Array<FilterId> = [];

    if (
      (reportType === ReportType.AD_ROAS_COUNTRY ||
        reportType === ReportType.ARPDAU) &&
      filter?.groupBy.includes(FilterId.COUNTRY)
    ) {
      groupBy = [FilterId.COUNTRY];
    } else if (
      reportType !== ReportType.AD_ROAS_COUNTRY &&
      reportType !== ReportType.ARPDAU
    ) {
      groupBy = filter?.groupBy?.length
        ? filter.groupBy.map((it: any) => it as FilterId)
        : [];
    } else {
      groupBy = [];
    }

    return filter
      ? new GroupByFilter(
          filter.aggregationPeriod as AggregationPeriod,
          groupBy,
          typeof filter.changeableAggregationPeriod === "string"
            ? filter.changeableAggregationPeriod === "true"
            : filter.changeableAggregationPeriod
        )
      : undefined;
  }

  get preview(): Array<FilterPreview> {
    return [
      ...(this.groupBy.length
        ? [
            {
              id: FilterPreviewId.GROUP_BY,
              value: this.groupBy,
            },
          ]
        : []),
      ...(this.aggregationPeriod && this.changeableAggregationPeriod
        ? [
            {
              id: FilterPreviewId.AGGREGATION_PERIOD,
              value: this.aggregationPeriod,
            },
          ]
        : []),
    ];
  }

  get singleValue(): FilterId {
    return this.groupBy[0];
  }

  set singleValue(payload: FilterId) {
    if (payload) {
      this.groupBy = [payload];
    } else if (this.isNotEmptyGroupBy) {
      this.groupBy.splice(0, 1);
    }
  }

  get isNotEmptyGroupBy() {
    return this.groupBy.length !== 0;
  }

  get isNotEmpty() {
    return this.isNotEmptyGroupBy || !!this.aggregationPeriod;
  }
}
