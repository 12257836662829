export class ParsingFullTablePerMetric {
  constructor(
    public metric: string,
    public matchRows: Array<ParsingMatchRow>
  ) {}
}

export class ParsingMatchRow {
  constructor(
    public date: string,
    public adjustValue: string,
    public bigQueryValue: string,
    public deltaValue: string,
    public deltaPercent: string
  ) {}
}
