import { intersection } from "lodash";

import {
  BaseReportFilter,
  BaseGradientFlags,
  ReportResultItem,
  ReportFilter,
  PredictedResultItem,
} from "./Report";
import { ReportHeader, ReportHeaderUtil } from "./ReportHeader";
import { GroupByFilter } from "./GroupByFilter";
import { ReportType } from "./ReportType";
import {
  DatesFilterModel,
  FilterId,
  recordToFilterModel,
  FilterModel,
  FilterPreviewId,
  DefaultValues,
  AdRevenueMethod,
  Application,
  ProvidedBannerType,
} from "@/shared/models";
import { LangService } from "@/shared/types/LangType";
import { ReportDataType } from "./ReportVisualization";
import {
  EventReportFilterExtension,
  GoalReportFilterExtension,
  NetRevenueReportFilterExtension,
  ProvidedBannerReportFilterExtension,
} from "./ReportFilterExtension";
import { ReportItemRowObject } from "./ReportItemRowObject";

export class CashCountryGradientFlags extends BaseGradientFlags {
  constructor() {
    super();

    this.flags = {
      gradient: true,
    };
  }

  getByKey(): boolean {
    return super.getByKey("gradient");
  }
}

export class CashCountryFilter
  extends BaseReportFilter
  implements
    GoalReportFilterExtension,
    ProvidedBannerReportFilterExtension,
    EventReportFilterExtension,
    NetRevenueReportFilterExtension
{
  constructor(
    app: Application,
    filter?: Array<FilterModel>,
    date?: DatesFilterModel,
    groupByFilter: GroupByFilter = new GroupByFilter(undefined, [
      FilterId.COUNTRY,
    ]),
    public event: string = DefaultValues.EVENT,
    public startDay: number = 3,
    public endDay: number = 31,
    public goal: number = 365,
    public providedBanner = DefaultValues.PROVIDED_BANNER,
    public adRevenueMethod: AdRevenueMethod = AdRevenueMethod.PRICED,
    public netRevenue = true
  ) {
    super(ReportType.CASH_COUNTRY, app, filter, date, groupByFilter);
  }

  get previews() {
    return [
      ...super.previews,
      {
        id: FilterPreviewId.START_DAY,
        value: this.startDay,
      },
      {
        id: FilterPreviewId.END_DAY,
        value: this.endDay,
      },
      {
        id: FilterPreviewId.GOAL,
        value: this.goal,
      },
      {
        id: FilterPreviewId.PROVIDED_BANNER,
        value: this.providedBanner,
      },
      {
        id: FilterPreviewId.AD_REVENUE_METHOD,
        value: this.adRevenueMethod,
      },
      {
        id: FilterPreviewId.EVENT_SIMPLE,
        value: this.event,
      },
      {
        id: FilterPreviewId.NET_REVENUE,
        value: this.netRevenue,
      },
    ];
  }

  get invalid(): boolean {
    return (
      !this.app ||
      !this.date.valid ||
      !this.startDay ||
      !this.endDay ||
      !this.goal ||
      this.endDay < this.startDay ||
      this.goal < this.endDay ||
      (this.isBannerProvidedDisabled &&
        this.providedBanner === ProvidedBannerType.FIREBASE)
    );
  }

  get isDefaultProvidedBanner(): boolean {
    return this.providedBanner === DefaultValues.PROVIDED_BANNER;
  }

  get isPricedMethod(): boolean {
    return this.adRevenueMethod === AdRevenueMethod.PRICED;
  }

  get isBannerProvidedDisabled(): boolean {
    return this.adRevenueMethod === AdRevenueMethod.AVERAGE;
  }

  toRequestQuery(): Record<string, any> {
    return {
      ...super.toRequestQuery(),
      event: this.event,
      startDay: this.startDay,
      endDay: this.endDay,
      goal: this.goal,
      adRevenueMethod: this.adRevenueMethod,
      ...(!this.isDefaultProvidedBanner
        ? { providedBanner: this.providedBanner }
        : {}),
      // TODO: NET_REVENUE need to uncomment later
      // netRevenue: this.netRevenue,
    };
  }

  static of(app: Application, filter?: Record<string, any>): CashCountryFilter {
    return filter
      ? new CashCountryFilter(
          app,
          filter.filter && filter.filter.length > 0
            ? filter.filter.map((it: any) => recordToFilterModel(it))
            : [],
          filter.date
            ? DatesFilterModel.ofRecord(FilterId.EVENTS_DATE, filter.date)
            : undefined,
          filter.groupByFilter
            ? GroupByFilter.of(filter.groupByFilter)
            : new GroupByFilter(),
          filter.event,
          filter.startDay,
          filter.endDay,
          filter.goal,
          filter.providedBanner,
          filter.adRevenueMethod
          // TODO: NET_REVENUE need to uncomment later
          // typeof filter.netRevenue === "string"
          //   ? filter.netRevenue === "true"
          //   : filter.netRevenue,
        )
      : new CashCountryFilter(app);
  }
}

export class CashCountryHeaders {
  static init(
    lang: LangService,
    report: CashCountryFilter,
    reportDataType: ReportDataType
  ): Array<ReportHeader> {
    const filters = [
      FilterId.SEGMENT,
      FilterId.DEVICE_TYPE,
      FilterId.ATTRIBUTION_STATUS,
    ];
    const hasCpiNetw =
      !report.filter.some(({ id }: FilterModel) => filters.includes(id)) &&
      !intersection(report.groupByFilter.groupBy, filters).length;

    return [
      ...(![ReportDataType.TOTAL, ReportDataType.SUB_TOTAL].includes(
        reportDataType
      )
        ? [
            ReportHeaderUtil.createDate(
              lang,
              report.groupByFilter.aggregationPeriod
            ),
          ]
        : []),
      ...(report.groupByFilter.isNotEmptyGroupBy &&
      reportDataType !== ReportDataType.TOTAL
        ? ReportHeaderUtil.createGroupBy(lang, report.groupByFilter)
        : []),
      {
        text: lang("components.baseTable.adjustInstalls"),
        align: "end",
        value: `${ReportResultItem.PREFIX}adjustInstalls`,
        fractionDigits: 0,
        hasGradient: true,
      },
      {
        text: lang("components.baseTable.paidInstalls"),
        align: "end",
        value: `${ReportResultItem.PREFIX}paidInstalls`,
        fractionDigits: 0,
        hasGradient: true,
      },
      {
        text: lang("components.baseTable.organic"),
        align: "end",
        value: `${ReportResultItem.PREFIX}organic`,
        hasGradient: true,
      },
      {
        text: lang("components.baseTable.cpiPaid"),
        align: "end",
        value: `${ReportResultItem.PREFIX}cpiPaid`,
        hasGradient: true,
        reverseGradient: true,
      },
      ...(hasCpiNetw
        ? [
            {
              text: lang("components.baseTable.cpiNetw"),
              align: "end",
              value: `${ReportResultItem.PREFIX}cpiNetw`,
              hasGradient: true,
              reverseGradient: true,
            } as ReportHeader,
          ]
        : []),
      {
        text: lang("components.baseTable.eCPI"),
        align: "end",
        value: `${ReportResultItem.PREFIX}eCPI`,
        hasGradient: true,
        reverseGradient: true,
      },
      {
        text: lang("components.baseTable.arpu"),
        align: "end",
        value: `${ReportResultItem.PREFIX}arpu`,
        hasGradient: true,
        fractionDigits: 5,
      },
      {
        text: lang("components.baseTable.cashPaid"),
        align: "end",
        value: `${ReportResultItem.PREFIX}cashPaid`,
        hasGradient: true,
      },
      {
        text: lang("components.baseTable.cashTotal"),
        align: "end",
        value: `${ReportResultItem.PREFIX}cashTotal`,
        hasGradient: true,
      },
      {
        text: lang("components.baseTable.roiPaid"),
        align: "end",
        value: `${ReportResultItem.PREFIX}roiPaid`,
        hasGradient: true,
      },
      {
        text: lang("components.baseTable.roi"),
        align: "end",
        value: `${ReportResultItem.PREFIX}roi`,
        hasGradient: true,
      },
    ];
  }
}

export class CashCountryResultItem extends PredictedResultItem {
  constructor(row: ReportItemRowObject, filter: ReportFilter) {
    super();
    this.date = row.getByHeader("date");
    this.setFormattedDate(
      this.date,
      filter.date.from,
      filter.date.to,
      filter.groupByFilter.aggregationPeriod
    );
    this.setGroupByValue(filter.groupByFilter, row);
    this.data["adjustInstalls"] = row.getByHeader("adjustInstalls") ?? 0;
    this.data["paidInstalls"] = row.getByHeader("paidInstalls");
    this.data["organic"] = row.getByHeader("organic");
    this.data["cpiPaid"] = row.getByHeader("cpiPaid");
    this.data["cpiNetw"] = row.getByHeader("cpiNetw");
    this.data["eCPI"] = row.getByHeader("eCPI");
    this.data["arpu"] = row.getByHeader("arpu");
    this.data["cashPaid"] = row.getByHeader("cashPaid");
    this.data["cashTotal"] = row.getByHeader("cashTotal");
    this.data["roiPaid"] = row.getByHeader("roiPaid");
    this.data["roi"] = row.getByHeader("roi");
  }
}
