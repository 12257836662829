export class ParsingFullMetricsByApp {
  constructor(
    public application = "",
    public startDate = "",
    public endDate = "",
    public periodDays = 0,
    public metrics: Array<ParsingMetric> = []
  ) {}
}

export interface ParsingMetric {
  metric: string;
  matchRows: Array<ParsingMatchRow>;
}

export interface ParsingMatchRow {
  date: string;
  adjustValue: number;
  bigQueryValue: number;
  deltaValue: number;
  deltaPercent: number;
}
