export interface RevenueEventAppVersion {
  from: string;
  to: string;
}

export default class RevenueEventModel {
  constructor(
    public token = "",
    public type = "",
    public event: string | null,
    public appVersionFrom: string | null,
    public appVersionTo: string | null,
    public id?: number
  ) {}

  static ofArray(revenueEvents: Array<RevenueEventModel>) {
    return revenueEvents.map(
      (revenueEvent) =>
        new RevenueEventModel(
          revenueEvent.token,
          revenueEvent.type,
          revenueEvent.event,
          revenueEvent.appVersionFrom,
          revenueEvent.appVersionTo,
          revenueEvent.id
        )
    );
  }

  static ofSource(model: RevenueEventModel): RevenueEventModel {
    return new RevenueEventModel(
      model.token,
      model.type,
      model.event,
      model.appVersionFrom,
      model.appVersionTo,
      model.id
    );
  }
}
