




















































import { Component, Vue, Ref, Prop, Watch } from "vue-property-decorator";
import { cloneDeep, delay, difference } from "lodash";

import { VueAutocomplete } from "@/shared/types/ExtendedVueType";
import { Dictionary, FilterPreviewId } from "@/shared/models";
import AccountSettingsModel from "@/account-settings/models/AccountSettingsModel";
import { FilterBehaviorOption } from "@/account-settings/models/AccountSettingType";

@Component
export default class CustomAutocomplete extends Vue {
  @Prop() value!: Array<string> | string;
  @Prop({ default: false }) autofocus!: boolean;
  @Prop({ default: false }) isAttached!: boolean;
  @Prop({ default: false }) hasItemSlot!: boolean;
  @Prop({ default: false }) hasSelectionSlot!: boolean;
  @Prop({ default: false }) multiple!: boolean;
  @Prop() items!: Array<Dictionary>;
  @Prop() filterPreviewId?: FilterPreviewId;

  @Ref("autocomplete") readonly autocomplete!: VueAutocomplete;

  holderElement: HTMLElement | null = null;
  eager = false;
  top = true;
  search = "";

  get clickedFilterPreviewId() {
    return this.$store.state.reportStore.clickedFilterPreviewId;
  }

  get sortedItems(): Array<Dictionary> {
    if (!Array.isArray(this.value) || !this.value.length) {
      return this.items;
    }

    const selectedItems = this.items.filter(({ value }) =>
      this.value.includes(value)
    );
    const otherItems = difference(this.items, selectedItems);

    return selectedItems.concat(otherItems);
  }

  get accountSettings(): AccountSettingsModel {
    return this.$store.state.accountSettingsStore.accountSettings;
  }

  get clearable(): boolean {
    const found = this.accountSettings.specificFiltersBehavior.find(
      ({ value }: any) => value === this.filterPreviewId
    );

    if (found) {
      return found.options.includes(FilterBehaviorOption.CLEARABLE);
    }

    return this.accountSettings.defaultFiltersBehavior.includes(
      FilterBehaviorOption.CLEARABLE
    );
  }

  get clearSearchValueAfterSelect(): boolean {
    const found = this.accountSettings.specificFiltersBehavior.find(
      ({ value }: any) => value === this.filterPreviewId
    );

    if (found) {
      return found.options.includes(
        FilterBehaviorOption.CLEAR_SEARCH_VALUE_AFTER_SELECT
      );
    }

    return this.accountSettings.defaultFiltersBehavior.includes(
      FilterBehaviorOption.CLEAR_SEARCH_VALUE_AFTER_SELECT
    );
  }

  get closeAfterSelect(): boolean {
    const found = this.accountSettings.specificFiltersBehavior.find(
      ({ value }: any) => value === this.filterPreviewId
    );

    if (found) {
      return found.options.includes(FilterBehaviorOption.CLOSE_AFTER_SELECT);
    }

    return this.accountSettings.defaultFiltersBehavior.includes(
      FilterBehaviorOption.CLOSE_AFTER_SELECT
    );
  }

  @Watch("clickedFilterPreviewId")
  watchPreviewClick(previewId: FilterPreviewId) {
    if (previewId !== this.filterPreviewId) {
      return;
    }

    delay(() => {
      this.focus();
      this.activateMenu();
      this.$store.commit("setClickedFilterPreviewId");
    }, 100);
  }

  @Watch("search")
  watchSearch() {
    this.$emit("search", this.search);
  }

  mounted() {
    this.holderElement = this.autocomplete.$el as HTMLElement;
    this.eager = true;
    if (this.autofocus) {
      this.focus();
      this.activateMenu();
    }
  }

  onInput(event: Event) {
    this.$emit("input", event);
    this.clearSearch();
  }

  onFocus(event: Event) {
    if (!this.isAttached) {
      return;
    }

    const targetElement = event.target as HTMLElement;

    this.top =
      targetElement.getBoundingClientRect().top +
        targetElement.offsetHeight / 2 >
      window.innerHeight / 2;
  }

  clearSearch() {
    if (this.clearSearchValueAfterSelect) {
      this.search = "";
    }
  }

  isSelected(item: string): boolean {
    return Array.isArray(this.value)
      ? this.value.includes(item)
      : this.value === item;
  }

  toggleItem(item: string) {
    let valueCloned: Array<string> = cloneDeep(this.value as Array<string>);

    if (this.value.includes(item)) {
      valueCloned = (this.value as Array<string>).filter(
        (value) => value !== item
      );
    } else {
      valueCloned.push(item);
    }

    this.clearSearch();
    this.focus();
    this.$emit("input", valueCloned);
  }

  handleClickItem(item: string) {
    this.toggleItem(item);

    if (this.closeAfterSelect) {
      this.blur();
    }
  }

  blur() {
    this.autocomplete.blur();
  }

  focus() {
    this.autocomplete.focus();
  }

  activateMenu() {
    this.autocomplete.activateMenu();
  }

  async onBlur() {
    this.$emit("blur");

    if (this.multiple === false) {
      return;
    }

    // Need to clear search on blur, because search-input.sync doesn't work in vuetify
    setTimeout(() => {
      if (
        this.autocomplete.$el.querySelector("input[type=text]")?.id !==
        document.activeElement?.id
      ) {
        this.search = "";
      }
    }, 300);
  }
}
