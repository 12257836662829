










import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class FilterListItem extends Vue {
  @Prop({ default: "grey" }) color!: string;

  get borderColor(): string {
    return `border-color: ${
      this.$vuetify.theme.dark
        ? "rgba(255, 255, 255, 0.12)"
        : "rgba(0, 0, 0, 0.12)"
    }`;
  }
}
