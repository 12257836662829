export enum AppSection {
  AB_TESTS = "AB_TESTS",
  AB_TEST_SEGMENTS = "AB_TEST_SEGMENTS",
  AB_TESTS_GROUPS_SIZE_RECOMMENDER = "AB_TESTS_GROUPS_SIZE_RECOMMENDER",
  AD_REVENUE_EVENTS = "AD_REVENUE_EVENTS",
  AGGREGATORS = "AGGREGATORS",
  ALERT_SYSTEM = "ALERT_SYSTEM",
  APPLICATIONS = "APPLICATIONS",
  APPLICATION_BASIC_INFO = "APPLICATION_BASIC_INFO",
  APPLICATION_VALIDATION = "APPLICATION_VALIDATION",
  APPLICATION_FEATURES_CONTROL = "APPLICATION_FEATURES_CONTROL",
  BATCHES = "BATCHES",
  CUSTOM_DYNAMIC_SEGMENTS = "CUSTOM_DYNAMIC_SEGMENTS",
  CUSTOM_STATIC_SEGMENTS = "CUSTOM_STATIC_SEGMENTS",
  DEFAULT_CONFIG = "DEFAULT_CONFIG",
  DICTIONARIES_APP_VERSIONS = "DICTIONARIES_APP_VERSIONS",
  DICTIONARIES_OS_VERSIONS = "DICTIONARIES_OS_VERSIONS",
  DIVIDER = "DIVIDER",
  DIVIDER_SEGMENTS = "DIVIDER_SEGMENTS",
  EXPORT_USER_FLOW = "EXPORT_USER_FLOW",
  HEALTHCHECK_MONETIZATION_ALERT = "HEALTHCHECK_MONETIZATION_ALERT",
  EDGE_NODE = "EDGE_NODE",
  EXTERNAL_TESTS = "EXTERNAL_TESTS",
  EXTERNAL_TESTS_CONFIGURATION = "EXTERNAL_TESTS_CONFIGURATION",
  EXTERNAL_TEST_SEGMENTS = "EXTERNAL_TEST_SEGMENTS",
  FUNNELS = "FUNNELS",
  FUNNEL_SEGMENTS = "FUNNEL_SEGMENTS",
  FUNNEL_TEMPLATES = "FUNNEL_TEMPLATES",
  HEALTHCHECK_DATA_FLOW = "HEALTHCHECK_DATA_FLOW",
  HEALTHCHECK_JOBS_QUEUE = "HEALTHCHECK_JOBS_QUEUE",
  HEALTHCHECK_PARSING = "HEALTHCHECK_PARSING",
  HEALTHCHECK_SUMMARY = "HEALTHCHECK_SUMMARY",
  HEALTHCHECK_MARKETING_ALERT = "HEALTHCHECK_MARKETING_ALERT",
  HEALTHCHECK_METRIC_WEIGHT = "HEALTHCHECK_METRIC_WEIGHT",
  JURISDICTIONS = "JURISDICTIONS",
  MULTIAPP_DASHBOARDS = "MULTIAPP_DASHBOARDS",
  NETWORKS_MANAGEMENT = "NETWORKS_MANAGEMENT",
  PERMISSIONS_BROWSER = "PERMISSIONS_BROWSER",
  PLACEMENTS = "PLACEMENTS",
  PROVIDED_SEGMENTS = "PROVIDED_SEGMENTS",
  RELOAD_DICTIONARIES_CACHE = "RELOAD_DICTIONARIES_CACHE",
  RELOAD_NETWORKS_CACHE = "RELOAD_NETWORKS_CACHE",
  REPORT_TEMPLATES = "REPORT_TEMPLATES",
  SCENARIOS = "SCENARIOS",
  SINGLEAPP_DASHBOARDS = "SINGLEAPP_DASHBOARDS",
  SUBSCRIPTION_EVENTS = "SUBSCRIPTION_EVENTS",
  TARGETED_CONFIGS = "TARGETED_CONFIGS",
  USERS = "USERS",
  BANKS = "BANKS",
  COUNTERPARTIES = "COUNTERPARTIES",
  INVOICES = "INVOICES",
  TAX_PERCENT = "TAX_PERCENT",
}
