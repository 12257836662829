export default class NetworkIntegrationShortResponseModel {
  constructor(
    public id: number,
    public networkId: number,
    public networkName: string
  ) {}

  static of(
    model: NetworkIntegrationShortResponseModel
  ): NetworkIntegrationShortResponseModel {
    return new NetworkIntegrationShortResponseModel(
      model.id,
      model.networkId,
      model.networkName
    );
  }

  static ofArray(
    items: Array<NetworkIntegrationShortResponseModel>
  ): Array<NetworkIntegrationShortResponseModel> {
    return items.map((item) => NetworkIntegrationShortResponseModel.of(item));
  }
}
