export default class FromDataUtil {
  static appendFormDataPart(
    formData: FormData,
    payloadName: string,
    payloadPart?: string | Blob | File | null
  ) {
    if (payloadPart) {
      formData.append(payloadName, payloadPart);
    }
  }
}
