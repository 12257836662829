import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { AxiosResponse } from "axios";

import axios from "@/shared/plugins/axios";
import {
  ConfigsDiffResponseModel,
  ConfigsDiffParamsRequest,
  ConfigType,
} from "@/ab-tests/models/ConfigResponseModel";
import { ConfigPriorityResponseModel } from "@/ab-tests/models/ConfigPriorityResponseModel";

@Module
export default class ConfigsStore extends VuexModule {
  isLoadingConfigsDiff = false;
  configsDiff: Array<ConfigsDiffResponseModel> = [];

  @Mutation
  setLoadingConfigsDiff(payload: boolean) {
    this.isLoadingConfigsDiff = payload;
  }

  @Mutation
  setConfigsDiff({
    data,
    needUpdateConfigsDiff,
  }: {
    data: Array<ConfigsDiffResponseModel>;
    needUpdateConfigsDiff: boolean;
  }) {
    if (this.configsDiff.length && !needUpdateConfigsDiff) {
      data.forEach((item) => {
        if (item.diff.length) {
          const found = this.configsDiff.find((diffItem) =>
            item.configType === ConfigType.AB_TEST
              ? diffItem.configId === item.configId &&
                diffItem.segmentName === item.segmentName
              : diffItem.configId === item.configId
          );

          if (found) {
            found.diff = [...found.diff, ...item.diff];
          } else {
            this.configsDiff.push(item);
          }
        }
      });
    } else {
      this.configsDiff = ConfigsDiffResponseModel.ofArray(data);
    }
  }

  @Mutation
  clearConfigsDiff() {
    this.configsDiff = [];
  }

  @Action({ commit: "setConfigsDiff" })
  async loadConfigsDiff({
    params,
    needUpdateConfigsDiff,
  }: {
    params?: ConfigsDiffParamsRequest;
    needUpdateConfigsDiff?: boolean;
  } = {}) {
    this.context.commit("setLoadingConfigsDiff", true);
    return axios
      .get(
        `/api/ab-tests/app/${this.context.rootState.application.applicationId}/abtest/configs/diff`,
        { params, skipErrors: true } as any
      )
      .then((result: AxiosResponse<ConfigsDiffResponseModel>) => {
        return { data: result.data, needUpdateConfigsDiff };
      })
      .finally(() => {
        this.context.commit("setLoadingConfigsDiff", false);
      });
  }

  @Action
  async updatePriorityConfig({
    configType,
    payload,
  }: {
    configType: ConfigType;
    payload: { id: number; priority: number };
  }) {
    return axios
      .put(
        `/api/ab-tests/priorities/app/${this.context.rootState.application.applicationId}/type/${configType}`,
        payload
      )
      .then((result: AxiosResponse<ConfigPriorityResponseModel>) => {
        return result.data;
      });
  }
}
