import NetworkUrlResponseModel from "./NetworkUrlResponseModel";

export enum PaginationType {
  DATE_INTERVAL = "DATE_INTERVAL",
  DATE_INTERVAL_WITH_NEXT_URL = "DATE_INTERVAL_WITH_NEXT_URL",
  DATE_INTERVAL_WITH_OFFSET = "DATE_INTERVAL_WITH_OFFSET",
  DATE_INTERVAL_WITH_NEXT_TOKEN = "DATE_INTERVAL_WITH_NEXT_TOKEN",
  NEXT_TOKEN = "NEXT_TOKEN",
  NEXT_URL = "NEXT_URL",
  OFFSET = "OFFSET",
}

export enum HttpMethod {
  POST = "POST",
  GET = "GET",
  PUT = "PUT",
}

export enum RequestType {
  AUTH = "AUTH",
  PERSISTENT_AUTH = "PERSISTENT_AUTH",
  DATA_FETCH = "DATA_FETCH",
}

export default class NetworkUrlRequestModel {
  constructor(
    public networkIntegrationId = 0,
    public requestType = RequestType.AUTH,
    public url = "",
    public httpMethod = HttpMethod.GET,
    public pagination = new NetworkUrlPaginationModel(),
    public node?: string,
    public expirationDays?: number
  ) {}

  static ofSource(
    model: NetworkUrlRequestModel | NetworkUrlResponseModel
  ): NetworkUrlRequestModel {
    return new NetworkUrlRequestModel(
      model.networkIntegrationId,
      model.requestType,
      model.url,
      model.httpMethod,
      model.pagination
        ? NetworkUrlPaginationModel.of(model.pagination)
        : new NetworkUrlPaginationModel(),
      model.node,
      model.expirationDays
    );
  }

  static ofRequest(model: NetworkUrlRequestModel) {
    return {
      networkIntegrationId: model.networkIntegrationId,
      requestType: model.requestType,
      url: model.url,
      httpMethod: model.httpMethod,
      pagination: NetworkUrlPaginationModel.ofRequest(model.pagination),
      node: model.requestType === RequestType.AUTH ? model.node : undefined,
      expirationDays:
        model.requestType === RequestType.PERSISTENT_AUTH
          ? model.expirationDays
          : undefined,
    };
  }
}

export interface NetworkUrlPagination {
  type?: PaginationType;
  value?: string;
  includeEndDate?: boolean;
  nextKey?: string;
  minContentLength?: number;
  lengthPage?: number;
}

export class NetworkUrlPaginationModel implements NetworkUrlPagination {
  constructor(
    public type?: PaginationType,
    public value?: string,
    public includeEndDate = false,
    public nextKey?: string,
    public minContentLength?: number,
    public lengthPage?: number
  ) {}

  static of(model: NetworkUrlPaginationModel) {
    return model
      ? new NetworkUrlPaginationModel(
          model.type,
          model.value,
          model.includeEndDate,
          model.nextKey,
          model.minContentLength,
          model.lengthPage
        )
      : new NetworkUrlPaginationModel();
  }

  static ofRequest(
    model: NetworkUrlPaginationModel
  ): NetworkUrlPagination | null {
    return model.type
      ? {
          type: model.type,
          value: model.value,
          ...([
            PaginationType.DATE_INTERVAL,
            PaginationType.DATE_INTERVAL_WITH_NEXT_URL,
            PaginationType.DATE_INTERVAL_WITH_OFFSET,
            PaginationType.DATE_INTERVAL_WITH_NEXT_TOKEN,
          ].includes(model.type)
            ? { includeEndDate: model.includeEndDate }
            : {}),
          ...([
            PaginationType.DATE_INTERVAL_WITH_NEXT_URL,
            PaginationType.DATE_INTERVAL_WITH_NEXT_TOKEN,
          ].includes(model.type)
            ? { nextKey: model.nextKey }
            : {}),
          ...([
            PaginationType.DATE_INTERVAL_WITH_OFFSET,
            PaginationType.OFFSET,
          ].includes(model.type)
            ? { minContentLength: model.minContentLength }
            : {}),
          ...(model.type === PaginationType.DATE_INTERVAL_WITH_OFFSET
            ? { lengthPage: model.lengthPage }
            : {}),
        }
      : null;
  }
}
