import SegmentModel, {
  SegmentStatus,
  SegmentType,
  SegmentDividerType,
  SegmentProvidedType,
} from "./SegmentModel";
import { AccessType, FilterModel } from "@/shared/models";

export default class SegmentGroupModel {
  id?: number;
  applicationId?: string;
  name?: string;
  description?: string;
  status?: SegmentStatus;
  filter?: Array<FilterModel>;
  usersShare?: number;
  usersCount?: number;
  type?: SegmentType;
  ownerId?: string;
  accessType?: AccessType;
  dividerType?: SegmentDividerType;
  providedType?: SegmentProvidedType;
  sourceFileName?: string;
  sourceFilePath?: string;
  segments?: Array<SegmentModel>;
}
