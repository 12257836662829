export enum InvoiceChangeHistoryStatus {
  EDITED = "EDITED",
  CREATED = "CREATED",
}

interface ChangeModel {
  fieldName: string;
  oldValue: any;
  newValue: any;
}

export default class InvoiceChangeHistoryResponseModel {
  constructor(
    public author: string,
    public date: string,
    public status: InvoiceChangeHistoryStatus,
    public changes: Array<ChangeModel>
  ) {}

  static of(
    model: InvoiceChangeHistoryResponseModel
  ): InvoiceChangeHistoryResponseModel {
    return new InvoiceChangeHistoryResponseModel(
      model.author,
      model.date,
      model.status,
      model.changes
    );
  }

  static ofArray(
    items: Array<InvoiceChangeHistoryResponseModel>
  ): Array<InvoiceChangeHistoryResponseModel> {
    return items.map((item) => InvoiceChangeHistoryResponseModel.of(item));
  }
}
