import RuleModel from "@/ab-tests/models/RuleModel";
import { ConfigsStatus } from "@/ab-tests/models/ConfigsStatusModel";
import JsonUtil from "./JsonUtil";
import {
  AbTestMetricType,
  AbTestResultModel,
  AbTestResultType,
} from "@/ab-tests/models/AbTestConfigurationModel";

export default class ConfigUtil {
  static maxDateInRules(rules: Array<RuleModel>) {
    const maxDate = rules
      .map((it) => it.installDates && it.installDates.maxInstallDate)
      .filter((it) => !!it);
    if (maxDate && maxDate.length > 0) {
      return maxDate[maxDate.length - 1];
    }
  }

  static getCurrentDate() {
    return new Date().toISOString().split("T")[0];
  }

  static getStatusColor(status: ConfigsStatus): string {
    return {
      STATISTICS_RECALCULATED: "success--text",
      STATISTICS_RECALCULATION: "success--text",
      STARTED: "light-green--text",
      GROUPS_DISTRIBUTED: "light-green--text",
      INIT: "cyan--text",
      SCHEDULED: "light-blue--text",
      DISABLED: "orange--text",
      CANCELED: "error--text",
      CONFIG_DISTRIBUTION_FINISHED: "blue-grey--text",
    }[status];
  }

  static hasDiffBetweenConfigs(
    initResponseConfig: string,
    changeableResponseConfig: string
  ): boolean {
    const initConfigFromJson = JsonUtil.formattingFromJson(initResponseConfig);
    const changeableConfigFromJson = JsonUtil.formattingFromJson(
      changeableResponseConfig
    );

    return (
      initConfigFromJson.length !== changeableConfigFromJson.length ||
      !!initConfigFromJson.filter((initItem: Record<string, any>) => {
        return !changeableConfigFromJson.some(
          (changeableItem: Record<string, any>) =>
            changeableItem.key === initItem.key &&
            (changeableItem.type === "array" || changeableItem.type === "object"
              ? JsonUtil.formattingToString(changeableItem.value) ===
                JsonUtil.formattingToString(initItem.value)
              : changeableItem.value === initItem.value) &&
            changeableItem.desc === initItem.desc &&
            changeableItem.type === initItem.type
        );
      }).length
    );
  }

  static getTestGroupPerformance(
    targetMetric: AbTestMetricType,
    groupResult: Record<AbTestMetricType, number>
  ): AbTestResultType {
    const target: number = groupResult[targetMetric];
    const nonTarget: Array<number> = Object.entries(groupResult).flatMap(
      ([metric, value]) => (metric === targetMetric ? [] : [value])
    );
    let targetMetricEffect = AbTestResultType.NEUTRAL;

    if (target !== 0) {
      targetMetricEffect =
        target > 0 ? AbTestResultType.POSITIVE : AbTestResultType.NEGATIVE;
    }

    // Non target metrics conditions
    // All neutral
    if (nonTarget.every((value) => !value)) {
      return {
        [AbTestResultType.POSITIVE]: AbTestResultType.POSITIVE,
        [AbTestResultType.NEUTRAL]: AbTestResultType.NEUTRAL,
        [AbTestResultType.NEGATIVE]: AbTestResultType.NEGATIVE,
      }[targetMetricEffect];
    }

    // All negative
    if (nonTarget.every((value) => value < 0)) {
      return {
        [AbTestResultType.POSITIVE]: AbTestResultType.INCONSISTENT_POSITIVE,
        [AbTestResultType.NEUTRAL]: AbTestResultType.NEGATIVE,
        [AbTestResultType.NEGATIVE]: AbTestResultType.NEGATIVE,
      }[targetMetricEffect];
    }

    // Without negative
    if (nonTarget.every((value) => value >= 0)) {
      return {
        [AbTestResultType.POSITIVE]: AbTestResultType.POSITIVE,
        [AbTestResultType.NEUTRAL]: AbTestResultType.UNCERTAIN,
        [AbTestResultType.NEGATIVE]: AbTestResultType.UNCERTAIN,
      }[targetMetricEffect];
    }

    // Without neutral
    if (nonTarget.every((value) => value !== 0)) {
      return {
        [AbTestResultType.POSITIVE]: AbTestResultType.INCONSISTENT_POSITIVE,
        [AbTestResultType.NEUTRAL]: AbTestResultType.UNCERTAIN,
        [AbTestResultType.NEGATIVE]: AbTestResultType.UNCERTAIN,
      }[targetMetricEffect];
    }

    // Without positive
    return {
      [AbTestResultType.POSITIVE]: AbTestResultType.INCONSISTENT_POSITIVE,
      [AbTestResultType.NEUTRAL]: AbTestResultType.NEGATIVE,
      [AbTestResultType.NEGATIVE]: AbTestResultType.NEGATIVE,
    }[targetMetricEffect];
  }

  static getConfigResultType(
    targetMetric: AbTestMetricType,
    testResult: AbTestResultModel
  ): AbTestResultType {
    const GroupsPerformance: Array<AbTestResultType> = Object.values(
      testResult.groups
    ).map((groupResult) =>
      this.getTestGroupPerformance(targetMetric, groupResult)
    );

    if (GroupsPerformance.includes(AbTestResultType.POSITIVE)) {
      return AbTestResultType.POSITIVE;
    }

    if (
      GroupsPerformance.includes(AbTestResultType.INCONSISTENT_POSITIVE) ||
      GroupsPerformance.includes(AbTestResultType.UNCERTAIN)
    ) {
      return AbTestResultType.UNCERTAIN;
    }

    if (GroupsPerformance.includes(AbTestResultType.NEGATIVE)) {
      return AbTestResultType.NEGATIVE;
    }

    return AbTestResultType.NEUTRAL;
  }
}
