import {
  AggregationPeriod,
  ReportResultItem,
  CohortFilter,
  ReportType,
  GroupByFilter,
  ReportHeader,
  ReportDataType,
  RevenueReportFilterExtension,
  NetRevenueReportFilterExtension,
  TimeSpentReportType,
  TimeSpentTypeReportFilterExtension,
  ReportItemRowObject,
} from "@/reports/models";
import {
  DatesFilterModel,
  FilterId,
  FilterPreviewId,
  recordToFilterModel,
  FilterModel,
  FilterPreview,
  DictionaryType,
  Application,
} from "@/shared/models";
import { LangService } from "@/shared/types/LangType";

const ARPU_PRECISION = 4;

export class CohortAnalysisFilter
  extends CohortFilter
  implements
    RevenueReportFilterExtension,
    NetRevenueReportFilterExtension,
    TimeSpentTypeReportFilterExtension
{
  revenue: Array<string>;
  netRevenue: boolean;

  constructor(
    app: Application,
    filter?: Array<FilterModel>,
    date?: DatesFilterModel,
    dayLimit?: number,
    revenue: Array<string> = [],
    netRevenue = true,
    public timeSpentType = TimeSpentReportType.TIME_SPENT
  ) {
    super(
      ReportType.COHORT_ANALYSIS,
      app,
      filter,
      date,
      new GroupByFilter(
        AggregationPeriod.DAY,
        [FilterId.SOURCE, FilterId.CAMPAIGN],
        false
      ),
      dayLimit
    );
    this.revenue = revenue;
    this.netRevenue = netRevenue;
  }

  get previews(): Array<FilterPreview> {
    return [
      ...super.previews,
      ...(this.revenue.length
        ? [
            {
              id: FilterPreviewId.REVENUE,
              value: this.revenue,
              dictionary: DictionaryType.REVENUES,
            },
          ]
        : []),
      {
        id: FilterPreviewId.TIME_SPENT_TYPE,
        value: this.timeSpentType,
      },
      {
        id: FilterPreviewId.NET_REVENUE,
        value: this.netRevenue,
      },
    ];
  }

  static of(
    app: Application,
    filter?: Record<string, any>
  ): CohortAnalysisFilter {
    return filter
      ? new CohortAnalysisFilter(
          app,
          filter.filter && filter.filter.length > 0
            ? filter.filter.map((it: any) => recordToFilterModel(it))
            : [],
          filter.date
            ? DatesFilterModel.ofRecord(
                FilterId.ATTRIBUTION_DATE_VALUE,
                filter.date
              )
            : undefined,
          filter.dayLimit
            ? Number.parseInt(filter.dayLimit as string)
            : undefined,
          filter.revenue,
          typeof filter.netRevenue === "string"
            ? filter.netRevenue === "true"
            : filter.netRevenue,
          filter.timeSpentType
        )
      : new CohortAnalysisFilter(app);
  }

  toRequestQuery(): Record<string, any> {
    return {
      ...super.toRequestQuery(),
      ...(this.revenue ? { revenue: this.revenue } : {}),
      netRevenue: this.netRevenue,
      timeSpentType: this.timeSpentType,
    };
  }
}

export class CohortAnalysisHeaders {
  static init(
    lang: LangService,
    report: CohortAnalysisFilter,
    reportDataType: ReportDataType
  ): Array<ReportHeader> {
    if (
      [ReportDataType.TOTAL, ReportDataType.SUB_TOTAL].includes(reportDataType)
    ) {
      return [
        ...(reportDataType === ReportDataType.SUB_TOTAL &&
        report.groupByFilter.groupBy.length
          ? report.groupByFilter.groupBy.map((filterId: FilterId) => {
              const name = filterId.toLowerCase();

              return {
                text: lang(`components.groupBy.${name}`),
                align: "start",
                value: `${ReportResultItem.PREFIX}${name}`,
                width: 180,
                hasExcludeFeature: true,
              } as ReportHeader;
            })
          : []),
        {
          text: lang("components.baseTable.totalUsers"),
          align: "end",
          value: `${ReportResultItem.PREFIX}totalUsers`,
        },
        {
          text: lang("components.baseTable.installed"),
          align: "end",
          value: `${ReportResultItem.PREFIX}installed`,
        },
        {
          text: lang("components.baseTable.reattributed"),
          align: "end",
          value: `${ReportResultItem.PREFIX}reattributed`,
        },
        {
          text: lang("components.baseTable.maxObservedDays"),
          align: "end",
          value: `${ReportResultItem.PREFIX}maxObservedDays`,
        },
        {
          text: lang("components.baseTable.payingUsers"),
          align: "end",
          value: `${ReportResultItem.PREFIX}payingUsers`,
        },
        {
          text: lang("components.baseTable.payingConv"),
          align: "end",
          value: `${ReportResultItem.PREFIX}payingConv`,
          postfix: "%",
        },
        {
          text: lang("components.baseTable.revenueValue"),
          align: "end",
          value: `${ReportResultItem.PREFIX}revenueValue`,
        },
        {
          text: lang("components.baseTable.adRevenue"),
          align: "end",
          value: `${ReportResultItem.PREFIX}adRevenue`,
          postfix: "%",
        },
        {
          text: lang("components.baseTable.arpu"),
          align: "end",
          value: `${ReportResultItem.PREFIX}arpu`,
          fractionDigits: ARPU_PRECISION,
        },
        {
          text: lang("components.baseTable.arppu"),
          align: "end",
          value: `${ReportResultItem.PREFIX}arppu`,
          fractionDigits: ARPU_PRECISION,
        },
        {
          text: lang("components.baseTable.spendValue"),
          align: "end",
          value: `${ReportResultItem.PREFIX}spendValue`,
        },
        {
          text: lang("components.baseTable.profitUsd"),
          align: "end",
          value: `${ReportResultItem.PREFIX}profit`,
        },
        {
          text: lang("components.baseTable.eCpi"),
          align: "end",
          value: `${ReportResultItem.PREFIX}eCpi`,
        },
        {
          text: lang("components.baseTable.roas"),
          align: "end",
          value: `${ReportResultItem.PREFIX}roas`,
          postfix: "%",
        },
        {
          text: lang("components.baseTable.retention"),
          align: "end",
          value: `${ReportResultItem.PREFIX}retention`,
          postfix: "%",
        },
        {
          text: lang("components.baseTable.playtime"),
          align: "end",
          value: `${ReportResultItem.PREFIX}playtime`,
        },
      ];
    }

    const postfix = [
      ReportDataType.RETENTION_RATE_COHORT,
      ReportDataType.ROAS_N_DAY_COHORT,
    ].includes(reportDataType)
      ? "%"
      : undefined;

    return [
      {
        text: lang("components.baseTable.cohortDate"),
        align: "center",
        value: "date",
        width: 100,
        sticky: true,
      },
      {
        text: lang("components.baseTable.installs"),
        align: "end",
        value: "installs",
        width: 75,
        fractionDigits: 0,
      },
      ...Array.from(new Array(report.dayLimit)).map((_, index) => {
        const value = `${ReportResultItem.PREFIX}${index.toString()}`;

        return {
          text: `Day ${index && index < 10 ? 0 : ""}${index}`,
          align: "end",
          value,
          width: 60,
          hasGradient: true,
          postfix,
          ...(reportDataType === ReportDataType.CUMULATED_ARPU_COHORT
            ? { fractionDigits: ARPU_PRECISION }
            : {}),
        } as ReportHeader;
      }),
    ];
  }
}

export class CATotalResultItem extends ReportResultItem {
  constructor(row: ReportItemRowObject) {
    super();

    this.data.totalUsers = row.getByHeader("totalUsers");
    this.data.installed = row.getByHeader("installed");
    this.data.reattributed = row.getByHeader("reattributed");
    this.data.maxObservedDays = row.getByHeader("maxObservedDays");
    this.data.payingUsers = row.getByHeader("payingUsers");
    this.data.payingConv = row.getByHeader("payingConv");
    this.data.revenueValue = row.getByHeader("revenueValue");
    this.data.adRevenue = row.getByHeader("adRevenue");
    this.data.arpu = row.getByHeader("arpu");
    this.data.arppu = row.getByHeader("arppu");
    this.data.spendValue = row.getByHeader("spendValue");
    this.data.profit = row.getByHeader("profit");
    this.data.eCpi = row.getByHeader("eCpi");
    this.data.roas = row.getByHeader("roas");
    this.data.retention = row.getByHeader("retention");
    this.data.playtime = row.getByHeader("playtime");
  }
}

export class CASubTotalResultItem extends CATotalResultItem {
  constructor(row: ReportItemRowObject) {
    super(row);

    this.data.source = row.getByHeader("source");
    this.data.campaign = row.getByHeader("campaign");
  }
}

export class CADauDynamicsResultItem extends ReportResultItem {
  constructor(row: ReportItemRowObject) {
    super();

    this.date = row.getByHeader("date");
    this.data.installed = row.getByHeader("installed");
    this.data.reattributed = row.getByHeader("reattributed");
    this.data.activeUsers = row.getByHeader("activeUsers");
  }
}

export class CARevenueSpendingsDailyDynamicsResultItem extends ReportResultItem {
  constructor(row: ReportItemRowObject) {
    super();

    this.date = row.getByHeader("date");
    this.data.adRevenue = row.getByHeader("adRevenue");
    this.data.inAppRevenue = row.getByHeader("inAppRevenue");
    this.data.subscriptionRevenue = row.getByHeader("subscriptionRevenue");
    this.data.spend = row.getByHeader("spend");
  }
}

export class CACumulatedRevenueSpendingsProfitResultItem extends ReportResultItem {
  constructor(row: ReportItemRowObject) {
    super();

    this.date = row.getByHeader("date");
    this.data.adRevenue = row.getByHeader("adRevenue");
    this.data.inAppRevenue = row.getByHeader("inAppRevenue");
    this.data.subscriptionRevenue = row.getByHeader("subscriptionRevenue");
    this.data.spend = row.getByHeader("spend");
    this.data.profit = row.getByHeader("profit");
  }
}

export class CACumulatedArpuStructureResultItem extends ReportResultItem {
  constructor(row: ReportItemRowObject) {
    super();

    this.data.day = row.getByHeader("day");
    this.data.adArpu = this.parseFloat(row.getByHeader("adArpu"), 4);
    this.data.inAppArpu = this.parseFloat(row.getByHeader("inAppArpu"), 4);
    this.data.subscriptionArpu = this.parseFloat(
      row.getByHeader("subscriptionArpu"),
      4
    );
    this.data.adArpuPercent = this.convertToPercent(this.data.adArpu);
    this.data.inAppArpuPercent = this.convertToPercent(this.data.inAppArpu);
    this.data.subscriptionArpuPercent = this.convertToPercent(
      this.data.subscriptionArpu
    );
  }

  get sumOfMetrics(): number {
    return (
      (this.data.adArpu ?? 0) +
      (this.data.inAppArpu ?? 0) +
      (this.data.subscriptionArpu ?? 0)
    );
  }

  convertToPercent(value: number) {
    return this.parseFloat(((value * 100) / this.sumOfMetrics).toString(), 2);
  }
}

export class CACumulatedArpuResultItem extends ReportResultItem {
  constructor(row: ReportItemRowObject) {
    super();

    this.data.day = row.getByHeader("day");
    this.data.cumArpu = row.getByHeader("cumArpu");
    this.data.eCpi = row.getByHeader("eCpi");
    this.data.installs = row.getByHeader("installs");
    this.data.cumRevenue = row.getByHeader("cumRevenue");
  }
}

export class CARoasNDayResultItem extends ReportResultItem {
  constructor(row: ReportItemRowObject) {
    super();

    this.data.day = row.getByHeader("day");
    this.data.roi = row.getByHeader("roi");
    this.data.paybackThreshold = row.getByHeader("paybackThreshold");
  }
}

export class CARetentionRateResultItem extends ReportResultItem {
  constructor(row: ReportItemRowObject) {
    super();

    this.data.day = row.getByHeader("day");
    this.data.retentionRate = row.getByHeader("retentionRate");
    this.data.retainedUsers = row.getByHeader("retainedUsers");
    this.data.installs = row.getByHeader("installs");
  }
}

export class CACumulatedPlaytimePerActiveUserResultItem extends ReportResultItem {
  constructor(row: ReportItemRowObject) {
    super();

    this.data.day = row.getByHeader("day");
    this.data.playtime = row.getByHeader("playtime");
  }
}
