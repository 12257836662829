import ApplicationResponseModel, {
  EnvironmentModel,
} from "./ApplicationResponseModel";
import KeyUtil from "@/shared/utils/KeyUtil";

export default class AbTestResponseModel extends ApplicationResponseModel {
  static readonly REG_EXP_FOR_ANALYTICS_SEGMENT_NAME = new RegExp('[,"]', "i");
  static readonly RESTRICTED_SYMBOLS_FOR_ANALYTICS_SEGMENT_NAME = ', "';
  static AB_TEST_CONTROL_SEGMENT_POSTFIX = "-controlab";
  segmentName: string;
  key?: string;
  isUniqueSegmentName?: boolean;

  constructor(
    segmentName = "",
    stage?: EnvironmentModel,
    production?: EnvironmentModel,
    id?: number,
    key?: string,
    isUniqueSegmentName = true
  ) {
    super(stage, production, id);
    this.key = key ? key : KeyUtil.generateKey();
    this.segmentName = segmentName;
    this.isUniqueSegmentName = isUniqueSegmentName;
  }

  static of(model: AbTestResponseModel): AbTestResponseModel {
    return new AbTestResponseModel(
      model.segmentName,
      EnvironmentModel.of(model.stage),
      EnvironmentModel.of(model.production),
      model.id,
      model.key
    );
  }

  static ofArray(abTestResponses: Array<AbTestResponseModel>) {
    return abTestResponses.map(
      (item) =>
        new AbTestResponseModel(
          item.segmentName,
          item.stage,
          item.production,
          item.id,
          item.key
        )
    );
  }
}
