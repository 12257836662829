import AccountGroupApplicationResponseModel from "./AccountGroupApplicationResponseModel";

export default class AccountGroupApplicationRequestModel {
  constructor(
    public networkIntegrationId = 0,
    public accountGroupId = 0,
    public networkApplicationId = "",
    public applicationId = "",
    public aliasName?: string
  ) {}

  static of(
    model:
      | AccountGroupApplicationRequestModel
      | AccountGroupApplicationResponseModel
  ): AccountGroupApplicationRequestModel {
    return new AccountGroupApplicationRequestModel(
      model.networkIntegrationId,
      model.accountGroupId,
      model.networkApplicationId,
      model.applicationId,
      model.aliasName || undefined
    );
  }

  static ofRequest({
    networkIntegrationId,
    accountGroupId,
    networkApplicationId,
    aliasName,
  }: AccountGroupApplicationRequestModel): {
    networkIntegrationId: number;
    accountGroupId: number;
    networkApplicationId: string;
    aliasName?: string;
  } {
    return {
      networkIntegrationId,
      accountGroupId,
      networkApplicationId,
      aliasName,
    };
  }
}
