import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { AxiosResponse } from "axios";

import axios from "@/shared/plugins/axios";
import BatchResponseModel from "@/application-manager/models/BatchResponseModel";
import BatchRequestModel from "@/application-manager/models/BatchRequestModel";

@Module
export default class ScenarioStore extends VuexModule {
  isLoading = false;
  isSavingInProgress = false;
  batches: Array<BatchResponseModel> = [];
  batch: BatchResponseModel | null = null;

  @Mutation
  setBatches(payload: Array<BatchResponseModel>) {
    this.batches = BatchResponseModel.ofArray(payload);
  }

  @Mutation
  setBatch(payload: BatchResponseModel) {
    this.batch = BatchResponseModel.ofSource(payload);
  }

  @Mutation
  setBatchesAfterChangeDefault(batchId: number) {
    this.batches = this.batches.map((item) => {
      if (Number(item.id) === Number(batchId)) {
        return {
          ...item,
          isDefault: true,
        };
      }

      return { ...item, isDefault: false };
    });
  }

  @Mutation
  setBatchesLoading(payload: boolean) {
    this.isLoading = payload;
  }

  @Mutation
  setIsSavingInProgress(payload: boolean) {
    this.isSavingInProgress = payload;
  }

  @Mutation
  clearBatches() {
    this.batches = [];
  }

  @Action({ commit: "setBatches" })
  async loadBatches() {
    this.context.commit("setBatchesLoading", true);

    return axios
      .get("/api/infra/batches")
      .then((result: AxiosResponse<Array<BatchResponseModel>>) => {
        return result.data;
      })
      .finally(() => {
        this.context.commit("setBatchesLoading", false);
      });
  }

  @Action({ commit: "setBatch" })
  async loadBatch(batchId: number) {
    this.context.commit("setBatchesLoading", true);

    return axios
      .get(`/api/infra/batches/${batchId}`)
      .then((result: AxiosResponse<Array<BatchResponseModel>>) => {
        return result.data;
      })
      .finally(() => {
        this.context.commit("setBatchesLoading", false);
      });
  }

  @Action
  async createBatch(payload: BatchRequestModel) {
    this.context.commit("setIsSavingInProgress", true);

    return axios
      .post("/api/infra/batches", payload)
      .then((result: AxiosResponse<BatchResponseModel>) => {
        return result.data;
      })
      .finally(() => {
        this.context.commit("setIsSavingInProgress", false);
      });
  }

  @Action
  async updateBatch(payload: BatchRequestModel) {
    this.context.commit("setIsSavingInProgress", true);

    return axios
      .put("/api/infra/batches", payload)
      .then((result: AxiosResponse<BatchResponseModel>) => {
        return result.data;
      })
      .finally(() => {
        this.context.commit("setIsSavingInProgress", false);
      });
  }

  @Action
  async deleteBatch(batchId: number) {
    return axios.delete(`/api/infra/batches/${batchId}`).then(() => {
      this.context.dispatch("loadBatches");
    });
  }

  @Action({ commit: "setBatchesAfterChangeDefault" })
  async changeDefaultBatch(batchId: number) {
    return axios
      .put(`/api/infra/batches/${batchId}/default`, {})
      .then(() => batchId);
  }
}
