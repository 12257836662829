import { ReportType } from "@/reports/models/ReportType";
import {
  DatesFilterModel,
  FilterId,
  recordToFilterModel,
  FilterModel,
  Application,
  AdRevenueMethod,
  FilterPreview,
  FilterPreviewId,
} from "@/shared/models";
import { LangService } from "@/shared/types/LangType";
import {
  GroupByType,
  ReportHeader,
  ReportHeaderUtil,
} from "@/reports/models/ReportHeader";
import { ReportFilter, ReportResultItem } from "@/reports/models/Report";
import { ReportDataType } from "./ReportVisualization";
import { CohortFilter, CohortHeaders } from "./CohortReport";
import { GroupByFilter, AggregationPeriod } from "./GroupByFilter";
import {
  AdRevenueMethodReportFilterExtension,
  NetRevenueReportFilterExtension,
} from "./ReportFilterExtension";
import { ReportItemRowObject } from "./ReportItemRowObject";

const DAYS = [1, 3, 7, 14, 30, 60];

export class PayingUsersConversionHeaders {
  static init(
    lang: LangService,
    report: PayingUsersConversionFilter,
    reportDataType: ReportDataType,
    groupBy: GroupByType
  ): Array<ReportHeader> {
    return [
      ...(reportDataType !== ReportDataType.TOTAL
        ? [
            ReportHeaderUtil.createDate(
              lang,
              report.groupByFilter.aggregationPeriod
            ),
          ]
        : []),
      {
        text: lang("components.baseTable.paidInstalls"),
        align: "end",
        value: ReportResultItem.PREFIX + "installs",
        fractionDigits: 0,
        width: 100,
      },
      ...this.generateHeaders(
        [
          ["PU"],
          ["PU_percents", CohortHeaders.PRECISION],
          ["PC"],
          ["APPPU", CohortHeaders.PRECISION],
          ["IAP", CohortHeaders.PRECISION],
          ["TOT", CohortHeaders.PRECISION],
        ],
        lang,
        groupBy
      ),
    ];
  }

  private static generateHeaders(
    names: Array<[string, number?]>,
    lang: LangService,
    groupBy: GroupByType
  ): Array<ReportHeader> {
    const result: Array<ReportHeader> = [];

    if (groupBy === GroupByType.METRICS) {
      names.forEach(([name, precision]) => {
        result.push(
          ...DAYS.map(
            (day) =>
              ({
                text: lang(`components.baseTable.${name}`, day),
                align: "end",
                value: `${ReportResultItem.PREFIX}${name}_${day}`,
                hasGradient: true,
                groupBy: {
                  border: {
                    left: day === DAYS[0],
                    right:
                      name === names[names.length - 1][0] &&
                      day === DAYS[DAYS.length - 1],
                  },
                  hasBackground: names
                    .map((item) => item[0])
                    .filter((_, index) => index % 2 === 0)
                    .includes(name),
                },
                fractionDigits: precision,
                width: 110,
              } as ReportHeader)
          )
        );
      });
    } else {
      DAYS.forEach((day) => {
        result.push(
          ...names.map(
            ([name, precision]) =>
              ({
                text: lang(`components.baseTable.${name}`, day),
                align: "end",
                value: `${ReportResultItem.PREFIX}${name}_${day}`,
                hasGradient: true,
                groupBy: {
                  border: {
                    left: name === names[0][0],
                    right:
                      name === names[names.length - 1][0] &&
                      day === DAYS[DAYS.length - 1],
                  },
                  hasBackground: DAYS.filter(
                    (_, index) => index % 2 === 0
                  ).includes(day),
                },
                fractionDigits: precision,
                width: 110,
              } as ReportHeader)
          )
        );
      });
    }

    return result;
  }
}

export class PayingUsersConversionFilter
  extends CohortFilter
  implements
    AdRevenueMethodReportFilterExtension,
    NetRevenueReportFilterExtension
{
  constructor(
    app: Application,
    filter?: Array<FilterModel>,
    date?: DatesFilterModel,
    public netRevenue = true,
    public adRevenueMethod = AdRevenueMethod.PRICED
  ) {
    super(
      ReportType.PAYING_USERS_CONVERSION,
      app,
      filter,
      date,
      new GroupByFilter(AggregationPeriod.DAY, [], false),
      61
    );
  }

  static of(
    app: Application,
    filter?: Record<string, any>
  ): PayingUsersConversionFilter {
    return filter
      ? new PayingUsersConversionFilter(
          app,
          filter.filter?.length
            ? filter.filter.map((it: any) => recordToFilterModel(it))
            : [],
          filter.date
            ? DatesFilterModel.ofRecord(
                FilterId.ATTRIBUTION_DATE_VALUE,
                filter.date
              )
            : undefined,
          typeof filter.netRevenue === "string"
            ? filter.netRevenue === "true"
            : filter.netRevenue
        )
      : new PayingUsersConversionFilter(app);
  }

  toRequestQuery(): Record<string, any> {
    return {
      ...super.toRequestQuery(),
      netRevenue: this.netRevenue,
    };
  }

  get previews(): Array<FilterPreview> {
    return [
      ...super.previews,
      ...(this.netRevenue
        ? [
            {
              id: FilterPreviewId.NET_REVENUE,
              value: this.netRevenue,
            },
          ]
        : []),
    ];
  }
}

export class PayingUsersConversionResultItem extends ReportResultItem {
  constructor(row: ReportItemRowObject, filter: ReportFilter) {
    super();

    const { groupByFilter } = filter as PayingUsersConversionFilter;

    if (row.getByHeader("installDate") || row.getByHeader("date")) {
      this.date = row.getByHeader("installDate") || row.getByHeader("date");
      this.setFormattedDate(
        this.date,
        filter.date.from,
        filter.date.to,
        groupByFilter.aggregationPeriod
      );
    }

    this.data["installs"] = row.getByHeader("installs") ?? 0;
    this.data = {
      ...this.data,
      ...this.generateResultItemData("PU", row),
      ...this.generateResultItemData("PU_percents", row),
      ...this.generateResultItemData("PC", row),
      ...this.generateResultItemData("APPPU", row),
      ...this.generateResultItemData("IAP", row),
      ...this.generateResultItemData("TOT", row),
    };
  }

  private generateResultItemData(
    name: string,
    row: ReportItemRowObject
  ): Record<string, number> {
    return DAYS.reduce(
      (result, number) => ({
        ...result,
        [`${name}_${number}`]:
          row.getByHeader(`${name}_${number}`) ?? undefined,
      }),
      {}
    );
  }
}
