import ExecutionChainElementResponseModel from "./ExecutionChainElementResponseModel";

export default class BatchExecutionChainResponseModel {
  constructor(
    public batchId: number,
    public name: string,
    public description: string,
    public executionChains: Array<ExecutionChain>,
    public batchExecutionId?: number,
    public scenariosComplete?: number,
    public stepsComplete?: number,
    public totalSteps?: number
  ) {}

  static ofSource(
    model: BatchExecutionChainResponseModel
  ): BatchExecutionChainResponseModel {
    return new BatchExecutionChainResponseModel(
      model.batchId,
      model.name,
      model.description,
      model.executionChains,
      model.batchExecutionId,
      model.scenariosComplete,
      model.stepsComplete,
      model.totalSteps
    );
  }

  static ofArray(items: Array<BatchExecutionChainResponseModel>) {
    return items.map(
      (item) =>
        new BatchExecutionChainResponseModel(
          item.batchId,
          item.name,
          item.description,
          item.executionChains,
          item.batchExecutionId,
          item.scenariosComplete,
          item.stepsComplete,
          item.totalSteps
        )
    );
  }
}

export interface ExecutionChain {
  application: any;
  batchId: number;
  executionChainElements: Array<ExecutionChainElementResponseModel>;
  executionId: number;
  platform: string;
  profileId: number;
  scenario: ExecutionScenario;
  stepsComplete: number;
  totalSteps: number;
}

export interface ExecutionScenario {
  application: string;
  id: number;
  name: string;
  description: string;
  commands: Array<ScriptCommand>;
  platform: string;
}

export interface ScriptCommand {
  command: string;
  method: string;
  params: Record<string, string>;
}
