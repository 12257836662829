export default class CounterpartyNetworkResponseModel {
  constructor(
    public id: number,
    public counterpartyId: number,
    public name: string,
    public dateFrom: string,
    public dateTo: string
  ) {}

  static of(
    model: CounterpartyNetworkResponseModel
  ): CounterpartyNetworkResponseModel {
    return new CounterpartyNetworkResponseModel(
      model.id,
      model.counterpartyId,
      model.name,
      model.dateFrom,
      model.dateTo
    );
  }

  static ofArray(
    items: Array<CounterpartyNetworkResponseModel>
  ): Array<CounterpartyNetworkResponseModel> {
    return items.map((item) => CounterpartyNetworkResponseModel.of(item));
  }
}
