
























import { Component, Prop, Vue } from "vue-property-decorator";

import MenuUtil from "@/shared/utils/MenuUtil";

@Component
export default class AccountingPortalMenu extends Vue {
  @Prop() applicationId!: string;

  get accountingPortalMenuItems(): Array<Record<string, string>> {
    return MenuUtil.getAccountingPortalMenuItems(
      this.$store.state.userStore.currentUser.viewAccessEntities[
        this.applicationId
      ]
    );
  }
}
