


















































import { Component, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";

import CustomAutocomplete from "@/shared/components/pickers/CustomAutocomplete.vue";
import PickerDictionaryNotification from "@/shared/components/pickers/PickerDictionaryNotification.vue";

import {
  ExcludableMultipleValueFilterModel,
  Dictionary,
  DictionaryType,
  ValidationRule,
  FilterPreviewId,
  FILTER_ID_TO_FILTER_PREVIEW_ID,
  TrackerOrigin,
} from "@/shared/models";
import { ReportType } from "@/reports/models";
import DictionaryLoadMixin from "@/shared/mixins/DictionaryLoadMixin";

@Component({ components: { CustomAutocomplete, PickerDictionaryNotification } })
export default class ExcludableMultipleDictionaryFilter extends mixins(
  DictionaryLoadMixin
) {
  @Prop() value!: ExcludableMultipleValueFilterModel;
  @Prop() label!: string;
  @Prop() dataTypes!: Array<DictionaryType>;
  @Prop({ default: false }) readonly!: boolean;
  @Prop({ default: false }) autofocus!: boolean;
  @Prop() rules?: Array<ValidationRule>;
  @Prop({ default: () => [] }) errorMessages!: Array<string>;
  @Prop({ default: false }) isAttached!: boolean;
  @Prop() itemsFilter?: (item: any, currentValue: any) => boolean;
  @Prop({ default: () => [] }) origins!: Array<TrackerOrigin>;

  dictionaryType = this.dataTypes[0];
  localFilter: ExcludableMultipleValueFilterModel =
    ExcludableMultipleValueFilterModel.of(this.value);

  get filterPreviewId(): FilterPreviewId {
    return FILTER_ID_TO_FILTER_PREVIEW_ID[this.value.id];
  }

  get valid(): boolean {
    return !!this.localFilter.values.length;
  }

  get slotName(): string {
    return this.isAttached ? "append-outer" : "prepend";
  }

  get localErrorMessages(): Array<string> {
    return [
      ...this.errorMessages,
      ...(this.localFilter.values.length === this.items.length &&
      !this.localFilter.included
        ? [this.$lang("validation.excludeAllValues")]
        : []),
    ];
  }

  get items(): Array<Dictionary> {
    return this.dictionaryData
      .filter(
        (item) =>
          !this.itemsFilter || this.itemsFilter(item, this.localFilter.values)
      )
      .sort(({ text: textA }: Dictionary, { text: textB }: Dictionary) => {
        const searchText = this.search?.toLowerCase();

        return (
          textA.toLowerCase().indexOf(searchText) -
          textB.toLowerCase().indexOf(searchText)
        );
      });
  }

  get dark(): boolean {
    return this.$vuetify.theme.dark;
  }

  get includedHidden(): boolean {
    return (
      this.filterPreviewId === FilterPreviewId.AD_NETWORK_NAME &&
      [
        ReportType.ARPU,
        ReportType.ARPDAU,
        ReportType.ARPDAU_SIMPLE,
        ReportType.CALENDAR_CPM,
        ReportType.CALENDAR_CTR,
        ReportType.COHORT_CPM,
        ReportType.COHORT_CTR,
      ].includes((this.$route as any).name)
    );
  }

  @Watch("value", { immediate: true })
  watchValue(value: ExcludableMultipleValueFilterModel) {
    this.localFilter = ExcludableMultipleValueFilterModel.of(value);

    const availableValues = this.items.map(({ value }) => value);

    this.localFilter.values = this.localFilter.values.filter((item) =>
      availableValues.includes(item)
    );
  }

  @Watch("localFilter", { deep: true })
  watchLocalFilter(localFilter: ExcludableMultipleValueFilterModel) {
    if (this.readonly) {
      return;
    }

    this.$emit("input", Object.assign(this.value, localFilter));
  }

  @Watch("localFilter.values")
  watchValues(newValues: Array<string>, oldValues: Array<string> | undefined) {
    this.setUsedDictionaries(newValues, oldValues);
  }

  @Watch("valid", { immediate: true })
  watchValid(valid: boolean) {
    this.localFilter.valid = valid;
  }

  @Watch("dictionaryData", { immediate: true })
  watchDictionaryDataItems() {
    if (
      !this.localFilter.filled ||
      this.readonly ||
      this.localFilter.values.length
    ) {
      return;
    }

    this.localFilter.values = this.dictionaryData.map(({ value }) => value);
  }

  @Watch("origins", { immediate: true })
  watchOrigins() {
    this.localOrigins =
      this.dictionaryType === DictionaryType.SOURCES ? this.origins : [];
  }

  created() {
    if (!this.dictionaryData.length && !this.isFullResult) {
      this.$store.dispatch("loadDictionary", {
        app: this.apps,
        type: this.dictionaryType,
        ...(this.localOrigins.length ? { origins: this.localOrigins } : {}),
      });
    }
  }
}
