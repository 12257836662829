import JsonUtil from "@/ab-tests/utils/JsonUtil";

export interface ExecutionParameterResponseModel {
  description: string;
  input: boolean;
  mandatory: boolean;
  name: string;
}

export class ExecutionParameterModel {
  constructor(
    public name = "",
    public defaultValue = "",
    public type = "string",
    public values = [],
    public dependentOn?: string,
    public dependentValue?: string
  ) {}

  static ofArray(items: Array<ExecutionParameterResponseModel>) {
    return items
      .map((item) => {
        if (item.name === "SCENARIO_FORM") {
          if (
            item.description &&
            Array.isArray(JsonUtil.formattingFromJson(item.description))
          ) {
            return JsonUtil.formattingFromJson(item.description).map(
              (item: any) =>
                new ExecutionParameterModel(
                  item.name,
                  item.type === "boolean"
                    ? Boolean(item.default)
                    : item.default,
                  item.type,
                  item.values,
                  item?.dependentOn,
                  item?.dependentValue
                )
            );
          }

          return [];
        }

        return new ExecutionParameterModel(item.name);
      })
      .flat();
  }
}
