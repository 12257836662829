import { AxiosResponse } from "axios";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import TargetedConfigurationModel from "@/ab-tests/models/TargetedConfigurationModel";
import axios, { AxiosSkipErrorsConfig } from "@/shared/plugins/axios";
import ApplicationResponseModel, {
  EnvironmentType,
} from "../models/ApplicationResponseModel";
import {
  ConfigsStatus,
  TargetedConfigDisablingModel,
  TargetEnablingModel,
} from "../models/ConfigsStatusModel";
import ConfigUtil from "../utils/ConfigUtil";

@Module
export default class TargetedConfigStore extends VuexModule {
  loadingConfig = false;
  disableConfirmationNeeded = false;
  targetedConfigs: Array<TargetedConfigurationModel> = [];
  targetedConfig: TargetedConfigurationModel = new TargetedConfigurationModel();
  enablingTargetedConfig: TargetedConfigurationModel | null = null;
  isTargetedConfigNameUnique = true;
  configStatusTotals: Record<ConfigsStatus, number> | null = null;
  deploymentInProgress = false;

  @Mutation
  setLoadingTargetedConfig(payload: boolean) {
    this.loadingConfig = payload;
  }

  @Mutation
  setDisableConfirmationNeeded(payload: boolean) {
    this.disableConfirmationNeeded = payload;
  }

  @Mutation
  setTargetedConfigs(
    payload: Record<ConfigsStatus, Array<TargetedConfigurationModel>>
  ) {
    this.targetedConfigs = Object.values(payload)
      .flat()
      .map((it) => TargetedConfigurationModel.of(it))
      .sort(
        (a: TargetedConfigurationModel, b: TargetedConfigurationModel) =>
          a.priority - b.priority
      );
  }

  @Mutation
  setConfigStatusTotals(payload: Record<ConfigsStatus, number>) {
    this.configStatusTotals = payload;
  }

  @Mutation
  setTargetedConfig(payload: TargetedConfigurationModel) {
    this.targetedConfig = TargetedConfigurationModel.of(payload);
  }

  @Mutation
  setIsTargetedConfigNameUnique(payload: boolean) {
    this.isTargetedConfigNameUnique = payload;
  }

  @Mutation
  updateTargetedConfigs(payload: TargetedConfigurationModel) {
    const index = this.targetedConfigs.findIndex(
      (item) => item.id === payload.id
    );

    if (index !== -1) {
      this.targetedConfigs[index].status = payload.status;
      this.targetedConfigs[index].disableable = payload.disableable;
    }

    if (this.targetedConfig.id === payload.id) {
      this.targetedConfig = payload;
    }
  }

  @Mutation
  setEnablingTargetedConfig(payload?: TargetedConfigurationModel) {
    this.enablingTargetedConfig = payload ?? null;
  }

  @Mutation
  clearTargetedConfigs() {
    this.targetedConfigs = [];
  }

  @Mutation
  setDeploymentInProgress(payload: boolean) {
    this.deploymentInProgress = payload;
  }

  get existsTargetedConfig() {
    return !this.loadingConfig && this.targetedConfigs.length > 0;
  }

  get targetedConfigNames() {
    return this.targetedConfigs.map(({ name }) => name.toLowerCase());
  }

  @Action({ commit: "setTargetedConfigs" })
  async loadTargetedConfigsByStatuses(statuses?: Array<ConfigsStatus>) {
    this.context.commit("setLoadingTargetedConfig", true);

    if (Array.isArray(statuses) && !statuses.length) {
      this.context.commit("clearTargetedConfigs");
      this.context.commit("setLoadingTargetedConfig", false);

      return {};
    }

    return axios
      .get(
        `/api/ab-tests/app/${this.context.rootState.application.applicationId}/targeted/configs/statuses/configs`,
        {
          params: { statuses: statuses?.join(",") },
        }
      )
      .then(
        (
          result: AxiosResponse<
            Record<ConfigsStatus, Array<TargetedConfigurationModel>>
          >
        ) => {
          this.context.commit("clearTargetedConfigs");
          return result.data;
        }
      )
      .finally(() => {
        this.context.commit("setLoadingTargetedConfig", false);
      });
  }

  @Action({ commit: "setConfigStatusTotals" })
  async loadTargetedConfigStatusTotals(statuses?: Array<ConfigsStatus>) {
    return axios
      .get(
        `/api/ab-tests/app/${this.context.rootState.application.applicationId}/targeted/configs/statuses/totals`,
        {
          params: { statuses: statuses?.join(",") },
        }
      )
      .then((result: AxiosResponse<Record<ConfigsStatus, number>>) => {
        return result.data;
      });
  }

  @Action({ commit: "setTargetedConfig", rawError: true })
  async getTargetedConfig(configId: number) {
    this.context.commit("setLoadingTargetedConfig", true);
    return axios
      .get(
        `/api/ab-tests/app/${this.context.rootState.application.applicationId}/targeted/configs/${configId}`,
        { skipErrors: true } as AxiosSkipErrorsConfig
      )
      .then((result: AxiosResponse<TargetedConfigurationModel>) => {
        return result.data;
      })
      .finally(() => {
        this.context.commit("setLoadingTargetedConfig", false);
      });
  }

  @Action({ commit: "setTargetedConfig" })
  async createTargetedConfig(payload: TargetedConfigurationModel) {
    return axios
      .post(
        `/api/ab-tests/app/${this.context.rootState.application.applicationId}/targeted/configs`,
        payload
      )
      .then((result: AxiosResponse<TargetedConfigurationModel>) => {
        return result.data;
      });
  }

  @Action({ commit: "setTargetedConfig" })
  async updateTargetedConfig(payload: TargetedConfigurationModel) {
    return axios
      .patch(
        `/api/ab-tests/app/${this.context.rootState.application.applicationId}/targeted/configs/${payload.id}`,
        payload
      )
      .then((result: AxiosResponse<TargetedConfigurationModel>) => {
        this.context.dispatch("loadTargetedConfigs");
        return result.data;
      });
  }

  @Action({ commit: "setTargetedConfig" })
  async deployTargetedConfig({
    config,
    environmentType,
  }: {
    config: TargetedConfigurationModel;
    environmentType: EnvironmentType;
  }) {
    this.context.commit("setDeploymentInProgress", true);
    const payload = ApplicationResponseModel.ofRequest(
      config.response,
      environmentType
    );

    return axios
      .post(
        `/api/ab-tests/app/${this.context.rootState.application.applicationId}/targeted/configs/${config.id}/deploy`,
        payload
      )
      .then((result: AxiosResponse<TargetedConfigurationModel>) => result.data)
      .finally(() => {
        this.context.commit("setDeploymentInProgress", false);
      });
  }

  @Action
  async enableTargetedConfig({ targetedConfig, date }: TargetEnablingModel) {
    if (
      !date &&
      targetedConfig.activeSince &&
      targetedConfig.activeSince <= ConfigUtil.getCurrentDate()
    ) {
      this.context.commit("setEnablingTargetedConfig", targetedConfig);

      return;
    }

    return axios
      .post(
        `/api/ab-tests/app/${this.context.rootState.application.applicationId}/targeted/configs/${targetedConfig.id}/enable`,
        date || {}
      )
      .then((result: AxiosResponse<TargetedConfigurationModel>) => {
        this.context.commit("updateTargetedConfigs", result.data);
      });
  }

  @Action
  async disableTargetedConfig({
    targetedConfig,
    confirmed,
  }: TargetedConfigDisablingModel) {
    return axios
      .post(
        `/api/ab-tests/app/${this.context.rootState.application.applicationId}/targeted/configs/${targetedConfig.id}/disable`,
        {
          ...(confirmed ? { confirmed } : {}),
        },
        { skipErrors: true } as AxiosSkipErrorsConfig
      )
      .then((result: AxiosResponse<TargetedConfigurationModel>) => {
        this.context.commit("updateTargetedConfigs", result.data);
        this.context.commit("setDisableConfirmationNeeded", false);
      })
      .catch((error) => {
        if (error.response.status === 409) {
          this.context.commit("setDisableConfirmationNeeded", true);
        }
      });
  }

  @Action({ commit: "setIsTargetedConfigNameUnique" })
  async checkIsTargetedConfigNameUnique({
    appId,
    targetedConfig,
  }: {
    appId: string;
    targetedConfig: TargetedConfigurationModel;
  }) {
    if (!targetedConfig.name) {
      return true;
    }

    return axios
      .post(`/api/ab-tests/app/${appId}/targeted/configs/uniqueName`, {
        name: targetedConfig.name,
      })
      .then((result: AxiosResponse<{ unique: boolean }>) => {
        return (
          this.targetedConfig.name === targetedConfig.name || result.data.unique
        );
      });
  }
}
