export default {
  appAdmin: {
    title: "Settings",
    dictionaries: {
      title: "Dictionaries",
      titlePage: {
        new: "New {0}",
        appVersions: "App Versions",
        osVersions: "OS Versions",
      },
      saveMsg:
        "{0} will append to dictionaries. Please refresh the page in several minutes.",
      appVersions: "App Version",
      osVersions: "OS Version",
      cacheReloadSuccess: "Dictionaries cache was successfully reloaded",
      networksCacheReloadSuccess: "Networks cache was successfully reloaded",
    },
    divider: "Divider",
    revenueEvents: {
      title: "Ad Revenue Events",
      newRevenueEventTitle: "New Revenue Events",
      editRevenueEventTitle: "Revenue Event Details",
      tableHeaders: {
        token: "Token",
        type: "Type",
        event: "Event",
        appVersionFrom: "App version from",
        appVersionTo: "App version to",
        actions: "Actions",
      },
      from: "from",
      to: "to",
      notification: {
        createdSuccess: "The revenue event was created successfully",
        updatedSuccess: "The revenue event was updated successfully",
        deletedSuccess: "The revenue event was deleted successfully",
      },
      deleteConfirmDialog: {
        title: "Delete revenue event",
        body: "Do you really want to delete this event?",
      },
    },
    subscriptionEvents: {
      title: "Subscription Events",
      newSubscriptionEventTitle: "New Subscription Event",
      editSubscriptionEventTitle: "Subscription Event Details",
      from: "from",
      to: "to",
      tableHeaders: {
        token: "Token",
        type: "Type",
        appVersionFrom: "App version from",
        appVersionTo: "App version to",
        actions: "Actions",
      },
      notification: {
        createdSuccess: "The subscription event was created successfully",
        updatedSuccess: "The subscription event was updated successfully",
        deletedSuccess: "The subscription event was deleted successfully",
      },
      deleteConfirmDialog: {
        title: "Delete subscription event",
        body: "Do you really want to delete this event?",
      },
      type: {
        trial_start: "Trial Start",
        start: "Start",
        renewal: "Renewal",
        grace_start: "Grace Start",
        grace_finish: "Grace Finish",
        pause_start: "Pause Start",
        hold_start: "Hold Start",
        cancel: "Cancel",
        finish: "Finish",
      },
    },
    placementManagement: {
      title: "Placements",
      newPlacementTitle: "New Placement",
      editPlacementTitle: "Placement Details",
      tableHeaders: {
        networkIntegrationApplicationId: "Network Application Integration Id",
        networkName: "Network Name",
        adType: "Ad Type",
        spotId: "Spot Id",
        deviceType: "Device Type",
        lat: "Lat",
        placementId: "Placement Id",
        placementName: "Placement Name",
        waterfallId: "Waterfall Id",
        actions: "Actions",
      },
      applications: "Applications",
      latDisabled: "Lat disabled",
      networkNames: "Network Names",
      syncWithAdCascade: "Synchronization with AdCascade",
      notification: {
        createdSuccess: "The Placement was created successfully",
        updatedSuccess: "The Placement was updated successfully",
      },
      copyHint: "Copy placement",
      deleteDialog: {
        title: "Delete placement",
        body: "Do you really want to delete this placement?",
      },
    },
    networksManagement: {
      title: "Networks Management",
      newNetworkTitle: "New Network",
      viewNetworkTitle: "Network name: {0}",
      goToNetworksList: "Go to networks list",
      parentNetwork: "Parent network",
      authorizationType: "Authorization type",
      daysAgo: "Days ago",
      fromTo: "From {0} to {1}",
      disabled: "Disabled",
      activated: "Activated",
      newIntegration: "New integration",
      editIntegration: "Edit integration",
      baseUrlTitle: "Base url",
      baseUrlSubtitle: "Set up base url",
      accountsGroupsTitle: "Accounts Groups",
      accountsGroupsSubtitle: "Select or add new account group",
      newAccountGroup: "New account group",
      editAccountGroup: "Edit account group",
      name: "Name",
      description: "Description",
      requestParams: {
        title: "Request params",
        subtitle: "Set up request params",
        new: "New request param",
        edit: "Edit request param",
      },
      requestSpecialParams: {
        title: "Request special params",
        subtitle: "Set up request special params",
        new: "New request special param",
        edit: "Edit request special param",
      },
      accounts: {
        title: "Accounts",
        subtitle: "Set up accounts",
        accountName: "Account name",
        newAccount: "New account",
        editAccount: "Edit account",
        addParamHint: "Add param",
        newParam: "New param",
        editParam: "Edit param",
        transferToAccountGroup: "Transfer to account group",
      },
      statistics: {
        title: "Statistics",
        subtitle: "Here you can see statistics for the selected account group",
        executionEndDate: "Execution end date",
        executionStatus: "Execution status",
        statusCode: "Status code",
        message: "Message",
      },
      parsingRules: {
        title: "Parsing Rules",
        subtitle: "Set up parsing rules",
        json: "JSON",
        json_map: "JSON_MAP",
        csv: "CSV",
        chartboost: "Chartboost",
        json_matrix: "JSON matrix",
        json_with_dynamic_keys: "JSON with dynamic keys",
        json_with_unpivot_children_object: "JSON with unpivot children object",
        aggregated_json: "Aggregated JSON",
        get_geo_api_currency_json: "Get geo api currency JSON",
        responseFormat: "Response format",
        childrenNode: "Children node",
        nodes: "Nodes",
        includeChildrenNodes: "Include children nodes",
        excludeNodes: "Exclude nodes",
        errorNodes: "Error nodes",
        errorValue: "Error value",
        successValue: "Success value",
        encoding: "Encoding",
        hasPom: "Has POM",
        divider: "Divider",
        headers: "Headers",
        format: "Format",
        fieldKey: "Field key",
        type: "Type",
        addRule: "Add rule",
        newRuleTitle: "New rule",
        editRuleTitle: "Edit rule",
      },
      accountsGroups: {
        title: "Accounts Groups",
        subtitle: "Select or add new account group",
        itemTitle: "Account group name: {0}",
      },
      applicationBindings: {
        title: "Application Bindings",
        subtitle: "Set up applications",
        gameId: "Game id",
        application: "Application",
        aliasName: "Alias name",
        new: "New application binding",
        edit: "Edit application binding",
        applicationIdNotUnique:
          "The app ID has already been added to another account group",
      },
      networkIntegration: {
        revenue: "Revenue",
        spend: "Spend",
        skad: "Skad",
        store: "Store",
        currency: "Currency",
        subscription: "Subscription",
        subscription_metadata: "Subscription metadata",
        motivated_traffics: "Motivated traffics",
      },
      authorizationSubtype: "Authorization subtype",
      netRevenue: {
        title: "Net Revenue",
        subtitle: "Set up net revenue",
        dateFrom: "Date from",
        dateTo: "Date to",
        net: "Net",
        addPeriod: "Add period",
        editNetByPeriods: "Edit net by periods",
        dateOneDayGreater:
          "The date must be 1 day greater than the date of the previous period",
        minDateTo: "Must be greater than {0}",
      },
      childNetworks: {
        title: "Child Networks",
        subtitle: "Set up child networks",
        name: "Name",
        add: "Add child network",
        network: "Network",
      },
      historicalRevenueNetwork: {
        title: "Historical revenue network",
        editTitle: "Edit historical revenue network",
        newTitle: "New historical revenue network",
      },
      key: "Key",
      value: "Value",
      paramType: "Param Type",
      actions: "Actions",
      list: "List",
      addIntegration: "Add integration",
      integration: "Integration",
      pre_auth_request: "Pre Auth Request",
      single_request: "Single Request",
      delta: "From {0} to {1} days ago",
      deltaFrom: "Days ago from",
      deltaTo: "Days ago to",
      authentication: "Authentication",
      dataRequest: "Data request",
      keyValue: "Key value",
      node: "Node",
      expirationDays: "Expiration Days",
      requestType: "Request type",
      auth: "Auth",
      persistent_auth: "Persistent auth",
      httpMethod: "Http Method",
      baseUrl: "Base url",
      userName: "User name",
      password: "Password",
      bearer: "Bearer",
      place: "Place",
      date_interval: "Date interval",
      date_interval_with_next_url: "Date interval with next url",
      date_interval_with_offset: "Date interval with offset",
      date_interval_with_next_token: "Date interval with next token",
      next_token: "Next token",
      next_url: "Next url",
      offset: "Offset",
      paginationType: "Pagination type",
      paginationValue: "Pagination value",
      paginationIncludeEndDate: "Include end date",
      minContentLength: "Min content length",
      lengthPage: "Length page",
      nextKey: "Next key",
      url: "Url",
      body: "Body",
      header: "Header",
      custom: "Custom",
      replace_url: "Replace url",
      sign_url: "Sign url",
      oauth_1_signature: "Oauth 1 signature",
      time_sign_url: "Time sign url",
      jwt: "JWT",
      authParamsTitle: "Basic Authentication Params",
      formatter: "Formatter",
      lastReceivedStatistics: "Last received statistics",
      customDataRequests: "Custom Data Requests",
      tableHeaders: {
        networkName: "Network name",
        net: "Net",
        lastExecutionStatus: "Last execution status",
        lastExecutionEndDate: "Last execution end date",
      },
      notification: {
        createdSuccess: "Created successfully",
        updatedSuccess: "Updated successfully",
        deletedSuccess: "Deleted successfully",
        resyncSuccess: "Resync was successful",
      },
    },
  },
};
