export default class ExportEventsRequestModel {
  constructor(
    public dates: Array<string> = [],
    public savedIds: Array<string> = []
  ) {}

  static ofRequest(model: ExportEventsRequestModel): Record<string, string> {
    return {
      dateFrom: model.dates[0],
      dateTo: model.dates[1],
      savedId: model.savedIds.join(","),
    };
  }
}
