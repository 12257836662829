import {
  BaseGradientFlags,
  BaseReportFilter,
  ReportFilter,
  ReportResultItem,
} from "./Report";
import { AggregationPeriod, GroupByFilter } from "./GroupByFilter";
import { ReportHeader, ReportHeaderUtil } from "./ReportHeader";
import { ReportType } from "./ReportType";
import {
  DatesFilterModel,
  FilterId,
  recordToFilterModel,
  FilterModel,
  Application,
  FilterPreviewId,
  FilterPreview,
} from "@/shared/models";
import { LangService } from "@/shared/types/LangType";
import { ExcludeNoInternetReportFilterExtension } from "./ReportFilterExtension";
import { ChartName } from "@/chart/models/ChartModel";
import { ReportItemRowObject } from "./ReportItemRowObject";

const metrics: Array<[string, number?]> = [
  ["dau"],
  ["bannerFailedEvents"],
  ["bannerFailedUsers"],
  ["bannerLoadedEvents"],
  ["bannerLoadedUsers"],
  ["bannerRequestsEvents"],
  ["bannerFillRate", 2],
  ["bannerImpressionsEvents"],
  ["bannerImpressionsEventsPerDau", 2],
  ["bannerRequestsEventsPerDau", 2],
  ["interFailedEvents"],
  ["interFailedUsers"],
  ["interLoadedEvents"],
  ["interLoadedUsers"],
  ["interRequestsEvents"],
  ["interRequestsUsers"],
  ["interFillRate", 2],
  ["interImpressionsEvents"],
  ["interImpressionsEventsPerDau", 2],
  ["interRequestsEventsPerDau", 2],
  ["rewardFailedEvents"],
  ["rewardFailedUsers"],
  ["rewardLoadedEvents"],
  ["rewardLoadedUsers"],
  ["rewardRequestsEvents"],
  ["rewardRequestsUsers"],
  ["rewardFillRate", 2],
  ["rewardImpressionsEvents"],
  ["rewardImpressionsEventsPerDau", 2],
  ["rewardRequestsEventsPerDau", 2],
];

export class FillRateGradientFlags extends BaseGradientFlags {
  constructor() {
    super();

    this.flags = {
      gradient: true,
    };
  }

  getByKey(): boolean {
    return super.getByKey("gradient");
  }
}

export class FillRateFilter
  extends BaseReportFilter
  implements ExcludeNoInternetReportFilterExtension
{
  constructor(
    app: Application,
    filter?: Array<FilterModel>,
    date?: DatesFilterModel,
    groupByFilter = new GroupByFilter(AggregationPeriod.DAY, [], false),
    public excludeNoInternet = false
  ) {
    super(ReportType.FILL_RATE, app, filter, date, groupByFilter);
  }

  static of(
    app: Application,
    requestQuery?: Record<string, any>
  ): FillRateFilter {
    return new FillRateFilter(
      app,
      requestQuery?.filter?.map((it: any) => recordToFilterModel(it)) || [],
      DatesFilterModel.ofRecord(FilterId.EVENTS_DATE, requestQuery?.date),
      GroupByFilter.of(requestQuery?.groupByFilter),
      typeof requestQuery?.excludeNoInternet === "string"
        ? requestQuery?.excludeNoInternet === "true"
        : requestQuery?.excludeNoInternet
    );
  }

  get invalid(): boolean {
    return !(this.app && this.date.valid);
  }

  get previews(): Array<FilterPreview> {
    return [
      ...super.previews,
      ...(this.excludeNoInternet
        ? [
            {
              id: FilterPreviewId.EXCLUDE_NO_INTERNET,
              value: this.excludeNoInternet,
            },
          ]
        : []),
    ];
  }

  get charts(): Array<ChartName> {
    return this.groupByFilter.isNotEmptyGroupBy
      ? [
          ChartName.FILL_RATE_GROUPED_BANNER_CHART,
          ChartName.FILL_RATE_GROUPED_BANNER_PER_DAU_CHART,
          ChartName.FILL_RATE_GROUPED_INTER_CHART,
          ChartName.FILL_RATE_GROUPED_INTER_PER_DAU_CHART,
          ChartName.FILL_RATE_GROUPED_REWARD_CHART,
          ChartName.FILL_RATE_GROUPED_REWARD_PER_DAU_CHART,
        ]
      : [
          ChartName.FILL_RATE_BANNER_CHART,
          ChartName.FILL_RATE_BANNER_PER_DAU_CHART,
          ChartName.FILL_RATE_INTER_CHART,
          ChartName.FILL_RATE_INTER_PER_DAU_CHART,
          ChartName.FILL_RATE_REWARD_CHART,
          ChartName.FILL_RATE_REWARD_PER_DAU_CHART,
        ];
  }

  get groupedCharts(): Array<ChartName> {
    return this.groupByFilter.isNotEmptyGroupBy
      ? [
          ChartName.FILL_RATE_BANNER_CHART,
          ChartName.FILL_RATE_BANNER_PER_DAU_CHART,
          ChartName.FILL_RATE_INTER_CHART,
          ChartName.FILL_RATE_INTER_PER_DAU_CHART,
          ChartName.FILL_RATE_REWARD_CHART,
          ChartName.FILL_RATE_REWARD_PER_DAU_CHART,
        ]
      : [];
  }

  toRequestQuery(): Record<string, any> {
    return {
      ...super.toRequestQuery(),
      ...(this.excludeNoInternet
        ? { excludeNoInternet: this.excludeNoInternet }
        : {}),
    };
  }
}

export class FillRateHeaders {
  static init(lang: LangService, report: FillRateFilter): Array<ReportHeader> {
    return [
      ReportHeaderUtil.createDate(lang, report.groupByFilter.aggregationPeriod),
      ...(report.groupByFilter.isNotEmptyGroupBy
        ? ReportHeaderUtil.createGroupBy(lang, report.groupByFilter)
        : []),
      ...metrics.map(
        ([metric, fractionDigits]) =>
          ({
            text: lang(`components.baseTable.${metric}`),
            align: "end",
            width: 90,
            value: `${ReportResultItem.PREFIX}${metric}`,
            hasGradient: true,
            ...(fractionDigits === undefined ? {} : { fractionDigits }),
          } as ReportHeader)
      ),
    ];
  }
}

export class FillRateResultItem extends ReportResultItem {
  constructor(row: ReportItemRowObject, filter: ReportFilter) {
    const { groupByFilter } = filter;

    super();
    this.date = row.getByHeader("date");
    this.setFormattedDate(
      this.date,
      filter.date.from,
      filter.date.to,
      groupByFilter.aggregationPeriod
    );
    this.setGroupByValue(groupByFilter, row);
    this.data = {
      ...this.data,
      ...metrics.reduce(
        (result, [metric, fractionDigits]) => ({
          ...result,
          [metric]:
            fractionDigits === undefined
              ? row.getByHeader(metric)
              : this.parseFloat(row.getByHeader(metric), fractionDigits),
        }),
        {}
      ),
    };
  }
}
