


























import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import ReportMenuItem from "@/reports/components/ReportMenuItem.vue";
import { ReportGroupModel, ReportItemModel } from "@/reports/models/ReportMenu";
import ReportUtil from "@/reports/utils/ReportUtil";

@Component({ components: { ReportMenuItem } })
export default class ReportMenu extends Vue {
  @Prop() applicationId!: string;

  reportsMenuItems: Array<ReportGroupModel | ReportItemModel> = [];

  @Watch("applicationId", { immediate: true })
  setReports() {
    this.reportsMenuItems = ReportUtil.createReportsMenu(
      this.$store.state.userStore.currentUser.reportAccessByApp[
        this.applicationId
      ] ?? []
    ) as Array<ReportGroupModel | ReportItemModel>;
  }

  @Watch("$route", { deep: true, immediate: true })
  watchRoute() {
    this.reportsMenuItems.forEach(
      (report: ReportGroupModel | ReportItemModel) => {
        if (report instanceof ReportItemModel) {
          return;
        }

        report.isActive = report.items.some(
          ({ id }) => id === this.$route.name
        );
      }
    );
  }
}
