import { Module, VuexModule, Mutation } from "vuex-module-decorators";
import { cloneDeep } from "lodash";

import { MenuModel } from "@/shared/models";

@Module
export default class MenuStore extends VuexModule {
  menu: Array<MenuModel> = [];

  @Mutation
  setMenu(payload: Array<MenuModel>) {
    this.menu = cloneDeep(payload);
  }
}
