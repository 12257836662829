export enum NotificationType {
  SUCCESS = "success",
  ERROR = "error",
}

export interface NotificationAction {
  path: string;
  key: string;
}

export interface NotificationLangMessage {
  key: string;
  params?: Array<string | number>;
  target?: string;
}

export type NotificationMessage = string | NotificationLangMessage;

export interface Notification {
  message: NotificationMessage;
  type?: NotificationType;
  timeout?: number;
  action?: NotificationAction;
}

export class NotificationModel {
  message: NotificationMessage;
  type: NotificationType;
  timeout: number;
  action?: NotificationAction;
  key: string;

  constructor(model: Notification) {
    this.message = model.message;
    this.type = model.type ?? NotificationType.SUCCESS;
    this.timeout = model.timeout ?? 5000;
    this.action = model.action;
    this.key = this.getKey(model.message);
  }

  private getKey(message: NotificationMessage) {
    if (typeof message === "string") {
      return message;
    }

    return `${message.key}-${
      message.params && message.params.length ? message.params.join("") : ""
    }`;
  }
}
