import { AccessType } from "@/shared/models";

export class DashboardModel {
  constructor(
    public id: number,
    public name: string,
    public owner: string,
    public accessType: AccessType
  ) {}

  static of(model: DashboardModel): DashboardModel {
    return new DashboardModel(
      model.id,
      model.name,
      model.owner,
      model.accessType
    );
  }
}

export default class UsedDashboardModel {
  constructor(
    public dashboards: Array<DashboardModel>,
    public usageInInaccessibleDashboards: boolean
  ) {}

  static of(model: UsedDashboardModel): UsedDashboardModel {
    return new UsedDashboardModel(
      model.dashboards.map((it) => DashboardModel.of(it)) || [],
      model.usageInInaccessibleDashboards
    );
  }
}
