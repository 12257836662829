export default {
  alertsSystem: {
    titleForList: "Alerts",
    titleForCreating: "Creating Alert",
    titaleForEditing: "Editing Alert",
    tableHeader: {
      text: "Text",
      type: "Type",
      feature: "Feature",
      subFeatures: "Subfeatures",
      wholeSystem: "Whole system",
      allReports: "All reports",
      applications: "Applications",
      activate: "Activate",
      actions: "Actions",
      displayTime: "Display time",
    },
    deleteHint: "Delete alert",
    border: "Border",
    dismissible: "Dismissible",
    alertMode: "Alert mode",
    applications: "Applications",
    selectAll: "Select All",
    constant: "Constant",
    datetime_limited: "Datetime limited",
    feature_time_limited: "Feature time limited",
    reports: "Reports",
    dashboards: "Dashboards",
    funnels: "Funnels",
    segments: "Segments",
    ab_tests: "Ab Tests",
    specificApplications: "Specific applications",
    availableAlerts: "Available alerts",
    title: "Title",
    displayableOnChart: "Displayable on chart",
    exampleText: "Example: Incorrect data from 20-09 till 22-09",
    deleteDialogHeading: `Delete alert`,
    deleteDialogContent: `Are you sure you want to delete alert with text: "{0}"?`,
    dontShowAgain: "Don't show again",
    notification: {
      createdSuccess: `The Alert was created successfully`,
      updatedSuccess: `The Alert was updated successfully`,
    },
  },
};
