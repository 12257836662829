export default class AccountResponseModel {
  constructor(
    public id: number,
    public accountGroupId: number,
    public accountName: string
  ) {}

  static of(model: AccountResponseModel) {
    return new AccountResponseModel(
      model.id,
      model.accountGroupId,
      model.accountName
    );
  }

  static ofArray(items: Array<AccountResponseModel>) {
    return items.map((item) => AccountResponseModel.of(item));
  }
}
