import { differenceWith, isEqual, unionWith } from "lodash";

import DateUtil from "../utils/DateUtil";
import { Feature } from "./Feature";
import { SegmentStatus, SegmentType } from "@/segments/models/SegmentModel";

export class Dictionary {
  readonly value: any;
  readonly name?: string;
  readonly parentValue?: string | null;
  readonly childValues?: Array<Dictionary>;

  constructor(
    value: any,
    name?: string,
    parentValue?: string | null,
    childValues?: Array<Dictionary>
  ) {
    this.value = value;
    this.name = name;
    this.parentValue = parentValue;
    this.childValues = childValues && Dictionary.ofArray(childValues);
  }

  get text() {
    return this.name || this.value;
  }

  static ofArray(dictionaries: Array<Dictionary>) {
    return dictionaries.map(
      (it) => new Dictionary(it.value, it.name, it.parentValue, it.childValues)
    );
  }
}

export class DictionaryModel<T = Dictionary> {
  usedValues: Array<T> = [];

  constructor(
    public dictionaryValues: Array<T> = [],
    public isFullResult = false
  ) {}

  get values(): Array<T> {
    return unionWith(this.dictionaryValues, this.usedValues, isEqual);
  }

  get unfilled(): boolean {
    return !this.dictionaryValues.length && !this.isFullResult;
  }

  update(dictionaryValues: Array<T>, isFullResult: boolean) {
    this.dictionaryValues = dictionaryValues;
    this.isFullResult = isFullResult;
  }

  updateUsedValues(dictionaryValues: Array<T>) {
    const uniqValues = differenceWith(
      dictionaryValues,
      this.usedValues,
      isEqual
    );

    if (!uniqValues.length) {
      return;
    }

    this.usedValues.push(...uniqValues);
  }

  static of({ dictionaryValues, isFullResult }: DictionaryModel) {
    return new DictionaryModel(
      Dictionary.ofArray(dictionaryValues),
      isFullResult
    );
  }
}

export class SegmentDictionaryModel extends DictionaryModel<SegmentDictionary> {
  constructor(
    public dictionaryValues: Array<SegmentDictionary> = [],
    public isFullResult = false
  ) {
    super();
  }

  static of({ dictionaryValues, isFullResult }: SegmentDictionaryModel) {
    return new SegmentDictionaryModel(
      SegmentDictionary.ofArray(dictionaryValues),
      isFullResult
    );
  }
}

export class TrackerDictionaryModel extends DictionaryModel<TrackerDictionary> {
  constructor(
    public dictionaryValues: Array<TrackerDictionary> = [],
    public isFullResult = false
  ) {
    super();
  }

  static of({ dictionaryValues, isFullResult }: TrackerDictionaryModel) {
    return new TrackerDictionaryModel(
      TrackerDictionary.ofArray(dictionaryValues),
      isFullResult
    );
  }
}

export class ExternalTestDictionaryModel extends DictionaryModel<ExternalTestDictionary> {
  constructor(
    public dictionaryValues: Array<ExternalTestDictionary> = [],
    public isFullResult = false
  ) {
    super();
  }

  static of({ dictionaryValues, isFullResult }: ExternalTestDictionaryModel) {
    return new ExternalTestDictionaryModel(
      ExternalTestDictionary.ofArray(dictionaryValues),
      isFullResult
    );
  }
}

export enum TrackerOrigin {
  MARKETING_EVENTS = "marketing_events",
  SPEND = "ad_panel_spend",
  NETWORK_SPEND = "network_spend",
  BANNERS = "banners",
  SKAD_EVENTS = "skad_events",
  NETWORK_SKAD = "network_skad",
}

export class TrackerDictionary extends Dictionary {
  readonly origins: Array<TrackerOrigin>;

  constructor(
    value: any,
    name?: string,
    parentValue?: string | null,
    origins: Array<TrackerOrigin> = []
  ) {
    super(value, name, parentValue);
    this.origins = origins;
  }

  static ofArray(dictionaries: Array<TrackerDictionary>) {
    return dictionaries.map(
      (it) =>
        new TrackerDictionary(it.value, it.name, it.parentValue, it.origins)
    );
  }
}

export const MULTI_APP = "multi";

export enum ApplicationStatus {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
}

export enum DataProviderAttributionType {
  APPSFLYER = "APPSFLYER",
  ADJUST = "ADJUST",
}

export enum DataProviderUserEventsType {
  FIREBASE = "FIREBASE",
  ADJUST = "ADJUST",
}

export interface ApplicationDataProviderModel {
  attribution: DataProviderAttributionType;
  userEvents: DataProviderUserEventsType;
}

export class Application {
  readonly id: string;
  readonly name: string;
  readonly platformType: string;
  readonly availableFeatures: Array<Feature>;
  readonly status: ApplicationStatus;
  readonly tableauHost: string | null;
  readonly tableauRoot: string | null;
  readonly tableauName: string | null;
  readonly dataProvider: ApplicationDataProviderModel | null;

  constructor(
    id: string,
    name: string,
    platformType: string,
    availableFeatures: Array<Feature>,
    status: ApplicationStatus,
    tableauHost: string | null,
    tableauRoot: string | null,
    tableauName: string | null,
    dataProvider: ApplicationDataProviderModel | null
  ) {
    this.id = id;
    this.name = name;
    this.platformType = platformType;
    this.availableFeatures = availableFeatures;
    this.status = status;
    this.tableauHost = tableauHost;
    this.tableauRoot = tableauRoot;
    this.tableauName = tableauName;
    this.dataProvider = dataProvider;
  }

  static ofArray(applications: Array<Application>) {
    return applications.map(
      (it) =>
        new Application(
          it.id,
          it.name,
          it.platformType,
          it.availableFeatures,
          it.status,
          it.tableauHost,
          it.tableauRoot,
          it.tableauName,
          it.dataProvider
        )
    );
  }

  get value() {
    return this.id;
  }

  get text() {
    return this.name || this.id;
  }

  get hasFirebaseBannersFeature(): boolean {
    return this.availableFeatures.includes(Feature.FIREBASE_BANNERS);
  }

  get hasAbTestPermutationTestFeature(): boolean {
    return this.availableFeatures.includes(Feature.AB_TEST_PERMUTATION_TEST);
  }

  get hasReportsMetricsPredicted(): boolean {
    return this.availableFeatures.includes(Feature.REPORTS_METRICS_PREDICTED);
  }
}

export interface PlatformApplicationsResponse {
  apps: Array<Application>;
  platforms: Array<Dictionary>;
}

export class ExternalTestDictionary extends Dictionary {
  constructor(
    public value: string,
    public testGroups: Array<string>,
    public startDate: string,
    public formattedStartDate: string
  ) {
    super(value);
  }

  static ofArray(dictionaries: Array<ExternalTestDictionary>) {
    return dictionaries.map(
      ({ value, testGroups, startDate }) =>
        new ExternalTestDictionary(
          value,
          testGroups,
          startDate,
          DateUtil.formatDate(startDate)
        )
    );
  }
}

export class SegmentDictionary extends Dictionary {
  constructor(
    public value: string,
    public name: string,
    public type: SegmentType,
    public status: SegmentStatus,
    public usersCount?: number,
    public usersShare?: number,
    public lastSuccessfulRecalculationAt?: string
  ) {
    super(value);
  }

  static ofArray(dictionaries: Array<SegmentDictionary>) {
    return dictionaries.map(
      ({
        value,
        name,
        type,
        status,
        usersCount,
        usersShare,
        lastSuccessfulRecalculationAt,
      }) =>
        new SegmentDictionary(
          value,
          name,
          type,
          status,
          usersCount,
          usersShare,
          lastSuccessfulRecalculationAt
        )
    );
  }
}
