export default {
  users: {
    title: "Users",
    newTitle: "New User",
    editTitle: "Edit User",
    isSuperUser: "Is Super User",
    components: {
      firstName: "First Name",
      lastName: "Last Name",
      username: "Email",
      apps: "Available Applications",
      reports: "Available Reports",
      networks: "Available Networks",
    },
    btn: {
      cancel: "Cancel",
      save: "Save",
      selectAll: "Select All",
      showAll: "Show All",
    },
    confirmDeleteUser: 'Are you sure you want to delete "{0}"?',
    roleErrorMsg: "Must be at least one user with {0} role",
    userName: "User name",
    deleteUser: "Delete User",
    permissions: "Permissions",
    newPermission: "New Permission",
    searchApplication: "Search application",
    feature: "Feature",
    features: "Features",
    permission: "Permission",
    actions: "Actions",
    editPermission: "Edit Permission",
    addPermission: "Add Permission",
    addGeneralPermission: "Add General Permission",
    application: "Application",
    newPermissionForApp: "Add Permission for {0}",
    editPermissionForApp: "Edit Permission for {0}",
    read_config: "Read",
    write_config: "Write",
    full_access_config: "Full Access",
    delete_config: "Delete",
    deploy_config: "Deploy",
    execute_export: "Execute export",
    ab_test: "AB Test",
    target_config: "Target Config",
    default_config: "Default Config",
    fullAccess: "The user has full access to the application",
    targets: "Targets",
    export: "Export",
    confirmDeletePermission:
      "Are you sure you want to delete this permission ?",
    funnel: "Funnel",
    viewer_funnel: "Viewer",
    user_funnel: "User",
    admin_funnel: "Admin",
    selectUser:
      "Select a user from the list to see and change their permissions",
    error: {
      notFound: "User {0} not found",
    },
    entity: "Entity",
    aggregatorEntity: "Aggregator Entity",
    value: "Value",
    aggregator: "Aggregator",
    applications: "Applications",
    applicationAggregators: "Application aggregators",
    appSectionTitle: "App Section",
    permissionEntity: {
      application: "Application",
      user: "User",
      feature: "Feature",
      app_section: "App section",
      app_sections: "App sections",
      ad_network: "Ad Network",
      ad_networks: "Ad Networks",
      general: "General",
    },
    appSection: {
      ab_tests: "AB Tests",
      ab_test_segments: "AB Test segments",
      ab_tests_groups_size_recommender: "AB Tests groups size recommender",
      alert_system: "Alerts system",
      jurisdictions: "Jurisdictions",
      scenarios: "Scenarios",
      batches: "Batches",
      applications: "Applications",
      custom_dynamic_segments: "Custom dynamic segments",
      custom_static_segments: "Custom static segments",
      default_config: "Default config",
      divider_segments: "Divider segments",
      edge_node: "Reset Edge Node state",
      export_user_flow: "Export user flow",
      external_tests: "External tests",
      external_test_segments: "External test segments",
      funnels: "Funnels",
      funnel_segments: "Funnel segments",
      funnel_templates: "Funnel templates",
      healthcheck_summary: "Healthcheck summary",
      healthcheck_data_flow: "Healthcheck data flow",
      healthcheck_parsing: "Healthcheck parsing",
      healthcheck_jobs_queue: "Healthcheck jobs queue",
      healthcheck_metric_weight: "Healthcheck metric weight",
      multiapp_dashboards: "Multiapp dashboards",
      report_templates: "Report templates",
      ad_revenue_events: "Ad revenue events",
      dictionaries_app_versions: "Dictionaries App versions",
      dictionaries_os_versions: "Dictionaries OS versions",
      divider: "Divider",
      placements: "Placements",
      networks_management: "Networks management",
      singleapp_dashboards: "Singleapp dashboards",
      targeted_configs: "Targeted configs",
      users: "Users",
      reload_dictionaries_cache: "Reload dictionaries cache",
      reload_networks_cache: "Reload networks cache",
      aggregators: "Aggregators",
      external_tests_configuration: "External Tests Configuration",
      subscription_events: "Subscription Events",
      permissions_browser: "Permissions Browser",
      application_portal: "Application Portal",
      banks: "Accounting portal banks",
      counterparties: "Accounting portal counterparties",
      invoices: "Accounting portal invoices",
      healthcheck_marketing_alert: "Marketing alerts",
      healthcheck_monetization_alert: "Monetization alerts",
      application_validation: "Application validation",
      application_basic_info: "Application basic info",
      application_features_control: "Application features control",
      tax_percent: "Tax Percents",
    },
    userAccess: {
      view: "View",
      create: "Create",
      full_access: "Full Access",
      delete: "Delete",
      execute: "Execute",
      deploy: "Deploy",
      edit: "Edit",
      finish: "Finish",
      validate: "Validate",
    },
    aggregators: {
      title: "Aggregators",
      name: "Name",
      type: "Type",
      description: "Description",
      entities: "Entities",
      actions: "Actions",
      editHint: "Edit aggregator",
      deleteHint: "Delete aggregator",
      editAggregator: 'Edit aggregator "{0}"',
      createNewAggregator: "Create new aggregator",
      deleteAggregator: 'Delete aggregator "{0}"',
      showMore: "show {0} more",
      aggregatorType: {
        title: "Aggregator type",
        general_app_sections: "General App Sections",
        none_general_app_sections: "None General App Sections",
      },
    },
    search: "Search",
    valueLabel: {
      app_section: "App Section",
      ad_network: "Ad Network",
    },
    permissionsExport: {
      title: "Export user permissions",
      exportToUser: "Export to user",
      applications: "Applications",
      insertMode: {
        title: "Insert mode",
        append: "Append",
        replace: "Replace",
      },
      copyMode: {
        title: "Copy mode",
        full: "Full",
        granulated: "Granulated",
      },
      type: {
        title: "Sections",
        ad_network: "Ad Networks",
        none_general_sections: "App Sections",
        general_sections: "General",
      },
      exportSuccess: "Permissions were successfully copied",
    },
    permissionsBrowser: {
      title: "Permissions Browser",
      user: "User",
      appSections: "App Sections",
      adNetworks: "Ad Networks",
    },
  },
  warning: "Warning",
};
