import { DashboardChartModel } from "@/dashboard/models/DashboardChartModel";
import { AccessType, AppSection, UserAccess } from "@/shared/models";

export interface DashboardPreview {
  id: number;
  isDefault: boolean;
  name: string;
  type: DashboardType;
}

export class DashboardPreviewModel implements DashboardPreview {
  constructor(
    public id: number,
    public isDefault: boolean,
    public name: string,
    public type: DashboardType,
    public colsCount: number,
    public accessType: AccessType,
    public ownerId: string,
    public ownerName: string,
    public features: Array<UserAccess>,
    public description?: string
  ) {}

  get editable(): boolean {
    return this.features.includes(UserAccess.EDIT);
  }

  get deletable(): boolean {
    return this.features.includes(UserAccess.DELETE);
  }

  static of(model: DashboardPreviewModel): DashboardPreviewModel {
    return new DashboardPreviewModel(
      model.id,
      model.isDefault,
      model.name,
      model.type,
      model.colsCount,
      model.accessType,
      model.ownerId,
      model.ownerName,
      model.features,
      model.description
    );
  }

  static ofArray(
    items: Array<DashboardPreviewModel>
  ): Array<DashboardPreviewModel> {
    return items.map((item) => DashboardPreviewModel.of(item));
  }
}

export default class DashboardModel {
  constructor(
    public colsCount: number = 1,
    public type: DashboardType = DashboardType.MULTI_APP,
    public accessType: AccessType = AccessType.PRIVATE,
    public charts: Array<DashboardChartModel> = [],
    public isDefault: boolean = false,
    public name?: string,
    public id?: number,
    public ownerId?: string,
    public ownerName?: string,
    public createdAt?: string,
    public description?: string,
    public warningMessage?: string
  ) {}

  static of(model: DashboardModel) {
    const charts = [
      ...model.charts.map((chart: DashboardChartModel) => {
        return DashboardChartModel.of(chart);
      }),
    ].sort((chartA, chartB) =>
      chartA.id && chartB.id ? chartA.id - chartB.id : 0
    );

    return new DashboardModel(
      model.colsCount,
      model.type,
      model.accessType,
      charts,
      model.isDefault,
      model.name,
      model.id,
      model.ownerId,
      model.ownerName,
      model.createdAt,
      model.description,
      model.warningMessage
    );
  }
}

export interface DashboardUniqueNameResponse {
  name: string;
  unique: boolean;
}

export enum DashboardType {
  SINGLE_APP = "SINGLE_APP",
  MULTI_APP = "MULTI_APP",
}

export const DASHBOARD_TYPE_TO_APP_SECTION: Record<DashboardType, AppSection> =
  {
    [DashboardType.MULTI_APP]: AppSection.MULTIAPP_DASHBOARDS,
    [DashboardType.SINGLE_APP]: AppSection.SINGLEAPP_DASHBOARDS,
  };
