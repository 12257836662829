export default class CounterpartyBankResponseModel {
  constructor(
    public id: number,
    public counterpartyId: number,
    public counterpartyName: string,
    public bankId: number,
    public bankName: string,
    public dateFrom: string,
    public dateTo: string
  ) {}

  static of(
    model: CounterpartyBankResponseModel
  ): CounterpartyBankResponseModel {
    return new CounterpartyBankResponseModel(
      model.id,
      model.counterpartyId,
      model.counterpartyName,
      model.bankId,
      model.bankName,
      model.dateFrom,
      model.dateTo
    );
  }

  static ofArray(
    items: Array<CounterpartyBankResponseModel>
  ): Array<CounterpartyBankResponseModel> {
    return items.map((item) => CounterpartyBankResponseModel.of(item));
  }
}
