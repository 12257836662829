export default class InfraCommandResponseModel {
  constructor(
    public description: string,
    public name: string,
    public parameters: ScriptCommandParametersModel,
    public example: Array<string> | Record<string, any>,
    public params: Array<string>,
    public usage: Array<string>,
    public isCommand: boolean
  ) {}

  static ofArray(items: Array<InfraCommandResponseModel>) {
    return items.map(
      (item) =>
        new InfraCommandResponseModel(
          item.description,
          item.name,
          item.parameters,
          { items: item.example, isOpenCollapse: false },
          item.params,
          item.usage,
          item.isCommand
        )
    );
  }
}

export interface ScriptCommandParametersModel {
  execute: ScriptCommandParameterModel;
  rollback: ScriptCommandParameterModel;
}

export interface ScriptCommandParameterModel {
  description: string;
  input: boolean;
  mandatory: boolean;
  name: string;
}
