const INFINITE_VALUE = "Inf.";

export class CellMenuDataModel {
  readonly CELL_MENU_DATA_PRECISION = 2;

  constructor(
    public lang: string,
    public currentValue = 0,
    public maxValue = 0,
    public minValue = 0,
    public maxValueByMaxInstalls = 0
  ) {}

  get deltaFromMax(): string {
    const value =
      this.maxValue !== 0
        ? (this.currentValue - this.maxValue) / this.maxValue
        : 0;

    return this.parseFloatToString(value);
  }

  get deltaFromMin(): string {
    return this.minValue === 0
      ? INFINITE_VALUE
      : this.parseFloatToString(
          (this.currentValue - this.minValue) / this.minValue
        );
  }

  get deltaFromInstalls(): string {
    return this.maxValueByMaxInstalls === 0
      ? INFINITE_VALUE
      : this.parseFloatToString(
          (this.currentValue - this.maxValueByMaxInstalls) /
            this.maxValueByMaxInstalls
        );
  }

  protected parseFloatToString(value: number) {
    const multiplier = Math.pow(10, this.CELL_MENU_DATA_PRECISION);
    const prefix = value > 0 ? "+" : "";

    return `${prefix}${(
      Math.round(value * multiplier * 100) / multiplier
    ).toLocaleString(this.lang, {
      minimumFractionDigits: this.CELL_MENU_DATA_PRECISION,
      maximumFractionDigits: this.CELL_MENU_DATA_PRECISION,
    })}%`;
  }

  setData(
    currentValue: number,
    maxValue: number,
    minValue: number,
    maxValueByMaxInstalls: number
  ) {
    this.currentValue = currentValue;
    this.maxValue = maxValue;
    this.minValue = minValue;
    this.maxValueByMaxInstalls = maxValueByMaxInstalls;
  }
}
