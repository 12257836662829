import {
  Application,
  DatesFilterModel,
  FilterId,
  FilterModel,
  recordToFilterModel,
} from "@/shared/models";
import { LangService } from "@/shared/types/LangType";
import { AdType } from "@/shared/models/AdType";
import { AggregationPeriod, GroupByFilter } from "./GroupByFilter";
import {
  BaseGradientFlags,
  BaseReportFilter,
  ReportFilter,
  ReportResultItem,
} from "./Report";
import { GroupByType, ReportHeader, ReportHeaderUtil } from "./ReportHeader";
import { ReportType } from "./ReportType";
import { ReportItemRowObject } from "./ReportItemRowObject";

export class FirebaseShowToImpressionGradientFlags extends BaseGradientFlags {
  constructor() {
    super();

    this.flags = {
      [`${ReportResultItem.PREFIX}interstitialEventsDiff`]: true,
      [`${ReportResultItem.PREFIX}rewardedEventsDiff`]: true,
    };
  }
}

export class FirebaseShowToImpressionFilter extends BaseReportFilter {
  constructor(
    app: Application,
    filter?: Array<FilterModel>,
    date?: DatesFilterModel,
    public groupByFilter = new GroupByFilter(AggregationPeriod.DAY, [], false)
  ) {
    super(ReportType.FIREBASE_SHOW_TO_IMPRESSION, app, filter, date);
  }

  get invalid(): boolean {
    return !(this.app && this.date.valid);
  }

  static of(
    app: Application,
    requestQuery?: Record<string, any>
  ): FirebaseShowToImpressionFilter {
    return new FirebaseShowToImpressionFilter(
      app,
      requestQuery?.filter?.map((it: any) => recordToFilterModel(it)) || [],
      DatesFilterModel.ofRecord(FilterId.EVENTS_DATE, requestQuery?.date),
      GroupByFilter.of(requestQuery?.groupByFilter)
    );
  }
}

export class FirebaseShowToImpressionHeaders {
  static readonly AD_TYPES = [AdType.INTERSTITIAL, AdType.REWARDED];
  static readonly METRICS = ["Fb", "Diff"];

  static init(
    lang: LangService,
    report: FirebaseShowToImpressionFilter,
    groupBy: GroupByType
  ): Array<ReportHeader> {
    return [
      ReportHeaderUtil.createDate(lang, report.groupByFilter.aggregationPeriod),
      ...(report.groupByFilter.isNotEmptyGroupBy
        ? ReportHeaderUtil.createGroupBy(lang, report.groupByFilter)
        : []),
      ...this.generateFirebaseShowToImpressionHeaders(lang, groupBy),
    ];
  }

  static generateFirebaseShowToImpressionHeaders(
    lang: LangService,
    groupBy: GroupByType
  ): Array<ReportHeader> {
    const result: Array<ReportHeader> = [];

    if (groupBy === GroupByType.AD_TYPES) {
      FirebaseShowToImpressionHeaders.AD_TYPES.forEach((adType) => {
        ["ShowEvents", "Events"].forEach((item) => {
          result.push(
            ...FirebaseShowToImpressionHeaders.METRICS.reduce(
              (result, metric) => {
                if (item === "ShowEvents" && metric === "Diff") {
                  return result;
                }

                return [
                  ...result,
                  {
                    value: `${
                      ReportResultItem.PREFIX
                    }${adType.toLowerCase()}${item}${metric}`,
                    text: lang(
                      `components.baseTable.${adType.toLowerCase()}${item}${metric}`
                    ),
                    align: "end",
                    width: 90,
                    groupBy: {
                      border: {
                        left:
                          metric ===
                            FirebaseShowToImpressionHeaders.METRICS[0] &&
                          item === "ShowEvents",
                        right:
                          adType ===
                            FirebaseShowToImpressionHeaders.AD_TYPES[
                              FirebaseShowToImpressionHeaders.AD_TYPES.length -
                                1
                            ] &&
                          metric ===
                            FirebaseShowToImpressionHeaders.METRICS[
                              FirebaseShowToImpressionHeaders.METRICS.length - 1
                            ] &&
                          item === "Events",
                      },
                      hasBackground:
                        FirebaseShowToImpressionHeaders.AD_TYPES.filter(
                          (_, index) => index % 2 === 0
                        ).includes(adType),
                    },
                    fractionDigits: metric === "Diff" ? 3 : 0,
                    postfix: metric === "Diff" ? "%" : "",
                    hasGradient: metric === "Diff" ? true : false,
                  } as ReportHeader,
                ];
              },
              [] as Array<ReportHeader>
            )
          );
        });
      });
    } else {
      FirebaseShowToImpressionHeaders.METRICS.forEach((metric) => {
        ["Events", "ShowEvents"].forEach((item) => {
          if (item === "ShowEvents" && metric === "Diff") {
            return;
          }

          result.push(
            ...FirebaseShowToImpressionHeaders.AD_TYPES.map(
              (adType) =>
                ({
                  value: `${
                    ReportResultItem.PREFIX
                  }${adType.toLowerCase()}${item}${metric}`,
                  text: lang(
                    `components.baseTable.${adType.toLowerCase()}${item}${metric}`
                  ),
                  align: "end",
                  width: 90,
                  groupBy: {
                    border: {
                      left:
                        adType ===
                          FirebaseShowToImpressionHeaders.AD_TYPES[0] &&
                        item === "Events",
                      right:
                        adType ===
                          FirebaseShowToImpressionHeaders.AD_TYPES[
                            FirebaseShowToImpressionHeaders.AD_TYPES.length - 1
                          ] &&
                        metric ===
                          FirebaseShowToImpressionHeaders.METRICS[
                            FirebaseShowToImpressionHeaders.METRICS.length - 1
                          ] &&
                        item === "Events",
                    },
                    hasBackground:
                      FirebaseShowToImpressionHeaders.METRICS.filter(
                        (_, index) => index % 2 === 0
                      ).includes(metric),
                  },
                  fractionDigits: metric === "Diff" ? 3 : 0,
                  postfix: metric === "Diff" ? "%" : "",
                  hasGradient: metric === "Diff" ? true : false,
                } as ReportHeader)
            )
          );
        });
      });
    }

    return result;
  }
}

export class FirebaseShowToImpressionResultItem extends ReportResultItem {
  constructor(row: ReportItemRowObject, filter: ReportFilter) {
    const { groupByFilter } = filter;

    super();
    this.date = row.getByHeader("date");
    this.setFormattedDate(
      this.date,
      filter.date.from,
      filter.date.to,
      groupByFilter.aggregationPeriod
    );
    this.setGroupByValue(groupByFilter, row);

    this.data["interstitialShowEventsFb"] = row.getByHeader(
      "interstitialShowEventsFb"
    );
    this.data["interstitialEventsFb"] = row.getByHeader("interstitialEventsFb");
    this.data["interstitialEventsDiff"] = row.getByHeader(
      "interstitialEventsDiff"
    );
    this.data["rewardedShowEventsFb"] = row.getByHeader("rewardedShowEventsFb");
    this.data["rewardedEventsFb"] = row.getByHeader("rewardedEventsFb");
    this.data["rewardedEventsDiff"] = row.getByHeader("rewardedEventsDiff");
  }
}
