export default {
  segment: {
    title: "Segments",
    search: "Search",
    showOnlyMy: "Only My",
    newTitle: "New segment",
    viewTitle: "{0} - Segment details",
    detailsTitle: 'Segment "{0}" details',
    goToSegmentList: "Go to segment list",
    tabs: {
      info: "Info",
      filters: "Filters",
      file_upload: "File upload",
      provided_file: "Provided file",
    },
    infoSubtitle: "Set up basic settings and privacy rules.",
    infoAbTestSubtitle: "Representing test group of AB test",
    testName: "Test name: ",
    controlGroup: "Control group: ",
    filtersSubtitle:
      "Select filters to limit audience/events for the segment’s calculation.",
    group: "Group",
    name: "Name",
    description: "Description",
    status: {
      title: "Status",
      created: "Created",
      requested_for_recalculation: "Requested for recalculation",
      recalculation: "Recalculation",
      recalculation_failed: "Recalculation failed",
      active: "Active",
      requested_for_statistics_recalculation:
        "Requested for statistic recalculation",
      statistics_recalculation: "Statistic recalculation",
      statistics_recalculation_failed: "Statistic recalculation failed",
    },
    users: "Users",
    relativeShare: "Relative share",
    type: {
      title: "Type",
      custom_static: "Static",
      custom_dynamic: "Dynamic",
      ab_test: "AB Test",
      divider: "Divider",
      provided: "Provided",
      funnel: "Funnel",
      external_ab_test: "External Test",
    },
    owner: "Owner",
    lastRecalculation: "Last Recalculation",
    lastStatisticRecalculation: "Last Statistic Recalculation",
    divider: {
      btn: "Divider",
      title: "Divider settings",
      upload: {
        title: "Upload new divider file",
        message: "Do you want to upload new divider file?",
      },
      view: {
        title: "Divider file",
      },
      revenue: "Revenue",
    },
    provided: {
      type: {
        title: "User IDs file content type",
        adjust_ids: "Adjust IDs",
        cds_ids: "CDS IDs",
      },
      file: {
        title: "Provided file",
      },
      adjustIdFileInfo:
        "Single column CSV-file containing Adjust ID on each line. <br /> Adjust ID is a 32 character string",
    },
    actions: {
      title: "Actions",
      view: {
        hint: "Display segment details",
      },
      copy: {
        hint: "Open create segment page with predefind fields from segment",
      },
      recalculate: {
        hint: "Recalculates segment",
        title: 'Recalculate segment "{0}"',
        message:
          'Are you sure you want to recalculate segment with name: "{0}"? Segment has time consuming filters.',
      },
      convert: {
        hint: {
          custom_static: "Convert segment to Static",
          custom_dynamic: "Convert segment to Dynamic",
        },
      },
      delete: {
        hint: "Delete segment",
        title: 'Delete segment "{0}"',
        message: 'Are you sure you want to delete segment with name: "{0}"?',
      },
    },
    notification: {
      createdSuccess: `The segment "{0}" was created successfully`,
      updatedSuccess: `The segment "{0}" was updated successfully`,
      deletedSuccess: "Deleted successfully",
    },
  },
};
