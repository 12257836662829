import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { AxiosResponse } from "axios";

import axios, { AxiosSkipErrorsConfig } from "@/shared/plugins/axios";
import DefaultConfigurationModel from "@/ab-tests/models/DefaultConfigurationModel";
import ApplicationResponseModel, {
  EnvironmentType,
} from "../models/ApplicationResponseModel";

@Module
export default class DefaultConfigStore extends VuexModule {
  config: DefaultConfigurationModel = new DefaultConfigurationModel();
  loadingDefaultConfig = true;
  deploymentInProgress = false;

  @Mutation
  setDefaultConfig(payload: DefaultConfigurationModel) {
    this.config = DefaultConfigurationModel.of(payload);
  }

  @Mutation
  setLoadingDefaultConfig(payload: boolean) {
    this.loadingDefaultConfig = payload;
  }

  @Mutation
  setDeploymentInProgress(payload: boolean) {
    this.deploymentInProgress = payload;
  }

  get existsDefaultConfig() {
    // if default config is not exist, then config id equal undefined
    return (
      !this.loadingDefaultConfig &&
      !!this.config.id &&
      !!this.config?.response?.stage
    );
  }

  @Action({ commit: "setDefaultConfig" })
  async loadDefaultConfig() {
    if (!this.context.rootState.application.applicationId) {
      return;
    }

    this.context.commit("setLoadingDefaultConfig", true);
    return axios
      .get(
        `/api/ab-tests/app/${this.context.rootState.application.applicationId}/default/configs`,
        { skipErrors: true } as AxiosSkipErrorsConfig
      )
      .then((result: AxiosResponse<DefaultConfigurationModel>) => {
        return result.data;
      })
      .catch(() => {
        return new DefaultConfigurationModel();
      })
      .finally(() => {
        this.context.commit("setLoadingDefaultConfig", false);
      });
  }

  @Action({ commit: "setDefaultConfig" })
  async createDefaultConfig(config: DefaultConfigurationModel) {
    const payload = ApplicationResponseModel.ofRequest(
      config.response,
      undefined,
      true
    );

    return axios
      .post(
        `/api/ab-tests/app/${this.context.rootState.application.applicationId}/default/configs`,
        payload
      )
      .then((result: AxiosResponse<DefaultConfigurationModel>) => result.data);
  }

  @Action({ commit: "setDefaultConfig" })
  async deployDefaultConfig({
    response,
    environmentType,
  }: {
    response: ApplicationResponseModel;
    environmentType: EnvironmentType;
  }) {
    this.context.commit("setDeploymentInProgress", true);
    const payload = ApplicationResponseModel.ofRequest(
      response,
      environmentType
    );

    return axios
      .post(
        `/api/ab-tests/app/${this.context.rootState.application.applicationId}/default/configs/deploy`,
        payload
      )
      .then((result: AxiosResponse<DefaultConfigurationModel>) => result.data)
      .finally(() => {
        this.context.commit("setDeploymentInProgress", false);
      });
  }
}
