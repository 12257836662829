export default {
  applicationManager: {
    jurisdiction: {
      title: "Jurisdictions",
      singular: "Jurisdiction",
      newJurisdictionTitle: "New jurisdiction",
      editValues: "Edit values for {0}",
      showValuesParams: "Show values params",
      valuesParamsTitle: "Values params",
      jurisdictionName: "Jurisdiction name: ",
      notification: {
        createdSuccess: "The jurisdiction was created successfully",
        updatedSuccess: "The jurisdiction was updated successfully",
      },
    },
    title: "Application manager",
    basicSetting: "Basic setting",
    values: "Values",
    name: "Name",
    description: "Description",
    script: "Script",
    usage: "Usage",
    example: "Example",
    requiredParams: "Required params",
    otherParams: "Other params",
    default: "Default",
    platforms: "Platforms",
    platform: "Platform",
    projectName: "Project name",
    progress: "Progress",
    numberOfStepsInTheScript: "Number of steps in the script",
    clickToPasteInEditor: "Click to paste in editor",
    successfullyCopied: "Successfully copied",
    details: "Details",
    scenario: {
      title: "Scenarios",
      newScenarioTitle: "New scenario",
      singular: "Scenario",
      scriptCommands: "Script commands",
      parameters: "Parameters",
      executeName: "Execute name",
      rollbackName: "Rollback name",
      showScriptCommands: "Show script commands",
      scriptCommandsTitle: "Script commands",
      scenarioName: "Scenario name: ",
      validateScript: "Validate script",
      addSemicolonErrorMessage: "Add a semicolon at the end of the line",
      fixErrorsInScriptHint: "Fix any errors in the script",
      clickToSaveHint: "Click to save",
      notification: {
        createdSuccess: "The scenario was created successfully",
        updatedSuccess: "The scenario was updated successfully",
        updatedScriptSuccess: "The scenario script was updated successfully",
        validationSuccessMessage: "Validation success",
      },
    },
    execution: {
      success: "Success",
      error: "Error",
      status: "Status",
      command: "Command",
    },
    configuration: {
      title: "Configurations",
      singular: "Configuration",
      appId: "Application id",
      profileId: "Profile id",
      key: "Key",
      value: "Value",
      missingKeysText: "<b>Add the missing keys to your configuration:</b> {0}",
    },
    application: {
      createdApplicationsTitle: "Created applications",
      inputParametersTitle: "Input parameters",
      basicSettings: "Basic settings",
      creatingApplicationTitle: "Creating application",
    },
    batch: {
      titleForList: "Batches",
      title: "Batches",
      singular: "Batch",
      newBatchTitle: "New batch",
    },
    applicationBasicInfo: {
      title: "Basic Info for {0}",
      name: "Name",
      platform: "Platform",
      organization: "Organization",
      urlConfigDistribution: "URL Config Distribution",
      urlDefaultAppConfig: "URL Default App Config",
      bundleIds: "Bundle IDs",
      featuresInclude: "Features Include",
      featuresExclude: "Features Exclude",
    },
    applicationFeaturesControl: {
      title: "Features Control for {0}",
      includedFeatures: "Included Features",
      excludedFeatures: "Excluded Features",
      includedFeaturesChanges: "Included Features Changes",
      excludedFeaturesChanges: "Excluded Features Changes",
      updateSuccess: "Application Features have been successfully updated",
    },
    applicationValidation: {
      title: "Config validation for {0}",
      valid: "Configuration properties are valid",
    },
    applicationValidationError: {
      key_mandatory_missed: "Key mandatory missed",
      key_mandatory_incorrect_value: "Key mandatory incorrect value",
      key_unknown_name: "Key unknown name",
      feature_unknown_include: "Feature unknown include",
      feature_unknown_exclude: "Feature unknown exclude",
      feature_include_empty: "Feature include empty",
    },
    applicationConfigs: {
      title: "Applications",
      createTitle: "Create new application",
      relaunchTitle: "Relaunch application",
      applicationName: "Application Name",
      progress: "Progress",
      status: "Status",
      actions: "Actions",
      rerun: "Rerun",
      applicationConfigStatus: {
        completed: "Completed",
        error: "Failed",
        in_progress: "In progress",
      },
      workflow: {
        create_application: "Create",
        set_application_to_archived: "Archive",
      },
      jobName: "Job Name",
      jobStarted: "Started",
      jobEnded: "Ended",
      jobMessage: "Message",
      jobStatus: "Status",
      filterByStatus: "Filter by status",
      addKeys: "Add keys",
      addApplicationConfigKeys: "Add application config keys",
      createApplication: "Create application",
      archiveApp: "Archive application",
      selectAppToArchive: "Select application to archive",
      createApp: {
        applicationId: "Application Id",
        applicationType: "Application Type",
        applicationName: "Application Name",
        applicationPackageNames: "Application Package Names",
        gcpDefaultConfig: "GCP Default Config",
        gcpDefaultProject: "GCP Default Project",
        gcpDefaultLocation: "GCP Default Location",
        abTestsAwsAccessKey: "AB Tests AWS Access Key",
        abTestsAwsSecret: "AB Tests AWS Secret",
        abTestsAwsRegion: "AB Tests AWS Region",
        adjustApplicationToken: "Adjust Application Token",
        adjustAuthToken: "Adjust Auth Token",
        adjustGcpSourceBucket: "Adjust GCP Source Bucket",
        adjustGcpSourceRegion: "Adjust GCP Source Region",
      },
    },
  },
};
