import { UserAccess } from "@/shared/models/UserModel";
import ApplicationResponseModel from "./ApplicationResponseModel";
import { ConfigsStatus } from "./ConfigsStatusModel";
import RuleModel from "./RuleModel";

export default class TargetedConfigurationModel {
  constructor(
    public id?: number,
    public version?: string,
    public name = "",
    public rules: Array<RuleModel> = [],
    public response: ApplicationResponseModel = new ApplicationResponseModel(),
    public activeSince?: string,
    public activeTill?: string,
    public creatorName?: string,
    public creatorId?: string,
    public parentId?: number,
    public status = ConfigsStatus.INIT,
    public deletable = false,
    public disableable = false,
    public editable = false,
    public enableable?: boolean,
    public priority = 0,
    public features: Array<UserAccess> = []
  ) {}

  get hasEditAccess(): boolean {
    return this.features.includes(UserAccess.EDIT);
  }

  get hasDeleteAccess(): boolean {
    return this.features.includes(UserAccess.DELETE);
  }

  get hasDeployAccess(): boolean {
    return this.features.includes(UserAccess.DEPLOY);
  }

  get isStarted(): boolean {
    return this.status === ConfigsStatus.STARTED;
  }

  get isDisabled(): boolean {
    return this.status === ConfigsStatus.DISABLED;
  }

  get isStatusSwitchable(): boolean {
    return (this.disableable || this.isDisabled) && this.hasEditAccess;
  }

  get statusColor(): string {
    return this.status
      ? {
          STATISTICS_RECALCULATED: "success",
          STATISTICS_RECALCULATION: "success",
          STARTED: "light-green",
          GROUPS_DISTRIBUTED: "light-green",
          INIT: "cyan",
          SCHEDULED: "light-blue",
          DISABLED: "orange",
          CANCELED: "error",
          CONFIG_DISTRIBUTION_FINISHED: "blue-grey",
        }[this.status]
      : "";
  }

  static of(model: TargetedConfigurationModel): TargetedConfigurationModel {
    return new TargetedConfigurationModel(
      model.id,
      model.version,
      model.name,
      model.rules.map((it) => RuleModel.of(it)),
      ApplicationResponseModel.of(model.response),
      model.activeSince,
      model.activeTill,
      model.creatorName,
      model.creatorId,
      model.parentId,
      model.status,
      model.deletable,
      model.disableable,
      model.editable,
      model.enableable,
      model.priority,
      model.features
    );
  }
}
