export default class ScenarioResponseModel {
  constructor(
    public id: number,
    public name: string,
    public description: string,
    public script: string
  ) {}

  static ofSource(model: ScenarioResponseModel): ScenarioResponseModel {
    return new ScenarioResponseModel(
      model.id,
      model.name,
      model.description,
      model.script
    );
  }

  static ofArray(items: Array<ScenarioResponseModel>) {
    return items.map(
      (item) =>
        new ScenarioResponseModel(
          item.id,
          item.name,
          item.description,
          item.script
        )
    );
  }
}

export interface ScenarioExecutionModel {
  id: number;
  executionDate: Date | string;
  scenarioId: number;
}

export interface ScenarioValidationScriptModel {
  command: string;
  lineNumber: number;
  message: string;
}
