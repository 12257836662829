export default class NetRevenueResponseModel {
  constructor(
    public id: number,
    public networkIntegrationId: number,
    public dateFrom: string | Date,
    public dateTo: string | Date,
    public net: number
  ) {}

  static of(model: NetRevenueResponseModel): NetRevenueResponseModel {
    return new NetRevenueResponseModel(
      model.id,
      model.networkIntegrationId,
      model.dateFrom,
      model.dateTo,
      model.net
    );
  }

  static ofArray(
    items: Array<NetRevenueResponseModel>
  ): Array<NetRevenueResponseModel> {
    return items.map((item) => NetRevenueResponseModel.of(item));
  }
}
