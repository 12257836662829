import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosResponse } from "axios";
import qs from "qs";
import { uniq } from "lodash";

import axios from "@/shared/plugins/axios";
import {
  DictionaryModel,
  DictionaryType,
  TrackerDictionary,
  TrackerDictionaryModel,
  TrackerOrigin,
  TrackerType,
} from "@/shared/models";

interface TrackerRequest {
  applicationId: string;
  type: TrackerType;
  search: string | null;
  origins?: Array<TrackerOrigin>;
  parentValues?: Array<string>;
}

type StateTrackerDictionaryType =
  | DictionaryType.SOURCES
  | DictionaryType.SUB_SOURCES
  | DictionaryType.CAMPAIGNS
  | DictionaryType.AD_SETS
  | DictionaryType.CREATIVES
  | DictionaryType.PUBLISHERS;

@Module
export default class TrackerStore extends VuexModule {
  dataTrackers: Record<TrackerType, TrackerDictionaryModel> = {
    [DictionaryType.SOURCES]: new TrackerDictionaryModel(),
    [DictionaryType.SUB_SOURCES]: new TrackerDictionaryModel(),
    [DictionaryType.CAMPAIGNS]: new TrackerDictionaryModel(),
    [DictionaryType.AD_SETS]: new TrackerDictionaryModel(),
    [DictionaryType.CREATIVES]: new TrackerDictionaryModel(),
    [DictionaryType.PUBLISHERS]: new TrackerDictionaryModel(),
  };
  loadingTracker: Record<TrackerType, boolean> = {
    [DictionaryType.SOURCES]: false,
    [DictionaryType.SUB_SOURCES]: false,
    [DictionaryType.CAMPAIGNS]: false,
    [DictionaryType.AD_SETS]: false,
    [DictionaryType.CREATIVES]: false,
    [DictionaryType.PUBLISHERS]: false,
  };
  trackerDictionariesToLoad: Record<string, Array<string>> = {};
  isTrackerDictionariesLoading = false;

  @Mutation
  setLoadingTracker(payload: Map<TrackerType, boolean>) {
    payload.forEach((loading, type) => (this.loadingTracker[type] = loading));
  }

  @Mutation
  setDataTracker(payload: Map<TrackerType, TrackerDictionaryModel>) {
    payload.forEach(({ dictionaryValues, isFullResult }, type) => {
      this.dataTrackers[type].update(
        TrackerDictionary.ofArray(dictionaryValues),
        isFullResult
      );
    });
  }

  @Mutation
  clearAllTrackers() {
    this.dataTrackers = {
      [DictionaryType.SOURCES]: new TrackerDictionaryModel(),
      [DictionaryType.SUB_SOURCES]: new TrackerDictionaryModel(),
      [DictionaryType.CAMPAIGNS]: new TrackerDictionaryModel(),
      [DictionaryType.AD_SETS]: new TrackerDictionaryModel(),
      [DictionaryType.CREATIVES]: new TrackerDictionaryModel(),
      [DictionaryType.PUBLISHERS]: new TrackerDictionaryModel(),
    };
    this.loadingTracker = {
      [DictionaryType.SOURCES]: false,
      [DictionaryType.SUB_SOURCES]: false,
      [DictionaryType.CAMPAIGNS]: false,
      [DictionaryType.AD_SETS]: false,
      [DictionaryType.CREATIVES]: false,
      [DictionaryType.PUBLISHERS]: false,
    };
  }

  @Mutation
  setUsedTrackerDictionaries(
    payload: Record<TrackerType, TrackerDictionaryModel>
  ) {
    Object.entries(payload).forEach(([type, { dictionaryValues }]) => {
      this.dataTrackers[type as TrackerType].updateUsedValues(
        TrackerDictionary.ofArray(dictionaryValues)
      );
    });

    this.trackerDictionariesToLoad = {};
  }

  @Mutation
  setTrackerDictionariesLoading(payload: boolean) {
    this.isTrackerDictionariesLoading = payload;
  }

  @Mutation
  setTrackerDictionariesToLoad(
    dictionaries: Record<StateTrackerDictionaryType, Array<string>>
  ) {
    Object.entries(dictionaries).forEach(([dictionaryType, values]) => {
      if (this.trackerDictionariesToLoad[dictionaryType]?.length) {
        this.trackerDictionariesToLoad[dictionaryType] = uniq(
          this.trackerDictionariesToLoad[dictionaryType].concat(values)
        );
      } else {
        this.trackerDictionariesToLoad[dictionaryType] = values;
      }
    });
  }

  @Action({ commit: "setDataTracker" })
  async loadTracker({
    type,
    applicationId,
    parentValues,
    origins,
    search,
  }: TrackerRequest) {
    this.context.commit("setLoadingTracker", new Map([[type, true]]));

    return axios
      .get(`/api/v2/dictionaries/apps/${applicationId}/${type}`, {
        params: {
          ...(search ? { nameValueSubstring: encodeURIComponent(search) } : {}),
          ...(parentValues?.length
            ? {
                parentValues: parentValues
                  .map((item) => encodeURIComponent(item))
                  .join(","),
              }
            : {}),
          ...(origins?.length ? { origins: origins.join(",") } : {}),
        },
        paramsSerializer: (params) => qs.stringify(params, { encode: false }),
      })
      .then(
        ({ data }: AxiosResponse<Record<TrackerType, DictionaryModel>>) =>
          new Map(Object.entries(data))
      )
      .finally(() => {
        this.context.commit("setLoadingTracker", new Map([[type, false]]));
      });
  }

  @Action
  async loadUsedTrackerDictionaries({
    app,
    values,
  }: {
    app: string;
    values: Record<DictionaryType, Array<string>>;
  }) {
    if (Object.keys(values).length) {
      this.context.commit("setTrackerDictionariesToLoad", values);
    }

    if (!Object.keys(this.trackerDictionariesToLoad).length) {
      return;
    }

    this.context.commit("setTrackerDictionariesLoading", true);

    return axios
      .post(
        `/api/v2/dictionaries/apps/${app}/convert`,
        this.trackerDictionariesToLoad
      )
      .then(
        ({ data }: AxiosResponse<Record<DictionaryType, DictionaryModel>>) => {
          this.context.commit("setUsedTrackerDictionaries", data);
        }
      )
      .finally(() => {
        this.context.commit("setTrackerDictionariesLoading", false);
      });
  }
}
