export enum MenuItems {
  HOME = "home",
  REPORT = "report",
  TEMPLATE = "template",
  SEGMENT = "segment",
  HEALTHCHECK_SUMMARY = "healthcheck_summary",
  HEALTHCHECK_PARSING = "healthcheck_parsing",
  HEALTHCHECK_DATAFLOW = "healthcheck_dataflow",
  HEALTHCHECK_JOBS_QUEUE = "healthcheck_jobs_queue",
  FUNNEL = "funnel",
  DASHBOARDS = "dashboards",
  DASHBOARD = "dashboard",
  DASHBOARD_NEW = "dashboard_new",
  DASHBOARD_VIEW = "dashboard_view",
  DASHBOARD_UPDATE = "dashboard_update",
  SETTINGS = "settings",
  AB_TESTS = "abTests",
  TABLEAU_DASHBOARD = "tableauDashboard",
  APPLICATION_MANAGER = "applicationManager",
  USER_ALERTS = "userAlerts",
  TOOLS = "tools",
  ACCOUNT_SETTINGS = "accountSettings",
  IAM = "iam",
  ACCOUNTING_PORTAL = "accountingPortal",
}

export enum MenuItemsAbTests {
  AB_TEST = "abTest",
  EXTERNAL = "external",
  TARGETED = "targeted",
  DEFAULT = "default",
}

export enum MenuItemsApplicationManager {
  JURISDICTIONS = "jurisdictions",
  JURISDICTION_NEW = "jurisdiction_new",
  JURISDICTION_VIEW = "jurisdiction_view",
  APPLICATION_MANAGER = "applicationManager",
  SCENARIOS = "scenarios",
  SCENARIO_VIEW = "scenario_view",
  SCENARIO_NEW = "scenario_new",
  BATCHES = "batches",
  BATCH_VIEW = "batch_view",
  BATCH_NEW = "batch_new",
  APPLICATIONS = "applications",
  APPLICATION_NEW = "application_new",
  CONFIGURATIONS = "configurations",
  CONFIGURATION_VIEW = "configuration_view",
}

export enum MenuItemsTools {
  EXPORT_EVENTS = "export_events",
  MONETIZATION_MONITORING = "MONETIZATION_MONITORING",
  HEALTHCHECK_MONETIZATION_ALERT = "HEALTHCHECK_MONETIZATION_ALERT",
  HEALTHCHECK_MARKETING_ALERT = "HEALTHCHECK_MARKETING_ALERT",
}

export enum MenuItemsSettings {
  AD_REVENUE_EVENTS = "revenue-events",
  AD_REVENUE_EVENTS_NEW = "revenue-events_new",
  AD_REVENUE_EVENTS_VIEW = "revenue-events_view",
  DICTIONARIES = "dictionaries",
  DIVIDER = "segment_new_divider",
  PLACEMENTS = "placements",
  PLACEMENTS_NEW = "placement_new",
  PLACEMENTS_VIEW = "placement_view",
  NETWORKS_MANAGEMENT = "networks-management",
  NETWORKS_MANAGEMENT_NEW = "networks-management_new",
  NETWORKS_MANAGEMENT_VIEW = "networks-management_view",
  SUBSCRIPTION_EVENTS = "subscription-events",
  SUBSCRIPTION_EVENTS_NEW = "subscription-events_new",
  SUBSCRIPTION_EVENTS_VIEW = "subscription-events_view",
}

export enum MenuItemsUsers {
  USERS = "users",
}

export interface MenuRoute {
  name?: string;
  path?: string;
}

export class MenuModel {
  constructor(
    public readonly id: MenuItems,
    public readonly label: string,
    public readonly icon: string,
    public readonly route: MenuRoute,
    public readonly isExact: boolean = false
  ) {}

  static ofArray(menuItems: Array<MenuModel>) {
    return menuItems.map(
      (item) =>
        new MenuModel(item.id, item.label, item.icon, item.route, item.isExact)
    );
  }
}
