export default {
  btn: {
    close: "Close",
    no: "No",
    yes: "Yes",
    cancel: "Cancel",
    ok: "OK",
    save: "Save",
    continue: "Continue",
    back: "Back",
    delete: "Delete",
    deleteAll: "Delete all",
    disable: "Disable",
    activate: "Activate",
    enable: "Enable",
    upload: "Upload",
    recalculate: "Recalculate",
    deploy: "Deploy",
    show: "Show",
    logout: "Logout",
    create: "Create",
    toTheList: "To the list",
    sync: "Sync",
    createAndContinue: "Create and continue",
    nextStep: "Next step",
    start: "Start",
    startNext: "Start next",
    validate: "Validate",
    restart: "Restart",
    apply: "Apply",
    addNewItem: "Add new item",
    update: "Update",
    edit: "Edit",
    reset: "Reset",
    resetAll: "Reset all",
    add: "Add",
    reloadDictionariesCache: "Reload dictionaries cache",
    reloadNetworksCache: "Reload networks cache",
    resetEdgeNodeState: "Reset Edge Node state",
    copy: "Copy",
    resync: "Resync",
    confirm: "Confirm",
    finish: "Finish",
    archive: "Archive",
    downloadPdf: "Download PDF",
  },
};
