import { uniq } from "lodash";

import {
  FilterModel,
  AccessType,
  FilterPreview,
  recordToFilterModel,
  UserAccess,
  AppSection,
  FilterId,
} from "@/shared/models";

export enum SegmentDividerType {
  REVENUE = "REVENUE",
}

export enum SegmentProvidedType {
  ADJUST_IDS = "ADJUST_IDS",
  CDS_IDS = "CDS_IDS",
}

export enum SegmentFunnelType {
  USER_CONVERSION = "USER_CONVERSION",
  REPEATED_CONVERSION = "REPEATED_CONVERSION",
}

export class SegmentFileModel {
  path?: string;
  name?: string;
  content?: string;
}

export enum SegmentType {
  CUSTOM_STATIC = "CUSTOM_STATIC",
  CUSTOM_DYNAMIC = "CUSTOM_DYNAMIC",
  AB_TEST = "AB_TEST",
  EXTERNAL_AB_TEST = "EXTERNAL_AB_TEST",
  DIVIDER = "DIVIDER",
  PROVIDED = "PROVIDED",
  FUNNEL = "FUNNEL",
}

export const SEGMENT_TYPES_FOR_REPORTS = [
  SegmentType.CUSTOM_STATIC,
  SegmentType.CUSTOM_DYNAMIC,
  SegmentType.DIVIDER,
  SegmentType.PROVIDED,
  SegmentType.FUNNEL,
];

export enum SegmentStatus {
  CREATED = "CREATED",
  REQUESTED_FOR_RECALCULATION = "REQUESTED_FOR_RECALCULATION",
  RECALCULATION = "RECALCULATION",
  RECALCULATION_FAILED = "RECALCULATION_FAILED",
  ACTIVE = "ACTIVE",
  REQUESTED_FOR_STATISTICS_RECALCULATION = "REQUESTED_FOR_STATISTICS_RECALCULATION",
  STATISTICS_RECALCULATION = "STATISTICS_RECALCULATION",
  STATISTICS_RECALCULATION_FAILED = "STATISTICS_RECALCULATION_FAILED",
}

export const SEGMENT_ACTIVE_STATUSES = [
  SegmentStatus.ACTIVE,
  SegmentStatus.REQUESTED_FOR_STATISTICS_RECALCULATION,
  SegmentStatus.STATISTICS_RECALCULATION,
  SegmentStatus.STATISTICS_RECALCULATION_FAILED,
];

export default class SegmentModel {
  static AB_TEST_CONTROL_SEGMENT_POSTFIX = "-controlab";

  constructor(
    public applicationId: string,
    public name?: string,
    public type: SegmentType = SegmentType.CUSTOM_DYNAMIC,
    public accessType: AccessType = AccessType.PRIVATE,
    public filter: Array<FilterModel> = [],
    public description?: string,
    public id?: number,
    public groupId?: number,
    public ownerId?: string,
    public ownerName?: string,
    public status?: SegmentStatus,
    public usersShare?: number,
    public usersCount?: number,
    public file: File | null = null,
    public providedType?: SegmentProvidedType,
    public sourceFileName?: string,
    public sourceFilePath?: string,
    public funnelType?: SegmentFunnelType,
    public stepFrom?: number,
    public stepTo?: number,
    public converted?: boolean,
    public bqTableName?: string,
    public lastSuccessfulRecalculationAt?: string,
    public lastSuccessfulStatisticRecalculationAt?: string,
    public createdAt?: string,
    public updatedAt?: string,
    public dividerType?: string,
    public controlSegmentId?: any,
    public features: Array<UserAccess> = [],
    public editable = false,
    public deletable = false
  ) {}

  get previews(): Array<FilterPreview> {
    return this.filter.reduce(
      (result: Array<FilterPreview>, filter: FilterModel) => {
        if (Array.isArray(filter.preview)) {
          result.push(...filter.preview);
        } else if (filter.preview) {
          result.push(filter.preview);
        }

        return result;
      },
      []
    );
  }

  get hasEditAccess(): boolean {
    return this.features.includes(UserAccess.EDIT);
  }

  get hasDeleteAccess(): boolean {
    return this.features.includes(UserAccess.DELETE);
  }

  static of(model: SegmentModel, isCopy = false): SegmentModel {
    return new SegmentModel(
      model.applicationId,
      isCopy ? `${model.name} copy` : model.name,
      model.type,
      model.accessType,
      model.filter
        ? model.filter.map(
            (it) => recordToFilterModel({ ...it, valid: true }) as FilterModel
          )
        : [],
      model.description,
      model.id,
      model.groupId,
      model.ownerId,
      model.ownerName,
      model.status,
      model.usersShare,
      model.usersCount,
      model.file,
      model.providedType,
      model.sourceFileName,
      model.sourceFilePath,
      model.funnelType,
      model.stepFrom,
      model.stepTo,
      model.converted,
      model.bqTableName,
      model.lastSuccessfulRecalculationAt,
      model.lastSuccessfulStatisticRecalculationAt,
      model.createdAt,
      model.updatedAt,
      model.dividerType,
      model.controlSegmentId,
      model.features,
      model.editable,
      model.deletable
    );
  }

  static ofArray(segments: Array<SegmentModel>): Array<SegmentModel> {
    return segments.map((segment) => SegmentModel.of(segment));
  }

  getUsedDictionaryValues(): Record<string, Array<string>> {
    const usedDictionaryValues: Record<string, Array<string>> = {};

    this.filter.forEach((filter: FilterModel) => {
      if (filter.id === FilterId.TRACKER) {
        return;
      }

      filter.getUsedDictionaryValues().forEach((values, dictionary) => {
        usedDictionaryValues[dictionary] = uniq(
          (usedDictionaryValues[dictionary] || []).concat(values)
        );
      });
    });

    return usedDictionaryValues;
  }

  getUsedTrackerDictionaryValues(): Record<string, Array<string>> {
    const usedTrackerDictionaryValues: Record<string, Array<string>> = {};
    const trackerFilter = this.filter.find(
      ({ id }: FilterModel) => id === FilterId.TRACKER
    );

    if (trackerFilter) {
      trackerFilter.getUsedDictionaryValues().forEach((values, dictionary) => {
        usedTrackerDictionaryValues[dictionary] = uniq(
          (usedTrackerDictionaryValues[dictionary] || []).concat(values)
        );
      });
    }

    return usedTrackerDictionaryValues;
  }
}

export const SEGMENT_TYPE_CONVERTIONS: Map<SegmentType, SegmentType> = new Map([
  [SegmentType.CUSTOM_STATIC, SegmentType.CUSTOM_DYNAMIC],
  [SegmentType.CUSTOM_DYNAMIC, SegmentType.CUSTOM_STATIC],
]);

export const SEGMENT_TYPE_TO_APP_SECTION: Record<SegmentType, AppSection> = {
  [SegmentType.AB_TEST]: AppSection.AB_TESTS,
  [SegmentType.CUSTOM_DYNAMIC]: AppSection.CUSTOM_DYNAMIC_SEGMENTS,
  [SegmentType.CUSTOM_STATIC]: AppSection.CUSTOM_STATIC_SEGMENTS,
  [SegmentType.DIVIDER]: AppSection.DIVIDER_SEGMENTS,
  [SegmentType.EXTERNAL_AB_TEST]: AppSection.EXTERNAL_TESTS,
  [SegmentType.FUNNEL]: AppSection.FUNNEL_SEGMENTS,
  [SegmentType.PROVIDED]: AppSection.PROVIDED_SEGMENTS,
};
