import {
  AdType,
  Application,
  DatesFilterModel,
  DefaultValues,
  FilterId,
  FilterModel,
  FilterPreview,
  FilterPreviewId,
  recordToFilterModel,
} from "@/shared/models";
import { LangService } from "@/shared/types/LangType";
import { GroupByFilter } from "./GroupByFilter";
import {
  MultiAppsReportFilter,
  ReportFilter,
  ReportResultItem,
} from "./Report";
import { AdTypeReportFilterExtension } from "./ReportFilterExtension";
import { ReportHeader, ReportHeaderUtil } from "./ReportHeader";
import { ReportType } from "./ReportType";
import { ChartName } from "@/chart/models/ChartModel";
import { ReportItemRowObject } from "./ReportItemRowObject";

export class WaterfallFilter
  extends MultiAppsReportFilter
  implements AdTypeReportFilterExtension
{
  adType: AdType;

  constructor(
    availableApps: Array<Application>,
    platforms?: Array<string>,
    apps?: Array<string>,
    includedApps?: boolean,
    filter?: Array<FilterModel>,
    date: DatesFilterModel = DefaultValues.initDatesFilterModel(
      FilterId.EVENTS_DATE
    ),
    groupByFilter: GroupByFilter = new GroupByFilter(undefined, []),
    adType = AdType.INTERSTITIAL
  ) {
    super(
      ReportType.WATERFALL,
      availableApps,
      platforms,
      apps,
      includedApps,
      filter,
      date,
      groupByFilter
    );

    this.adType = adType;
  }

  get previews(): Array<FilterPreview> {
    return [
      ...super.previews,
      ...(this.adType
        ? [
            {
              id: FilterPreviewId.AD_TYPE,
              value: this.adType,
            },
          ]
        : []),
    ];
  }

  get charts(): Array<ChartName> {
    return [ChartName.SOV_CHART, ChartName.SOW_CHART];
  }

  get groupedCharts(): Array<ChartName> {
    return this.groupByFilter.isNotEmpty
      ? [ChartName.SOV_CHART, ChartName.SOW_CHART]
      : [];
  }

  static of(
    availableApps: Array<Application>,
    requestQuery?: Record<string, any>
  ): WaterfallFilter {
    return new WaterfallFilter(
      availableApps,
      requestQuery?.platforms as Array<string>,
      requestQuery?.apps as Array<string>,
      typeof requestQuery?.includedApps === "string"
        ? requestQuery?.includedApps === "true"
        : requestQuery?.includedApps,
      requestQuery?.filter?.map((it: any) => recordToFilterModel(it)) || [],
      DatesFilterModel.ofRecord(FilterId.EVENTS_DATE, requestQuery?.date),
      GroupByFilter.of(requestQuery?.groupByFilter),
      requestQuery?.adType as AdType
    );
  }

  toRequestQuery(): Record<string, any> {
    return {
      ...super.toRequestQuery(),
      adType: this.adType,
    };
  }
}
export class WaterfallHeaders {
  static init(lang: LangService, report: WaterfallFilter): Array<ReportHeader> {
    return [
      ...(report.groupByFilter.aggregationPeriod
        ? [
            Object.assign(
              ReportHeaderUtil.createDate(
                lang,
                report.groupByFilter.aggregationPeriod
              ),
              { isGrouped: true }
            ),
          ]
        : []),
      ...(report.groupByFilter.isNotEmptyGroupBy
        ? ReportHeaderUtil.createGroupBy(lang, report.groupByFilter, 200)
        : []),
      {
        text: lang("components.baseTable.name"),
        value: `${ReportResultItem.PREFIX}name`,
      },
      {
        text: lang("components.baseTable.requests"),
        align: "end",
        value: `${ReportResultItem.PREFIX}requests`,
        hasGradient: true,
        fractionDigits: 0,
      },
      {
        text: lang("components.baseTable.impressions"),
        align: "end",
        value: `${ReportResultItem.PREFIX}impressions`,
        hasGradient: true,
        fractionDigits: 0,
      },
      {
        text: lang("components.baseTable.fillRate"),
        align: "end",
        value: `${ReportResultItem.PREFIX}fillRate`,
        hasGradient: true,
        fractionDigits: 2,
      },
      {
        text: lang("components.baseTable.clicks"),
        align: "end",
        value: `${ReportResultItem.PREFIX}clicks`,
        hasGradient: true,
        fractionDigits: 0,
      },
      {
        text: lang("components.baseTable.ctr"),
        align: "end",
        value: `${ReportResultItem.PREFIX}ctr`,
        hasGradient: true,
        fractionDigits: 2,
      },
      {
        text: lang("components.baseTable.revenue"),
        align: "end",
        value: `${ReportResultItem.PREFIX}revenue`,
        hasGradient: true,
      },
      {
        text: lang("components.baseTable.ecpm"),
        align: "end",
        value: `${ReportResultItem.PREFIX}ecpm`,
        hasGradient: true,
        fractionDigits: 2,
      },
      {
        text: lang("components.baseTable.cpc"),
        align: "end",
        value: `${ReportResultItem.PREFIX}cpc`,
        hasGradient: true,
        fractionDigits: 2,
      },
    ];
  }
}

export class WaterfallResultItem extends ReportResultItem {
  constructor(row: ReportItemRowObject, filter: ReportFilter) {
    super();

    this.setGroupByValue(filter.groupByFilter, row);

    if (row.getByHeader("date")) {
      this.date = row.getByHeader("date");
      this.setFormattedDate(
        this.date,
        filter.date.from,
        filter.date.to,
        filter.groupByFilter.aggregationPeriod
      );
    }

    this.data["name"] = row.getByHeader("name");
    this.data["requests"] = row.getByHeader("requests");
    this.data["impressions"] = row.getByHeader("impressions");
    this.data["fillRate"] = row.getByHeader("fillRate");
    this.data["clicks"] = row.getByHeader("clicks");
    this.data["ctr"] = row.getByHeader("ctr");
    this.data["revenue"] = this.parseValue(row.getByHeader("revenue"));
    this.data["ecpm"] = row.getByHeader("ecpm");
    this.data["cpc"] = row.getByHeader("cpc");
  }

  protected parseValue(value: string) {
    return Number.parseFloat(value) >= 100
      ? super.parseFloat(value)
      : super.parseFloat(value, 2);
  }
}
