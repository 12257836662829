export default {
  dashboard: {
    title: {
      default: "Dashboard",
      favorite: "Favorite dashboard",
      multiapp_dashboards: "Multiple applications dashboards",
      singleapp_dashboards: "Single application dashboards",
      dashboard_view: "Dashboard",
      dashboard_new: "Create new dashboard",
    },
    deleteDialogHeading: `Delete dashboard "{0}"`,
    deleteDialogContent: `Are you sure you want to delete dashboard with name: "{0}"?`,
    delete: "Delete dashboard",
    edit: "Edit dashboard",
    makeFavorite: "Make favorite",
    reportTemplates: "Report templates",
    funnelTemplates: "Funnel templates",
    selectedTemplates: "Selected templates",
    templateCharts: "Charts: {0}",
    search: "Search",
    back: "Back",
    nextStep: "Next step",
    createTitle: "Create new dashboard",
    editTitle: "Editing: {0}",
    stepTemplates: "Templates",
    stepCharts: "Charts",
    stepInfo: "Info",
    name: "Dashboard name",
    accessType: "Access type",
    description: "Description",
    selectedCharts: "Selected charts",
    availableCharts: "Available charts",
    favorite: "Favorite",
    editDashboardTitle: "Edit dashboard",
    selectLabel: "Select dashboard",
    emptySelectedTemplatesMessage:
      "Select templates from the list of available",
    emptySelectedChartsMessage: "Select charts from the list of available",
    noFavoriteText: "You don't have favorite dashboard. You can",
    noFavoriteLink: "choose it from the list",
    notification: {
      createSuccess: `Dashboard "{0}" was created successfully`,
      deleteSuccess: `Dashboard "{0}" was deleted successfully`,
      updateSuccess: `Dashboard "{0}" was updated successfully`,
      setDefaultSuccess: `Dashboard "{0}" was successfully set by default`,
      unsetDefaultSuccess: `Dashboard "{0}" was successfully stopped being selected by default`,
      unavailable: `Part of requested dashboard is unavailable: {0}`,
      noCharts: "Charts cannot be displayed",
    },
    type: {
      title: "Dashboard type",
      single_app: "Single application",
      multi_app: "Multiple applications",
    },
  },
};
