




























































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import MenuUtil from "@/shared/utils/MenuUtil";
import { AppSection } from "@/shared/models";

@Component
export default class SettingsMenu extends Vue {
  @Prop() applicationId!: string;

  isActiveListGroup = false;

  get settingsMenuItems(): Array<Record<string, any>> {
    return MenuUtil.getSettingMenuItems(
      this.$store.state.userStore.currentUser.viewAccessEntities[
        this.applicationId
      ]
    );
  }

  @Watch("$route", { deep: true, immediate: true })
  watchRoute() {
    this.isActiveListGroup = [
      AppSection.DICTIONARIES_APP_VERSIONS,
      AppSection.DICTIONARIES_OS_VERSIONS,
    ].includes(this.$route.name as AppSection);
  }
}
