export class JurisdictionValueModel {
  constructor(public id?: string, public name = "", public value = "") {}

  static ofArray(items: Array<JurisdictionValueModel>) {
    return items.map(
      (item) => new JurisdictionValueModel(item.id, item.name, item.value)
    );
  }
}

export default class JurisdictionResponseModel {
  constructor(
    public id: number,
    public name: string,
    public description: string,
    public values: Array<JurisdictionValueModel>
  ) {}

  static ofSource(model: JurisdictionResponseModel): JurisdictionResponseModel {
    return new JurisdictionResponseModel(
      model.id,
      model.name,
      model.description,
      model.values
    );
  }

  static ofArray(items: Array<JurisdictionResponseModel>) {
    return items.map(
      (item) =>
        new JurisdictionResponseModel(
          item.id,
          item.name,
          item.description,
          item.values
        )
    );
  }
}
