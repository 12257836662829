import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { AxiosResponse } from "axios";

import axios from "@/shared/plugins/axios";
import AlertResponseModel, {
  AlertFilterModel,
} from "@/alerts-system/models/AlertResponseModel";
import AlertRequestModel from "@/alerts-system/models/AlertRequestModel";

@Module
export default class AlertsSystemStore extends VuexModule {
  isLoading = false;
  isSavingInProgress = false;
  alerts: Array<AlertResponseModel> = [];
  alertFilter: AlertFilterModel | null = null;
  systemAlerts: Array<AlertResponseModel> = [];
  alert: AlertResponseModel | null = null;

  @Mutation
  setAlertsLoading(payload: boolean) {
    this.isLoading = payload;
  }

  @Mutation
  setIsSavingInProgress(payload: boolean) {
    this.isSavingInProgress = payload;
  }

  @Mutation
  setAlerts(payload: Array<AlertResponseModel>) {
    this.alerts = AlertResponseModel.ofArray(payload);
  }

  @Mutation
  setSystemAlerts(payload: Array<AlertResponseModel>) {
    this.systemAlerts = AlertResponseModel.ofArray(payload);
  }

  @Mutation
  clearSystemAlerts() {
    this.systemAlerts = [];
  }

  @Mutation
  setAlert(payload: AlertResponseModel) {
    this.alert = AlertResponseModel.ofSource(payload);
  }

  @Mutation
  updateAlerts(id: number) {
    this.alerts = this.alerts.filter((alert) => alert.id !== id);
  }

  @Mutation
  updateSystemAlerts(id: number) {
    this.systemAlerts = this.systemAlerts.filter((alert) => alert.id !== id);
  }

  @Mutation
  setAlertFilter(payload: AlertFilterModel | null) {
    this.alertFilter = payload;
  }

  @Action({ commit: "setAlerts" })
  async loadAlerts() {
    this.context.commit("setAlertsLoading", true);
    return axios
      .get("/api/user-alerts")
      .then((result: AxiosResponse<Array<AlertResponseModel>>) => result.data)
      .finally(() => {
        this.context.commit("setAlertsLoading", false);
      });
  }

  @Action({ commit: "setSystemAlerts" })
  async loadSystemAlerts() {
    return axios
      .get("/api/user-alerts/filter", {
        params: this.alertFilter,
      })
      .then(({ data }: AxiosResponse<Array<AlertResponseModel>>) => data);
  }

  @Action({ commit: "setAlert" })
  async loadAlert(id: string) {
    this.context.commit("setAlertsLoading", true);
    return axios
      .get(`/api/user-alerts/${id}`)
      .then((result: AxiosResponse<AlertResponseModel>) => result.data)
      .finally(() => {
        this.context.commit("setAlertsLoading", false);
      });
  }

  @Action
  async createAlert(alert: AlertRequestModel) {
    const payload = AlertRequestModel.ofRequest(alert);

    return axios.post("/api/user-alerts", payload).then(() => {
      this.context.commit("addNotification", {
        message: {
          key: "alertsSystem.notification.createdSuccess",
        },
      });
    });
  }

  @Action({ commit: "updateAlerts" })
  async deleteAlert(id: number) {
    this.context.commit("setAlertsLoading", true);
    return axios
      .delete(`/api/user-alerts/${id}`)
      .then(() => id)
      .finally(() => {
        this.context.commit("setAlertsLoading", false);
      });
  }

  @Action
  async updateAlert(alert: AlertRequestModel) {
    const payload = AlertRequestModel.ofRequest(alert);

    return axios.put(`/api/user-alerts/${alert.id}`, payload).then(() => {
      this.context.commit("addNotification", {
        message: {
          key: "alertsSystem.notification.updatedSuccess",
        },
      });
    });
  }

  @Action
  async changeAlertVisibility({
    alertId,
    payload,
  }: {
    alertId: number;
    payload: Record<string, any>;
  }) {
    this.context.commit("setAlertsLoading", true);

    return axios
      .patch(`/api/user-alerts/${alertId}`, payload)
      .then((result: AxiosResponse<Array<AlertResponseModel>>) => result.data)
      .finally(() => {
        this.context.commit("setAlertsLoading", false);
      });
  }

  @Action({ commit: "updateSystemAlerts" })
  async hideSystemAlertById(id: number) {
    return axios.put(`/api/user-alerts/hide/${id}`).then(() => id);
  }
}
