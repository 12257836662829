export default {
  abTestsTitle: "AB Tests Panel",
  defaultConfig: {
    title: "Default config",
    dialogTitle: "Production config",
  },
  targetedConfig: {
    title: "Targeted configs",
    newTitle: "New targeted config",
    editTitle: "Edit targeted config",
    editConfigsTitle: 'Edit "{0}" targeted configs',
    actions: {
      disable: "Disable targeted config",
      disableConfirmationMessage:
        "Target config has active children. If you disable this config, children will be disabled as well.",
      activate: "Activate targeted config",
      editConfigs: "Edit targeted configs",
      editItem: "Edit targeted item",
      settings: "Settings",
      config: "Config",
    },
    priorityChanged: "Priority changed",
    moveToTop: "Move to top",
    error: {
      onlyExcludeRules:
        "Unable to create a Target Config containing only exclude rules",
      rulesRequired: "Rules cannot be empty",
      maxDateInRules: "Max date in rules cannot be later than active till date",
      activeSinceLaterThanActiveTill:
        "Active since date cannot be later than active till date",
      currentDateLaterThanActiveSince:
        "Current date cannot be later than active since date",
    },
    status: {
      title: "Status",
      init: "Inited",
      started: "Started",
      canceled: "Canceled",
      disabled: "Disabled",
      scheduled: "Scheduled",
      active: "Active",
      finished: "Finished",
      config_distribution_finished: "Config Distribution Finished",
    },
    start: "Start",
    end: "End",
    stepLabel: {
      init: "Inited",
      scheduled: "Scheduled",
      started: "Active",
      config_distribution_finished: "Finished",
    },
    currentState: {
      title: "Current State",
      fillConfigs: "You should fill production configs",
      waiting: "Waiting <b>{0}</b> to start",
      waitingToFinish: "Waiting <b>{0}</b> to finish",
      active: "Active",
      configDistributionFinished: "Config distribution finished",
      finished: "Activity period ended.",
    },
  },
  abTestConfig: {
    title: "AB Test configs",
    newTitle: "New AB Test config",
    settingsTitle: "AB Test settings",
    editConfigsTitle: 'Edit "{0}" AB Test configs',
    selectTargetedConfig: "Select a targeted config",
    version: "ver. {0}",
    error: {
      assignmentTill: '"Assignment till" should be greater than "Active till"',
      onlyExcludeRules:
        "Unable to create a AB Test containing only exclude rules",
    },
    segmentNameHint: "Should not contain symbols: {0}",
    actions: {
      disable: "Disable AB Test",
      delete: "Delete AB Test",
      activate: "Activate AB Test",
      edit: "Edit AB Test",
      editItem: "Edit AB Test item",
      editConfigs: "Edit AB Test configs",
      deleteResponse: "Delete response",
      finish: "Finish",
      save: {
        duplicateConfigTitle: "Duplication configs",
        duplicateConfigMessage:
          "AB Test has duplicate configs. Are you sure you want to save it?",
      },
    },
    inputSampleHint: "The serial number of the user when receiving the config.",
    samplingRateTooltip: "Amount of auditory to get into the test",
    inputAssignmentHint:
      "Date when user will stop receiving configs from abtest and switch to default of target config.",
    response: {
      status: {
        readyToTest: "Ready to test",
        testing: "Testing",
        tested: "Tested",
      },
      btn: {
        activeDeploy: "Deploy config to stage",
        testedDeploy: "You can deploy only untested configurations",
        disabledDeploy: "You can deploy only one config at a time",
      },
      tabName: "Test response {0}",
    },
    segmentStatisticNotFound: "N/A",
    responsesNotFound:
      "The ABTest is in inconsistent state. Please, contact support team for details.",
    notification: {
      createdSuccess: `The AB Test "{0}" was created successfully`,
      updatedSuccess: `The AB Test "{0}" was updated successfully`,
      updatedStatusSuccess: "The AB Test status was updated successfully",
      deleteSuccess: `The AB Test was deleted successfully`,
      cantBeEnabled: "Can't be enabled with inactive parent targeted config",
    },
    addDefaultConfig: "You should add default config first",
    abTestTypeTitle: "AB Test Type",
    dayNumber: "Compare target metric on day",
    assignmentTillUsersPerGroup:
      "Minimum user count per test group needed (approx)",
    assignmentTillUsersResult: "User count needed for correct test (approx)",
    abTestMetricType: "AB Test target metric",
    metricType: {
      arpu: "ARPU",
      retention_rate: "Retention Rate",
      time_spent: "Time Spent",
    },
    abTestType: {
      title: "Type",
      new_users: "New users",
      audience: "Audience",
    },
    copyFromOtherGroups: "Copy from other groups",
  },
  commonConfig: {
    name: "Name",
    segments: "Segments",
    segmentName: "Segment name",
    testGroups: "Test Groups",
    testGroupsCount: "Test groups count",
    testGroup: "Test group {0}",
    affectedAuditory: "Affected auditory",
    assignmentTill: "Assignment till",
    activeSince: "Active since",
    activeTill: "Active till",
    creator: "Creator",
    rules: "Rules",
    audience: "Audience",
    usersCount: "Users count",
    config: "Config",
    enabled: "Enabled",
    search: "Search",
    parentConfig: "Parent config",
    settings: "Settings",
    configs: "Configs",
    showEnabled: "Show enabled configs",
    showDisabled: "Show disabled configs",
    showEnded: "Show ended configs",
    actions: "Actions",
    confirmDeployProdText:
      "Are you sure you want to deploy an unchecked config?",
    confirmDeployStageText: "Are you sure you want to deploy this config?",
    inputActiveSinceHint: "Date when configuration distribution starts",
    inputActiveTillHint: "Date when configuration distribution ends",
    deployAndSync: "Deploy and sync with {0}",
    deploy: "Deploy to test",
    deployedToProd: "Deployed to production",
    deployedToStage: "Deployed to stage",
    deployYourChanges: "Deploy your changes",
    activeDeploy: "Deploy to prod",
    disabledDeploy:
      "Deploy to prod. Only after at least one request from stage for all responses.",
    invalidDeploy: "Deploy to prod. Invalid JSON",
    changedDeploy: "Deploy to prod. Stage config was changed",
    isNotDeployedParent: "Deploy to prod. Need to deploy parent",
    priority: "Priority",
    enabledConfigs: "Enabled configs",
    disabledConfigs: "Disabled configs",
    endedConfigs: "Ended configs",
    type: "Type",
    users: "Users",
    toProduction: "To Production",
    toStage: "To Stage",
    stage: "stage",
    production: "production",
    duplicationConfigs: "Duplication configs",
    controlGroup: "Control group",
    deployToStageHint: "Deploy to Stage",
    deployToProdHint: "Deploy to Prod",
    transferToProdHint: "Transfer to production",
    transferToStageHint: "Transfer to stage",
    statusTitle: "Status",
    hypothesis: "Hypothesis",
    minimalDetectableRelativeEffect: "Minimal detectable relative effect",
    estimatedValue: "Estimated value",
    estimatedStd: "Estimated STD",
    usersSampleSize: "Users sample size",
    usersSampleSizeWarning:
      "Set Minimal detectable relative effect or users sample size",
    usersCountStatus: {
      default:
        "Unable to sample enough users from the test audience to estimate the target metric based on historical data and evaluate the recommended sample size for AB-test. However, using at least <strong>{0} users</strong> for each test and control group is recommended. In this case, the minimal detectable effect size for the target metric as well as the statistical power of the experiment can't be estimated in advance",
      for_select_audience:
        "It is recommended to use at least <strong>{0} users</strong> for each test and control group to be able to detect the desirable minimal effect size for the target metric. The recommendation is based on historical data of the test audience",
      for_select_audience_without_versions:
        "It is recommended to use at least <strong>{0} users</strong> for each test and control group. The recommendation is based on historical data of test-like audience ignoring the OS and App version parameters, thereby it can be inaccurate regarding the minimal detectable effect size for the target metric as well as the statistical power of the experiment",
      for_all_audience:
        "It is recommended to use at least <strong>{0} users</strong> for each test and control group. The recommendation is based on historical data of all audience, thereby it can be inaccurate regarding the minimal detectable effect size for the target metric as well as the statistical power of the experiment",
    },
    usersCountLoadingWarning:
      "Estimating recommended user groups sizes. Please wait, calculation could take a while",
    testInfo: "Click here to open FAQ",
  },
  jsonEditor: {
    btn: {
      viewProd: "View prod config",
      copyProduction: "Copy production configuration",
      emptyProduction:
        "Copy production configuration. No production configuration deployed yet",
    },
    confirm: "Do you really want to deploy to production?",
    defaultValue: "Default value",
    rewrites: "rewrites",
    overwritten: "Overwritten",
    key: "Key",
    value: "Value",
    prodValue: "Prod Value",
    name: "Name",
    actions: "Actions",
    description: "Description",
    valueType: "Value type",
    importJson: "Import JSON file",
    exportJson: "Export JSON file",
    notification: {
      successExport: "File exported successfully",
      successImport: "File imported successfully",
      errorExport: "File was exported with an error",
      errorImport: "File was imported with an error",
    },
  },
  rules: {
    new: "New rule",
    edit: "Edit rule",
    include: "Include",
    exclude: "Exclude",
    userId: "User id",
    userProperty: "User property",
    inputCdsId: "CDS id",
    inputCdsIdHint:
      "Csv file should comply with the rules:\n 1. No header line \n 2. No empty lines \n 3. No separators \n 4. All ids should be positive numeric",
    showCdsId: "Show CDS Id",
    languages: "Current languages",
    countries: "Current countries",
    deviceTypes: "Device types",
    deviceModels: "Device models",
    appVersions: "Current app versions",
    osVersions: "Current os versions",
    isLatEnabled: "Lat enabled",
    segments: "Segments",
    downloadCsvFile: "Download CSV-file",
    noRulesText: "No additional rules",
    addButtonTooltip: "Add new filtration rule",
    installDates: {
      absolute_value: "Install dates",
      users_age: "User's age",
    },
    usersAge: {
      between: "between {0} and {1} days",
      greater_or_equal: "≥ {0} days",
      less_or_equal: "≤ {0} days",
    },
    dateRangeOperator: {
      between: "Between",
      greater_or_equal: "Greater or equal",
      less_or_equal: "Less or equal",
      label: "Days",
    },
    customProperties: {
      title: "Custom Properties",
      key: "Key",
      value: "Value",
    },
    deleteRule: "Delete rule",
    warningMatchForRule: "Nobody would match because of Exclude rule #{0}",
  },
  combobox: {
    selectAll: "Select All",
    others: "(+{0})",
  },
  warning: "Warning",
  applicationItem: {
    abTest: "AB Test",
    targetedConfig: "Targeted Config",
    defaultConfig: "Default Config",
  },
  components: {
    lat: {
      label: "LAT",
      values: {
        any: "Any",
        on: "ON Only",
        off: "OFF Only",
      },
    },
  },
  shared: {
    applicationDialog: {
      application: "Application",
      title: "Select active application",
      search: "Search applications",
      name: "Name",
      id: "ID",
      attentionTitle: "Attention!",
      attentionTextForChangedApplication:
        "Are you sure you want to change an application? All entered data will be removed.",
      attentionTextForLeave:
        "Are you sure you want to leave from page? All entered data will be removed.",
    },
    notification: {
      next: `Next ("{0}") more`,
      close: "Close",
    },
  },
  configResponseEditor: {
    version: "Version: {0}",
    useHierarchy: "Use hierarchy for overwrittens",
    dialogDeleteTitle: 'Are you sure you want to delete "{0}"?',
    notSupportedYet: "Not supported yet",
    form: {
      newItemTitle: "New Item",
      editItemTitle: "Edit Item",
      overwriteItemTitle: "Overwrite item",
      add: "Add new Item",
      deleteSelected: "Delete selected",
    },
    enterSomeSymbols: "Enter at least {0} symbols to see configs",
    nothingFound: "Nothing found",
    stage: "Stage",
    prod: "Prod",
    abTests: "AB Tests",
    targetedConfigs: "Targeted configs",
    nestedItemsCount: "{0} nested items",
    configsWithThisValue: "Configs with this value: ",
    goToAbTest: "Go to AB Test",
    goToTargetedConfig: "Go to targeted config",
    goToResponses: "Go to responses",
    goToDefaultConfig: "Go to default config",
    ab_test: "AB Test",
    targeted: "Targeted",
    default: "Default",
    notDeployedChanges: "You have not-deployed changes",
    arrayRule:
      'Value should be written as valid JSON array:<br/>* Wrap list of values in brackets -> []<br/>* Wrap values with string type in double quotes -> ["string value1", "string value 2" ]<br/>* Numeric, String and complex types supported<br/>* Any level of nested arrays supported<br/>* You can mix data type in array, but we don`t recommend this -> [1, 2, 3, "String 1", ["nested array string 1", 2] ]',
    objectRule:
      'Value should be written as valid JSON object:<br/>* Specify key-value pairs between curly braces -> { }<br/>* Wrap key`s names and values with string type in double quotes -> { "key" : "string value" }<br/>* Any level of nested arrays / objects supported<br/>* Built in validation in editor will help you<br/>Example:<br/>{<br/>"field1": 12,<br/>"field2": "String",<br/>"field3": [1, 2, "string1", 3, "string2"],<br/>"field4": { subObjectField1: [1, 2, 3] }<br/>}',
    hideParentConfig: "Hide parent config",
    showParentConfig: "Show parent config",
    confirmDialogTitle: "Confirm deployable items",
    addedConfigItem: "Added config item",
    editedConfigItem: "Edited config item",
    deletedConfigItem: "Deleted config item",
    alertInfoText: `When deploying new default config, all targeted configs will be updated. <br />
      AB Test configs in all statuses except Inited will be unchanged.<br />
      AB Test configs in Inited status will be updated to the latest version of default config after AB Test config will be deployed`,
    copyConfigFrom: "Copy other config from",
  },
  rulePreview: {
    languages: "Languages",
    countries: "Countries",
    device_types: "Device types",
    device_models: "Device models",
    app_versions: "App versions",
    os_versions: "OS versions",
    segments: "Segments",
    csv_file_id: "CSV file id",
    users_age: "Users age",
    lat: "LAT",
    install_dates: "Install dates",
    custom_properties: "Custom properties",
  },
  abTestType: {
    title: "Type",
    new_users: "New users",
    audience: "Audience",
    external_ab_test: "External",
    ab_test: "Ab Test",
  },
  abTest: {
    start: "Start",
    end: "End",
    dayN: "Day N",
    rules: "Rules",
    segments: "Segments",
    testResult: "Test Result",
    generateReport: "Generate report",
    statisticalSignificance: "Statistical significance",
    download: "Download",
    downloadPrevPeriod: "Download prev period",
    prev: "prev",
    result: "Result",
    previousPeriodValidation: "Previous period validation",
    testPeriod: "Test period",
    metric: "Metric",
    relativeEffect: "Relative Effect (diff vs ci)",
    metricType: {
      arpu: "ARPU",
      retention_rate: "Retention Rate",
      time_spent: "Time Spent",
    },
    summary: {
      caption: "<strong>Summary:</strong> ",
      positive: `As a result of the experiment, test group <strong>{0}</strong> showed the highest improvement <strong>in {2} on Day {3} with an <span class="green--text">increase of at least {1}</span></strong>.`,
      inconsistentPositive: ` Please consider the <strong class="red--text">negative side effects</span> identified in non-target metrics within test group <strong>{0}</strong> when interpreting the results and making further decisions.`,
      uncertain: `None of the test groups demonstrated statistically significant positive results concerning <strong>{0} on Day {1}</strong>. It should be noted the <strong class="green--text">positive side effects</strong> detected on non-target metrics in the test groups, which could be subjects for further investigation.`,
      negative: `Based on the performance of <strong>{0} on Day {1}</strong>, the control group is considered the most optimal variant.`,
    },
    chart: {
      confidenceInterval: "Confidence interval",
      controlGroup: "Control group",
      targetGroup: "Target group",
      seriesUnit: {
        arpu: "$",
        retention_rate: "%",
        time_spent: " min",
      },
    },
    usersCount: "Users count",
    assignmentTillUsersCount: "Required users quantity",
    completeness: "Completeness",
    usersShare: "Users share",
    relativeShare: "Relative share",
    status: {
      title: "Status",
      init: "Inited",
      started: "Started",
      scheduled: "Scheduled",
      canceled: "Canceled",
      disabled: "Disabled",
      groups_distributed: "Groups Distributed",
      config_distribution_finished: "Config Distribution Finished",
      statistics_recalculation: "Statistic Calculation",
      statistics_recalculated: "Statistic Calculated",
    },
    targetMetric: "Target Metric",
    parentConfig: "Parent Config",
    defaultConfig: "Default",
    disableTitle: 'Cancel AB Test "{0}"',
    disableText:
      "AB Test will be canceled. It won't be possible to activate it later",
    enableTitle: "Update AB Test dates to enable it",
    stepLabel: {
      init: "Inited",
      started: "Filling",
      scheduled: "Scheduled",
      groups_distributed: "Observing",
      config_distribution_finished: "Syncing",
      statistics_recalculation: "Checking",
      statistics_recalculated: "Finished",
    },
    currentState: {
      title: "Current State",
      fillConfigs: "You should fill production configs",
      waiting: "Waiting <b>{0}</b> to start",
      fillingGroupsHeading: "Filling test groups",
      fillingGroupsText: "Finished <b>{0}%</b>. <b>{1}</b> more users needed.",
      observingHeading: "Observing test users behaviour",
      observingText: "Observation will continue till <b>{0}</b>.",
      daysLeft: "Days left: {0}",
      observationFinished: "Observation finished",
      observationText:
        "Waiting data from user-data provider. Expected date: <b>{0}</b>.",
      perfoming: "Performing statistical testing and comparing",
      finished: "Test finished, results ready!",
      finishingToday: "Finishing today",
    },
    groupName: "Group name",
    observations: "Observations",
    diffControl: "Diff vs control",
    effect: "Effect (diff vs ci)",
    abs: "abs",
    rel: "rel",
    completed: "Completed",
  },
  notAvailable: "N/A",
  testType: {
    permutation_test: "Permutation test",
    t_test: "Two Sample T-Test (Welch's T-test)",
    z_test: "Two Sample proportion Z-Test",
  },
  abTestResult: {
    positive:
      "<b>{4}: {2}</b> shows the best results in the experiment - there's at least <b>{3}% increase</b> in {0} on Day {1}",
    negative:
      "<b>{4}: {2}</b> seems the most optimal variant considering {0} on Day {1}",
    none: "<b>{4}:</b> None of the Test groups show statistically significant results considering {0} on Day {1}",
    failed_validation:
      "Failed validation. Determined significant differences between the groups within the period before the AB-test can be evidence of imbalanced sampling and may lead the bias in AB-test results",
  },
  abTestSegmentResult: {
    positive:
      '<b>{5}: Detected the positive statistically significant {2}% increase above</b> the confidence interval in {3} for "{0}" on Day {4}.',
    positiveDetails:
      "Since p-value < {0} H<sub>0</sub> is rejected with p-value equals {1}. It means the {2}% probability of obtaining results at least as extreme as the observed results, assuming that the H<sub>0</sub> is correct.",
    negative:
      '<b>{5}: Detected the negative statistically significant {2}% decrease below</b> the confidence interval in {3} for "{0}" on Day {4}.',
    negativeDetails:
      "Since p-value < {0} H<sub>0</sub> is rejected with p-value equals {1}. It means the {2}% probability of obtaining results at least as extreme as the observed results, assuming that the H<sub>0</sub> is correct.",
    none: "<b>{5}: Unable to detect a statistically significant difference between {0} and {1}</b>.",
    noneDetails:
      "Since p-value > {0} H<sub>0</sub> cannot be rejected with p-value equals {1}. It means the {2}% probability of obtaining results at least as extreme as the observed results, assuming that the H<sub>0</sub> is correct.",
    notTargetMetric:
      "For this AB Test target metric is {0}. Current metric isn't included into test's result calculation.",
    details: "Details",
    noData: "<b>No data for Day {0}</b>",
    prevValidationChartTitle: "Dynamics chart for validation ({0})",
    chartTitle: "Test result chart ({0})",
  },
  externalTest: {
    title: "External Tests",
    configurationTitle: "External Tests Configuration",
    newTitle: "New External Test",
    editConfiguration: "Edit configuration",
    addExternalTest: "Add new external test",
    alert: "Can't find external ab test mapping for application {0}",
    adjustConfiguration: "Adjust configuration",
    testNameParamKey: "Test Name Param Key",
    testGroupParamKey: "Test Group Param Key",
    successSave: "Configuration saved successfully",
    successHistorySyncing: "History sync is started successfully",
    successTestFinishing: "Test finished successfully",
    externalId: "External Id",
    externalIdPlaceholder: "Create new or choose External Id",
    addTestGroup: "Add Test group",
    controlGroup: "Control group",
    controlGroupValidation: "Select Control group",
    externalTestId: "External Test ID",
    syncHistory: "Sync history",
    existingTests: "Existing tests",
    proposals: "Proposals",
    finishTitle: 'Finish test "{0}"?',
    proposalInfo:
      "Below is a list of potential External id of AB tests found in the raw data",
    delete: "Delete",
    notification: {
      saveSuccess: `External test "{0}" was created successfully`,
      deleteSuccess: `External test was deleted successfully`,
    },
    status: {
      inited: "Inited",
      proposal: "Proposal",
    },
    proposal: {
      testGroups: "Test groups",
      startDate: "Start date",
    },
    configuration: {
      confirmationTitle: "Change configuration",
      confirmationWarning:
        "If you change the configuration, all previously available proposals for creating External AB Tests will become unavailable",
    },
    confirmFinish: "Confirm finish",
  },
};
