import { CohortFilter } from "./CohortReport";
import { GroupByFilter } from "./GroupByFilter";
import { ReportType } from "./ReportType";
import {
  DatesFilterModel,
  FilterId,
  FilterPreviewId,
  recordToFilterModel,
  FilterModel,
  Application,
  AdRevenueMethod,
  DefaultValues,
  FilterPreview,
  DictionaryType,
  ProvidedBannerType,
} from "@/shared/models";
import {
  EventReportFilterExtension,
  ProvidedBannerReportFilterExtension,
  RevenueReportFilterExtension,
} from "./ReportFilterExtension";
import { ChartName } from "@/chart/models/ChartModel";

export class CohortCpmFilter
  extends CohortFilter
  implements
    EventReportFilterExtension,
    RevenueReportFilterExtension,
    ProvidedBannerReportFilterExtension
{
  constructor(
    app: Application,
    filter?: Array<FilterModel>,
    date?: DatesFilterModel,
    groupByFilter?: GroupByFilter,
    dayLimit?: number,
    public event: string = DefaultValues.EVENT,
    public revenue: Array<string> = ["REWARDED"],
    public providedBanner = DefaultValues.PROVIDED_BANNER,
    public adRevenueMethod: AdRevenueMethod = AdRevenueMethod.PRICED,
    public netRevenue = true
  ) {
    super(ReportType.COHORT_CPM, app, filter, date, groupByFilter, dayLimit);
  }

  get previews(): Array<FilterPreview> {
    return [
      ...super.previews,
      {
        id: FilterPreviewId.EVENT_SIMPLE,
        value: this.event,
      },
      ...(this.revenue.length
        ? [
            {
              id: FilterPreviewId.REVENUE,
              value: this.revenue,
              dictionary: DictionaryType.REVENUES,
            },
          ]
        : []),
      {
        id: FilterPreviewId.PROVIDED_BANNER,
        value: this.providedBanner,
      },
      {
        id: FilterPreviewId.AD_REVENUE_METHOD,
        value: this.adRevenueMethod,
      },
      {
        id: FilterPreviewId.NET_REVENUE,
        value: this.netRevenue,
      },
    ];
  }

  get isBannerRevenue(): boolean {
    return this.revenue.length === 0 || this.revenue.includes("banner");
  }

  get isDefaultProvidedBanner(): boolean {
    return this.providedBanner === DefaultValues.PROVIDED_BANNER;
  }

  get isPricedMethod(): boolean {
    return this.adRevenueMethod === AdRevenueMethod.PRICED;
  }

  get isBannerProvidedDisabled(): boolean {
    return this.adRevenueMethod === AdRevenueMethod.AVERAGE;
  }

  get invalid(): boolean {
    return (
      super.invalid ||
      (this.isBannerProvidedDisabled &&
        this.providedBanner === ProvidedBannerType.FIREBASE)
    );
  }

  get charts(): Array<ChartName> {
    return this.groupByFilter.isNotEmptyGroupBy
      ? this.getGroupedCohortChartNames("COHORT_CPM", [3, 7, 14, 30])
      : [ChartName.COHORT_CPM_CHART];
  }

  get groupedCharts(): Array<ChartName> {
    return this.groupByFilter.isNotEmptyGroupBy
      ? [ChartName.COHORT_CPM_CHART]
      : [];
  }

  static of(app: Application, filter?: Record<string, any>): CohortCpmFilter {
    return filter
      ? new CohortCpmFilter(
          app,
          filter.filter && filter.filter.length > 0
            ? filter.filter.map((it: any) => recordToFilterModel(it))
            : [],
          filter.date
            ? DatesFilterModel.ofRecord(
                FilterId.ATTRIBUTION_DATE_VALUE,
                filter.date
              )
            : undefined,
          filter.groupByFilter
            ? GroupByFilter.of(filter.groupByFilter)
            : undefined,
          filter.dayLimit
            ? Number.parseInt(filter.dayLimit as string)
            : undefined,
          filter.event as string,
          filter.revenue,
          filter.providedBanner,
          filter.adRevenueMethod as AdRevenueMethod
          // TODO: NET_REVENUE need to uncomment later
          // typeof filter.netRevenue === "string"
          //   ? filter.netRevenue === "true"
          //   : filter.netRevenue,
        )
      : new CohortCpmFilter(app);
  }

  toRequestQuery(): Record<string, any> {
    return {
      ...super.toRequestQuery(),
      event: this.event,
      adRevenueMethod: this.adRevenueMethod,
      ...(this.revenue.length ? { revenue: this.revenue } : {}),
      ...(this.isBannerRevenue && !this.isDefaultProvidedBanner
        ? { providedBanner: this.providedBanner }
        : {}),
      // TODO: NET_REVENUE need to uncomment later
      // netRevenue: this.netRevenue,
    };
  }
}
