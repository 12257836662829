export class JobInfo {
  constructor(
    public application: string,
    public job: string,
    public count: number,
    public processingAverageTimeSeconds: string,
    public totalAverageTimeSeconds: string,
    public diffAverageTimeSeconds: string
  ) {}
}
