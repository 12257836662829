import { DataTableHeader } from "vuetify";

import { AggregationPeriod, GroupByFilter } from "./GroupByFilter";
import { ReportResultItem } from "./Report";
import { LangService } from "@/shared/types/LangType";

export interface ReportHeader extends DataTableHeader {
  gradientKey?: string;
  hasGradient?: boolean;
  fractionDigits?: number;
  isGrouped?: boolean;
  prefix?: string;
  postfix?: string;
  sticky?: boolean;
  reverseGradient?: boolean;
  groupBy?: Record<string, any>;
  hasFill?: boolean;
  hasExcludeFeature?: boolean;
  tooltipText?: string;
}

export class ReportHeaderUtil {
  static createDate(
    lang: LangService,
    aggregationPeriod?: AggregationPeriod
  ): ReportHeader {
    const width =
      !!aggregationPeriod && aggregationPeriod != AggregationPeriod.DAY
        ? 180
        : 120;
    return {
      text: lang("components.date"),
      align: "center",
      value: "date",
      width,
      sticky: true,
    };
  }
  static createGroupBy(
    lang: any,
    groupByFilter: GroupByFilter,
    width = 120,
    isGrouped = true
  ): Array<ReportHeader> {
    return groupByFilter.groupBy.map((it) => ({
      text: lang(`components.groupBy.${it.toString().toLowerCase()}`),
      align: "start",
      value: `${ReportResultItem.PREFIX}${it.toString().toLowerCase()}`,
      width: width,
      sticky: true,
      isGrouped,
    }));
  }

  static getActiveUsersName(aggregationPeriod?: AggregationPeriod): string {
    return aggregationPeriod
      ? {
          [AggregationPeriod.WEEK]: "wau",
          [AggregationPeriod.MONTH]: "mau",
          [AggregationPeriod.DAY]: "dau",
        }[aggregationPeriod]
      : "dau";
  }
}

export enum GroupByType {
  DAY = "day",
  AD_TYPES = "ad_types",
  METRICS = "metrics",
}

export class ReportHeaderGroupBy {
  constructor(
    public value = false,
    public border = {
      left: false,
      right: false,
    },
    public hasBackground = false
  ) {}
}
