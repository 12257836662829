import { ChartName } from "@/chart/models/ChartModel";
import { CohortFilter } from "./CohortReport";
import { GroupByFilter } from "./GroupByFilter";
import { ReportType } from "./ReportType";
import {
  DatesFilterModel,
  FilterId,
  recordToFilterModel,
  FilterModel,
  Application,
} from "@/shared/models";

export class ReturnRateFilter extends CohortFilter {
  constructor(
    app: Application,
    filter?: Array<FilterModel>,
    date?: DatesFilterModel,
    groupByFilter?: GroupByFilter,
    dayLimit?: number
  ) {
    super(ReportType.RETURN_RATE, app, filter, date, groupByFilter, dayLimit);
  }

  get charts(): Array<ChartName> {
    return this.groupByFilter.isNotEmptyGroupBy
      ? this.getGroupedCohortChartNames("RETURN_RATE_REPORT", [1, 7, 14])
      : [ChartName.RETURN_RATE_REPORT_CHART];
  }

  get groupedCharts(): Array<ChartName> {
    return this.groupByFilter.isNotEmptyGroupBy
      ? [ChartName.RETURN_RATE_REPORT_CHART]
      : [];
  }

  static of(app: Application, filter?: Record<string, any>): ReturnRateFilter {
    return filter
      ? new ReturnRateFilter(
          app,
          filter.filter && filter.filter.length > 0
            ? filter.filter.map((it: any) => recordToFilterModel(it))
            : [],
          filter.date
            ? DatesFilterModel.ofRecord(
                FilterId.ATTRIBUTION_DATE_VALUE,
                filter.date
              )
            : undefined,
          filter.groupByFilter
            ? GroupByFilter.of(filter.groupByFilter)
            : undefined,
          filter.dayLimit
            ? Number.parseInt(filter.dayLimit as string)
            : undefined
        )
      : new ReturnRateFilter(app);
  }
}
