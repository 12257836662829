import RevenueEventModel from "./RevenueEventModel";

export class GetRevenueEventRequestModel {
  id: string;
  applicationId: string;

  constructor(id: string, applicationId: string) {
    this.id = id;
    this.applicationId = applicationId;
  }
}

export class RevenueEventUniqueTokenRequestModel {
  token: string;

  constructor(token: string) {
    this.token = token;
  }
}

export class RevenueEventUniqueTokenResponseModel {
  unique: boolean;

  constructor(unique: boolean) {
    this.unique = unique;
  }
}

export default class RevenueEventRequestModel extends RevenueEventModel {
  constructor(
    public applicationId: string,
    id?: number,
    token = "",
    type = "",
    event = "",
    appVersionFrom: string | null = "",
    appVersionTo: string | null = ""
  ) {
    super(token, type, event, appVersionFrom, appVersionTo, id);
  }

  static ofSource(model: RevenueEventRequestModel): RevenueEventRequestModel {
    return new RevenueEventRequestModel(
      model.applicationId,
      model.id,
      model.token,
      model.type,
      model.event ?? "",
      model.appVersionFrom,
      model.appVersionTo
    );
  }

  static ofRequest(model: RevenueEventRequestModel): RevenueEventModel {
    return new RevenueEventModel(
      model.token,
      model.type,
      model.event ? model.event : null,
      model.appVersionFrom || null,
      model.appVersionTo || null
    );
  }
}
