import { ChartName } from "@/chart/models/ChartModel";
import { ReportFilter } from "@/reports/models";

export enum DashboardChartType {
  LINEAR = "LINEAR",
  STACKED = "STACKED",
  COLUMN = "COLUMN",
  FUNNEL = "FUNNEL",
  PIE = "PIE",
}

export interface DashboardChart {
  type: DashboardChartType;
  name: ChartName;
}

export class DashboardChartModel implements DashboardChart {
  constructor(
    public type: DashboardChartType,
    public name: ChartName,
    public expanded: boolean,
    public templateId: number,
    public templateName: string,
    public reportFilter?: ReportFilter,
    public id?: number
  ) {}

  static of(model: DashboardChartModel) {
    return new DashboardChartModel(
      model.type,
      model.name,
      model.expanded,
      model.templateId,
      model.templateName,
      model.reportFilter,
      model.id
    );
  }
}
