import AlertResponseModel, {
  AlertDisplayTime,
  AlertType,
} from "@/alerts-system/models/AlertResponseModel";

export const getAlertColorByType = (type: AlertType): string => {
  return {
    [AlertType.WARNING]: "#fb8c00",
    [AlertType.ERROR]: "#ff5252",
    [AlertType.INFO]: "#2196f3",
    [AlertType.SUCCESS]: "#4caf50",
  }[type];
};

export const getSystemAlertsForCharts = (alerts: Array<AlertResponseModel>) => {
  return alerts.filter(
    (alert: AlertResponseModel) =>
      alert.isDisplayableOnChart &&
      alert.displayTime === AlertDisplayTime.FEATURE_TIME_LIMITED
  );
};
