export default {
  validation: {
    required: "Required",
    maxLength: "Max {0} characters",
    minLength: "Min {0} characters",
    min: "Must be greater than or equal to {0}",
    max: "Must be less than or equal to {0}",
    integer: "The value must be an integer number",
    unique: "Non unique value",
    restrict: "Contains one of restricted symbols: {0}",
    notEndsWith: "Value shouldn't ends with {0}",
    matchVersion: "The value must match the version format",
    email: "Please, enter valid email address",
    exists: "Such name already exists",
    json: "Fill correctly in all JSON fields",
    duplicateConfigs: "There are duplicate configurations",
    duplicateConfigKey: "In other configs there is a duplicate of this key",
    float: "The value must be an float number",
    maxFloat: "Must be less than 7 numbers after the decimal point",
    invalidFormat: "Invalid format",
    excludeAllValues: "Cannot exclude all available values",
    dateGreaterThanMaxDate: "Date is greater than max date",
    dateLessThanMinDate: "Date less than min date",
    invalidDate: "Invalid date",
    lessThanSecondValue: "Must be less or equal than second value",
    number: "The value must be a number",
    maxDiff: "Max diff should be {0} days",
    englishLettersOnly: "Please, remove russian letters",
    capitalLetters: "Please, enter capital letters",
    invalidCharacters: "Contains invalid characters",
    invalidPattern: "Invalid pattern",
  },
};
