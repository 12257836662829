























































import { cloneDeep } from "lodash";
import { Component, Prop, Vue } from "vue-property-decorator";

import { Application, ApplicationStatus } from "@/shared/models";

@Component
export default class ApplicationPickerItem extends Vue {
  @Prop() app!: Application;

  get favoriteApplications(): Array<string> {
    return this.$store.state.accountSettingsStore.accountSettings
      .favoriteApplications;
  }

  get defaultApplication(): string {
    return this.$store.state.accountSettingsStore.accountSettings
      .defaultApplication;
  }

  get isDefault(): boolean {
    return this.app.id === this.defaultApplication;
  }

  get isFavorite(): boolean {
    return this.favoriteApplications.includes(this.app.id);
  }

  get isArchived(): boolean {
    return this.app.status === ApplicationStatus.ARCHIVED;
  }

  updateDefaultApp() {
    const accountSettingsCloned = cloneDeep(
      this.$store.state.accountSettingsStore.accountSettings
    );

    accountSettingsCloned.defaultApplication = this.isDefault
      ? null
      : this.app.id;
    this.$store.dispatch("updateAccountSettings", {
      payload: accountSettingsCloned,
    });
  }

  updateFavorites() {
    const accountSettingsCloned = cloneDeep(
      this.$store.state.accountSettingsStore.accountSettings
    );

    if (this.isDefault || this.isFavorite) {
      accountSettingsCloned.defaultApplication = null;
    } else if (!this.favoriteApplications.length) {
      accountSettingsCloned.defaultApplication = this.app.id;
    }

    accountSettingsCloned.favoriteApplications = this.isFavorite
      ? this.favoriteApplications.filter(
          (favId: string) => this.app.id !== favId
        )
      : [...this.favoriteApplications, this.app.id];
    this.$store.dispatch("updateAccountSettings", {
      payload: accountSettingsCloned,
    });
  }
}
