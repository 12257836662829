export class HealthcheckAlertsSettingsModel {
  constructor(
    public fileName: string,
    public originalLinesCount: number,
    public processedLinesCount: number,
    public notProcessedLinesCount: number
  ) {}

  static of(item: HealthcheckAlertsSettingsModel) {
    return new HealthcheckAlertsSettingsModel(
      item.fileName,
      item.originalLinesCount,
      item.processedLinesCount,
      item.notProcessedLinesCount
    );
  }
}
