export default class FileUtil {
  static readonly CONTENT_TYPE_CSV = "text/csv;charset=utf-8;";
  static readonly CONTENT_TYPE_TSV = "text/tab-separated-values;charset=utf-8;";

  static download(content: string, filename: string, contentType: string) {
    const blob = new Blob([content], { type: contentType });
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
}
