import { render, staticRenderFns } from "./PickerDictionaryNotification.vue?vue&type=template&id=147d9e7a&scoped=true&"
import script from "./PickerDictionaryNotification.vue?vue&type=script&lang=ts&"
export * from "./PickerDictionaryNotification.vue?vue&type=script&lang=ts&"
import style0 from "./PickerDictionaryNotification.vue?vue&type=style&index=0&id=147d9e7a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "147d9e7a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
installComponents(component, {VIcon,VListItem,VProgressLinear})
