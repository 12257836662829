var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('custom-autocomplete',{ref:"autocomplete",attrs:{"autofocus":_vm.autofocus,"is-attached":_vm.isAttached,"items":_vm.groupedItems,"label":_vm.$lang(_vm.label),"loading":_vm.isLoading,"clearable":!_vm.readonly,"readonly":_vm.readonly,"append-icon":_vm.readonly ? '' : 'arrow_drop_down',"rules":_vm.rules,"error-messages":_vm.errorMessages,"filter-preview-id":_vm.filterPreviewId,"has-item-slot":true,"has-selection-slot":true,"hide-details":"auto","outlined":"","dense":"","multiple":"","chips":"","return-object":""},on:{"search":_vm.onSearchChange},scopedSlots:_vm._u([(!_vm.isFullResult)?{key:"prepend-item",fn:function(){return [_c('picker-dictionary-notification')]},proxy:true}:null,{key:_vm.slotName,fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({style:(("opacity: " + (_vm.dark ? '0.8' : '0.2'))),attrs:{"color":_vm.localFilter.included ? '' : 'red',"disabled":_vm.readonly},on:{"click":function($event){_vm.localFilter.included = !_vm.localFilter.included}}},on),[_vm._v(" "+_vm._s(_vm.localFilter.included ? "add_box" : "indeterminate_check_box")+" ")])]}}])},[_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm.localFilter.included ? _vm.$lang("shared.filters.excludeFilter") : _vm.$lang("shared.filters.includeFilter"))+" ")])])]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
return [(!item.childValues)?_c('v-chip',{staticClass:"mt-1",attrs:{"close":!_vm.readonly},on:{"click:close":function($event){return _vm.handleRemoveItem(item)}}},[_vm._v(" "+_vm._s(item.text.length > 40 ? ((item.text.slice(0, 40)) + "...") : item.text)+" ")]):_vm._e()]}},{key:"groupedItem",fn:function(ref){
var item = ref.item;
return [(item.childValues)?_c('v-list-item',{class:{ selected: _vm.isSelected(item) },style:({
        borderBottom: _vm.isLastTierOnList(item)
          ? '1px solid rgba(0, 0, 0, 0.2)'
          : '',
      }),on:{"click":function($event){return _vm.handleClickItem(item)}}},[_c('v-list-item-action',[_c('v-simple-checkbox',{attrs:{"color":"primary","value":_vm.isSelected(item),"ripple":false},on:{"click":function($event){$event.stopPropagation();return _vm.handleChoosingItem(item)}}})],1),_c('v-list-item-content',{class:{ 'primary--text': _vm.isSelected(item) }},[_c('v-list-item-title',{staticClass:"d-flex align-center justify-space-between"},[_c('span',[_vm._v(" "+_vm._s(item.text)+" ")]),_c('v-chip',{staticClass:"ma-2",attrs:{"small":"","color":"primary","text-color":"white"}},[_vm._v(" "+_vm._s(item.childValues.length)+" ")])],1)],1)],1):_c('v-list-item',{class:{ selected: _vm.isSelected(item) },on:{"click":function($event){return _vm.handleClickItem(item)}}},[_c('v-list-item-action',[_c('v-simple-checkbox',{attrs:{"color":"primary","value":_vm.isSelected(item),"ripple":false},on:{"click":function($event){$event.stopPropagation();return _vm.handleChoosingItem(item)}}})],1),_c('v-list-item-content',{class:[{ 'primary--text': _vm.isSelected(item) }]},[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(item.text)}})],1)],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})}
var staticRenderFns = []

export { render, staticRenderFns }