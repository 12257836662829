import Vue from "vue";
import vuetify from "@/shared/plugins/vuetify";
import { LangService } from "@/shared/types/LangType";

declare module "vue/types/vue" {
  interface Vue {
    $lang: LangService;
  }
}

const lang = {
  install() {
    Vue.prototype.$lang = (
      str: string,
      ...params: Array<string | number>
    ): string => {
      return vuetify.framework.lang.t(`$vuetify.${str}`, ...params);
    };
  },
};

export default lang;
