import { ResponseFormatRuleModel, RuleModel } from "./ParsingRuleRequestModel";

export enum ParsingRuleResponseFormat {
  AGGREGATED_JSON = "AGGREGATED_JSON",
  CSV = "CSV",
  CHARTBOOST = "CHARTBOOST",
  GET_GEO_API_CURRENCY_JSON = "GET_GEO_API_CURRENCY_JSON",
  JSON = "JSON",
  JSON_MAP = "JSON_MAP",
  JSON_MATRIX = "JSON_MATRIX",
  JSON_WITH_DYNAMIC_KEYS = "JSON_WITH_DYNAMIC_KEYS",
  JSON_WITH_UNPIVOT_CHILDREN_OBJECT = "JSON_WITH_UNPIVOT_CHILDREN_OBJECT",
}

export enum RuleFieldKey {
  DATE = "DATE",
  COUNTRY = "COUNTRY",
  NETWORK_NAME = "NETWORK_NAME",
  CAMPAIGN_ID = "CAMPAIGN_ID",
  CAMPAIGN_NAME = "CAMPAIGN_NAME",
  AD_TYPE = "AD_TYPE",
  APPLICATION_ID = "APPLICATION_ID",
  NETWORK_APPLICATION_ID = "NETWORK_APPLICATION_ID",
  PLACEMENT_ID = "PLACEMENT_ID",
  REQUESTS = "REQUESTS",
  IMPRESSIONS = "IMPRESSIONS",
  CLICKS = "CLICKS",
  REVENUE = "REVENUE",
  CONVERSION_VALUE = "CONVERSION_VALUE",
  REDOWNLOAD = "REDOWNLOAD",
  AD_UNIT = "AD_UNIT",
  DEVICE_TYPE = "DEVICE_TYPE",
  LAT = "LAT",
  SPEND = "SPEND",
  CONVERSION_VALUE_COUNT = "CONVERSION_VALUE_COUNT",
  INSTALL = "INSTALL",
  AD_GROUP_NAME = "AD_GROUP_NAME",
  AD_GROUP_ID = "AD_GROUP_ID",
  PUBLISHER_NAME = "PUBLISHER_NAME",
  PUBLISHER_ID = "PUBLISHER_ID",
  CREATIVE_NAME = "CREATIVE_NAME",
  CREATIVE_ID = "CREATIVE_ID",
}

export enum RuleType {
  CONSTANT = "CONSTANT",
  NODE = "NODE",
  JSON = "JSON",
  JSON_BY_NODE = "JSON_BY_NODE",
  SPEL_EXPRESSION = "SPEL_EXPRESSION",
  DATE = "DATE",
  OBJECT = "OBJECT",
  SPECIAL_PARAM = "SPECIAL_PARAM",
}

export default class ParsingRuleResponseModel {
  constructor(
    public id: number,
    public responseFormatRule: ResponseFormatRuleModel,
    public rules: Array<RuleModel>,
    public networkIntegrationId: number,
    public responseDateFormatPattern?: string | null
  ) {}

  static ofSource({
    id,
    responseFormatRule,
    rules,
    networkIntegrationId,
    responseDateFormatPattern,
  }: any): ParsingRuleResponseModel {
    return new ParsingRuleResponseModel(
      id,
      responseFormatRule,
      Object.entries(rules).reduce(
        (result: Array<RuleModel>, [key, value]: [string, any]) => {
          return [
            ...result,
            {
              fieldKey: key,
              ...value,
            },
          ];
        },
        [] as Array<RuleModel>
      ),
      networkIntegrationId,
      responseDateFormatPattern
    );
  }
}
