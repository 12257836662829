import JsonUtil from "@/ab-tests/utils/JsonUtil";
import AbTestResponseModel from "./AbTestResponseModel";

export enum ResponseStatus {
  INIT = "INIT",
  UNCHECKED = "UNCHECKED",
  CHECKED = "CHECKED",
}

export enum EnvironmentType {
  STAGE = "STAGE",
  PROD = "PROD",
}

export default class ApplicationResponseModel {
  constructor(
    public stage = new EnvironmentModel(),
    public production = new EnvironmentModel(),
    public id?: number
  ) {}

  static of(model: ApplicationResponseModel): ApplicationResponseModel {
    return new ApplicationResponseModel(
      EnvironmentModel.of(model.stage),
      EnvironmentModel.of(model.production),
      model.id
    );
  }

  static ofRequest(
    model: ApplicationResponseModel | AbTestResponseModel,
    environmentType?: EnvironmentType,
    isCreate = false
  ) {
    if (isCreate) {
      return {
        response: {
          stage: (model as ApplicationResponseModel).stage?.response,
          production: (model as ApplicationResponseModel).production?.response,
        },
      };
    }

    return {
      response:
        model[
          environmentType === EnvironmentType.STAGE ? "stage" : "production"
        ]?.response,
      version:
        model[
          environmentType === EnvironmentType.STAGE ? "stage" : "production"
        ]?.version,
      environmentType,
    };
  }
}

export class EnvironmentModel {
  constructor(
    public environmentType = EnvironmentType.STAGE,
    public response = JsonUtil.formatting("[]"),
    public version?: number
  ) {}

  static of(model: EnvironmentModel): EnvironmentModel {
    return new EnvironmentModel(
      model.environmentType,
      !model.response ? JsonUtil.formatting("[]") : model.response,
      model.version
    );
  }
}
