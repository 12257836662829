import {
  BaseGradientFlags,
  BaseReportFilter,
  ReportFilter,
  ReportResultItem,
} from "./Report";
import { AggregationPeriod, GroupByFilter } from "./GroupByFilter";
import { GroupByType, ReportHeader, ReportHeaderUtil } from "./ReportHeader";
import { ReportType } from "./ReportType";
import {
  DatesFilterModel,
  FilterId,
  recordToFilterModel,
  FilterModel,
  Application,
} from "@/shared/models";
import { LangService } from "@/shared/types/LangType";
import { AdType } from "@/shared/models/AdType";
import { ReportItemRowObject } from "./ReportItemRowObject";

export class FirebaseVsNetworksGradientFlags extends BaseGradientFlags {
  constructor() {
    super();

    this.flags = {
      [`${ReportResultItem.PREFIX}bannerEventsDiff`]: true,
      [`${ReportResultItem.PREFIX}bannerRevenueDiff`]: true,
      [`${ReportResultItem.PREFIX}interstitialEventsDiff`]: true,
      [`${ReportResultItem.PREFIX}interstitialRevenueDiff`]: true,
      [`${ReportResultItem.PREFIX}rewardedEventsDiff`]: true,
      [`${ReportResultItem.PREFIX}rewardedRevenueDiff`]: true,
    };
  }
}

export class FirebaseVsNetworksFilter extends BaseReportFilter {
  constructor(
    app: Application,
    filter?: Array<FilterModel>,
    date?: DatesFilterModel,
    groupByFilter = new GroupByFilter(AggregationPeriod.DAY)
  ) {
    super(ReportType.FIREBASE_VS_NETWORKS, app, filter, date, groupByFilter);
  }

  get invalid(): boolean {
    return !(this.app && this.date.valid);
  }

  static of(
    app: Application,
    requestQuery?: Record<string, any>
  ): FirebaseVsNetworksFilter {
    return new FirebaseVsNetworksFilter(
      app,
      requestQuery?.filter?.map((it: any) => recordToFilterModel(it)) || [],
      DatesFilterModel.ofRecord(FilterId.EVENTS_DATE, requestQuery?.date),
      GroupByFilter.of(requestQuery?.groupByFilter)
    );
  }
}

export class FirebaseVsNetworksHeaders {
  static readonly AD_TYPES = [
    AdType.BANNER,
    AdType.INTERSTITIAL,
    AdType.REWARDED,
  ];
  static readonly METRICS = ["Fb", "Net", "Diff"];

  static init(
    lang: LangService,
    report: FirebaseVsNetworksFilter,
    groupBy: GroupByType
  ): Array<ReportHeader> {
    return [
      ReportHeaderUtil.createDate(lang, report.groupByFilter.aggregationPeriod),
      ...(report.groupByFilter.isNotEmptyGroupBy
        ? ReportHeaderUtil.createGroupBy(lang, report.groupByFilter)
        : []),
      ...this.generateFirebaseVsNetworksHeaders(lang, groupBy),
    ];
  }

  static generateFirebaseVsNetworksHeaders(
    lang: LangService,
    groupBy: GroupByType
  ): Array<ReportHeader> {
    const result: Array<ReportHeader> = [];

    if (groupBy === GroupByType.AD_TYPES) {
      FirebaseVsNetworksHeaders.AD_TYPES.forEach((adType) => {
        ["Events", "Revenue"].forEach((item) => {
          result.push(
            ...FirebaseVsNetworksHeaders.METRICS.map(
              (metric) =>
                ({
                  value: `${
                    ReportResultItem.PREFIX
                  }${adType.toLowerCase()}${item}${metric}`,
                  text: lang(
                    `components.baseTable.${adType.toLowerCase()}${item}${metric}`
                  ),
                  align: "end",
                  width: 90,
                  groupBy: {
                    border: {
                      left:
                        metric === FirebaseVsNetworksHeaders.METRICS[0] &&
                        item === "Events",
                      right:
                        adType ===
                          FirebaseVsNetworksHeaders.AD_TYPES[
                            FirebaseVsNetworksHeaders.AD_TYPES.length - 1
                          ] &&
                        metric ===
                          FirebaseVsNetworksHeaders.METRICS[
                            FirebaseVsNetworksHeaders.METRICS.length - 1
                          ] &&
                        item === "Revenue",
                    },
                    hasBackground: FirebaseVsNetworksHeaders.AD_TYPES.filter(
                      (_, index) => index % 2 === 0
                    ).includes(adType),
                  },
                  fractionDigits: metric === "Diff" ? 3 : 0,
                  postfix: metric === "Diff" ? "%" : "",
                  hasGradient: metric === "Diff" ? true : false,
                } as ReportHeader)
            )
          );
        });
      });
    } else {
      FirebaseVsNetworksHeaders.METRICS.forEach((metric) => {
        ["Events", "Revenue"].forEach((item) => {
          result.push(
            ...FirebaseVsNetworksHeaders.AD_TYPES.map(
              (adType) =>
                ({
                  value: `${
                    ReportResultItem.PREFIX
                  }${adType.toLowerCase()}${item}${metric}`,
                  text: lang(
                    `components.baseTable.${adType.toLowerCase()}${item}${metric}`
                  ),
                  align: "end",
                  width: 90,
                  groupBy: {
                    border: {
                      left:
                        adType === FirebaseVsNetworksHeaders.AD_TYPES[0] &&
                        item === "Events",
                      right:
                        adType ===
                          FirebaseVsNetworksHeaders.AD_TYPES[
                            FirebaseVsNetworksHeaders.AD_TYPES.length - 1
                          ] &&
                        metric ===
                          FirebaseVsNetworksHeaders.METRICS[
                            FirebaseVsNetworksHeaders.METRICS.length - 1
                          ] &&
                        item === "Revenue",
                    },
                    hasBackground: FirebaseVsNetworksHeaders.METRICS.filter(
                      (_, index) => index % 2 === 0
                    ).includes(metric),
                  },
                  fractionDigits: metric === "Diff" ? 3 : 0,
                  postfix: metric === "Diff" ? "%" : "",
                  hasGradient: metric === "Diff" ? true : false,
                } as ReportHeader)
            )
          );
        });
      });
    }

    return result;
  }
}

export class FirebaseVsNetworksResultItem extends ReportResultItem {
  constructor(row: ReportItemRowObject, filter: ReportFilter) {
    const { groupByFilter } = filter;

    super();
    this.date = row.getByHeader("date");
    this.setFormattedDate(
      this.date,
      filter.date.from,
      filter.date.to,
      groupByFilter.aggregationPeriod
    );
    this.setGroupByValue(groupByFilter, row);
    this.data = {
      ...this.data,
      ...[
        "bannerEventsFb",
        "bannerEventsNet",
        "bannerEventsDiff",
        "bannerRevenueFb",
        "bannerRevenueNet",
        "bannerRevenueDiff",
        "interstitialEventsFb",
        "interstitialEventsNet",
        "interstitialEventsDiff",
        "interstitialRevenueFb",
        "interstitialRevenueNet",
        "interstitialRevenueDiff",
        "rewardedEventsFb",
        "rewardedEventsNet",
        "rewardedEventsDiff",
        "rewardedRevenueFb",
        "rewardedRevenueNet",
        "rewardedRevenueDiff",
      ].reduce(
        (result, metric) => ({
          ...result,
          [metric]: row.getByHeader(metric),
        }),
        {}
      ),
    };
  }
}
