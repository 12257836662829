import ScenarioResponseModel from "./ScenarioResponseModel";

export default class BatchResponseModel {
  constructor(
    public id: number,
    public name: string,
    public description: string,
    public sort: Array<number>,
    public executions: Array<number>,
    public scenarios: Array<ScenarioResponseModel>,
    public isDefault: boolean
  ) {}

  static ofSource(model: BatchResponseModel): BatchResponseModel {
    return new BatchResponseModel(
      model.id,
      model.name,
      model.description,
      model.sort,
      model.executions,
      model.scenarios,
      Boolean(model.isDefault)
    );
  }

  static ofArray(items: Array<BatchResponseModel>) {
    return items.map(
      (item) =>
        new BatchResponseModel(
          item.id,
          item.name,
          item.description,
          item.sort,
          item.executions,
          item.scenarios,
          Boolean(item.isDefault)
        )
    );
  }
}
