export enum AdType {
  BANNER = "BANNER",
  INTERSTITIAL = "INTERSTITIAL",
  REWARDED = "REWARDED",
}

export enum AdTypeShort {
  BANNER = "BANNER",
  INTER = "INTER",
  REWARD = "REWARD",
}
