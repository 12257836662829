import { Route, NavigationGuardNext } from "vue-router";

interface Context {
  from: Route;
  next: NavigationGuardNext;
  store: any;
  to: Route;
}

export function middlewarePipeline(
  context: Context,
  middleware: Array<any>,
  index: number
) {
  const nextMiddleware = middleware[index];
  if (!nextMiddleware) {
    return context.next;
  }

  return () => {
    const nextPipeline = middlewarePipeline(context, middleware, index + 1);
    nextMiddleware({ ...context, next: nextPipeline });
  };
}
