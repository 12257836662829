import { DatesFilterModel, FilterId } from "@/shared/models";
import DateUtil from "@/shared/utils/DateUtil";

export class DefaultValues {
  static readonly PLATFORM = "ios";
  static readonly APP = "happy-color-ios";

  static readonly EVENT = "impressions";
  static readonly PROVIDED_BANNER = "ADJUST";

  static readonly DAYS_LIMIT = 31;
  static readonly ROUND_SESSIONS_TO = 15;
  static readonly MAX_DAYS_LIMIT = 365;

  static initDatesFilterModel(
    id: FilterId,
    daysFrom = 30,
    toDate?: string,
    fromDate?: string
  ) {
    const to = toDate ? toDate : DateUtil.twoDaysAgo();
    const from = fromDate ? fromDate : DateUtil.subtractDays(to, daysFrom);
    return new DatesFilterModel(id, true, false, true, from, to);
  }
}
