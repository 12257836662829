



































































































import { intersection } from "lodash";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Route } from "vue-router";

import ApplicationPickerItem from "@/shared/components/ApplicationPickerItem.vue";

import {
  Application,
  ApplicationStatus,
  Dictionary,
  MULTI_APP,
} from "@/shared/models";

@Component({
  components: { ApplicationPickerItem },
})
export default class ApplicationPicker extends Vue {
  readonly MULTI_APP = MULTI_APP;
  selectedPlatform: "ios" | "android" | null = null;
  isMenuVisible = false;
  search: string | null = null;
  archivedVisible = false;

  get applicationId(): string {
    return this.$store.state.application.applicationId;
  }

  get apps(): Array<Application> {
    return this.$store.state.application.apps
      .filter(({ id, name, platformType }: Application) => {
        const search = this.search?.toLowerCase() ?? "";

        return (
          (id.toLowerCase().includes(search) ||
            name?.toLowerCase().includes(search)) &&
          (!this.selectedPlatform || platformType === this.selectedPlatform)
        );
      })
      .sort(({ id: idA }: Application, { id: idB }: Application) => {
        if (intersection(this.favoriteApplications, [idA, idB]).length !== 1) {
          return 0;
        }

        return this.favoriteApplications.includes(idA) ? -1 : 1;
      });
  }

  get activeApps(): Array<Application> {
    return this.apps.filter(
      ({ status }) => status === ApplicationStatus.ACTIVE
    );
  }

  get archivedApps(): Array<Application> {
    return this.apps.filter(
      ({ status }) => status === ApplicationStatus.ARCHIVED
    );
  }

  get platforms(): Array<Dictionary> {
    return this.$store.state.application.platforms;
  }

  get isMultiAppMode(): boolean {
    return this.$store.getters.isMultiAppMode;
  }

  get selectedAppName(): string {
    return this.isMultiAppMode
      ? this.$lang("shared.applicationPicker.multiAppMode")
      : this.$store.state.application.appName;
  }

  get iconName(): string {
    return this.isMultiAppMode
      ? "mdi-play-box-multiple-outline"
      : "mdi-play-box-outline";
  }

  get isLoadingApps(): boolean {
    return this.$store.state.application.loadingApps;
  }

  get hasUnsavedChanges(): boolean {
    return this.$store.state.unsavedChangesStore.hasUnsavedChanges;
  }

  get favoriteApplications(): Array<string> {
    return (
      this.$store.state.accountSettingsStore.accountSettings
        ?.favoriteApplications ?? []
    );
  }

  @Watch("isMenuVisible")
  clearSearch() {
    if (this.isMenuVisible) {
      return;
    }

    this.search = null;
  }

  setApp(id: string) {
    this.isMenuVisible = false;

    if (id === this.$store.state.application.applicationId) {
      return;
    }

    if (this.hasUnsavedChanges) {
      this.showUnsavedChangesDialog({
        ...this.$route,
        params: { id },
      });

      return;
    }

    if (!this.applicationId) {
      this.$store.commit("setApplicationId", id);

      return;
    }

    this.$store.commit("setApplicationId", id);

    if (this.$route.params.id) {
      this.$router.push({
        params: { id },
      });
    }
  }

  showUnsavedChangesDialog(nextRoute: Route) {
    this.$store.commit("setUnsavedChangesDialogVisibility", true);
    this.$store.commit("setNextRoute", nextRoute);
  }
}
