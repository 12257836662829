import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { AxiosResponse } from "axios";

import axios, {
  AxiosSkipErrorsConfig,
  cancelAllRequests,
  cancelSource,
  refreshToken,
} from "@/shared/plugins/axios";
import AbTestConfigurationModel, {
  AbTestChartModel,
  AbTestMinUsersCountRequest,
  AbTestMinUsersCountResponse,
  AbTestResultModel,
  AbTestResultSummary,
  AbTestStatisticalSignificanceTestType,
} from "@/ab-tests/models/AbTestConfigurationModel";
import {
  AbTestDisablingModel,
  AbTestEnablingModel,
  ConfigsStatus,
} from "@/ab-tests/models/ConfigsStatusModel";
import ConfigUtil from "@/ab-tests/utils/ConfigUtil";
import { NotificationType } from "@/shared/models";
import ApplicationResponseModel, {
  EnvironmentType,
} from "@/ab-tests/models/ApplicationResponseModel";
import AbTestResponseModel from "../models/AbTestResponseModel";
import EventSourceWrapper from "@/shared/utils/EventSourceWrapper";

@Module
export default class AbTestConfigStore extends VuexModule {
  loadingConfig = false;
  abTestConfigs: Record<ConfigsStatus, Array<AbTestConfigurationModel>> =
    Object.keys(ConfigsStatus).reduce(
      (
        res: Record<ConfigsStatus, Array<AbTestConfigurationModel>>,
        status: string
      ) => {
        return {
          ...res,
          [status]: [],
        };
      },
      {} as Record<ConfigsStatus, Array<AbTestConfigurationModel>>
    );
  abTestConfig: AbTestConfigurationModel = new AbTestConfigurationModel();
  abTestResultSummary: Array<AbTestResultSummary> = [];
  abTestCharts: Array<AbTestChartModel> = [];
  abTestChartsLoading = false;
  minUsersCount: AbTestMinUsersCountResponse | null = null;
  minUsersCountEventSource: EventSourceWrapper | null = null;
  isMinUsersCountLoading = false;
  disablingAbTest: AbTestConfigurationModel | null = null;
  enablingAbTest: AbTestConfigurationModel | null = null;
  isAbTestNameUnique = true;
  configStatusTotals: Record<ConfigsStatus, number> | null = null;
  isConfigCreating = false;
  deploymentInProgress = false;

  get getAbTestConfigNames() {
    return Object.values(this.abTestConfigs)
      .flat()
      .map((value: AbTestConfigurationModel) => value.name.toLowerCase());
  }

  @Mutation
  setLoadingAbTestConfig(payload: boolean) {
    this.loadingConfig = payload;
  }

  @Mutation
  setAbTestConfigs(
    payload: Record<ConfigsStatus, Array<AbTestConfigurationModel>>
  ) {
    Object.entries(payload).forEach(
      ([status, configs]: [string, Array<AbTestConfigurationModel>]) => {
        this.abTestConfigs[status as ConfigsStatus] = configs.map(
          (it: AbTestConfigurationModel) => AbTestConfigurationModel.of(it)
        );
      }
    );
  }

  @Mutation
  setConfigStatusTotals(payload: Record<ConfigsStatus, number>) {
    this.configStatusTotals = payload;
  }

  @Mutation
  updateAbTestConfigStatusTotals(payload: AbTestConfigurationModel) {
    if (this.configStatusTotals) {
      this.configStatusTotals[payload.status as ConfigsStatus]--;
    }
  }

  @Mutation
  setAbTestConfig(payload: AbTestConfigurationModel) {
    this.abTestConfig = AbTestConfigurationModel.of(payload);
  }

  @Mutation
  setAbTestCharts(payload: Array<AbTestChartModel>) {
    this.abTestCharts = payload.map((chartModel) =>
      AbTestChartModel.of(chartModel)
    );
  }

  @Mutation
  setAbTestResultSummary(payload: Array<AbTestResultSummary>) {
    this.abTestResultSummary = payload;
  }

  @Mutation
  setAbTestConfigsAfterDeleteItem(payload: number) {
    this.abTestConfigs = Object.entries(this.abTestConfigs).reduce(
      (
        res: Record<ConfigsStatus, Array<AbTestConfigurationModel>>,
        [status, configs]: [string, Array<AbTestConfigurationModel>]
      ) => {
        return {
          ...res,
          [status]: configs.filter((config) => config.id !== payload),
        };
      },
      {} as Record<ConfigsStatus, Array<AbTestConfigurationModel>>
    );
  }

  @Mutation
  clearAbTestConfigs() {
    this.abTestConfigs = Object.keys(ConfigsStatus).reduce(
      (
        res: Record<ConfigsStatus, Array<AbTestConfigurationModel>>,
        status: string
      ) => {
        return {
          ...res,
          [status]: [],
        };
      },
      {} as Record<ConfigsStatus, Array<AbTestConfigurationModel>>
    );
  }

  @Mutation
  clearAbTestCharts() {
    this.abTestCharts = [];
  }

  @Mutation
  setAbTestChartsLoading(payload: boolean) {
    this.abTestChartsLoading = payload;
  }

  @Mutation
  setResultTypeForAbTestById(payload: Array<AbTestResultModel>) {
    payload.forEach((abTestResult) => {
      const found = this.abTestConfigs[
        ConfigsStatus.STATISTICS_RECALCULATED
      ].find(
        ({ id }: AbTestConfigurationModel) => abTestResult.configId === id
      );

      if (found?.metric) {
        found.resultType = ConfigUtil.getConfigResultType(
          found.metric,
          abTestResult
        );
      }
    });
  }

  @Mutation
  updateAbTestConfigs(payload: AbTestConfigurationModel) {
    const found = (Object.values(this.abTestConfigs) as any)
      .flat()
      .find((item: ApplicationResponseModel) => item.id === payload.id);

    if (this.configStatusTotals) {
      this.configStatusTotals[found.status as ConfigsStatus]--;
      this.configStatusTotals[payload.status as ConfigsStatus]++;
    }

    if (found) {
      found.status = payload.status;
      found.disableable = payload.disableable;

      this.abTestConfigs = Object.entries(this.abTestConfigs).reduce(
        (
          res: Record<ConfigsStatus, Array<AbTestConfigurationModel>>,
          [status, configs]: [string, Array<AbTestConfigurationModel>]
        ) => {
          return {
            ...res,
            [status]: configs.filter((config) => config.status === status),
          };
        },
        {} as Record<ConfigsStatus, Array<AbTestConfigurationModel>>
      );
    }

    if (this.abTestConfig.id === payload.id) {
      this.abTestConfig = payload;
    }
  }

  @Mutation
  setMinUsersCount(payload: AbTestMinUsersCountResponse) {
    this.minUsersCount = payload;
  }

  @Mutation
  resetMinUsersCount() {
    this.minUsersCount = null;
  }

  @Mutation
  setMinUsersCountEventSource(payload: EventSourceWrapper) {
    this.minUsersCountEventSource = payload;
  }

  @Mutation
  closeMinUsersCountEventSource() {
    this.minUsersCountEventSource?.close();
    this.minUsersCountEventSource = null;
    this.isMinUsersCountLoading = false;
  }

  @Mutation
  setMinUsersCountLoading(payload: boolean) {
    this.isMinUsersCountLoading = payload;
  }

  @Mutation
  setEnablingAbTest(payload?: AbTestConfigurationModel) {
    this.enablingAbTest = payload ?? null;
  }

  @Mutation
  setIsAbTestNameUnique(payload: boolean) {
    this.isAbTestNameUnique = payload;
  }

  @Mutation
  setDisablingAbTest(payload?: AbTestConfigurationModel) {
    this.disablingAbTest = payload ?? null;
  }

  @Mutation
  setConfigCreating(payload: boolean) {
    this.isConfigCreating = payload;
  }

  @Mutation
  setDeploymentInProgress(payload: boolean) {
    this.deploymentInProgress = payload;
  }

  @Action({ commit: "setAbTestConfigs" })
  async loadAbTestConfigsByStatuses(statuses?: Array<ConfigsStatus>) {
    this.context.commit("setLoadingAbTestConfig", true);
    this.context.commit("clearAbTestConfigs");

    if (Array.isArray(statuses) && !statuses.length) {
      this.context.commit("setLoadingAbTestConfig", false);

      return {};
    }

    cancelAllRequests();
    refreshToken();

    return axios
      .get(
        `/api/ab-tests/app/${this.context.rootState.application.applicationId}/abtest/configs/statuses/configs`,
        {
          cancelToken: cancelSource.token,
          params: { statuses: statuses?.join(",") },
        }
      )
      .then(
        ({
          data,
        }: AxiosResponse<
          Record<ConfigsStatus, Array<AbTestConfigurationModel>>
        >) => data
      )
      .finally(() => {
        this.context.commit("setLoadingAbTestConfig", false);
      });
  }

  @Action({ commit: "setConfigStatusTotals" })
  async loadAbTestConfigStatusTotals(statuses?: Array<ConfigsStatus>) {
    return axios
      .get(
        `/api/ab-tests/app/${this.context.rootState.application.applicationId}/abtest/configs/statuses/totals`,
        {
          params: { statuses: statuses?.join(",") },
        }
      )
      .then((result: AxiosResponse<Record<ConfigsStatus, number>>) => {
        return result.data;
      });
  }

  @Action({ commit: "setAbTestConfig", rawError: true })
  async getAbTestConfig(configId: number) {
    this.context.commit("setLoadingAbTestConfig", true);
    return axios
      .get(
        `/api/ab-tests/app/${this.context.rootState.application.applicationId}/abtest/configs/${configId}`,
        { skipErrors: true } as AxiosSkipErrorsConfig
      )
      .then((result: AxiosResponse<AbTestConfigurationModel>) => {
        return result.data;
      })
      .finally(() => {
        this.context.commit("setLoadingAbTestConfig", false);
      });
  }

  @Action
  async getAbTestResult(configIds: Array<number>) {
    return axios
      .get(
        `/api/ab-tests/app/${
          this.context.rootState.application.applicationId
        }/abtest/configs/${configIds.join(",")}/result`
      )
      .then(({ data }: AxiosResponse<Array<AbTestResultModel>>) => data);
  }

  @Action({ commit: "setAbTestConfig" })
  async createAbTestConfig(payload: AbTestConfigurationModel) {
    this.context.commit("setConfigCreating", true);

    return axios
      .post(
        `/api/ab-tests/app/${this.context.rootState.application.applicationId}/abtest/configs`,
        payload
      )
      .then((result: AxiosResponse<AbTestConfigurationModel>) => {
        this.context.dispatch("loadAbTestConfigsByStatuses");
        this.context.commit("addNotification", {
          message: {
            key: "abTestConfig.notification.createdSuccess",
            params: [payload.name],
          },
        });

        return result.data;
      })
      .finally(() => {
        this.context.commit("setConfigCreating", false);
      });
  }

  @Action({ commit: "setAbTestConfig" })
  async updateAbTestConfig(payload: AbTestConfigurationModel) {
    return axios
      .patch(
        `/api/ab-tests/app/${this.context.rootState.application.applicationId}/abtest/configs/${payload.id}`,
        payload
      )
      .then((result: AxiosResponse<AbTestConfigurationModel>) => {
        this.context.dispatch("loadAbTestConfigsByStatuses");
        this.context.commit("addNotification", {
          message: {
            key: "abTestConfig.notification.updatedSuccess",
            params: [payload?.name ? payload?.name : ""],
          },
        });

        return result.data;
      });
  }

  @Action({ commit: "setAbTestConfig" })
  async deployAbTestConfigResponse({
    config,
    response,
    environmentType,
  }: {
    config: AbTestConfigurationModel;
    response: AbTestResponseModel;
    environmentType: EnvironmentType;
  }) {
    this.context.commit("setDeploymentInProgress", true);
    const payload = ApplicationResponseModel.ofRequest(
      response,
      environmentType
    );

    return axios
      .post(
        `/api/ab-tests/app/${this.context.rootState.application.applicationId}/abtest/configs/${config.id}/responses/${response.id}/deploy`,
        payload
      )
      .then((result: AxiosResponse<AbTestConfigurationModel>) => result.data)
      .finally(() => {
        this.context.commit("setDeploymentInProgress", false);
      });
  }

  @Action
  async enableAbTest({ abTest, date }: AbTestEnablingModel) {
    if (
      !date?.activeSince &&
      abTest.activeSince &&
      abTest.activeSince <= ConfigUtil.getCurrentDate()
    ) {
      this.context.commit("setEnablingAbTest", abTest);

      return;
    }

    return axios
      .post(
        `/api/ab-tests/app/${this.context.rootState.application.applicationId}/abtest/configs/${abTest.id}/enable`,
        date || {},
        { skipErrors: true } as AxiosSkipErrorsConfig
      )
      .then((result: AxiosResponse<AbTestConfigurationModel>) => {
        this.context.commit("updateAbTestConfigs", result.data);
        this.context.commit("addNotification", {
          message: {
            key: "abTestConfig.notification.updatedStatusSuccess",
          },
        });
      })
      .catch((error) => {
        if (error.response.status === 400) {
          this.context.commit("addNotification", {
            message: {
              key: "abTestConfig.notification.cantBeEnabled",
            },
            type: NotificationType.ERROR,
          });
        } else {
          this.context.dispatch("addError", error.response.message);
        }
      });
  }

  @Action
  async disableAbTest({ abTest, confirmed }: AbTestDisablingModel) {
    return axios
      .post(
        `/api/ab-tests/app/${this.context.rootState.application.applicationId}/abtest/configs/${abTest.id}/disable`,
        {
          ...(confirmed ? { confirmed } : {}),
        },
        { skipErrors: true } as AxiosSkipErrorsConfig
      )
      .then((result: AxiosResponse<AbTestConfigurationModel>) => {
        this.context.commit("updateAbTestConfigs", result.data);
        this.context.commit("addNotification", {
          message: {
            key: "abTestConfig.notification.updatedStatusSuccess",
          },
        });
        this.context.commit("setDisablingAbTest");
      })
      .catch((error) => {
        if (error.response.status === 409) {
          this.context.commit("setDisablingAbTest", abTest);
        }
      });
  }

  @Action({ commit: "setAbTestConfigsAfterDeleteItem" })
  async deleteAbTest(abTest: AbTestConfigurationModel) {
    return axios
      .delete(
        `/api/ab-tests/app/${this.context.rootState.application.applicationId}/abtest/configs/${abTest.id}`
      )
      .then(() => {
        this.context.commit("addNotification", {
          message: {
            key: "abTestConfig.notification.deleteSuccess",
          },
        });
        this.context.commit("updateAbTestConfigStatusTotals", abTest);

        return abTest.id;
      });
  }

  @Action
  async checkSegmentNameByUnique({
    name,
    applicationId,
  }: {
    name: string;
    applicationId: string;
  }) {
    if (!name) {
      return true;
    }

    return axios
      .post(`/api/app/${applicationId}/segments/uniqueName`, {
        name,
      })
      .then((result: any) => result.data.unique);
  }

  @Action
  async getAbTestCharts({
    applicationId,
    abTestId,
    metricType,
    testType,
  }: {
    applicationId: string;
    abTestId: string;
    metricType: string;
    testType?: AbTestStatisticalSignificanceTestType;
  }) {
    this.context.commit("clearAbTestCharts");
    this.context.commit("setAbTestChartsLoading", true);

    return axios
      .get(
        `/api/ab-tests/app/${applicationId}/abtest/configs/${abTestId}/metrics/${metricType}${
          testType ? `?test=${testType}` : ""
        }`
      )
      .then(
        ({
          data: { summary, charts },
        }: AxiosResponse<{
          summary: any;
          charts: Array<AbTestChartModel>;
        }>) => {
          this.context.commit("setAbTestCharts", charts);
          this.context.commit("setAbTestResultSummary", summary);
        }
      )
      .finally(() => {
        this.context.commit("setAbTestChartsLoading", false);
      });
  }

  @Action
  async calculateMinUsersCount({
    appId,
    payload,
  }: {
    appId: string;
    payload: AbTestMinUsersCountRequest;
  }) {
    this.context.commit("setMinUsersCountLoading", true);

    await axios
      .post(
        `/api/ab-tests/app/${appId}/abtest/configs/calculateMinUsersCount`,
        payload
      )
      .then(({ data }: AxiosResponse<string>) => {
        const eventSource = new EventSourceWrapper(
          `/api/ab-tests/app/${appId}/abtest/configs/calculateMinUsersCount/${data}`
        );

        eventSource.addEventListener("message", (data) => {
          this.context.commit("setMinUsersCount", data);
          this.context.commit("closeMinUsersCountEventSource");
        });

        this.context.commit("setMinUsersCountEventSource", eventSource);
      })
      .catch(() => {
        this.context.commit("setMinUsersCountLoading", false);
      });
  }

  @Action({ commit: "setIsAbTestNameUnique" })
  async checkIsAbTestNameUnique({
    appId,
    abTest,
  }: {
    appId: string;
    abTest: AbTestConfigurationModel;
  }) {
    if (!abTest.name) {
      return true;
    }

    return axios
      .post(`/api/ab-tests/app/${appId}/abtest/configs/uniqueName`, {
        name: abTest.name.trim(),
      })
      .then((result: AxiosResponse<{ unique: boolean }>) => result.data.unique);
  }

  @Action({ commit: "setAbTestConfig" })
  async getAbTestConfigBySegmentId(segmentId: number) {
    this.context.commit("setLoadingAbTestConfig", true);

    return axios
      .get(
        `/api/ab-tests/app/${this.context.rootState.application.applicationId}/abtest/configs/search`,
        { params: { segmentId: segmentId } }
      )
      .then((result: AxiosResponse<AbTestConfigurationModel>) => result.data)
      .finally(() => {
        this.context.commit("setLoadingAbTestConfig", false);
      });
  }
}
