import { ReportType } from "@/reports/models";
import ReportUtil from "@/reports/utils/ReportUtil";
import { SEGMENT_ACTIVE_STATUSES } from "@/segments/models/SegmentModel";
import DateUtil from "@/shared/utils/DateUtil";
import { Dictionary, SegmentDictionary, TrackerDictionary } from "./Dictionary";
import { DictionaryType } from "./DictionaryType";
import { EventOperation } from "./PickerModel";

export enum FilterId {
  ATTRIBUTION_DATE_VALUE = "ATTRIBUTION_DATE_VALUE",
  ATTRIBUTION_STATUS = "ATTRIBUTION_STATUS",
  ATTRIBUTION_DATE = "ATTRIBUTION_DATE",
  COUNTRY = "COUNTRY",
  AD_REVENUE = "AD_REVENUE",
  IAP_REVENUE = "IAP_REVENUE",
  SOURCE = "SOURCE",
  SUB_SOURCE = "SUB_SOURCE",
  CAMPAIGN = "CAMPAIGN",
  AD_SET = "AD_SET",
  CREATIVE = "CREATIVE",
  PUBLISHER = "PUBLISHER",
  TRACKER = "TRACKER",
  DEVICE_TYPE = "DEVICE_TYPE",
  DEVICE_MODEL = "DEVICE_MODEL",
  INSTALL_APP_VERSION = "INSTALL_APP_VERSION",
  APP_VERSION = "APP_VERSION",
  OS_VERSION = "OS_VERSION",
  LANGUAGE = "LANGUAGE",
  SESSION_NUMBER = "SESSION_NUMBER",
  SEGMENT = "SEGMENT",
  EVENT_PARAMETER = "EVENT_PARAMETER",
  EVENT = "EVENT",
  RANGE_BASED_MULTIPLE_EVENT = "RANGE_BASED_MULTIPLE_EVENT",
  EVENTS_DATE = "EVENTS_DATE",
  EVENTS_APP_VERSION = "EVENTS_APP_VERSION",
  EVENTS_OS_VERSION = "EVENTS_OS_VERSION",
  EVENTS_COUNTRY = "EVENTS_COUNTRY",
  ACTIVITY_KIND = "ACTIVITY_KIND",
  LAT = "LAT",
  WATERFALLS_ID = "WATERFALLS_ID",
  AD_NETWORK_NAME = "AD_NETWORK_NAME",
  NET_REVENUE = "NET_REVENUE",
  DATE = "DATE",
  SIMPLE_MULTIPLE_EVENT = "SIMPLE_MULTIPLE_EVENT",
  ACTUAL_EVENT_PARAMETER = "ACTUAL_EVENT_PARAMETER",
  USER_AGE = "USER_AGE",
  SUBSCRIPTION_RENEWAL_COUNT = "SUBSCRIPTION_RENEWAL_COUNT",

  //only for groupBy
  PLATFORM = "PLATFORM",
  APPLICATION = "APPLICATION",
}

export type NetworksFilterType =
  | FilterId.AD_NETWORK_NAME
  | FilterId.SOURCE
  | FilterId.TRACKER;

export enum FilterPreviewId {
  EVENTS_DATE = "EVENTS_DATE",
  ATTRIBUTION_DATE_VALUE = "ATTRIBUTION_DATE_VALUE",
  DAY_LIMIT = "DAY_LIMIT",
  SEGMENT = "SEGMENT",
  PLATFORMS = "PLATFORMS",
  APPLICATIONS = "APPLICATIONS",
  COUNTRY = "COUNTRY",
  SOURCE = "SOURCE",
  SUB_SOURCE = "SUB_SOURCE",
  CAMPAIGN = "CAMPAIGN",
  AD_SET = "AD_SET",
  CREATIVE = "CREATIVE",
  PUBLISHER = "PUBLISHER",
  ATTRIBUTION_STATUS = "ATTRIBUTION_STATUS",
  EVENTS_COUNTRY = "EVENTS_COUNTRY",
  DEVICE_TYPE = "DEVICE_TYPE",
  EVENTS_APP_VERSION = "EVENTS_APP_VERSION",
  WATERFALLS_ID = "WATERFALLS_ID",
  LAT = "LAT",
  AD_REVENUE = "AD_REVENUE",
  IAP_REVENUE = "IAP_REVENUE",
  AD_NETWORK_NAME = "AD_NETWORK_NAME",
  NET_REVENUE = "NET_REVENUE",
  PROVIDED_BANNER = "PROVIDED_BANNER",
  AD_REVENUE_METHOD = "AD_REVENUE_METHOD",
  USER_ACTIVITY = "USER_ACTIVITY",
  EVENT = "EVENT",
  EVENT_NAME = "EVENT_NAME",
  EVENT_PARAM = "EVENT_PARAM",
  GROUP_BY = "GROUP_BY",
  AGGREGATION_PERIOD = "AGGREGATION_PERIOD",
  AD_TYPE = "AD_TYPE",
  REPORT_TYPE_TIME_SPENT = "REPORT_TYPE_TIME_SPENT",
  REPORT_TYPE_MEASURES_LITE = "REPORT_TYPE_MEASURES_LITE",
  REPORT_TYPE_COHORT_PERMIN = "REPORT_TYPE_COHORT_PERMIN",
  REPORT_TYPE_PERMIN = "REPORT_TYPE_PERMIN",
  GENERATED_EVENT = "GENERATED_EVENT",
  GENERATED_EVENT_PARAM = "GENERATED_EVENT_PARAM",
  GENERATED_EVENT_PARAM_DATE = "GENERATED_EVENT_PARAM_DATE",
  EVENT_SIMPLE = "EVENT_SIMPLE",
  REVENUE = "REVENUE",
  CUMULATIVE = "CUMULATIVE",
  INSTALL_APP_VERSION = "INSTALL_APP_VERSION",
  EVENTS_OS_VERSION = "EVENTS_OS_VERSION",
  ATTRIBUTION_DATE = "ATTRIBUTION_DATE",
  TRACKER = "TRACKER",
  DEVICE_MODEL = "DEVICE_MODEL",
  APP_VERSION = "APP_VERSION",
  OS_VERSION = "OS_VERSION",
  LANGUAGE = "LANGUAGE",
  SESSION_NUMBER = "SESSION_NUMBER",
  RANGE_BASED_MULTIPLE_EVENT = "RANGE_BASED_MULTIPLE_EVENT",
  ACTIVITY_KIND = "ACTIVITY_KIND",
  EVENT_PARAMETER = "EVENT_PARAMETER",
  ADDITIONAL_GROUPING = "ADDITIONAL_GROUPING",
  TIME_SPENT_TYPE = "TIME_SPENT_TYPE",
  EVENT_COST = "EVENT_COST",
  EVENTS_DAY_LIMIT = "EVENTS_DAY_LIMIT",
  ROUND_SESSIONS_TO_SECONDS = "ROUND_SESSIONS_TO_SECONDS",
  START_DAY = "START_DAY",
  END_DAY = "END_DAY",
  GOAL = "GOAL",
  TARGET_DAY_MODE = "TARGET_DAY_MODE",
  TARGET_DAY = "TARGET_DAY",
  INCLUDE_IAPS = "INCLUDE_IAPS",
  CONVERSION_VALUE = "CONVERSION_VALUE",
  SKAD_REPORT_TYPE = "SKAD_REPORT_TYPE",
  EXCLUDE_NO_INTERNET = "EXCLUDE_NO_INTERNET",
  ACTUAL_EVENT_PARAMETER = "ACTUAL_EVENT_PARAMETER",
  EVENT_PARAMS = "EVENT_PARAMS",
  FROM = "FROM",
  SESSIONS_COUNT = "SESSIONS_COUNT",
  SESSION_NUMBER_PERIOD = "SESSION_NUMBER_PERIOD",
  EVENT_COMBINATION = "EVENT_COMBINATION",
  EVENT_RANGE = "EVENT_RANGE",
  EVENTS = "EVENTS",
  NETWORK_NAMES = "NETWORK_NAMES",
  ACCRUAL_DATE_VALUE = "ACCRUAL_DATE_VALUE",
  COHORT_METRICS_DAYS = "COHORT_METRICS_DAYS",
  USER_AGE = "USER_AGE",
  SUBSCRIPTION_RENEWAL_COUNT = "SUBSCRIPTION_RENEWAL_COUNT",
  REVENUE_TYPE = "REVENUE_TYPE",
  NO_MONITORING = "NO_MONITORING",
  COHORT = "COHORT",
  COUNTERPARTY = "COUNTERPARTY",
  BANK = "BANK",
  TAXATION = "TAXATION",
  PAYMENT_DATE = "PAYMENT_DATE",
  SHOW_UNIQUE_DEVICES = "SHOW_UNIQUE_DEVICES",
}

export const FILTER_ID_TO_DATA_TYPES: Record<
  FilterId,
  Array<DictionaryType>
> = {
  ATTRIBUTION_DATE_VALUE: [],
  ATTRIBUTION_STATUS: [DictionaryType.ATTRIBUTION_STATUS],
  ATTRIBUTION_DATE: [DictionaryType.ATTRIBUTION_STATUS],
  COUNTRY: [DictionaryType.GROUPED_COUNTRIES, DictionaryType.COUNTRIES],
  AD_REVENUE: [DictionaryType.REVENUES],
  IAP_REVENUE: [DictionaryType.REVENUES],
  SOURCE: [DictionaryType.SOURCES],
  SUB_SOURCE: [DictionaryType.SUB_SOURCES],
  CAMPAIGN: [DictionaryType.CAMPAIGNS],
  AD_SET: [DictionaryType.AD_SETS],
  CREATIVE: [DictionaryType.CREATIVES],
  PUBLISHER: [DictionaryType.PUBLISHERS],
  TRACKER: [],
  DEVICE_TYPE: [DictionaryType.DEVICE_TYPES],
  DEVICE_MODEL: [DictionaryType.DEVICE_MODELS],
  INSTALL_APP_VERSION: [
    DictionaryType.GROUPED_APP_VERSIONS,
    DictionaryType.APP_VERSIONS,
  ],
  APP_VERSION: [
    DictionaryType.GROUPED_APP_VERSIONS,
    DictionaryType.APP_VERSIONS,
  ],
  OS_VERSION: [DictionaryType.OS_VERSIONS],
  LANGUAGE: [DictionaryType.LANGUAGES],
  SESSION_NUMBER: [],
  EVENT: [DictionaryType.EVENT_NAMES, DictionaryType.EVENT_PARAM_KEYS],
  RANGE_BASED_MULTIPLE_EVENT: [
    DictionaryType.EVENT_NAMES,
    DictionaryType.EVENT_PARAM_KEYS,
  ],
  SEGMENT: [DictionaryType.SEGMENTS],
  EVENTS_APP_VERSION: [
    DictionaryType.GROUPED_APP_VERSIONS,
    DictionaryType.APP_VERSIONS,
  ],
  EVENTS_OS_VERSION: [DictionaryType.OS_VERSIONS],
  EVENTS_COUNTRY: [DictionaryType.GROUPED_COUNTRIES, DictionaryType.COUNTRIES],
  ACTIVITY_KIND: [DictionaryType.ACTIVITY_KIND],
  LAT: [],
  AD_NETWORK_NAME: [DictionaryType.ROOT_AD_NETWORK_NAMES],
  WATERFALLS_ID: [DictionaryType.WATERFALLS_ID],
  NET_REVENUE: [],
  EVENT_PARAMETER: [],
  EVENTS_DATE: [],
  PLATFORM: [],
  APPLICATION: [],
  DATE: [],
  SIMPLE_MULTIPLE_EVENT: [DictionaryType.EVENT_NAMES],
  ACTUAL_EVENT_PARAMETER: [
    DictionaryType.EVENT_NAMES,
    DictionaryType.EVENT_PARAM_KEYS,
  ],
  USER_AGE: [],
  SUBSCRIPTION_RENEWAL_COUNT: [],
};

export const COMMON_FILTER_ID_TO_ITEM_FILTER: Map<
  FilterId,
  (item: Dictionary, currentValue: any) => boolean
> = new Map([
  [
    FilterId.SEGMENT,
    (item, currentValue) =>
      (currentValue as Array<string>).includes(item.value) ||
      SEGMENT_ACTIVE_STATUSES.includes((item as SegmentDictionary).status),
  ],
]);

export const REPORT_FILTER_ID_TO_ITEM_FILTER: Map<
  FilterId,
  (type: ReportType) => (item: Dictionary, currentValue: any) => boolean
> = new Map([
  [
    FilterId.TRACKER,
    (type: ReportType) => (item: Dictionary) => {
      const filter = ReportUtil.trackerItemsFilter(type);
      return filter ? filter(item as TrackerDictionary) : true;
    },
  ],
  [
    FilterId.SOURCE,
    (type: ReportType) => (item: Dictionary) => {
      const filter = ReportUtil.sourceItemsFilter(type);
      return filter ? filter(item as TrackerDictionary) : true;
    },
  ],
  [
    FilterId.DEVICE_TYPE,
    (type: ReportType) => (item: Dictionary) => {
      const filter = ReportUtil.deviceTypeItemsFilter(type);
      return filter ? filter(item as TrackerDictionary) : true;
    },
  ],
  [
    FilterId.SEGMENT,
    (type: ReportType) => (item: Dictionary, currentValue: any) => {
      const filter = ReportUtil.segmentItemsFilter(type);

      return filter
        ? filter(item as SegmentDictionary, currentValue as Array<string>)
        : true;
    },
  ],
]);

export const FILTER_ID_TO_FILTER_PREVIEW_ID: Record<FilterId, FilterPreviewId> =
  {
    ATTRIBUTION_DATE_VALUE: FilterPreviewId.ATTRIBUTION_DATE_VALUE,
    ATTRIBUTION_STATUS: FilterPreviewId.ATTRIBUTION_STATUS,
    ATTRIBUTION_DATE: FilterPreviewId.ATTRIBUTION_DATE,
    COUNTRY: FilterPreviewId.COUNTRY,
    AD_REVENUE: FilterPreviewId.AD_REVENUE,
    IAP_REVENUE: FilterPreviewId.IAP_REVENUE,
    SOURCE: FilterPreviewId.SOURCE,
    SUB_SOURCE: FilterPreviewId.SUB_SOURCE,
    CAMPAIGN: FilterPreviewId.CAMPAIGN,
    AD_SET: FilterPreviewId.AD_SET,
    CREATIVE: FilterPreviewId.CREATIVE,
    PUBLISHER: FilterPreviewId.PUBLISHER,
    TRACKER: FilterPreviewId.TRACKER,
    DEVICE_TYPE: FilterPreviewId.DEVICE_TYPE,
    DEVICE_MODEL: FilterPreviewId.DEVICE_MODEL,
    INSTALL_APP_VERSION: FilterPreviewId.INSTALL_APP_VERSION,
    APP_VERSION: FilterPreviewId.APP_VERSION,
    OS_VERSION: FilterPreviewId.OS_VERSION,
    LANGUAGE: FilterPreviewId.LANGUAGE,
    SESSION_NUMBER: FilterPreviewId.SESSION_NUMBER,
    EVENT: FilterPreviewId.EVENT,
    RANGE_BASED_MULTIPLE_EVENT: FilterPreviewId.RANGE_BASED_MULTIPLE_EVENT,
    SEGMENT: FilterPreviewId.SEGMENT,
    EVENTS_APP_VERSION: FilterPreviewId.EVENTS_APP_VERSION,
    EVENTS_OS_VERSION: FilterPreviewId.EVENTS_OS_VERSION,
    EVENTS_COUNTRY: FilterPreviewId.EVENTS_COUNTRY,
    ACTIVITY_KIND: FilterPreviewId.ACTIVITY_KIND,
    LAT: FilterPreviewId.LAT,
    AD_NETWORK_NAME: FilterPreviewId.AD_NETWORK_NAME,
    WATERFALLS_ID: FilterPreviewId.WATERFALLS_ID,
    EVENT_PARAMETER: FilterPreviewId.EVENT_PARAMETER,
    EVENTS_DATE: FilterPreviewId.EVENTS_DATE,
    PLATFORM: FilterPreviewId.PLATFORMS,
    APPLICATION: FilterPreviewId.APPLICATIONS,
    NET_REVENUE: FilterPreviewId.NET_REVENUE,
    DATE: FilterPreviewId.EVENTS_DATE,
    SIMPLE_MULTIPLE_EVENT: FilterPreviewId.EVENT_COST,
    ACTUAL_EVENT_PARAMETER: FilterPreviewId.ACTUAL_EVENT_PARAMETER,
    USER_AGE: FilterPreviewId.USER_AGE,
    SUBSCRIPTION_RENEWAL_COUNT: FilterPreviewId.SUBSCRIPTION_RENEWAL_COUNT,
  };

export class FilterPreview {
  constructor(
    public id: FilterPreviewId,
    public value: any,
    public excluded?: boolean,
    public dictionary?: DictionaryType
  ) {}

  static preparingRangeDataForPreview = (
    operator: EventOperation,
    eventParamName: string,
    value: Array<string | number>
  ): string => {
    if (operator === EventOperation.EQUAL) {
      return `${eventParamName} = ${value[0]}`;
    } else if (operator === EventOperation.NOT_EQUAL) {
      return `${eventParamName} ≠ ${value[0]}`;
    } else if (operator === EventOperation.BETWEEN) {
      return `${eventParamName} ≥ ${value[0]} and ${eventParamName} ≤ ${value[1]}`;
    } else if (operator === EventOperation.NOT_BETWEEN) {
      return `${eventParamName} < ${value[0]} or ${eventParamName} > ${value[1]}`;
    } else if (operator === EventOperation.GREATER) {
      return `${eventParamName} > ${value[0]}`;
    } else if (operator === EventOperation.LESS) {
      return `${eventParamName} < ${value[0]}`;
    } else if (operator === EventOperation.IN) {
      return value.reduce(
        (result: string, item: string | number, index: number) => {
          return (result += `${eventParamName} = ${item} ${
            index !== value.length - 1 ? `or ` : ""
          }`);
        },
        ""
      );
    } else if (operator === EventOperation.NOT_IN) {
      return value.reduce(
        (result: string, item: string | number, index: number) => {
          return (result += `${eventParamName} ≠ ${item} ${
            index !== value.length - 1 ? `or ` : ""
          }`);
        },
        ""
      );
    } else if (operator === EventOperation.LIKE) {
      return `${eventParamName} like ${value[0]}`;
    } else if (operator === EventOperation.NOT_LIKE) {
      return `${eventParamName} not like ${value[0]}`;
    }

    return "";
  };
}

export class FilterModel {
  id: FilterId;
  required: boolean;
  filled: boolean;
  valid: boolean;
  defaultValues: Array<string>;

  constructor(
    id: FilterId,
    required = false,
    filled = false,
    valid = false,
    defaultValues: Array<string> = []
  ) {
    this.id = id;
    this.required = required;
    this.filled = filled;
    this.valid = valid;
    this.defaultValues = defaultValues;
  }

  get preview(): FilterPreview | Array<FilterPreview> {
    return [];
  }

  isEmpty(): boolean {
    return true;
  }

  toRecord(): Record<string, any> {
    return { id: this.id };
  }

  static of(model: FilterModel): FilterModel {
    return new FilterModel(model.id);
  }

  clone(): FilterModel {
    return FilterModel.of(this);
  }

  getUsedDictionaryValues(): Map<DictionaryType, Array<string>> {
    return new Map();
  }
}

export class ExcludableMultipleValueFilterModel extends FilterModel {
  included: boolean;
  values: Array<string>;

  constructor(
    id: FilterId,
    defaultValues: Array<string> = [],
    required = false,
    filled = false,
    valid = false,
    included = true,
    values: Array<string> | Record<string, string> = []
  ) {
    super(id, required, filled, valid, defaultValues);
    this.included = included;
    // by deeplink if there are more than 21 values, then for some reason an object comes instead of an array (This need to look again later)
    this.values = Array.isArray(values) ? values : Object.values(values);
  }

  get dictionaryType(): DictionaryType | undefined {
    // cause if we have a group, then it goes first
    return FILTER_ID_TO_DATA_TYPES[this.id].length > 1
      ? FILTER_ID_TO_DATA_TYPES[this.id][1]
      : FILTER_ID_TO_DATA_TYPES[this.id].length === 1
      ? FILTER_ID_TO_DATA_TYPES[this.id][0]
      : undefined;
  }

  get preview(): FilterPreview {
    return {
      id: FILTER_ID_TO_FILTER_PREVIEW_ID[this.id],
      value: this.values,
      excluded: !this.included,
      dictionary: this.dictionaryType,
    };
  }

  static of(
    model: ExcludableMultipleValueFilterModel
  ): ExcludableMultipleValueFilterModel {
    return new ExcludableMultipleValueFilterModel(
      model.id,
      model.defaultValues,
      model.required,
      model.filled,
      model.valid,
      typeof model.included === "string"
        ? model.included === "true"
        : model.included,
      //TODO  segmentId is Integer in FunnelDto but segmentId is String in DictionaryDto
      // so we should cast to the same type and revert this change
      model.values.map((it) => it.toString())
    );
  }

  isEmpty(): boolean {
    return this.values.length === 0;
  }

  toRecord(): Record<string, any> {
    const result = super.toRecord();
    result["values"] = this.values;
    result["included"] = this.included;
    return result;
  }

  clone(): ExcludableMultipleValueFilterModel {
    return ExcludableMultipleValueFilterModel.of(this);
  }

  getUsedDictionaryValues(): Map<DictionaryType, Array<string>> {
    if (!this.dictionaryType) {
      return super.getUsedDictionaryValues();
    }

    return new Map([[this.dictionaryType, this.values]]);
  }
}

export class MultipleValueFilterModel extends FilterModel {
  values: Array<string>;

  constructor(
    id: FilterId,
    required = false,
    filled = false,
    valid = false,
    values: Array<string> = []
  ) {
    super(id, required, filled, valid);
    this.values = values;
  }

  static of(model: MultipleValueFilterModel): MultipleValueFilterModel {
    return new MultipleValueFilterModel(
      model.id,
      model.required,
      model.filled,
      model.valid,
      model.values
    );
  }

  isEmpty(): boolean {
    return this.values.length === 0;
  }

  toRecord(): Record<string, any> {
    const result = super.toRecord();
    result["values"] = this.values;
    return result;
  }

  get dictionaryType(): DictionaryType | undefined {
    return FILTER_ID_TO_DATA_TYPES[this.id][0];
  }

  get preview(): FilterPreview {
    return {
      id: FILTER_ID_TO_FILTER_PREVIEW_ID[this.id],
      value: this.values,
      dictionary: this.dictionaryType,
    };
  }

  clone(): MultipleValueFilterModel {
    return MultipleValueFilterModel.of(this);
  }

  getUsedDictionaryValues(): Map<DictionaryType, Array<string>> {
    if (!this.dictionaryType) {
      return super.getUsedDictionaryValues();
    }

    return new Map([[this.dictionaryType, this.values]]);
  }
}

export class BoolFilterModel extends FilterModel {
  value: boolean | null;

  constructor(
    id: FilterId,
    required = false,
    filled = false,
    valid = false,
    value: boolean | null = null
  ) {
    super(id, required, filled, valid);
    this.value = value;
  }

  static of(model: BoolFilterModel): BoolFilterModel {
    return new BoolFilterModel(
      model.id,
      model.required,
      model.filled,
      model.valid,
      model.value
    );
  }

  isEmpty(): boolean {
    return this.value === null;
  }

  toRecord(): Record<string, any> {
    const result = super.toRecord();
    result["value"] = this.value;
    return result;
  }

  get preview(): FilterPreview {
    return {
      id: FILTER_ID_TO_FILTER_PREVIEW_ID[this.id],
      value: !!this.value,
    };
  }

  clone(): BoolFilterModel {
    return BoolFilterModel.of(this);
  }
}

export class DatesFilterModel extends FilterModel {
  from: string;
  to: string;

  constructor(
    id: FilterId,
    required = false,
    filled = false,
    valid = false,
    from: string = DateUtil.daysAgoFromCurrent(30),
    to: string = DateUtil.daysAfterCurrent(30),
    public clearable?: boolean
  ) {
    super(id, required, filled, valid);
    this.from = from;
    this.to = to;
  }

  get preview(): FilterPreview | Array<FilterPreview> {
    const dateTo = this.from !== this.to ? ` — ${this.to}` : "";

    return {
      id: FILTER_ID_TO_FILTER_PREVIEW_ID[this.id],
      value: `${this.from}${dateTo}`,
    };
  }

  static of(model: DatesFilterModel): DatesFilterModel {
    return new DatesFilterModel(
      model.id,
      model.required,
      model.filled,
      model.valid,
      model.from,
      model.to,
      model.clearable
    );
  }

  static ofRecord(
    id: FilterId,
    filter: Record<string, any>,
    required = true,
    clearable = false
  ): DatesFilterModel | undefined {
    return filter
      ? new DatesFilterModel(
          id,
          required,
          false,
          true,
          filter.from as string,
          filter.to as string,
          clearable
        )
      : undefined;
  }

  isEmpty(): boolean {
    return !this.from || !this.to;
  }

  toRecord(): Record<string, any> {
    const result = super.toRecord();
    result["from"] = this.from;
    result["to"] = this.to;
    return result;
  }

  clone(): DatesFilterModel {
    return DatesFilterModel.of(this);
  }
}

export class AttributionDateFilterModel extends DatesFilterModel {
  attributionStatuses: Array<string>;

  constructor(
    id: FilterId,
    required = false,
    filled = false,
    valid = false,
    attributionStatuses: Array<string> = [],
    from: string = DateUtil.daysAgoFromCurrent(30),
    to: string = DateUtil.daysAfterCurrent(30)
  ) {
    super(id, required, filled, valid, from, to);
    this.attributionStatuses = attributionStatuses;
  }

  get preview(): Array<FilterPreview> {
    return [
      ...(Array.isArray(super.preview) ? super.preview : [super.preview]),
      {
        id: FILTER_ID_TO_FILTER_PREVIEW_ID[FilterPreviewId.ATTRIBUTION_STATUS],
        value: this.attributionStatuses,
      },
    ];
  }

  static of(model: AttributionDateFilterModel): AttributionDateFilterModel {
    return new AttributionDateFilterModel(
      model.id,
      model.required,
      model.filled,
      model.valid,
      model.attributionStatuses,
      model.from,
      model.to
    );
  }

  isEmpty(): boolean {
    return super.isEmpty() || this.attributionStatuses.length === 0;
  }

  toRecord(): Record<string, any> {
    const result = super.toRecord();
    result["attributionStatuses"] = this.attributionStatuses;
    return result;
  }

  clone(): DatesFilterModel {
    return DatesFilterModel.of(this);
  }
}

export class NumbersFilterModel extends FilterModel {
  constructor(
    id: FilterId,
    required = false,
    filled = false,
    valid = false,
    public range = new RangeModel()
  ) {
    super(id, required, filled, valid);
  }

  get preview(): Array<FilterPreview> | FilterPreview {
    if (this.id === FilterId.IAP_REVENUE || this.id === FilterId.AD_REVENUE) {
      return {
        id: FilterPreviewId.REVENUE,
        value: FilterPreview.preparingRangeDataForPreview(
          this.range.operator,
          "Value",
          this.range.params
        ),
      };
    } else if (this.id === FilterId.USER_AGE) {
      return {
        id: FilterPreviewId.USER_AGE,
        value: FilterPreview.preparingRangeDataForPreview(
          this.range.operator,
          "Day",
          this.range.params
        ),
      };
    } else if (this.id === FilterId.SUBSCRIPTION_RENEWAL_COUNT) {
      return {
        id: FilterPreviewId.SUBSCRIPTION_RENEWAL_COUNT,
        value: FilterPreview.preparingRangeDataForPreview(
          this.range.operator,
          "Value",
          this.range.params
        ),
      };
    }

    return [];
  }

  static of(model: NumbersFilterModel): NumbersFilterModel {
    return new NumbersFilterModel(
      model.id,
      model.required,
      model.filled,
      model.valid,
      model.range
    );
  }

  isEmpty(): boolean {
    return !this.range.params[0] || !this.range.params[1];
  }

  toRecord(): Record<string, any> {
    const result = super.toRecord();
    result["range"] = this.range.toRecord();
    return result;
  }

  clone(): NumbersFilterModel {
    return NumbersFilterModel.of(this);
  }
}

export class TrackerFilterPartModel {
  included: boolean;
  values: Array<string> | Record<string, any>;

  constructor(included = true, values: Array<string> = []) {
    this.included = included;
    this.values = Array.isArray(values) ? values : Object.values(values);
  }

  static of(model: TrackerFilterPartModel): TrackerFilterPartModel {
    return new TrackerFilterPartModel(
      typeof model.included === "string"
        ? model.included === "true"
        : model.included,
      Array.isArray(model.values) ? model.values : Object.values(model.values)
    );
  }

  static ofRecord(filter: Record<string, any>): TrackerFilterPartModel {
    return new TrackerFilterPartModel(
      typeof filter.included === "string"
        ? filter.included === "true"
        : filter.included,
      Array.isArray(filter.values)
        ? filter.values
        : Object.values(filter.values)
    );
  }

  isEmpty(): boolean {
    return this.values.length === 0;
  }
}

export class TrackerFilterModel extends FilterModel {
  source?: TrackerFilterPartModel;
  subSource?: TrackerFilterPartModel;
  campaign?: TrackerFilterPartModel;
  adSet?: TrackerFilterPartModel;
  creative?: TrackerFilterPartModel;
  publisher?: TrackerFilterPartModel;

  constructor(
    id: FilterId,
    required = false,
    filled = false,
    valid = false,
    source?: TrackerFilterPartModel,
    subSource?: TrackerFilterPartModel,
    campaign?: TrackerFilterPartModel,
    adSet?: TrackerFilterPartModel,
    creative?: TrackerFilterPartModel,
    publisher?: TrackerFilterPartModel
  ) {
    super(id, required, filled, valid);
    this.source = source;
    this.subSource = subSource;
    this.campaign = campaign;
    this.adSet = adSet;
    this.creative = creative;
    this.publisher = publisher;
  }

  get preview(): Array<FilterPreview> {
    type TrackerFilterPropName =
      | "source"
      | "subSource"
      | "campaign"
      | "adSet"
      | "creative"
      | "publisher";

    const results: Array<FilterPreview> = [];

    new Map([
      [FilterPreviewId.SOURCE, "source"],
      [FilterPreviewId.SUB_SOURCE, "subSource"],
      [FilterPreviewId.CAMPAIGN, "campaign"],
      [FilterPreviewId.AD_SET, "adSet"],
      [FilterPreviewId.CREATIVE, "creative"],
      [FilterPreviewId.PUBLISHER, "publisher"],
    ]).forEach((property: string, id: FilterPreviewId) => {
      const prop = this[property as TrackerFilterPropName];

      if (!prop) {
        return;
      }

      results.push({
        id,
        value: prop.values as Array<string>,
        excluded: !prop.included,
      });
    });

    return results;
  }

  getUsedDictionaryValues() {
    const trackerValuesMap = new Map();

    if (this.source?.values.length) {
      trackerValuesMap.set(DictionaryType.SOURCES, this.source?.values);
    }

    if (this.subSource?.values.length) {
      trackerValuesMap.set(DictionaryType.SUB_SOURCES, this.subSource?.values);
    }

    if (this.campaign?.values.length) {
      trackerValuesMap.set(DictionaryType.CAMPAIGNS, this.campaign?.values);
    }

    if (this.adSet?.values.length) {
      trackerValuesMap.set(DictionaryType.AD_SETS, this.adSet?.values);
    }

    if (this.creative?.values.length) {
      trackerValuesMap.set(DictionaryType.CREATIVES, this.creative?.values);
    }

    if (this.publisher?.values.length) {
      trackerValuesMap.set(DictionaryType.PUBLISHERS, this.publisher?.values);
    }

    return trackerValuesMap;
  }

  static of(model: TrackerFilterModel): TrackerFilterModel {
    return new TrackerFilterModel(
      model.id,
      model.required,
      model.filled,
      model.valid,
      model.source,
      model.subSource,
      model.campaign,
      model.adSet,
      model.creative,
      model.publisher
    );
  }

  isEmpty(): boolean {
    return !this.source || this.source.isEmpty();
  }

  toRecord(): Record<string, any> {
    const result = super.toRecord();
    if (this.source && !this.source.isEmpty()) {
      result["source"] = this.source;
    }
    if (this.subSource && !this.subSource.isEmpty()) {
      result["subSource"] = this.subSource;
    }
    if (this.campaign && !this.campaign.isEmpty()) {
      result["campaign"] = this.campaign;
    }
    if (this.adSet && !this.adSet.isEmpty()) {
      result["adSet"] = this.adSet;
    }
    if (this.creative && !this.creative.isEmpty()) {
      result["creative"] = this.creative;
    }
    if (this.publisher && !this.publisher.isEmpty()) {
      result["publisher"] = this.publisher;
    }
    return result;
  }

  clone(): TrackerFilterModel {
    return TrackerFilterModel.of(this);
  }
}

export enum AdRevenueMethod {
  PRICED = "PRICED",
  AVERAGE = "AVERAGE",
}

export class AdRevenueFilterModel extends NumbersFilterModel {
  constructor(
    id: FilterId,
    required = false,
    filled = false,
    valid = false,
    range = new RangeModel(),
    public revenueMethod: AdRevenueMethod = AdRevenueMethod.PRICED
  ) {
    super(id, required, filled, valid, range);
  }

  get preview(): Array<FilterPreview> {
    return [
      {
        id: FilterPreviewId.AD_REVENUE_METHOD,
        value: this.revenueMethod,
      },
      ...(Array.isArray(super.preview) ? super.preview : [super.preview]),
    ];
  }

  static of(model: AdRevenueFilterModel): AdRevenueFilterModel {
    return new AdRevenueFilterModel(
      model.id,
      model.required,
      model.filled,
      model.valid,
      model.range,
      model.revenueMethod
    );
  }

  isEmpty(): boolean {
    return super.isEmpty();
  }

  toRecord(): Record<string, any> {
    const result = super.toRecord();
    result["revenueMethod"] = this.revenueMethod;
    return result;
  }

  clone(): AdRevenueFilterModel {
    return AdRevenueFilterModel.of(this);
  }
}

export class IapRevenueFilterModel extends NumbersFilterModel {
  constructor(
    id: FilterId,
    required = false,
    filled = false,
    valid = false,
    range = new RangeModel(),
    public revenue: Array<string> = []
  ) {
    super(id, required, filled, valid, range);
  }

  get preview(): Array<FilterPreview> {
    return [
      {
        id: FilterPreviewId.REVENUE_TYPE,
        value: this.revenue,
      },
      ...(Array.isArray(super.preview) ? super.preview : [super.preview]),
    ];
  }

  static of(model: IapRevenueFilterModel): IapRevenueFilterModel {
    return new IapRevenueFilterModel(
      model.id,
      model.required,
      model.filled,
      model.valid,
      model.range,
      model.revenue
    );
  }

  toRecord(): Record<string, any> {
    const result = super.toRecord();
    result["revenue"] = this.revenue;
    return result;
  }

  clone(): IapRevenueFilterModel {
    return IapRevenueFilterModel.of(this);
  }
}

export enum SessionNumberPeriod {
  FIRST_DAY = "FIRST_DAY",
  FIRST_WEEK = "FIRST_WEEK",
  FIRST_30_DAYS = "FIRST_30_DAYS",
  LAST_DAY = "LAST_DAY",
  LAST_WEEK = "LAST_WEEK",
  LAST_30_DAYS = "LAST_30_DAYS",
}

export class SessionNumberModel extends NumbersFilterModel {
  constructor(
    id: FilterId,
    required = false,
    filled = false,
    valid = false,
    range = new RangeModel(),
    public period: SessionNumberPeriod = SessionNumberPeriod.FIRST_DAY
  ) {
    super(id, required, filled, valid, range);
  }

  get preview(): Array<FilterPreview> {
    return [
      {
        id: FilterPreviewId.SESSION_NUMBER_PERIOD,
        value: this.period,
      },
      {
        id: FilterPreviewId.SESSIONS_COUNT,
        value: FilterPreview.preparingRangeDataForPreview(
          this.range.operator,
          "Value",
          this.range.params
        ),
      },
      ...(Array.isArray(super.preview) ? super.preview : [super.preview]),
    ];
  }

  static of(model: SessionNumberModel): SessionNumberModel {
    return new SessionNumberModel(
      model.id,
      model.required,
      model.filled,
      model.valid,
      model.range,
      model.period
    );
  }

  isEmpty(): boolean {
    return super.isEmpty();
  }

  toRecord(): Record<string, any> {
    const result = super.toRecord();
    result["period"] = this.period;
    return result;
  }

  clone(): SessionNumberModel {
    return SessionNumberModel.of(this);
  }
}

export class EventParamRangeModel {
  constructor(public name?: string, public value?: RangeModel) {}

  static of(model: EventParamRangeModel): EventParamRangeModel {
    return new EventParamRangeModel(model.name, model.value);
  }

  static ofRecord(filter: Record<string, any>): EventParamRangeModel {
    return new EventParamRangeModel(filter.name, filter.value);
  }

  isEmpty(): boolean {
    return !this.name;
  }

  toRecord(): Record<string, any> {
    const result: Record<string, any> = {};

    if (this.name) {
      result["name"] = this.name;
    }

    if (this.value) {
      result["value"] = this.value.toRecord();
    }

    return result;
  }
}

export class EventParamModel {
  name?: string;
  operator?: EventOperation;
  values?: Array<string>;

  constructor(
    name?: string,
    operator?: EventOperation,
    values?: Array<string>
  ) {
    this.name = name;
    this.operator = operator;
    this.values = values;
  }

  static of(model: EventParamModel): EventParamModel {
    return new EventParamModel(model.name, model.operator, model.values);
  }

  static ofRecord(filter: Record<string, any>): EventParamModel {
    return new EventParamModel(
      filter.name,
      filter.operator as EventOperation,
      filter.values
        ? Array.isArray(filter.values)
          ? (filter.values as Array<string>)
          : Object.values(filter.values as Record<string, any>)
        : undefined
    );
  }

  isEmpty(): boolean {
    return !this.name;
  }

  toRecord(): Record<string, any> {
    const result: Record<string, any> = {};
    if (this.name) {
      result["name"] = this.name;
    }

    if (this.operator) {
      result["operator"] = this.operator;
    }
    if (this.values && this.values.length !== 0) {
      result["values"] = this.values;
    }
    return result;
  }
}

export class EventModel {
  included: boolean;
  name?: string;
  timesFrom?: number;
  timesTo?: number;
  params?: Array<EventParamModel>;

  constructor(
    included = true,
    name?: string,
    timesFrom?: number,
    timesTo?: number,
    params?: Array<EventParamModel>
  ) {
    this.included = included;
    this.name = name;
    this.timesFrom = timesFrom;
    this.timesTo = timesTo;
    this.params = params;
  }

  static of(model: EventModel): EventModel {
    return new EventModel(
      typeof model.included === "string"
        ? model.included === "true"
        : model.included,
      model.name,
      model.timesFrom,
      model.timesTo,
      model.params?.map((it) => EventParamModel.of(it))
    );
  }

  static ofRecord(filter: Record<string, any>): EventModel {
    return new EventModel(
      typeof filter.included === "string"
        ? filter.included === "true"
        : filter.included,
      filter.name as string,
      filter.timesFrom
        ? Number.parseInt(filter.timesFrom as string)
        : undefined,
      filter.timesTo ? Number.parseInt(filter.timesTo as string) : undefined,
      filter.params
        ? filter.params.map((it: Record<string, any>) =>
            EventParamModel.ofRecord(it)
          )
        : undefined
    );
  }

  isEmpty(): boolean {
    return !this.name;
  }

  toRecord(): Record<string, any> {
    const result: Record<string, any> = {};
    if (!this.name) {
      return result;
    }

    result["included"] = this.included;
    result["name"] = this.name;

    if (this.timesFrom) {
      result["timesFrom"] = this.timesFrom;
    }
    if (this.timesTo) {
      result["timesTo"] = this.timesTo;
    }
    if (this.params && this.params.length !== 0) {
      result["params"] = this.params.map((it) => it.toRecord());
    }

    return result;
  }
}

export class EventRangeModel {
  constructor(
    public included = true,
    public name?: string,
    public value?: RangeModel,
    public params: Array<EventParamRangeModel> = []
  ) {}

  static of(model: EventRangeModel): EventRangeModel {
    return new EventRangeModel(
      typeof model.included === "string"
        ? model.included === "true"
        : model.included,
      model.name,
      model.value,
      model.params?.map((it) => EventParamRangeModel.of(it))
    );
  }

  static ofRecord(filter: Record<string, any> | undefined): EventRangeModel {
    return filter
      ? new EventRangeModel(
          typeof filter.included === "string"
            ? filter.included === "true"
            : filter.included,
          filter.name as string,
          filter.value ? RangeModel.of(filter.value) : undefined,
          filter.params?.map((it: Record<string, any>) =>
            EventParamRangeModel.ofRecord(it)
          )
        )
      : new EventRangeModel();
  }

  isEmpty(): boolean {
    return !this.name;
  }

  toRecord(): Record<string, any> {
    const result: Record<string, any> = {};
    if (!this.name) {
      return result;
    }

    result["included"] = this.included;
    result["name"] = this.name;

    if (this.value) {
      result["value"] = this.value.toRecord();
    }
    if (this.params && this.params.length !== 0) {
      result["params"] = this.params.map((it) => it.toRecord());
    }

    return result;
  }
}

export enum EventCombination {
  AND = "AND",
  OR = "OR",
}

export class RangeBasedMultipleEventParamFilterModel extends FilterModel {
  constructor(
    id: FilterId,
    required = false,
    filled = false,
    valid = false,
    public combination: EventCombination = EventCombination.AND,
    public values: Array<RangeBasedMultipleEventParamFilterValueModel> = [],
    public from: string | null = DateUtil.twoDaysAgo(),
    public to: string | null = DateUtil.today()
  ) {
    super(id, required, filled, valid);
  }

  get names(): Array<string> {
    return this.values.flatMap(({ name }: { name: string }) =>
      name ? [name] : []
    );
  }

  get preview(): Array<FilterPreview> {
    const previews = [];

    if (this.combination) {
      previews.push({
        id: FilterPreviewId.EVENT_COMBINATION,
        value: this.combination,
      });
    }

    if (this.from && this.to) {
      previews.push({
        id: FilterPreviewId.EVENT_RANGE,
        value: `${this.from} — ${this.to}`,
      });
    }

    if (this.values.length) {
      if (this.values.filter(({ name }) => !!name).length > 1) {
        previews.push({
          id: FilterPreviewId.EVENTS,
          value: String(this.values.length),
        });
      } else {
        const params = this.values
          .map((item) =>
            item.params.map((param: EventParamRangeModel) =>
              FilterPreview.preparingRangeDataForPreview(
                param.value?.operator ?? EventOperation.EQUAL,
                param.name ?? "",
                param.value?.params ?? []
              )
            )
          )
          .flat();

        if (this.names.length) {
          previews.push({
            id: FilterPreviewId.EVENT_NAME,
            value: this.names,
            dictionary: DictionaryType.EVENT_NAMES,
          });
        }

        if (params.length) {
          previews.push({
            id: FilterPreviewId.EVENT_PARAMS,
            value: params,
          });
        }
      }
    }

    return previews;
  }

  static of(
    model: RangeBasedMultipleEventParamFilterModel
  ): RangeBasedMultipleEventParamFilterModel {
    return new RangeBasedMultipleEventParamFilterModel(
      model.id,
      model.required,
      model.filled,
      model.valid,
      model.combination,
      model.values.map((it) =>
        RangeBasedMultipleEventParamFilterValueModel.of(it)
      ),
      model.from,
      model.to
    );
  }

  isEmpty(): boolean {
    return this.values.length === 0;
  }

  toRecord(): Record<string, any> {
    const result = super.toRecord();
    result["combination"] = this.combination;
    result["values"] = this.values.map((it) => it.toRecord());
    return result;
  }

  clone(): RangeBasedMultipleEventParamFilterModel {
    return RangeBasedMultipleEventParamFilterModel.of(this);
  }

  getUsedDictionaryValues(): Map<DictionaryType, Array<string>> {
    if (!this.names.length) {
      return super.getUsedDictionaryValues();
    }

    return new Map([[DictionaryType.EVENT_NAMES, this.names]]);
  }
}

export class RangeBasedMultipleEventParamFilterValueModel {
  constructor(
    public name = "",
    public range = new RangeModel(),
    public params: Array<EventParamRangeModel> = [],
    public included = true
  ) {}

  static of(
    model: RangeBasedMultipleEventParamFilterValueModel
  ): RangeBasedMultipleEventParamFilterValueModel {
    return new RangeBasedMultipleEventParamFilterValueModel(
      model.name,
      model.range,
      model.params,
      model.included
    );
  }

  toRecord(): Record<string, any> {
    const result: Record<string, any> = {};

    result["name"] = this.name;
    result["range"] = this.range.toRecord();

    if (this.params && this.params.length !== 0) {
      result["params"] = this.params.map((it) => it.toRecord());
    }

    result["included"] = this.included;

    return result;
  }
}

export class EventParamFilterRangeModel extends FilterModel {
  constructor(
    id: FilterId,
    required = false,
    filled = false,
    valid = false,
    public value = new EventRangeModel()
  ) {
    super(id, required, filled, valid);
  }

  get dictionaryType(): DictionaryType | undefined {
    return FILTER_ID_TO_DATA_TYPES[this.id][0];
  }

  get preview(): Array<FilterPreview> {
    const previews = [];

    if (this.value.name) {
      previews.push({
        id: FilterPreviewId.EVENT,
        value: this.value.name,
        excluded: !this.value.included,
        dictionary: this.dictionaryType,
      });
    }

    if (this.value.params?.length) {
      this.value.params.forEach((param: EventParamRangeModel) => {
        const value = [param.name];

        if (this.valid && param?.value?.params?.length) {
          value.push(param.value.operator);
          value.push(...(param.value.params as any));
        }

        previews.push({
          id: FilterPreviewId.EVENT_PARAM,
          value,
        });
      });
    }

    return previews;
  }

  static of(model: EventParamFilterRangeModel): EventParamFilterRangeModel {
    return new EventParamFilterRangeModel(
      model.id,
      model.required,
      model.filled,
      model.valid,
      EventRangeModel.of(model.value)
    );
  }

  isEmpty(): boolean {
    return this.value.isEmpty();
  }

  toRecord(): Record<string, any> {
    const result = super.toRecord();
    result["value"] = this.value.toRecord();
    return result;
  }

  clone(): EventParamFilterRangeModel {
    return EventParamFilterRangeModel.of(this);
  }

  getUsedDictionaryValues(): Map<DictionaryType, Array<string>> {
    if (!this.dictionaryType || !this.value.name) {
      return super.getUsedDictionaryValues();
    }

    return new Map([[this.dictionaryType, [this.value.name]]]);
  }
}

export class EventParamDateFilterModel extends FilterModel {
  constructor(
    id: FilterId,
    required = false,
    filled = false,
    valid = false,
    public value = new EventRangeModel(),
    public from?: string | Date
  ) {
    super(id, required, filled, valid);
  }

  get dictionaryType(): DictionaryType | undefined {
    return FILTER_ID_TO_DATA_TYPES[this.id][0];
  }

  get preview(): Array<FilterPreview> {
    const previews = [];

    if (this.from) {
      previews.push({
        id: FilterPreviewId.FROM,
        value: this.from,
      });
    }

    if (this.value.name) {
      previews.push({
        id: FilterPreviewId.EVENT_NAME,
        value: this.value.name,
        excluded: !this.value.included,
        dictionary: this.dictionaryType,
      });
    }

    if (this.value.params?.length) {
      previews.push({
        id: FilterPreviewId.EVENT_PARAMS,
        value: this.value.params.map((param: EventParamRangeModel) =>
          FilterPreview.preparingRangeDataForPreview(
            param.value?.operator ?? EventOperation.EQUAL,
            param.name ?? "",
            param.value?.params ?? []
          )
        ),
      });
    }

    return previews;
  }

  static of(model: EventParamDateFilterModel): EventParamDateFilterModel {
    return new EventParamDateFilterModel(
      model.id,
      model.required,
      model.filled,
      model.valid,
      EventRangeModel.of(model.value),
      model.from
    );
  }

  isEmpty(): boolean {
    return this.value.isEmpty();
  }

  toRecord(): Record<string, any> {
    const result = super.toRecord();

    result["value"] = this.value.toRecord();

    if (this.from) {
      result["from"] = this.from;
    }

    return result;
  }

  clone(): EventParamDateFilterModel {
    return EventParamDateFilterModel.of(this);
  }

  getUsedDictionaryValues(): Map<DictionaryType, Array<string>> {
    if (!this.dictionaryType || !this.value.name) {
      return super.getUsedDictionaryValues();
    }

    return new Map([[this.dictionaryType, [this.value.name]]]);
  }
}

export class RangeModel {
  constructor(
    public operator = EventOperation.BETWEEN,
    public params: Array<number | string> = [0, 0]
  ) {}

  static of(model: RangeModel | undefined): RangeModel {
    return model
      ? new RangeModel(model.operator, model.params)
      : new RangeModel();
  }

  get isSingleValue(): boolean {
    return (
      this.operator === EventOperation.EQUAL ||
      this.operator === EventOperation.NOT_EQUAL ||
      this.operator === EventOperation.GREATER ||
      this.operator === EventOperation.LESS ||
      this.operator === EventOperation.LIKE ||
      this.operator === EventOperation.NOT_LIKE
    );
  }

  get isTwoValue(): boolean {
    return (
      this.operator === EventOperation.BETWEEN ||
      this.operator === EventOperation.NOT_BETWEEN
    );
  }

  get isMultiValue(): boolean {
    return (
      this.operator === EventOperation.IN ||
      this.operator === EventOperation.NOT_IN
    );
  }

  get isFloat(): boolean {
    return (
      this.operator === EventOperation.GREATER ||
      this.operator === EventOperation.LESS ||
      this.operator === EventOperation.BETWEEN ||
      this.operator === EventOperation.NOT_BETWEEN
    );
  }

  toRecord(): Record<string, any> {
    const result: Record<string, any> = {};

    result["operator"] = this.operator;
    result["params"] = this.params;

    return result;
  }
}

export const FILTER_ID_TO_CHILDREN = {
  [FilterId.ATTRIBUTION_DATE_VALUE]: [],
  [FilterId.ATTRIBUTION_STATUS]: [],
  [FilterId.ATTRIBUTION_DATE]: [
    FilterId.ATTRIBUTION_DATE_VALUE,
    FilterId.ATTRIBUTION_STATUS,
  ],
  [FilterId.COUNTRY]: [],
  [FilterId.AD_REVENUE]: [],
  [FilterId.IAP_REVENUE]: [],
  [FilterId.SOURCE]: [],
  [FilterId.SUB_SOURCE]: [],
  [FilterId.CAMPAIGN]: [],
  [FilterId.AD_SET]: [],
  [FilterId.CREATIVE]: [],
  [FilterId.PUBLISHER]: [],
  [FilterId.TRACKER]: [
    FilterId.SOURCE,
    FilterId.SUB_SOURCE,
    FilterId.CAMPAIGN,
    FilterId.AD_SET,
    FilterId.CREATIVE,
    FilterId.PUBLISHER,
  ],
  [FilterId.DEVICE_TYPE]: [],
  [FilterId.DEVICE_MODEL]: [],
  [FilterId.INSTALL_APP_VERSION]: [],
  [FilterId.APP_VERSION]: [],
  [FilterId.OS_VERSION]: [],
  [FilterId.LANGUAGE]: [],
  [FilterId.SESSION_NUMBER]: [],
  [FilterId.EVENT_PARAMETER]: [],
  [FilterId.EVENT]: [FilterId.EVENT_PARAMETER],
  [FilterId.RANGE_BASED_MULTIPLE_EVENT]: [],
  [FilterId.SEGMENT]: [],
  [FilterId.EVENTS_DATE]: [],
  [FilterId.EVENTS_APP_VERSION]: [],
  [FilterId.EVENTS_OS_VERSION]: [],
  [FilterId.EVENTS_COUNTRY]: [],
  [FilterId.ACTIVITY_KIND]: [],
  [FilterId.LAT]: [],
  [FilterId.AD_NETWORK_NAME]: [],
  [FilterId.WATERFALLS_ID]: [],
  [FilterId.PLATFORM]: [],
  [FilterId.APPLICATION]: [],
  [FilterId.NET_REVENUE]: [],
  [FilterId.DATE]: [],
  [FilterId.SIMPLE_MULTIPLE_EVENT]: [],
  [FilterId.ACTUAL_EVENT_PARAMETER]: [],
  [FilterId.USER_AGE]: [],
  [FilterId.SUBSCRIPTION_RENEWAL_COUNT]: [],
};

export const SEGMENT_FILTER_ID = [
  FilterId.ATTRIBUTION_DATE,
  FilterId.COUNTRY,
  FilterId.AD_REVENUE,
  FilterId.IAP_REVENUE,
  FilterId.TRACKER,
  FilterId.DEVICE_TYPE,
  FilterId.DEVICE_MODEL,
  FilterId.INSTALL_APP_VERSION,
  FilterId.APP_VERSION,
  FilterId.OS_VERSION,
  FilterId.LANGUAGE,
  FilterId.SESSION_NUMBER,
  FilterId.RANGE_BASED_MULTIPLE_EVENT,
  FilterId.SEGMENT,
  FilterId.ACTUAL_EVENT_PARAMETER,
  FilterId.USER_AGE,
  FilterId.SUBSCRIPTION_RENEWAL_COUNT,
];

export const FUNNEL_FILTER_ID = [
  FilterId.ATTRIBUTION_DATE,
  FilterId.SEGMENT,
  FilterId.COUNTRY,
  FilterId.TRACKER,
  FilterId.DEVICE_TYPE,
  FilterId.INSTALL_APP_VERSION,
];

export const FUNNEL_BREAKDOWN_FILTER_ID = [
  FilterId.ATTRIBUTION_DATE,
  FilterId.SEGMENT,
  FilterId.COUNTRY,
  FilterId.TRACKER,
  FilterId.DEVICE_TYPE,
  FilterId.INSTALL_APP_VERSION,
];

export const FUNNEL_STEP_ADDITIONAL_FILTER_ID = [
  FilterId.EVENTS_APP_VERSION,
  FilterId.EVENTS_OS_VERSION,
  FilterId.EVENTS_COUNTRY,
];

export const REPORT_GROUP_BY_FILTER_ID = [
  FilterId.COUNTRY,
  FilterId.SOURCE,
  FilterId.SUB_SOURCE,
  FilterId.CAMPAIGN,
  FilterId.AD_SET,
  FilterId.CREATIVE,
  FilterId.PUBLISHER,
  FilterId.ATTRIBUTION_STATUS,
  FilterId.DEVICE_TYPE,
  FilterId.SEGMENT,
];

export enum FILTER_COMPONENTS {
  DATES = "dates-filter",
  MULTIPLE_VALUE = "multiple-value-filter",
  EXCLUDABLE_MULTIPLE_VALUE = "excludable-multiple-dictionary-filter",
  ATTRIBUTION_DATE = "attribution-date-filter",
  AD_REVENUE = "ad-revenue-filter",
  IAP_REVENUE = "iap-revenue-filter",
  TRACKER = "tracker-filter",
  SESSION_NUMBER = "session-number-filter",
  EVENT_PARAM = "event-param-filter",
  RANGE_BASED_MULTIPLE_EVENT_PARAM = "range-based-multiple-event-param-filter",
  BOOL = "bool-filter",
  GROUPED_FILTER = "grouped-filter",
  EVENT_PARAM_DATE = "event-param-date-filter",
  USER_AGE = "user-age-filter",
  SUBSCRIPTION_RENEWAL_COUNT = "subscription-renewals-count-filter",
}

export const FILTER_ID_TO_COMPONENT = {
  [FilterId.ATTRIBUTION_DATE_VALUE]: FILTER_COMPONENTS.DATES,
  [FilterId.ATTRIBUTION_STATUS]: FILTER_COMPONENTS.MULTIPLE_VALUE,
  [FilterId.ATTRIBUTION_DATE]: FILTER_COMPONENTS.ATTRIBUTION_DATE,
  [FilterId.COUNTRY]: FILTER_COMPONENTS.GROUPED_FILTER,
  [FilterId.AD_REVENUE]: FILTER_COMPONENTS.AD_REVENUE,
  [FilterId.IAP_REVENUE]: FILTER_COMPONENTS.IAP_REVENUE,
  [FilterId.SOURCE]: FILTER_COMPONENTS.EXCLUDABLE_MULTIPLE_VALUE,
  [FilterId.SUB_SOURCE]: FILTER_COMPONENTS.EXCLUDABLE_MULTIPLE_VALUE,
  [FilterId.CAMPAIGN]: FILTER_COMPONENTS.EXCLUDABLE_MULTIPLE_VALUE,
  [FilterId.AD_SET]: FILTER_COMPONENTS.EXCLUDABLE_MULTIPLE_VALUE,
  [FilterId.CREATIVE]: FILTER_COMPONENTS.EXCLUDABLE_MULTIPLE_VALUE,
  [FilterId.PUBLISHER]: FILTER_COMPONENTS.EXCLUDABLE_MULTIPLE_VALUE,
  [FilterId.TRACKER]: FILTER_COMPONENTS.TRACKER,
  [FilterId.DEVICE_TYPE]: FILTER_COMPONENTS.EXCLUDABLE_MULTIPLE_VALUE,
  [FilterId.DEVICE_MODEL]: FILTER_COMPONENTS.EXCLUDABLE_MULTIPLE_VALUE,
  [FilterId.INSTALL_APP_VERSION]: FILTER_COMPONENTS.GROUPED_FILTER,
  [FilterId.APP_VERSION]: FILTER_COMPONENTS.GROUPED_FILTER,
  [FilterId.OS_VERSION]: FILTER_COMPONENTS.EXCLUDABLE_MULTIPLE_VALUE,
  [FilterId.LANGUAGE]: FILTER_COMPONENTS.EXCLUDABLE_MULTIPLE_VALUE,
  [FilterId.SESSION_NUMBER]: FILTER_COMPONENTS.SESSION_NUMBER,
  [FilterId.EVENT_PARAMETER]: FILTER_COMPONENTS.EVENT_PARAM,
  [FilterId.EVENT]: FILTER_COMPONENTS.EVENT_PARAM,
  [FilterId.RANGE_BASED_MULTIPLE_EVENT]:
    FILTER_COMPONENTS.RANGE_BASED_MULTIPLE_EVENT_PARAM,
  [FilterId.SEGMENT]: FILTER_COMPONENTS.EXCLUDABLE_MULTIPLE_VALUE,
  [FilterId.EVENTS_DATE]: FILTER_COMPONENTS.DATES,
  [FilterId.EVENTS_APP_VERSION]: FILTER_COMPONENTS.GROUPED_FILTER,
  [FilterId.EVENTS_OS_VERSION]: FILTER_COMPONENTS.EXCLUDABLE_MULTIPLE_VALUE,
  [FilterId.EVENTS_COUNTRY]: FILTER_COMPONENTS.GROUPED_FILTER,
  [FilterId.ACTIVITY_KIND]: FILTER_COMPONENTS.MULTIPLE_VALUE,
  [FilterId.AD_NETWORK_NAME]: FILTER_COMPONENTS.EXCLUDABLE_MULTIPLE_VALUE,
  [FilterId.WATERFALLS_ID]: FILTER_COMPONENTS.EXCLUDABLE_MULTIPLE_VALUE,
  [FilterId.LAT]: FILTER_COMPONENTS.BOOL,
  [FilterId.PLATFORM]: FILTER_COMPONENTS.MULTIPLE_VALUE,
  [FilterId.APPLICATION]: FILTER_COMPONENTS.MULTIPLE_VALUE,
  [FilterId.NET_REVENUE]: FILTER_COMPONENTS.BOOL,
  [FilterId.DATE]: FILTER_COMPONENTS.DATES,
  [FilterId.SIMPLE_MULTIPLE_EVENT]: FILTER_COMPONENTS.MULTIPLE_VALUE,
  [FilterId.ACTUAL_EVENT_PARAMETER]: FILTER_COMPONENTS.EVENT_PARAM_DATE,
  [FilterId.USER_AGE]: FILTER_COMPONENTS.USER_AGE,
  [FilterId.SUBSCRIPTION_RENEWAL_COUNT]:
    FILTER_COMPONENTS.SUBSCRIPTION_RENEWAL_COUNT,
};

export const getFilterIdToInitFunction = (
  filterId: FilterId,
  defaultValues: Array<string>
): FilterModel => {
  return {
    ATTRIBUTION_DATE_VALUE: () =>
      new DatesFilterModel(FilterId.ATTRIBUTION_DATE_VALUE),
    DATE: () =>
      new DatesFilterModel(FilterId.DATE, false, false, false, "", ""),
    ATTRIBUTION_STATUS: () =>
      new MultipleValueFilterModel(FilterId.ATTRIBUTION_STATUS),
    ATTRIBUTION_DATE: () =>
      new AttributionDateFilterModel(FilterId.ATTRIBUTION_DATE),
    COUNTRY: () =>
      new ExcludableMultipleValueFilterModel(FilterId.COUNTRY, defaultValues),
    AD_REVENUE: () => new AdRevenueFilterModel(FilterId.AD_REVENUE),
    IAP_REVENUE: () => new IapRevenueFilterModel(FilterId.IAP_REVENUE),
    SOURCE: () => new ExcludableMultipleValueFilterModel(FilterId.SOURCE),
    SUB_SOURCE: () =>
      new ExcludableMultipleValueFilterModel(FilterId.SUB_SOURCE),
    CAMPAIGN: () => new ExcludableMultipleValueFilterModel(FilterId.CAMPAIGN),
    AD_SET: () => new ExcludableMultipleValueFilterModel(FilterId.AD_SET),
    CREATIVE: () => new ExcludableMultipleValueFilterModel(FilterId.CREATIVE),
    PUBLISHER: () => new ExcludableMultipleValueFilterModel(FilterId.PUBLISHER),
    TRACKER: () => new TrackerFilterModel(FilterId.TRACKER),
    DEVICE_TYPE: () =>
      new ExcludableMultipleValueFilterModel(FilterId.DEVICE_TYPE),
    DEVICE_MODEL: () =>
      new ExcludableMultipleValueFilterModel(FilterId.DEVICE_MODEL),
    INSTALL_APP_VERSION: () =>
      new ExcludableMultipleValueFilterModel(FilterId.INSTALL_APP_VERSION),
    APP_VERSION: () =>
      new ExcludableMultipleValueFilterModel(FilterId.APP_VERSION),
    OS_VERSION: () =>
      new ExcludableMultipleValueFilterModel(FilterId.OS_VERSION),
    LANGUAGE: () => new ExcludableMultipleValueFilterModel(FilterId.LANGUAGE),
    SESSION_NUMBER: () => new SessionNumberModel(FilterId.SESSION_NUMBER),
    EVENT_PARAMETER: () =>
      new EventParamFilterRangeModel(FilterId.EVENT_PARAMETER),
    EVENT: () => new EventParamFilterRangeModel(FilterId.EVENT),
    RANGE_BASED_MULTIPLE_EVENT: () =>
      new RangeBasedMultipleEventParamFilterModel(
        FilterId.RANGE_BASED_MULTIPLE_EVENT
      ),
    SEGMENT: () => new ExcludableMultipleValueFilterModel(FilterId.SEGMENT),
    EVENTS_DATE: () => new DatesFilterModel(FilterId.EVENTS_DATE),
    EVENTS_APP_VERSION: () =>
      new ExcludableMultipleValueFilterModel(FilterId.EVENTS_APP_VERSION),
    EVENTS_OS_VERSION: () =>
      new ExcludableMultipleValueFilterModel(FilterId.EVENTS_OS_VERSION),
    EVENTS_COUNTRY: () =>
      new ExcludableMultipleValueFilterModel(
        FilterId.EVENTS_COUNTRY,
        defaultValues
      ),
    ACTIVITY_KIND: () => new MultipleValueFilterModel(FilterId.ACTIVITY_KIND),
    LAT: () => new BoolFilterModel(FilterId.LAT),
    AD_NETWORK_NAME: () =>
      new ExcludableMultipleValueFilterModel(FilterId.AD_NETWORK_NAME),
    WATERFALLS_ID: () =>
      new ExcludableMultipleValueFilterModel(FilterId.WATERFALLS_ID),
    PLATFORM: () =>
      new ExcludableMultipleValueFilterModel(FilterId.AD_NETWORK_NAME),
    APPLICATION: () =>
      new ExcludableMultipleValueFilterModel(FilterId.WATERFALLS_ID),
    NET_REVENUE: () => new BoolFilterModel(FilterId.NET_REVENUE),
    SIMPLE_MULTIPLE_EVENT: () =>
      new MultipleValueFilterModel(FilterId.SIMPLE_MULTIPLE_EVENT),
    ACTUAL_EVENT_PARAMETER: () =>
      new EventParamDateFilterModel(FilterId.ACTUAL_EVENT_PARAMETER),
    USER_AGE: () => new NumbersFilterModel(FilterId.USER_AGE),
    SUBSCRIPTION_RENEWAL_COUNT: () =>
      new NumbersFilterModel(FilterId.SUBSCRIPTION_RENEWAL_COUNT),
  }[filterId]();
};

export const FILTER_ID_TO_INIT_FUNCTION = {
  [FilterId.ATTRIBUTION_DATE_VALUE]: () =>
    new DatesFilterModel(FilterId.ATTRIBUTION_DATE_VALUE),
  [FilterId.DATE]: () =>
    new DatesFilterModel(FilterId.DATE, false, false, false, "", ""),
  [FilterId.ATTRIBUTION_STATUS]: () =>
    new MultipleValueFilterModel(FilterId.ATTRIBUTION_STATUS),
  [FilterId.ATTRIBUTION_DATE]: () =>
    new AttributionDateFilterModel(FilterId.ATTRIBUTION_DATE),
  [FilterId.COUNTRY]: () =>
    new ExcludableMultipleValueFilterModel(FilterId.COUNTRY),
  [FilterId.AD_REVENUE]: () => new AdRevenueFilterModel(FilterId.AD_REVENUE),
  [FilterId.IAP_REVENUE]: () => new IapRevenueFilterModel(FilterId.IAP_REVENUE),
  [FilterId.SOURCE]: () =>
    new ExcludableMultipleValueFilterModel(FilterId.SOURCE),
  [FilterId.SUB_SOURCE]: () =>
    new ExcludableMultipleValueFilterModel(FilterId.SUB_SOURCE),
  [FilterId.CAMPAIGN]: () =>
    new ExcludableMultipleValueFilterModel(FilterId.CAMPAIGN),
  [FilterId.AD_SET]: () =>
    new ExcludableMultipleValueFilterModel(FilterId.AD_SET),
  [FilterId.CREATIVE]: () =>
    new ExcludableMultipleValueFilterModel(FilterId.CREATIVE),
  [FilterId.PUBLISHER]: () =>
    new ExcludableMultipleValueFilterModel(FilterId.PUBLISHER),
  [FilterId.TRACKER]: () => new TrackerFilterModel(FilterId.TRACKER),
  [FilterId.DEVICE_TYPE]: () =>
    new ExcludableMultipleValueFilterModel(FilterId.DEVICE_TYPE),
  [FilterId.DEVICE_MODEL]: () =>
    new ExcludableMultipleValueFilterModel(FilterId.DEVICE_MODEL),
  [FilterId.INSTALL_APP_VERSION]: () =>
    new ExcludableMultipleValueFilterModel(FilterId.INSTALL_APP_VERSION),
  [FilterId.APP_VERSION]: () =>
    new ExcludableMultipleValueFilterModel(FilterId.APP_VERSION),
  [FilterId.OS_VERSION]: () =>
    new ExcludableMultipleValueFilterModel(FilterId.OS_VERSION),
  [FilterId.LANGUAGE]: () =>
    new ExcludableMultipleValueFilterModel(FilterId.LANGUAGE),
  [FilterId.SESSION_NUMBER]: () =>
    new SessionNumberModel(FilterId.SESSION_NUMBER),
  [FilterId.EVENT_PARAMETER]: () =>
    new EventParamFilterRangeModel(FilterId.EVENT_PARAMETER),
  [FilterId.EVENT]: () => new EventParamFilterRangeModel(FilterId.EVENT),
  [FilterId.RANGE_BASED_MULTIPLE_EVENT]: () =>
    new RangeBasedMultipleEventParamFilterModel(
      FilterId.RANGE_BASED_MULTIPLE_EVENT
    ),
  [FilterId.SEGMENT]: () =>
    new ExcludableMultipleValueFilterModel(FilterId.SEGMENT),
  [FilterId.EVENTS_DATE]: () => new DatesFilterModel(FilterId.EVENTS_DATE),
  [FilterId.EVENTS_APP_VERSION]: () =>
    new ExcludableMultipleValueFilterModel(FilterId.EVENTS_APP_VERSION),
  [FilterId.EVENTS_OS_VERSION]: () =>
    new ExcludableMultipleValueFilterModel(FilterId.EVENTS_OS_VERSION),
  [FilterId.EVENTS_COUNTRY]: () =>
    new ExcludableMultipleValueFilterModel(FilterId.EVENTS_COUNTRY),
  [FilterId.ACTIVITY_KIND]: () =>
    new MultipleValueFilterModel(FilterId.ACTIVITY_KIND),
  [FilterId.LAT]: () => new BoolFilterModel(FilterId.LAT),
  [FilterId.AD_NETWORK_NAME]: () =>
    new ExcludableMultipleValueFilterModel(FilterId.AD_NETWORK_NAME),
  [FilterId.WATERFALLS_ID]: () =>
    new ExcludableMultipleValueFilterModel(FilterId.WATERFALLS_ID),
  [FilterId.PLATFORM]: () =>
    new ExcludableMultipleValueFilterModel(FilterId.AD_NETWORK_NAME),
  [FilterId.APPLICATION]: () =>
    new ExcludableMultipleValueFilterModel(FilterId.WATERFALLS_ID),
  [FilterId.NET_REVENUE]: () => new BoolFilterModel(FilterId.NET_REVENUE),
  [FilterId.SIMPLE_MULTIPLE_EVENT]: () =>
    new MultipleValueFilterModel(FilterId.SIMPLE_MULTIPLE_EVENT),
  [FilterId.ACTUAL_EVENT_PARAMETER]: () =>
    new EventParamDateFilterModel(FilterId.ACTUAL_EVENT_PARAMETER),
  [FilterId.USER_AGE]: () => new NumbersFilterModel(FilterId.USER_AGE),
  [FilterId.SUBSCRIPTION_RENEWAL_COUNT]: () =>
    new NumbersFilterModel(FilterId.SUBSCRIPTION_RENEWAL_COUNT),
};

export const singleValueToFilterModel = (
  filterId: FilterId,
  value: string
): FilterModel => {
  const component = FILTER_ID_TO_COMPONENT[filterId];
  if (component === FILTER_COMPONENTS.DATES) {
    return new DatesFilterModel(filterId, false, false, true, value, value);
  } else if (component === FILTER_COMPONENTS.MULTIPLE_VALUE) {
    return new MultipleValueFilterModel(filterId, false, false, true, [value]);
  } else if (component === FILTER_COMPONENTS.EXCLUDABLE_MULTIPLE_VALUE) {
    return new ExcludableMultipleValueFilterModel(
      filterId,
      [],
      false,
      false,
      true,
      true,
      [value]
    );
  } else if (component === FILTER_COMPONENTS.BOOL) {
    return new BoolFilterModel(filterId, false, false, true, value === "true");
  } else if (component === FILTER_COMPONENTS.ATTRIBUTION_DATE) {
    return new AttributionDateFilterModel(filterId, false, false, true, [
      value,
    ]);
  } else if (component === FILTER_COMPONENTS.TRACKER) {
    return new TrackerFilterModel(
      filterId,
      false,
      false,
      true,
      new TrackerFilterPartModel(true, [value])
    );
  } else if (component === FILTER_COMPONENTS.EVENT_PARAM) {
    return new EventParamFilterRangeModel(
      filterId,
      false,
      false,
      true,
      new EventRangeModel(true, value)
    );
  } else {
    return new FilterModel(filterId, true);
  }
};

export const recordToFilterModel = (
  filter: Record<string, any>
): FilterModel | undefined => {
  const filterId = filter.id as FilterId;
  const component = FILTER_ID_TO_COMPONENT[filterId];

  if (component === FILTER_COMPONENTS.DATES) {
    return DatesFilterModel.ofRecord(filterId, filter, false);
  } else if (component === FILTER_COMPONENTS.MULTIPLE_VALUE) {
    return new MultipleValueFilterModel(
      filterId,
      false,
      false,
      true,
      filter.values as Array<string>
    );
  } else if (component === FILTER_COMPONENTS.EXCLUDABLE_MULTIPLE_VALUE) {
    return new ExcludableMultipleValueFilterModel(
      filterId,
      filter.defaultValues,
      false,
      false,
      true,
      typeof filter.included === "string"
        ? filter.included === "true"
        : filter.included,
      filter.values.map((value: string | number) => value.toString())
    );
  } else if (component === FILTER_COMPONENTS.GROUPED_FILTER) {
    return new ExcludableMultipleValueFilterModel(
      filterId,
      filter.defaultValues,
      false,
      false,
      true,
      typeof filter.included === "string"
        ? filter.included === "true"
        : filter.included,
      filter.values as Array<string>
    );
  } else if (component === FILTER_COMPONENTS.BOOL) {
    return new BoolFilterModel(
      filterId,
      false,
      false,
      true,
      typeof filter.value === "string" ? filter.value === "true" : filter.value
    );
  } else if (component === FILTER_COMPONENTS.ATTRIBUTION_DATE) {
    return new AttributionDateFilterModel(
      filterId,
      !!filter.required,
      false,
      true,
      filter.attributionStatuses as Array<string>,
      filter.from as string,
      filter.to as string
    );
  } else if (component === FILTER_COMPONENTS.TRACKER) {
    return new TrackerFilterModel(
      filterId,
      false,
      false,
      true,
      filter.source
        ? TrackerFilterPartModel.ofRecord(filter.source)
        : undefined,
      filter.subSource
        ? TrackerFilterPartModel.ofRecord(filter.subSource)
        : undefined,
      filter.campaign
        ? TrackerFilterPartModel.ofRecord(filter.campaign)
        : undefined,
      filter.adSet ? TrackerFilterPartModel.ofRecord(filter.adSet) : undefined,
      filter.creative
        ? TrackerFilterPartModel.ofRecord(filter.creative)
        : undefined,
      filter.publisher
        ? TrackerFilterPartModel.ofRecord(filter.publisher)
        : undefined
    );
  } else if (component === FILTER_COMPONENTS.EVENT_PARAM) {
    return new EventParamFilterRangeModel(
      filterId,
      false,
      false,
      true,
      EventRangeModel.ofRecord(filter.value)
    );
  } else if (component === FILTER_COMPONENTS.AD_REVENUE) {
    return new AdRevenueFilterModel(
      filterId,
      false,
      false,
      true,
      filter.range,
      filter.revenueMethod
    );
  } else if (component === FILTER_COMPONENTS.IAP_REVENUE) {
    return new IapRevenueFilterModel(
      filterId,
      false,
      false,
      true,
      filter.range,
      filter.revenue
    );
  } else if (component === FILTER_COMPONENTS.SESSION_NUMBER) {
    return new SessionNumberModel(
      filterId,
      false,
      false,
      true,
      filter.range,
      filter.period
    );
  } else if (component === FILTER_COMPONENTS.RANGE_BASED_MULTIPLE_EVENT_PARAM) {
    return new RangeBasedMultipleEventParamFilterModel(
      filterId,
      false,
      false,
      true,
      filter.combination,
      filter.values.map((it: any) =>
        RangeBasedMultipleEventParamFilterValueModel.of(it)
      ),
      filter.from,
      filter.to
    );
  } else if (component === FILTER_COMPONENTS.EVENT_PARAM_DATE) {
    return new EventParamDateFilterModel(
      filterId,
      false,
      false,
      true,
      EventRangeModel.of(filter.value),
      filter.from
    );
  } else if (
    component === FILTER_COMPONENTS.USER_AGE ||
    component === FILTER_COMPONENTS.SUBSCRIPTION_RENEWAL_COUNT
  ) {
    return new NumbersFilterModel(filterId, false, false, true, filter.range);
  }

  return undefined;
};

export const FILTER_BACKGROUND = new Map<FilterId, string>([
  [FilterId.SEGMENT, "red"],
  [FilterId.COUNTRY, "light-blue"],
  [FilterId.TRACKER, "light-green"],
  [FilterId.SOURCE, "light-green"],
  [FilterId.DATE, "yellow"],
]);

export const FILTER_PREVIEW_SORT_ORDER = [
  FilterPreviewId.EVENTS_DATE,
  FilterPreviewId.ATTRIBUTION_DATE_VALUE,
  FilterPreviewId.DAY_LIMIT,
  FilterPreviewId.TARGET_DAY_MODE,
  FilterPreviewId.TARGET_DAY,
  FilterPreviewId.START_DAY,
  FilterPreviewId.END_DAY,
  FilterPreviewId.GOAL,
  FilterPreviewId.EVENTS_DAY_LIMIT,
  FilterPreviewId.SEGMENT,
  FilterPreviewId.PLATFORMS,
  FilterPreviewId.APPLICATIONS,
  FilterPreviewId.COUNTRY,
  FilterPreviewId.SOURCE,
  FilterPreviewId.SUB_SOURCE,
  FilterPreviewId.CAMPAIGN,
  FilterPreviewId.AD_SET,
  FilterPreviewId.CREATIVE,
  FilterPreviewId.PUBLISHER,
  FilterPreviewId.ATTRIBUTION_DATE,
  FilterPreviewId.ATTRIBUTION_STATUS,
  FilterPreviewId.EVENTS_COUNTRY,
  FilterPreviewId.DEVICE_TYPE,
  FilterPreviewId.EVENTS_OS_VERSION,
  FilterPreviewId.INSTALL_APP_VERSION,
  FilterPreviewId.EVENTS_APP_VERSION,
  FilterPreviewId.WATERFALLS_ID,
  FilterPreviewId.LAT,
  FilterPreviewId.REVENUE,
  FilterPreviewId.AD_REVENUE,
  FilterPreviewId.IAP_REVENUE,
  FilterPreviewId.AD_NETWORK_NAME,
  FilterPreviewId.PROVIDED_BANNER,
  FilterPreviewId.AD_REVENUE_METHOD,
  FilterPreviewId.EVENT_SIMPLE,
  FilterPreviewId.EVENT_COST,
  FilterPreviewId.USER_ACTIVITY,
  FilterPreviewId.GENERATED_EVENT,
  FilterPreviewId.GENERATED_EVENT_PARAM,
  FilterPreviewId.GENERATED_EVENT_PARAM_DATE,
  FilterPreviewId.AD_TYPE,
  FilterPreviewId.REPORT_TYPE_TIME_SPENT,
  FilterPreviewId.TIME_SPENT_TYPE,
  FilterPreviewId.REPORT_TYPE_MEASURES_LITE,
  FilterPreviewId.REPORT_TYPE_COHORT_PERMIN,
  FilterPreviewId.REPORT_TYPE_PERMIN,
  FilterPreviewId.CONVERSION_VALUE,
  FilterPreviewId.SKAD_REPORT_TYPE,
  FilterPreviewId.INCLUDE_IAPS,
  FilterPreviewId.GROUP_BY,
  FilterPreviewId.AGGREGATION_PERIOD,
  FilterPreviewId.EVENT,
  FilterPreviewId.EVENT_PARAM,
  FilterPreviewId.CUMULATIVE,
  FilterPreviewId.NET_REVENUE,
  FilterPreviewId.ADDITIONAL_GROUPING,
  FilterPreviewId.ROUND_SESSIONS_TO_SECONDS,
  FilterPreviewId.EXCLUDE_NO_INTERNET,
];

export enum FilterColor {
  YELLOW = "yellow",
  RED = "red",
  LIGHT_BLUE = "light-blue",
  LIGHT_GREEN = "light-green",
  LIME = "lime",
  ORANGE = "orange",
  BROWN = "brown",
  PINK = "pink",
  GREY = "grey",
}

export const PREVIEW_COLOR = new Map<FilterPreviewId, FilterColor>([
  [FilterPreviewId.EVENTS_DATE, FilterColor.YELLOW],
  [FilterPreviewId.ATTRIBUTION_DATE_VALUE, FilterColor.YELLOW],
  [FilterPreviewId.DAY_LIMIT, FilterColor.YELLOW],
  [FilterPreviewId.TARGET_DAY_MODE, FilterColor.YELLOW],
  [FilterPreviewId.TARGET_DAY, FilterColor.YELLOW],
  [FilterPreviewId.START_DAY, FilterColor.YELLOW],
  [FilterPreviewId.END_DAY, FilterColor.YELLOW],
  [FilterPreviewId.GOAL, FilterColor.YELLOW],
  [FilterPreviewId.FROM, FilterColor.YELLOW],
  [FilterPreviewId.SEGMENT, FilterColor.RED],
  [FilterPreviewId.COUNTRY, FilterColor.LIGHT_BLUE],
  [FilterPreviewId.SOURCE, FilterColor.LIGHT_GREEN],
  [FilterPreviewId.SUB_SOURCE, FilterColor.LIGHT_GREEN],
  [FilterPreviewId.CAMPAIGN, FilterColor.LIGHT_GREEN],
  [FilterPreviewId.AD_SET, FilterColor.LIGHT_GREEN],
  [FilterPreviewId.CREATIVE, FilterColor.LIGHT_GREEN],
  [FilterPreviewId.PUBLISHER, FilterColor.LIGHT_GREEN],
  [FilterPreviewId.GENERATED_EVENT, FilterColor.LIME],
  [FilterPreviewId.GENERATED_EVENT_PARAM, FilterColor.LIME],
  [FilterPreviewId.GENERATED_EVENT_PARAM_DATE, FilterColor.LIME],
  [FilterPreviewId.EVENT, FilterColor.ORANGE],
  [FilterPreviewId.EVENT_PARAM, FilterColor.ORANGE],
  [FilterPreviewId.EVENT_NAME, FilterColor.ORANGE],
  [FilterPreviewId.EVENT_PARAMS, FilterColor.ORANGE],
  [FilterPreviewId.REVENUE, FilterColor.ORANGE],
  [FilterPreviewId.EVENTS, FilterColor.ORANGE],
  [FilterPreviewId.SESSIONS_COUNT, FilterColor.ORANGE],
  [FilterPreviewId.GROUP_BY, FilterColor.BROWN],
  [FilterPreviewId.AGGREGATION_PERIOD, FilterColor.BROWN],
  [FilterPreviewId.PLATFORMS, FilterColor.PINK],
  [FilterPreviewId.APPLICATIONS, FilterColor.PINK],
]);

export const REPORT_FILTERS: Record<ReportType, Array<FilterId>> = {
  ARPDAU: [
    FilterId.SEGMENT,
    FilterId.COUNTRY,
    FilterId.DEVICE_TYPE,
    FilterId.LAT,
    FilterId.AD_NETWORK_NAME,
  ],
  ARPDAU_SIMPLE: [
    FilterId.COUNTRY,
    FilterId.DEVICE_TYPE,
    FilterId.AD_NETWORK_NAME,
  ],
  ARPU: [
    FilterId.SEGMENT,
    FilterId.COUNTRY,
    FilterId.TRACKER,
    FilterId.ATTRIBUTION_STATUS,
    FilterId.DEVICE_TYPE,
    FilterId.LAT,
    FilterId.AD_NETWORK_NAME,
  ],
  RETURN_RATE: [
    FilterId.SEGMENT,
    FilterId.COUNTRY,
    FilterId.TRACKER,
    FilterId.ATTRIBUTION_STATUS,
    FilterId.INSTALL_APP_VERSION,
    FilterId.DEVICE_TYPE,
    FilterId.LAT,
  ],
  MEASURES_LITE: [
    FilterId.SEGMENT,
    FilterId.COUNTRY,
    FilterId.TRACKER,
    FilterId.ATTRIBUTION_STATUS,
    FilterId.INSTALL_APP_VERSION,
    FilterId.EVENTS_COUNTRY,
    FilterId.DEVICE_TYPE,
    FilterId.LAT,
    FilterId.EVENT,
  ],
  TIME_SPENT: [
    FilterId.SEGMENT,
    FilterId.COUNTRY,
    FilterId.TRACKER,
    FilterId.ATTRIBUTION_STATUS,
    FilterId.INSTALL_APP_VERSION,
    FilterId.DEVICE_TYPE,
    FilterId.LAT,
  ],
  COHORT_PER_MIN: [
    FilterId.SEGMENT,
    FilterId.COUNTRY,
    FilterId.TRACKER,
    FilterId.ATTRIBUTION_STATUS,
    FilterId.INSTALL_APP_VERSION,
    FilterId.DEVICE_TYPE,
    FilterId.LAT,
  ],
  CALENDAR_PER_MIN: [
    FilterId.SEGMENT,
    FilterId.TRACKER,
    FilterId.ATTRIBUTION_STATUS,
    FilterId.EVENTS_APP_VERSION,
    FilterId.EVENTS_COUNTRY,
    FilterId.DEVICE_TYPE,
    FilterId.EVENTS_OS_VERSION,
    FilterId.LAT,
  ],
  EVENTS_SUMMARY: [
    FilterId.SEGMENT,
    FilterId.COUNTRY,
    FilterId.TRACKER,
    FilterId.ATTRIBUTION_STATUS,
    FilterId.EVENTS_APP_VERSION,
    FilterId.EVENTS_COUNTRY,
    FilterId.DEVICE_TYPE,
    FilterId.EVENT,
  ],
  REVENUE: [
    FilterId.EVENTS_COUNTRY,
    FilterId.DEVICE_TYPE,
    FilterId.LAT,
    FilterId.WATERFALLS_ID,
    FilterId.AD_NETWORK_NAME,
  ],
  SPEND: [FilterId.COUNTRY, FilterId.TRACKER],
  SPEND_HYPER: [FilterId.COUNTRY],
  SPEND_MINI: [FilterId.COUNTRY, FilterId.SOURCE],
  COHORT_ANALYSIS: [
    FilterId.SEGMENT,
    FilterId.COUNTRY,
    FilterId.TRACKER,
    FilterId.ATTRIBUTION_STATUS,
    FilterId.INSTALL_APP_VERSION,
  ],
  PROFIT: [FilterId.COUNTRY],
  CALENDAR_CPM: [FilterId.COUNTRY, FilterId.AD_NETWORK_NAME],
  METRICS: [
    FilterId.SEGMENT,
    FilterId.COUNTRY,
    FilterId.EVENT,
    FilterId.TRACKER,
    FilterId.ATTRIBUTION_STATUS,
    FilterId.DEVICE_TYPE,
    FilterId.LAT,
  ],
  WATERFALL: [
    FilterId.EVENTS_COUNTRY,
    FilterId.DEVICE_TYPE,
    FilterId.LAT,
    FilterId.WATERFALLS_ID,
    FilterId.AD_NETWORK_NAME,
  ],
  EVENTS_COST: [
    FilterId.COUNTRY,
    FilterId.TRACKER,
    FilterId.SIMPLE_MULTIPLE_EVENT,
  ],
  CALENDAR_CTR: [
    FilterId.EVENTS_COUNTRY,
    FilterId.TRACKER,
    FilterId.SEGMENT,
    FilterId.ATTRIBUTION_STATUS,
    FilterId.DEVICE_TYPE,
    FilterId.LAT,
    FilterId.AD_NETWORK_NAME,
  ],
  CASH_COUNTRY: [
    FilterId.COUNTRY,
    FilterId.SEGMENT,
    FilterId.EVENT,
    FilterId.TRACKER,
    FilterId.ATTRIBUTION_STATUS,
    FilterId.DEVICE_TYPE,
  ],
  DIFF_INSTALLS_STORES: [FilterId.COUNTRY, FilterId.DEVICE_TYPE],
  COHORT_CTR: [
    FilterId.COUNTRY,
    FilterId.SEGMENT,
    FilterId.TRACKER,
    FilterId.ATTRIBUTION_STATUS,
    FilterId.DEVICE_TYPE,
    FilterId.AD_NETWORK_NAME,
  ],
  CASH_GAMING: [FilterId.COUNTRY, FilterId.TRACKER],
  AD_ROAS_COUNTRY: [FilterId.COUNTRY],
  AD_ROAS_NETWORK: [FilterId.COUNTRY, FilterId.TRACKER],
  COHORT_CPM: [
    FilterId.COUNTRY,
    FilterId.SEGMENT,
    FilterId.TRACKER,
    FilterId.ATTRIBUTION_STATUS,
    FilterId.DEVICE_TYPE,
    FilterId.LAT,
    FilterId.AD_NETWORK_NAME,
  ],
  SKAD: [FilterId.COUNTRY, FilterId.TRACKER, FilterId.EVENT],
  METRICS_SPEND: [
    FilterId.COUNTRY,
    FilterId.TRACKER,
    FilterId.ATTRIBUTION_STATUS,
    FilterId.DEVICE_TYPE,
  ],
  FILL_RATE: [
    FilterId.DEVICE_TYPE,
    FilterId.EVENTS_COUNTRY,
    FilterId.LAT,
    FilterId.EVENTS_APP_VERSION,
    FilterId.EVENTS_OS_VERSION,
  ],
  PAYING_USERS_CONVERSION: [FilterId.COUNTRY, FilterId.TRACKER],
  PRICED_REVENUE: [
    FilterId.EVENTS_COUNTRY,
    FilterId.DEVICE_TYPE,
    FilterId.LAT,
    FilterId.AD_NETWORK_NAME,
    FilterId.SEGMENT,
    FilterId.TRACKER,
  ],
  MONETIZATION_MONITORING: [FilterId.COUNTRY, FilterId.AD_NETWORK_NAME],
  FIREBASE_SHOW_TO_IMPRESSION: [
    FilterId.EVENTS_COUNTRY,
    FilterId.AD_NETWORK_NAME,
    FilterId.DEVICE_TYPE,
    FilterId.LAT,
  ],
  FIREBASE_VS_NETWORKS: [
    FilterId.EVENTS_COUNTRY,
    FilterId.DEVICE_TYPE,
    FilterId.LAT,
    FilterId.AD_NETWORK_NAME,
  ],
  FIREBASE_FILL_RATE: [
    FilterId.EVENTS_COUNTRY,
    FilterId.DEVICE_TYPE,
    FilterId.LAT,
    FilterId.EVENTS_APP_VERSION,
    FilterId.EVENTS_OS_VERSION,
  ],
  TRAFFIC_QUALITY: [
    FilterId.COUNTRY,
    FilterId.TRACKER,
    FilterId.SEGMENT,
    FilterId.ATTRIBUTION_STATUS,
    FilterId.DEVICE_TYPE,
    FilterId.LAT,
  ],
  UA_MAIN_METRICS_OVERVIEW: [FilterId.COUNTRY, FilterId.TRACKER],
  SUBSCRIPTIONS_OVERVIEW: [
    FilterId.SEGMENT,
    FilterId.COUNTRY,
    FilterId.TRACKER,
    FilterId.ATTRIBUTION_STATUS,
    FilterId.INSTALL_APP_VERSION,
  ],
  METRICS_CONSTRUCTOR: [FilterId.COUNTRY, FilterId.TRACKER],
  SUBSCRIPTIONS_OVERVIEW_IOS: [
    FilterId.SEGMENT,
    FilterId.COUNTRY,
    FilterId.TRACKER,
    FilterId.ATTRIBUTION_STATUS,
    FilterId.INSTALL_APP_VERSION,
  ],
  EVENTS_SUMMARY_LITE: [
    FilterId.EVENTS_COUNTRY,
    FilterId.EVENTS_APP_VERSION,
    FilterId.EVENT,
  ],
};
