export class ReportItemRowObject {
  constructor(
    public row: Array<any> = [],
    public headers: Record<string, number>
  ) {}

  getByHeader(header: string) {
    return this.row[this.headers[header]];
  }
}
