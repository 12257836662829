export default class NetworkIntegrationApplicationResponseModel {
  constructor(
    public id: number,
    public networkName: string,
    public aliasName: string | null
  ) {}

  static ofArray(
    networkIntegrationApplications: Array<NetworkIntegrationApplicationResponseModel>
  ) {
    return networkIntegrationApplications.map(
      (networkIntegrationApplication) =>
        new NetworkIntegrationApplicationResponseModel(
          networkIntegrationApplication.id,
          networkIntegrationApplication.networkName,
          networkIntegrationApplication.aliasName
        )
    );
  }
}
