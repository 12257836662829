export default class AccountGroupStatisticModel {
  constructor(
    public id: number,
    public accountGroupId: number,
    public executionStatus: StatisticExecutionStatus,
    public executionEndDate: Date | string,
    public statusCode: StatisticStatusCode,
    public message: string
  ) {}

  static of(model: AccountGroupStatisticModel): AccountGroupStatisticModel {
    return new AccountGroupStatisticModel(
      model.id,
      model.accountGroupId,
      model.executionStatus,
      model.executionEndDate,
      model.statusCode,
      model.message
    );
  }

  static ofArray(
    items: Array<AccountGroupStatisticModel>
  ): Array<AccountGroupStatisticModel> {
    return items.map((item) => AccountGroupStatisticModel.of(item));
  }
}

export enum StatisticExecutionStatus {
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
}

export enum StatisticStatusCode {
  UNDEFINED = "UNDEFINED",
  UNAUTHORIZED = "UNAUTHORIZED",
  BAD_REQUEST = "BAD_REQUEST",
}
