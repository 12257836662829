

































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import DatesFilter from "@/shared/components/filters/DatesFilter.vue";
import FilterListItem from "@/reports/components/FilterListItem.vue";
import ExcludableMultipleDictionaryFilter from "@/shared/components/filters/ExcludableMultipleDictionaryFilter.vue";
import GroupedFilter from "@/shared/components/filters/GroupedFilter.vue";
import {
  FilterId,
  FilterPreviewId,
  FILTER_BACKGROUND,
  FILTER_ID_TO_DATA_TYPES,
  FILTER_ID_TO_INIT_FUNCTION,
  COMMON_FILTER_ID_TO_ITEM_FILTER,
} from "@/shared/models";
import DashboardFilterModel from "../models/DashboardFilterModel";

@Component({
  components: {
    FilterListItem,
    ExcludableMultipleDictionaryFilter,
    GroupedFilter,
    DashboardFilter,
    DatesFilter,
  },
})
export default class DashboardFilter extends Vue {
  @Prop() applicationId!: string;
  @Prop({ default: () => [] }) value!: DashboardFilterModel;

  readonly FilterPreviewId = FilterPreviewId;
  readonly filterIdToDataTypes = FILTER_ID_TO_DATA_TYPES;
  readonly filterIdToItemFilter = COMMON_FILTER_ID_TO_ITEM_FILTER;
  dateFilter = FILTER_ID_TO_INIT_FUNCTION[FilterId.DATE]();
  segmentFilter = FILTER_ID_TO_INIT_FUNCTION[FilterId.SEGMENT]();
  countryFilter = FILTER_ID_TO_INIT_FUNCTION[FilterId.COUNTRY]();

  getColor(filterId: FilterId) {
    return FILTER_BACKGROUND.get(filterId) ?? "";
  }

  @Watch("dateFilter", { deep: true })
  @Watch("segmentFilter", { deep: true })
  @Watch("countryFilter", { deep: true })
  watchFilters() {
    this.value.filters = [
      ...(this.dateFilter.from && this.dateFilter.to ? [this.dateFilter] : []),
      ...(this.segmentFilter.values.length ? [this.segmentFilter] : []),
      ...(this.countryFilter.values.length ? [this.countryFilter] : []),
    ];

    this.$emit("input", this.value);
  }
}
