import { AddressModel } from "../AddressModel";

export default class BankResponseModel {
  constructor(
    public id: number,
    public name: string,
    public code: string,
    public address: AddressModel,
    public swift: string,
    public ibans: Array<BankIbanModel>
  ) {}

  static of(model: BankResponseModel): BankResponseModel {
    return new BankResponseModel(
      model.id,
      model.name,
      model.code,
      model.address,
      model.swift,
      model.ibans
    );
  }

  static ofArray(items: Array<BankResponseModel>): Array<BankResponseModel> {
    return items.map((item) => BankResponseModel.of(item));
  }
}

export class BankIbanModel {
  constructor(public currency = "", public iban = "", public id?: number) {}

  static ofRequest(items: Array<BankIbanModel>): Array<BankIbanModel> {
    return items.map(
      (item) =>
        new BankIbanModel(
          item.currency,
          item.iban.replaceAll(" ", ""),
          item?.id
        )
    );
  }
}
