export enum RequestParamPlace {
  URL = "URL",
  BODY = "BODY",
  HEADER = "HEADER",
  CUSTOM = "CUSTOM",
}

export default class NetworkRequestParamResponseModel {
  constructor(
    public id: number,
    public requestId: number,
    public place: RequestParamPlace,
    public paramKey: string,
    public paramValue: string
  ) {}

  static ofSource(
    model: NetworkRequestParamResponseModel
  ): NetworkRequestParamResponseModel {
    return new NetworkRequestParamResponseModel(
      model.id,
      model.requestId,
      model.place,
      model.paramKey,
      model.paramValue
    );
  }

  static ofArray(
    networkRequestParams: Array<NetworkRequestParamResponseModel>
  ) {
    return networkRequestParams.map((networkRequestParam) =>
      NetworkRequestParamResponseModel.ofSource(networkRequestParam)
    );
  }
}
