import { CohortFilter, CohortHeaders } from "@/reports/models/CohortReport";
import { GroupByFilter } from "@/reports/models/GroupByFilter";
import { ReportType } from "@/reports/models/ReportType";
import {
  DatesFilterModel,
  FilterId,
  recordToFilterModel,
  FilterModel,
  Application,
  DefaultValues,
  AdRevenueMethod,
  FilterPreviewId,
} from "@/shared/models";
import { LangService } from "@/shared/types/LangType";
import {
  GroupByType,
  ReportHeader,
  ReportHeaderUtil,
} from "@/reports/models/ReportHeader";
import {
  PredictedResultItem,
  ReportFilter,
  ReportResultItem,
} from "@/reports/models/Report";
import { TimeSpentReportType } from "@/reports/models/TimeSpentReportType";
import { MetricsHeaders } from "@/reports/models/MetricsReport";
import { ReportDataType } from "./ReportVisualization";
import {
  ConversionValueReportFilterExtension,
  EventReportFilterExtension,
  IncludeIapsReportFilterExtension,
  SkadReportTypeReportFilterExtension,
  TimeSpentTypeReportFilterExtension,
} from "./ReportFilterExtension";
import { ReportItemRowObject } from "./ReportItemRowObject";

export enum ConversionValue {
  C_NULL = "C_NULL",
  C_0 = "C_0",
  E_1 = "E_1",
  E_2 = "E_2",
  E_3 = "E_3",
  E_4 = "E_4",
  E_5 = "E_5",
  E_6 = "E_6",
}

export enum SkadReportType {
  PER_EVENTS = "PER_EVENTS",
  FUNNEL = "FUNNEL",
}

export class SkadHeaders {
  static init(
    lang: LangService,
    report: SkadFilter,
    reportDataType: ReportDataType,
    groupBy: GroupByType
  ): Array<ReportHeader> {
    return [
      ...(![ReportDataType.TOTAL, ReportDataType.SUB_TOTAL].includes(
        reportDataType
      )
        ? [
            ReportHeaderUtil.createDate(
              lang,
              report.groupByFilter.aggregationPeriod
            ),
          ]
        : []),
      ...(report.groupByFilter.isNotEmptyGroupBy &&
      reportDataType !== ReportDataType.TOTAL
        ? ReportHeaderUtil.createGroupBy(lang, report.groupByFilter, 200)
        : []),
      {
        text: lang("components.baseTable.installs"),
        align: "end",
        value: ReportResultItem.PREFIX + "installs",
        hasGradient: true,
        fractionDigits: 0,
        width: 100,
      },
      {
        text: lang("components.baseTable.installsPercent"),
        align: "end",
        value: ReportResultItem.PREFIX + "installs_percent",
        hasGradient: true,
        fractionDigits: 0,
        width: 110,
      },
      {
        text: lang("components.baseTable.arpu_365"),
        align: "end",
        value: `${ReportResultItem.PREFIX}arpu_365`,
        hasGradient: true,
        fractionDigits: CohortHeaders.PRECISION_FOR_ARPU,
        class: "text-no-wrap",
      },
      ...MetricsHeaders.generateMetricsHeaders(
        ["returnRate", "timeSpent"],
        lang,
        groupBy
      ),
      {
        text: lang("components.baseTable.skadInstalls"),
        align: "end",
        value: ReportResultItem.PREFIX + "skad_installs",
        hasGradient: true,
        width: 130,
      },
      {
        text: lang("components.baseTable.downloads"),
        align: "end",
        value: ReportResultItem.PREFIX + "count_download",
        hasGradient: true,
        width: 110,
      },
      {
        text: lang("components.baseTable.redownloads"),
        align: "end",
        value: ReportResultItem.PREFIX + "count_redownload",
        hasGradient: true,
        width: 130,
      },
      {
        text: lang("components.baseTable.crPercent"),
        align: "end",
        value: ReportResultItem.PREFIX + "CR_percent",
        hasGradient: true,
        width: 90,
      },
      ...this.generateSkadReportHeaders(report.conversionValue, lang),
    ];
  }

  private static generateSkadReportHeaders(
    conversionValue: Array<ConversionValue>,
    lang: LangService
  ): Array<ReportHeader> {
    return (
      conversionValue.length
        ? Object.values(ConversionValue).filter((value) =>
            conversionValue.includes(value)
          )
        : Object.values(ConversionValue)
    )
      .map(
        (value) =>
          [
            {
              text: lang(`components.baseTable.event.${value.toLowerCase()}`),
              align: "end",
              value: ReportResultItem.PREFIX + value,
              hasGradient: true,
              width: 85,
            },
            {
              text: lang(
                `components.baseTable.eventPercent.${value.toLowerCase()}`
              ),
              align: "end",
              value: `${ReportResultItem.PREFIX}${value}_percent`,
              hasGradient: true,
              width: 140,
            },
          ] as Array<ReportHeader>
      )
      .flat();
  }
}

export class SkadFilter
  extends CohortFilter
  implements
    TimeSpentTypeReportFilterExtension,
    EventReportFilterExtension,
    ConversionValueReportFilterExtension,
    IncludeIapsReportFilterExtension,
    SkadReportTypeReportFilterExtension
{
  constructor(
    app: Application,
    filter?: Array<FilterModel>,
    date?: DatesFilterModel,
    groupByFilter?: GroupByFilter,
    public timeSpentType = TimeSpentReportType.TIME_SPENT,
    public event: string = DefaultValues.EVENT,
    public adRevenueMethod: AdRevenueMethod = AdRevenueMethod.PRICED,
    public conversionValue: Array<ConversionValue> = [],
    public includeIaps = false,
    public reportType = SkadReportType.PER_EVENTS
  ) {
    super(ReportType.SKAD, app, filter, date, groupByFilter, 31);
  }

  get isPricedMethod(): boolean {
    return this.adRevenueMethod === AdRevenueMethod.PRICED;
  }

  get previews() {
    return [
      ...super.previews,
      {
        id: FilterPreviewId.EVENT_SIMPLE,
        value: this.event,
      },
      {
        id: FilterPreviewId.AD_REVENUE_METHOD,
        value: this.adRevenueMethod,
      },
      {
        id: FilterPreviewId.TIME_SPENT_TYPE,
        value: this.timeSpentType,
      },
      ...(this.conversionValue.length &&
      this.conversionValue.length < Object.values(ConversionValue).length
        ? [
            {
              id: FilterPreviewId.CONVERSION_VALUE,
              value: this.conversionValue,
            },
          ]
        : []),
      {
        id: FilterPreviewId.SKAD_REPORT_TYPE,
        value: this.reportType,
      },
      ...(this.includeIaps
        ? [
            {
              id: FilterPreviewId.INCLUDE_IAPS,
              value: this.includeIaps,
            },
          ]
        : []),
    ];
  }

  static of(app: Application, filter?: Record<string, any>): SkadFilter {
    return filter
      ? new SkadFilter(
          app,
          filter.filter && filter.filter.length > 0
            ? filter.filter.map((it: any) => recordToFilterModel(it))
            : [],
          filter.date
            ? DatesFilterModel.ofRecord(
                FilterId.ATTRIBUTION_DATE_VALUE,
                filter.date
              )
            : undefined,
          filter.groupByFilter
            ? GroupByFilter.of(filter.groupByFilter)
            : undefined,
          filter.timeSpentType,
          filter.event,
          filter.adRevenueMethod,
          filter.conversionValue,
          filter.includeIaps,
          Object.values(SkadReportType).find(
            (value: string) => value === filter.reportType
          ) ?? SkadReportType.PER_EVENTS
        )
      : new SkadFilter(app);
  }

  toRequestQuery(): Record<string, any> {
    return {
      ...super.toRequestQuery(),
      timeSpentType: this.timeSpentType,
      event: this.event,
      adRevenueMethod: this.adRevenueMethod,
      conversionValue: this.conversionValue,
      reportType: this.reportType,
      ...(this.includeIaps ? { includeIaps: this.includeIaps } : {}),
    };
  }
}

export class SkadResultItem extends PredictedResultItem {
  constructor(row: ReportItemRowObject, filter: ReportFilter) {
    super();

    const { groupByFilter, conversionValue } = filter as SkadFilter;

    if (row.getByHeader("installDate") || row.getByHeader("date")) {
      this.date = row.getByHeader("installDate") || row.getByHeader("date");
      this.setFormattedDate(
        this.date,
        filter.date.from,
        filter.date.to,
        groupByFilter.aggregationPeriod
      );
    }

    this.setGroupByValue(groupByFilter, row);
    this.data["installs"] = row.getByHeader("installs");
    this.data["installs_percent"] = row.getByHeader("installs_percent");
    this.data["arpu_365"] = super.parseFloat(
      row.getByHeader("arpu_365"),
      CohortHeaders.PRECISION_FOR_ARPU
    );
    this.data["count_download"] = row.getByHeader("count_download");
    this.data["count_redownload"] = row.getByHeader("count_redownload");
    this.data["skad_installs"] = row.getByHeader("skad_installs");
    this.data["CR_percent"] = row.getByHeader("CR_percent");
    this.data = {
      ...this.data,
      ...this.generateResultItemData("timeSpent", row),
      ...this.generateResultItemData("returnRate", row),
    };

    (conversionValue?.length
      ? conversionValue
      : Object.values(ConversionValue)
    ).forEach((value) => {
      this.data[value] = row.getByHeader(value);
      this.data[`${value}_percent`] = row.getByHeader(`${value}_percent`);
    });
  }

  private generateResultItemData(
    name: string,
    row: ReportItemRowObject
  ): Record<string, number> {
    return [3, 7, 14, 21, 30].reduce(
      (result, number) => ({
        ...result,
        [`${name}_${number}`]:
          row.getByHeader(`${name}_${number}`) ?? undefined,
      }),
      {}
    );
  }
}
