

























import { Component, Vue, Ref, Prop, Watch } from "vue-property-decorator";

import { ChartRenderer } from "@/chart/models/ChartRenderer";
import { ChartName } from "@/chart/models/ChartModel";
import { getChartRenderer } from "@/chart/utils/ChartUtil";
import AlertResponseModel from "@/alerts-system/models/AlertResponseModel";
import { getSystemAlertsForCharts } from "@/alerts-system/utils/AlertsSystemUtil";
import { DatesFilterModel, MenuItemsTools } from "@/shared/models";
import { GroupByFilter } from "@/reports/models";
import { AbTestPeriodValidationStatus } from "@/ab-tests/models/AbTestConfigurationModel";

@Component
export default class Chart extends Vue {
  @Prop() name!: ChartName;
  @Prop({ default: false }) loading!: boolean;
  @Prop() data!: Array<Record<string, any>>;
  @Prop({ default: 600 }) height!: number | string;
  @Prop({ default: 16 / 9 }) aspectRatio!: number;
  @Prop() reportDate?: DatesFilterModel;
  @Prop() groupBy?: GroupByFilter;
  @Prop({ default: true }) hasTitle!: boolean;
  @Prop() periodValidationStatus?: AbTestPeriodValidationStatus;

  @Ref("element") readonly element!: HTMLElement;

  root?: ChartRenderer;

  get isDarkMode(): boolean {
    return this.$vuetify.theme.dark;
  }

  get applicationId(): string {
    return this.$route.params.id;
  }

  get alerts(): Array<AlertResponseModel> {
    return getSystemAlertsForCharts(
      this.$store.state.alertsSystemStore.systemAlerts
    );
  }

  @Watch("isDarkMode")
  watchDarkMode(value: boolean) {
    this.root?.setThemes(value);
  }

  @Watch("loading")
  @Watch("data")
  async watchLoading() {
    if (this.loading) {
      return;
    }

    await this.$nextTick();
    this.updateChart();
  }

  mounted() {
    if (!this.element || this.loading) {
      return;
    }

    this.updateChart();
  }

  updateChart() {
    if (this.root) {
      this.root.dispose();
    }

    if (!this.data.length) {
      return;
    }

    this.root = getChartRenderer(
      this.name,
      this.element,
      this.data,
      this.isDarkMode,
      this.$lang,
      this.$route.fullPath.includes("report") ? this.alerts : [],
      this.aspectRatio > 1,
      this.groupBy?.aggregationPeriod,
      this.reportDate?.to,
      this.$route.name === MenuItemsTools.MONETIZATION_MONITORING,
      this.periodValidationStatus
    );
  }
}
