export class ParsingFullTablePerDate {
  constructor(
    public date: string,
    public parsingMetricsPerDate: Array<ParsingMetricPerDate>
  ) {}
}

export class ParsingMetricPerDate {
  constructor(
    public metric: string,
    public adjustValue: string,
    public bigQueryValue: string,
    public deltaValue: string,
    public deltaPercent: string
  ) {}
}
