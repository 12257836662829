export enum ReportType {
  AD_ROAS_COUNTRY = "AD_ROAS_COUNTRY",
  AD_ROAS_NETWORK = "AD_ROAS_NETWORK",
  ARPDAU = "ARPDAU",
  ARPDAU_SIMPLE = "ARPDAU_SIMPLE",
  ARPU = "ARPU",
  CALENDAR_CPM = "CALENDAR_CPM",
  CALENDAR_CTR = "CALENDAR_CTR",
  CALENDAR_PER_MIN = "CALENDAR_PER_MIN",
  CASH_COUNTRY = "CASH_COUNTRY",
  CASH_GAMING = "CASH_GAMING",
  COHORT_ANALYSIS = "COHORT_ANALYSIS",
  COHORT_CPM = "COHORT_CPM",
  COHORT_CTR = "COHORT_CTR",
  COHORT_PER_MIN = "COHORT_PER_MIN",
  DIFF_INSTALLS_STORES = "DIFF_INSTALLS_STORES",
  EVENTS_COST = "EVENTS_COST",
  EVENTS_SUMMARY = "EVENTS_SUMMARY",
  FILL_RATE = "FILL_RATE",
  FIREBASE_FILL_RATE = "FIREBASE_FILL_RATE",
  FIREBASE_SHOW_TO_IMPRESSION = "FIREBASE_SHOW_TO_IMPRESSION",
  FIREBASE_VS_NETWORKS = "FIREBASE_VS_NETWORKS",
  MEASURES_LITE = "MEASURES_LITE",
  METRICS = "METRICS",
  METRICS_SPEND = "METRICS_SPEND",
  MONETIZATION_MONITORING = "MONETIZATION_MONITORING",
  PAYING_USERS_CONVERSION = "PAYING_USERS_CONVERSION",
  PRICED_REVENUE = "PRICED_REVENUE",
  PROFIT = "PROFIT",
  RETURN_RATE = "RETURN_RATE",
  REVENUE = "REVENUE",
  SKAD = "SKAD",
  SPEND = "SPEND",
  SPEND_HYPER = "SPEND_HYPER",
  SPEND_MINI = "SPEND_MINI",
  TIME_SPENT = "TIME_SPENT",
  TRAFFIC_QUALITY = "TRAFFIC_QUALITY",
  WATERFALL = "WATERFALL",
  UA_MAIN_METRICS_OVERVIEW = "UA_MAIN_METRICS_OVERVIEW",
  SUBSCRIPTIONS_OVERVIEW = "SUBSCRIPTIONS_OVERVIEW",
  METRICS_CONSTRUCTOR = "METRICS_CONSTRUCTOR",
  SUBSCRIPTIONS_OVERVIEW_IOS = "SUBSCRIPTIONS_OVERVIEW_IOS",
  EVENTS_SUMMARY_LITE = "EVENTS_SUMMARY_LITE",
}

export enum GroupReportNames {
  EVENTS_SUMMARY = "Events Summary",
  COHORT = "Cohort",
  PER_MIN = "Permin",
  SPEND = "Spend",
  ARPDAU = "ARPDAU",
  CTR = "CTR",
  CASH = "Cash",
  CPM = "CPM",
  REVENUE = "Revenue",
  FIREBASE = "Firebase",
}

export enum ReportChartConditionMessage {
  GROUP_BY_COUNTRY = "GROUP_BY_COUNTRY",
  GROUP_BY = "GROUP_BY",
  AGGREGATION_PERIOD = "AGGREGATION_PERIOD",
  EVENT = "EVENT",
  GROUP_BY_LESS_THAN_TWO_FILTERS = "GROUP_BY_LESS_THAN_TWO_FILTERS",
  EVENT_FILTER_AND_AGG_PERIOD = "EVENT_FILTER_AND_AGG_PERIOD",
}

const REPORT_TYPE_MENU_VISIBILITY: Record<ReportType, boolean> = {
  [ReportType.EVENTS_SUMMARY]: true,
  [ReportType.FILL_RATE]: true,
  [ReportType.REVENUE]: true,
  [ReportType.PRICED_REVENUE]: true,
  [ReportType.ARPDAU]: true,
  [ReportType.ARPDAU_SIMPLE]: true,
  [ReportType.ARPU]: true,
  [ReportType.RETURN_RATE]: true,
  [ReportType.MEASURES_LITE]: true,
  [ReportType.TIME_SPENT]: true,
  [ReportType.SKAD]: true,
  [ReportType.METRICS]: true,
  [ReportType.METRICS_SPEND]: true,
  [ReportType.TRAFFIC_QUALITY]: true,
  [ReportType.EVENTS_COST]: true,
  [ReportType.AD_ROAS_COUNTRY]: true,
  [ReportType.AD_ROAS_NETWORK]: true,
  [ReportType.PAYING_USERS_CONVERSION]: true,
  [ReportType.UA_MAIN_METRICS_OVERVIEW]: true,
  [ReportType.SUBSCRIPTIONS_OVERVIEW]: true,
  [ReportType.SUBSCRIPTIONS_OVERVIEW_IOS]: true,
  [ReportType.METRICS_CONSTRUCTOR]: true,
  [ReportType.COHORT_PER_MIN]: true,
  [ReportType.CALENDAR_PER_MIN]: true,
  [ReportType.SPEND]: true,
  [ReportType.SPEND_HYPER]: true,
  [ReportType.SPEND_MINI]: true,
  [ReportType.COHORT_ANALYSIS]: true,
  [ReportType.COHORT_CPM]: true,
  [ReportType.CALENDAR_CPM]: true,
  [ReportType.PROFIT]: true,
  [ReportType.WATERFALL]: true,
  [ReportType.CASH_COUNTRY]: true,
  [ReportType.CASH_GAMING]: true,
  [ReportType.CALENDAR_CTR]: true,
  [ReportType.COHORT_CTR]: true,
  [ReportType.FIREBASE_VS_NETWORKS]: true,
  [ReportType.FIREBASE_SHOW_TO_IMPRESSION]: true,
  [ReportType.FIREBASE_FILL_RATE]: true,
  [ReportType.DIFF_INSTALLS_STORES]: true,
  [ReportType.MONETIZATION_MONITORING]: false,
  [ReportType.EVENTS_SUMMARY_LITE]: true,
};

// Need for report menu and middleware redirect priority. Key order is important
// Boolean value indicates whether a report is needed in report menu
export const REPORT_SORT_ORDER: Array<ReportType> = (
  Object.entries(REPORT_TYPE_MENU_VISIBILITY) as [ReportType, boolean][]
).reduce((result: Array<ReportType>, [reportType, visible]) => {
  if (visible) {
    result.push(reportType);
  }

  return result;
}, []);
