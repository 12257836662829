import { FilterBehaviorOption } from "./AccountSettingType";
import { FilterId } from "@/shared/models/FilterModel";

export default class SpecificFiltersBehaviorModel {
  constructor(
    public value: FilterId,
    public options: Array<FilterBehaviorOption> = []
  ) {}

  static of(model: SpecificFiltersBehaviorModel): SpecificFiltersBehaviorModel {
    return new SpecificFiltersBehaviorModel(model.value, model.options);
  }

  static ofArray(
    items: Array<SpecificFiltersBehaviorModel>
  ): Array<SpecificFiltersBehaviorModel> {
    return items.map((item) => SpecificFiltersBehaviorModel.of(item));
  }
}
