import { CohortFilter } from "./CohortReport";
import { AggregationPeriod, GroupByFilter } from "./GroupByFilter";
import { ReportType } from "./ReportType";
import {
  AdType,
  DatesFilterModel,
  FilterId,
  FilterPreview,
  FilterPreviewId,
  recordToFilterModel,
  FilterModel,
  Application,
} from "@/shared/models";
import { AdTypeReportFilterExtension } from "./ReportFilterExtension";
import { ChartName } from "@/chart/models/ChartModel";

export class CtrCohortFilter
  extends CohortFilter
  implements AdTypeReportFilterExtension
{
  constructor(
    app: Application,
    filter?: Array<FilterModel>,
    date?: DatesFilterModel,
    groupByFilter = new GroupByFilter(AggregationPeriod.DAY, [], false),
    dayLimit?: number,
    public adType = AdType.BANNER
  ) {
    super(ReportType.COHORT_CTR, app, filter, date, groupByFilter, dayLimit);
  }

  get previews(): Array<FilterPreview> {
    return [
      ...super.previews,
      {
        id: FilterPreviewId.AD_TYPE,
        value: this.adType,
      },
    ];
  }

  static of(
    app: Application,
    requestQuery?: Record<string, any>
  ): CtrCohortFilter {
    return new CtrCohortFilter(
      app,
      requestQuery?.filter?.map((it: any) => recordToFilterModel(it)) || [],
      DatesFilterModel.ofRecord(
        FilterId.ATTRIBUTION_DATE_VALUE,
        requestQuery?.date
      ),
      new GroupByFilter(
        AggregationPeriod.DAY,
        requestQuery?.groupByFilter?.groupBy,
        false
      ),
      !!requestQuery && !!requestQuery.dayLimit
        ? Number.parseInt(requestQuery.dayLimit as string)
        : undefined,
      requestQuery?.adType
    );
  }

  get invalid(): boolean {
    return !(this.app && this.date.valid);
  }

  get charts(): Array<ChartName> {
    return this.groupByFilter.isNotEmptyGroupBy
      ? this.getGroupedCohortChartNames("COHORT_CTR", [3, 7, 14, 30])
      : [ChartName.COHORT_CTR_CHART];
  }

  get groupedCharts(): Array<ChartName> {
    return this.groupByFilter.isNotEmptyGroupBy
      ? [ChartName.COHORT_CTR_CHART]
      : [];
  }

  toRequestQuery(): Record<string, any> {
    const result = super.toRequestQuery();

    result["adType"] = this.adType;

    return {
      ...super.toRequestQuery(),
      adType: this.adType,
    };
  }
}
