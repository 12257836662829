import { SegmentDividerType, SegmentFileModel } from "./SegmentModel";

export class UploadDividerRequestModel {
  constructor(
    public applicationId: string,
    public type: SegmentDividerType,
    public file: File
  ) {}
}

export class GetDividerFileRequestModel {
  constructor(public applicationId: string, public type: SegmentDividerType) {}
}

export class DividerFileResponse {
  constructor(public type: SegmentDividerType, public file: SegmentFileModel) {}
}
