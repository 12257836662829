import ReportUtil from "@/reports/utils/ReportUtil";

export abstract class ReportModel {
  readonly name: string;

  constructor(name: string) {
    this.name = name;
  }

  get icon() {
    return ReportUtil.getIcon(this.getId() || this.name);
  }

  abstract getId(): string | null;
}

export class ReportItemModel extends ReportModel {
  readonly id: string;

  constructor(id: string, name: string, public multiApp = false) {
    super(name);
    this.id = id;
  }

  getId(): string | null {
    return this.id;
  }
}

export class ReportGroupModel extends ReportModel {
  items: Array<ReportItemModel> = [];
  isActive = false;

  getId(): string | null {
    return null;
  }
}
