export default {
  accountSettings: {
    title: "Account settings",
    tabs: {
      interface: "Interface",
      applications: "Applications",
    },
    interfaceThemeTitle: "Interface theme",
    interfaceThemeText: "Select your UI theme",
    filterLocationTitle: "Filter location",
    filterLocationText: "Select your filter location in reports",
    locationForAdditionalMenuTitle: "Location for additional menu",
    locationForAdditionalMenuText: "Select your location for additional menu",
    defaultApplicationTitle: "Default application",
    defaultApplicationText: "Change your default application",
    favoriteApplicationsTitle: "Favorite applications",
    favoriteApplicationsText: "Change your favorite applications",
    favoriteApplicationsBtnText: "Add favorite application",
    filtersBehaviorTitle: "Filters behavior",
    filtersBehaviorText: "Select default settings for the all components",
    specificFiltersBehaviorTitle: "Specific filters behavior",
    specificFiltersBehaviorBtnText: "Add specific filter behavior",
    selectFilter: "Select filter",
    defaultBehaviorTitle: "Default behavior",
    filtersBehaviorOptions: {
      clearable: "Clearable",
      clear_search_value_after_select: "Clear search value after select",
      close_after_select: "Close after select",
    },
    optionsTitle: "Options",
    filtersBehaviorPlaceholder: "Select some values",
    specificFilterTitle: "{0} filter",
    dark: "Dark",
    light: "Light",
    horizontal: "Horizontal",
    vertical: "Vertical",
    collapsed: "Collapsed",
    expanded: "Expanded",
    example: "Example",
    filters: {
      country: "Country filter",
      source: "Source filter",
      segment: "Segment filter",
      device_type: "Device type filter",
    },
    notification: {
      updatedSuccess: "Account settings have been successfully updated",
    },
  },
};
