import { DictionaryType, FilterColor } from "@/shared/models";
import KeyUtil from "@/shared/utils/KeyUtil";

export enum RuleType {
  UserProperty = "UserProperty",
  UserId = "UserId",
}

export enum RuleOperator {
  INCLUDE = "INCLUDE",
  EXCLUDE = "EXCLUDE",
}

export enum DateRangeConditionType {
  ABSOLUTE_VALUE = "ABSOLUTE_VALUE",
  USERS_AGE = "USERS_AGE",
}

export enum DateRangeConditionOperator {
  BETWEEN = "BETWEEN",
  GREATER_OR_EQUAL = "GREATER_OR_EQUAL",
  LESS_OR_EQUAL = "LESS_OR_EQUAL",
}

export class DateRangeConditionModel {
  constructor(
    public type = DateRangeConditionType.ABSOLUTE_VALUE,
    public minInstallDate?: string,
    public maxInstallDate?: string,
    public operator = DateRangeConditionOperator.BETWEEN,
    public values: Array<number> = []
  ) {}

  get valid(): boolean {
    return {
      [DateRangeConditionType.ABSOLUTE_VALUE]: () =>
        !!(
          this.minInstallDate &&
          this.maxInstallDate &&
          this.minInstallDate <= this.maxInstallDate
        ),
      [DateRangeConditionType.USERS_AGE]: () => {
        if (this.operator === DateRangeConditionOperator.BETWEEN) {
          return (
            this.values.length === 2 &&
            this.values[0] >= 0 &&
            this.values[0] <= this.values[1]
          );
        }

        return !!this.values.length && this.values[0] >= 0;
      },
    }[this.type]();
  }

  static of(model: DateRangeConditionModel): DateRangeConditionModel {
    return new DateRangeConditionModel(
      model.type,
      model.minInstallDate,
      model.maxInstallDate,
      model.operator,
      model.values ? [...model.values] : undefined
    );
  }
}

export default class RuleModel {
  constructor(
    public type: RuleType = RuleType.UserProperty,
    public operator: RuleOperator = RuleOperator.INCLUDE,
    public languages: Array<string> = [],
    public countries: Array<string> = [],
    public deviceTypes: Array<string> = [],
    public deviceModels: Array<string> = [],
    public appVersions: Array<string> = [],
    public osVersions: Array<string> = [],
    public segments: Array<string> = [],
    public csvFileId: number | null = null,
    public isLatEnabled: boolean | null = null,
    public installDates: DateRangeConditionModel | null = null,
    public customProperties: Record<string, string> = {}
  ) {}

  get usedDictionaryValues(): Record<string, Array<string>> {
    return {
      ...(this.languages.length
        ? { [DictionaryType.LANGUAGES]: this.languages }
        : {}),
      ...(this.countries.length
        ? { [DictionaryType.COUNTRIES]: this.countries }
        : {}),
      ...(this.deviceTypes.length
        ? { [DictionaryType.DEVICE_TYPES]: this.deviceTypes }
        : {}),
      ...(this.deviceModels.length
        ? { [DictionaryType.DEVICE_MODELS]: this.deviceModels }
        : {}),
      ...(this.appVersions.length
        ? { [DictionaryType.APP_VERSIONS]: this.appVersions }
        : {}),
      ...(this.osVersions.length
        ? { [DictionaryType.OS_VERSIONS]: this.osVersions }
        : {}),
      ...(this.segments.length
        ? { [DictionaryType.SEGMENTS]: this.segments }
        : {}),
    };
  }

  static of(rule?: RuleModel) {
    return !rule
      ? new RuleModel()
      : new RuleModel(
          rule.type,
          rule.operator,
          !rule.languages ? [] : [...rule.languages],
          !rule.countries ? [] : [...rule.countries],
          !rule.deviceTypes ? [] : [...rule.deviceTypes],
          !rule.deviceModels ? [] : [...rule.deviceModels],
          !rule.appVersions ? [] : [...rule.appVersions],
          !rule.osVersions ? [] : [...rule.osVersions],
          !rule.segments ? [] : [...rule.segments],
          rule.csvFileId,
          rule.isLatEnabled,
          !rule.installDates || rule.installDates.valid === false
            ? null
            : DateRangeConditionModel.of(rule.installDates),
          !rule.customProperties ? {} : Object.assign({}, rule.customProperties)
        );
  }
}

export class RuleCustomPropertyModel {
  constructor(
    public key: string = "",
    public value: string = "",
    public id: string = KeyUtil.generateKey()
  ) {}
}

export enum RulePreviewType {
  LANGUAGES = "LANGUAGES",
  COUNTRIES = "COUNTRIES",
  DEVICE_TYPES = "DEVICE_TYPES",
  DEVICE_MODELS = "DEVICE_MODELS",
  APP_VERSIONS = "APP_VERSIONS",
  OS_VERSIONS = "OS_VERSIONS",
  SEGMENTS = "SEGMENTS",
  CSV_FILE_ID = "CSV_FILE_ID",
  LAT = "LAT",
  INSTALL_DATES = "INSTALL_DATES",
  USERS_AGE = "USERS_AGE",
  CUSTOM_PROPERTIES = "CUSTOM_PROPERTIES",
}

export interface RulePreview {
  type: RulePreviewType;
  text: string;
  values?: Array<string>;
  color?: FilterColor;
  dictionary?: DictionaryType;
}
