import {
  Application,
  DatesFilterModel,
  FilterId,
  FilterModel,
  recordToFilterModel,
} from "@/shared/models";
import { LangService } from "@/shared/types/LangType";
import DateUtil from "@/shared/utils/DateUtil";
import { AggregationPeriod, GroupByFilter } from "./GroupByFilter";
import {
  BaseGradientFlags,
  BaseReportFilter,
  ReportResultItem,
} from "./Report";
import { ReportHeader, ReportHeaderUtil } from "./ReportHeader";
import { ReportType } from "./ReportType";
import { ReportDataType } from "./ReportVisualization";
import { ChartName } from "@/chart/models/ChartModel";
import { ReportItemRowObject } from "./ReportItemRowObject";

export class DiffInstallsStoresGradientFlags extends BaseGradientFlags {
  constructor() {
    super();

    this.flags = {
      [`${ReportResultItem.PREFIX}rawData`]: true,
      [`${ReportResultItem.PREFIX}storeData`]: true,
      [`${ReportResultItem.PREFIX}deltaInstallRawStore`]: true,
      [`${ReportResultItem.PREFIX}percentInstallRawStore`]: true,
    };
  }
}

export class DiffInstallsStoresFilter extends BaseReportFilter {
  constructor(
    app: Application,
    filter?: Array<FilterModel>,
    date?: DatesFilterModel,
    public groupByFilter = new GroupByFilter(AggregationPeriod.DAY, [], false)
  ) {
    super(ReportType.DIFF_INSTALLS_STORES, app, filter, date);
  }

  get invalid(): boolean {
    return !(this.app && this.date.valid);
  }

  get charts(): Array<ChartName> {
    return [ChartName.DIFF_INSTALLS_STORES_CHART];
  }

  static of(
    app: Application,
    requestQuery?: Record<string, any>
  ): DiffInstallsStoresFilter {
    return new DiffInstallsStoresFilter(
      app,
      requestQuery?.filter?.map((it: any) => recordToFilterModel(it)) || [],
      DatesFilterModel.ofRecord(FilterId.EVENTS_DATE, requestQuery?.date)
    );
  }
}

export class DiffInstallsStoresHeaders {
  static readonly PRECISION = 2;

  static init(
    lang: LangService,
    reportDataType: ReportDataType
  ): Array<ReportHeader> {
    return [
      ...(reportDataType !== ReportDataType.TOTAL
        ? [ReportHeaderUtil.createDate(lang)]
        : []),
      {
        text: lang("components.baseTable.rawData"),
        align: "end",
        value: `${ReportResultItem.PREFIX}rawData`,
        hasGradient: true,
      },
      {
        text: lang("components.baseTable.storeData"),
        align: "end",
        value: `${ReportResultItem.PREFIX}storeData`,
        hasGradient: true,
      },
      {
        text: lang("components.baseTable.deltaInstallRawStore"),
        align: "end",
        value: `${ReportResultItem.PREFIX}deltaInstallRawStore`,
        hasGradient: true,
      },
      {
        text: lang("components.baseTable.percentInstallRawStore"),
        align: "end",
        value: `${ReportResultItem.PREFIX}percentInstallRawStore`,
        hasGradient: true,
        fractionDigits: DiffInstallsStoresHeaders.PRECISION,
      },
    ];
  }
}

export class DiffInstallsStoresResultItem extends ReportResultItem {
  constructor(row: ReportItemRowObject, isTotal = false) {
    super();

    if (!isTotal) {
      this.date = row.getByHeader("date");
      this.formattedDate = DateUtil.formatDate(this.date);
    }

    this.data["rawData"] = row.getByHeader("rawData");
    this.data["storeData"] = row.getByHeader("storeData");
    this.data["deltaInstallRawStore"] =
      row.getByHeader("deltaInstallRawStore") === null
        ? "N/A"
        : row.getByHeader("deltaInstallRawStore");
    this.data["percentInstallRawStore"] =
      row.getByHeader("percentInstallRawStore") === null
        ? "N/A"
        : row.getByHeader("percentInstallRawStore");
  }
}
