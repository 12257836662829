export enum Feature {
  SEGMENT_RECALCULATION = "SEGMENT_RECALCULATION",
  SEGMENT_DELETE = "SEGMENT_DELETE",
  FUNNEL_RECALCULATION = "FUNNEL_RECALCULATION",
  FUNNEL_DELETE = "FUNNEL_DELETE",
  AB_TEST_RECALCULATION = "AB_TEST_RECALCULATION",
  AD_PANEL_REVENUE = "AD_PANEL_REVENUE",
  AD_PANEL_SPEND = "AD_PANEL_SPEND",
  AD_PANEL_STORE = "AD_PANEL_STORE",
  AMAZON_S3_ADJUST_PARSING = "AMAZON_S3_ADJUST_PARSING",
  GCS_ADJUST_PARSING = "GCS_ADJUST_PARSING",
  AMAZON_S3_ADJUST_SKAD_PARSING = "AMAZON_S3_ADJUST_SKAD_PARSING",
  GCS_ADJUST_SKAD_PARSING = "GCS_ADJUST_SKAD_PARSING",
  SEGMENT_STATISTIC_RECALCULATION = "SEGMENT_STATISTIC_RECALCULATION",
  DASHBOARD = "DASHBOARD",
  FIREBASE_BANNERS = "FIREBASE_BANNERS",
  AB_TEST_PERMUTATION_TEST = "AB_TEST_PERMUTATION_TEST",
  REPORTS_METRICS_PREDICTED = "REPORTS_METRICS_PREDICTED",
}
