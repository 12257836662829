import { CohortFilter } from "./CohortReport";
import { GroupByFilter } from "./GroupByFilter";
import { ReportType } from "./ReportType";
import {
  DatesFilterModel,
  FilterId,
  FilterPreview,
  FilterPreviewId,
  recordToFilterModel,
  FilterModel,
  Application,
} from "@/shared/models";
import { ReportResultItem } from "./Report";
import { ReportHeader } from "./ReportHeader";
import { LangService } from "@/shared/types/LangType";
import { ReportDataType } from "./ReportVisualization";
import { ReportItemRowObject } from "./ReportItemRowObject";

const HEADER_DATA: Array<[string, string?]> = [
  ["su"],
  ["asu"],
  ["asur", "%"],
  ["psu"],
  ["psur", "%"],
  ["apsu"],
  ["apsur", "%"],
  ["purchases"],
  ["pppsu"],
  ["revenue"],
  ["revenueRate", "%"],
  ["revenueEst"],
  ["tsu"],
  ["tasu"],
  ["tesu"],
  ["tpsu"],
  ["tcr", "%"],
  ["rsu"],
  ["srr", "%"],
  ["renewals"],
  ["rprsu"],
  ["inactiveRsu"],
  ["inactiveSrr", "%"],
  ["agsu"],
  ["isu"],
  ["isur", "%"],
  ["holdSu"],
  ["canceledSu"],
  ["finishedSu"],
];

export class SubscriptionIosFilter extends CohortFilter {
  constructor(
    app: Application,
    filter?: Array<FilterModel>,
    date?: DatesFilterModel,
    groupByFilter?: GroupByFilter,
    dayLimit?: number,
    public netRevenue = true
  ) {
    super(
      ReportType.SUBSCRIPTIONS_OVERVIEW_IOS,
      app,
      filter,
      date,
      groupByFilter,
      dayLimit
    );
  }

  get previews(): Array<FilterPreview> {
    return [
      ...super.previews,
      {
        id: FilterPreviewId.NET_REVENUE,
        value: this.netRevenue,
      },
    ];
  }

  static of(
    app: Application,
    filter?: Record<string, any>
  ): SubscriptionIosFilter {
    return filter
      ? new SubscriptionIosFilter(
          app,
          filter.filter && filter.filter.length > 0
            ? filter.filter.map((it: any) => recordToFilterModel(it))
            : [],
          filter.date
            ? DatesFilterModel.ofRecord(
                FilterId.ATTRIBUTION_DATE_VALUE,
                filter.date
              )
            : undefined,
          filter.groupByFilter
            ? GroupByFilter.of(filter.groupByFilter)
            : undefined,
          filter.dayLimit
            ? Number.parseInt(filter.dayLimit as string)
            : undefined,
          typeof filter.netRevenue === "string"
            ? filter.netRevenue === "true"
            : filter.netRevenue
        )
      : new SubscriptionIosFilter(app);
  }

  toRequestQuery(): Record<string, any> {
    return {
      ...super.toRequestQuery(),
      netRevenue: this.netRevenue,
    };
  }
}

export class SubscriptionIosHeaders {
  static init(
    lang: LangService,
    report: SubscriptionIosFilter,
    reportDataType: ReportDataType
  ): Array<ReportHeader> {
    return [
      ...(reportDataType === ReportDataType.SUB_TOTAL
        ? ([
            {
              text: lang("components.baseTable.subscription_name"),
              align: "start",
              value: `${ReportResultItem.PREFIX}subscription_name`,
              width: 145,
              tooltipText: lang("components.baseTable.subscriptionNameHint"),
              sticky: true,
            },
            {
              text: lang("components.baseTable.offer_id"),
              align: "start",
              value: `${ReportResultItem.PREFIX}offer_id`,
              width: 150,
              tooltipText: lang("components.baseTable.offerIdHint"),
              sticky: true,
            },
          ] as Array<ReportHeader>)
        : []),
      ...HEADER_DATA.map(
        ([item, postfix]) =>
          ({
            text: lang(`components.baseTable.${item}`),
            align: "end",
            width: 90,
            value: `${ReportResultItem.PREFIX}${item}`,
            tooltipText: lang(`components.baseTable.${item}Hint`),
            ...(postfix === undefined ? {} : { postfix }),
          } as ReportHeader)
      ),
    ];
  }
}

export class SubscriptionIosResultItem extends ReportResultItem {
  constructor(row: ReportItemRowObject) {
    super();

    this.data["subscription_name"] = row.getByHeader("subscription_name");
    this.data["offer_id"] = row.getByHeader("offer_id");
    HEADER_DATA.forEach(([item]) => (this.data[item] = row.getByHeader(item)));
  }
}
