import {
  BaseGradientFlags,
  MultiAppsReportFilter,
  ReportResultItem,
  ReportHeader,
  ReportHeaderUtil,
  AdRevenueMethodReportFilterExtension,
  ProvidedBannerReportFilterExtension,
  ReportItemRowObject,
} from "@/reports/models";
import { ReportType } from "@/reports/models/ReportType";
import {
  DatesFilterModel,
  FilterId,
  recordToFilterModel,
  FilterModel,
  AdRevenueMethod,
  DefaultValues,
  FilterPreviewId,
  Application,
  ProvidedBannerType,
} from "@/shared/models";
import { LangService } from "@/shared/types/LangType";
import DateUtil from "@/shared/utils/DateUtil";
import { AggregationPeriod, GroupByFilter } from "./GroupByFilter";
import { ReportFilter } from "./Report";
import { ChartName } from "@/chart/models/ChartModel";

export class CpmGradientFlags extends BaseGradientFlags {
  constructor() {
    super();

    this.flags = {
      [`${ReportResultItem.PREFIX}bannerImpressions`]: true,
      [`${ReportResultItem.PREFIX}bannerCPM`]: true,
      [`${ReportResultItem.PREFIX}interstitialImpressions`]: true,
      [`${ReportResultItem.PREFIX}interstitialCPM`]: true,
      [`${ReportResultItem.PREFIX}interstitialNeededRate`]: true,
      [`${ReportResultItem.PREFIX}rewardedImpressions`]: true,
      [`${ReportResultItem.PREFIX}rewardedCPM`]: true,
      [`${ReportResultItem.PREFIX}rewardedNeededRate`]: true,
    };
  }
}

export class CpmFilter
  extends MultiAppsReportFilter
  implements
    AdRevenueMethodReportFilterExtension,
    ProvidedBannerReportFilterExtension
{
  constructor(
    availableApps: Array<Application>,
    platforms?: Array<string>,
    apps?: Array<string>,
    includedApps?: boolean,
    filter?: Array<FilterModel>,
    date: DatesFilterModel = DefaultValues.initDatesFilterModel(
      FilterId.EVENTS_DATE
    ),
    groupByFilter?: GroupByFilter,
    public adRevenueMethod = AdRevenueMethod.AVERAGE,
    public providedBanner = DefaultValues.PROVIDED_BANNER,
    public netRevenue = true
  ) {
    super(
      ReportType.CALENDAR_CPM,
      availableApps,
      platforms,
      apps,
      includedApps,
      filter,
      date,
      groupByFilter
    );
  }

  get isDefaultProvidedBanner(): boolean {
    return this.providedBanner === DefaultValues.PROVIDED_BANNER;
  }

  get isBannerProvidedDisabled(): boolean {
    return this.adRevenueMethod === AdRevenueMethod.AVERAGE;
  }

  get previews() {
    return [
      ...super.previews,
      {
        id: FilterPreviewId.PROVIDED_BANNER,
        value: this.providedBanner,
      },
      {
        id: FilterPreviewId.AD_REVENUE_METHOD,
        value: this.adRevenueMethod,
      },
      {
        id: FilterPreviewId.NET_REVENUE,
        value: this.netRevenue,
      },
    ];
  }

  get invalid(): boolean {
    return (
      super.invalid ||
      (this.isBannerProvidedDisabled &&
        this.providedBanner === ProvidedBannerType.FIREBASE)
    );
  }

  get charts(): Array<ChartName> {
    return this.groupByFilter.isNotEmptyGroupBy
      ? [
          ChartName.CPM_REPORT_BANNER_CHART,
          ChartName.CPM_REPORT_INTERSTITIAL_CHART,
          ChartName.CPM_REPORT_REWARDED_CHART,
        ]
      : [ChartName.CPM_REPORT_CHART];
  }

  get groupedCharts(): Array<ChartName> {
    return this.groupByFilter.isNotEmptyGroupBy
      ? [ChartName.CPM_REPORT_CHART]
      : [];
  }

  static of(
    availableApps: Array<Application>,
    requestQuery?: Record<string, any>
  ): CpmFilter {
    return new CpmFilter(
      availableApps,
      requestQuery?.platforms as Array<string>,
      requestQuery?.apps as Array<string>,
      typeof requestQuery?.includedApps === "string"
        ? requestQuery?.includedApps === "true"
        : requestQuery?.includedApps,
      requestQuery?.filter?.map((it: any) => recordToFilterModel(it)) || [],
      DatesFilterModel.ofRecord(FilterId.EVENTS_DATE, requestQuery?.date),
      new GroupByFilter(
        AggregationPeriod.DAY,
        requestQuery?.groupByFilter?.groupBy,
        false
      ),
      requestQuery?.adRevenueMethod,
      requestQuery?.providedBanner
      // TODO: NET_REVENUE need to uncomment later
      // typeof requestQuery.netRevenue === "string"
      //   ? requestQuery.netRevenue === "true"
      //   : requestQuery.netRevenue,
    );
  }

  toRequestQuery(): Record<string, any> {
    return {
      ...super.toRequestQuery(),
      adRevenueMethod: this.adRevenueMethod,
      providedBanner: this.providedBanner,
      // TODO: NET_REVENUE need to uncomment later
      // netRevenue: this.netRevenue,
    };
  }
}

export class CpmHeaders {
  static readonly PRECISION = 3;
  static init(lang: LangService, report: CpmFilter): Array<ReportHeader> {
    return [
      ReportHeaderUtil.createDate(lang, report.groupByFilter.aggregationPeriod),
      ...(report.groupByFilter.isNotEmptyGroupBy
        ? ReportHeaderUtil.createGroupBy(lang, report.groupByFilter, 200)
        : []),
      {
        text: lang("components.baseTable.bannerImpressions"),
        align: "end",
        value: `${ReportResultItem.PREFIX}bannerImpressions`,
        hasGradient: true,
        class: "text-no-wrap",
      },
      {
        text: lang("components.baseTable.bannerCPM"),
        align: "end",
        value: `${ReportResultItem.PREFIX}bannerCPM`,
        hasGradient: true,
        fractionDigits: CpmHeaders.PRECISION,
        class: "text-no-wrap",
      },
      {
        text: lang("components.baseTable.interstitialImpressions"),
        align: "end",
        value: `${ReportResultItem.PREFIX}interstitialImpressions`,
        hasGradient: true,
        class: "text-no-wrap",
      },
      {
        text: lang("components.baseTable.interstitialCPM"),
        align: "end",
        value: `${ReportResultItem.PREFIX}interstitialCPM`,
        hasGradient: true,
        fractionDigits: CpmHeaders.PRECISION,
        class: "text-no-wrap",
      },
      ...(!report.groupByFilter.groupBy.includes(FilterId.AD_NETWORK_NAME) &&
      !report.filter.some((item) => item.id === FilterId.AD_NETWORK_NAME)
        ? ([
            {
              text: lang("components.baseTable.interstitialNeededRate"),
              align: "end",
              value: `${ReportResultItem.PREFIX}interstitialNeededRate`,
              hasGradient: true,
              fractionDigits: CpmHeaders.PRECISION,
            },
          ] as Array<ReportHeader>)
        : []),
      {
        text: lang("components.baseTable.rewardedImpressions"),
        align: "end",
        value: `${ReportResultItem.PREFIX}rewardedImpressions`,
        hasGradient: true,
        class: "text-no-wrap",
      },
      {
        text: lang("components.baseTable.rewardedCPM"),
        align: "end",
        value: `${ReportResultItem.PREFIX}rewardedCPM`,
        hasGradient: true,
        fractionDigits: CpmHeaders.PRECISION,
        class: "text-no-wrap",
      },
      ...(!report.groupByFilter.groupBy.includes(FilterId.AD_NETWORK_NAME) &&
      !report.filter.some((item) => item.id === FilterId.AD_NETWORK_NAME)
        ? ([
            {
              text: lang("components.baseTable.rewardedNeededRate"),
              align: "end",
              value: `${ReportResultItem.PREFIX}rewardedNeededRate`,
              hasGradient: true,
              fractionDigits: CpmHeaders.PRECISION,
            },
          ] as Array<ReportHeader>)
        : []),
    ];
  }
}

export class CpmResultItem extends ReportResultItem {
  constructor(row: ReportItemRowObject, filter: ReportFilter) {
    super();
    const { groupByFilter } = filter;
    this.date = row.getByHeader("date");
    this.formattedDate = DateUtil.formatDate(this.date);
    this.setGroupByValue(groupByFilter, row);
    this.data["bannerImpressions"] = Math.round(
      Number.parseFloat(row.getByHeader("bannerImpressions"))
    );
    this.data["bannerCPM"] = super.parseFloat(
      row.getByHeader("bannerCPM"),
      CpmHeaders.PRECISION
    );
    this.data["interstitialImpressions"] = Math.round(
      Number.parseFloat(row.getByHeader("interstitialImpressions"))
    );
    this.data["interstitialCPM"] = super.parseFloat(
      row.getByHeader("interstitialCPM"),
      CpmHeaders.PRECISION
    );
    this.data["interstitialNeededRate"] = super.parseFloat(
      row.getByHeader("interstitialNeededRate"),
      CpmHeaders.PRECISION
    );
    this.data["rewardedImpressions"] = Math.round(
      Number.parseFloat(row.getByHeader("rewardedImpressions"))
    );
    this.data["rewardedCPM"] = super.parseFloat(
      row.getByHeader("rewardedCPM"),
      CpmHeaders.PRECISION
    );
    this.data["rewardedNeededRate"] = super.parseFloat(
      row.getByHeader("rewardedNeededRate"),
      CpmHeaders.PRECISION
    );
  }
}
