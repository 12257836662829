import AbTestConfigurationModel from "./AbTestConfigurationModel";
import TargetedConfigurationModel from "./TargetedConfigurationModel";

export enum ConfigsStatus {
  INIT = "INIT",
  SCHEDULED = "SCHEDULED",
  STARTED = "STARTED",
  GROUPS_DISTRIBUTED = "GROUPS_DISTRIBUTED",
  CONFIG_DISTRIBUTION_FINISHED = "CONFIG_DISTRIBUTION_FINISHED",
  STATISTICS_RECALCULATION = "STATISTICS_RECALCULATION",
  STATISTICS_RECALCULATED = "STATISTICS_RECALCULATED",
  DISABLED = "DISABLED",
  CANCELED = "CANCELED",
}

export interface AbTestEnablingModel {
  abTest: AbTestConfigurationModel;
  date?: {
    activeSince?: string;
    activeTill?: string;
    assignmentTillDate?: string;
  };
}
export interface TargetEnablingModel {
  targetedConfig: TargetedConfigurationModel;
  date?: {
    activeSince?: string;
    activeTill?: string;
    assignmentTillDate?: string;
  };
}

export interface AbTestDisablingModel {
  abTest: AbTestConfigurationModel;
  confirmed: boolean;
}

export interface TargetedConfigDisablingModel {
  targetedConfig: TargetedConfigurationModel;
  confirmed: boolean;
}
