import { PermissionUtil } from "@/iam/utils/PermissionUtil";
import { UserAccess } from "@/shared/models/UserModel";

export enum PermissionEntity {
  APPLICATION = "APPLICATION",
  USER = "USER",
  FEATURE = "FEATURE",
  APP_SECTION = "APP_SECTION",
  AD_NETWORK = "AD_NETWORK",
  GENERAL = "GENERAL",

  // Aggregators
  APP_SECTIONS = "APP_SECTIONS",
  APPLICATIONS = "APPLICATIONS",
  AD_NETWORKS = "AD_NETWORKS",
}

export enum AppSectionAggregator {
  GENERAL_APP_SECTIONS = "GENERAL_APP_SECTIONS",
  NONE_GENERAL_APP_SECTIONS = "NONE_GENERAL_APP_SECTIONS",
}

export interface PermissionLink {
  ngacName: string;
  ngacEntityName: PermissionEntity;
  isAggregator?: boolean;
}

export interface PermissionByApplicationLink extends PermissionLink {
  permission: Array<PermissionResponse>;
}

export interface PermissionRequest {
  link: Array<PermissionLink>;
}

export interface PermissionResponse extends PermissionRequest {
  id: string;
  isAggregator?: boolean;
}

export class PermissionRequestModel {
  constructor(
    public user: string,
    public application?: string,
    public features: Array<UserAccess> = [],
    public entity?: PermissionEntity,
    public value?: string,
    public isAggregator = false,
    public isApplicationAggregator = false
  ) {}

  get permissionsRequest(): Array<PermissionRequest> {
    if (
      !(this.application && this.features.length && this.entity && this.value)
    ) {
      return [];
    }

    return this.features.map((feature) => ({
      link: [
        {
          ngacName: this.user,
          ngacEntityName: PermissionEntity.USER,
        },
        {
          ngacName: feature,
          ngacEntityName: PermissionEntity.FEATURE,
        },
        {
          ngacName: this.application as string,
          ngacEntityName: this.isApplicationAggregator
            ? PermissionEntity.APPLICATIONS
            : PermissionEntity.APPLICATION,
        },
        {
          ngacName: this.value as string,
          ngacEntityName: this.entity as PermissionEntity,
          isAggregator: this.isAggregator,
        },
      ],
    }));
  }

  get generalPermissionsRequest(): Array<PermissionRequest> {
    if (!(this.features.length && this.value)) {
      return [];
    }

    return this.features.map((feature) => ({
      link: [
        {
          ngacName: this.user,
          ngacEntityName: PermissionEntity.USER,
        },
        {
          ngacName: feature,
          ngacEntityName: PermissionEntity.FEATURE,
        },
        {
          ngacName: this.value as string,
          ngacEntityName: this.isAggregator
            ? PermissionEntity.APP_SECTIONS
            : PermissionEntity.APP_SECTION,
          isAggregator: this.isAggregator,
        },
      ],
    }));
  }
}

export class PermissionResponseModel {
  constructor(
    public id: string,
    public user: string,
    public application: string,
    public feature: UserAccess,
    public entity: PermissionEntity,
    public value: string,
    public isAggregator?: boolean
  ) {}
}

export interface PermissionResponseGroup {
  entity: PermissionEntity;
  value: string;
  permissions: Array<PermissionResponseModel>;
  isAggregator?: boolean;
}

export class PermissionAggregatorModel {
  constructor(
    public name: string,
    public aggregatorType: AppSectionAggregator | null,
    public type: PermissionEntity,
    public entities: Array<string>,
    public description: string = ""
  ) {}

  static of(model: PermissionAggregatorModel) {
    return new PermissionAggregatorModel(
      model.name,
      model.aggregatorType,
      model.type,
      model.entities,
      model.description
    );
  }

  static ofArray(models: Array<PermissionAggregatorModel>) {
    return models.map((model) => PermissionAggregatorModel.of(model));
  }
}

export interface PermissionEntityNames {
  ngacEntityName: PermissionEntity;
  ngacNames: Array<string>;
}

export interface PermissionAggregatorNames {
  ngacEntityName: PermissionEntity;
  aggregators: Array<{
    name: string;
    type: AppSectionAggregator | null;
  }>;
}

export interface PermissionFeatures {
  ngacName: string;
  features: Array<UserAccess>;
}

export class UserPermissionsByApplicationModel {
  constructor(
    public username: string,
    public firstName: string,
    public lastName: string,
    public permissionsByApplication: Array<PermissionByApplicationLink>
  ) {}

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  get simplifiedPermissions(): Array<PermissionResponseModel> {
    return this.permissionsByApplication.flatMap(({ permission }) =>
      permission.map((permission) =>
        PermissionUtil.responseToModel(permission, this.username)
      )
    );
  }

  static of(model: UserPermissionsByApplicationModel) {
    return new UserPermissionsByApplicationModel(
      model.username,
      model.firstName,
      model.lastName,
      model.permissionsByApplication
    );
  }

  static ofArray(models: Array<UserPermissionsByApplicationModel>) {
    return models.map((model) => UserPermissionsByApplicationModel.of(model));
  }
}
