import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosResponse } from "axios";

import axios from "@/shared/plugins/axios";
import SegmentModel, {
  SegmentDividerType,
  SegmentFileModel,
} from "@/segments/models/SegmentModel";
import {
  GetSegmentRequestModel,
  SegmentUniqueNameRequestModel,
  SegmentUniqueNameResponseModel,
  UpdateSegmentRequestModel,
  ConvertSegmentRequestModel,
} from "@/segments/models/SegmentRequestModel";
import SegmentGroupModel from "@/segments/models/SegmentGroupModel";
import {
  DividerFileResponse,
  GetDividerFileRequestModel,
  UploadDividerRequestModel,
} from "@/segments/models/SegmentGroupRequestModel";
import FromDataUtil from "@/shared/utils/FromDataUtil";

@Module
export default class SegmentStore extends VuexModule {
  loadingSegments = false;
  segments: Array<SegmentModel> = [];

  loadingSegment = false;
  segment: SegmentModel = new SegmentModel("");

  loadingDividers = false;
  dividers: Record<SegmentDividerType, SegmentGroupModel> = {
    [SegmentDividerType.REVENUE]: new SegmentGroupModel(),
  };

  loadingDividerFile = false;
  dividerFiles: Record<SegmentDividerType, SegmentFileModel> = {
    [SegmentDividerType.REVENUE]: new SegmentFileModel(),
  };

  isSegmentNameUnique = true;
  savingInProgress = false;

  @Mutation
  setLoadingSegments() {
    this.loadingSegments = true;
  }

  @Mutation
  setSegments(payload: Array<SegmentModel>) {
    this.segments = SegmentModel.ofArray(payload);
    this.loadingSegments = false;
  }

  @Mutation
  setLoadingSegment() {
    this.loadingSegment = true;
  }

  @Mutation
  patchSegment(payload: SegmentModel) {
    const segmentIndex = this.segments.findIndex(
      (segment) => segment.id === payload.id
    );

    if (segmentIndex !== -1) {
      this.segments.splice(segmentIndex, 1, SegmentModel.of(payload));
    }
  }

  @Mutation
  setSegment(payload: SegmentModel) {
    this.segment = SegmentModel.of(payload);
    this.loadingSegment = false;
  }

  @Mutation
  setLoadingDividers() {
    this.loadingDividers = true;
  }

  @Mutation
  setDividers(payload: Array<SegmentGroupModel>) {
    Object.values(SegmentDividerType).forEach((type) => {
      const divider = payload.find((segment) => segment.dividerType === type);
      if (divider) {
        this.dividers[type] = divider;
        this.dividerFiles[type] = new SegmentFileModel();
      }
    });
    this.loadingDividers = false;
  }

  @Mutation
  setLoadingDividerFile() {
    this.loadingDividerFile = true;
  }

  @Mutation
  setDividerFile(payload: DividerFileResponse) {
    this.dividerFiles[payload.type] = payload.file;
    this.loadingDividerFile = false;
  }

  @Mutation
  setIsSegmentNameUnique(payload: boolean) {
    this.isSegmentNameUnique = payload;
  }

  @Mutation
  setSavingInProgress(payload: boolean) {
    this.savingInProgress = payload;
  }

  @Mutation
  updateSegmentsAfterDelete(id: number) {
    this.segments = this.segments.filter((segment) => segment.id !== id);
  }

  @Action({ commit: "setSegments" })
  async fetchSegments(applicationId: string) {
    this.context.commit("setLoadingSegments");

    return axios
      .get(`/api/app/${applicationId}/segments`)
      .then((response: AxiosResponse<Array<SegmentModel>>) => response.data);
  }

  @Action({ commit: "setSegment" })
  async getSegment(payload: GetSegmentRequestModel) {
    this.context.commit("setLoadingSegment");
    return axios
      .get(`/api/app/${payload.applicationId}/segments/${payload.id}`)
      .then((result: AxiosResponse<SegmentModel>) => result.data);
  }

  @Action({ commit: "setDividers" })
  async getDividers(applicationId: string) {
    this.context.commit("setLoadingDividers");
    return axios
      .get(`/api/app/${applicationId}/segments/divider`)
      .then((result: AxiosResponse<SegmentGroupModel>) => result.data);
  }

  @Action({ commit: "setDividerFile" })
  async getDividerFile(payload: GetDividerFileRequestModel) {
    this.context.commit("setLoadingDividerFile");
    return axios
      .get(
        `/api/app/${payload.applicationId}/segments/divider/${payload.type}/file`
      )
      .then(
        (result: AxiosResponse<SegmentFileModel>) =>
          new DividerFileResponse(payload.type, result.data)
      );
  }

  @Action
  async createSegment(payload: SegmentModel) {
    this.context.commit("setSavingInProgress", true);

    return axios
      .post(`/api/app/${payload.applicationId}/segments/custom`, payload)
      .then((result: AxiosResponse<SegmentModel>) => {
        this.context.commit("addNotification", {
          message: {
            key: "segment.notification.createdSuccess",
            params: [payload.name],
          },
        });

        return result.data;
      })
      .finally(() => {
        this.context.commit("setSavingInProgress", false);
      });
  }

  @Action
  async createProvidedSegment(payload: SegmentModel) {
    this.context.commit("setSavingInProgress", true);

    const data = new FormData();
    FromDataUtil.appendFormDataPart(data, "name", payload.name);
    FromDataUtil.appendFormDataPart(data, "description", payload.description);
    FromDataUtil.appendFormDataPart(data, "type", payload.providedType);
    FromDataUtil.appendFormDataPart(data, "accessType", payload.accessType);
    FromDataUtil.appendFormDataPart(data, "file", payload.file);

    return axios
      .post(`/api/app/${payload.applicationId}/segments/provided`, data)
      .then((result: AxiosResponse<SegmentModel>) => {
        this.context.commit("addNotification", {
          message: {
            key: "segment.notification.createdSuccess",
            params: [payload.name],
          },
        });

        return result.data;
      })
      .finally(() => {
        this.context.commit("setSavingInProgress", false);
      });
  }

  @Action
  async updateSegment(payload: SegmentModel) {
    const updatePayload = new UpdateSegmentRequestModel(
      payload.description,
      payload.accessType
    );
    return axios
      .patch(
        `/api/app/${payload.applicationId}/segments/${payload.id}`,
        updatePayload
      )
      .then((result: AxiosResponse<SegmentModel>) => {
        this.context.commit("addNotification", {
          message: {
            key: "segment.notification.updatedSuccess",
            params: [payload.name],
          },
        });

        return result.data;
      })
      .finally(() =>
        this.context.dispatch("fetchSegments", payload.applicationId)
      );
  }

  @Action
  async uploadDivider(payload: UploadDividerRequestModel) {
    const data = new FormData();
    data.append("file", payload.file);
    data.append("type", payload.type);
    return axios
      .post(`/api/app/${payload.applicationId}/segments/divider`, data)
      .then((result: AxiosResponse<SegmentGroupModel>) => result.data)
      .finally(() =>
        this.context.dispatch("getDividers", payload.applicationId)
      );
  }

  @Action({ commit: "updateSegmentsAfterDelete" })
  async deleteSegment(payload: SegmentModel) {
    return axios
      .delete(`/api/app/${payload.applicationId}/segments/${payload.id}`)
      .then(() => {
        this.context.commit("addNotification", {
          message: {
            key: "segment.notification.deletedSuccess",
          },
        });

        return payload.id;
      });
  }

  @Action
  async recalculateSegment(payload: SegmentModel) {
    const path = `/api/app/${payload.applicationId}/segments/${payload.groupId}/recalculate`;
    return axios
      .post(path)
      .then((result: AxiosResponse<SegmentModel>) => result.data)
      .finally(() =>
        this.context.dispatch("fetchSegments", payload.applicationId)
      );
  }

  @Action({ commit: "patchSegment" })
  async convertSegment(payload: ConvertSegmentRequestModel) {
    return axios
      .patch(
        `/api/app/${payload.applicationId}/segments/${payload.id}/changeType`,
        payload
      )
      .then((result: AxiosResponse<SegmentModel>) => result.data);
  }

  @Action({ commit: "setIsSegmentNameUnique" })
  async checkIsSegmentNameUnique(payload: SegmentModel) {
    if (!payload.name) {
      return true;
    }

    const request = new SegmentUniqueNameRequestModel(payload.name.trim());

    return axios
      .post(`/api/app/${payload.applicationId}/segments/uniqueName`, request)
      .then(
        (result: AxiosResponse<SegmentUniqueNameResponseModel>) =>
          result.data.unique
      );
  }
}
