export default {
  home: {
    title: "BI Panel",
  },
  components: {
    templates: "Templates",
    platforms: "Platforms",
    segments: "Segments",
    funnels: "Funnels",
    dashboard: "Dashboard",
    dashboards: "Dashboards",
    country: "Country",
    attributionCountry: "Attribution Country",
    eventCountry: "Event Country",
    applications: "Applications",
    deviceType: "Device type",
    waterfallsId: "Waterfall Id",
    date: "Date",
    dayLimit: "Day limit",
    eventsDayLimit: "Events day limit",
    roundSessionsToSeconds: "Round sessions to seconds",
    search: "Search",
    minInstalls: "Min installs",
    maxInstalls: "Max installs",
    timeSpentType: "Time Spent Type",
    cohortMetricsDays: "Cohort Metrics Days",
    groupBy: {
      name: "Group by",
      aggregationPeriod: "Aggregation Period",
      country: "Attribution Country",
      events_country: "Event Country",
      source: "Source",
      sub_source: "Sub-Source",
      campaign: "Campaign",
      ad_set: "Ad Set",
      creative: "Creative",
      publisher: "Publisher",
      attribution_date: "Attribution Date",
      attribution_date_value: "Attribution Date",
      attribution_status: "Attribution Status",
      device_type: "Device Type",
      tracker: "Tracker",
      segment: "Segment",
      platform: "Platform",
      application: "Application",
      waterfalls_id: "Waterfall id",
      ad_network_name: "Revenue Source",
      lat: "LAT",
      events_app_version: "App Version",
      event_parameter: "Event Parameter",
      day: "Day",
      week: "Week",
      month: "Month",
      install_app_version: "Install App Version",
      events_os_version: "Events OS Version",
      metrics: "Metrics",
      ad_types: "Ad Types",
    },
    revenueType: "Revenue Type",
    adType: "Ad Type",
    event: "Event",
    attributionStatus: "Attribution Status",
    adRevenueMethod: "Ad Revenue Method",
    adRevenueMethodPicker: {
      priced: "Priced",
      average: "Average",
    },
    metricType: "Metric Type",
    metricTypePicker: {
      cohort: "Cohort",
      daily: "Daily",
    },
    metrics: {
      play_time: "PlayTime",
      time_spent: "TimeSpent",
    },
    usersActivityType: "Users",
    usersActivityTypePicker: {
      all: "All",
      ad_active: "AdActive",
    },
    reportType: "Report Type",
    eventName: {
      name: "Event Name",
      paramsForGroupBy: "Event Params For Group by",
      whoGenerated: "Who Generated",
      viewStatsFor: "View stats for",
    },
    eventAppVersion: "Event App Version",
    installAppVersion: "Install App Version",
    providedBanner: "Banners' Provided",
    providedBannerPicker: {
      adjust: "Adjust",
      firebase: "Firebase",
    },
    baseTable: {
      gradient: "Gradient",
      revenue: "Revenue",
      revenueHint: "Revenue from subscription",
      dau: "DAU",
      wau: "WAU",
      mau: "MAU",
      arpdau: "ARPDAU",
      arpdauAd: "ARPDAU Ad",
      arpdauIap: "ARPDAU IAP",
      banner: "Banner",
      interstitial: "Interstitial",
      rewarded: "Rewarded",
      subscription: "Subscription",
      gradientUnlock: "Gradient unlock",
      gradientHorizontal: "Gradient horizontal",
      activeDevices: "Active Devices",
      installs: "Installs",
      day: "Day {0}",
      arpu_365: "ARPU 365",
      timeSpent: "TS, day {0}",
      timeSpent_3: "TS, day 3",
      timeSpent_7: "TS, day 7",
      timeSpent_14: "TS, day 14",
      timeSpent_21: "TS, day 21",
      timeSpent_30: "TS, day 30",
      returnRate_3: "RR, day 3",
      returnRate_7: "RR, day 7",
      returnRate_14: "RR, day 14",
      returnRate_21: "RR, day 21",
      returnRate_30: "RR, day 30",
      returnRate: "RR, day {0}",
      total: "Total",
      inApps: "IAP Revenue",
      iapRevenue: "IAP Revenue",
      totalAdRevenue: "Total Ad Revenue",
      totalOccurrences: "Total Occurrences",
      avgEventPerDevice: "Avg Event per Device",
      avgEventsPerSession: "Avg Events per Session",
      occurrencesDailyAvg: "Avg Events per Device",
      uniqueDevicesDailyAvg: "Unique Devices",
      eventsBanner: "Events Banner",
      perMinBanner: "Permin Banner",
      storeInstalls: "Store Installs",
      adjustInstalls: "Adjust Installs",
      paidInstalls: "Paid Installs",
      organic: "Organic",
      percentOrganic: "% Organic",
      cpi: "CPI",
      ecpiAdjust: "eCPI Adjust",
      spend: "Spend",
      impressions: "Impressions",
      clicks: "Clicks",
      ctr: "CTR, %",
      ir: "IR, %",
      cr: "CR, %",
      cpm: "CPM",
      totalUsers: "Total users",
      installed: "Installed",
      reattributed: "Rtd",
      maxObservedDays: "D (N)",
      payingUsers: "PU",
      payingConv: "Conv",
      revenueValue: "Rev, $",
      adRevenue: "Ad Revenue, %",
      arpu: "ARPU",
      arpuUsd: "ARPU, $",
      arppu: "ARPPU, $",
      spendValue: "Spend, $",
      profit: "Profit",
      profitUsd: "Profit, $",
      eCpi: "eCPI, $",
      roas: "ROAS",
      roasPercent: "ROAS N-day, %",
      retention: "RR",
      playtime: "TS (m)",
      cohortDate: "Cohort date",
      bannerImpressions: "Banner Impressions",
      bannerCPM: "Banner CPM",
      interstitialImpressions: "Interstitial Impressions",
      interstitialCPM: "Interstitial CPM",
      interstitialNeededRate: "Interstitial Needed Rate %",
      rewardedImpressions: "Rewarded Impressions",
      rewardedCPM: "Rewarded CPM",
      rewardedNeededRate: "Rewarded Needed Rate %",
      name: "Name",
      requests: "Requests",
      fillRate: "Fill Rate, %",
      ecpm: "eCPM",
      cpc: "CPC",
      dau_e: "DAUe",
      arpdau_e: "ARPDAUe",
      dau_b: "DAUb",
      banner_e: "ARPDAUb",
      dau_i: "DAUi",
      interstitial_e: "ARPDAUi",
      dau_r: "DAUr",
      rewarded_e: "ARPDAUr",
      cpa_: "CPA {0}",
      adjustPercent_: "% users {0}",
      adjustCount_: "# users {0}",
      payoutDate: "Payout Date",
      payout: "Payout",
      month: "Month",
      paymentTerms: "Payment Terms",
      source: "Source",
      bannerClicks: "Banner Clicks",
      bannerCtr: "Banner CTR",
      interstitialClicks: "Interstitial Clicks",
      interstitialCtr: "Interstitial CTR",
      rewardedClicks: "Rewarded Clicks",
      rewardedCtr: "Rewarded CTR",
      cpiPaid: "CPI Paid",
      cpiNetw: "CPI Netw",
      eCPI: "eCPI",
      cashPaid: "Cash Paid",
      cashTotal: "Cash Total",
      roiPaid: "ROI Paid",
      roi: "ROI",
      rawData: "Raw Data",
      storeData: "Store",
      deltaInstallRawStore: "Raw Data-Store",
      percentInstallRawStore: "Raw Data-Store, %",
      cpiAdjust: "CPI Adjust",
      organicAdjust: "Organic Adjust",
      organicAdjustPercent: "% Organic Adjust",
      purchases: "Purchases",
      purchasesHint: "Number of purchased subscriptions",
      cppu: "CPPU",
      payingUsersPercent: "% Paying User",
      roasDayNumber: "ROAS D#",
      cpa: "CPA",
      netwInstalls: "Installs Netw",
      net: "NET ",
      installsPercent: "Installs, %",
      skadInstalls: "SKAd Installs",
      downloads: "Downloads",
      redownloads: "Redownloads",
      crPercent: "CR, %",
      engagement: "Eng-t, day {0}",
      value: "Value, day {0}",
      event: {
        c_null: "C null",
        c_0: "C 0",
        e_1: "E 1",
        e_2: "E 2",
        e_3: "E 3",
        e_4: "E 4",
        e_5: "E 5",
        e_6: "E 6",
      },
      eventPercent: {
        c_null: "Per event null, %",
        c_0: "Per event 0, %",
        e_1: "Per event 1, %",
        e_2: "Per event 2, %",
        e_3: "Per event 3, %",
        e_4: "Per event 4, %",
        e_5: "Per event 5, %",
        e_6: "Per event 6, %",
      },
      PU: "Paying Users, day {0}",
      PU_percents: "Paying Users %, day {0}",
      PC: "Purchases, day {0}",
      APPPU: "APPPU, day {0}",
      IAP: "IAP ROAS %, day {0}",
      TOT: "Total ROAS %, day {0}",
      bannerRequests: "Banner Requests Events",
      bannerFailedEvents: "Banner Failed Events",
      bannerFailedUsers: "Banner Failed Users",
      bannerLoadedEvents: "Banner Loaded Events",
      bannerLoadedUsers: "Banner Loaded Users",
      bannerFillRate: "Banner Fill Rate, %",
      bannerRequestsEvents: "Banner Requests Events",
      bannerEvents: "Banner Events",
      bannerRequestsPerDau: "Banner Requests Per DAU",
      bannerPerDau: "Banner Per DAU",
      bannerRequestsEventsPerDau: "Banner Requests Per DAU",
      bannerImpressionsEventsPerDau: "Banner Impressions Per DAU",
      bannerImpressionsEvents: "Banner Impressions",
      interFailedEvents: "Inter Failed Events",
      interFailedUsers: "Inter Failed Users",
      interLoadedEvents: "Inter Loaded Events",
      interLoadedUsers: "Inter Loaded Users",
      interRequestsEvents: "Inter Requests Events",
      interRequestsUsers: "Inter Requests Users",
      interFillRate: "Inter Fill Rate, %",
      interImpressionsEvents: "Inter Impressions Events",
      interRequestsPerDau: "Inter Requests Per DAU",
      interPerDau: "Inter Per DAU",
      interRequestsEventsPerDau: "Inter Requests Per DAU",
      interImpressionsEventsPerDau: "Inter Impressions Per DAU",
      rewardFailedEvents: "Reward Failed Events",
      rewardFailedUsers: "Reward Failed Users",
      rewardLoadedEvents: "Reward Loaded Events",
      rewardLoadedUsers: "Reward Loaded Users",
      rewardRequestsEvents: "Reward Requests Events",
      rewardRequestsUsers: "Reward Requests Users",
      rewardFillRate: "Reward Fill Rate, %",
      rewardImpressionsEvents: "Reward Impressions Events",
      rewardRequestsPerDau: "Reward Requests Per DAU",
      rewardPerDau: "Reward Per DAU",
      rewardRequestsEventsPerDau: "Reward Requests Per DAU",
      rewardImpressionsEventsPerDau: "Reward Impressions Per DAU",
      spendUsd: "Spend USD",
      country: "Country",
      adType: "Ad Type",
      network: "Network",
      diffRevenueDay: "Diff Revenue Day",
      diffImpressionsDay: "Diff Impressions Day",
      adNetworkName: "Ad Network Name",
      diffRevenuePrevDay: "Diff Revenue Prev Day",
      diffImpressionsPrevDay: "Diff Impressions Prev Day",
      diffRevenuePrevYear: "Diff Revenue Prev Year",
      diffImpressionsPrevYear: "Diff Impressions Prev Year",
      metrics: {
        arpu: "ARPU, day {0}",
        arppu: "ARPPU, day {0}",
        payingUsers: "PU, day {0}",
        returnRate: "RR, day {0}",
        timeSpent: "TS, day {0}",
        roas: "ROAS %, day {0}",
      },
      bannerEventsFb: "Banner Events FB",
      bannerEventsNet: "Banner Events NET",
      bannerEventsDiff: "Banner Events DIFF",
      bannerRevenueFb: "Banner Revenue FB",
      bannerRevenueNet: "Banner Revenue NET",
      bannerRevenueDiff: "Banner Revenue DIFF",
      interstitialShowEventsFb: "Inter Show Events FB",
      interstitialEventsFb: "Inter Events FB",
      interstitialEventsNet: "Inter Events NET",
      interstitialEventsDiff: "Inter Events DIFF",
      interstitialRevenueFb: "Inter Revenue FB",
      interstitialRevenueNet: "Inter Revenue NET",
      interstitialRevenueDiff: "Inter Revenue DIFF",
      rewardedShowEventsFb: "Reward Show Events FB",
      rewardedEventsFb: "Reward Events FB",
      rewardedEventsNet: "Reward Events NET",
      rewardedEventsDiff: "Reward Events DIFF",
      rewardedRevenueFb: "Reward Revenue FB",
      rewardedRevenueNet: "Reward Revenue NET",
      rewardedRevenueDiff: "Reward Revenue DIFF",
      exclude: "Exclude",
      include: "Include",
      selectToExclude: "Select {0} to exclude",
      subscription_name: "SKU",
      subscriptionNameHint: "Subscription name / SKU",
      offer_id: "Offer",
      offerIdHint: "Offer ID",
      psur: "PSU, %",
      psurHint:
        "Share of users paid for subscription of Total Paid Subscribers",
      pppsu: "PPPSU",
      pppsuHint: "Number of purchased subscriptions per Paid Subscriber",
      revenueRate: "Revenue, %",
      revenueRateHint:
        "Share of Revenue from subscription of Total Revenue from subscriptions",
      renewalRate: "SSR, %",
      renewalRateHint:
        "Subscription Renewal Rate - share of Renewal Subscribers of Paid Subscribers",
      rprsu: "RPRSU",
      rprsuHint: "Number of Renewals per Renewal Subscribers",
      srr: "SRR",
      srrHint:
        "Subscription Renewal Rate - share of Renewal Subscribers of Paid Subscribers",
      inactiveSrr: "Inactive SRR, %",
      inactiveSrrHint:
        "Subscription Renewal Rate for Inactive Subscribers - share of Inactive Renewal Subscribers of Paid Subscribers ",
      agsu: "AGSU",
      agsuHint: "Number of users in Active Grace Period on Day N",
      isu: "ISU",
      isuHint: "Number of users with inactive subscription on Day N",
      isur: "ISU, %",
      isurHint: "Share of Inactive Subscribers",
      pausedSu: "Paused SU",
      pausedSuHint: "Number of users with paused subscription on Day N",
      holdSu: "Hold SU",
      holdSuHint: "Number of users with subscription on hold state on Day N",
      canceledSu: "Canceled SU",
      canceledSuHint: "Number of users with canceled subscription on Day N",
      finishedSu: "Finished SU",
      finishedSuHint: "Number of users with finished subscription on Day N",
      refundsRate: "Refunds, %",
      refundsRateHint: "Share of Refunded subscriptions",
      su: "SU",
      suHint: "Total number of Subscribers",
      asu: "ASU",
      asuHint: "Number of Active Subscribers on Day N",
      asur: "ASU, %",
      asurHint: "Share of Active Subscribers",
      psu: "PSU",
      psuHint: "Number of users paid for subscription",
      apsu: "APSU",
      apsuHint: "Number of Active Subscribers with paid subscription on Day N",
      apsur: "APSUR, %",
      apsurHint: "Share of Active Paid Subscribers of Paid Subscribers",
      rsu: "RSU",
      rsuHint: "Number of Renewal Subscribers",
      inactiveRsu: "Inactive RSU",
      inactiveRsuHint:
        "Number of Renewal Subscribers who were Inactive during previous subscribe period",
      renewals: "Renewals",
      renewalsHint: "Number of Renewals",
      refunds: "Refunds",
      refundsHint: "Number of Refunded subscriptions",
      refundedAmount: "Refunds, $",
      refundedAmountHint: "Refunded amount",
      revenueEst: "Est. Revenue",
      revenueEstHint: "Full (non confirmed, offers ignored) revenue",
      tsu: "TSU",
      tsuHint: "Number of Trial Subscribers",
      tasu: "TASU",
      tasuHint: "Number of Trial Active Subscribers on Day N",
      tesu: "TESU",
      tesuHint: "Number of Trial Ended Subscribers on Day N",
      tpsu: "TPSU",
      tpsuHint: "Number of Subscribers Paid after Trial",
      tcr: "TCR, %",
      tcrHint: "Trial Churn Rate",
      deltaFromMax: "Diff from max in column:",
      deltaFromMin: "Diff from min in column:",
      deltaFromInstalls: "Diff from largest cohort:",
    },
    baseChart: {
      loadingText: "Loading chart...",
    },
    btn: {
      copy: "Copy",
      copyTSV: "Copy TSV",
      csv: "CSV",
      tsv: "TSV",
      excel: "Excel",
      pdf: "PDF",
    },
    cumulative: "Cumulative",
    include_iaps: "Include IAPs",
    exclude_no_internet: 'Exclude "No Internet"',
    net_revenue: "Net Revenue",
    excludeNoInternet: 'Exclude "No Internet"',
    show_unique_devices: "Show unique devices",
  },
  views: {
    titleFilters: "{0} Filters",
    titleResult: "{0} Result",
    titleData: "{0} Data",
    titleTotal: "{0} Total",
    titleSubTotal: "{0} SubTotal",
    titleMonthTotal: "{0} Months total",
    filters: "Filters",
    generate: "Generate",
    cancelReport: "Cancel calculation",
    saveAsTemplate: "Save as template",
    table: "Table",
    graphs: "Graphs",
    graphsHint:
      "To view graphs, select less than 2 options in the Group by filter",
    groupByCountry: "Group by country",
    netRevenue: "Net Revenue",
    emptyReport: "Fill filters to see the report",
    groupBySingle: {
      country: "Group by country",
    },
    measures: {
      events_count: "Events Count",
      events_delta_per_user: "Events Delta Per User",
      events_delta_per_user_cumulative: "Events Cumulative Per User",
      events_delta_per_active_user: "Events Delta Per Active User",
      unique_users_count: "Count Unique Users",
      unique_users_percent: "Percent Unique Users",
    },
    timeSpent: {
      cumulative_play_time: "PlayTime Cumulative",
      play_time: "PlayTime Deltas",
      cumulative_time_spent: "TimeSpent Cumulative",
      time_spent: "TimeSpent Deltas",
    },
    perMin: {
      play_time: "PlayTime",
      time_spent: "TimeSpent",
      banner: "Banner",
      interstitial: "Interstitial",
      rewarded: "Rewarded",
      cumulative_time_spent: "TimeSpent Cumulative",
    },
    cohortAnalysisHeading: {
      total: "Total",
      sub_total: "Top campaigns by ARPU",
      campaigns_relationship: "Campaigns ARPU/eCPI relationship scatter plot",
      dau_dynamics: "DAU dynamics",
      revenue_spendings_daily_dynamics: "Revenue, Spendings daily dynamics",
      cumulated_revenue_spendings_profit:
        "Cumulated Revenue, Spendings, Profit",
      cumulated_arpu_structure: "Cumulated ARPU structure",
      cumulated_arpu: "Cumulated ARPU",
      roas_n_day: "ROAS N Day",
      retention_rate: "Retention Rate",
      cumulated_playtime_per_active_user: "Cumulated Playtime per Active user",
    },
    waterfalls: {
      title: "Waterfall",
      forSsb: "For SSB",
      charts: "Charts",
    },
    includeIaps: "Include IAPs",
    conversionValue: {
      title: "Conversion Value",
      c_null: "C null",
      c_0: "C 0",
      e_1: "E 1",
      e_2: "E 2",
      e_3: "E 3",
      e_4: "E 4",
      e_5: "E 5",
      e_6: "E 6",
    },
    skadReportType: {
      title: "Report Type",
      per_events: "Per Events",
      funnel: "Funnel",
    },
    metrics: {
      title: "Metrics",
      addMetric: "Add Metric",
      newCustomMetricTitle: "New custom metric",
      editCustomMetricTitle: "Edit custom metric",
      copyCustomMetricTitle: "Copy custom metric",
      createOneMoreMetric: "Create one more metric",
      metricName: "Metric name",
      basicMetricsTitle: "Basic metrics",
      basicMetricsText: "Choose your metric to construct custom metric",
      resultSettingsTitle: "Result settings",
      resultSettingsText: "Set up some result settings",
      calculationSettingsTitle: "Calculation settings",
      calculationSettingsText: "Set up some calculation settings",
      basicMetrics: {
        installs_count_adjust: "Installs count adjust",
        installs_count_network: "Installs count network",
        spend: "Spend",
        revenue: "Revenue",
        revenue_events_count: "Revenue events count",
        revenue_active_users_count: "Revenue active users count",
        time_spent: "Time spent",
        daily_active_users_count: "Daily active users count",
        predicted_revenue: "Predicted Revenue",
      },
      type: "Type",
      revenueTypes: "Revenue Types",
      revenueMethod: "Ad Revenue Method",
      eventTypes: "Event types",
      bannerProvider: "Banners' Provided",
      timeSpentType: "Time spent type",
      cohort: "Cohort",
      cumulative: "Cumulative",
      firstOperand: "First operand",
      secondOperand: "Second operand",
      operands: "Operands",
      operation: "Operation",
      formatter: "Formatter",
      aggregatorStrategy: "Total / SubTotal calculation",
      days: "Days",
      calculationMode: "Calculation mode",
      full: "Full",
      pyramid: "Pyramid",
      sum: "Sum",
      avg: "Avg",
      weighted_average: "Weighted average",
      conditional_round_more_than_100_number_formatter: "Conditional rounding",
      conditionalRoundDesc:
        "if < 100 then two decimal places, if ≥ 100 then no decimal places",
      zero_digits_number_formatter: "No decimal places",
      two_digits_number_formatter: "Two decimal places",
      four_digits_number_formatter: "Four decimal places",
      addCustomMetric: "Add custom metric",
      addPredefinedMetric: "Add predefined metric",
      newPredefinedMetric: "New predefined metric",
      editPredefinedMetric: "Edit predefined metric",
      setUpAnotherMetric: "Setting up another basic metric",
      metric: "Metric",
      dayPlaceholder: "Create new or choose day",
      tableHeader: {
        installs_count_adjust: "Installs Adjust",
        installs_count_network: "Installs Network",
        spend: "Spend",
        cpi_adjust: "CPI Adjust",
        cpi_network: "CPI Network",
        cps: "CPS",
        cpsDayN: "CPS, day {0}",
        subscription_count: "Subscription all",
        subscription_countDayN: "Subscription all, day {0}",
        subscription_users_count: "Subscription first",
        subscription_users_countDayN: "Subscription first, day {0}",
        in_app_purchase_count: "Total purchases (in app)",
        in_app_purchase_countDayN: "Total purchases (in app), day {0}",
        in_app_paying_users_count: "Unique paying users (in app)",
        in_app_paying_users_countDayN: "Unique paying users (in app), day {0}",
        average_subscription: "Avg. subscription # per user",
        average_subscriptionDayN: "Avg. subscription # per user, day {0}",
        average_purchase: "Avg. Purchase # per user",
        average_purchaseDayN: "Avg. Purchase # per user, day {0}",
        conversion_to_subscription_user:
          "Conv. to Paying User % (subscription)",
        conversion_to_subscription_userDayN:
          "Conv. to Paying User % (subscription), day {0}",
        conversion_to_in_app_paying_user: "Conv. to Paying User % (in app)",
        conversion_to_in_app_paying_userDayN:
          "Conv. to Paying User % (in app), day {0}",
        roas_subscription: "ROAS % subscription",
        roas_subscriptionDayN: "ROAS % subscription, day {0}",
        roas_in_app: "ROAS % in app",
        roas_in_appDayN: "ROAS % in app, day {0}",
        roas_subscription_in_app: "ROAS % subscription + in app",
        roas_subscription_in_appDayN: "ROAS % subscription + in app, day {0}",
        revenue_subscription: "Revenue subscription",
        revenue_subscriptionDayN: "Revenue subscription, day {0}",
        revenue_in_app: "Revenue in app",
        revenue_in_appDayN: "Revenue in app, day {0}",
        revenue_subscription_in_app: "Revenue subscription + in app",
        revenue_subscription_in_appDayN:
          "Revenue subscription + in app, day {0}",
        arppu: "ARPPU",
        arppuDayN: "ARPPU, day {0}",
        cac: "CAC",
        cacDayN: "CAC, day {0}",
        time_spent: "Time spent per cohort",
        time_spentDayN: "Time spent per cohort, day {0}",
        play_time: "Play time per cohort",
        play_timeDayN: "Play time per cohort, day {0}",
        time_spent_cumulative: "Time spent per cohort cumulative",
        time_spent_cumulativeDayN: "Time spent per cohort cumulative, day {0}",
        play_time_cumulative: "Play time per cohort cumulative",
        play_time_cumulativeDayN: "Play time per cohort cumulative, day {0}",
        arpdau_daily: "ARPDAU Daily",
        arpdau_cohort: "ARPDAU Cohort",
        arpdau_cohortDayN: "ARPDAU Cohort, day {0}",
        return_rate: "Return Rate",
        return_rateDayN: "Return Rate, day {0}",
        revenue_ad: "Revenue ad",
        revenue_adDayN: "Revenue ad, day {0}",
        revenue_cohort: "Revenue Cohort",
        revenue_cohortDayN: "Revenue Cohort, day {0}",
        revenue_daily: "Revenue Daily",
        roas_ad: "ROAS % ad",
        roas_adDayN: "ROAS % ad, day {0}",
        roas: "ROAS % total",
        roasDayN: "ROAS % total, day {0}",
        arpu: "ARPU",
        arpuDayN: "ARPU, day {0}",
        arpu_cumulative: "ARPU cumulative",
        arpu_cumulativeDayN: "ARPU cumulative, day {0}",
        conversion_to_subscription_in_app_paying_user:
          "Conv. to Paying User % (subscription + in app)",
        conversion_to_subscription_in_app_paying_userDayN:
          "Conv. to Paying User % (subscription + in app), day {0}",
        daily_active_users_count: "DAU",
        life_time_value: "LTV",
        life_time_valueDayN: "LTV, day {0}",
        life_time_value_per_user: "LTV per user",
        life_time_value_per_userDayN: "LTV per user, day {0}",
        cpm: "CPM",
        cr: "CR %",
        ir: "IR %",
        ctr: "CTR %",
        impressions: "Impressions",
        clicks: "Clicks",
      },
    },
  },
  reportTemplatesHeading: "Report templates",
  openReportTemplate: "Open report template",
  chartDialogHeading: "{0} chart ({1})",
  chartByGroupTooltip: "Show chart for this group",
  chartTotal: "Total",
  cohortChart: {
    firstDay: "1st day",
    thirdDay: "3rd day",
    seventhDay: "7th day",
    fourteenthDay: "14th day",
    twentyfirstDay: "21st day",
    thirtiethDay: "30th day",
  },
  filterPreview: {
    events_date: "Date",
    group_by: "Group by",
    country: "Country",
    user_activity: "Users",
    ad_revenue_method: "Ad revenue method",
    provided_banner: "Provided banner",
    segment: "Segment",
    device_type: "Device Type",
    source: "Source",
    sub_source: "Sub Source",
    campaign: "Campaign",
    ad_set: "Ad Set",
    creative: "Creative",
    publisher: "Publisher",
    attribution_status: "Attribution status",
    events_country: "Events country",
    events_app_version: "Events app version",
    aggregation_period: "Aggregation period",
    generated_event: "Who generated event",
    generated_event_param: "Event Params",
    generated_event_param_date: "Event Param Date",
    event: "View stats for event",
    event_param: "Event Params",
    waterfalls_id: "Waterfall Id",
    ad_network_name: "Revenue source",
    lat: "LAT",
    applications: "Applications",
    platforms: "Platforms",
    day_limit: "Day limit",
    event_simple: "Event",
    cumulative: "Cumulative",
    attribution_date_value: "Date",
    revenue: "Revenue",
    report_type_time_spent: "Report type",
    time_spent_type: "Time spent type",
    report_type_cohort_permin: "Report type",
    report_type_permin: "Report type",
    report_type_measures_lite: "Report type",
    install_app_version: "Install App Version",
    ad_type: "Ad Type",
    events_os_version: "OS Version",
    net_revenue: "Net Revenue",
    gross_revenue: "Gross Revenue",
    attribution_date: "Attribution date",
    additional_grouping: "Additional grouping",
    event_cost: "Events",
    events_day_limit: "Events day limit",
    round_sessions_to_seconds: "Round sessions to seconds",
    start_day: "Start Day",
    end_day: "End Day",
    goal: "Goal",
    target_day_mode: "Target day mode",
    target_day: "Target day",
    conversion_value: "Conversion value",
    skad_report_type: "Report type",
    include_iaps: "Include IAPs",
    exclude_no_internet: 'Exclude "No internet"',
    os_version: "OS Version",
    iap_revenue: "Iap Revenue",
    ad_revenue: "Ad Revenue",
    device_model: "Device Model",
    app_version: "App Version",
    language: "Language",
    and: "and",
    or: "or",
    value: "Value",
    like: "like",
    not_like: "not like",
    event_name: "Event name",
    event_params: "Event params",
    from: "From",
    session_number_period: "Session number period",
    event_combination: "Event combination",
    event_range: "Event range",
    events: "Events",
    network_names: "Network names",
    sessions_count: "Sessions count",
    accrual_date_value: "Accrual Period",
    user_age: "Days Since Install",
    subscription_renewal_count: "SRC",
    revenue_type: "Revenue type",
  },
  reportSource: {
    title: "Data sources",
    adjust_raw_events: "Adjust Raw Events",
    adjust_attribution: "Adjust Attribution",
    networks_revenue: "Networks' Revenue",
    networks_spend: "Networks' Spend",
    firebase_raw_events: "Firebase Raw Events",
    stores_revenue: "Stores' Revenue",
    adjust_skad_events: "Skad Events",
    networks_skad: "Networks' Skad",
    stores_installs: "Stores' Installs",
    firebase_events: "Firebase Events",
    appsflyer_attribution: "Appsflyer Attribution",
  },
  chartCondition: {
    title: "To build a chart you need:",
    aggregation_period: "Select aggregation period",
    event: 'Select event in section "View stats for"',
    noDataFoundForThePeriod: "No data found for the period",
    noDataFound: "No data found for {0} {1}",
    group_by_less_than_two_filters: "Group by less than two filters",
    event_filter_and_agg_period:
      "Set Event name (View stats for) and Aggregation period filters or empty Aggregation period and Group by filters",
  },
  filterPanel: {
    fix: "Fix filters<br> Set selected filters to other reports by default",
    overlap: "Filter panel overlap mode",
    close: "Close",
    show: "Show filter panel",
    makeVertical: "Place filters vertically",
    makeHorizontal: "Place filters horizontally",
    apply: "Apply",
  },
  reportVisualizationName: {
    total: "Total",
    sub_total: "SubTotal",
    data: "Results",
    dau_dynamics: "Dau Dynamics",
    revenue_spendings_daily_dynamics: "Revenue, Spendings daily dynamics",
    cumulated_revenue_spendings_profit: "Cumulated Revenue, Spendings, Profit",
    cumulated_arpu_structure: "Cumulated ARPU structure",
    cumulated_arpu: "Cumulated ARPU",
    cumulated_arpu_cohort: "Cumulated ARPU Cohort",
    roas_n_day: "ROAS N Day",
    roas_n_day_cohort: "ROAS N Day Cohort",
    retention_rate: "Retention Rate",
    retention_rate_cohort: "Retention Rate Cohort",
    cumulated_playtime_per_active_user: "Cumulated Playtime per Active user",
    cumulated_playtime_per_active_user_cohort:
      "Cumulated Playtime per Active user Cohort",
    ssb: "SSB",
    ssb_chart: "SSB Chart",
    month_total: "Month Total",
    topCampaignsByArpu: "Top campaigns by ARPU",
    charts: "Charts",
    monitoring: "Monitoring",
    noMonitoringLabel: "Don't calculate monitoring table",
    revenueDiffPercentDateCountryBasedDiffRevenueDay:
      "Revenue diff: Priced vs Networks — Countries",
    revenueDiffPercentDateCountryBasedDiffImpressionsDay:
      "Impressions diff: Priced vs Networks — Countries",
    revenueDiffPercentDateNetworkBasedDiffRevenueDay:
      "Revenue diff: Priced vs Networks — Networks",
    revenueDiffPercentDateNetworkBasedDiffImpressionsDay:
      "Impressions diff: Priced vs Networks — Networks",
    arpdauDiffDateCountryBasedDiffRevenuePrevDay:
      "ARPDAU diff: Previous day — Countries",
    arpdauDiffDateCountryBasedDiffImpressionsPrevDay:
      "AIPDAU diff: Previous day — Countries",
    arpdauDiffDateCountryBasedDiffRevenuePrevYear:
      "ARPDAU diff: Previous year — Countries",
    arpdauDiffDateCountryBasedDiffImpressionsPrevYear:
      "AIPDAU diff: Previous year — Countries",
    arpdauDiffDateNetworkBasedDiffRevenuePrevDay:
      "ARPDAU diff: Previous day — Networks",
    arpdauDiffDateNetworkBasedDiffImpressionsPrevDay:
      "AIPDAU diff: Previous day — Networks",
    arpdauDiffDateNetworkBasedDiffRevenuePrevYear:
      "ARPDAU diff: Previous year — Networks",
    arpdauDiffDateNetworkBasedDiffImpressionsPrevYear:
      "AIPDAU diff: Previous year — Networks",
  },
  currency: "Currency: $USD",
  reportInfo: "Click here to open report FAQ",
  search: {
    label: "Input text and press Enter to perform search",
    properties: "Properties",
    includedColumnsInSearch: "Included columns in search",
    impreciseComparison: "Imprecise comparison",
    searchAllOver: "Search all over",
    press: "Press",
    enter: "Enter",
    toSearchAllOver: " to search all over",
    toSearchByColumn: " to apply <strong>{0}</strong> column filter",
    greaterThan: "greater than",
    lessThan: "less than",
  },
};
