import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosResponse } from "axios";

import axios from "@/shared/plugins/axios";
import PlacementResponseModel, {
  PlacementIdUniqueReponseModel,
} from "@/settings/models/placements/PlacementResponseModel";
import PlacementRequestModel from "@/settings/models/placements/PlacementRequestModel";
import NetworkIntegrationApplicationResponseModel from "../models/placements/NetworkIntegrationApplicationResponseModel";

@Module
export default class PlacementsStore extends VuexModule {
  placements: Array<PlacementResponseModel> = [];
  networkIntegrationApplications: Array<NetworkIntegrationApplicationResponseModel> =
    [];
  placement: PlacementResponseModel | null = null;
  isSavingInProgress = false;
  isLoading = true;
  isPlacementDeleting = false;
  isPlacementIdUnique = true;
  isSyncWithAdCascadeInProgress = false;

  @Mutation
  setPlacements(payload: Array<PlacementResponseModel>) {
    this.placements = PlacementResponseModel.ofArray(payload);
  }

  @Mutation
  setPlacement(payload: PlacementResponseModel) {
    this.placement = PlacementResponseModel.ofSource(payload);
  }

  @Mutation
  setNetworkIntegrationApplications(
    payload: Array<NetworkIntegrationApplicationResponseModel>
  ) {
    this.networkIntegrationApplications =
      NetworkIntegrationApplicationResponseModel.ofArray(payload);
  }

  @Mutation
  setIsLoading(payload: boolean) {
    this.isLoading = payload;
  }

  @Mutation
  setIsPlacementDeleting(payload: boolean) {
    this.isPlacementDeleting = payload;
  }

  @Mutation
  setIsSavingInProgress(payload: boolean) {
    this.isSavingInProgress = payload;
  }

  @Mutation
  setPlacementIdUniqueness(payload: boolean) {
    this.isPlacementIdUnique = payload;
  }

  @Mutation
  setIsSyncWithAdCascadeInProgress(payload: boolean) {
    this.isSyncWithAdCascadeInProgress = payload;
  }

  @Mutation
  updatePlacements(id: number) {
    this.placements = this.placements.filter(
      (placement) => placement.id !== id
    );
  }

  @Action({ commit: "setPlacements" })
  async loadPlacements(applicationId: string) {
    this.context.commit("setIsLoading", true);

    return axios
      .get(`/api/third-party/developer/app/${applicationId}/placements`)
      .then(
        (result: AxiosResponse<Array<PlacementResponseModel>>) => result.data
      )
      .finally(() => {
        this.context.commit("setIsLoading", false);
      });
  }

  @Action({ commit: "setPlacement" })
  async loadPlacementById(id: string) {
    this.context.commit("setIsLoading", true);

    return axios
      .get(`/api/third-party/developer/placements/${id}`)
      .then((result: AxiosResponse<PlacementResponseModel>) => result.data)
      .finally(() => {
        this.context.commit("setIsLoading", false);
      });
  }

  @Action({ commit: "setNetworkIntegrationApplications" })
  async loadNetworkIntegrationApplications({
    applicationId,
    params,
  }: {
    applicationId: string;
    params?: Record<string, any>;
  }) {
    return axios
      .get(
        `/api/third-party/developer/app/${applicationId}/network-integration-applications`,
        { params }
      )
      .then(
        (result: AxiosResponse<NetworkIntegrationApplicationResponseModel>) =>
          result.data
      );
  }

  @Action({ commit: "updatePlacements" })
  async deletePlacement(id: number) {
    this.context.commit("setIsPlacementDeleting", true);

    return axios
      .delete(`/api/third-party/developer/placements/${id}`)
      .then(() => {
        return id;
      })
      .finally(() => {
        this.context.commit("setIsPlacementDeleting", false);
      });
  }

  @Action
  async createPlacement(payload: PlacementRequestModel) {
    this.context.commit("setIsSavingInProgress", true);

    return axios
      .post(`/api/third-party/developer/placements`, payload)
      .then((result: AxiosResponse<PlacementResponseModel>) => {
        this.context.commit("addNotification", {
          message: {
            key: "appAdmin.placementManagement.notification.createdSuccess",
          },
        });

        return result.data;
      })
      .finally(() => {
        this.context.commit("setIsSavingInProgress", false);
      });
  }

  @Action
  async updatePlacement(payload: PlacementRequestModel) {
    this.context.commit("setIsSavingInProgress", true);

    return axios
      .patch(`/api/third-party/developer/placements/${payload.id}`, payload)
      .then((result: AxiosResponse<PlacementResponseModel>) => {
        this.context.commit("addNotification", {
          message: {
            key: "appAdmin.placementManagement.notification.updatedSuccess",
          },
        });

        return result.data;
      })
      .finally(() => {
        this.context.commit("setIsSavingInProgress", false);
      });
  }

  @Action({ commit: "setPlacementIdUniqueness" })
  async checkPlacementIdByUnique(payload: { placementId: string }) {
    if (!payload.placementId) {
      return true;
    }

    return axios
      .post(`/api/third-party/developer/placements/unique-placement`, payload)
      .then(
        (result: AxiosResponse<PlacementIdUniqueReponseModel>) =>
          result.data.unique
      );
  }

  @Action
  async syncWithAdCascade() {
    this.context.commit("setIsSyncWithAdCascadeInProgress", true);

    return axios
      .post("/api/third-party/developer/update-placements")
      .finally(() => {
        this.context.commit("setIsSyncWithAdCascadeInProgress", false);
      });
  }
}
