import {
  BaseReportFilter,
  BaseGradientFlags,
  ReportResultItem,
  ReportFilter,
} from "./Report";
import { ReportHeader, ReportHeaderUtil } from "./ReportHeader";
import { GroupByFilter } from "./GroupByFilter";
import { ReportType } from "./ReportType";
import { UsersActivityType } from "@/reports/models/ArpdauReport";
import {
  DatesFilterModel,
  FilterId,
  FilterPreviewId,
  recordToFilterModel,
  FilterModel,
  Application,
} from "@/shared/models";
import { LangService } from "@/shared/types/LangType";
import DateUtil from "@/shared/utils/DateUtil";
import {
  NetRevenueReportFilterExtension,
  UserActivityReportFilterExtension,
} from "./ReportFilterExtension";
import { ChartName } from "@/chart/models/ChartModel";
import { ReportItemRowObject } from "./ReportItemRowObject";

export class ArpdauSimpleGradientFlags extends BaseGradientFlags {
  constructor(allUsers?: boolean) {
    super();

    this.flags = allUsers
      ? {
          [`${ReportResultItem.PREFIX}revenue`]: false,
          [`${ReportResultItem.PREFIX}arpdau`]: true,
          [`${ReportResultItem.PREFIX}arpdauIap`]: true,
          [`${ReportResultItem.PREFIX}subscription`]: true,
          [`${ReportResultItem.PREFIX}arpdauAd`]: true,
          [`${ReportResultItem.PREFIX}banner`]: true,
          [`${ReportResultItem.PREFIX}interstitial`]: true,
          [`${ReportResultItem.PREFIX}rewarded`]: true,
        }
      : {
          [`${ReportResultItem.PREFIX}dau_e`]: true,
          [`${ReportResultItem.PREFIX}arpdau_e`]: true,
          [`${ReportResultItem.PREFIX}dau_b`]: true,
          [`${ReportResultItem.PREFIX}banner_e`]: true,
          [`${ReportResultItem.PREFIX}dau_i`]: true,
          [`${ReportResultItem.PREFIX}interstitial_e`]: true,
          [`${ReportResultItem.PREFIX}dau_r`]: true,
          [`${ReportResultItem.PREFIX}rewarded_e`]: true,
        };
  }
}

export class ArpdauSimpleFilter
  extends BaseReportFilter
  implements UserActivityReportFilterExtension, NetRevenueReportFilterExtension
{
  constructor(
    app: Application,
    filter?: Array<FilterModel>,
    date?: DatesFilterModel,
    groupByFilter?: GroupByFilter,
    public usersActivityType = UsersActivityType.ALL,
    public netRevenue = true
  ) {
    super(ReportType.ARPDAU_SIMPLE, app, filter, date, groupByFilter);
  }

  get previews() {
    return [
      ...super.previews,
      {
        id: FilterPreviewId.USER_ACTIVITY,
        value: this.usersActivityType,
      },
      {
        id: FilterPreviewId.NET_REVENUE,
        value: this.netRevenue,
      },
    ];
  }

  static of(
    app: Application,
    filter?: Record<string, any>
  ): ArpdauSimpleFilter {
    return filter
      ? new ArpdauSimpleFilter(
          app,
          filter.filter && filter.filter.length > 0
            ? filter.filter.map((it: any) => recordToFilterModel(it))
            : [],
          filter.date
            ? DatesFilterModel.ofRecord(FilterId.EVENTS_DATE, filter.date)
            : undefined,
          GroupByFilter.of(filter?.groupByFilter),
          filter.usersActivityType as UsersActivityType
          // TODO: NET_REVENUE need to uncomment later
          // typeof filter.netRevenue === "string"
          //   ? filter.netRevenue === "true"
          //   : filter.netRevenue,
        )
      : new ArpdauSimpleFilter(app);
  }

  get invalid(): boolean {
    return !(this.app && this.date.valid);
  }

  get groupByCountry(): boolean {
    return !!this.groupByFilter.groupBy.find((it) => it == FilterId.COUNTRY);
  }

  set groupByCountry(payload: boolean) {
    if (payload) {
      this.groupByFilter.groupBy.push(FilterId.COUNTRY);
    } else {
      const index = this.groupByFilter.groupBy.indexOf(FilterId.COUNTRY, 0);
      if (index > -1) {
        this.groupByFilter.groupBy.splice(index, 1);
      }
    }
  }

  get charts(): Array<ChartName> {
    return {
      AD_ACTIVE: this.groupByFilter.isNotEmptyGroupBy
        ? [
            ChartName.ARPDAU_SIMPLE_ACTIVE_USERS_ARPDAU_CHART,
            ChartName.ARPDAU_SIMPLE_ACTIVE_USERS_BANNER_CHART,
            ChartName.ARPDAU_SIMPLE_ACTIVE_USERS_INTERSTITIAL_CHART,
            ChartName.ARPDAU_SIMPLE_ACTIVE_USERS_REWARDED_CHART,
          ]
        : [ChartName.ARPDAU_ACTIVE_USERS_CHART],
      ALL: this.groupByFilter.isNotEmptyGroupBy
        ? [
            ChartName.ARPDAU_SIMPLE_ALL_USERS_ARPDAU_CHART,
            ChartName.ARPDAU_SIMPLE_ALL_USERS_ARPDAU_IAP_CHART,
            ChartName.ARPDAU_SIMPLE_ALL_USERS_BANNER_CHART,
            ChartName.ARPDAU_SIMPLE_ALL_USERS_INTERSTITIAL_CHART,
            ChartName.ARPDAU_SIMPLE_ALL_USERS_REWARDED_CHART,
          ]
        : [ChartName.ARPDAU_ALL_USERS_CHART],
    }[this.usersActivityType];
  }

  get groupedCharts(): Array<ChartName> {
    return this.groupByFilter.isNotEmptyGroupBy
      ? {
          AD_ACTIVE: [ChartName.ARPDAU_ACTIVE_USERS_CHART],
          ALL: [ChartName.ARPDAU_ALL_USERS_CHART],
        }[this.usersActivityType]
      : [];
  }

  toRequestQuery(): Record<string, any> {
    return {
      ...super.toRequestQuery(),
      usersActivityType: this.usersActivityType,
      // TODO: NET_REVENUE need to uncomment later
      // netRevenue: this.netRevenue,
    };
  }
}

export class ArpdauSimpleHeaders {
  static readonly PRECISION = 4;

  static init(
    lang: LangService,
    report: ArpdauSimpleFilter
  ): Array<ReportHeader> {
    const result: Array<ReportHeader> = [ReportHeaderUtil.createDate(lang)];

    if (report.groupByFilter.isNotEmptyGroupBy) {
      result.push(
        ...ReportHeaderUtil.createGroupBy(lang, report.groupByFilter)
      );
    }

    if (report.usersActivityType === UsersActivityType.ALL) {
      result.push(
        {
          text: lang("components.baseTable.activeDevices"),
          align: "end",
          value: `${ReportResultItem.PREFIX}activeDevices`,
          fractionDigits: 0,
        },
        {
          text: lang("components.baseTable.revenue"),
          align: "end",
          value: `${ReportResultItem.PREFIX}revenue`,
          hasGradient: true,
          fractionDigits: 0,
        },
        {
          text: lang("components.baseTable.arpdau"),
          align: "end",
          value: `${ReportResultItem.PREFIX}arpdau`,
          hasGradient: true,
          fractionDigits: ArpdauSimpleHeaders.PRECISION,
        },
        {
          text: lang("components.baseTable.arpdauIap"),
          align: "end",
          value: `${ReportResultItem.PREFIX}arpdauIap`,
          hasGradient: true,
          fractionDigits: ArpdauSimpleHeaders.PRECISION,
        },
        {
          text: lang("components.baseTable.subscription"),
          align: "end",
          value: `${ReportResultItem.PREFIX}subscription`,
          hasGradient: true,
          fractionDigits: ArpdauSimpleHeaders.PRECISION,
        },
        {
          text: lang("components.baseTable.arpdauAd"),
          align: "end",
          value: `${ReportResultItem.PREFIX}arpdauAd`,
          hasGradient: true,
          fractionDigits: ArpdauSimpleHeaders.PRECISION,
        },
        {
          text: lang("components.baseTable.banner"),
          align: "end",
          value: `${ReportResultItem.PREFIX}banner`,
          hasGradient: true,
          fractionDigits: ArpdauSimpleHeaders.PRECISION,
        },
        {
          text: lang("components.baseTable.interstitial"),
          align: "end",
          value: `${ReportResultItem.PREFIX}interstitial`,
          hasGradient: true,
          fractionDigits: ArpdauSimpleHeaders.PRECISION,
        },
        {
          text: lang("components.baseTable.rewarded"),
          align: "end",
          value: `${ReportResultItem.PREFIX}rewarded`,
          hasGradient: true,
          fractionDigits: ArpdauSimpleHeaders.PRECISION,
        }
      );
    } else {
      result.push(
        {
          text: lang("components.baseTable.dau_e"),
          align: "end",
          value: `${ReportResultItem.PREFIX}dau_e`,
          hasGradient: true,
          fractionDigits: 0,
        },
        {
          text: lang("components.baseTable.arpdau_e"),
          align: "end",
          value: `${ReportResultItem.PREFIX}arpdau_e`,
          hasGradient: true,
          fractionDigits: ArpdauSimpleHeaders.PRECISION,
        },
        {
          text: lang("components.baseTable.dau_b"),
          align: "end",
          value: `${ReportResultItem.PREFIX}dau_b`,
          hasGradient: true,
          fractionDigits: 0,
        },
        {
          text: lang("components.baseTable.banner_e"),
          align: "end",
          value: `${ReportResultItem.PREFIX}banner_e`,
          hasGradient: true,
          fractionDigits: ArpdauSimpleHeaders.PRECISION,
        },
        {
          text: lang("components.baseTable.dau_i"),
          align: "end",
          value: `${ReportResultItem.PREFIX}dau_i`,
          hasGradient: true,
          fractionDigits: 0,
        },
        {
          text: lang("components.baseTable.interstitial_e"),
          align: "end",
          value: `${ReportResultItem.PREFIX}interstitial_e`,
          hasGradient: true,
          fractionDigits: ArpdauSimpleHeaders.PRECISION,
        },
        {
          text: lang("components.baseTable.dau_r"),
          align: "end",
          value: `${ReportResultItem.PREFIX}dau_r`,
          hasGradient: true,
          fractionDigits: 0,
        },
        {
          text: lang("components.baseTable.rewarded_e"),
          align: "end",
          value: `${ReportResultItem.PREFIX}rewarded_e`,
          hasGradient: true,
          fractionDigits: ArpdauSimpleHeaders.PRECISION,
        }
      );
    }
    return result;
  }
}

export class ArpdauSimpleResultItem extends ReportResultItem {
  constructor(
    row: ReportItemRowObject,
    filter: ReportFilter,
    allUsers?: boolean
  ) {
    super();

    const { groupByFilter } = filter;

    this.date = row.getByHeader("date");
    this.formattedDate = DateUtil.formatDate(this.date);
    const isTotalRow = !this.date;
    this.setGroupByValue(groupByFilter, row);

    if (allUsers) {
      this.data["activeDevices"] =
        super.parseInt(row.getByHeader("activeDevices")) ||
        (isTotalRow ? "" : 0);
      this.data["revenue"] =
        Math.round(Number.parseFloat(row.getByHeader("revenue"))) ||
        (isTotalRow ? "" : 0);
      this.data["arpdau"] = super.parseFloat(
        row.getByHeader("arpdau"),
        ArpdauSimpleHeaders.PRECISION
      );
      this.data["arpdauIap"] = super.parseFloat(
        row.getByHeader("arpdauIap"),
        ArpdauSimpleHeaders.PRECISION
      );
      this.data["subscription"] = row.getByHeader("subscription");
      this.data["arpdauAd"] = super.parseFloat(
        row.getByHeader("arpdauAd"),
        ArpdauSimpleHeaders.PRECISION
      );
      this.data["banner"] = super.parseFloat(
        row.getByHeader("banner"),
        ArpdauSimpleHeaders.PRECISION
      );
      this.data["interstitial"] = super.parseFloat(
        row.getByHeader("interstitial"),
        ArpdauSimpleHeaders.PRECISION
      );
      this.data["rewarded"] = super.parseFloat(
        row.getByHeader("rewarded"),
        ArpdauSimpleHeaders.PRECISION
      );
    } else {
      this.data["dau_e"] = super.parseFloat(
        row.getByHeader("dau_e"),
        ArpdauSimpleHeaders.PRECISION
      );
      this.data["arpdau_e"] = super.parseFloat(
        row.getByHeader("arpdau_e"),
        ArpdauSimpleHeaders.PRECISION
      );
      this.data["dau_b"] = super.parseFloat(
        row.getByHeader("dau_b"),
        ArpdauSimpleHeaders.PRECISION
      );
      this.data["banner_e"] = super.parseFloat(
        row.getByHeader("banner_e"),
        ArpdauSimpleHeaders.PRECISION
      );
      this.data["dau_i"] = super.parseFloat(
        row.getByHeader("dau_i"),
        ArpdauSimpleHeaders.PRECISION
      );
      this.data["interstitial_e"] = super.parseFloat(
        row.getByHeader("interstitial_e"),
        ArpdauSimpleHeaders.PRECISION
      );
      this.data["dau_r"] = super.parseFloat(
        row.getByHeader("dau_r"),
        ArpdauSimpleHeaders.PRECISION
      );
      this.data["rewarded_e"] = super.parseFloat(
        row.getByHeader("rewarded_e"),
        ArpdauSimpleHeaders.PRECISION
      );
    }
  }
}
