import {
  HttpMethod,
  NetworkUrlPaginationModel,
  RequestType,
} from "./NetworkUrlRequestModel";

export default class NetworkUrlResponseModel {
  constructor(
    public id: number,
    public httpMethod: HttpMethod,
    public networkIntegrationId: number,
    public node: string,
    public requestType: RequestType,
    public url: string,
    public pagination: NetworkUrlPaginationModel | null = null,
    public expirationDays?: number
  ) {}

  static ofSource(model: NetworkUrlResponseModel): NetworkUrlResponseModel {
    return new NetworkUrlResponseModel(
      model.id,
      model.httpMethod,
      model.networkIntegrationId,
      model.node,
      model.requestType,
      model.url,
      model.pagination,
      model.expirationDays
    );
  }

  static ofArray(networksRequests: Array<NetworkUrlResponseModel>) {
    return networksRequests.map((networksRequest) =>
      NetworkUrlResponseModel.ofSource(networksRequest)
    );
  }
}
