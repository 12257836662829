import KeyUtil from "@/shared/utils/KeyUtil";
import { RangeModel } from "./FilterModel";
import { DictionaryType } from "./DictionaryType";
import { TrackerDictionary } from "./Dictionary";

export type TrackerType =
  | DictionaryType.SOURCES
  | DictionaryType.SUB_SOURCES
  | DictionaryType.CAMPAIGNS
  | DictionaryType.AD_SETS
  | DictionaryType.CREATIVES
  | DictionaryType.PUBLISHERS;

export class TrackerPickerModel {
  [DictionaryType.SOURCES]: Array<string>;
  [DictionaryType.SUB_SOURCES]: Array<string>;
  [DictionaryType.CAMPAIGNS]: Array<string>;
  [DictionaryType.AD_SETS]: Array<string>;
  [DictionaryType.CREATIVES]: Array<string>;
  [DictionaryType.PUBLISHERS]: Array<string>;
  included = {
    [DictionaryType.SOURCES]: true,
    [DictionaryType.SUB_SOURCES]: true,
    [DictionaryType.CAMPAIGNS]: true,
    [DictionaryType.AD_SETS]: true,
    [DictionaryType.CREATIVES]: true,
    [DictionaryType.PUBLISHERS]: true,
  };

  constructor(filter?: Record<string, any>) {
    this[DictionaryType.SOURCES] =
      (filter && (filter.source as Array<string>)) || [];
    this.included[DictionaryType.SOURCES] =
      filter && filter.includedSource !== undefined
        ? filter.includedSource === "true" || filter.includedSource === true
        : true;
    this[DictionaryType.SUB_SOURCES] =
      (filter && (filter.subSource as Array<string>)) || [];
    this.included[DictionaryType.SUB_SOURCES] =
      filter && filter.includedSubSource !== undefined
        ? filter.includedSubSource === "true" ||
          filter.includedSubSource === true
        : true;
    this[DictionaryType.CAMPAIGNS] =
      (filter && (filter.campaign as Array<string>)) || [];
    this.included[DictionaryType.CAMPAIGNS] =
      filter && filter.includedCampaign !== undefined
        ? filter.includedCampaign === "true" || filter.includedCampaign === true
        : true;
    this[DictionaryType.AD_SETS] =
      (filter && (filter.adSet as Array<string>)) || [];
    this.included[DictionaryType.AD_SETS] =
      filter && filter.includedAdSet !== undefined
        ? filter.includedAdSet === "true" || filter.includedAdSet === true
        : true;
    this[DictionaryType.CREATIVES] =
      (filter && (filter.creative as Array<string>)) || [];
    this.included[DictionaryType.CREATIVES] =
      filter && filter.includedCreative !== undefined
        ? filter.includedCreative === "true" || filter.includedCreative === true
        : true;
    this[DictionaryType.PUBLISHERS] =
      (filter && (filter.publisher as Array<string>)) || [];
    this.included[DictionaryType.PUBLISHERS] =
      filter && filter.includedPublisher !== undefined
        ? filter.includedPublisher === "true" ||
          filter.includedPublisher === true
        : true;
  }

  filterDependedValues(
    type: TrackerType,
    dictionaries: Array<TrackerDictionary>,
    deletedParentValues: Array<string>
  ) {
    if (!this[type].length) {
      return;
    }

    const deletedValues: Array<string> = dictionaries.flatMap(
      ({ value, parentValue }: TrackerDictionary) =>
        !!parentValue && deletedParentValues.includes(parentValue)
          ? [value]
          : []
    );

    this[type] = this[type].filter((value) => !deletedValues.includes(value));
  }

  getParentValues(type: TrackerType) {
    return {
      [DictionaryType.SOURCES]: () => [],
      [DictionaryType.SUB_SOURCES]: () => this[DictionaryType.SOURCES],
      [DictionaryType.CAMPAIGNS]: () => this[DictionaryType.SOURCES],
      [DictionaryType.AD_SETS]: () =>
        this[DictionaryType.SOURCES].concat(this[DictionaryType.CAMPAIGNS]),
      [DictionaryType.CREATIVES]: () =>
        this[DictionaryType.SOURCES].concat(
          this[DictionaryType.CAMPAIGNS],
          this[DictionaryType.AD_SETS]
        ),
      [DictionaryType.PUBLISHERS]: () =>
        this[DictionaryType.SOURCES].concat(
          this[DictionaryType.CAMPAIGNS],
          this[DictionaryType.AD_SETS]
        ),
    }[type]();
  }

  resetValues(trackerType: TrackerType) {
    if (
      [
        DictionaryType.SUB_SOURCES,
        DictionaryType.CREATIVES,
        DictionaryType.PUBLISHERS,
      ].includes(trackerType)
    ) {
      return;
    }

    const dependedTypes: Array<TrackerType> = [
      DictionaryType.CREATIVES,
      DictionaryType.PUBLISHERS,
    ];

    if (
      [DictionaryType.SOURCES, DictionaryType.CAMPAIGNS].includes(trackerType)
    ) {
      dependedTypes.push(DictionaryType.AD_SETS);
    }

    if (trackerType === DictionaryType.SOURCES) {
      dependedTypes.push(DictionaryType.SUB_SOURCES, DictionaryType.CAMPAIGNS);
    }

    dependedTypes.forEach((type) => {
      this[type] = [];
      this.included[type] = true;
    });
  }

  static of(model: TrackerPickerModel) {
    return new TrackerPickerModel({
      source: [...model[DictionaryType.SOURCES]],
      subSource: [...model[DictionaryType.SUB_SOURCES]],
      campaign: [...model[DictionaryType.CAMPAIGNS]],
      adSet: [...model[DictionaryType.AD_SETS]],
      creative: [...model[DictionaryType.CREATIVES]],
      publisher: [...model[DictionaryType.PUBLISHERS]],
      includedSource: model.included[DictionaryType.SOURCES],
      includedSubSource: model.included[DictionaryType.SUB_SOURCES],
      includedCampaign: model.included[DictionaryType.CAMPAIGNS],
      includedAdSet: model.included[DictionaryType.AD_SETS],
      includedCreative: model.included[DictionaryType.CREATIVES],
      includedPublisher: model.included[DictionaryType.PUBLISHERS],
    });
  }
}

export enum EventOperation {
  EQUAL = "EQUAL",
  NOT_EQUAL = "NOT_EQUAL",
  IN = "IN",
  NOT_IN = "NOT_IN",
  BETWEEN = "BETWEEN",
  NOT_BETWEEN = "NOT_BETWEEN",
  GREATER = "GT",
  LESS = "LT",
  LIKE = "LIKE",
  NOT_LIKE = "NOT_LIKE",
}

export const EventOperations: Array<EventOperation> = [
  EventOperation.EQUAL,
  EventOperation.NOT_EQUAL,
  EventOperation.IN,
  EventOperation.NOT_IN,
  EventOperation.BETWEEN,
  EventOperation.NOT_BETWEEN,
  EventOperation.GREATER,
  EventOperation.LESS,
  EventOperation.LIKE,
  EventOperation.NOT_LIKE,
];

export class EventPickerParamModel {
  key: string | null;
  param?: string;
  operation?: EventOperation;
  value: Array<string>;

  constructor(
    value: Array<string> = [],
    param?: string,
    operation?: EventOperation,
    key: string | null = KeyUtil.generateKey()
  ) {
    this.key = key;
    this.value = value;
    this.param = param;
    this.operation = operation;
  }

  static of(param: EventPickerParamModel): EventPickerParamModel {
    return new EventPickerParamModel(param.value, param.param, param.operation);
  }

  get isSingleValue(): boolean {
    return (
      this.operation === EventOperation.EQUAL ||
      this.operation === EventOperation.NOT_EQUAL ||
      this.operation === EventOperation.LIKE ||
      this.operation === EventOperation.NOT_LIKE ||
      this.operation === EventOperation.GREATER ||
      this.operation === EventOperation.LESS
    );
  }

  get isTwoValue(): boolean {
    return (
      this.operation === EventOperation.BETWEEN ||
      this.operation === EventOperation.NOT_BETWEEN
    );
  }

  get isMultiValue(): boolean {
    return (
      this.operation === EventOperation.IN ||
      this.operation === EventOperation.NOT_IN
    );
  }

  get isFloat(): boolean {
    return (
      this.operation === EventOperation.GREATER ||
      this.operation === EventOperation.LESS ||
      this.operation === EventOperation.BETWEEN ||
      this.operation === EventOperation.NOT_BETWEEN
    );
  }
}

export class EventPickerParamRangeModel {
  constructor(
    public value?: RangeModel,
    public param?: string,
    public key: string | null = KeyUtil.generateKey()
  ) {}

  static of(model: EventPickerParamRangeModel): EventPickerParamRangeModel {
    return new EventPickerParamRangeModel(model.value, model.param, model.key);
  }

  static ofArray(
    items: Array<EventPickerParamRangeModel>
  ): Array<EventPickerParamRangeModel> {
    return items.length
      ? items.map((item) => EventPickerParamRangeModel.of(item))
      : [];
  }
}

export class EventPickerModel {
  key: string | null;
  included: boolean;
  params: Array<EventPickerParamModel>;
  timesFrom?: number;
  timesTo?: number;
  name?: string;

  constructor(
    included = true,
    params: Array<EventPickerParamModel> = [new EventPickerParamModel()],
    name?: string,
    timesFrom?: number,
    timesTo?: number,
    key: string | null = KeyUtil.generateKey()
  ) {
    this.key = key;
    this.included = included;
    this.name = name;
    this.timesFrom = timesFrom;
    this.timesTo = timesTo;
    this.params =
      params && params.length ? params : [new EventPickerParamModel()];
  }

  static of(filter?: Record<string, any>): EventPickerModel {
    return filter
      ? new EventPickerModel(
          filter.included !== undefined
            ? filter.included === "true" || filter.included === true
            : true,
          filter.params && filter.params.length > 0
            ? filter.params.map((it: any) => EventPickerParamModel.of(it))
            : [{}],
          filter.name,
          filter.timesFrom,
          filter.timesTo
        )
      : new EventPickerModel();
  }
}

export class EventRangePickerModel {
  constructor(
    public included = true,
    public params: Array<EventPickerParamRangeModel> = [],
    public name?: string,
    public value?: RangeModel,
    public range?: RangeModel,
    public key: string | null = KeyUtil.generateKey()
  ) {}

  static of(filter?: Record<string, any>): EventRangePickerModel {
    return filter
      ? new EventRangePickerModel(
          filter.included !== undefined
            ? filter.included === "true" || filter.included === true
            : true,
          filter.params && filter.params.length > 0
            ? filter.params.map((it: any) => EventPickerParamRangeModel.of(it))
            : [{}],
          filter.name,
          filter.value,
          filter.range
        )
      : new EventRangePickerModel();
  }
}
