export default class ValidUtil {
  private static readonly REG_EXP_FOR_VERSIONS = new RegExp(
    "^[0-9]+(\\.[0-9]+)*$",
    "i"
  );

  private static readonly REG_EXP_FOR_EMAIL =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  private static readonly REG_EXP_RUSSIAN_LETTERS_ONLY = /[а-яА-ЯЁё]/;

  private static readonly REG_EXP_SMALL_LETTERS = /[a-z]/;

  private static readonly REG_EXP_CITY = /^[a-zA-Z0-9 '-]+$/;

  static endsWith(searchString: string, message: string) {
    return (value?: string) =>
      !!value || value?.endsWith(searchString) || message;
  }

  static maxLength(length: number, message: string) {
    return (value?: string) =>
      !value || value.trim().length <= length || message;
  }

  static minLength(length: number, message: string) {
    return (value?: string) =>
      !value || value.trim().length >= length || message;
  }

  static required(message: string) {
    return (value?: string) => !!value || message;
  }

  static requiredArray(message: string) {
    return (value?: Array<any>) => (!!value && value.length > 0) || message;
  }

  static requiredNumber(message: string) {
    return (value?: string) =>
      (value !== "" && value !== null && value !== undefined) || message;
  }

  static min(val: number, message: string) {
    return (value?: number) => !value || value >= val || message;
  }

  static max(val: number, message: string) {
    return (value?: number) => !value || value <= val || message;
  }

  static minNumber(val: number, message: string) {
    return (value: number) => value >= val || message;
  }

  static integer(message: string) {
    return (value?: number) =>
      !value || Number.isInteger(Number(value)) || message;
  }

  static maxForFloat(message: string) {
    return (value: number) =>
      Number.isInteger(Number(value)) ||
      String(value).split(".")[1]?.length <= 6 ||
      message;
  }

  static unique(values: Array<string>, message: string) {
    return (val?: string) =>
      (val && values.indexOf(val.trim().toLowerCase()) === -1) || message;
  }

  static maxDate(val?: string, message?: string) {
    return (value?: string) => !value || !val || value <= val || message;
  }

  static minDate(val?: string, message?: string) {
    return (value?: string) => !value || !val || value >= val || message;
  }

  static restrict(regExp: RegExp, message: string) {
    return function (val: string) {
      if (!regExp.test(val)) {
        return true;
      }
      return regExp.exec(val) == null || message;
    };
  }

  static matchVersion(message: string) {
    return (val: string) => ValidUtil.REG_EXP_FOR_VERSIONS.test(val) || message;
  }

  static notEndsWith(value: string, message: string) {
    return (val: string) => !val.endsWith(value) || message;
  }

  static email(message: string) {
    return (val: string) => ValidUtil.REG_EXP_FOR_EMAIL.test(val) || message;
  }

  static boolean(message: string) {
    return (val: any) => typeof val === "boolean" || message;
  }

  static englishLettersOnly(message: string) {
    return (val: string) =>
      !ValidUtil.REG_EXP_RUSSIAN_LETTERS_ONLY.test(val) || message;
  }

  static capitalLetters(message: string) {
    return (val: string) =>
      !ValidUtil.REG_EXP_SMALL_LETTERS.test(val) || message;
  }

  static city(message: string) {
    return (val: string) => ValidUtil.REG_EXP_CITY.test(val) || message;
  }
}
