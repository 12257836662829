import { AddressModel } from "../AddressModel";
import { CounterpartyTaxationType } from "./CounterpartyTaxationType";
import NetworkResponseModel from "./CounterpartyNetworkResponseModel";
import ThresholdResponseModel from "./ThresholdResponseModel";

export default class CounterpartyResponseModel {
  constructor(
    public id: number,
    public name: string,
    public currencyCode: string,
    public currentBankId: number,
    public currentBankName: string,
    public alertThresholds: Array<ThresholdResponseModel>,
    public currentNet: any | null,
    public currentNetworks: Array<NetworkResponseModel>,
    public taxation: CounterpartyTaxationType,
    public address: AddressModel,
    public websiteUrl: string,
    public note: string,
    public vatNumber?: string
  ) {}

  static of(model: CounterpartyResponseModel): CounterpartyResponseModel {
    return new CounterpartyResponseModel(
      model.id,
      model.name,
      model.currencyCode,
      model.currentBankId,
      model.currentBankName,
      model.alertThresholds,
      model.currentNet,
      model.currentNetworks,
      model.taxation,
      model.address,
      model.websiteUrl,
      model.note,
      model.vatNumber
    );
  }

  static ofArray(
    items: Array<CounterpartyResponseModel>
  ): Array<CounterpartyResponseModel> {
    return items.map((item) => CounterpartyResponseModel.of(item));
  }
}
