import { CohortFilter } from "./CohortReport";
import { GroupByFilter } from "./GroupByFilter";
import { TimeSpentReportType } from "./TimeSpentReportType";
import { ReportType } from "./ReportType";
import {
  DatesFilterModel,
  FilterId,
  FilterPreview,
  FilterPreviewId,
  recordToFilterModel,
  FilterModel,
  Application,
} from "@/shared/models";
import { TimeSpentRportTypeReportFilterExtension } from "./ReportFilterExtension";
import { ChartName } from "@/chart/models/ChartModel";

export class TimeSpentFilter
  extends CohortFilter
  implements TimeSpentRportTypeReportFilterExtension
{
  reportType: TimeSpentReportType;

  constructor(
    app: Application,
    filter?: Array<FilterModel>,
    date?: DatesFilterModel,
    groupByFilter?: GroupByFilter,
    dayLimit?: number,
    reportType = TimeSpentReportType.CUMULATIVE_TIME_SPENT
  ) {
    super(ReportType.TIME_SPENT, app, filter, date, groupByFilter, dayLimit);
    this.reportType = reportType;
  }

  get previews(): Array<FilterPreview> {
    return [
      ...super.previews,
      {
        id: FilterPreviewId.REPORT_TYPE_TIME_SPENT,
        value: this.reportType,
      },
    ];
  }

  get charts(): Array<ChartName> {
    return this.groupByFilter.isNotEmptyGroupBy
      ? this.getGroupedCohortChartNames("TIME_SPENT_REPORT", [1, 7, 14])
      : [ChartName.TIME_SPENT_REPORT_CHART];
  }

  get groupedCharts(): Array<ChartName> {
    return this.groupByFilter.isNotEmptyGroupBy
      ? [ChartName.TIME_SPENT_REPORT_CHART]
      : [];
  }

  static of(app: Application, filter?: Record<string, any>): TimeSpentFilter {
    return filter
      ? new TimeSpentFilter(
          app,
          filter.filter && filter.filter.length > 0
            ? filter.filter.map((it: any) => recordToFilterModel(it))
            : [],
          filter.date
            ? DatesFilterModel.ofRecord(
                FilterId.ATTRIBUTION_DATE_VALUE,
                filter.date
              )
            : undefined,
          filter.groupByFilter
            ? GroupByFilter.of(filter.groupByFilter)
            : undefined,
          filter.dayLimit
            ? Number.parseInt(filter.dayLimit as string)
            : undefined,
          Object.values(TimeSpentReportType).find(
            (value: string) => value === filter.reportType
          ) ?? TimeSpentReportType.TIME_SPENT
        )
      : new TimeSpentFilter(app);
  }

  toRequestQuery(): Record<string, any> {
    const result = super.toRequestQuery();
    result["reportType"] = this.reportType;
    return result;
  }
}
