import { uniq } from "lodash";

import {
  BaseGradientFlags,
  BaseReportFilter,
  GroupByWithEventParamFilter,
  ReportFilter,
  ReportResultItem,
} from "./Report";
import { GroupByFilter } from "./GroupByFilter";
import { ReportHeader, ReportHeaderUtil } from "./ReportHeader";
import { ReportType } from "./ReportType";
import {
  DatesFilterModel,
  EventParamFilterRangeModel,
  FilterId,
  FilterPreview,
  FilterPreviewId,
  recordToFilterModel,
  FilterModel,
  Application,
} from "@/shared/models";
import { LangService } from "@/shared/types/LangType";
import {
  GeneratedEventReportFilterExtension,
  ShowUniqueDevicesReportFilterExtension,
} from "./ReportFilterExtension";
import { ChartName } from "@/chart/models/ChartModel";
import { ReportItemRowObject } from "./ReportItemRowObject";

export class EventsSummaryLiteGradientFlags extends BaseGradientFlags {
  constructor(showUserDevices: boolean) {
    super();

    this.flags = {
      [`${ReportResultItem.PREFIX}totalOccurrences`]: false,
      ...(showUserDevices
        ? {
            [`${ReportResultItem.PREFIX}avgEventPerDevice`]: false,
            [`${ReportResultItem.PREFIX}uniqueDevicesDailyAvg`]: false,
          }
        : {}),
    };
  }
}

export class EventsSummaryLiteFilter
  extends BaseReportFilter
  implements
    GroupByWithEventParamFilter,
    GeneratedEventReportFilterExtension,
    ShowUniqueDevicesReportFilterExtension
{
  generatedEventFilter?: EventParamFilterRangeModel;
  eventParamKeyForGroupBy?: string;
  generatedEventDateFrom?: string;
  generatedEventDateTo?: string;

  constructor(
    app: Application,
    filter?: Array<FilterModel>,
    date?: DatesFilterModel,
    groupByFilter: GroupByFilter = new GroupByFilter(undefined, []),
    generatedEventFilter?: EventParamFilterRangeModel,
    eventParamKeyForGroupBy?: string,
    generatedEventDateFrom?: string,
    generatedEventDateTo?: string,
    public showUniqueDevices = false
  ) {
    super(ReportType.EVENTS_SUMMARY_LITE, app, filter, date, groupByFilter);
    this.generatedEventFilter = generatedEventFilter;
    this.eventParamKeyForGroupBy = eventParamKeyForGroupBy;
    this.generatedEventDateFrom = generatedEventDateFrom;
    this.generatedEventDateTo = generatedEventDateTo;
  }

  get previews(): Array<FilterPreview> {
    return [
      ...super.previews,
      ...(this.generatedEventFilter
        ? this.generatedEventFilter.preview.map((preview) => {
            return {
              ...preview,
              id:
                preview.id === FilterPreviewId.EVENT
                  ? FilterPreviewId.GENERATED_EVENT
                  : FilterPreviewId.GENERATED_EVENT_PARAM,
            };
          })
        : []),
      ...(this.generatedEventDateFrom && this.generatedEventDateTo
        ? [
            {
              id: FilterPreviewId.GENERATED_EVENT_PARAM_DATE,
              value:
                this.generatedEventDateFrom === this.generatedEventDateTo
                  ? this.generatedEventDateFrom
                  : `${this.generatedEventDateFrom} — ${this.generatedEventDateTo}`,
            },
          ]
        : []),
    ];
  }

  static of(
    app: Application,
    filter?: Record<string, any>
  ): EventsSummaryLiteFilter {
    return filter
      ? new EventsSummaryLiteFilter(
          app,
          filter.filter && filter.filter.length > 0
            ? filter.filter.map((it: any) => recordToFilterModel(it))
            : [],
          filter.date
            ? DatesFilterModel.ofRecord(FilterId.EVENTS_DATE, filter.date)
            : undefined,
          filter.groupByFilter
            ? GroupByFilter.of(filter.groupByFilter)
            : undefined,
          filter.generatedEventFilter
            ? (recordToFilterModel(
                filter.generatedEventFilter
              ) as EventParamFilterRangeModel)
            : undefined,
          filter.eventParamKeyForGroupBy as string,
          filter.generatedEventDateFrom,
          filter.generatedEventDateTo,
          typeof filter?.showUniqueDevices === "string"
            ? filter?.showUniqueDevices === "true"
            : filter?.showUniqueDevices
        )
      : new EventsSummaryLiteFilter(app);
  }

  get invalid(): boolean {
    return (
      !this.app ||
      !this.date.valid ||
      (this.hasGroupByEventParameter && !this.eventParamKeyForGroupBy) ||
      (this.isGeneratedEventParamValid &&
        !(this.generatedEventDateFrom && this.generatedEventDateTo))
    );
  }

  get isGeneratedEventParamValid(): boolean {
    return !!(
      this.generatedEventFilter?.valid &&
      this.generatedEventFilter?.value?.params?.length
    );
  }

  get hasEventFilter(): boolean {
    return this.filter.some(
      ({ id }: { id: FilterId }) => id === FilterId.EVENT
    );
  }

  get statsEventFilterName(): string | undefined {
    return (
      this.filter.find(
        (it) => it.id === FilterId.EVENT
      ) as EventParamFilterRangeModel
    )?.value?.name;
  }

  get hasGroupByEventParameter() {
    return this.groupByFilter.groupBy.includes(FilterId.EVENT_PARAMETER);
  }

  get charts(): Array<ChartName> {
    if (this.groupByFilter.groupBy.length > 1) {
      return [];
    }

    if (this.hasEventFilter && this.groupByFilter.aggregationPeriod) {
      return this.groupByFilter.isNotEmptyGroupBy
        ? [
            ChartName.EVENTS_SUMMARY_TOTAL_OCCURRENCES_CHART,
            ...(this.showUniqueDevices ? [ChartName.UNIQUE_DEVICES_CHART] : []),
          ]
        : [ChartName.EVENTS_SUMMARY_LITE_REPORT_CHART];
    }

    return this.groupByFilter.aggregationPeriod ||
      this.groupByFilter.isNotEmptyGroupBy
      ? []
      : [
          ChartName.TOTAL_OCCURRENCES_CHART,
          ...(this.showUniqueDevices ? [ChartName.UNIQUE_DEVICES_CHART] : []),
        ];
  }

  get groupedCharts(): Array<ChartName> {
    return this.groupByFilter.isNotEmptyGroupBy &&
      this.groupByFilter.aggregationPeriod
      ? [ChartName.EVENTS_SUMMARY_LITE_REPORT_CHART]
      : [];
  }

  getUsedDictionaryValues(): Record<string, Array<string>> {
    const usedDictionaryValues: Record<
      string,
      Array<string>
    > = super.getUsedDictionaryValues();

    if (this.generatedEventFilter) {
      this.generatedEventFilter
        .getUsedDictionaryValues()
        .forEach((values, dictionary) => {
          usedDictionaryValues[dictionary] = uniq(
            (usedDictionaryValues[dictionary] || []).concat(values)
          );
        });
    }

    return usedDictionaryValues;
  }

  removeGroupByEventParameter() {
    if (this.hasGroupByEventParameter) {
      const index = this.groupByFilter.groupBy.indexOf(
        FilterId.EVENT_PARAMETER
      );
      this.groupByFilter.groupBy.splice(index, 1);
    }
    this.eventParamKeyForGroupBy = undefined;
  }

  clearAndSetApp(app: Application) {
    super.clearAndSetApp(app);
    this.removeGroupByEventParameter();
  }

  toRequestQuery(): Record<string, any> {
    const result = super.toRequestQuery();

    if (this.generatedEventFilter && !this.generatedEventFilter.isEmpty()) {
      result["generatedEventFilter"] = this.generatedEventFilter.toRecord();
    }

    if (this.eventParamKeyForGroupBy) {
      result["eventParamKeyForGroupBy"] = this.eventParamKeyForGroupBy;
    }

    if (this.generatedEventDateFrom) {
      result["generatedEventDateFrom"] = this.generatedEventDateFrom;
    }

    if (this.generatedEventDateTo) {
      result["generatedEventDateTo"] = this.generatedEventDateTo;
    }

    if (this.showUniqueDevices) {
      result["showUniqueDevices"] = this.showUniqueDevices;
    }

    return result;
  }
}

export class EventsSummaryLiteHeaders {
  static readonly PRECISION = 2;

  static init(
    lang: LangService,
    report: EventsSummaryLiteFilter
  ): Array<ReportHeader> {
    return [
      ...(report.groupByFilter.aggregationPeriod
        ? [
            ReportHeaderUtil.createDate(
              lang,
              report.groupByFilter.aggregationPeriod
            ),
          ]
        : []),
      ...(report.groupByFilter.isNotEmptyGroupBy
        ? ReportHeaderUtil.createGroupBy(lang, report.groupByFilter)
        : []),
      {
        text: lang("components.eventName.name"),
        align: "start",
        value: `${ReportResultItem.PREFIX}eventName`,
        isGrouped: report.hasGroupedCharts,
      },
      {
        text: lang("components.baseTable.totalOccurrences"),
        align: "end",
        value: `${ReportResultItem.PREFIX}totalOccurrences`,
        hasGradient: true,
        fractionDigits: 0,
      },
      ...(report.showUniqueDevices
        ? ([
            {
              text: lang("components.baseTable.avgEventPerDevice"),
              align: "end",
              value: `${ReportResultItem.PREFIX}avgEventPerDevice`,
              hasGradient: true,
              fractionDigits: EventsSummaryLiteHeaders.PRECISION,
            },
            {
              text: lang("components.baseTable.uniqueDevicesDailyAvg"),
              align: "end",
              value: `${ReportResultItem.PREFIX}uniqueDevicesDailyAvg`,
              hasGradient: true,
              fractionDigits: 0,
            },
          ] as Array<ReportHeader>)
        : []),
    ];
  }
}

export class EventsSummaryLiteResultItem extends ReportResultItem {
  constructor(row: ReportItemRowObject, filter: ReportFilter) {
    super();
    const { groupByFilter } = filter;
    this.date = row.getByHeader("date");
    this.setFormattedDate(
      this.date,
      filter.date.from,
      filter.date.to,
      groupByFilter.aggregationPeriod
    );
    this.setGroupByValue(groupByFilter, row);
    this.data["eventName"] = row.getByHeader("eventName");
    this.data["totalOccurrences"] = super.parseInt(
      row.getByHeader("totalOccurrences")
    );
    this.data["avgEventPerDevice"] = super.parseFloat(
      row.getByHeader("avgEventPerDevice"),
      EventsSummaryLiteHeaders.PRECISION
    );
    this.data["uniqueDevicesDailyAvg"] = super.parseInt(
      row.getByHeader("uniqueDevicesDailyAvg")
    );
  }

  get eventName(): string {
    return this.data["eventName"];
  }

  set eventName(val: string) {
    this.data["eventName"] = val;
  }

  get totalOccurrences(): number {
    return this.data["totalOccurrences"];
  }

  set totalOccurrences(val: number) {
    this.data["totalOccurrences"] = val;
  }

  get avgEventPerDevice(): number {
    return this.data["avgEventPerDevice"];
  }

  set avgEventPerDevice(val: number) {
    this.data["avgEventPerDevice"] = val;
  }

  get uniqueDevicesDailyAvg(): number {
    return this.data["uniqueDevicesDailyAvg"];
  }

  set uniqueDevicesDailyAvg(val: number) {
    this.data["uniqueDevicesDailyAvg"] = val;
  }
}
