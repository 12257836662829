import { UserAccess } from "@/shared/models";
import ApplicationResponseModel from "./ApplicationResponseModel";

export default class DefaultConfigurationModel {
  constructor(
    public id?: number,
    public version?: string,
    public response = new ApplicationResponseModel(),
    public creatorName?: string,
    public features: Array<UserAccess> = []
  ) {}

  get hasDeployAccess(): boolean {
    return this.features.includes(UserAccess.DEPLOY);
  }

  static of(model: DefaultConfigurationModel): DefaultConfigurationModel {
    return new DefaultConfigurationModel(
      model.id,
      model.version,
      ApplicationResponseModel.of(model.response),
      model.creatorName,
      model.features
    );
  }
}
