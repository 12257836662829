export default class ConfigurationResponseModel {
  constructor(
    public applicationId: string,
    public profileId: string,
    public label: string
  ) {}

  static ofArray(items: Array<ConfigurationResponseModel>) {
    return items.map(
      (item) =>
        new ConfigurationResponseModel(
          item.applicationId,
          item.profileId,
          item.label
        )
    );
  }
}
