import { Vue, Component, Watch } from "vue-property-decorator";
import { debounce, difference } from "lodash";

import {
  CurrentUserModel,
  Dictionary,
  DictionaryType,
  MULTI_APP,
  TrackerOrigin,
} from "@/shared/models";
import { ReportFilter } from "@/reports/models";

@Component
export default class DictionaryLoadMixin extends Vue {
  dictionaryType!: DictionaryType;
  search: string | null = null;
  currentSearch: string | null = null;
  localOrigins: Array<TrackerOrigin> = [];

  get applicationId(): string {
    return this.$store.state.application.applicationId;
  }

  get isLoading(): boolean {
    return this.$store.state.dictionaryStore.loadingDictionary;
  }

  get dictionaryData(): Array<Dictionary> {
    return this.$store.state.dictionaryStore[this.dictionaryType].values;
  }

  get isFullResult(): boolean {
    return this.$store.state.dictionaryStore[this.dictionaryType].isFullResult;
  }

  get currentUser(): CurrentUserModel {
    return this.$store.state.userStore.currentUser;
  }

  get localReport(): ReportFilter | null {
    return this.$store.state.reportStore.localReport;
  }

  get apps(): string {
    if (this.applicationId !== MULTI_APP || !this.localReport) {
      return this.applicationId;
    }

    return (
      this.currentUser.availableReportApps
        .get(this.localReport.reportId)
        ?.join(",") || this.applicationId
    );
  }

  @Watch("search")
  watchSearch = debounce(this.initSearch, 500);

  initSearch(search: string | null) {
    if (
      (this.isFullResult && !this.currentSearch) ||
      this.currentSearch === search ||
      (!this.currentSearch && (!search || search.length < 2))
    ) {
      return;
    }

    this.currentSearch = search && search.length > 1 ? search : null;
    this.$store.dispatch("loadDictionary", {
      app: this.apps,
      type: this.dictionaryType,
      search: this.currentSearch,
      ...(this.localOrigins.length ? { origins: this.localOrigins } : {}),
    });
  }

  onSearchChange(value: string | null) {
    this.search = value;
  }

  setUsedDictionaries(newValues: Array<string>, oldValues: Array<string> = []) {
    const valuesToAdd = difference(newValues, oldValues);

    if (!valuesToAdd.length) {
      return;
    }

    this.$store.commit("setUsedDictionaries", {
      [this.dictionaryType]: {
        dictionaryValues: this.dictionaryData.filter(({ value }) =>
          valuesToAdd.includes(value)
        ),
      },
    });
  }
}
