export default class NetResponseModel {
  constructor(
    public id: number,
    public counterpartyId: number,
    public dateFrom: string,
    public dateTo: string | null,
    public net: number
  ) {}

  static of(model: NetResponseModel): NetResponseModel {
    return new NetResponseModel(
      model.id,
      model.counterpartyId,
      model.dateFrom,
      model.dateTo,
      model.net
    );
  }

  static ofArray(items: Array<NetResponseModel>): Array<NetResponseModel> {
    return items.map((item) => NetResponseModel.of(item));
  }
}
