import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import axios from "@/shared/plugins/axios";
import { Dictionary, DictionaryType } from "@/shared/models";

interface SaveDictionariesRequest {
  app: string;
  dictionaryType: DictionaryType;
  dictionaries: Array<Dictionary>;
  callback: () => void;
}

@Module
export default class SaveDictionaryStore extends VuexModule {
  savingDictionary = false;

  @Mutation
  setSavingDictionaries(payload: boolean) {
    this.savingDictionary = payload;
  }

  @Action
  async saveDictionaries(param: SaveDictionariesRequest) {
    this.context.commit("setSavingDictionaries", true);
    axios
      .post(
        `/api/appadmin/app/${param.app}/dictionary/${param.dictionaryType}`,
        param.dictionaries
      )
      .then(() => {
        this.context.commit("setSavingDictionaries", false);
        param.callback();
      });
  }
}
