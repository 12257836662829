import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosResponse } from "axios";

import axios from "@/shared/plugins/axios";
import RevenueEventModel from "@/settings/models/revenue-events/RevenueEventModel";
import RevenueEventRequestModel, {
  GetRevenueEventRequestModel,
  RevenueEventUniqueTokenRequestModel,
  RevenueEventUniqueTokenResponseModel,
} from "@/settings/models/revenue-events/RevenueEventRequestModel";

@Module
export default class RevenueEventsStore extends VuexModule {
  revenueEvents: Array<RevenueEventModel> = [];
  revenueEvent: RevenueEventModel | null = null;
  isSavingInProgress = false;
  isEventDeleting = false;
  isTokenUnique = true;

  @Mutation
  setRevenueEvents(payload: Array<RevenueEventModel>) {
    this.revenueEvents = RevenueEventModel.ofArray(payload);
  }

  @Mutation
  setRevenueEvent(payload: RevenueEventModel) {
    this.revenueEvent = RevenueEventModel.ofSource(payload);
  }

  @Mutation
  clearRevenueEvents() {
    this.revenueEvents = [];
  }

  @Mutation
  setIsSavingInProgress(payload: boolean) {
    this.isSavingInProgress = payload;
  }

  @Mutation
  setIsTokenUnique(payload: boolean) {
    this.isTokenUnique = payload;
  }

  @Mutation
  updateRevenueEvents(deletedEventId: number) {
    this.revenueEvents = this.revenueEvents.filter(
      ({ id }) => id !== deletedEventId
    );
  }

  @Mutation
  setIsEventDeleting(payload: boolean) {
    this.isEventDeleting = payload;
  }

  @Action({ commit: "setRevenueEvents" })
  async loadRevenueEvents(applicationId: string) {
    return axios
      .get(`/api/appadmin/app/${applicationId}/settings/revenueEvents`)
      .then((result: AxiosResponse<Array<RevenueEventModel>>) => {
        return result.data;
      });
  }

  @Action({ commit: "setRevenueEvent" })
  async loadRevenueEventById({
    applicationId,
    id,
  }: GetRevenueEventRequestModel) {
    return axios
      .get(`/api/appadmin/app/${applicationId}/settings/revenueEvents/${id}`)
      .then((result: AxiosResponse<RevenueEventModel>) => {
        return result.data;
      });
  }

  @Action
  async createRevenueEvent(payload: RevenueEventRequestModel) {
    const createdRevenueEvent = RevenueEventRequestModel.ofRequest(payload);
    this.context.commit("setIsSavingInProgress", true);

    return axios
      .post(
        `/api/appadmin/app/${payload.applicationId}/settings/revenueEvents`,
        createdRevenueEvent
      )
      .then((result: AxiosResponse<RevenueEventModel>) => {
        this.context.commit("addNotification", {
          message: {
            key: "appAdmin.revenueEvents.notification.createdSuccess",
          },
        });

        return result.data;
      })
      .finally(() => {
        this.context.commit("setIsSavingInProgress", false);
      });
  }

  @Action
  async updateRevenueEvent(payload: RevenueEventRequestModel) {
    const updatedRevenueEvent = RevenueEventRequestModel.ofRequest(payload);
    this.context.commit("setIsSavingInProgress", true);

    return axios
      .put(
        `/api/appadmin/app/${payload.applicationId}/settings/revenueEvents/${payload.id}`,
        updatedRevenueEvent
      )
      .then((result: AxiosResponse<RevenueEventModel>) => {
        this.context.commit("addNotification", {
          message: {
            key: "appAdmin.revenueEvents.notification.updatedSuccess",
          },
        });

        return result.data;
      })
      .finally(() => {
        this.context.commit("setIsSavingInProgress", false);
      });
  }

  @Action
  async deleteRevenueEvent({ applicationId, id }: RevenueEventRequestModel) {
    this.context.commit("setIsEventDeleting", true);

    return axios
      .delete(`/api/appadmin/app/${applicationId}/settings/revenueEvents/${id}`)
      .then(() => {
        this.context.commit("addNotification", {
          message: {
            key: "appAdmin.revenueEvents.notification.deletedSuccess",
          },
        });
        this.context.commit("updateRevenueEvents", id);
      })
      .finally(() => {
        this.context.commit("setIsEventDeleting", false);
      });
  }

  @Action({ commit: "setIsTokenUnique" })
  async checkIsTokenUnique(payload: RevenueEventRequestModel) {
    if (!payload.token) {
      return true;
    }

    const requestParams = new RevenueEventUniqueTokenRequestModel(
      payload.token.trim()
    );

    return axios
      .post(
        `/api/appadmin/app/${payload.applicationId}/settings/revenueEvents/uniqueToken`,
        requestParams
      )
      .then(
        (result: AxiosResponse<RevenueEventUniqueTokenResponseModel>) =>
          result.data.unique
      );
  }
}
