import { NetworkIntegrationShortModel } from "./NetworkIntegrationResponseModel";
import { StatisticExecutionStatus } from "./AccountGroupStatisticModel";

export default class NetworkResponseModel {
  constructor(
    public id: number,
    public networkName: string,
    public lastExecutionStatus: StatisticExecutionStatus,
    public lastExecutionEndDate: string | Date | null,
    public networkIntegrations: Array<NetworkIntegrationShortModel>
  ) {}

  static ofSource(model: NetworkResponseModel): NetworkResponseModel {
    return new NetworkResponseModel(
      model.id,
      model.networkName,
      model.lastExecutionStatus,
      model.lastExecutionEndDate,
      NetworkIntegrationShortModel.ofArray(model.networkIntegrations)
    );
  }

  static ofArray(networks: Array<NetworkResponseModel>) {
    return networks.map((network) => NetworkResponseModel.ofSource(network));
  }
}
