import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { AxiosResponse } from "axios";

import axios from "@/shared/plugins/axios";
import InfraCommandResponseModel from "@/application-manager/models/InfraCommandResponseModel";

@Module
export default class InfraCommandsStore extends VuexModule {
  isLoading = false;
  infraCommands: Array<InfraCommandResponseModel> = [];

  @Mutation
  setInfraCommandsLoading(payload: boolean) {
    this.isLoading = payload;
  }

  @Mutation
  setInfraCommands(payload: Array<InfraCommandResponseModel>) {
    this.infraCommands = InfraCommandResponseModel.ofArray(payload);
  }

  @Action({ commit: "setInfraCommands" })
  async loadInfraCommands() {
    this.context.commit("setInfraCommandsLoading", true);
    return axios
      .get(`/api/infra/commands`)
      .then((result: AxiosResponse<Array<InfraCommandResponseModel>>) => {
        return result.data;
      })
      .finally(() => {
        this.context.commit("setInfraCommandsLoading", false);
      });
  }
}
