import { ChartName } from "@/chart/models/ChartModel";
import { getDashboardChartByChartName } from "@/chart/utils/ChartUtil";
import {
  DashboardChart,
  DashboardChartType,
} from "@/dashboard/models/DashboardChartModel";
import { ReportFilter, ReportType } from "@/reports/models";
import ReportUtil from "@/reports/utils/ReportUtil";
import { AccessType, Application, FilterId, UserAccess } from "@/shared/models";
import DateUtil from "@/shared/utils/DateUtil";

export enum TemplateType {
  REPORT_TEMPLATE = "REPORT_TEMPLATE",
  FUNNEL_TEMPLATE = "FUNNEL_TEMPLATE",
}

export interface TemplateUniqueNameRequestInterface {
  name: string;
}

export interface TemplateUniqueNameResponseInterface {
  name: string;
  unique: boolean;
}

export class NewFunnelTemplateModel {
  constructor(
    public funnelId?: number,
    public accessType: AccessType = AccessType.PRIVATE,
    public name: string = "",
    public description?: string
  ) {}
}

export class NewReportTemplateModel {
  constructor(
    public applicationIds: Array<string>,
    public reportFilter: ReportFilter,
    public accessType: AccessType = AccessType.PRIVATE,
    public relative: boolean = false,
    public name: string = ""
  ) {}
}

export abstract class TemplateModel {
  abstract label: string;

  constructor(
    public type: TemplateType,
    public id: number,
    public name: string,
    public ownerId: string,
    public ownerName: string,
    public applicationIds: Array<string>,
    public features: Array<UserAccess>
  ) {}

  get deletable(): boolean {
    return this.features.includes(UserAccess.DELETE);
  }

  abstract getCharts(availableApps?: Array<Application>): Array<DashboardChart>;
}

export class ReportTemplateModel extends TemplateModel {
  constructor(
    id: number,
    name: string,
    ownerId: string,
    ownerName: string,
    applicationIds: Array<string>,
    features: Array<UserAccess>,
    public accessType: AccessType,
    public reportFilter: ReportFilter,
    public report: ReportType,
    public relative: boolean,
    public createdAt: string,
    public description?: string
  ) {
    super(
      TemplateType.REPORT_TEMPLATE,
      id,
      name,
      ownerId,
      ownerName,
      applicationIds,
      features
    );
  }

  get label(): string {
    return ReportUtil.getReportNameByReportType(this.report);
  }

  getCharts(availableApps: Array<Application>): Array<DashboardChart> {
    const apps = availableApps.filter(({ id }) =>
      this.applicationIds.includes(id)
    );

    return ReportUtil.getCopyOfReportFilter(
      this.reportFilter,
      this.report,
      apps
    ).charts.map((name: ChartName) => getDashboardChartByChartName(name));
  }

  getQuery(): Record<string, any> {
    const query = { ...this.reportFilter };

    if (this.relative && !!this.createdAt) {
      query.date.from = DateUtil.getRelativeDate(
        query.date.from,
        this.createdAt
      );
      query.date.to = DateUtil.getRelativeDate(query.date.to, this.createdAt);
    }

    query.filter = query.filter.map((filter: any) => {
      return filter.id !== FilterId.SEGMENT
        ? filter
        : {
            ...filter,
            values: filter.values.map((value: any) =>
              typeof value === "number" ? value.toString() : value
            ),
          };
    });

    return query;
  }

  static of(model: ReportTemplateModel): ReportTemplateModel {
    return new ReportTemplateModel(
      model.id,
      model.name,
      model.ownerId,
      model.ownerName,
      model.applicationIds,
      model.features,
      model.accessType,
      model.reportFilter,
      model.report,
      model.relative,
      model.createdAt,
      model.description
    );
  }
}

export class FunnelTemplateModel extends TemplateModel {
  readonly label = "Funnel";

  constructor(
    id: number,
    name: string,
    ownerId: string,
    ownerName: string,
    applicationIds: Array<string>,
    features: Array<UserAccess>,
    public accessType: AccessType,
    public funnelId: number,
    public funnelName: string,
    public createdAt: string,
    public description?: string
  ) {
    super(
      TemplateType.FUNNEL_TEMPLATE,
      id,
      name,
      ownerId,
      ownerName,
      applicationIds,
      features
    );
  }

  getCharts(): Array<DashboardChart> {
    return [
      {
        name: ChartName.FUNNEL_CHART,
        type: DashboardChartType.FUNNEL,
      },
      {
        name: ChartName.COLUMN_CHART,
        type: DashboardChartType.COLUMN,
      },
    ];
  }

  static of(model: FunnelTemplateModel): FunnelTemplateModel {
    return new FunnelTemplateModel(
      model.id,
      model.name,
      model.ownerId,
      model.ownerName,
      model.applicationIds,
      model.features,
      model.accessType,
      model.funnelId,
      model.funnelName,
      model.createdAt,
      model.description
    );
  }
}
