import { StatisticExecutionStatus } from "./AccountGroupStatisticModel";
import { RuleFieldKey } from "./ParsingRuleResponseModel";

export enum NetworkType {
  REVENUE = "REVENUE",
  SPEND = "SPEND",
  SKAD = "SKAD",
  STORE = "STORE",
  CURRENCY = "CURRENCY",
  SUBSCRIPTION = "SUBSCRIPTION",
  SUBSCRIPTION_METADATA = "SUBSCRIPTION_METADATA",
  MOTIVATED_TRAFFICS = "MOTIVATED_TRAFFICS",
}

export enum AuthorizationType {
  PRE_AUTH_REQUEST = "PRE_AUTH_REQUEST",
  SINGLE_REQUEST = "SINGLE_REQUEST",
}

export enum NetworkIntegrationStatus {
  DELETED = "DELETED",
  DISABLED = "DISABLED",
  ACTIVE = "ACTIVE",
}

export default class NetworkIntegrationResponseModel {
  constructor(
    public id: number,
    public networkId: number,
    public networkType: NetworkType,
    public authorizationType: AuthorizationType,
    public networkFieldKeys: Array<RuleFieldKey>,
    public lastExecutionEndDate: string | Date | null,
    public lastExecutionStatus: StatisticExecutionStatus | null,
    public lastExecutionMessage: string | null,
    public deltaFrom: number,
    public deltaTo: number,
    public status: NetworkIntegrationStatus,
    public parentNetworkId: number | null,
    public parentNetworkName: string | null,
    public hasChildEntities: boolean = true,
    public parentNetworkIntegrationId: number | null
  ) {}

  get hasParentNetwork(): boolean {
    return !!(
      this.parentNetworkId &&
      this.parentNetworkName &&
      this.parentNetworkIntegrationId
    );
  }

  static ofSource(
    model: NetworkIntegrationResponseModel
  ): NetworkIntegrationResponseModel {
    return new NetworkIntegrationResponseModel(
      model.id,
      model.networkId,
      model.networkType,
      model.authorizationType,
      model.networkFieldKeys,
      model.lastExecutionEndDate,
      model.lastExecutionStatus,
      model.lastExecutionMessage,
      model.deltaFrom,
      model.deltaTo,
      model.status,
      model.parentNetworkId,
      model.parentNetworkName,
      model.hasChildEntities,
      model.parentNetworkIntegrationId
    );
  }

  static ofArray(networkIntegrations: Array<NetworkIntegrationResponseModel>) {
    return networkIntegrations.map((networkIntegration) =>
      NetworkIntegrationResponseModel.ofSource(networkIntegration)
    );
  }
}

export class NetworkIntegrationShortModel {
  constructor(
    public id: number,
    public lastExecutionEndDate: string | Date,
    public lastExecutionStatus: StatisticExecutionStatus,
    public networkType: NetworkType
  ) {}

  static ofSource(
    model: NetworkIntegrationShortModel
  ): NetworkIntegrationShortModel {
    return new NetworkIntegrationShortModel(
      model.id,
      model.lastExecutionEndDate,
      model.lastExecutionStatus,
      model.networkType
    );
  }

  static ofArray(networkIntegrations: Array<NetworkIntegrationShortModel>) {
    return networkIntegrations.map((networkIntegration) =>
      NetworkIntegrationShortModel.ofSource(networkIntegration)
    );
  }
}
