export default class HistoricalRevenueNetworkResponseModel {
  constructor(
    public id: number,
    public net: number,
    public networkName: string
  ) {}

  static of(model: HistoricalRevenueNetworkResponseModel) {
    return new HistoricalRevenueNetworkResponseModel(
      model.id,
      model.net,
      model.networkName
    );
  }

  static ofArray(items: Array<HistoricalRevenueNetworkResponseModel>) {
    return items.map((item) => HistoricalRevenueNetworkResponseModel.of(item));
  }
}
