import { AppSection, MULTI_APP, MenuItems } from "@/shared/models";
import {
  PermissionResponse,
  PermissionResponseModel,
} from "@/iam/models/PermissionModel";
import { ReportType } from "@/reports/models";
import UserUtil from "@/shared/utils/UserUtil";
import ReportUtil from "@/reports/utils/ReportUtil";
import { PermissionUtil } from "@/iam/utils/PermissionUtil";
import { uniq } from "lodash";

export const GENERAL_SECTIONS: Array<string> = [
  AppSection.ALERT_SYSTEM,
  AppSection.JURISDICTIONS,
  AppSection.SCENARIOS,
  AppSection.BATCHES,
  AppSection.APPLICATIONS,
  AppSection.HEALTHCHECK_SUMMARY,
  AppSection.HEALTHCHECK_DATA_FLOW,
  AppSection.HEALTHCHECK_PARSING,
  AppSection.HEALTHCHECK_JOBS_QUEUE,
  AppSection.HEALTHCHECK_METRIC_WEIGHT,
  AppSection.NETWORKS_MANAGEMENT,
  AppSection.USERS,
  AppSection.RELOAD_DICTIONARIES_CACHE,
  AppSection.RELOAD_NETWORKS_CACHE,
  AppSection.MULTIAPP_DASHBOARDS,
  AppSection.AGGREGATORS,
  AppSection.PERMISSIONS_BROWSER,
  AppSection.BANKS,
  AppSection.COUNTERPARTIES,
  AppSection.INVOICES,
];

export const MULTI_APP_ROUTE_NAMES: Array<string> = [
  MenuItems.HOME,
  MenuItems.ACCOUNT_SETTINGS,
  AppSection.REPORT_TEMPLATES,
  AppSection.MULTIAPP_DASHBOARDS,
  AppSection.APPLICATIONS,
  AppSection.BATCHES,
  AppSection.JURISDICTIONS,
  AppSection.SCENARIOS,
  AppSection.ALERT_SYSTEM,
  AppSection.HEALTHCHECK_SUMMARY,
  AppSection.HEALTHCHECK_JOBS_QUEUE,
  AppSection.HEALTHCHECK_METRIC_WEIGHT,
  AppSection.USERS,
  AppSection.AGGREGATORS,
  AppSection.PERMISSIONS_BROWSER,
  AppSection.NETWORKS_MANAGEMENT,
  AppSection.RELOAD_DICTIONARIES_CACHE,
  AppSection.INVOICES,
  AppSection.BANKS,
  AppSection.COUNTERPARTIES,
  ...Object.values(ReportType).filter((reportType) =>
    ReportUtil.isMultiAppReport(reportType)
  ),
];

export enum UserStatus {
  ACTIVE = "ACTIVE",
  DISABLED = "DISABLED",
}

export enum UserAccess {
  VIEW = "VIEW",
  CREATE = "CREATE",
  EXECUTE = "EXECUTE",
  DEPLOY = "DEPLOY",
  DELETE = "DELETE",
  EDIT = "EDIT",
  FINISH = "FINISH",
  FULL_ACCESS = "FULL_ACCESS",
  VALIDATE = "VALIDATE",
  RELOAD_CONFIGS = "RELOAD_CONFIGS",
}

export interface UserSectionAccess {
  entity: AppSection | ReportType;
  permissions: Array<UserAccess>;
}

export const GENERAL_ACCESS = "general";

export class UserModel {
  constructor(
    public firstName: string,
    public lastName: string,
    public username: string,
    public isSuperUser: boolean,
    public status: UserStatus
  ) {}

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  static of(model: UserModel): UserModel {
    return new UserModel(
      model.firstName,
      model.lastName,
      model.username,
      model.isSuperUser,
      model.status
    );
  }

  static ofArray(models: Array<UserModel>) {
    return models.map((model) => UserModel.of(model));
  }
}

export class UserResponseModel extends UserModel {
  constructor(
    public firstName = "",
    public lastName = "",
    public username = "",
    public isSuperUser = false,
    public status = UserStatus.ACTIVE,
    public permissions: Array<PermissionResponse> = []
  ) {
    super(firstName, lastName, username, isSuperUser, status);
  }

  get simplifiedPermissions(): Array<PermissionResponseModel> {
    return this.permissions.map((permission) =>
      PermissionUtil.responseToModel(permission, this.username)
    );
  }

  get permissionsByApps(): Record<string, Array<PermissionResponseModel>> {
    return this.simplifiedPermissions.reduce(
      (
        result: Record<string, Array<PermissionResponseModel>>,
        permission: PermissionResponseModel
      ) => {
        if (permission.application) {
          if (result[permission.application]) {
            result[permission.application].push(permission);
          } else {
            result[permission.application] = [permission];
          }
        }

        return result;
      },
      {}
    );
  }

  get generalPermissions(): Array<PermissionResponseModel> {
    return this.simplifiedPermissions.reduce(
      (
        result: Array<PermissionResponseModel>,
        permission: PermissionResponseModel
      ) => {
        if (!permission.application) {
          result.push(permission);
        }

        return result;
      },
      []
    );
  }

  static of(model: UserResponseModel): UserResponseModel {
    return new UserResponseModel(
      model.firstName,
      model.lastName,
      model.username,
      model.isSuperUser,
      model.status,
      model.permissions
    );
  }

  static ofArray(models: Array<UserResponseModel>) {
    return models.map((model) => UserResponseModel.of(model));
  }
}

export class CurrentUserModel extends UserModel {
  constructor(
    public firstName = "",
    public lastName = "",
    public username = "",
    public isSuperUser = false,
    public status = UserStatus.ACTIVE,
    public access: Record<string, Array<UserSectionAccess>> = {}
  ) {
    super(firstName, lastName, username, isSuperUser, status);
  }

  get availableApps(): Array<string> {
    return Object.keys(this.access).filter((app) => app !== GENERAL_ACCESS);
  }

  get viewAccessEntities(): Record<string, Array<AppSection | ReportType>> {
    const generalSectionAccess =
      this.access[GENERAL_ACCESS]?.map(({ entity }) => entity) || [];
    const availableSectionByApps: Record<
      string,
      Array<AppSection | ReportType>
    > = {};

    this.availableApps.forEach((app) => {
      availableSectionByApps[app] = (
        this.access[app]?.map(({ entity }) => entity) || []
      ).concat(generalSectionAccess);
    });

    availableSectionByApps[MULTI_APP] = this.availableApps.length
      ? uniq(Object.values(availableSectionByApps).flat()).filter((routeName) =>
          MULTI_APP_ROUTE_NAMES.includes(routeName)
        )
      : generalSectionAccess;

    return availableSectionByApps;
  }

  get createAccessEntities(): Record<string, Array<AppSection | ReportType>> {
    return this.getEntitiesByAccessType(UserAccess.CREATE);
  }

  get editAccessEntities(): Record<string, Array<AppSection | ReportType>> {
    return this.getEntitiesByAccessType(UserAccess.EDIT);
  }

  get deleteAccessEntities(): Record<string, Array<AppSection | ReportType>> {
    return this.getEntitiesByAccessType(UserAccess.DELETE);
  }

  get finishAccessEntities(): Record<string, Array<AppSection | ReportType>> {
    return this.getEntitiesByAccessType(UserAccess.FINISH);
  }

  get validateAccessEntities(): Record<string, Array<AppSection | ReportType>> {
    return this.getEntitiesByAccessType(UserAccess.VALIDATE);
  }

  get routesAccessMap(): Record<string, Map<string, boolean>> {
    return UserUtil.getRoutesAccessMap(this);
  }

  get reportAccessByApp(): Record<string, Array<ReportType>> {
    const reportTypes = Object.values(ReportType);
    const singleAppReportAccess: Record<
      string,
      Array<ReportType>
    > = Object.entries(this.viewAccessEntities).reduce(
      (result, [app, entities]) =>
        Object.assign(result, {
          [app]: entities.filter(
            (entity) =>
              reportTypes.includes(entity as ReportType) &&
              entity !== ReportType.MONETIZATION_MONITORING
          ),
        }),
      {}
    );

    return Object.assign(singleAppReportAccess, {
      [MULTI_APP]: [
        ...new Set(Object.values(singleAppReportAccess).flat()),
      ].filter((reportType) => ReportUtil.isMultiAppReport(reportType)),
    });
  }

  get availableReportApps(): Map<ReportType, Array<string>> {
    const reports: Array<ReportType> = this.reportAccessByApp[MULTI_APP];

    return new Map(
      reports.map((reportType) => [
        reportType,
        Object.entries(this.reportAccessByApp).reduce(
          (result: Array<string>, [appId, appSections]) => {
            if (appId !== MULTI_APP && appSections.includes(reportType)) {
              result.push(appId);
            }

            return result;
          },
          []
        ),
      ])
    );
  }

  getEntitiesByAccessType(
    userAccess: UserAccess
  ): Record<string, Array<AppSection | ReportType>> {
    const generalSectionAccess =
      this.access[GENERAL_ACCESS]?.reduce(
        (
          entitiesResult: Array<AppSection | ReportType>,
          { entity, permissions }
        ) =>
          permissions.includes(userAccess)
            ? entitiesResult.concat([entity])
            : entitiesResult,
        []
      ) || [];

    const availableSectionByApps: Record<
      string,
      Array<AppSection | ReportType>
    > = Object.entries(this.access).reduce(
      (result, [appId, links]) =>
        appId !== GENERAL_ACCESS
          ? Object.assign(result, {
              [appId]: links.reduce(
                (
                  entitiesResult: Array<AppSection | ReportType>,
                  { entity, permissions }
                ) =>
                  permissions.includes(userAccess)
                    ? entitiesResult.concat([entity])
                    : entitiesResult,
                generalSectionAccess
              ),
            })
          : result,
      {}
    );

    availableSectionByApps[MULTI_APP] = uniq(
      Object.values(availableSectionByApps).flat().concat(generalSectionAccess)
    );

    return availableSectionByApps;
  }

  static of(user: CurrentUserModel): CurrentUserModel {
    return new CurrentUserModel(
      user.firstName,
      user.lastName,
      user.username,
      user.isSuperUser,
      user.status,
      user.access
    );
  }

  static ofArray(users: Array<CurrentUserModel>): Array<CurrentUserModel> {
    return users.map((user) => CurrentUserModel.of(user));
  }
}
