import { Module, VuexModule, Mutation } from "vuex-module-decorators";
import { Route } from "vue-router";

@Module
export default class UnsavedChangesStore extends VuexModule {
  isUnsavedChangesDialogVisible = false;
  nextRoute: Route | null = null;
  hasUnsavedChanges = false;

  @Mutation
  setUnsavedChangesDialogVisibility(payload: boolean) {
    this.isUnsavedChangesDialogVisible = payload;
  }

  @Mutation
  setNextRoute(payload: Route) {
    this.nextRoute = payload;
  }

  @Mutation
  setUnsavedChanges(payload: boolean) {
    this.hasUnsavedChanges = payload;
  }
}
