import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

import {
  Notification,
  NotificationModel,
  NotificationType,
} from "@/shared/models";

@Module
export default class NotificationStore extends VuexModule {
  notifications: Array<NotificationModel> = [];

  @Mutation
  addNotification(payload: Notification) {
    const newNotification = new NotificationModel(payload);
    const hasNotification = this.notifications.some(
      (notification: NotificationModel) =>
        notification.key === newNotification.key
    );

    if (hasNotification) {
      return;
    }

    if (
      this.notifications.length &&
      this.notifications[this.notifications.length - 1].type ===
        NotificationType.SUCCESS
    ) {
      this.notifications.shift();
    }

    this.notifications = [...this.notifications, newNotification];
  }

  @Mutation
  deleteNotification() {
    this.notifications.shift();
  }

  @Action({ commit: "addNotification" })
  addError(message: string) {
    return {
      message,
      type: NotificationType.ERROR,
    };
  }
}
