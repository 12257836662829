import { MenuItems, MenuItemsAbTests } from "@/shared/models/Menu";

export const UnsavedChangesRouting = new Map<string, any>([
  ["abTest_edit_configs", MenuItemsAbTests.AB_TEST],
  ["funnel_new", MenuItems.FUNNEL],
  ["funnel_view", MenuItems.FUNNEL],
  ["segment_new", MenuItems.SEGMENT],
  ["segment_update", MenuItems.SEGMENT],
  ["abTest_edit", MenuItemsAbTests.AB_TEST],
  ["abTest_new", MenuItemsAbTests.AB_TEST],
  ["targeted_edit_configs", MenuItemsAbTests.TARGETED],
  ["targeted_edit", MenuItemsAbTests.TARGETED],
  ["targeted_new", MenuItemsAbTests.TARGETED],
  ["revenue-events_new", "revenue-events"],
  ["placement_new", "placements"],
  ["placement_view", "placements"],
]);
