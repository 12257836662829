import {
  BaseGradientFlags,
  BaseReportFilter,
  ReportFilter,
  ReportResultItem,
} from "./Report";
import { AggregationPeriod, GroupByFilter } from "./GroupByFilter";
import { ReportHeader, ReportHeaderUtil } from "./ReportHeader";
import { PerMinReportType } from "./PerMinReportType";
import { ReportType } from "./ReportType";
import {
  DatesFilterModel,
  FilterId,
  FilterPreview,
  FilterPreviewId,
  recordToFilterModel,
  FilterModel,
  DefaultValues,
  Application,
} from "@/shared/models";
import { LangService } from "@/shared/types/LangType";
import {
  PerminReportTypeReportFilterExtension,
  ProvidedBannerReportFilterExtension,
  RoundSessionsReportFilterExtension,
} from "./ReportFilterExtension";
import { ReportDataType } from "./ReportVisualization";
import { ChartName } from "@/chart/models/ChartModel";
import { ReportItemRowObject } from "./ReportItemRowObject";

export class CalendarPerMinGradientFlags extends BaseGradientFlags {
  constructor() {
    super();

    this.flags = {
      [`${ReportResultItem.PREFIX}eventsBanner`]: true,
      [`${ReportResultItem.PREFIX}perMinBanner`]: true,
    };
  }
}

export class CalendarPerMinFilter
  extends BaseReportFilter
  implements
    PerminReportTypeReportFilterExtension,
    ProvidedBannerReportFilterExtension,
    RoundSessionsReportFilterExtension
{
  reportType: PerMinReportType;
  providedBanner: string;
  roundSessions: boolean;
  roundSessionsToSeconds: number;

  constructor(
    app: Application,
    filter?: Array<FilterModel>,
    date?: DatesFilterModel,
    groupByFilter = new GroupByFilter(AggregationPeriod.DAY, [], false),
    roundSessions = false,
    roundSessionsToSeconds: number = DefaultValues.ROUND_SESSIONS_TO,
    reportType = PerMinReportType.TIME_SPENT,
    providedBanner = DefaultValues.PROVIDED_BANNER
  ) {
    super(ReportType.CALENDAR_PER_MIN, app, filter, date, groupByFilter);
    this.roundSessionsToSeconds = roundSessionsToSeconds;
    this.roundSessions = roundSessions;
    this.reportType = reportType;
    this.providedBanner = providedBanner;
  }

  get previews(): Array<FilterPreview> {
    return [
      ...super.previews,
      {
        id: FilterPreviewId.PROVIDED_BANNER,
        value: this.providedBanner,
      },
      {
        id: FilterPreviewId.REPORT_TYPE_PERMIN,
        value: this.reportType,
      },
      ...(this.roundSessions
        ? [
            {
              id: FilterPreviewId.ROUND_SESSIONS_TO_SECONDS,
              value: this.roundSessionsToSeconds,
            },
          ]
        : []),
    ];
  }

  get isBannerProvidedDisabled(): boolean {
    return false;
  }

  static of(
    app: Application,
    requestQuery?: Record<string, any>
  ): CalendarPerMinFilter {
    return new CalendarPerMinFilter(
      app,
      requestQuery?.filter?.map((it: any) => recordToFilterModel(it)) || [],
      DatesFilterModel.ofRecord(FilterId.EVENTS_DATE, requestQuery?.date),
      new GroupByFilter(
        AggregationPeriod.DAY,
        requestQuery?.groupByFilter?.groupBy,
        false
      ),
      !!requestQuery && !!requestQuery.roundSessions,
      !!requestQuery && !!requestQuery.roundSessionsToSeconds
        ? Number.parseInt(requestQuery.roundSessionsToSeconds as string)
        : undefined,
      Object.values(PerMinReportType).find(
        (value: string) => value === requestQuery?.reportType
      ) ?? PerMinReportType.TIME_SPENT,
      requestQuery?.providedBanner
    );
  }

  get invalid(): boolean {
    return !(
      this.app &&
      this.date.valid &&
      this.roundSessionsToSeconds &&
      this.roundSessionsToSeconds > 0
    );
  }

  get charts(): Array<ChartName> {
    return this.groupByFilter.isNotEmptyGroupBy
      ? [
          ChartName.CALENDAR_PERMIN_REPORT_EVENTS_BANNER_CHART,
          ChartName.CALENDAR_PERMIN_REPORT_PERMIN_BANNER_CHART,
        ]
      : [ChartName.CALENDAR_PERMIN_REPORT_CHART];
  }

  get groupedCharts(): Array<ChartName> {
    return this.groupByFilter.isNotEmptyGroupBy
      ? [ChartName.CALENDAR_PERMIN_REPORT_CHART]
      : [];
  }

  toRequestQuery(): Record<string, any> {
    const result = super.toRequestQuery();

    result["reportType"] = this.reportType;

    if (this.providedBanner !== DefaultValues.PROVIDED_BANNER) {
      result["providedBanner"] = this.providedBanner;
    }

    if (this.roundSessions) {
      result["roundSessions"] = this.roundSessions;
      result["roundSessionsToSeconds"] = this.roundSessionsToSeconds;
    }

    return result;
  }
}

export class CalendarPerMinHeaders {
  static readonly PRECISION = 2;

  static init(
    lang: LangService,
    report: CalendarPerMinFilter,
    reportDataType: ReportDataType
  ): Array<ReportHeader> {
    return [
      ...(![ReportDataType.TOTAL, ReportDataType.SUB_TOTAL].includes(
        reportDataType
      )
        ? [
            ReportHeaderUtil.createDate(
              lang,
              report.groupByFilter.aggregationPeriod
            ),
          ]
        : []),
      ...(report.groupByFilter.isNotEmptyGroupBy &&
      reportDataType !== ReportDataType.TOTAL
        ? ReportHeaderUtil.createGroupBy(lang, report.groupByFilter)
        : []),
      {
        text: lang("components.baseTable.eventsBanner"),
        align: "end",
        value: `${ReportResultItem.PREFIX}eventsBanner`,
        hasGradient: true,
        fractionDigits: 0,
      },
      {
        text: lang("components.baseTable.perMinBanner"),
        align: "end",
        value: `${ReportResultItem.PREFIX}perMinBanner`,
        hasGradient: true,
        fractionDigits: CalendarPerMinHeaders.PRECISION,
      },
    ];
  }
}

export class CalendarPerMinResultItem extends ReportResultItem {
  constructor(row: ReportItemRowObject, filter: ReportFilter) {
    super();
    const { groupByFilter } = filter;
    this.date = row.getByHeader("date");
    this.setFormattedDate(
      this.date,
      filter.date.from,
      filter.date.to,
      groupByFilter.aggregationPeriod
    );
    this.setGroupByValue(groupByFilter, row);
    this.data["eventsBanner"] =
      super.parseInt(row.getByHeader("eventsBanner")) || 0;
    this.data["perMinBanner"] =
      super.parseFloat(
        row.getByHeader("perMinBanner"),
        CalendarPerMinHeaders.PRECISION
      ) || 0;
  }
}
