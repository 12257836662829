import { RuleType } from "./ParsingRuleResponseModel";
import {
  ParsingRuleResponseFormat,
  RuleFieldKey,
} from "./ParsingRuleResponseModel";

export default class ParsingRuleRequestModel {
  constructor(
    public responseFormatRule = new ResponseFormatRuleModel(),
    public rules: Array<RuleModel> = [],
    public networkIntegrationId?: number
  ) {}

  static ofRequest({
    responseFormatRule,
    rules,
    networkIntegrationId,
  }: ParsingRuleRequestModel): any {
    return {
      responseFormatRule: ResponseFormatRuleModel.ofRequest(responseFormatRule),
      rules: rules.reduce(
        (
          result: Record<RuleFieldKey, Record<string, string>>,
          rule: RuleModel
        ) => {
          result[rule.fieldKey as RuleFieldKey] = Object.entries(rule).reduce(
            (res: Record<string, string>, [key, value]: any) => {
              if (key === "fieldKey") {
                return res;
              }

              res[key] = value;

              return res;
            },
            {}
          );

          return result;
        },
        {} as Record<RuleFieldKey, Record<string, string>>
      ),
      networkIntegrationId,
    };
  }

  static ofSource(model: ParsingRuleRequestModel): ParsingRuleRequestModel {
    return new ParsingRuleRequestModel(
      model.responseFormatRule,
      model.rules,
      model.networkIntegrationId
    );
  }
}

export interface ResponseFormatRule {
  responseFormat: ParsingRuleResponseFormat;
  nodes?: Array<string>;
  includeChildrenNodes?: Array<string>;
  excludeNodes?: Array<string>;
  divider?: string;
  headers?: Array<string>;
  hasPom?: boolean;
  childrenNode?: string;
  encoding?: string;
  errorNodes?: Array<string>;
  errorValue?: string;
}

export class ResponseFormatRuleModel implements ResponseFormatRule {
  constructor(
    public responseFormat = ParsingRuleResponseFormat.JSON,
    public nodes: Array<string> = [],
    public includeChildrenNodes: Array<string> = [],
    public excludeNodes: Array<string> = [],
    public divider = ",",
    public headers: Array<string> = [],
    public hasPom = false,
    public childrenNode = "",
    public encoding = "",
    public errorNodes: Array<string> = [],
    public errorValue?: string,
    public successValue?: string
  ) {}

  static ofRequest(model: ResponseFormatRuleModel): ResponseFormatRule {
    const isJsonFormat = [
      ParsingRuleResponseFormat.JSON,
      ParsingRuleResponseFormat.JSON_MATRIX,
      ParsingRuleResponseFormat.JSON_WITH_DYNAMIC_KEYS,
      ParsingRuleResponseFormat.JSON_WITH_UNPIVOT_CHILDREN_OBJECT,
      ParsingRuleResponseFormat.AGGREGATED_JSON,
    ].includes(model.responseFormat);

    return {
      responseFormat: model.responseFormat,
      ...(model.nodes?.length && isJsonFormat ? { nodes: model.nodes } : {}),
      ...(model.includeChildrenNodes?.length &&
      [
        ParsingRuleResponseFormat.JSON,
        ParsingRuleResponseFormat.JSON_MATRIX,
        ParsingRuleResponseFormat.JSON_WITH_DYNAMIC_KEYS,
        ParsingRuleResponseFormat.JSON_WITH_UNPIVOT_CHILDREN_OBJECT,
      ].includes(model.responseFormat)
        ? { includeChildrenNodes: model.includeChildrenNodes }
        : {}),
      ...(model.responseFormat === ParsingRuleResponseFormat.CSV ||
      model.responseFormat === ParsingRuleResponseFormat.CHARTBOOST
        ? {
            divider: model.divider,
            hasPom: model.hasPom,
            ...(model.headers?.length ? { headers: model.headers } : {}),
            ...(model.encoding ? { encoding: model.encoding } : {}),
          }
        : {}),
      ...(model.responseFormat ===
      ParsingRuleResponseFormat.JSON_WITH_UNPIVOT_CHILDREN_OBJECT
        ? { childrenNode: model.childrenNode }
        : {}),
      ...(model.excludeNodes?.length &&
      model.responseFormat === ParsingRuleResponseFormat.AGGREGATED_JSON
        ? { excludeNodes: model.excludeNodes }
        : {}),
      ...(isJsonFormat ? { errorNodes: model.errorNodes } : {}),
      ...(model.errorValue && isJsonFormat
        ? { errorValue: model.errorValue }
        : {}),
      ...(model.successValue && isJsonFormat
        ? { successValue: model.successValue }
        : {}),
    };
  }
}

export interface DefaultRuleModel {
  value?: string;
}

export interface ObjectRuleModel {
  key?: string;
  keyValue?: string;
  node?: string;
  value?: string;
}

export interface DateRuleModel {
  format?: string;
  subRule?: SubRuleModel;
}

export interface SubRuleModel extends DefaultRuleModel, ObjectRuleModel {
  type?: RuleType;
}

export interface RuleModel
  extends DefaultRuleModel,
    ObjectRuleModel,
    DateRuleModel {
  fieldKey: RuleFieldKey;
  type: RuleType;
}
