import Vue from "vue";
import axios, {
  AxiosStatic,
  AxiosError,
  AxiosRequestConfig,
  CancelTokenStatic,
} from "axios";
import store from "@/shared/store";
import router from "@/shared/router";

export interface AxiosSkipErrorsConfig extends AxiosRequestConfig {
  skipErrors?: boolean;
  isValidation?: boolean;
}

const cancelToken: CancelTokenStatic = axios.CancelToken;
export let cancelSource = cancelToken.source();
export const refreshToken = () => {
  cancelSource = cancelToken.source();
};
export const cancelAllRequests = () => {
  cancelSource.cancel();
};

export const handleAxiosError = (error: AxiosError) => {
  const response = error.response;
  if (!response) {
    return Promise.reject(error);
  }

  const skipErrors =
    "skipErrors" in error.config &&
    (error.config as AxiosSkipErrorsConfig).skipErrors;
  const isValidation =
    "isValidation" in error.config &&
    (error.config as AxiosSkipErrorsConfig).isValidation;

  if (response.status === 401 && router.currentRoute.path !== "/app/login") {
    router.push("/app/login");
  } else if (!skipErrors && response.status === 403) {
    router.push("/app/403");
  } else if (!skipErrors && response.status === 404) {
    router.push("/app/404");
  } else if (!skipErrors && response.status === 500) {
    router.push("/app/500");
  } else if (isValidation && response.status >= 400) {
    store.commit("setValidations", response.data);
  } else if (!skipErrors && response.status >= 400) {
    store.dispatch(
      "addError",
      response.data.msg || `Server error: ${response.status}`
    );
  }

  return Promise.reject(error);
};

const axiosConfig: AxiosRequestConfig = {
  withCredentials: true,
};

if (process.env.VUE_APP_API_URL) {
  axiosConfig.baseURL = process.env.VUE_APP_API_URL;
}

const _axios = axios.create(axiosConfig);

_axios.interceptors.request.use((config) => {
  const source = axios.CancelToken.source();

  config.cancelToken = source.token;
  store.commit("setCancelRequest", source);

  return config;
});

_axios.interceptors.response.use((response) => response, handleAxiosError);

declare module "vue/types/vue" {
  interface Vue {
    axios: AxiosStatic;
  }
}

export const axiosPrototype = {
  install() {
    Vue.prototype.axios = _axios;
  },
};

export default _axios;
