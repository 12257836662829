import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosResponse } from "axios";

import FunnelModel from "@/funnels/models/FunnelModel";
import FunnelChartModel, {
  FunnelChartData,
} from "@/funnels/models/FunnelChartModel";
import {
  FunnelUniqueNameRequestModel,
  FunnelUniqueNameResponseModel,
  GetFunnelRequestModel,
  GetFunnelChartRequestModel,
  CreateFunnelSegmentModel,
} from "@/funnels/models/FunnelRequestModel";
import EventSourceWrapper from "@/shared/utils/EventSourceWrapper";
import axios from "@/shared/plugins/axios";
import { processFunnelSteps } from "@/funnels/utils/FunnelUtil";
import { FilterId } from "@/shared/models";

@Module
export default class FunnelStore extends VuexModule {
  loadingFunnels = false;
  funnels: Array<FunnelModel> = [];

  loadingFunnel = false;
  funnel: FunnelModel = new FunnelModel("");

  loadingFunnelChart = false;
  funnelChartData: FunnelChartData | null = null;
  funnelChartEventSource?: EventSourceWrapper;

  isFunnelNameUnique = true;

  @Mutation
  setLoadingFunnels() {
    this.loadingFunnels = true;
  }

  @Mutation
  storeFunnels(payload: Array<FunnelModel>) {
    this.funnels = FunnelModel.ofArray(payload);
    this.loadingFunnels = false;
  }

  @Mutation
  setLoadingFunnel() {
    this.loadingFunnel = true;
  }

  @Mutation
  storeFunnel(payload: FunnelModel) {
    this.funnel = FunnelModel.of(payload, [FilterId.ATTRIBUTION_DATE]);
    this.loadingFunnel = false;
  }

  @Mutation
  setLoadingFunnelChart() {
    this.loadingFunnelChart = true;
  }

  @Mutation
  setFunnelChartEventSource(payload: EventSourceWrapper) {
    this.funnelChartEventSource = payload;
  }

  @Mutation
  setFunnelChart(payload: FunnelChartData) {
    this.funnelChartData = {
      data: payload.data.map((it) =>
        FunnelChartModel.of(it.breakdownValue, it.stepsValue)
      ),
      steps: processFunnelSteps(payload.steps),
    };
    this.loadingFunnelChart = false;
  }

  @Mutation
  setIsFunnelNameUnique(payload: boolean) {
    this.isFunnelNameUnique = payload;
  }

  @Action({ commit: "storeFunnels" })
  async fetchFunnels(applicationId: string) {
    this.context.commit("setLoadingFunnels");

    return axios
      .get(`/api/app/${applicationId}/funnels`)
      .then((response: AxiosResponse<Array<FunnelModel>>) => response.data);
  }

  @Action({ commit: "storeFunnel" })
  async getFunnel(payload: GetFunnelRequestModel) {
    this.context.commit("setLoadingFunnel");
    return axios
      .get(`/api/app/${payload.applicationId}/funnels/${payload.id}`)
      .then((result: AxiosResponse<FunnelModel>) => result.data);
  }

  @Action({ commit: "setFunnelChartEventSource" })
  async subscribeOnFunnelChart(payload: GetFunnelChartRequestModel) {
    this.funnelChartEventSource?.close();
    this.context.commit("setLoadingFunnelChart");
    const path = `/api/app/${payload.applicationId}/funnels/${payload.id}/chart`;

    return axios.post(path, payload).then((result: AxiosResponse<string>) => {
      const eventSource = new EventSourceWrapper(`${path}/${result.data}`);

      eventSource.addEventListener("message", (message: any) => {
        this.context.commit("setFunnelChart", message);
        eventSource.close();
      });

      eventSource.addEventListener("serverError", (data: any) => {
        this.context.dispatch(
          "addError",
          data?.msg ?? "An error occurred while loading the funnel charts"
        );
        eventSource.close();
      });

      return eventSource;
    });
  }

  @Action({ commit: "setIsFunnelNameUnique" })
  async checkIsFunnelNameUnique(payload: FunnelModel) {
    if (!payload.name) {
      return true;
    }

    const uniquePayload = new FunnelUniqueNameRequestModel(payload.name.trim());

    return axios
      .post(
        `/api/app/${payload.applicationId}/funnels/uniqueName`,
        uniquePayload
      )
      .then(
        (result: AxiosResponse<FunnelUniqueNameResponseModel>) =>
          result.data.unique
      );
  }

  @Action
  async createFunnel(payload: FunnelModel) {
    await axios
      .post(`/api/app/${payload.applicationId}/funnels`, payload)
      .then((result: AxiosResponse<FunnelModel>) => {
        this.context.commit("addNotification", {
          message: {
            key: "funnel.notification.createdSuccess",
            params: [payload.name],
          },
        });

        return result.data;
      })
      .finally(() => {
        this.context.dispatch("fetchFunnels", payload.applicationId);
      });
  }

  @Action
  async updateFunnel({
    payload,
    id,
    applicationId,
    funnelName,
  }: {
    payload: FunnelModel;
    id: number;
    applicationId: string;
    funnelName: string;
  }) {
    return axios
      .patch(`/api/app/${applicationId}/funnels/${id}`, payload)
      .then((result: AxiosResponse<FunnelModel>) => {
        this.context.commit("addNotification", {
          message: {
            key: "funnel.notification.updatedSuccess",
            params: [funnelName],
          },
        });

        return result.data;
      })
      .finally(() => this.context.dispatch("fetchFunnels", applicationId));
  }

  @Action
  async deleteFunnel(payload: FunnelModel) {
    return axios
      .delete(`/api/app/${payload.applicationId}/funnels/${payload.id}`)
      .then((result: AxiosResponse<FunnelModel>) => result.data)
      .finally(() => {
        this.context.dispatch("fetchFunnels", payload.applicationId);
      });
  }

  @Action
  async recalculateFunnel(payload: FunnelModel) {
    return axios
      .post(
        `/api/app/${payload.applicationId}/funnels/${payload.id}/recalculate`
      )
      .then((result: AxiosResponse<FunnelModel>) => result.data)
      .finally(() =>
        this.context.dispatch("fetchFunnels", payload.applicationId)
      );
  }

  @Action
  async createFunnelSegment(payload: CreateFunnelSegmentModel) {
    return axios
      .post(
        `/api/app/${payload.applicationId}/funnels/${payload.funnelId}/segment`,
        payload
      )
      .then((result: AxiosResponse<void>) => {
        this.context.commit("addNotification", {
          message: {
            key: "segment.notification.createdSuccess",
            params: [payload.name],
          },
        });

        return result.data;
      });
  }

  @Action
  async loadFunnelDictionaries({
    app,
    funnel,
  }: {
    app: string;
    funnel: FunnelModel;
  }) {
    const funnelUsedDictionaries = funnel.getUsedDictionaryValues();
    const dictionaryTypes = Object.entries(funnelUsedDictionaries).flatMap(
      ([dictionaryType, values]) => (values.length ? [dictionaryType] : [])
    );

    if (dictionaryTypes.length) {
      await this.context.dispatch("loadDictionaries", {
        app,
        dictionaryTypes,
      });
    }

    this.context.dispatch("loadUsedDictionaries", {
      app,
      values: funnelUsedDictionaries,
    });
    this.context.dispatch("loadUsedTrackerDictionaries", {
      app,
      values: funnel.getUsedTrackerDictionaryValues(),
    });
  }
}
