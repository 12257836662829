import { AxiosResponse } from "axios";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import axios from "@/shared/plugins/axios";

import CounterpartyResponseModel from "@/accounting-portal/models/counterparties/CounterpartyResponseModel";
import CounterpartyShortResponseModel from "@/accounting-portal/models/counterparties/CounterpartyShortResponseModel";
import CounterpartyRequestModel from "@/accounting-portal/models/counterparties/CounterpartyRequestModel";
import ThresholdResponseModel from "@/accounting-portal/models/counterparties/ThresholdResponseModel";
import CounterpartyNetworkResponseModel from "@/accounting-portal/models/counterparties/CounterpartyNetworkResponseModel";
import CounterpartyNetworkRequestModel from "@/accounting-portal/models/counterparties/CounterpartyNetworkRequestModel";
import ThresholdRequestModel from "@/accounting-portal/models/counterparties/ThresholdRequestModel";
import NetResponseModel from "@/accounting-portal/models/counterparties/NetResponseModel";
import CounterpartyNetRequestModel from "@/accounting-portal/models/counterparties/NetRequestModel";
import CounterpartyBankResponseModel from "@/accounting-portal/models/counterparties/CounterpartyBankResponseModel";
import CounterpartyBanksRequestModel from "@/accounting-portal/models/counterparties/CounterpartyBanksRequestModel";
import { NotificationType } from "@/shared/models";

@Module
export default class CounterpartyStore extends VuexModule {
  counterparties: Array<CounterpartyResponseModel> = [];
  shortCounterparties: Array<CounterpartyShortResponseModel> = [];
  isCounterpartiesLoading = false;
  counterparty: CounterpartyResponseModel | null = null;
  isCounterpartyLoading = false;
  isCounterpartySaving = false;
  isCounterpartyNameUnique = true;
  thresholds: Array<ThresholdResponseModel> = [];
  isThresholdsLoading = false;
  isThresholdSaving = false;
  networks: Array<CounterpartyNetworkResponseModel> = [];
  isNetworksLoading = false;
  isNetworkSaving = false;
  netRevenues: Array<any> = [];
  isNetRevenuesLoading = false;
  isNetRevenuesSaving = false;
  counterpartyBanks: Array<CounterpartyBankResponseModel> = [];
  isCounterpartyBanksLoading = false;
  isCounterpartyBanksSaving = false;

  @Mutation
  setIsCounterpartiesLoading(payload: boolean) {
    this.isCounterpartiesLoading = payload;
  }

  @Mutation
  setIsCounterpartyLoading(payload: boolean) {
    this.isCounterpartyLoading = payload;
  }

  @Mutation
  setIsCounterpartySaving(payload: boolean) {
    this.isCounterpartySaving = payload;
  }

  @Mutation
  setCounterparties(payload: Array<CounterpartyResponseModel>) {
    this.counterparties = CounterpartyResponseModel.ofArray(payload);
  }

  @Mutation
  setShortCounterparties(payload: Array<CounterpartyShortResponseModel>) {
    this.shortCounterparties = CounterpartyShortResponseModel.ofArray(payload);
  }

  @Mutation
  setCounterparty(payload: CounterpartyResponseModel) {
    this.counterparty = CounterpartyResponseModel.of(payload);
  }

  @Mutation
  setCounterpartiesAfterUpdate(payload: CounterpartyResponseModel) {
    const indexOfUpdatedEntity = this.counterparties.findIndex(
      ({ id }) => id === payload.id
    );
    this.counterparties.splice(
      indexOfUpdatedEntity,
      1,
      CounterpartyResponseModel.of(payload)
    );
  }

  @Mutation
  updateCounterpartyAfterUpdate(payload: CounterpartyResponseModel) {
    this.counterparty = CounterpartyResponseModel.of(payload);
  }

  @Mutation
  setCounterpartiesAfterDelete(id: number) {
    this.counterparties = this.counterparties.filter((item) => item.id !== id);
  }

  @Mutation
  clearCounterparties() {
    this.counterparties = [];
    this.shortCounterparties = [];
  }

  @Mutation
  setIsCounterpartyNameUnique(payload: boolean) {
    this.isCounterpartyNameUnique = payload;
  }

  @Mutation
  setIsThresholdsLoading(payload: boolean) {
    this.isThresholdsLoading = payload;
  }

  @Mutation
  setIsThresholdSaving(payload: boolean) {
    this.isThresholdSaving = payload;
  }

  @Mutation
  setThresholds(payload: Array<ThresholdResponseModel>) {
    this.thresholds = ThresholdResponseModel.ofArray(payload);
  }

  @Mutation
  setThresholdsAfterCreate(payload: ThresholdResponseModel) {
    this.thresholds.push(ThresholdResponseModel.of(payload));
  }

  @Mutation
  setThresholdsAfterUpdate(payload: ThresholdResponseModel) {
    const indexOfUpdatedEntity = this.thresholds.findIndex(
      ({ id }) => id === payload.id
    );
    this.thresholds.splice(
      indexOfUpdatedEntity,
      1,
      ThresholdResponseModel.of(payload)
    );
  }

  @Mutation
  setThresholdsAfterDelete(id: number) {
    this.thresholds = this.thresholds.filter((item) => item.id !== id);
  }

  @Mutation
  clearThresholds() {
    this.thresholds = [];
  }

  @Mutation
  setIsNetworksLoading(payload: boolean) {
    this.isNetworksLoading = payload;
  }

  @Mutation
  setIsNetworkSaving(payload: boolean) {
    this.isNetworkSaving = payload;
  }

  @Mutation
  setCounterpartyNetworks(payload: Array<CounterpartyNetworkResponseModel>) {
    this.networks = CounterpartyNetworkResponseModel.ofArray(payload);
  }

  @Mutation
  setNetworksAfterCreate(payload: CounterpartyNetworkResponseModel) {
    this.networks.push(CounterpartyNetworkResponseModel.of(payload));
  }

  @Mutation
  setNetworksAfterUpdate(payload: CounterpartyNetworkResponseModel) {
    const indexOfUpdatedEntity = this.networks.findIndex(
      ({ id }) => id === payload.id
    );

    this.networks.splice(
      indexOfUpdatedEntity,
      1,
      CounterpartyNetworkResponseModel.of(payload)
    );
  }

  @Mutation
  setNetworksAfterDelete(id: number) {
    this.networks = this.networks.filter((item) => item.id !== id);
  }

  @Mutation
  clearCounterpartyNetworks() {
    this.networks = [];
  }

  @Mutation
  setIsNetRevenuesLoading(payload: boolean) {
    this.isNetRevenuesLoading = payload;
  }

  @Mutation
  setIsNetRevenuesSaving(payload: boolean) {
    this.isNetRevenuesSaving = payload;
  }

  @Mutation
  setCounterpartyNetRevenues(payload: Array<NetResponseModel>) {
    this.netRevenues = NetResponseModel.ofArray(payload);
  }

  @Mutation
  clearCounterpartyNetRevenues() {
    this.netRevenues = [];
  }

  @Mutation
  setIsCounterpartyBanksLoading(payload: boolean) {
    this.isCounterpartyBanksLoading = payload;
  }

  @Mutation
  setIsCounterpartyBanksSaving(payload: boolean) {
    this.isCounterpartyBanksSaving = payload;
  }

  @Mutation
  setCounterpartyBanks(payload: Array<CounterpartyBankResponseModel>) {
    this.counterpartyBanks = CounterpartyBankResponseModel.ofArray(payload);
  }

  @Mutation
  clearCounterpartyBanks() {
    this.counterpartyBanks = [];
  }

  @Action({ commit: "setCounterparties" })
  async loadCounterparties() {
    this.context.commit("setIsCounterpartiesLoading", true);

    return axios
      .get(`/api/accounting/counterparties`)
      .then(
        (response: AxiosResponse<Array<CounterpartyResponseModel>>) =>
          response.data
      )
      .finally(() => this.context.commit("setIsCounterpartiesLoading", false));
  }

  @Action({ commit: "setShortCounterparties" })
  async loadShortCounterparties() {
    this.context.commit("setIsCounterpartiesLoading", true);

    return axios
      .get(`/api/accounting/counterparties/short`)
      .then(
        (response: AxiosResponse<Array<CounterpartyShortResponseModel>>) =>
          response.data
      )
      .finally(() => this.context.commit("setIsCounterpartiesLoading", false));
  }

  @Action({ commit: "setCounterparty" })
  async loadCounterpartyById(id: number) {
    this.context.commit("setIsCounterpartyLoading", true);

    return axios
      .get(`/api/accounting/counterparties/${id}`)
      .then((result: AxiosResponse<CounterpartyResponseModel>) => result.data)
      .finally(() => this.context.commit("setIsCounterpartyLoading", false));
  }

  @Action({ commit: "setShortCounterparties" })
  async loadCounterpartiesByBankId(bankId: string) {
    this.context.commit("setIsCounterpartiesLoading", true);

    return axios
      .get(`/api/accounting/counterparties/bank-id/${bankId}/current`)
      .then(
        (result: AxiosResponse<Array<CounterpartyShortResponseModel>>) =>
          result.data
      )
      .finally(() => this.context.commit("setIsCounterpartiesLoading", false));
  }

  @Action
  async createCounterparty(payload: CounterpartyRequestModel) {
    this.context.commit("setIsCounterpartySaving", true);

    return axios
      .post(`/api/accounting/counterparties`, payload)
      .then((result: AxiosResponse<CounterpartyResponseModel>) => {
        this.context.commit("addNotification", {
          message: {
            key: "accountingPortal.counterparties.notification.createdSuccess",
            params: [payload.name],
          },
        });

        return result.data;
      })
      .finally(() => this.context.commit("setIsCounterpartySaving", false));
  }

  @Action
  async updateCounterparty({
    payload,
    id,
    name,
    isForList = false,
  }: {
    payload: CounterpartyResponseModel;
    id: number;
    name: string;
    isForList: boolean;
  }) {
    this.context.commit("setIsCounterpartySaving", true);

    return axios
      .patch(`/api/accounting/counterparties/${id}`, payload)
      .then((result: AxiosResponse<CounterpartyResponseModel>) => {
        this.context.commit("addNotification", {
          message: {
            key: "accountingPortal.counterparties.notification.updatedSuccess",
            params: [name],
          },
        });

        if (!isForList) {
          this.context.commit("updateCounterpartyAfterUpdate", result.data);
        }

        this.context.commit("setCounterpartiesAfterUpdate", result.data);
      })
      .finally(() => this.context.commit("setIsCounterpartySaving", false));
  }

  @Action({ commit: "setCounterpartiesAfterDelete" })
  async deleteCounterparty(id: number) {
    return axios.delete(`/api/accounting/counterparties/${id}`).then(() => {
      this.context.commit("addNotification", {
        message: {
          key: "accountingPortal.counterparties.notification.deletedSuccess",
        },
      });

      return id;
    });
  }

  @Action({ commit: "setIsCounterpartyNameUnique" })
  async checkCounterpartyNameUniqueness(name: string) {
    if (!name) {
      return true;
    }

    return axios
      .post(`/api/accounting/counterparties/unique-name`, {
        name,
      })
      .then((result: AxiosResponse<{ unique: boolean }>) => result.data.unique);
  }

  @Action({ commit: "setThresholds" })
  async loadThresholds(counterpartyId: number) {
    this.context.commit("setIsThresholdsLoading", true);

    return axios
      .get(
        `/api/accounting/counterparties-alert-threshold/counterparty-id/${counterpartyId}`
      )
      .then(
        (response: AxiosResponse<Array<ThresholdResponseModel>>) =>
          response.data
      )
      .finally(() => this.context.commit("setIsThresholdsLoading", false));
  }

  @Action({ commit: "setThresholdsAfterCreate" })
  async createThreshold(payload: ThresholdRequestModel) {
    this.context.commit("setIsThresholdSaving", true);

    return axios
      .post(`/api/accounting/counterparties-alert-threshold`, payload)
      .then((result: AxiosResponse<ThresholdResponseModel>) => result.data)
      .finally(() => this.context.commit("setIsThresholdSaving", false));
  }

  @Action({ commit: "setThresholdsAfterUpdate" })
  async updateThreshold({
    id,
    payload,
  }: {
    id: number;
    payload: ThresholdRequestModel;
  }) {
    this.context.commit("setIsThresholdSaving", true);

    return axios
      .patch(`/api/accounting/counterparties-alert-threshold/${id}`, payload)
      .then((result: AxiosResponse<ThresholdResponseModel>) => result.data)
      .finally(() => this.context.commit("setIsThresholdSaving", false));
  }

  @Action({ commit: "setThresholdsAfterDelete" })
  async deleteThreshold(id: number) {
    return axios
      .delete(`/api/accounting/counterparties-alert-threshold/${id}`)
      .then(() => id);
  }

  @Action({ commit: "setCounterpartyNetworks" })
  async loadCounterpartyNetworks(counterpartyId: number) {
    this.context.commit("setIsNetworksLoading", true);

    return axios
      .get(
        `/api/accounting/counterparty-networks/counterparty-id/${counterpartyId}`
      )
      .then(
        (response: AxiosResponse<Array<CounterpartyNetworkResponseModel>>) =>
          response.data
      )
      .finally(() => this.context.commit("setIsNetworksLoading", false));
  }

  @Action({ commit: "setNetworksAfterCreate" })
  async createCounterpartyNetwork(payload: CounterpartyNetworkRequestModel) {
    this.context.commit("setIsNetworkSaving", true);

    return axios
      .post("/api/accounting/counterparty-networks", payload)
      .then(
        (result: AxiosResponse<CounterpartyNetworkResponseModel>) => result.data
      )
      .finally(() => this.context.commit("setIsNetworkSaving", false));
      // temp comment and need to uncomm in the future
      // .catch(() => {
      //   const validations = this.context.rootState.validationStore.validations;

      //   if (validations.length) {
      //     this.context.commit("addNotification", {
      //       message: {
      //         key: `accountingPortal.counterparties.networks.notification.${
      //           validations[validations.length - 1].errorCode.toLowerCase()
      //         }`,
      //       },
      //       type: NotificationType.ERROR,
      //     });
      //   }
      // })
  }

  @Action({ commit: "setNetworksAfterUpdate" })
  async updateCounterpartyNetwork({
    id,
    payload,
  }: {
    id: number;
    payload: CounterpartyNetworkRequestModel;
  }) {
    this.context.commit("setIsNetworkSaving", true);

    return axios
      .patch(`/api/accounting/counterparty-networks/${id}`, payload)
      .then(
        (result: AxiosResponse<CounterpartyNetworkResponseModel>) => result.data
      )
      .finally(() => this.context.commit("setIsNetworkSaving", false));
  }

  @Action({ commit: "setNetworksAfterDelete" })
  async deleteCounterpartyNetwork(id: number) {
    this.context.commit("setIsNetworksLoading", true);

    return axios
      .delete(`/api/accounting/counterparty-networks/${id}`)
      .then(() => id)
      .finally(() => {
        this.context.commit("setIsNetworksLoading", false);
      });
  }

  @Action({ commit: "setCounterpartyNetRevenues" })
  async loadCounterpartyNetRevenues(counterpartyId: number) {
    this.context.commit("setIsNetRevenuesLoading", true);

    return axios
      .get(
        `/api/accounting/counterparties-net/counterparty-id/${counterpartyId}`
      )
      .then((response: AxiosResponse<Array<NetResponseModel>>) => response.data)
      .finally(() => this.context.commit("setIsNetRevenuesLoading", false));
  }

  @Action({ commit: "setCounterpartyNetRevenues" })
  async createCounterpartyNetRevenue(payload: CounterpartyNetRequestModel) {
    this.context.commit("setIsNetRevenuesSaving", true);

    return axios
      .post(`/api/accounting/counterparties-net`, payload)
      .then((result: AxiosResponse<Array<NetResponseModel>>) => result.data)
      .finally(() => this.context.commit("setIsNetRevenuesSaving", false));
  }

  @Action({ commit: "setCounterpartyNetRevenues" })
  async updateCounterpartyNetRevenue({
    counterpartyId,
    payload,
  }: {
    counterpartyId: number;
    payload: CounterpartyNetRequestModel;
  }) {
    this.context.commit("setIsNetRevenuesSaving", true);

    return axios
      .put(
        `/api/accounting/counterparties-net/counterparty-id/${counterpartyId}`,
        payload
      )
      .then((result: AxiosResponse<Array<NetResponseModel>>) => result.data)
      .finally(() => this.context.commit("setIsNetRevenuesSaving", false));
  }

  @Action({ commit: "setCounterpartyNetRevenues" })
  async deleteAllCounterpartyNetRevenues(counterpartyId: number) {
    this.context.commit("setIsNetRevenuesLoading", true);

    return axios
      .delete(
        `/api/accounting/counterparties-net/counterparty-id/${counterpartyId}`
      )
      .then(() => [])
      .finally(() => {
        this.context.commit("setIsNetRevenuesLoading", false);
      });
  }

  @Action({ commit: "setCounterpartyBanks" })
  async loadCounterpartyBanks(counterpartyId: number) {
    this.context.commit("setIsCounterpartyBanksLoading", true);

    return axios
      .get(`/api/accounting/counterparty-banks`, {
        params: { counterpartyId },
      })
      .then(
        (response: AxiosResponse<Array<CounterpartyBankResponseModel>>) =>
          response.data
      )
      .finally(() =>
        this.context.commit("setIsCounterpartyBanksLoading", false)
      );
  }

  @Action({ commit: "setCounterpartyBanks" })
  async createCounterpartyBanks(payload: CounterpartyNetRequestModel) {
    this.context.commit("setIsCounterpartyBanksSaving", true);

    return axios
      .post("/api/accounting/counterparty-banks", payload)
      .then(
        (result: AxiosResponse<Array<CounterpartyBankResponseModel>>) =>
          result.data
      )
      .finally(() =>
        this.context.commit("setIsCounterpartyBanksSaving", false)
      );
  }

  @Action({ commit: "setCounterpartyBanks" })
  async updateCounterpartyBanks({
    counterpartyId,
    payload,
  }: {
    counterpartyId: number;
    payload: CounterpartyBanksRequestModel;
  }) {
    this.context.commit("setIsCounterpartyBanksSaving", true);

    return axios
      .put(
        `/api/accounting/counterparty-banks/counterparty-id/${counterpartyId}`,
        payload
      )
      .then(
        (result: AxiosResponse<Array<CounterpartyResponseModel>>) => result.data
      )
      .finally(() =>
        this.context.commit("setIsCounterpartyBanksSaving", false)
      );
  }

  @Action({ commit: "setCounterpartyBanks" })
  async deleteAllCounterpartyBanks(counterpartyId: number) {
    this.context.commit("setIsCounterpartyBanksLoading", true);

    return axios
      .delete(
        `/api/accounting/counterparty-banks/counterparty-id/${counterpartyId}`
      )
      .then(() => [])
      .finally(() => {
        this.context.commit("setIsCounterpartyBanksLoading", false);
      });
  }
}
