import {
  BaseGradientFlags,
  BaseReportFilter,
  MultiAppsReportFilter,
  ReportFilter,
  ReportResultItem,
} from "./Report";
import { AggregationPeriod, GroupByFilter } from "./GroupByFilter";
import { ReportHeader, ReportHeaderUtil } from "./ReportHeader";
import { ReportType } from "./ReportType";
import {
  DatesFilterModel,
  FilterId,
  recordToFilterModel,
  FilterModel,
  Application,
  FilterPreviewId,
  FilterPreview,
  DefaultValues,
  DictionaryType,
} from "@/shared/models";
import { LangService } from "@/shared/types/LangType";
import {
  NetRevenueReportFilterExtension,
  ProvidedBannerReportFilterExtension,
} from "./ReportFilterExtension";
import { ChartName } from "@/chart/models/ChartModel";
import { ReportItemRowObject } from "./ReportItemRowObject";

export class RevenueGradientFlags extends BaseGradientFlags {
  constructor(public report: ReportFilter) {
    super();

    this.flags = {
      [`${ReportResultItem.PREFIX}total`]: true,
      [`${ReportResultItem.PREFIX}inApps`]: true,
      [`${ReportResultItem.PREFIX}subscription`]: true,
      [`${ReportResultItem.PREFIX}totalAdRevenue`]: true,
      [`${ReportResultItem.PREFIX}banner`]: true,
      [`${ReportResultItem.PREFIX}interstitial`]: true,
      [`${ReportResultItem.PREFIX}rewarded`]: true,
    };
  }
}

export class RevenueFilter
  extends MultiAppsReportFilter
  implements NetRevenueReportFilterExtension
{
  constructor(
    availableApps: Array<Application>,
    platforms?: Array<string>,
    apps?: Array<string>,
    includedApps?: boolean,
    filter?: Array<FilterModel>,
    date: DatesFilterModel = DefaultValues.initDatesFilterModel(
      FilterId.EVENTS_DATE
    ),
    groupByFilter: GroupByFilter = new GroupByFilter(AggregationPeriod.DAY, []),
    public netRevenue = true
  ) {
    super(
      ReportType.REVENUE,
      availableApps,
      platforms,
      apps,
      includedApps,
      filter,
      date,
      groupByFilter
    );
  }

  get previews(): Array<FilterPreview> {
    return [
      ...super.previews,
      {
        id: FilterPreviewId.NET_REVENUE,
        value: this.netRevenue,
      },
    ];
  }

  get charts(): Array<ChartName> {
    if (this.groupByFilter.groupBy.length > 1) {
      return [];
    }

    return this.groupByFilter.isNotEmptyGroupBy
      ? [
          ChartName.REVENUE_REPORT_IAP_REVENUE_CHART,
          ChartName.REVENUE_REPORT_REWARDED_CHART,
          ChartName.REVENUE_REPORT_INTERSTITIAL_CHART,
          ChartName.REVENUE_REPORT_BANNER_CHART,
        ]
      : [
          ChartName.REVENUE_REPORT_STACKED_CHART,
          ChartName.REVENUE_REPORT_LINEAR_CHART,
        ];
  }

  get groupedCharts(): Array<ChartName> {
    return this.groupByFilter.isNotEmptyGroupBy
      ? [
          ChartName.REVENUE_REPORT_STACKED_CHART,
          ChartName.REVENUE_REPORT_LINEAR_CHART,
        ]
      : [];
  }

  static of(
    availableApps: Array<Application>,
    requestQuery?: Record<string, any>
  ): RevenueFilter {
    return new RevenueFilter(
      availableApps,
      requestQuery?.platforms as Array<string>,
      requestQuery?.apps as Array<string>,
      typeof requestQuery?.includedApps === "string"
        ? requestQuery?.includedApps === "true"
        : requestQuery?.includedApps,
      requestQuery?.filter?.map((it: any) => recordToFilterModel(it)) || [],
      DatesFilterModel.ofRecord(FilterId.EVENTS_DATE, requestQuery?.date),
      GroupByFilter.of(requestQuery?.groupByFilter)
    );
  }
}

export class PricedRevenueFilter
  extends BaseReportFilter
  implements
    NetRevenueReportFilterExtension,
    ProvidedBannerReportFilterExtension
{
  constructor(
    app: Application,
    filter?: Array<FilterModel>,
    date?: DatesFilterModel,
    groupByFilter: GroupByFilter = new GroupByFilter(AggregationPeriod.DAY, []),
    public netRevenue = true,
    public providedBanner = DefaultValues.PROVIDED_BANNER
  ) {
    super(ReportType.PRICED_REVENUE, app, filter, date, groupByFilter);
  }

  get invalid(): boolean {
    return !(this.app && this.date.valid);
  }

  get previews(): Array<FilterPreview> {
    return [
      ...super.previews,
      {
        id: FilterPreviewId.NET_REVENUE,
        value: this.netRevenue,
      },
      {
        id: FilterPreviewId.PROVIDED_BANNER,
        value: this.providedBanner,
      },
    ];
  }

  get charts(): Array<ChartName> {
    if (this.groupByFilter.groupBy.length > 1) {
      return [];
    }

    return this.groupByFilter.isNotEmptyGroupBy
      ? [
          ChartName.PRICED_REVENUE_IAP_REVENUE_CHART,
          ChartName.PRICED_REVENUE_SUBSCRIPTION_CHART,
          ChartName.PRICED_REVENUE_REWARDED_CHART,
          ChartName.PRICED_REVENUE_INTERSTITIAL_CHART,
          ChartName.PRICED_REVENUE_BANNER_CHART,
        ]
      : [
          ChartName.PRICED_REVENUE_STACKED_CHART,
          ChartName.PRICED_REVENUE_LINEAR_CHART,
        ];
  }

  get groupedCharts(): Array<ChartName> {
    return this.groupByFilter.isNotEmptyGroupBy
      ? [
          ChartName.PRICED_REVENUE_STACKED_CHART,
          ChartName.PRICED_REVENUE_LINEAR_CHART,
        ]
      : [];
  }

  get isDefaultProvidedBanner(): boolean {
    return this.providedBanner === DefaultValues.PROVIDED_BANNER;
  }

  get isBannerProvidedDisabled(): boolean {
    return false;
  }

  toRequestQuery(): Record<string, any> {
    return {
      ...super.toRequestQuery(),
      netRevenue: this.netRevenue,
      providedBanner: this.providedBanner,
    };
  }

  static of(
    app: Application,
    requestQuery?: Record<string, any>
  ): PricedRevenueFilter {
    return requestQuery
      ? new PricedRevenueFilter(
          app,
          requestQuery?.filter?.map((it: any) => recordToFilterModel(it)) || [],
          DatesFilterModel.ofRecord(FilterId.EVENTS_DATE, requestQuery?.date),
          GroupByFilter.of(requestQuery?.groupByFilter),
          typeof requestQuery?.netRevenue === "string"
            ? requestQuery?.netRevenue === "true"
            : requestQuery?.netRevenue,
          requestQuery.providedBanner
        )
      : new PricedRevenueFilter(app);
  }

  getUsedDictionaryValues(): Record<string, Array<string>> {
    const usedDictionaryValues = super.getUsedDictionaryValues();

    if (usedDictionaryValues[DictionaryType.ROOT_AD_NETWORK_NAMES]?.length) {
      usedDictionaryValues[DictionaryType.PRICED_NETWORK_NAMES] =
        usedDictionaryValues[DictionaryType.ROOT_AD_NETWORK_NAMES];
      delete usedDictionaryValues[DictionaryType.ROOT_AD_NETWORK_NAMES];
    }

    return usedDictionaryValues;
  }
}

export class RevenueHeaders {
  static init(lang: LangService, report: RevenueFilter): Array<ReportHeader> {
    return [
      ReportHeaderUtil.createDate(lang, report.groupByFilter.aggregationPeriod),
      ...(report.groupByFilter.isNotEmptyGroupBy
        ? ReportHeaderUtil.createGroupBy(lang, report.groupByFilter, 200)
        : []),
      {
        text: lang("components.baseTable.total"),
        align: "end",
        value: `${ReportResultItem.PREFIX}total`,
        hasGradient: true,
      },
      {
        text: lang("components.baseTable.iapRevenue"),
        align: "end",
        value: `${ReportResultItem.PREFIX}inApps`,
        hasGradient: true,
      },
      {
        text: lang("components.baseTable.subscription"),
        align: "end",
        value: `${ReportResultItem.PREFIX}subscription`,
        hasGradient: true,
      },
      {
        text: lang("components.baseTable.totalAdRevenue"),
        align: "end",
        value: `${ReportResultItem.PREFIX}totalAdRevenue`,
        hasGradient: true,
      },
      {
        text: lang("components.baseTable.banner"),
        align: "end",
        value: `${ReportResultItem.PREFIX}banner`,
        hasGradient: true,
      },
      {
        text: lang("components.baseTable.interstitial"),
        align: "end",
        value: `${ReportResultItem.PREFIX}interstitial`,
        hasGradient: true,
      },
      {
        text: lang("components.baseTable.rewarded"),
        align: "end",
        value: `${ReportResultItem.PREFIX}rewarded`,
        hasGradient: true,
      },
    ];
  }
}

export class RevenueResultItem extends ReportResultItem {
  constructor(row: ReportItemRowObject, filter: ReportFilter) {
    super();
    const { groupByFilter } = filter;
    this.date = row.getByHeader("date");
    this.setFormattedDate(
      this.date,
      filter.date.from,
      filter.date.to,
      groupByFilter.aggregationPeriod
    );
    this.setGroupByValue(groupByFilter, row);
    this.data["total"] = this.parseValue(row.getByHeader("total"));
    this.data["inApps"] = this.parseValue(row.getByHeader("inapps")) || 0;
    this.data["totalAdRevenue"] = this.parseValue(
      row.getByHeader("totalAdRevenue")
    );
    this.data["banner"] = this.parseValue(row.getByHeader("banner"));
    this.data["interstitial"] = this.parseValue(
      row.getByHeader("interstitial")
    );
    this.data["rewarded"] = this.parseValue(row.getByHeader("rewarded"));
    this.data["subscription"] = this.parseValue(
      row.getByHeader("subscription")
    );
  }

  protected parseValue(value: string) {
    return Number.parseFloat(value) >= 1000
      ? super.parseFloat(value)
      : super.parseFloat(value, 2);
  }
}
