import { FilterId } from "@/shared/models";

export enum FunnelChartMode {
  FUNNEL = "FUNNEL",
  COLUMN = "COLUMN",
  TABLE = "TABLE",
}

export enum FunnelChartPercentMode {
  RELATIVE = "RELATIVE",
  FIRST_STEP = "FIRST_STEP",
  TOTAL = "TOTAL",
}

export class FunnelChartDataTableModel {
  constructor(public data: Record<string, number>, public name: string) {}
}

export class FunnelChartTableHeaderDataModel {
  constructor(
    public text: string,
    public align: string,
    public value: string,
    public width?: string
  ) {}
}

export default class FunnelChartModel {
  breakdownValue?: Map<FilterId, any>;
  stepsValue: Map<number, number>;

  constructor(
    breakdownValue: Map<FilterId, any>,
    stepsValue: Map<number, number>
  ) {
    this.breakdownValue = breakdownValue;
    this.stepsValue = stepsValue;
  }

  static of(breakdownValue?: any, stepsValue?: any): FunnelChartModel {
    const modelBreakdownValue = new Map(
      Object.keys(breakdownValue || []).map((it) => [
        it as FilterId,
        breakdownValue[it],
      ])
    );
    const modelStepsValue = new Map(
      Object.keys(stepsValue || []).map((it) => [
        Number.parseInt(it),
        stepsValue[it],
      ])
    );
    return new FunnelChartModel(modelBreakdownValue, modelStepsValue);
  }
}

export interface FunnelChartStep {
  number: number;
  name: string;
  description?: string;
}

export interface FunnelChartData {
  data: Array<FunnelChartModel>;
  steps: Array<FunnelChartStep>;
}

export interface FunnelChartDataItem {
  name: string;
  value: number;
  percent: number;
  description?: string;
}
