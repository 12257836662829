import { AccessType } from "@/shared/models";
import { SegmentType } from "./SegmentModel";

export class SegmentUniqueNameRequestModel {
  constructor(public name: string) {}
}

export class SegmentUniqueNameResponseModel {
  constructor(public unique: boolean) {}
}

export class UpdateSegmentRequestModel {
  constructor(public description?: string, public accessType?: AccessType) {}
}

export class ConvertSegmentRequestModel {
  constructor(
    public id: number,
    public applicationId: string,
    public newType: SegmentType
  ) {}
}

export class GetSegmentRequestModel {
  constructor(public id: number, public applicationId: string) {}
}
