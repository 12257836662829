import { FunnelChartStep } from "@/funnels/models/FunnelChartModel";

export const calculateRelativePercent = (
  index: number,
  value: number,
  stepValues: Array<number>
): number => {
  if (!index) {
    return 100;
  }

  return stepValues[index - 1]
    ? Math.round((value / stepValues[index - 1]) * 100)
    : 0;
};

export const calculateFirstStepPercent = (
  value: number,
  stepValues: Array<number>
): number => (stepValues[0] ? Math.round((value / stepValues[0]) * 100) : 0);

export const calculateTotalPercent = (value: number, totalValue: number) =>
  totalValue ? Math.round((value / totalValue) * 100) : 0;

export const processFunnelSteps = (
  steps: Array<FunnelChartStep>
): Array<FunnelChartStep> => {
  return steps.map((step, index) =>
    steps.slice(0, index).some(({ name }) => name === step.name)
      ? { ...step, name: `${step.name} (${step.number})` }
      : step
  );
};
