import { ChartName } from "@/chart/models/ChartModel";
import {
  AdRevenueMethod,
  Application,
  DatesFilterModel,
  DefaultValues,
  FilterId,
  FilterModel,
  FilterPreviewId,
  ProvidedBannerType,
  recordToFilterModel,
} from "@/shared/models";
import { LangService } from "@/shared/types/LangType";
import DateUtil from "@/shared/utils/DateUtil";
import { GroupByFilter } from "./GroupByFilter";
import {
  BaseGradientFlags,
  BaseReportFilter,
  ReportFilter,
  ReportResultItem,
} from "./Report";
import {
  AdRevenueMethodReportFilterExtension,
  NetRevenueReportFilterExtension,
  ProvidedBannerReportFilterExtension,
  UserActivityReportFilterExtension,
} from "./ReportFilterExtension";
import { ReportHeader, ReportHeaderUtil } from "./ReportHeader";
import { ReportType } from "./ReportType";
import { ReportItemRowObject } from "./ReportItemRowObject";

export class ArpdauGradientFlags extends BaseGradientFlags {
  constructor() {
    super();

    this.flags = {
      [`${ReportResultItem.PREFIX}revenue`]: false,
      [`${ReportResultItem.PREFIX}arpdau`]: true,
      [`${ReportResultItem.PREFIX}arpdauIap`]: true,
      [`${ReportResultItem.PREFIX}subscription`]: true,
      [`${ReportResultItem.PREFIX}arpdauAd`]: true,
      [`${ReportResultItem.PREFIX}banner`]: true,
      [`${ReportResultItem.PREFIX}interstitial`]: true,
      [`${ReportResultItem.PREFIX}rewarded`]: true,
    };
  }
}

export enum UsersActivityType {
  ALL = "ALL",
  AD_ACTIVE = "AD_ACTIVE",
}

export class ArpdauFilter
  extends BaseReportFilter
  implements
    UserActivityReportFilterExtension,
    AdRevenueMethodReportFilterExtension,
    ProvidedBannerReportFilterExtension,
    NetRevenueReportFilterExtension
{
  usersActivityType: UsersActivityType;
  adRevenueMethod: AdRevenueMethod;
  providedBanner: string;

  constructor(
    app: Application,
    filter?: Array<FilterModel>,
    date?: DatesFilterModel,
    groupByFilter?: GroupByFilter,
    usersActivityType: UsersActivityType = UsersActivityType.ALL,
    adRevenueMethod: AdRevenueMethod = AdRevenueMethod.AVERAGE,
    providedBanner = DefaultValues.PROVIDED_BANNER,
    public netRevenue = true
  ) {
    super(ReportType.ARPDAU, app, filter, date, groupByFilter);
    this.usersActivityType = usersActivityType;
    this.adRevenueMethod = adRevenueMethod;
    this.providedBanner = providedBanner;
  }

  get previews() {
    return [
      ...super.previews,
      {
        id: FilterPreviewId.USER_ACTIVITY,
        value: this.usersActivityType,
      },
      {
        id: FilterPreviewId.PROVIDED_BANNER,
        value: this.providedBanner,
      },
      {
        id: FilterPreviewId.AD_REVENUE_METHOD,
        value: this.adRevenueMethod,
      },
      {
        id: FilterPreviewId.NET_REVENUE,
        value: this.netRevenue,
      },
    ];
  }

  static of(app: Application, filter?: Record<string, any>): ArpdauFilter {
    return filter
      ? new ArpdauFilter(
          app,
          filter.filter && filter.filter.length > 0
            ? filter.filter.map((it: any) => recordToFilterModel(it))
            : [],
          filter.date
            ? DatesFilterModel.ofRecord(FilterId.EVENTS_DATE, filter.date)
            : undefined,
          GroupByFilter.of(filter?.groupByFilter),
          filter.usersActivityType as UsersActivityType,
          filter.adRevenueMethod as AdRevenueMethod,
          filter.providedBanner
          // TODO: NET_REVENUE need to uncomment later
          // typeof filter.netRevenue === "string"
          //   ? filter.netRevenue === "true"
          //   : filter.netRevenue,
        )
      : new ArpdauFilter(app);
  }

  get invalid(): boolean {
    return (
      !(this.app && this.date.valid) ||
      (this.isBannerProvidedDisabled &&
        this.providedBanner === ProvidedBannerType.FIREBASE)
    );
  }

  get groupByCountry(): boolean {
    return !!this.groupByFilter.groupBy.find((it) => it == FilterId.COUNTRY);
  }

  set groupByCountry(payload: boolean) {
    if (payload) {
      this.groupByFilter.groupBy.push(FilterId.COUNTRY);
    } else {
      const index = this.groupByFilter.groupBy.indexOf(FilterId.COUNTRY, 0);
      if (index > -1) {
        this.groupByFilter.groupBy.splice(index, 1);
      }
    }
  }

  get isDefaultProvidedBanner(): boolean {
    return this.providedBanner === DefaultValues.PROVIDED_BANNER;
  }

  get isBannerProvidedDisabled(): boolean {
    return this.adRevenueMethod === AdRevenueMethod.AVERAGE;
  }

  get charts(): Array<ChartName> {
    return this.groupByFilter.isNotEmptyGroupBy
      ? [
          ChartName.ARPDAU_ARPDAU_CHART,
          ChartName.ARPDAU_ARPDAU_IAP_CHART,
          ChartName.ARPDAU_SUBSCRIPTION_CHART,
          ChartName.ARPDAU_BANNER_CHART,
          ChartName.ARPDAU_INTERSTITIAL_CHART,
          ChartName.ARPDAU_REWARDED_CHART,
        ]
      : [ChartName.ARPDAU_REPORT_CHART];
  }

  get groupedCharts(): Array<ChartName> {
    return this.groupByFilter.isNotEmptyGroupBy
      ? [ChartName.ARPDAU_REPORT_CHART]
      : [];
  }

  toRequestQuery(): Record<string, any> {
    return {
      ...super.toRequestQuery(),
      usersActivityType: this.usersActivityType,
      adRevenueMethod: this.adRevenueMethod,
      providedBanner: this.providedBanner,
      // TODO: NET_REVENUE need to uncomment later
      // netRevenue: this.netRevenue,
    };
  }
}

export class ArpdauHeaders {
  static readonly PRECISION = 4;

  static init(lang: LangService, report: ArpdauFilter): Array<ReportHeader> {
    const result: Array<ReportHeader> = [ReportHeaderUtil.createDate(lang)];

    if (report.groupByFilter.isNotEmptyGroupBy) {
      result.push(
        ...ReportHeaderUtil.createGroupBy(lang, report.groupByFilter)
      );
    }

    result.push(
      {
        text: lang("components.baseTable.activeDevices"),
        align: "end",
        value: `${ReportResultItem.PREFIX}activeDevices`,
        fractionDigits: 0,
      },
      {
        text: lang("components.baseTable.revenue"),
        align: "end",
        value: `${ReportResultItem.PREFIX}revenue`,
        hasGradient: true,
        fractionDigits: 0,
      },
      {
        text: lang("components.baseTable.arpdau"),
        align: "end",
        value: `${ReportResultItem.PREFIX}arpdau`,
        hasGradient: true,
        fractionDigits: ArpdauHeaders.PRECISION,
      },
      {
        text: lang("components.baseTable.arpdauIap"),
        align: "end",
        value: `${ReportResultItem.PREFIX}arpdauIap`,
        hasGradient: true,
        fractionDigits: ArpdauHeaders.PRECISION,
      },
      {
        text: lang("components.baseTable.subscription"),
        align: "end",
        value: `${ReportResultItem.PREFIX}subscription`,
        hasGradient: true,
        fractionDigits: ArpdauHeaders.PRECISION,
      },
      {
        text: lang("components.baseTable.arpdauAd"),
        align: "end",
        value: `${ReportResultItem.PREFIX}arpdauAd`,
        hasGradient: true,
        fractionDigits: ArpdauHeaders.PRECISION,
      },
      {
        text: lang("components.baseTable.banner"),
        align: "end",
        value: `${ReportResultItem.PREFIX}banner`,
        hasGradient: true,
        fractionDigits: ArpdauHeaders.PRECISION,
      },
      {
        text: lang("components.baseTable.interstitial"),
        align: "end",
        value: `${ReportResultItem.PREFIX}interstitial`,
        hasGradient: true,
        fractionDigits: ArpdauHeaders.PRECISION,
      },
      {
        text: lang("components.baseTable.rewarded"),
        align: "end",
        value: `${ReportResultItem.PREFIX}rewarded`,
        hasGradient: true,
        fractionDigits: ArpdauHeaders.PRECISION,
      }
    );

    return result;
  }
}

export class ArpdauResultItem extends ReportResultItem {
  // FOR VUE 3 - filter: ReportFilter needs to be replaced with ArpdauFilter and it needs to do in all reports
  constructor(row: ReportItemRowObject, filter: ReportFilter) {
    super();
    const { groupByFilter } = filter;

    this.date = row.getByHeader("date");
    this.formattedDate = DateUtil.formatDate(this.date);
    const isTotalRow = !this.date;
    this.setGroupByValue(groupByFilter, row);

    this.data["activeDevices"] =
      super.parseInt(row.getByHeader("activeDevices")) || (isTotalRow ? "" : 0);
    this.data["revenue"] =
      Math.round(Number.parseFloat(row.getByHeader("revenue"))) ||
      (isTotalRow ? "" : 0);
    this.data["arpdau"] = super.parseFloat(
      row.getByHeader("arpdau"),
      ArpdauHeaders.PRECISION
    );
    this.data["arpdauAd"] = super.parseFloat(
      row.getByHeader("arpdauad"),
      ArpdauHeaders.PRECISION
    );
    this.data["arpdauIap"] = super.parseFloat(
      row.getByHeader("arpdauiap"),
      ArpdauHeaders.PRECISION
    );
    this.data["subscription"] = super.parseFloat(
      row.getByHeader("subscription"),
      ArpdauHeaders.PRECISION
    );
    this.data["banner"] = super.parseFloat(
      row.getByHeader("banner"),
      ArpdauHeaders.PRECISION
    );
    this.data["interstitial"] = super.parseFloat(
      row.getByHeader("interstitial"),
      ArpdauHeaders.PRECISION
    );
    this.data["rewarded"] = super.parseFloat(
      row.getByHeader("rewarded"),
      ArpdauHeaders.PRECISION
    );
  }

  get activeDevices(): number {
    return this.data["activeDevices"];
  }
  set activeDevices(val: number) {
    this.data["activeDevices"] = val;
  }
  get country(): string {
    return this.data["country"];
  }
  set country(val: string) {
    this.data["country"] = val;
  }
  get revenue(): number {
    return this.data["revenue"];
  }
  set revenue(val: number) {
    this.data["revenue"] = val;
  }
  get arpdau(): number {
    return this.data["arpdau"];
  }
  set arpdau(val: number) {
    this.data["arpdau"] = val;
  }
  get arpdauAd(): number {
    return this.data["arpdauAd"];
  }
  set arpdauAd(val: number) {
    this.data["arpdauAd"] = val;
  }
  get arpdauIap(): number {
    return this.data["arpdauIap"];
  }
  set arpdauIap(val: number) {
    this.data["arpdauIap"] = val;
  }
  get banner(): number {
    return this.data["banner"];
  }
  set banner(val: number) {
    this.data["banner"] = val;
  }
  get interstitial(): number {
    return this.data["interstitial"];
  }
  set interstitial(val: number) {
    this.data["interstitial"] = val;
  }
  get rewarded(): number {
    return this.data["rewarded"];
  }
  set rewarded(val: number) {
    this.data["rewarded"] = val;
  }
}
