import { BaseReportFilter, ReportFilter, ReportResultItem } from "./Report";
import { AggregationPeriod, GroupByFilter } from "./GroupByFilter";
import { GroupByType, ReportHeader, ReportHeaderUtil } from "./ReportHeader";
import { ReportType } from "./ReportType";
import {
  DatesFilterModel,
  FilterId,
  recordToFilterModel,
  FilterModel,
  Application,
} from "@/shared/models";
import { LangService } from "@/shared/types/LangType";
import { AdType, AdTypeShort } from "@/shared/models/AdType";
import { ReportItemRowObject } from "./ReportItemRowObject";

export class FirebaseFillRateFilter extends BaseReportFilter {
  constructor(
    app: Application,
    filter?: Array<FilterModel>,
    date?: DatesFilterModel,
    groupByFilter = new GroupByFilter(AggregationPeriod.DAY, [], false)
  ) {
    super(ReportType.FIREBASE_FILL_RATE, app, filter, date, groupByFilter);
  }

  get invalid(): boolean {
    return !(this.app && this.date.valid);
  }

  static of(
    app: Application,
    requestQuery?: Record<string, any>
  ): FirebaseFillRateFilter {
    return new FirebaseFillRateFilter(
      app,
      requestQuery?.filter?.map((it: any) => recordToFilterModel(it)) || [],
      DatesFilterModel.ofRecord(FilterId.EVENTS_DATE, requestQuery?.date),
      new GroupByFilter(
        AggregationPeriod.DAY,
        requestQuery?.groupByFilter?.groupBy,
        false
      )
    );
  }
}

export class FirebaseFillRateHeaders {
  static readonly AD_TYPES = [
    AdType.BANNER,
    AdTypeShort.INTER,
    AdTypeShort.REWARD,
  ];
  static readonly METRICS = [
    "FailedEvents",
    "FailedUsers",
    "LoadedEvents",
    "LoadedUsers",
    "Requests",
    "RequestsEvents",
    "RequestsUsers",
    "FillRate",
    "Events",
    "ImpressionsEvents",
    "RequestsEventsPerDau",
    "ImpressionsEventsPerDau",
  ];

  static init(
    lang: LangService,
    report: FirebaseFillRateFilter,
    groupBy: GroupByType
  ): Array<ReportHeader> {
    return [
      ReportHeaderUtil.createDate(lang, report.groupByFilter.aggregationPeriod),
      ...(report.groupByFilter.isNotEmptyGroupBy
        ? ReportHeaderUtil.createGroupBy(lang, report.groupByFilter)
        : []),
      {
        value: `${ReportResultItem.PREFIX}dau`,
        text: lang(`components.baseTable.dau`),
        align: "end",
        width: 90,
        hasGradient: true,
      },
      ...this.generateFirebaseFillRateHeaders(lang, groupBy),
    ];
  }

  static generateFirebaseFillRateHeaders(
    lang: LangService,
    groupBy: GroupByType
  ): Array<ReportHeader> {
    const result: Array<ReportHeader> = [];

    if (groupBy === GroupByType.AD_TYPES) {
      FirebaseFillRateHeaders.AD_TYPES.forEach((adType) => {
        result.push(
          ...FirebaseFillRateHeaders.METRICS.reduce((result, metric) => {
            if (
              (adType === AdType.BANNER &&
                ["Requests", "Events", "RequestsUsers"].includes(metric)) ||
              ([AdTypeShort.INTER, AdTypeShort.REWARD].includes(
                adType as AdTypeShort
              ) &&
                ["Events", "Requests"].includes(metric))
            ) {
              return result;
            }

            return [
              ...result,
              {
                value: `${
                  ReportResultItem.PREFIX
                }${adType.toLowerCase()}${metric}`,
                text: lang(
                  `components.baseTable.${adType.toLowerCase()}${metric}`
                ),
                align: "end",
                width: 90,
                groupBy: {
                  border: {
                    left: metric === FirebaseFillRateHeaders.METRICS[0],
                    right:
                      adType ===
                        FirebaseFillRateHeaders.AD_TYPES[
                          FirebaseFillRateHeaders.AD_TYPES.length - 1
                        ] &&
                      metric ===
                        FirebaseFillRateHeaders.METRICS[
                          FirebaseFillRateHeaders.METRICS.length - 1
                        ],
                  },
                  hasBackground: FirebaseFillRateHeaders.AD_TYPES.filter(
                    (_, index) => index % 2 === 0
                  ).includes(adType),
                },
                fractionDigits: [
                  "RequestsEventsPerDau",
                  "FillRate",
                  "ImpressionsEventsPerDau",
                ].includes(metric)
                  ? 2
                  : 0,
                hasGradient: true,
              } as ReportHeader,
            ];
          }, [] as Array<ReportHeader>)
        );
      });
    } else {
      FirebaseFillRateHeaders.METRICS.forEach((metric) => {
        result.push(
          ...FirebaseFillRateHeaders.AD_TYPES.reduce((result, adType) => {
            if (
              (adType === AdType.BANNER &&
                ["Requests", "Events", "RequestsUsers"].includes(metric)) ||
              ([AdTypeShort.INTER, AdTypeShort.REWARD].includes(
                adType as AdTypeShort
              ) &&
                ["Events", "Requests"].includes(metric))
            ) {
              return result;
            }

            return [
              ...result,
              {
                value: `${
                  ReportResultItem.PREFIX
                }${adType.toLowerCase()}${metric}`,
                text: lang(
                  `components.baseTable.${adType.toLowerCase()}${metric}`
                ),
                align: "end",
                width: 90,
                groupBy: {
                  border: {
                    left:
                      adType === FirebaseFillRateHeaders.AD_TYPES[0] ||
                      (adType === AdTypeShort.INTER &&
                        metric === "RequestsUsers"),
                    right:
                      adType ===
                        FirebaseFillRateHeaders.AD_TYPES[
                          FirebaseFillRateHeaders.AD_TYPES.length - 1
                        ] &&
                      metric ===
                        FirebaseFillRateHeaders.METRICS[
                          FirebaseFillRateHeaders.METRICS.length - 1
                        ],
                  },
                  hasBackground: FirebaseFillRateHeaders.METRICS.filter(
                    (_, index) => index % 2 === 0
                  ).includes(metric),
                },
                fractionDigits: [
                  "RequestsEventsPerDau",
                  "FillRate",
                  "ImpressionsEventsPerDau",
                ].includes(metric)
                  ? 2
                  : 0,
                hasGradient: true,
              } as ReportHeader,
            ];
          }, [] as Array<ReportHeader>)
        );
      });
    }

    return result;
  }
}

export class FirebaseFillRateResultItem extends ReportResultItem {
  constructor(row: ReportItemRowObject, filter: ReportFilter) {
    const { groupByFilter } = filter;

    super();
    this.date = row.getByHeader("date");
    this.setFormattedDate(
      this.date,
      filter.date.from,
      filter.date.to,
      groupByFilter.aggregationPeriod
    );
    this.setGroupByValue(groupByFilter, row);
    this.data = {
      ...this.data,
      ...[
        "dau",
        "bannerRequestsEvents",
        "bannerFailedEvents",
        "bannerFailedUsers",
        "bannerLoadedEvents",
        "bannerLoadedUsers",
        "bannerFillRate",
        "bannerImpressionsEvents",
        "bannerRequestsEventsPerDau",
        "bannerImpressionsEventsPerDau",
        "interFailedEvents",
        "interFailedUsers",
        "interLoadedEvents",
        "interLoadedUsers",
        "interRequestsEvents",
        "interRequestsUsers",
        "interFillRate",
        "interImpressionsEvents",
        "interRequestsEventsPerDau",
        "interImpressionsEventsPerDau",
        "rewardFailedEvents",
        "rewardFailedUsers",
        "rewardLoadedEvents",
        "rewardLoadedUsers",
        "rewardRequestsEvents",
        "rewardRequestsUsers",
        "rewardFillRate",
        "rewardImpressionsEvents",
        "rewardRequestsEventsPerDau",
        "rewardImpressionsEventsPerDau",
      ].reduce(
        (result, metric) => ({
          ...result,
          [metric]: row.getByHeader(metric),
        }),
        {}
      ),
    };
  }
}
