






















import { Component, Vue, Prop } from "vue-property-decorator";

import { ReportItemModel } from "@/reports/models/ReportMenu";
import { ReportType } from "@/reports/models";

@Component
export default class ReportMenuItem extends Vue {
  @Prop() report!: ReportItemModel;

  get applicationId(): string {
    return this.$route.params.id;
  }

  get hash(): string | undefined {
    return this.$store.state.reportStore.isCommonReportFilterEnabled
      ? this.$store.state.reportStore.tabHash
      : undefined;
  }

  get isVisible(): boolean {
    return !(
      this.report.getId() === ReportType.SKAD &&
      this.$store.getters.applicationsById.get(this.applicationId)
        ?.platformType !== "ios"
    );
  }
}
