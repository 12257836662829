export default class CounterpartyShortResponseModel {
  constructor(public id: number, public name: string) {}

  static of(
    model: CounterpartyShortResponseModel
  ): CounterpartyShortResponseModel {
    return new CounterpartyShortResponseModel(model.id, model.name);
  }

  static ofArray(
    items: Array<CounterpartyShortResponseModel>
  ): Array<CounterpartyShortResponseModel> {
    return items.map((item) => CounterpartyShortResponseModel.of(item));
  }
}
