import { intersection } from "lodash";
import { NavigationGuardNext, Route } from "vue-router";

import { REPORT_SORT_ORDER, ReportType } from "@/reports/models";
import { AppSection, MenuItems } from "@/shared/models";

const sectionsForRouteName = new Map<MenuItems, Array<AppSection | ReportType>>(
  [
    [
      MenuItems.AB_TESTS,
      [
        AppSection.AB_TESTS,
        AppSection.TARGETED_CONFIGS,
        AppSection.DEFAULT_CONFIG,
        AppSection.EXTERNAL_TESTS,
      ],
    ],
    [MenuItems.REPORT, REPORT_SORT_ORDER],
    [
      MenuItems.TOOLS,
      [
        AppSection.EXPORT_USER_FLOW,
        ReportType.MONETIZATION_MONITORING,
        AppSection.HEALTHCHECK_MARKETING_ALERT,
        AppSection.HEALTHCHECK_MONETIZATION_ALERT,
      ],
    ],
    [
      MenuItems.DASHBOARDS,
      [AppSection.MULTIAPP_DASHBOARDS, AppSection.SINGLEAPP_DASHBOARDS],
    ],
    [
      MenuItems.SETTINGS,
      [
        AppSection.AD_REVENUE_EVENTS,
        AppSection.DICTIONARIES_APP_VERSIONS,
        AppSection.DICTIONARIES_OS_VERSIONS,
        AppSection.DIVIDER,
        AppSection.PLACEMENTS,
        AppSection.NETWORKS_MANAGEMENT,
        AppSection.SUBSCRIPTION_EVENTS,
        AppSection.TAX_PERCENT,
      ],
    ],
    [
      MenuItems.SEGMENT,
      [
        AppSection.CUSTOM_DYNAMIC_SEGMENTS,
        AppSection.CUSTOM_STATIC_SEGMENTS,
        AppSection.AB_TEST_SEGMENTS,
        AppSection.EXTERNAL_TEST_SEGMENTS,
        AppSection.FUNNEL_SEGMENTS,
        AppSection.DIVIDER_SEGMENTS,
      ],
    ],
    [
      MenuItems.TEMPLATE,
      [AppSection.REPORT_TEMPLATES, AppSection.FUNNEL_TEMPLATES],
    ],
    [
      MenuItems.APPLICATION_MANAGER,
      [
        AppSection.APPLICATIONS,
        AppSection.JURISDICTIONS,
        AppSection.SCENARIOS,
        AppSection.BATCHES,
        AppSection.APPLICATION_BASIC_INFO,
        AppSection.APPLICATION_FEATURES_CONTROL,
      ],
    ],
    [
      MenuItems.IAM,
      [
        AppSection.USERS,
        AppSection.AGGREGATORS,
        AppSection.PERMISSIONS_BROWSER,
      ],
    ],
    [
      MenuItems.ACCOUNTING_PORTAL,
      [AppSection.INVOICES, AppSection.BANKS, AppSection.COUNTERPARTIES],
    ],
  ]
);

export function sectionMiddleware({
  to,
  next,
  store,
}: {
  to: Route;
  next: NavigationGuardNext;
  store: any;
}) {
  const { id, type } = to.params;
  const routeSections = sectionsForRouteName.get(to.name as MenuItems);

  if (!routeSections) {
    return next({
      name: "403",
    });
  }

  const availableSections: Array<AppSection | ReportType> = intersection(
    store.state.userStore.currentUser.viewAccessEntities[id],
    routeSections
  ).sort(
    (sectionA, sectionB) =>
      routeSections.indexOf(sectionA) - routeSections.indexOf(sectionB)
  );

  return next({
    name: availableSections[0],
    params: { id, type },
  });
}
