import { AggregationPeriod } from "@/reports/models";
import DateUtil from "../utils/DateUtil";
import { FilterColor, FilterId, FilterPreviewId } from "./FilterModel";

export class FilterPreviewModel {
  constructor(
    public filterPreviewId: FilterPreviewId,
    public color = FilterColor.GREY,
    public defaultValues: Array<string> = [],
    public disabled = false,
    public clearable = false
  ) {}

  get attrs(): Record<string, any> {
    return Object.entries(this).reduce(
      (result, [key, value]) =>
        ["color", "filterId"].includes(key)
          ? result
          : { ...result, [key]: value },
      {}
    );
  }

  // TODO: REPORTS temp getter
  get filterId(): FilterId | undefined {
    return new Map<FilterPreviewId, FilterId>([
      [FilterPreviewId.AD_NETWORK_NAME, FilterId.AD_NETWORK_NAME],
      [FilterPreviewId.ATTRIBUTION_STATUS, FilterId.ATTRIBUTION_STATUS],
      [FilterPreviewId.COUNTRY, FilterId.COUNTRY],
      [FilterPreviewId.DEVICE_TYPE, FilterId.DEVICE_TYPE],
      [FilterPreviewId.EVENTS_APP_VERSION, FilterId.EVENTS_APP_VERSION],
      [FilterPreviewId.EVENTS_COUNTRY, FilterId.EVENTS_COUNTRY],
      [FilterPreviewId.EVENTS_OS_VERSION, FilterId.EVENTS_OS_VERSION],
      [FilterPreviewId.INSTALL_APP_VERSION, FilterId.INSTALL_APP_VERSION],
      [FilterPreviewId.LAT, FilterId.LAT],
      [FilterPreviewId.SEGMENT, FilterId.SEGMENT],
      [FilterPreviewId.EVENT_COST, FilterId.SIMPLE_MULTIPLE_EVENT],
      [FilterPreviewId.SOURCE, FilterId.SOURCE],
      [FilterPreviewId.TRACKER, FilterId.TRACKER],
      [FilterPreviewId.WATERFALLS_ID, FilterId.WATERFALLS_ID],
    ]).get(this.filterPreviewId);
  }
}

export class DateFilterPreviewModel extends FilterPreviewModel {
  constructor(
    public filterPreviewId = FilterPreviewId.ATTRIBUTION_DATE_VALUE,
    public max: string | null = DateUtil.today(),
    public presetStartingDate = DateUtil.twoDaysAgo(),
    public daysLimit: DayLimitFilterPreviewModel | null = new DayLimitFilterPreviewModel()
  ) {
    super(filterPreviewId, FilterColor.YELLOW);
  }
}

export class DayLimitFilterPreviewModel extends FilterPreviewModel {
  constructor(
    public filterPreviewId = FilterPreviewId.DAY_LIMIT,
    public disabled = false
  ) {
    super(filterPreviewId);
  }
}

export class GroupByFilterPreviewModel extends FilterPreviewModel {
  constructor(
    public items: Array<FilterId>,
    public aggregationPeriod = new AggregationPeriodFilterPreviewModel(),
    public multiple = false
  ) {
    super(FilterPreviewId.GROUP_BY, FilterColor.BROWN);
  }
}

export class AggregationPeriodFilterPreviewModel extends FilterPreviewModel {
  constructor(
    public items = [
      AggregationPeriod.DAY,
      AggregationPeriod.WEEK,
      AggregationPeriod.MONTH,
    ],
    public optional = false
  ) {
    super(FilterPreviewId.AGGREGATION_PERIOD);
  }
}
