import * as querystring from "qs";
import Vue from "vue";
import Component from "vue-class-component";
import VueRouter, { RouteConfig } from "vue-router";

import { DashboardType } from "@/dashboard/models/DashboardModel";
import DashboardView from "@/dashboard/views/DashboardView.vue";
import { ReportType } from "@/reports/models";
import { SegmentType } from "@/segments/models/SegmentModel";
import {
  AppSection,
  Application,
  INITIAL_PATH,
  MULTI_APP,
  MenuItems,
  MenuItemsApplicationManager,
  MenuItemsSettings,
  MenuItemsTools,
} from "@/shared/models";
import store from "@/shared/store";
import { sectionMiddleware } from "./middleware/middleware";
import { middlewarePipeline } from "./middleware/middlewarePipeline";

Vue.use(VueRouter);

Component.registerHooks([
  "beforeRouteEnter",
  "beforeRouteLeave",
  "beforeRouteUpdate",
]);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: { path: "/app" },
  },
  {
    path: "/app/logout",
    redirect: { path: "/app/login" },
  },
  {
    path: "/app/login",
    name: "login",
    component: () => import("@/shared/views/LoginView.vue"),
  },
  {
    path: "/app",
    name: "initial",
    component: DashboardView,
  },
  {
    path: "/app/403",
    name: "403",
    component: () => import("@/shared/views/ErrorView.vue"),
  },
  {
    path: "/app/404",
    name: "404",
    component: () => import("@/shared/views/ErrorView.vue"),
  },
  {
    path: "/app/500",
    name: "500",
    component: () => import("@/shared/views/ErrorView.vue"),
  },
  {
    path: "/app/admin/iam",
    name: MenuItems.IAM,
    component: () => import("@/iam/views/IamView.vue"),
    meta: {
      middleware: [sectionMiddleware],
    },
    children: [
      {
        path: "users",
        name: AppSection.USERS,
        component: () => import("@/iam/views/UsersView.vue"),
        children: [
          {
            path: ":user",
            name: "user",
            component: () => import("@/iam/views/UserView.vue"),
          },
        ],
      },
      {
        path: "aggregators",
        name: AppSection.AGGREGATORS,
        component: () => import("@/iam/views/AggregatorsView.vue"),
      },
      {
        path: "permissions-browser",
        name: AppSection.PERMISSIONS_BROWSER,
        component: () => import("@/iam/views/PermissionsBrowserView.vue"),
      },
    ],
  },
  {
    path: "/app/:id/report",
    name: MenuItems.REPORT,
    component: {
      render: (c) => c("router-view"),
    },
    meta: {
      middleware: [sectionMiddleware],
    },
    children: [
      {
        path: `${ReportType.ARPDAU.toLowerCase()}`,
        name: ReportType.ARPDAU,
        component: () => import("@/reports/views/ReportView.vue"),
      },
      {
        path: `${ReportType.ARPDAU_SIMPLE.toLowerCase()}`,
        name: ReportType.ARPDAU_SIMPLE,
        component: () => import("@/reports/views/ReportView.vue"),
      },
      {
        path: `${ReportType.ARPU.toLowerCase()}`,
        name: ReportType.ARPU,
        component: () => import("@/reports/views/ReportView.vue"),
      },
      {
        path: `${ReportType.REVENUE.toLowerCase()}`,
        name: ReportType.REVENUE,
        component: () => import("@/reports/views/ReportView.vue"),
      },
      {
        path: `${ReportType.RETURN_RATE.toLowerCase()}`,
        name: ReportType.RETURN_RATE,
        component: () => import("@/reports/views/ReportView.vue"),
      },
      {
        path: `${ReportType.EVENTS_SUMMARY.toLowerCase()}`,
        name: ReportType.EVENTS_SUMMARY,
        component: () => import("@/reports/views/ReportView.vue"),
      },
      {
        path: `${ReportType.MEASURES_LITE.toLowerCase()}`,
        name: ReportType.MEASURES_LITE,
        component: () => import("@/reports/views/ReportView.vue"),
      },
      {
        path: `${ReportType.TIME_SPENT.toLowerCase()}`,
        name: ReportType.TIME_SPENT,
        component: () => import("@/reports/views/ReportView.vue"),
      },
      {
        path: `${ReportType.METRICS.toLowerCase()}`,
        name: ReportType.METRICS,
        component: () => import("@/reports/views/ReportView.vue"),
      },
      {
        path: `${ReportType.COHORT_PER_MIN.toLowerCase()}`,
        name: ReportType.COHORT_PER_MIN,
        component: () => import("@/reports/views/ReportView.vue"),
      },
      {
        path: `${ReportType.CALENDAR_PER_MIN.toLowerCase()}`,
        name: ReportType.CALENDAR_PER_MIN,
        component: () => import("@/reports/views/ReportView.vue"),
      },
      {
        path: `${ReportType.SPEND_MINI.toLowerCase()}`,
        name: ReportType.SPEND_MINI,
        component: () => import("@/reports/views/ReportView.vue"),
      },
      {
        path: `${ReportType.SPEND.toLowerCase()}`,
        name: ReportType.SPEND,
        component: () => import("@/reports/views/ReportView.vue"),
      },
      {
        path: `${ReportType.SPEND_HYPER.toLowerCase()}`,
        name: ReportType.SPEND_HYPER,
        component: () => import("@/reports/views/ReportView.vue"),
      },
      {
        path: `${ReportType.PROFIT.toLowerCase()}`,
        name: ReportType.PROFIT,
        component: () => import("@/reports/views/ReportView.vue"),
      },
      {
        path: `${ReportType.CALENDAR_CPM.toLowerCase()}`,
        name: ReportType.CALENDAR_CPM,
        component: () => import("@/reports/views/ReportView.vue"),
      },
      {
        path: `${ReportType.COHORT_ANALYSIS.toLowerCase()}`,
        name: ReportType.COHORT_ANALYSIS,
        component: () => import("@/reports/views/ReportView.vue"),
      },
      {
        path: `${ReportType.WATERFALL.toLowerCase()}`,
        name: ReportType.WATERFALL,
        component: () => import("@/reports/views/ReportView.vue"),
      },
      {
        path: `${ReportType.EVENTS_COST.toLowerCase()}`,
        name: ReportType.EVENTS_COST,
        component: () => import("@/reports/views/ReportView.vue"),
      },
      {
        path: `${ReportType.CALENDAR_CTR.toLowerCase()}`,
        name: ReportType.CALENDAR_CTR,
        component: () => import("@/reports/views/ReportView.vue"),
      },
      {
        path: `${ReportType.COHORT_CTR.toLowerCase()}`,
        name: ReportType.COHORT_CTR,
        component: () => import("@/reports/views/ReportView.vue"),
      },
      {
        path: `${ReportType.CASH_COUNTRY.toLowerCase()}`,
        name: ReportType.CASH_COUNTRY,
        component: () => import("@/reports/views/ReportView.vue"),
      },
      {
        path: `${ReportType.DIFF_INSTALLS_STORES.toLowerCase()}`,
        name: ReportType.DIFF_INSTALLS_STORES,
        component: () => import("@/reports/views/ReportView.vue"),
      },
      {
        path: `${ReportType.AD_ROAS_COUNTRY.toLowerCase()}`,
        name: ReportType.AD_ROAS_COUNTRY,
        component: () => import("@/reports/views/ReportView.vue"),
      },
      {
        path: `${ReportType.AD_ROAS_NETWORK.toLowerCase()}`,
        name: ReportType.AD_ROAS_NETWORK,
        component: () => import("@/reports/views/ReportView.vue"),
      },
      {
        path: `${ReportType.CASH_GAMING.toLowerCase()}`,
        name: ReportType.CASH_GAMING,
        component: () => import("@/reports/views/ReportView.vue"),
      },
      {
        path: `${ReportType.COHORT_CPM.toLowerCase()}`,
        name: ReportType.COHORT_CPM,
        component: () => import("@/reports/views/ReportView.vue"),
      },
      {
        path: `${ReportType.SKAD.toLowerCase()}`,
        name: ReportType.SKAD,
        component: () => import("@/reports/views/ReportView.vue"),
      },
      {
        path: `${ReportType.METRICS_SPEND.toLowerCase()}`,
        name: ReportType.METRICS_SPEND,
        component: () => import("@/reports/views/ReportView.vue"),
      },
      {
        path: `${ReportType.FILL_RATE.toLowerCase()}`,
        name: ReportType.FILL_RATE,
        component: () => import("@/reports/views/ReportView.vue"),
      },
      {
        path: `${ReportType.PAYING_USERS_CONVERSION.toLowerCase()}`,
        name: ReportType.PAYING_USERS_CONVERSION,
        component: () => import("@/reports/views/ReportView.vue"),
      },
      {
        path: `${ReportType.PRICED_REVENUE.toLowerCase()}`,
        name: ReportType.PRICED_REVENUE,
        component: () => import("@/reports/views/ReportView.vue"),
      },
      {
        path: `${ReportType.FIREBASE_SHOW_TO_IMPRESSION.toLowerCase()}`,
        name: ReportType.FIREBASE_SHOW_TO_IMPRESSION,
        component: () => import("@/reports/views/ReportView.vue"),
      },
      {
        path: `${ReportType.FIREBASE_VS_NETWORKS.toLowerCase()}`,
        name: ReportType.FIREBASE_VS_NETWORKS,
        component: () => import("@/reports/views/ReportView.vue"),
      },
      {
        path: `${ReportType.FIREBASE_FILL_RATE.toLowerCase()}`,
        name: ReportType.FIREBASE_FILL_RATE,
        component: () => import("@/reports/views/ReportView.vue"),
      },
      {
        path: `${ReportType.TRAFFIC_QUALITY.toLowerCase()}`,
        name: ReportType.TRAFFIC_QUALITY,
        component: () => import("@/reports/views/ReportView.vue"),
      },
      {
        path: `${ReportType.UA_MAIN_METRICS_OVERVIEW.toLowerCase()}`,
        name: ReportType.UA_MAIN_METRICS_OVERVIEW,
        component: () => import("@/reports/views/ReportView.vue"),
      },
      {
        path: `${ReportType.SUBSCRIPTIONS_OVERVIEW.toLowerCase()}`,
        name: ReportType.SUBSCRIPTIONS_OVERVIEW,
        component: () => import("@/reports/views/ReportView.vue"),
      },
      {
        path: `${ReportType.METRICS_CONSTRUCTOR.toLowerCase()}`,
        name: ReportType.METRICS_CONSTRUCTOR,
        component: () => import("@/reports/views/ReportView.vue"),
      },
      {
        path: `${ReportType.SUBSCRIPTIONS_OVERVIEW_IOS.toLowerCase()}`,
        name: ReportType.SUBSCRIPTIONS_OVERVIEW_IOS,
        component: () => import("@/reports/views/ReportView.vue"),
      },
      {
        path: `${ReportType.EVENTS_SUMMARY_LITE.toLowerCase()}`,
        name: ReportType.EVENTS_SUMMARY_LITE,
        component: () => import("@/reports/views/ReportView.vue"),
      },
    ],
  },
  {
    path: `/app/:id/funnel`,
    name: MenuItems.FUNNEL,
    component: () => import("@/funnels/views/FunnelsView.vue"),
  },
  {
    path: `/app/:id/funnel/new`,
    name: "funnel_new",
    component: () => import("@/funnels/views/NewFunnelView.vue"),
    props: true,
  },
  {
    path: `/app/:id/funnel/:funnelId/view`,
    name: "funnel_view",
    component: () => import("@/funnels/views/FunnelView.vue"),
  },
  {
    path: `/app/:id/funnel/:funnelId/chart`,
    name: "funnel_chart_view",
    component: () => import("@/funnels/views/FunnelChartView.vue"),
  },
  {
    path: "/app/:id/segment",
    name: MenuItems.SEGMENT,
    meta: {
      middleware: [sectionMiddleware],
    },
    component: () => import("@/segments/views/SegmentsView.vue"),
    children: [
      {
        path: `${SegmentType.CUSTOM_DYNAMIC.toLowerCase()}`,
        name: AppSection.CUSTOM_DYNAMIC_SEGMENTS,
      },
      {
        path: `${SegmentType.CUSTOM_STATIC.toLowerCase()}`,
        name: AppSection.CUSTOM_STATIC_SEGMENTS,
      },
      {
        path: `${SegmentType.AB_TEST.toLowerCase()}`,
        name: AppSection.AB_TEST_SEGMENTS,
      },
      {
        path: `${SegmentType.EXTERNAL_AB_TEST.toLowerCase()}`,
        name: AppSection.EXTERNAL_TEST_SEGMENTS,
      },
      {
        path: `${SegmentType.DIVIDER.toLowerCase()}`,
        name: AppSection.DIVIDER_SEGMENTS,
      },
      // TODO: temporary disabled
      // {
      //   path: `${SegmentType.PROVIDED.toLowerCase()}`,
      //   name: SegmentType.PROVIDED,
      // },
      {
        path: `${SegmentType.FUNNEL.toLowerCase()}`,
        name: AppSection.FUNNEL_SEGMENTS,
      },
    ],
  },
  {
    path: `/app/:id/segment/new`,
    name: "segment_new",
    component: () => import("@/segments/views/NewSegmentView.vue"),
    props: true,
  },
  {
    path: `/app/:id/segment/:segmentId/update`,
    name: "segment_update",
    component: () => import("@/segments/views/SegmentView.vue"),
  },
  {
    path: "/app/:id/dashboards",
    name: MenuItems.DASHBOARDS,
    meta: {
      middleware: [sectionMiddleware],
    },
    component: () => import("@/dashboard/views/DashboardsView.vue"),
    children: [
      {
        path: `${DashboardType.MULTI_APP.toLowerCase()}`,
        name: AppSection.MULTIAPP_DASHBOARDS,
      },
      {
        path: `${DashboardType.SINGLE_APP.toLowerCase()}`,
        name: AppSection.SINGLEAPP_DASHBOARDS,
      },
    ],
  },
  {
    path: `/app/:id/dashboard`,
    name: MenuItems.DASHBOARD,
    component: DashboardView,
  },
  {
    path: `/app/:id/dashboard/new`,
    name: MenuItems.DASHBOARD_NEW,
    component: () => import("@/dashboard/views/DashboardItemView.vue"),
  },
  {
    path: `/app/:id/dashboard/:dashboardId/view`,
    name: MenuItems.DASHBOARD_VIEW,
    component: DashboardView,
  },
  {
    path: `/app/:id/dashboard/:dashboardId/edit`,
    name: MenuItems.DASHBOARD_UPDATE,
    component: () => import("@/dashboard/views/DashboardItemView.vue"),
  },
  {
    path: "/app/admin/:id/settings",
    name: MenuItems.SETTINGS,
    meta: {
      middleware: [sectionMiddleware],
    },
    component: {
      render: (c) => c("router-view"),
    },
    children: [
      {
        path: "dictionary/appVersions",
        name: AppSection.DICTIONARIES_APP_VERSIONS,
        component: () =>
          import("@/settings/views/dictionaries/DictionariesView.vue"),
      },
      {
        path: "dictionary/osVersions",
        name: AppSection.DICTIONARIES_OS_VERSIONS,
        component: () =>
          import("@/settings/views/dictionaries/DictionariesView.vue"),
      },
      {
        path: "revenue-events",
        name: AppSection.AD_REVENUE_EVENTS,
        component: () =>
          import("@/settings/views/revenue-events/RevenueEventsView.vue"),
      },
      {
        path: "revenue-events/new",
        name: MenuItemsSettings.AD_REVENUE_EVENTS_NEW,
        component: () =>
          import("@/settings/views/revenue-events/NewRevenueEventView.vue"),
        props: true,
      },
      {
        path: "revenue-events/:revenueEventsId/view",
        name: MenuItemsSettings.AD_REVENUE_EVENTS_VIEW,
        component: () =>
          import("@/settings/views/revenue-events/RevenueEventView.vue"),
      },
      {
        path: "divider",
        name: AppSection.DIVIDER,
        component: () => import("@/settings/views/DividerSegmentView.vue"),
      },
      {
        path: "placements",
        name: AppSection.PLACEMENTS,
        component: () =>
          import("@/settings/views/placements/PlacementsView.vue"),
      },
      {
        path: "placements/new",
        name: MenuItemsSettings.PLACEMENTS_NEW,
        component: () =>
          import("@/settings/views/placements/NewPlacementView.vue"),
        props: true,
      },
      {
        path: "placements/:placementId/view",
        name: MenuItemsSettings.PLACEMENTS_VIEW,
        component: () =>
          import("@/settings/views/placements/PlacementView.vue"),
      },
      {
        path: "networks-management",
        name: AppSection.NETWORKS_MANAGEMENT,
        component: () =>
          import("@/settings/views/networks-management/NetworksView.vue"),
      },
      {
        path: "networks-management/new",
        name: MenuItemsSettings.NETWORKS_MANAGEMENT_NEW,
        component: () =>
          import("@/settings/views/networks-management/NewNetworkView.vue"),
      },
      {
        path: "networks-management/:networkId",
        name: MenuItemsSettings.NETWORKS_MANAGEMENT_VIEW,
        props: true,
        component: () =>
          import("@/settings/views/networks-management/NetworkView.vue"),
        children: [
          {
            path: "network-integration/:networkIntegrationId",
            name: "networkIntegration",
            props: true,
            redirect: { name: "accountsGroups" },
            component: () =>
              import(
                "@/settings/views/networks-management/NetworkIntegrationView.vue"
              ),
            children: [
              {
                path: "accounts-groups",
                name: "accountsGroups",
                component: () =>
                  import(
                    "@/settings/views/networks-management/AccountsGroupsView.vue"
                  ),
              },
              {
                path: "accounts-groups/:accountGroupId",
                name: "accountGroup",
                component: () =>
                  import(
                    "@/settings/views/networks-management/AccountGroupView.vue"
                  ),
              },
              {
                path: "parsing-rules",
                name: "parsingRules",
                component: () =>
                  import(
                    "@/settings/views/networks-management/ParsingRulesView.vue"
                  ),
              },
              {
                path: "authentication",
                name: "authentication",
                component: () =>
                  import(
                    "@/settings/views/networks-management/AuthenticationView.vue"
                  ),
              },
              {
                path: "custom-data-requests",
                name: "customDataRequests",
                component: () =>
                  import(
                    "@/settings/views/networks-management/CustomDataRequestsView.vue"
                  ),
              },
              {
                path: "data-request",
                name: "dataRequest",
                component: () =>
                  import(
                    "@/settings/views/networks-management/DataRequestView.vue"
                  ),
              },
              {
                path: "net-revenue",
                name: "netRevenue",
                component: () =>
                  import(
                    "@/settings/views/networks-management/NetRevenueView.vue"
                  ),
              },
              {
                path: "child-networks",
                name: "childNetworks",
                component: () =>
                  import(
                    "@/settings/views/networks-management/ChildNetworksView.vue"
                  ),
              },
            ],
          },
        ],
      },
      {
        path: "historical-revenue-network",
        name: "historicalRevenueNetwork",
        component: () =>
          import(
            "@/settings/views/networks-management/HistoricalRevenueNetwork.vue"
          ),
      },
      {
        path: "subscription-events",
        name: AppSection.SUBSCRIPTION_EVENTS,
        component: () =>
          import(
            "@/settings/views/subscription-events/SubscriptionEventsView.vue"
          ),
      },
      {
        path: "subscription-events/new",
        name: MenuItemsSettings.SUBSCRIPTION_EVENTS_NEW,
        component: () =>
          import(
            "@/settings/views/subscription-events/NewSubscriptionEventView.vue"
          ),
      },
      {
        path: "subscription-events/:subscriptionEventId/view",
        name: MenuItemsSettings.SUBSCRIPTION_EVENTS_VIEW,
        component: () =>
          import(
            "@/settings/views/subscription-events/SubscriptionEventView.vue"
          ),
      },
    ],
  },
  {
    path: "/app/:id/ab-tests",
    name: MenuItems.AB_TESTS,
    component: () => import("@/ab-tests/views/AbTests.vue"),
    meta: {
      middleware: [sectionMiddleware],
    },
    children: [
      {
        path: "ab_test",
        name: AppSection.AB_TESTS,
        component: () =>
          import("@/ab-tests/views/ab-test-config/AbTestConfigurationView.vue"),
      },
      {
        path: "ab_test/new",
        name: "abTest_new",
        component: () =>
          import(
            "@/ab-tests/views/ab-test-config/AbTestConfigurationNewView.vue"
          ),
      },
      {
        path: "ab_test/configs/:configId",
        name: "abTest_edit_configs",
        component: () =>
          import(
            "@/ab-tests/views/ab-test-config/AbTestConfigurationEditConfigsView.vue"
          ),
      },
      {
        path: "ab_test/:configId",
        name: "abTest_edit",
        component: () =>
          import(
            "@/ab-tests/views/ab-test-config/AbTestConfigurationEditView.vue"
          ),
      },
      {
        path: ":configId/view",
        name: "abTest_view",
        component: () => import("@/ab-tests/views/AbTestView.vue"),
      },
      {
        path: "targeted",
        name: AppSection.TARGETED_CONFIGS,
        component: () =>
          import(
            "@/ab-tests/views/targeted-config/TargetedConfigurationView.vue"
          ),
      },
      {
        path: "targeted/new",
        name: "targeted_new",
        component: () =>
          import(
            "@/ab-tests/views/targeted-config/TargetedConfigurationNewView.vue"
          ),
      },
      {
        path: "targeted/configs/:configId",
        name: "targeted_edit_configs",
        component: () =>
          import(
            "@/ab-tests/views/targeted-config/TargetedConfigurationEditConfigsView.vue"
          ),
      },
      {
        path: "targeted/:configId",
        name: "targeted_edit",
        component: () =>
          import(
            "@/ab-tests/views/targeted-config/TargetedConfigurationEditView.vue"
          ),
      },
      {
        path: "targeted/:configId/view",
        name: "targeted_view",
        component: () =>
          import("@/ab-tests/views/targeted-config/TargetedView.vue"),
      },
      {
        path: "default",
        name: AppSection.DEFAULT_CONFIG,
        component: () =>
          import(
            "@/ab-tests/views/default-config/DefaultConfigurationView.vue"
          ),
      },
      {
        path: "external",
        component: () =>
          import("@/ab-tests/views/external/ExternalTestsBaseView.vue"),
        children: [
          {
            path: "",
            name: AppSection.EXTERNAL_TESTS,
            component: () =>
              import("@/ab-tests/views/external/ExternalTestsView.vue"),
          },
          {
            path: "configuration",
            name: "external_configuration",
            component: () =>
              import("@/ab-tests/views/external/ExternalConfigurationView.vue"),
          },
          {
            path: "new",
            name: "external_new",
            component: () =>
              import("@/ab-tests/views/external/ExternalNewView.vue"),
          },
          {
            path: ":configId/view",
            name: "external_test",
            component: () =>
              import("@/ab-tests/views/external/ExternalTestView.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/app/:id/template",
    name: MenuItems.TEMPLATE,
    meta: {
      middleware: [sectionMiddleware],
    },
    component: () => import("@/templates/views/TemplatesView.vue"),
    children: [
      {
        path: "/app/:id/template/report",
        name: AppSection.REPORT_TEMPLATES,
        component: () => import("@/templates/views/ReportTemplatesView.vue"),
      },
      {
        path: "/app/:id/template/funnel",
        name: AppSection.FUNNEL_TEMPLATES,
        component: () => import("@/templates/views/FunnelTemplatesView.vue"),
      },
    ],
  },
  {
    path: "/app/:id/template/:templateId/dashboards",
    name: "used_dashboards",
    component: () => import("@/templates/views/UsedDashboardsView.vue"),
  },
  {
    path: "/healthcheck/summary",
    name: AppSection.HEALTHCHECK_SUMMARY,
    component: () => import("@/healthcheck/views/HealthCheckSummaryView.vue"),
  },
  {
    path: "/healthcheck/dataflow/app/:appId",
    name: AppSection.HEALTHCHECK_DATA_FLOW,
    component: () => import("@/healthcheck/views/HealthCheckDataFlowView.vue"),
  },
  {
    path: "/healthcheck/parsing/app/:appId",
    name: AppSection.HEALTHCHECK_PARSING,
    component: () => import("@/healthcheck/views/HealthCheckParsingView.vue"),
  },
  {
    path: "/healthcheck/jobs-queue",
    name: AppSection.HEALTHCHECK_JOBS_QUEUE,
    component: () => import("@/healthcheck/views/HealthCheckJobsQueueView.vue"),
  },
  {
    path: "/healthcheck/metric-weights",
    name: AppSection.HEALTHCHECK_METRIC_WEIGHT,
    component: () =>
      import("@/healthcheck/views/HealthCheckMetricWeightsView.vue"),
  },
  {
    path: "/application-manager",
    name: MenuItems.APPLICATION_MANAGER,
    meta: {
      middleware: [sectionMiddleware],
    },
    component: {
      render: (c) => c("router-view"),
    },
    children: [
      {
        path: "jurisdictions",
        name: AppSection.JURISDICTIONS,
        component: () =>
          import(
            "@/application-manager/views/jurisdiction/JurisdictionsView.vue"
          ),
      },
      {
        path: "jurisdictions/new",
        name: MenuItemsApplicationManager.JURISDICTION_NEW,
        component: () =>
          import(
            "@/application-manager/views/jurisdiction/NewJurisdictionView.vue"
          ),
        props: true,
      },
      {
        path: "jurisdictions/:jurisdictionId/view",
        name: MenuItemsApplicationManager.JURISDICTION_VIEW,
        component: () =>
          import(
            "@/application-manager/views/jurisdiction/JurisdictionView.vue"
          ),
      },
      {
        path: "scenarios",
        name: AppSection.SCENARIOS,
        component: () =>
          import("@/application-manager/views/scenarios/ScenariosView.vue"),
      },
      {
        path: "scenarios/new",
        name: MenuItemsApplicationManager.SCENARIO_NEW,
        component: () =>
          import("@/application-manager/views/scenarios/NewScenarioView.vue"),
      },
      {
        path: "scenarios/:scenarioId/view",
        name: MenuItemsApplicationManager.SCENARIO_VIEW,
        component: () =>
          import("@/application-manager/views/scenarios/ScenarioView.vue"),
      },
      {
        path: "batches",
        name: AppSection.BATCHES,
        component: () =>
          import("@/application-manager/views/batches/BatchesView.vue"),
      },
      {
        path: "batches/new",
        name: MenuItemsApplicationManager.BATCH_NEW,
        component: () =>
          import("@/application-manager/views/batches/NewBatchView.vue"),
      },
      {
        path: "batches/:batchId/view",
        name: MenuItemsApplicationManager.BATCH_VIEW,
        component: () =>
          import("@/application-manager/views/batches/BatchView.vue"),
      },
      {
        path: "applications",
        name: AppSection.APPLICATIONS,
        component: () =>
          import(
            "@/application-manager/views/application/ApplicationWorkflowsView.vue"
          ),
      },
      {
        path: "applications/new",
        name: MenuItemsApplicationManager.APPLICATION_NEW,
        component: () =>
          import(
            "@/application-manager/views/application/NewApplicationView.vue"
          ),
      },
      {
        path: "app/:id/basic-info",
        name: AppSection.APPLICATION_BASIC_INFO,
        component: () =>
          import(
            "@/application-manager/views/application-basic-info/ApplicationBasicInfoView.vue"
          ),
      },
      {
        path: "app/:id/features-control",
        name: AppSection.APPLICATION_FEATURES_CONTROL,
        component: () =>
          import(
            "@/application-manager/views/application-features-control/ApplicationFeaturesControlView.vue"
          ),
      },
      // {
      //   path: "configurations",
      //   name: MenuItemsApplicationManager.CONFIGURATIONS,
      //   meta: {
      //     middleware: [sectionMiddleware],
      //   },
      //   component: () =>
      //     import(
      //       "@/application-manager/views/configuration/ConfigurationsView.vue"
      //     ),
      // },
      // {
      //   path: "configurations/view",
      //   name: MenuItemsApplicationManager.CONFIGURATION_VIEW,
      //   meta: {
      //     middleware: [sectionMiddleware],
      //   },
      //   component: () =>
      //     import(
      //       "@/application-manager/views/configuration/ConfigurationView.vue"
      //     ),
      // },
    ],
  },
  {
    path: `/user-alerts`,
    name: MenuItems.USER_ALERTS,
    component: () => import("@/alerts-system/views/AlertsView.vue"),
  },
  {
    path: `/user-alerts/new`,
    name: "alert_new",
    component: () => import("@/alerts-system/views/NewAlertView.vue"),
  },
  {
    path: `/user-alerts/:alertId`,
    name: "alert_view",
    component: () => import("@/alerts-system/views/AlertView.vue"),
  },
  {
    path: "/app/:id/tools",
    name: MenuItems.TOOLS,
    meta: {
      middleware: [sectionMiddleware],
    },
    component: {
      render: (c) => c("router-view"),
    },
    children: [
      {
        path: MenuItemsTools.EXPORT_EVENTS,
        name: AppSection.EXPORT_USER_FLOW,
        component: () =>
          import("@/tools/views/export-events/ExportEventsView.vue"),
      },
      {
        path: MenuItemsTools.MONETIZATION_MONITORING.toLowerCase(),
        name: ReportType.MONETIZATION_MONITORING,
        component: () => import("@/reports/views/ReportView.vue"),
      },
      {
        path: MenuItemsTools.HEALTHCHECK_MONETIZATION_ALERT.toLowerCase(),
        name: AppSection.HEALTHCHECK_MONETIZATION_ALERT,
        component: () =>
          import("@/tools/views/healthcheck-alerts/HealthcheckAlertsView.vue"),
      },
      {
        path: MenuItemsTools.HEALTHCHECK_MARKETING_ALERT.toLowerCase(),
        name: AppSection.HEALTHCHECK_MARKETING_ALERT,
        component: () =>
          import("@/tools/views/healthcheck-alerts/HealthcheckAlertsView.vue"),
      },
    ],
  },
  {
    path: "/accounting-portal",
    name: MenuItems.ACCOUNTING_PORTAL,
    meta: {
      middleware: [sectionMiddleware],
    },
    component: {
      render: (c) => c("router-view"),
    },
    children: [
      {
        path: "invoices",
        name: AppSection.INVOICES,
        component: () => import("@/accounting-portal/views/InvoicesView.vue"),
      },
      {
        path: "banks",
        name: AppSection.BANKS,
        component: () => import("@/accounting-portal/views/BanksView.vue"),
        children: [
          {
            path: ":bankId",
            name: "bank",
            component: () => import("@/accounting-portal/views/BankView.vue"),
          },
        ],
      },
      {
        path: "counterparties",
        name: AppSection.COUNTERPARTIES,
        component: () =>
          import(
            "@/accounting-portal/views/counterparties/CounterpartiesView.vue"
          ),
      },
      {
        path: "counterparties/:counterpartyId",
        name: "counterparty",
        component: () =>
          import(
            "@/accounting-portal/views/counterparties/CounterpartyView.vue"
          ),
        redirect: { name: "counterpartyBanks" },
        children: [
          {
            path: "banks",
            name: "counterpartyBanks",
            component: () =>
              import("@/accounting-portal/views/counterparties/BanksView.vue"),
          },
          {
            path: "networks",
            name: "counterpartyNetworks",
            component: () =>
              import(
                "@/accounting-portal/views/counterparties/NetworksView.vue"
              ),
          },
          {
            path: "net-revenue",
            name: "counterpartyNetRevenue",
            component: () =>
              import(
                "@/accounting-portal/views/counterparties/NetRevenueView.vue"
              ),
          },
          {
            path: "thresholds",
            name: "thresholds",
            component: () =>
              import(
                "@/accounting-portal/views/counterparties/ThresholdsView.vue"
              ),
          },
        ],
      },
    ],
  },
  {
    path: "/account-settings",
    name: MenuItems.ACCOUNT_SETTINGS,
    component: () => import("@/account-settings/views/AccountSettingsView.vue"),
  },
  {
    path: "/app/:id",
    name: MenuItems.HOME,
    component: DashboardView,
  },
  {
    path: "*",
    redirect: { name: "404" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  parseQuery(query: string): Record<string, any> {
    return querystring.parse(query, {
      allowDots: true,
      // By default, when nesting objects qs will only parse up to 5 children deep.
      // This means if you attempt to parse a string like 'a[b][c][d][e][f][g][h][i]=j' your resulting object will be:
      // var expected = {
      //   a: {
      //       b: {
      //           c: {
      //               d: {
      //                   e: {
      //                       f: {
      //                           '[g][h][i]': 'j'
      //                       }
      //                   }
      //               }
      //           }
      //       }
      //   }
      // };
      depth: 10,
      // qs will also limit specifying indices in an array to a maximum index of 20.
      //Any array members with an index of greater than 20 will instead be converted to an object with the index as the key.
      // This is needed to handle cases when someone sent, for example, a[999999999] and it will take significant time to iterate over this huge array.
      // This limit can be overridden by passing an arrayLimit option:
      arrayLimit: 50,
    });
  },
  stringifyQuery(query: Record<string, any>): string {
    const result = querystring.stringify(query, {
      skipNulls: true,
      allowDots: true,
      format: "RFC3986",
    });
    return result ? "?" + result : "";
  },
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const initialPath = sessionStorage.getItem(INITIAL_PATH);
  const isUserLogined = !!store.state.userStore.currentUser.username;

  if (initialPath && to.name !== "login" && isUserLogined) {
    sessionStorage.removeItem(INITIAL_PATH);

    return next(initialPath);
  }

  if (to.name !== from?.name) {
    store.dispatch("cancelPendingRequests");
  }

  const appId = to.params.id ?? MULTI_APP;

  if (
    appId !== MULTI_APP &&
    !store.state.application.apps.some(({ id }: Application) => id === appId)
  ) {
    return next({
      name: "404",
    });
  }

  const hasAccess: boolean | undefined =
    store.state.userStore.currentUser.routesAccessMap[appId].get(to.name);
  const middleware = to.meta?.middleware;

  if (hasAccess === false) {
    return next({
      name: "404",
    });
  }

  if (!middleware) {
    return next();
  }

  const context = {
    to,
    from,
    next,
    store,
  };
  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

export default router;
