export default class ConfigurationModel {
  constructor(public key = "", public value = "") {}

  static ofArray(model: Record<string, string>): Array<ConfigurationModel> {
    return Object.entries(model).reduce(
      (
        res: Array<ConfigurationModel>,
        currentItem: Array<string>
      ): Array<ConfigurationModel> => {
        return [...res, new ConfigurationModel(currentItem[0], currentItem[1])];
      },
      []
    );
  }
}
