export class SummaryResponseModel {
  constructor(
    public application: string,
    public metricsForPeriods: Array<MetricsForPeriod>
  ) {}

  static of(model: SummaryResponseModel): SummaryResponseModel {
    return new SummaryResponseModel(
      model.application,
      MetricsForPeriod.ofArray(model.metricsForPeriods)
    );
  }

  static ofArray(
    items: Array<SummaryResponseModel>
  ): Array<SummaryResponseModel> {
    return items.map((item) => SummaryResponseModel.of(item));
  }
}

export class MetricsForPeriod {
  constructor(
    public dataFlowMetrics: Array<DataFlowMetrics>,
    public parsingMetrics: Array<ParsingMetricShort>,
    public segmentMetrics: Array<Record<string, number>>,
    public thirdPartyMetrics: Array<Record<string, number>>,
    public summaryScore: number,
    public parsingScore: number,
    public dataFlowScore: number,
    public segmentScore: number,
    public thirdPartyScore: number,
    public scoreSeverity: string,
    public timePeriod: number
  ) {}

  static of(model: MetricsForPeriod): MetricsForPeriod {
    return new MetricsForPeriod(
      model.dataFlowMetrics,
      model.parsingMetrics,
      model.segmentMetrics,
      model.thirdPartyMetrics,
      model.summaryScore,
      model.parsingScore,
      model.dataFlowScore,
      model.segmentScore,
      model.thirdPartyScore,
      model.scoreSeverity,
      model.timePeriod
    );
  }

  static ofArray(items: Array<MetricsForPeriod>): Array<MetricsForPeriod> {
    return items.map((item) => MetricsForPeriod.of(item));
  }
}

export interface ParsingMetricShort {
  metric: string;
  averagePercentDelta: number;
  maxPercentDelta: number;
}

export interface DataFlowMetrics {
  dataFlow: string;
  metrics: Array<Record<string, number>>;
}

export enum ScoreSeverity {
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
}
