import { CohortFilter } from "./CohortReport";
import { GroupByFilter } from "./GroupByFilter";
import { ReportType } from "./ReportType";
import {
  AdRevenueMethod,
  DatesFilterModel,
  FilterId,
  FilterPreview,
  FilterPreviewId,
  recordToFilterModel,
  FilterModel,
  DefaultValues,
  DictionaryType,
  Application,
  ProvidedBannerType,
} from "@/shared/models";
import {
  CumulativeReportFilterExtension,
  EventReportFilterExtension,
  NetRevenueReportFilterExtension,
  ProvidedBannerReportFilterExtension,
  RevenueReportFilterExtension,
} from "./ReportFilterExtension";
import { ChartName } from "@/chart/models/ChartModel";

export class ArpuFilter
  extends CohortFilter
  implements
    EventReportFilterExtension,
    ProvidedBannerReportFilterExtension,
    RevenueReportFilterExtension,
    CumulativeReportFilterExtension,
    NetRevenueReportFilterExtension
{
  event: string;
  revenue: Array<string>;
  providedBanner: string;
  adRevenueMethod: AdRevenueMethod;
  cumulative: boolean;

  constructor(
    app: Application,
    filter?: Array<FilterModel>,
    date?: DatesFilterModel,
    groupByFilter?: GroupByFilter,
    dayLimit?: number,
    event: string = DefaultValues.EVENT,
    revenue: Array<string> = [],
    providedBanner = DefaultValues.PROVIDED_BANNER,
    adRevenueMethod: AdRevenueMethod = AdRevenueMethod.PRICED,
    cumulative = true,
    public netRevenue = true
  ) {
    super(ReportType.ARPU, app, filter, date, groupByFilter, dayLimit);
    this.event = event;
    this.revenue = revenue;
    this.providedBanner = providedBanner;
    this.adRevenueMethod = adRevenueMethod;
    this.cumulative = cumulative;
  }

  get previews(): Array<FilterPreview> {
    return [
      ...super.previews,
      {
        id: FilterPreviewId.EVENT_SIMPLE,
        value: this.event,
      },
      ...(this.revenue.length
        ? [
            {
              id: FilterPreviewId.REVENUE,
              value: this.revenue,
              dictionary: DictionaryType.REVENUES,
            },
          ]
        : []),
      {
        id: FilterPreviewId.PROVIDED_BANNER,
        value: this.providedBanner,
      },
      {
        id: FilterPreviewId.AD_REVENUE_METHOD,
        value: this.adRevenueMethod,
      },
      ...(this.cumulative
        ? [
            {
              id: FilterPreviewId.CUMULATIVE,
              value: this.cumulative,
            },
          ]
        : []),
      {
        id: FilterPreviewId.NET_REVENUE,
        value: this.netRevenue,
      },
    ];
  }

  get invalid(): boolean {
    return (
      super.invalid ||
      (this.isBannerProvidedDisabled &&
        this.providedBanner === ProvidedBannerType.FIREBASE)
    );
  }

  static of(app: Application, filter?: Record<string, any>): ArpuFilter {
    return filter
      ? new ArpuFilter(
          app,
          filter.filter && filter.filter.length > 0
            ? filter.filter.map((it: any) => recordToFilterModel(it))
            : [],
          filter.date
            ? DatesFilterModel.ofRecord(
                FilterId.ATTRIBUTION_DATE_VALUE,
                filter.date
              )
            : undefined,
          filter.groupByFilter
            ? GroupByFilter.of(filter.groupByFilter)
            : undefined,
          filter.dayLimit
            ? Number.parseInt(filter.dayLimit as string)
            : undefined,
          filter.event as string,
          filter.revenue,
          filter.providedBanner,
          filter.adRevenueMethod as AdRevenueMethod,
          typeof filter.cumulative === "string"
            ? filter.cumulative === "true"
            : filter.cumulative
          // TODO: NET_REVENUE need to uncomment later
          // typeof filter.netRevenue === "string"
          //   ? filter.netRevenue === "true"
          //   : filter.netRevenue,
        )
      : new ArpuFilter(app);
  }

  get isBannerRevenue(): boolean {
    return this.revenue.length === 0 || this.revenue.includes("banner");
  }

  get isDefaultProvidedBanner(): boolean {
    return this.providedBanner === DefaultValues.PROVIDED_BANNER;
  }

  get isPricedMethod(): boolean {
    return this.adRevenueMethod === AdRevenueMethod.PRICED;
  }

  get isBannerProvidedDisabled(): boolean {
    return this.adRevenueMethod === AdRevenueMethod.AVERAGE;
  }

  get charts(): Array<ChartName> {
    return this.groupByFilter.isNotEmptyGroupBy
      ? this.getGroupedCohortChartNames("ARPU_REPORT", [1, 7, 14])
      : [ChartName.ARPU_REPORT_CHART];
  }

  get groupedCharts(): Array<ChartName> {
    return this.groupByFilter.isNotEmptyGroupBy
      ? [ChartName.ARPU_REPORT_CHART]
      : [];
  }

  toRequestQuery(): Record<string, any> {
    const result = super.toRequestQuery();

    result["event"] = this.event;
    result["adRevenueMethod"] = this.adRevenueMethod;
    result["cumulative"] = this.cumulative;

    if (this.revenue.length !== 0) {
      result["revenue"] = this.revenue;
    }

    if (this.isBannerRevenue && !this.isDefaultProvidedBanner) {
      result["providedBanner"] = this.providedBanner;
    }

    // TODO: NET_REVENUE need to uncomment later
    // result["netRevenue"] = this.netRevenue;

    return result;
  }
}
