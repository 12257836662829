import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosResponse } from "axios";

import DashboardModel, {
  DashboardPreview,
  DashboardPreviewModel,
  DashboardUniqueNameResponse,
} from "@/dashboard/models/DashboardModel";
import ReportProvide from "@/reports/utils/ReportProvide";
import EventSourceWrapper, {
  EventSourceFormat,
} from "@/shared/utils/EventSourceWrapper";
import axios, { AxiosSkipErrorsConfig } from "@/shared/plugins/axios";
import DashboardFilterModel from "@/dashboard/models/DashboardFilterModel";
import { DashboardChartModel } from "@/dashboard/models/DashboardChartModel";
import FunnelChartModel from "@/funnels/models/FunnelChartModel";
import {
  getFunnelChartData,
  getReportChartData,
} from "@/chart/utils/ChartUtil";
import { processFunnelSteps } from "@/funnels/utils/FunnelUtil";
import { ReportFilter } from "@/reports/models";

type ChartsRecordType = Record<string, Array<Record<string, any>> | null>;

@Module
export default class DashboardStore extends VuexModule {
  dashboardPreviews: Array<DashboardPreview> = [];
  dashboard: DashboardModel | null = null;
  dashboards: Array<DashboardPreviewModel> = [];
  dashboardCharts: ChartsRecordType = {};
  dashboardChartsEventSource: EventSourceWrapper | null = null;
  isDashboardNameUnique = true;
  isDashboardLoading = false;
  isDashboardPreviewsLoading = false;
  isDashboardsLoading = false;
  isDashboardDeleting = false;

  @Mutation
  setDashboardChartsEventSource(payload: EventSourceWrapper) {
    this.dashboardChartsEventSource = payload;
  }

  @Mutation
  closeDashboardChartsEventSource() {
    if (!this.dashboardChartsEventSource) {
      return;
    }

    this.dashboardChartsEventSource.close();
    this.dashboardChartsEventSource = null;
  }

  @Mutation
  clearDashboard() {
    this.dashboard = null;
  }

  @Mutation
  clearDashboards() {
    this.dashboards = [];
  }

  @Mutation
  storeDashboard(payload: DashboardModel) {
    this.dashboard = DashboardModel.of(payload);
    this.dashboardCharts = payload.charts.reduce(
      (result: ChartsRecordType, chart: DashboardChartModel) => {
        return chart.id
          ? {
              ...result,
              [chart.id]: null,
            }
          : result;
      },
      {}
    );
  }

  @Mutation
  fillDashboardChart(payload: {
    id: string;
    data: Array<Record<string, any>>;
  }) {
    this.dashboardCharts = Object.assign({}, this.dashboardCharts, {
      [payload.id]: payload.data,
    });
  }

  @Mutation
  storeDashboards(payload: Array<DashboardPreviewModel>) {
    this.dashboards = DashboardPreviewModel.ofArray(payload);
  }

  @Mutation
  setDashboardsLoading(payload: boolean) {
    this.isDashboardsLoading = payload;
  }

  @Mutation
  setIsDashboardNameUnique(payload: boolean) {
    this.isDashboardNameUnique = payload;
  }

  @Mutation
  clearDashboardCharts() {
    this.dashboardCharts = Object.keys(this.dashboardCharts).reduce(
      (result: ChartsRecordType, key: string) => {
        return {
          ...result,
          [key]: null,
        };
      },
      {}
    );
  }

  @Mutation
  setDashboardLoading(payload: boolean) {
    this.isDashboardLoading = payload;
  }

  @Mutation
  filterDashboardsAfterDelete(payload: number) {
    this.dashboards = this.dashboards.filter(({ id }) => id !== payload);
  }

  @Mutation
  setDashboardPreviewsLoading(payload: boolean) {
    this.isDashboardPreviewsLoading = payload;
  }

  @Mutation
  clearDashboardPreviews() {
    this.dashboardPreviews = [];
  }

  @Mutation
  storeDashboardPreviews(payload: Array<DashboardPreview>) {
    this.dashboardPreviews = payload;
  }

  @Mutation
  setDashboardDeleting(payload: boolean) {
    this.isDashboardDeleting = payload;
  }

  @Action({ commit: "storeDashboard" })
  getDashboard(dashboardId: number) {
    this.context.commit("setDashboardLoading", true);

    return axios
      .get(`/api/dashboards/${dashboardId}`)
      .then(({ data }: AxiosResponse<DashboardModel>) => data)
      .finally(() => {
        this.context.commit("setDashboardLoading", false);
      });
  }

  @Action({ rawError: true })
  getDashboardCharts(payload: DashboardFilterModel) {
    this.context.commit("closeDashboardChartsEventSource");
    this.context.commit("clearDashboardCharts");

    return axios
      .post(`/api/dashboards/${this.dashboard?.id}/charts`, payload, {
        // TODO: temp solution for deleted funnel template
        skipErrors: true,
      } as AxiosSkipErrorsConfig)
      .then((result: AxiosResponse<string>) => {
        this.context.commit(
          "setDashboardChartsEventSource",
          new EventSourceWrapper(
            `/api/dashboards/${this.dashboard?.id}/charts/${result.data}`,
            EventSourceFormat.PLAIN
          )
        );
        this.dashboardChartsEventSource?.addEventListener(
          "chartReport",
          (data: any, id: string) => {
            const reportFilter = this.dashboard?.charts.find(
              (chart: DashboardChartModel) => chart.id === +id
            )?.reportFilter;

            this.context.commit("fillDashboardChart", {
              id,
              data: getReportChartData(
                ReportProvide.parseResultData(
                  reportFilter as ReportFilter,
                  data
                ),
                undefined,
                this.dashboard?.charts.find((chart) => chart.id === +id)?.name,
                (reportFilter as ReportFilter).groupByFilter.groupBy[0],
                (
                  reportFilter as ReportFilter & {
                    dayLimit: number | undefined;
                  }
                ).dayLimit
              ),
            });

            if (Object.values(this.dashboardCharts).every((chart) => chart)) {
              this.dashboardChartsEventSource?.close();
            }
          }
        );
        this.dashboardChartsEventSource?.addEventListener(
          "chartFunnel",
          (data: any, id: string) => {
            const parsedData = JSON.parse(data);

            this.context.commit("fillDashboardChart", {
              id,
              data: getFunnelChartData({
                data: parsedData.data.map((it: FunnelChartModel) =>
                  FunnelChartModel.of(it.breakdownValue, it.stepsValue)
                ),
                steps: processFunnelSteps(parsedData.steps),
              })[0],
            });

            if (Object.values(this.dashboardCharts).every((chart) => chart)) {
              this.dashboardChartsEventSource?.close();
            }
          }
        );
      });
  }

  @Action({ commit: "storeDashboardPreviews" })
  async fetchDashboardPreviews() {
    this.context.commit("clearDashboardPreviews");
    this.context.commit("setDashboardPreviewsLoading", true);

    return axios
      .get("/api/dashboards/names")
      .then(({ data }: AxiosResponse<Array<DashboardPreview>>) => data)
      .finally(() => {
        this.context.commit("setDashboardPreviewsLoading", false);
      });
  }

  @Action({ commit: "storeDashboards" })
  async fetchDashboards() {
    this.context.commit("clearDashboards");
    this.context.commit("setDashboardsLoading", true);

    return axios
      .get("/api/dashboards")
      .then(
        (response: AxiosResponse<Array<DashboardPreviewModel>>) => response.data
      )
      .finally(() => {
        this.context.commit("setDashboardsLoading", false);
      });
  }

  @Action
  setDefaultDashboard({ id, payload }: { id: number; payload: boolean }) {
    return axios
      .patch(`/api/dashboards/${id}`, { isDefault: payload })
      .then((response: AxiosResponse<DashboardModel>) => {
        this.context.commit("addNotification", {
          message: {
            key: `dashboard.notification.${
              payload ? "setDefaultSuccess" : "unsetDefaultSuccess"
            }`,
            params: [response.data.name],
          },
        });
      });
  }

  @Action
  deleteDashboard({ id, name }: { id: number; name: string }) {
    this.context.commit("setDashboardDeleting", true);

    return axios
      .delete(`/api/dashboards/${id}`)
      .then(() => {
        this.context.commit("filterDashboardsAfterDelete", id);
        this.context.commit("addNotification", {
          message: {
            key: "dashboard.notification.deleteSuccess",
            params: [name],
          },
        });
      })
      .finally(() => {
        this.context.commit("setDashboardDeleting", false);
      });
  }

  @Action
  createDashboard(dashboard: DashboardModel) {
    return axios
      .post("/api/dashboards", dashboard)
      .then((response: AxiosResponse<DashboardModel>) => {
        this.context.commit("addNotification", {
          message: {
            key: "dashboard.notification.createSuccess",
            params: [response.data.name],
          },
        });
      });
  }

  @Action
  updateDashboard(dashboard: DashboardModel) {
    return axios
      .patch(`/api/dashboards/${dashboard.id}`, dashboard)
      .then((response: AxiosResponse<DashboardModel>) => {
        this.context.commit("addNotification", {
          message: {
            key: "dashboard.notification.updateSuccess",
            params: [response.data.name],
          },
        });
      });
  }

  @Action({ commit: "setIsDashboardNameUnique" })
  async checkIsDashboardNameUnique(dashboard: DashboardModel) {
    const dashboardName = dashboard.name;

    if (!dashboardName) {
      return true;
    }

    return axios
      .post(`/api/dashboards/uniqueName`, { name: dashboardName })
      .then(
        (response: AxiosResponse<DashboardUniqueNameResponse>) =>
          response.data.unique
      );
  }
}
