import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { AxiosResponse } from "axios";

import axios from "@/shared/plugins/axios";

import AccountSettingsModel from "@/account-settings/models/AccountSettingsModel";

@Module
export default class AccountSettingsStore extends VuexModule {
  isLoading = false;
  isSavingInProgress = false;
  accountSettings: AccountSettingsModel = new AccountSettingsModel();

  @Mutation
  setAccountSettingsLoading(payload: boolean) {
    this.isLoading = payload;
  }

  @Mutation
  setIsSavingInProgress(payload: boolean) {
    this.isSavingInProgress = payload;
  }

  @Mutation
  setAccountSettings(payload: AccountSettingsModel) {
    this.accountSettings = AccountSettingsModel.of(payload);
  }

  @Action({ commit: "setAccountSettings" })
  loadAccountSettings() {
    this.context.commit("setAccountSettingsLoading", true);

    return axios
      .get("/api/user-settings")
      .then((result: AxiosResponse<AccountSettingsModel>) => result.data)
      .finally(() => {
        this.context.commit("setAccountSettingsLoading", false);
      });
  }

  @Action({ commit: "setAccountSettings" })
  async updateAccountSettings({
    payload,
    isNeedNotification = false,
  }: {
    payload: AccountSettingsModel;
    isNeedNotification: boolean;
  }) {
    this.context.commit("setIsSavingInProgress", true);

    return axios
      .put("/api/user-settings", payload)
      .then((result: AxiosResponse<AccountSettingsModel>) => {
        if (isNeedNotification) {
          this.context.commit("addNotification", {
            message: {
              key: "accountSettings.notification.updatedSuccess",
            },
          });
        }

        return result.data;
      })
      .finally(() => {
        this.context.commit("setIsSavingInProgress", false);
      });
  }
}
