import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import { ChartRenderer } from "@/chart/models/ChartRenderer";
import { SlicedChartOptions } from "@/chart/models/ChartModel";

export default class PieChartRenderer extends ChartRenderer {
  chart: am5percent.PieChart;
  series?: am5percent.PieSeries;

  constructor(
    public element: HTMLElement,
    public data: Array<Record<string, any>>,
    public options: SlicedChartOptions,
    public isDarkMode: boolean,
    public horizontal = true
  ) {
    super(element, isDarkMode);

    this.chart = this.root.container.children.push(
      am5percent.PieChart.new(this.root, {
        layout: this.root.horizontalLayout,
        ...(options.legend ? { paddingTop: 0 } : {}),
      })
    );
    this.init();
  }

  get total(): number {
    return this.data.reduce(
      (result, item) => (result += item[this.options.series.value]),
      0
    );
  }

  get processedData(): Array<Record<string, any>> {
    return this.data
      .map((item) => ({
        ...item,
        percent:
          Math.round((item[this.options.series.value] * 10000) / this.total) /
          100,
      }))
      .sort(
        ({ percent: percentA }, { percent: percentB }) => percentB - percentA
      );
  }

  init() {
    this.addSeries();
    super.init();
  }

  addSeries() {
    this.series = this.chart.series.push(
      am5percent.PieSeries.new(this.root, {
        valueField: this.options.series.value,
        categoryField: this.options.series.category,
        ...(this.options.series.legendLabelText
          ? { legendLabelText: this.options.series.legendLabelText }
          : {}),
        ...(this.options.series.legendValueText
          ? { legendValueText: this.options.series.legendValueText }
          : {}),
      })
    );

    this.series.labels.template.set("forceHidden", true);
    this.series.ticks.template.set("forceHidden", true);
    this.series.data.setAll(this.processedData);
    this.series.labels.template.setAll({
      fontSize: 12,
      text: this.options.series.labelText,
    });
    this.series.slices.template.set(
      "tooltipText",
      this.options.series.tooltipText
    );
  }

  addLegend() {
    if (!this.options.legend || !this.series) {
      return;
    }

    const legend = this.chart.children.unshift(
      am5.Legend.new(this.root, {
        centerY: am5.p50,
        y: am5.p50,
        layout: this.root.verticalLayout,
        height: am5.p100,
        verticalScrollbar: am5.Scrollbar.new(this.root, {
          orientation: "vertical",
        }),
      })
    );

    legend.data.setAll(this.series.dataItems);
  }
}
