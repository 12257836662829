import {
  ReportResultItem,
  ReportFilter,
  MeasuresLiteFilter,
  CohortHeaders,
  CohortResultItem,
  ArpdauResultItem,
  RevenueResultItem,
  EventsSummaryResultItem,
  CalendarPerMinResultItem,
  SpendResultItem,
  SpendMiniResultItem,
  SpendHyperResultItem,
  MeasuresLiteReportType,
  CATotalResultItem,
  CADauDynamicsResultItem,
  CASubTotalResultItem,
  CACumulatedArpuResultItem,
  CACumulatedArpuStructureResultItem,
  CACumulatedRevenueSpendingsProfitResultItem,
  CARevenueSpendingsDailyDynamicsResultItem,
  CARoasNDayResultItem,
  CARetentionRateResultItem,
  CACumulatedPlaytimePerActiveUserResultItem,
  ReportDataType,
  ProfitResultItem,
  CpmResultItem,
  MetricsResultItem,
  ArpdauSimpleResultItem,
  WaterfallResultItem,
  EventsCostResultItem,
  ArpdauSimpleFilter,
  UsersActivityType,
  CashCountryResultItem,
  DiffInstallsStoresResultItem,
  SkadResultItem,
  AdRoasResultItem,
  MetricsSpendResultItem,
  FillRateResultItem,
  PayingUsersConversionResultItem,
  MMArpdauDiffDateCountryResultItem,
  MMArpdauDiffDateNetworkResultItem,
  MMRevenueDiffPercentDateCountryResultItem,
  MMRevenueDiffPercentDateNetworkResultItem,
  MonetizationMonitoringResultItem,
  FirebaseShowToImpressionResultItem,
  FirebaseVsNetworksResultItem,
  FirebaseFillRateResultItem,
  ReportType,
  TrafficQualityResultItem,
  UaMainMetricsOverviewResultItem,
  SubscriptionsOverviewResultItem,
  SubscriptionIosResultItem,
  MetricsConstructorResultItem,
  ReportItemRowObject,
  MonetizationMonitoringFilter,
  EventsSummaryLiteResultItem,
} from "@/reports/models";
import { CashGamingResultItem } from "../models/CashGamingReport";
import { CtrCalendarResultItem } from "../models/CtrCalendarReport";

export default class ReportProvide {
  static getResultData(
    filter: ReportFilter,
    headers: Array<string>,
    data: Array<Array<any>>,
    reportDataType?: ReportDataType
  ) {
    // Temp solution for MM report performance optimization
    if (
      (filter as MonetizationMonitoringFilter).noMonitoringTable &&
      reportDataType === ReportDataType.MONITORING
    ) {
      return [];
    }

    const headerDict = headers.reduce(
      (result, item, index) => Object.assign(result, { [item]: index }),
      {}
    );

    return data.map((rowArray) =>
      ReportProvide.createResultItem(
        new ReportItemRowObject(rowArray, headerDict),
        filter,
        reportDataType
      )
    );
  }

  static parseResultData(
    filter: ReportFilter,
    data?: string,
    reportDataType?: ReportDataType
  ) {
    if (!data) {
      return [];
    }

    const resultData = JSON.parse(data) as Array<Array<any>>;

    if (resultData.length === 0) {
      return [];
    }

    const headers = resultData.shift() as Array<string>;

    return this.getResultData(filter, headers, resultData, reportDataType);
  }

  static createResultItem(
    data: any,
    filter: ReportFilter,
    reportDataType: ReportDataType = ReportDataType.DATA
  ): ReportResultItem {
    return {
      REVENUE: () => new RevenueResultItem(data, filter),
      ARPU: () =>
        new CohortResultItem(data, filter, CohortHeaders.PRECISION_FOR_ARPU),
      RETURN_RATE: () =>
        new CohortResultItem(data, filter, CohortHeaders.PRECISION),
      TIME_SPENT: () =>
        new CohortResultItem(data, filter, CohortHeaders.PRECISION),
      COHORT_PER_MIN: () =>
        new CohortResultItem(data, filter, CohortHeaders.PRECISION),
      MEASURES_LITE: () =>
        new CohortResultItem(
          data,
          filter,
          (filter as MeasuresLiteFilter).reportType ===
            MeasuresLiteReportType.UNIQUE_USERS_COUNT ||
          (filter as MeasuresLiteFilter).reportType ===
            MeasuresLiteReportType.EVENTS_COUNT
            ? 0
            : CohortHeaders.PRECISION
        ),
      ARPDAU: () => new ArpdauResultItem(data, filter),
      ARPDAU_SIMPLE: () =>
        new ArpdauSimpleResultItem(
          data,
          filter,
          (filter as ArpdauSimpleFilter).usersActivityType ===
            UsersActivityType.ALL
        ),
      EVENTS_SUMMARY: () => new EventsSummaryResultItem(data, filter),
      CALENDAR_PER_MIN: () => new CalendarPerMinResultItem(data, filter),
      SPEND_MINI: () => new SpendMiniResultItem(data, filter),
      SPEND: () => new SpendResultItem(data, filter),
      SPEND_HYPER: () => new SpendHyperResultItem(data, filter),
      PROFIT: () => new ProfitResultItem(data, filter),
      CALENDAR_CPM: () => new CpmResultItem(data, filter),
      METRICS: () => new MetricsResultItem(data, filter),
      COHORT_ANALYSIS: () =>
        ((
          {
            [ReportDataType.TOTAL]: () => new CATotalResultItem(data),
            [ReportDataType.SUB_TOTAL]: () => new CASubTotalResultItem(data),
            [ReportDataType.DAU_DYNAMICS]: () =>
              new CADauDynamicsResultItem(data),
            [ReportDataType.REVENUE_SPENDINGS_DAILY_DYNAMICS]: () =>
              new CARevenueSpendingsDailyDynamicsResultItem(data),
            [ReportDataType.CUMULATED_REVENUE_SPENDINGS_PROFIT]: () =>
              new CACumulatedRevenueSpendingsProfitResultItem(data),
            [ReportDataType.CUMULATED_ARPU_STRUCTURE]: () =>
              new CACumulatedArpuStructureResultItem(data),
            [ReportDataType.CUMULATED_ARPU]: () =>
              new CACumulatedArpuResultItem(data),
            [ReportDataType.CUMULATED_ARPU_COHORT]: () =>
              new CohortResultItem(data, filter, 4),
            [ReportDataType.ROAS_N_DAY]: () => new CARoasNDayResultItem(data),
            [ReportDataType.ROAS_N_DAY_COHORT]: () =>
              new CohortResultItem(data, filter, 4),
            [ReportDataType.RETENTION_RATE]: () =>
              new CARetentionRateResultItem(data),
            [ReportDataType.RETENTION_RATE_COHORT]: () =>
              new CohortResultItem(data, filter, 2),
            [ReportDataType.CUMULATED_PLAYTIME_PER_ACTIVE_USER]: () =>
              new CACumulatedPlaytimePerActiveUserResultItem(data),
            [ReportDataType.CUMULATED_PLAYTIME_PER_ACTIVE_USER_COHORT]: () =>
              new CohortResultItem(data, filter, 0),
          } as Record<ReportDataType, any>
        )[reportDataType as ReportDataType]()),
      WATERFALL: () => new WaterfallResultItem(data, filter),
      EVENTS_COST: () => new EventsCostResultItem(data, filter),
      CALENDAR_CTR: () => new CtrCalendarResultItem(data, filter),
      CASH_COUNTRY: () => new CashCountryResultItem(data, filter),
      DIFF_INSTALLS_STORES: () =>
        new DiffInstallsStoresResultItem(
          data,
          reportDataType === ReportDataType.TOTAL
        ),
      COHORT_CTR: () =>
        new CohortResultItem(data, filter, CohortHeaders.PRECISION),
      AD_ROAS_COUNTRY: () =>
        new AdRoasResultItem(data, filter, CohortHeaders.PRECISION),
      AD_ROAS_NETWORK: () =>
        new AdRoasResultItem(data, filter, CohortHeaders.PRECISION),
      CASH_GAMING: () =>
        new CashGamingResultItem(
          data,
          filter,
          reportDataType === ReportDataType.DATA
        ),
      COHORT_CPM: () =>
        new CohortResultItem(data, filter, CohortHeaders.PRECISION),
      SKAD: () => new SkadResultItem(data, filter),
      METRICS_SPEND: () => new MetricsSpendResultItem(data, filter),
      FILL_RATE: () => new FillRateResultItem(data, filter),
      PAYING_USERS_CONVERSION: () =>
        new PayingUsersConversionResultItem(data, filter),
      PRICED_REVENUE: () => new RevenueResultItem(data, filter),
      MONETIZATION_MONITORING: () =>
        ((
          {
            [ReportDataType.REVENUE_DIFF_PERCENT_DATE_COUNTRY]: () =>
              new MMRevenueDiffPercentDateCountryResultItem(data),
            [ReportDataType.REVENUE_DIFF_PERCENT_DATE_NETWORK]: () =>
              new MMRevenueDiffPercentDateNetworkResultItem(data),
            [ReportDataType.ARPDAU_DIFF_DATE_COUNTRY]: () =>
              new MMArpdauDiffDateCountryResultItem(data),
            [ReportDataType.ARPDAU_DIFF_DATE_NETWORK]: () =>
              new MMArpdauDiffDateNetworkResultItem(data),
            [ReportDataType.MONITORING]: () =>
              new MonetizationMonitoringResultItem(data),
          } as Record<ReportDataType, any>
        )[reportDataType]()),
      FIREBASE_SHOW_TO_IMPRESSION: () =>
        new FirebaseShowToImpressionResultItem(data, filter),
      FIREBASE_VS_NETWORKS: () =>
        new FirebaseVsNetworksResultItem(data, filter),
      FIREBASE_FILL_RATE: () => new FirebaseFillRateResultItem(data, filter),
      TRAFFIC_QUALITY: () => new TrafficQualityResultItem(data, filter),
      UA_MAIN_METRICS_OVERVIEW: () =>
        new UaMainMetricsOverviewResultItem(data, filter),
      SUBSCRIPTIONS_OVERVIEW: () => new SubscriptionsOverviewResultItem(data),
      METRICS_CONSTRUCTOR: () => new MetricsConstructorResultItem(data, filter),
      SUBSCRIPTIONS_OVERVIEW_IOS: () => new SubscriptionIosResultItem(data),
      EVENTS_SUMMARY_LITE: () => new EventsSummaryLiteResultItem(data, filter),
    }[filter.reportId as ReportType]() as any;
  }
}
