export default class BankShortResponseModel {
  constructor(public id: number, public name: string) {}

  static of(model: BankShortResponseModel): BankShortResponseModel {
    return new BankShortResponseModel(model.id, model.name);
  }

  static ofArray(
    items: Array<BankShortResponseModel>
  ): Array<BankShortResponseModel> {
    return items.map((item) => BankShortResponseModel.of(item));
  }
}
