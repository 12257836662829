























import { Component, Prop, Vue } from "vue-property-decorator";

import MenuUtil from "@/shared/utils/MenuUtil";
import { AppSection } from "@/shared/models";

@Component
export default class AbTestMenu extends Vue {
  @Prop() applicationId!: string;

  get abTestsMenuItems(): Array<Record<string, any>> {
    const items = MenuUtil.getAbTestMenuItems(
      this.$store.state.userStore.currentUser.viewAccessEntities[
        this.applicationId
      ]
    ).map(({ atid, name, icon }) => ({
      atid,
      icon,
      name,
      ...(name === AppSection.AB_TESTS_GROUPS_SIZE_RECOMMENDER
        ? { href: `/v3/app/${this.applicationId}/ab-test-group-size` }
        : { to: { name, params: { id: this.applicationId } } }),
    }));

    return items;
  }
}
