import { EnvironmentType } from "./ApplicationResponseModel";

export enum ConfigValueType {
  STRING = "string",
  ARRAY = "array",
  OBJECT = "object",
  FLOAT = "float",
  INTEGER = "integer",
  BOOLEAN = "boolean",
}

export const CONFIGS_COLORS: Record<ConfigType, string> = {
  AB_TEST: "blue lighten-2",
  TARGETED: "green lighten-2",
  DEFAULT: "orange lighten-",
};

export enum ConfigType {
  AB_TEST = "AB_TEST",
  TARGETED = "TARGETED",
  DEFAULT = "DEFAULT",
}

export interface ConfigsDiffModel {
  key: string;
  value: string;
}

export interface ConfigResponseOverwrittenModel {
  children: Array<OverwrittenChildrenModel>;
  chipColor?: string;
  configId?: number;
  configName?: string;
  segmentName?: string | null;
  configType?: string;
  isMulti: false;
  link?: string;
  value: string;
}

export interface OverwrittenChildrenModel {
  chipColor: string;
  configId: number;
  configName: string;
  configType: string;
  link: string;
  value: string;
}

export class ConfigResponseEditedItemModel {
  constructor(
    public key: string,
    public value: any,
    public desc: string,
    public type: ConfigValueType
  ) {}

  clearData() {
    this.key = "";
    this.value = "";
    this.desc = "";
    this.type = ConfigValueType.STRING;
  }
}

export enum STAGE_PROP_TYPE {
  EDITED = "EDITED",
  NEW = "NEW",
  MISSING = "MISSING",
  SAME = "SAME",
}

export class ConfigResponseDataTableItem {
  constructor(
    public key: string,
    public value: string | number,
    public desc: string,
    public type: ConfigValueType,
    public overwritten?: Array<ConfigResponseOverwrittenModel>,
    public isParent?: boolean,
    public prodValue?: string | number,
    public stagePropType?: STAGE_PROP_TYPE,
    public isProdItem?: boolean
  ) {}
}

export interface ConfigsDiffParamsRequest {
  configId?: string;
  key?: string;
  value?: string;
  useHierarchy?: boolean;
  environmentType?: EnvironmentType;
}

export class ConfigsDiffResponseModel {
  constructor(
    public configId: number,
    public configName: string,
    public segmentName: string | null,
    public configType: ConfigType,
    public diff: Array<ConfigsDiffModel>
  ) {}

  static ofArray(configsDiff: Array<ConfigsDiffResponseModel>) {
    return configsDiff.map(
      (config) =>
        new ConfigsDiffResponseModel(
          config.configId,
          config.configName,
          config.segmentName,
          config.configType,
          config.diff
        )
    );
  }
}

export class ConfigStageResponseModel {
  constructor(
    public key: string,
    public value: string,
    public desc: string,
    public type: ConfigValueType
  ) {}
}
