export enum HealthcheckAlertDestination {
  MONETIZATION = "MONETIZATION",
  MARKETING = "MARKETING",
}

export class HealthcheckAlertModel {
  constructor(
    public id: number | null,
    public destination: HealthcheckAlertDestination,
    public applicationId: string,
    public metricId: string,
    public country: string,
    public absoluteDiff: number | null,
    public percentDiff: number | null,
    public operator: string | null,
    public observedPeriod: string | null,
    public basePeriod: string | null,
    public adType?: string
  ) {}

  static of(item: HealthcheckAlertModel) {
    return new HealthcheckAlertModel(
      item.id,
      item.destination,
      item.applicationId,
      item.metricId,
      item.country,
      item.absoluteDiff,
      item.percentDiff,
      item.operator,
      item.observedPeriod,
      item.basePeriod,
      item.adType
    );
  }

  static ofArray(items: Array<HealthcheckAlertModel>) {
    return items.map((it) => this.of(it));
  }
}
