










import { Component, Vue } from "vue-property-decorator";

@Component
export default class PickerDictionaryNotification extends Vue {
  get isUpdating(): boolean {
    return this.$store.state.dictionaryStore.isDictionaryUpdating;
  }
}
