








































import { Component, Vue, Prop } from "vue-property-decorator";

import {
  FilterPreview,
  FilterPreviewId,
  FilterColor,
  PREVIEW_COLOR,
  Application,
  Dictionary,
  DictionaryType,
} from "@/shared/models";

@Component
export default class ReportFilterCurrentStateItem extends Vue {
  @Prop() preview!: FilterPreview;
  @Prop({ default: true }) isClickable!: boolean;

  get hasMultipleValue() {
    return (
      ![
        FilterPreviewId.EVENT_PARAM,
        FilterPreviewId.GENERATED_EVENT_PARAM,
        FilterPreviewId.EVENTS_DATE,
      ].includes(this.preview.id) &&
      Array.isArray(this.preview.value) &&
      this.preview.value.length > 1
    );
  }

  get previewColor() {
    const baseColor = PREVIEW_COLOR.get(this.preview.id) || FilterColor.GREY;

    return `${baseColor} ${this.$vuetify.theme.dark ? "darken" : "lighten"}-3`;
  }

  get previewLabel() {
    if (this.preview.id === FilterPreviewId.NET_REVENUE) {
      return `<span class="font-weight-medium">${this.$lang(
        this.preview.value
          ? `filterPreview.net_revenue`
          : `filterPreview.gross_revenue`
      )}</span>`;
    }

    let previewText = `<span class="font-weight-medium">${this.$lang(
      `filterPreview.${this.preview.id.toLowerCase()}`
    )}</span>`;

    if (this.preview.id === FilterPreviewId.SESSION_NUMBER_PERIOD) {
      return (previewText += `: ${this.$lang(
        `shared.filters.sessionNumberPeriod.${this.preview.value.toLowerCase()}`
      )}`);
    }

    if (
      this.preview.id === FilterPreviewId.LAT &&
      typeof this.preview.value === "boolean"
    ) {
      previewText += `: ${this.$lang(
        `shared.boolNames.${this.preview.value ? "on" : "off"}`
      )}`;
    }

    if (
      typeof this.preview.value === "number" ||
      typeof this.preview.value === "string"
    ) {
      previewText += `: ${this.getProcessedValue(this.preview.value)}`;
    }

    if (Array.isArray(this.preview.value)) {
      if (
        [
          FilterPreviewId.EVENT_PARAM,
          FilterPreviewId.GENERATED_EVENT_PARAM,
        ].includes(this.preview.id)
      ) {
        previewText += `: ${this.getEventParamValue(
          this.preview.value as Array<string | number>
        )}`;
      } else if (this.preview.id === FilterPreviewId.EVENTS_DATE) {
        const datePreview =
          this.preview.value[0] === this.preview.value[1]
            ? this.preview.value[0]
            : `${this.preview.value[0]} — ${this.preview.value[1]}`;

        previewText += `: ${datePreview}`;
      } else if (
        this.preview.id === FilterPreviewId.COUNTRY ||
        this.preview.id === FilterPreviewId.EVENTS_COUNTRY
      ) {
        const foundTier = this.$store.state.dictionaryStore[
          DictionaryType.GROUPED_COUNTRIES
        ].values.find(
          (item: Dictionary) =>
            item?.childValues?.length ===
              (this.preview.value as Array<string>).length &&
            item?.childValues?.every((item) =>
              (this.preview.value as Array<string>).includes(item.value)
            )
        );
        if (foundTier) {
          previewText += `: ${foundTier.name}`;
        } else if (!foundTier && this.preview.value.length === 1) {
          return (previewText += `: ${this.getProcessedValue(
            this.preview.value[0]
          )}`);
        }
      } else if (this.preview.value.length === 1) {
        previewText += `: ${this.getProcessedValue(this.preview.value[0])}`;
      }
    }

    return previewText;
  }

  getProcessedValue(value: string | number) {
    const filterMap = new Map<FilterPreviewId, string>([
      [FilterPreviewId.GROUP_BY, "components.groupBy"],
      [FilterPreviewId.AGGREGATION_PERIOD, "components.groupBy"],
      [FilterPreviewId.PROVIDED_BANNER, "components.providedBannerPicker"],
      [FilterPreviewId.AD_REVENUE_METHOD, "components.adRevenueMethodPicker"],
      [FilterPreviewId.USER_ACTIVITY, "components.usersActivityTypePicker"],
      [FilterPreviewId.REPORT_TYPE_TIME_SPENT, "views.timeSpent"],
      [FilterPreviewId.REPORT_TYPE_MEASURES_LITE, "views.measures"],
      [FilterPreviewId.REPORT_TYPE_COHORT_PERMIN, "views.perMin"],
      [FilterPreviewId.REPORT_TYPE_PERMIN, "views.perMin"],
      [FilterPreviewId.AD_TYPE, "views.perMin"],
      [FilterPreviewId.EVENT_SIMPLE, "components.baseTable"],
      [FilterPreviewId.ATTRIBUTION_STATUS, "components.baseTable"],
      [FilterPreviewId.ADDITIONAL_GROUPING, "components.groupBy"],
      [FilterPreviewId.TIME_SPENT_TYPE, "components.metrics"],
      [FilterPreviewId.CONVERSION_VALUE, "views.conversionValue"],
      [FilterPreviewId.SKAD_REPORT_TYPE, "views.skadReportType"],
    ]);
    const filterMapValue = filterMap.get(this.preview.id);

    if (filterMapValue) {
      return this.$lang(`${filterMapValue}.${(value as string).toLowerCase()}`);
    }

    if (this.preview.id === FilterPreviewId.APPLICATIONS) {
      const app = this.$store.state.application.apps.find(
        (app: Application) => app.id === value
      );

      return app?.name ?? value;
    }

    if (this.preview.id === FilterPreviewId.PLATFORMS) {
      const platform = this.$store.state.application.platforms.find(
        (platform: Dictionary) => platform.value === value
      );

      return platform?.name ?? value;
    }

    if (this.preview.id === FilterPreviewId.LAT) {
      return this.$lang(`shared.boolNames.${value ? "on" : "off"}`);
    }

    if (this.preview.dictionary) {
      const dictionary = this.$store.state.dictionaryStore[
        this.preview.dictionary
      ].values.find(
        (dictionary: Dictionary) => dictionary.value === value.toString()
      );

      if (dictionary) {
        return dictionary.text;
      }
    }

    return value;
  }

  getEventParamValue(value: Array<string | number>) {
    const [name, operator, ...values] = value;
    let returnedValue = name;

    if (operator) {
      returnedValue += ` ${this.$lang(
        `shared.eventName.${(operator as string).toLowerCase()}`
      )} `;
    }

    if (values.length) {
      returnedValue +=
        values.length > 2
          ? values.join(", ")
          : values.join(` ${this.$lang("shared.and")} `);
    }

    return returnedValue;
  }

  onClick() {
    if (!this.isClickable) {
      return;
    }

    this.$store.commit("setClickedFilterPreviewId", this.preview.id);
  }
}
