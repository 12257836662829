import { AxiosResponse } from "axios";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import axios, { AxiosSkipErrorsConfig } from "@/shared/plugins/axios";
import HealthCheckSummaryUtils from "@/healthcheck/utils/HealthCheckSummaryUtils";
import { DataFlowFullMetricsByApp } from "@/healthcheck/model/DataFlowFullResponse";
import { DataFlowFullTable } from "@/healthcheck/model/DataFlowFullTable";
import { ParsingFullMetricsByApp } from "@/healthcheck/model/ParsingFullResponse";
import { ParsingFullTablePerDate } from "@/healthcheck/model/ParsingFullTablePerDate";
import { ParsingFullTablePerMetric } from "@/healthcheck/model/ParsingFullTablePerMetric";
import { JobQueueInfoResponse } from "@/healthcheck/model/JobQueueInfoResponse";
import { JobInfo } from "@/healthcheck/model/JobInfo";
import { QueueInfo } from "@/healthcheck/model/QueueInfo";
import { SummaryResponseModel } from "@/healthcheck/model/SummaryResponseModel";
import { MetricWeightRequestModel } from "@/healthcheck/model/MetricWeightRequestModel";
import { MetricWeightResponseModel } from "@/healthcheck/model/MetricWeightResponseModel";

@Module
export default class HealthCheckStore extends VuexModule {
  isSummaryLoading = true;
  isDataFlowLoading = true;
  isParsingLoading = true;
  isJobsQueueLoading = true;
  isMetricWeightsLoading = false;
  isSummaryUpdateLoading = false;
  summaryHealthCheckTables: Array<SummaryResponseModel> = [];
  dataFlowTables: Array<DataFlowFullTable> = [];
  parsingPerDateTables: Array<ParsingFullTablePerDate> = [];
  parsingPerMetricTables: Array<ParsingFullTablePerMetric> = [];
  startDate = "";
  endDate = "";
  metrics: Array<string> = [];
  jobsQueueInfoResponse: Array<JobQueueInfoResponse> = [];
  jobsInfoTable: Array<JobInfo> = [];
  queuesInfoTable: Array<QueueInfo> = [];
  metricWeights: Array<MetricWeightResponseModel> = [];
  isHealthCheckSegmentsUpdating = false;
  isThirdPartyUpdateLoading = false;
  isParsingUpdateLoading = false;
  isMetricWeightsSaving = false;
  isMetricWeightDeleting = false;

  @Mutation
  setSummaryHealthCheckTable(payload: Array<SummaryResponseModel>) {
    this.summaryHealthCheckTables = SummaryResponseModel.ofArray(payload);
    this.isSummaryLoading = false;
    this.isDataFlowLoading = true;
    this.isParsingLoading = true;
    this.isJobsQueueLoading = true;
  }

  @Mutation
  setDataFlowFullTable(dataFlowFullData: DataFlowFullMetricsByApp) {
    this.dataFlowTables =
      HealthCheckSummaryUtils.convertDataFlowFullMetricsByAppToTableData(
        dataFlowFullData
      );
    this.isSummaryLoading = true;
    this.isDataFlowLoading = false;
    this.isParsingLoading = true;
    this.isJobsQueueLoading = true;
  }

  @Mutation
  setParsingPerDateTable(parsingFullMetricsByApp: ParsingFullMetricsByApp) {
    this.startDate = parsingFullMetricsByApp.startDate;
    this.endDate = parsingFullMetricsByApp.endDate;
    this.metrics = parsingFullMetricsByApp.metrics.map((it) => it.metric);
    this.parsingPerDateTables =
      HealthCheckSummaryUtils.convertParsingFullMetricsByAppToTablePerDayData(
        parsingFullMetricsByApp
      );
    this.parsingPerMetricTables =
      HealthCheckSummaryUtils.convertParsingFullMetricsByAppToTablePerMetric(
        parsingFullMetricsByApp
      );
    this.isSummaryLoading = true;
    this.isDataFlowLoading = true;
    this.isParsingLoading = false;
    this.isJobsQueueLoading = true;
  }

  @Mutation
  setJobsQueue(jobsQueueInfoResponse: Array<JobQueueInfoResponse>) {
    this.jobsQueueInfoResponse = jobsQueueInfoResponse;
    this.jobsInfoTable = HealthCheckSummaryUtils.convertJobsQueueInfoToJobsInfo(
      this.jobsQueueInfoResponse
    );
    this.queuesInfoTable =
      HealthCheckSummaryUtils.convertJobsQueueInfoToQueuesInfo(
        this.jobsQueueInfoResponse
      );
    this.isSummaryLoading = true;
    this.isDataFlowLoading = true;
    this.isParsingLoading = true;
    this.isJobsQueueLoading = false;
  }

  @Mutation
  setIsThirdPartyUpdateLoading(payload: boolean) {
    this.isThirdPartyUpdateLoading = payload;
  }

  @Mutation
  setIsParsingUpdateLoading(payload: boolean) {
    this.isParsingUpdateLoading = payload;
  }

  @Mutation
  setIsSummaryUpdateLoading(payload: boolean) {
    this.isSummaryUpdateLoading = payload;
  }

  @Mutation
  setHealthCheckSegmentsUpdating(payload: boolean) {
    this.isHealthCheckSegmentsUpdating = payload;
  }

  @Mutation
  setMetricWeights(metricWeights: Array<MetricWeightResponseModel>) {
    this.metricWeights = MetricWeightResponseModel.ofArray(metricWeights);
  }

  @Mutation
  setIsMetricWeightsLoading(payload: boolean) {
    this.isMetricWeightsLoading = payload;
  }

  @Mutation
  setIsMetricWeightSaving(payload: boolean) {
    this.isMetricWeightsSaving = payload;
  }

  @Mutation
  setIsMetricWeightDeleting(payload: boolean) {
    this.isMetricWeightDeleting = payload;
  }

  @Mutation
  setMetricWeightAfterCreate(metricWeight: MetricWeightResponseModel) {
    this.metricWeights.push(MetricWeightResponseModel.of(metricWeight));
  }

  @Mutation
  setMetricWeightAfterUpdate(metricWeight: MetricWeightResponseModel) {
    const indexOfUpdatedEntity = this.metricWeights.findIndex(
      (it) => it.id === metricWeight.id
    );
    this.metricWeights.splice(indexOfUpdatedEntity, 1, metricWeight);
  }

  @Mutation
  setMetricWeightAfterDelete(metricWeight: MetricWeightRequestModel) {
    const indexOfDeletedEntity = this.metricWeights.findIndex(
      (it) => it.id === metricWeight.id
    );
    this.metricWeights.splice(indexOfDeletedEntity);
  }

  @Action({ commit: "setSummaryHealthCheckTable" })
  async getHealthCheckData() {
    return axios
      .get(`/api/developer/healthcheck/summary`)
      .then(
        (result: AxiosResponse<Array<SummaryResponseModel>>) => result.data
      );
  }

  @Action
  async postThirdPartyUpdate() {
    this.context.commit("setIsThirdPartyUpdateLoading", true);
    return axios
      .post(`/api/developer/healthcheck/third-party/update`)
      .then(() => {
        this.context.dispatch("getHealthCheckData");
      })
      .finally(() => {
        this.context.commit("setIsThirdPartyUpdateLoading", false);
      });
  }

  @Action
  async postParsingUpdate() {
    this.context.commit("setIsParsingUpdateLoading", true);
    return axios
      .post(`/api/developer/healthcheck/parsing/update`)
      .then(() => {
        this.context.dispatch("getHealthCheckData");
      })
      .finally(() => {
        this.context.commit("setIsParsingUpdateLoading", false);
      });
  }

  @Action
  async postDataFlowShortUpdate() {
    this.context.commit("setIsSummaryUpdateLoading", true);
    return axios
      .post(`/api/developer/healthcheck/dataflow-short/update`)
      .then(() => {
        this.context.dispatch("getHealthCheckData");
      })
      .finally(() => {
        this.context.commit("setIsSummaryUpdateLoading", false);
      });
  }

  @Action({ commit: "setDataFlowFullTable" })
  async getDataFlowFullData({
    applicationId,
    timePeriodDays,
  }: {
    applicationId: string;
    timePeriodDays: number;
  }) {
    return axios
      .get(
        `/api/developer/healthcheck/dataflow-full/application/${applicationId}/time-period/${timePeriodDays}`
      )
      .then((result: AxiosResponse<DataFlowFullMetricsByApp>) => result.data);
  }

  @Action({ commit: "setParsingPerDateTable" })
  async getParsingFullData({ applicationId }: { applicationId: string }) {
    return axios
      .get(
        `/api/developer/healthcheck/parsing-full/application/${applicationId}`,
        { skipErrors: true } as AxiosSkipErrorsConfig
      )
      .then((result: AxiosResponse<ParsingFullMetricsByApp>) => result.data)
      .catch(() => {
        return new ParsingFullMetricsByApp();
      });
  }

  @Action({ commit: "setJobsQueue" })
  async getJobsQueue() {
    return axios
      .get(`/api/developer/healthcheck/jobs-queue`)
      .then(
        (result: AxiosResponse<Array<JobQueueInfoResponse>>) => result.data
      );
  }

  @Action
  async updateHealthCheckSegments() {
    this.context.commit("setHealthCheckSegmentsUpdating", true);

    return axios
      .post("/api/developer/healthcheck/segments/update")
      .then(() => {
        this.context.dispatch("getHealthCheckData");
      })
      .finally(() => {
        this.context.commit("setHealthCheckSegmentsUpdating", false);
      });
  }

  @Action({ commit: "setMetricWeights" })
  async getMetricWeights() {
    this.context.commit("setIsMetricWeightsLoading", true);
    return axios
      .get(`/api/developer/healthcheck/metric-weights`)
      .then(
        (result: AxiosResponse<Array<MetricWeightResponseModel>>) => result.data
      )
      .finally(() => {
        this.context.commit("setIsMetricWeightsLoading", false);
      });
  }

  @Action({ commit: "setMetricWeightAfterCreate" })
  async createMetricWeight(payload: MetricWeightRequestModel) {
    this.context.commit("setIsMetricWeightSaving", true);
    return axios
      .post("/api/developer/healthcheck/metric-weights", payload)
      .then(({ data }: AxiosResponse<MetricWeightRequestModel>) => {
        this.context.commit("addNotification", {
          message: {
            key: "metricWeights.notifications.createdSuccess",
          },
        });
        return data;
      })
      .finally(() => {
        this.context.commit("setIsMetricWeightSaving", false);
      });
  }

  @Action({ commit: "setMetricWeightAfterUpdate" })
  async updateMetricWeight(payload: MetricWeightRequestModel) {
    this.context.commit("setIsMetricWeightSaving", true);
    return axios
      .patch(`/api/developer/healthcheck/metric-weights/${payload.id}`, payload)
      .then(({ data }: AxiosResponse<MetricWeightRequestModel>) => {
        this.context.commit("addNotification", {
          message: {
            key: "metricWeights.notifications.updatedSuccess",
          },
        });
        return data;
      })
      .finally(() => {
        this.context.commit("setIsMetricWeightSaving", false);
      });
  }

  @Action({ commit: "setMetricWeightAfterDelete" })
  async deleteMetricWeight(id: number) {
    this.context.commit("setIsMetricWeightDeleting", true);
    return axios
      .delete(`/api/developer/healthcheck/metric-weights/${id}`)
      .then(({ data }: AxiosResponse<MetricWeightRequestModel>) => {
        this.context.commit("addNotification", {
          message: {
            key: "metricWeights.notifications.deletedSuccess",
          },
        });
        return data;
      })
      .finally(() => {
        this.context.commit("setIsMetricWeightDeleting", false);
      });
  }
}
