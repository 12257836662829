import Vue from "vue";
import Vuex from "vuex";
import AbTestConfigStore from "@/ab-tests/store/AbTestConfigStore";
import AccountSettingsStore from "@/account-settings/store/AccountSettingsStore";
import AlertsSystemStore from "@/alerts-system/store/AlertsSystemStore";
import ApplicationInfoStore from "@/application-manager/store/ApplicationInfoStore";
import ApplicationWorkflowStore from "@/application-manager/store/ApplicationWorkflowStore";
import ApplicationStore from "./modules/ApplicationStore";
import BatchStore from "@/application-manager/store/BatchStore";
import CancelRequestStore from "@/shared/store/modules/CancelRequestStore";
import ConfigsStore from "@/ab-tests/store/ConfigsStore";
import ConfigurationStore from "@/application-manager/store/ConfigurationStore";
import DashboardStore from "@/dashboard/store/DashboardStore";
import DefaultConfigStore from "@/ab-tests/store/DefaultConfigStore";
import DictionaryStore from "./modules/DictionaryStore";
import ExecutionStore from "@/application-manager/store/ExecutionStore";
import ExportEventsStore from "@/tools/store/ExportEventsStore";
import ExternalTestStore from "@/ab-tests/store/ExternalTestStore";
import FunnelStore from "@/funnels/store/FunnelStore";
import HealthCheckStore from "@/healthcheck/store/HealthCheckStore";
import InfraCommandsStore from "@/application-manager/store/InfraCommandsStore";
import JurisdictionStore from "@/application-manager/store/JurisdictionStore";
import MenuStore from "./modules/MenuStore";
import NetworksManagementStore from "@/settings/store/NetworksManagementStore";
import NotificationStore from "./modules/NotificationStore";
import PlacementsStore from "@/settings/store/PlacementsStore";
import ReportStore from "@/reports/store/ReportStore";
import RevenueEventsStore from "@/settings/store/RevenueEventsStore";
import SaveDictionaryStore from "@/settings/store/SaveDictionaryStore";
import SubscriptionEventsStore from "@/settings/store/SubscriptionEventsStore";
import ScenarioStore from "@/application-manager/store/ScenarioStore";
import SegmentStore from "@/segments/store/SegmentStore";
import TargetedConfigStore from "@/ab-tests/store/TargetedConfigStore";
import TemplateStore from "@/templates/store/TemplateStore";
import TrackerStore from "./modules/TrackerStore";
import UnsavedChangesStore from "./modules/UnsavedChangesStore";
import UserStore from "./modules/UserStore";
import UsersStore from "@/iam/store/UsersStore";
import BankStore from "@/accounting-portal/store/BankStore";
import CounterpartyStore from "@/accounting-portal/store/CounterpartyStore";
import InvoiceStore from "@/accounting-portal/store/InvoiceStore";
import HealthcheckAlertsStore from "@/tools/store/HealthcheckAlertsStore";
import ValidationStore from "./modules/ValidationStore";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    abTestConfig: AbTestConfigStore,
    accountSettingsStore: AccountSettingsStore,
    alertsSystemStore: AlertsSystemStore,
    application: ApplicationStore,
    applicationInfoStore: ApplicationInfoStore,
    batchStore: BatchStore,
    cancelRequestStore: CancelRequestStore,
    configsStore: ConfigsStore,
    configurationStore: ConfigurationStore,
    dashboardStore: DashboardStore,
    defaultConfig: DefaultConfigStore,
    dictionaryStore: DictionaryStore,
    executionStore: ExecutionStore,
    exportEventsStore: ExportEventsStore,
    externalTestStore: ExternalTestStore,
    funnelStore: FunnelStore,
    healthCheckStore: HealthCheckStore,
    infraCommandsStore: InfraCommandsStore,
    jurisdictionStore: JurisdictionStore,
    menuStore: MenuStore,
    networksManagementStore: NetworksManagementStore,
    notificationStore: NotificationStore,
    placementsStore: PlacementsStore,
    reportStore: ReportStore,
    revenueEventsStore: RevenueEventsStore,
    saveDictionaryStore: SaveDictionaryStore,
    scenarioStore: ScenarioStore,
    segmentStore: SegmentStore,
    subscriptionEventsStore: SubscriptionEventsStore,
    targetedConfig: TargetedConfigStore,
    templateStore: TemplateStore,
    trackerStore: TrackerStore,
    unsavedChangesStore: UnsavedChangesStore,
    userStore: UserStore,
    usersStore: UsersStore,
    bankStore: BankStore,
    counterpartyStore: CounterpartyStore,
    invoiceStore: InvoiceStore,
    healthcheckAlertsStore: HealthcheckAlertsStore,
    applicationWorkflowStore: ApplicationWorkflowStore,
    validationStore: ValidationStore,
  },
});
