import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { AxiosResponse } from "axios";

import axios, { AxiosSkipErrorsConfig } from "@/shared/plugins/axios";
import {
  ExternalTestConfigModel,
  ExternalTestModel,
  EXTERNAL_TEST_STATUSES,
} from "@/ab-tests/models/ExternalTest";
import { ConfigsStatus } from "@/ab-tests/models/ConfigsStatusModel";
import { AbTestResultModel } from "@/ab-tests/models/AbTestConfigurationModel";
import ConfigUtil from "@/ab-tests/utils/ConfigUtil";

@Module
export default class ExternalTestStore extends VuexModule {
  isExternalTestConfigLoading = false;
  isExternalTestConfigSaving = false;
  externalTestConfig: ExternalTestConfigModel | null = null;
  isExternalTestNameUnique = true;
  isExternalTestIdUnique = true;
  isExternalTestSaving = false;
  externalTests = EXTERNAL_TEST_STATUSES.reduce(
    (
      res: Record<ConfigsStatus, Array<ExternalTestModel>>,
      status: ConfigsStatus
    ) => ({
      ...res,
      [status]: [],
    }),
    {} as Record<ConfigsStatus, Array<ExternalTestModel>>
  );
  isExternalTestsLoading = false;
  externalTestTotals: Record<ConfigsStatus, number> | null = null;
  isHistorySyncing = false;
  isTestFinishing = false;
  test: ExternalTestModel | null = null;
  isTestLoading = false;

  @Mutation
  setExternalTestConfigLoading(payload: boolean) {
    this.isExternalTestConfigLoading = payload;
  }

  @Mutation
  setExternalTestConfigSaving(payload: boolean) {
    this.isExternalTestConfigSaving = payload;
  }

  @Mutation
  setExternalTestConfig(payload: ExternalTestConfigModel | null) {
    this.externalTestConfig = payload
      ? ExternalTestConfigModel.of(payload)
      : payload;
  }

  @Mutation
  setExternalTestNameUniqueness(payload: boolean) {
    this.isExternalTestNameUnique = payload;
  }

  @Mutation
  setExternalTestIdUniqueness(payload: boolean) {
    this.isExternalTestIdUnique = payload;
  }

  @Mutation
  setExternalTestSaving(payload: boolean) {
    this.isExternalTestSaving = payload;
  }

  @Mutation
  setExternalTestsLoading(payload: boolean) {
    this.isExternalTestsLoading = payload;
  }

  @Mutation
  setExternalTests(payload: Record<ConfigsStatus, Array<ExternalTestModel>>) {
    Object.entries(payload).forEach(
      ([status, tests]: [string, Array<ExternalTestModel>]) => {
        this.externalTests[status as ConfigsStatus] = tests.map(
          (test: ExternalTestModel) => ExternalTestModel.of(test)
        );
      }
    );
  }

  @Mutation
  setExternalTestsAfterDelete(payload: number) {
    this.externalTests = Object.entries(this.externalTests).reduce(
      (
        res: Record<ConfigsStatus, Array<ExternalTestModel>>,
        [status, configs]: [string, Array<ExternalTestModel>]
      ) => {
        return {
          ...res,
          [status]: configs.filter((config) => config.id !== payload),
        };
      },
      {} as Record<ConfigsStatus, Array<ExternalTestModel>>
    );
  }

  @Mutation
  updateExternalTestTotals(payload: ExternalTestModel) {
    if (this.externalTestTotals) {
      this.externalTestTotals[payload.status as ConfigsStatus]--;
    }
  }

  @Mutation
  clearExternalTests() {
    this.externalTests = EXTERNAL_TEST_STATUSES.reduce(
      (
        res: Record<ConfigsStatus, Array<ExternalTestModel>>,
        status: ConfigsStatus
      ) => ({
        ...res,
        [status]: [],
      }),
      {} as Record<ConfigsStatus, Array<ExternalTestModel>>
    );
  }

  @Mutation
  setExternalTestTotals(payload: Record<ConfigsStatus, number>) {
    this.externalTestTotals = payload;
  }

  @Mutation
  setHistorySyncing(payload: boolean) {
    this.isHistorySyncing = payload;
  }

  @Mutation
  setTestFinishing(payload: boolean) {
    this.isTestFinishing = payload;
  }

  @Mutation
  setTestLoading(payload: boolean) {
    this.isTestLoading = payload;
  }

  @Mutation
  setExternalTest(payload: ExternalTestModel) {
    this.test = ExternalTestModel.of(payload);
  }

  @Mutation
  setResultTypeForExternalTestById(payload: Array<AbTestResultModel>) {
    payload.forEach((abTestResult) => {
      const found = this.externalTests[
        ConfigsStatus.STATISTICS_RECALCULATED
      ].find(({ id }: ExternalTestModel) => abTestResult.configId === id);

      if (found?.metric) {
        found.resultType = ConfigUtil.getConfigResultType(
          found.metric,
          abTestResult
        );
      }
    });
  }

  @Action({ commit: "setExternalTestConfig" })
  async getExternalTestConfig() {
    this.context.commit("setExternalTestConfig", null);
    this.context.commit("setExternalTestConfigLoading", true);

    return axios
      .get(
        `/api/appadmin/app/${this.context.rootState.application.applicationId}/settings/externalAbTestMappings`,
        { skipErrors: true } as AxiosSkipErrorsConfig
      )
      .then((result: AxiosResponse<ExternalTestConfigModel>) => {
        return result.data;
      })
      .catch((error) => {
        if (error.response.status !== 404) {
          this.context.dispatch("addError", error.response.message);
        }
      })
      .finally(() => {
        this.context.commit("setExternalTestConfigLoading", false);
      });
  }

  @Action({ commit: "setExternalTestConfig" })
  async saveExternalTestConfig(payload: ExternalTestConfigModel) {
    this.context.commit("setExternalTestConfigSaving", true);

    return axios
      .post(
        `/api/appadmin/app/${this.context.rootState.application.applicationId}/settings/externalAbTestMappings`,
        payload
      )
      .then((result: AxiosResponse<ExternalTestConfigModel>) => {
        this.context.commit("addNotification", {
          message: {
            key: "externalTest.successSave",
          },
        });

        return result.data;
      })
      .finally(() => {
        this.context.commit("setExternalTestConfigSaving", false);
      });
  }

  @Action({ commit: "setExternalTests" })
  getExternalTests(statuses: Array<ConfigsStatus>) {
    this.context.commit("setExternalTestsLoading", true);

    return axios
      .get(
        `/api/ab-tests/app/${this.context.rootState.application.applicationId}/externalAbtest/configs/statuses/configs`,
        {
          params: { statuses: statuses.join(",") },
        }
      )
      .then(
        (
          result: AxiosResponse<Record<ConfigsStatus, Array<ExternalTestModel>>>
        ) => {
          this.context.commit("clearExternalTests");

          return result.data;
        }
      )
      .finally(() => {
        this.context.commit("setExternalTestsLoading", false);
      });
  }

  @Action({ commit: "setExternalTestTotals" })
  getExternalTestTotals(statuses: Array<ConfigsStatus>) {
    return axios
      .get(
        `/api/ab-tests/app/${this.context.rootState.application.applicationId}/externalAbtest/configs/statuses/totals`,
        {
          params: { statuses: statuses.join(",") },
        }
      )
      .then((result: AxiosResponse<Record<ConfigsStatus, number>>) => {
        return result.data;
      });
  }

  @Action({ commit: "setExternalTestNameUniqueness" })
  checkExternalTestNameUniqueness(name: string) {
    if (!name) {
      return true;
    }

    return axios
      .post(
        `/api/ab-tests/app/${this.context.rootState.application.applicationId}/externalAbtest/configs/uniqueName`,
        {
          name,
        }
      )
      .then((result: AxiosResponse<{ unique: boolean }>) => result.data.unique);
  }

  @Action({ commit: "setExternalTestIdUniqueness" })
  checkExternalTestIdUniqueness(externalId: string) {
    if (!externalId) {
      return true;
    }

    return axios
      .post(
        `/api/ab-tests/app/${this.context.rootState.application.applicationId}/externalAbtest/configs/uniqueExternalId`,
        {
          externalId,
        }
      )
      .then((result: AxiosResponse<{ unique: boolean }>) => result.data.unique);
  }

  @Action
  async saveExternalTest(externalTest: ExternalTestModel) {
    this.context.commit("setExternalTestSaving", true);

    return axios
      .post(
        `/api/ab-tests/app/${this.context.rootState.application.applicationId}/externalAbtest/configs`,
        externalTest
      )
      .then(() => {
        this.context.commit("addNotification", {
          message: {
            key: "externalTest.notification.saveSuccess",
            params: [externalTest.name],
          },
        });
      })
      .finally(() => {
        this.context.commit("setExternalTestSaving", false);
      });
  }

  @Action
  async checkSegmentNameUniqueness(name: string) {
    if (!name) {
      return true;
    }

    return axios
      .post(
        `/api/app/${this.context.rootState.application.applicationId}/segments/uniqueName`,
        {
          name,
        }
      )
      .then((result: any) => result.data.unique);
  }

  @Action
  async syncExternalTestConfigHistory([from, to]: Array<string>) {
    this.context.commit("setHistorySyncing", true);

    return axios
      .post(
        `/api/appadmin/app/${this.context.rootState.application.applicationId}/settings/externalAbTestMappings/sync`,
        {
          from,
          to,
        }
      )
      .then(() => {
        this.context.commit("addNotification", {
          message: {
            key: "externalTest.successHistorySyncing",
          },
        });
      })
      .finally(() => {
        this.context.commit("setHistorySyncing", false);
      });
  }

  @Action
  async finishExternalTest({
    id,
    payload,
  }: {
    id: number;
    payload: { activeTill: string };
  }) {
    this.context.commit("setTestFinishing", true);

    return axios
      .post(
        `/api/ab-tests/app/${this.context.rootState.application.applicationId}/externalAbtest/configs/${id}/finish`,
        payload
      )
      .then(() => {
        this.context.commit("addNotification", {
          message: {
            key: "externalTest.successTestFinishing",
          },
        });
      })
      .finally(() => {
        this.context.commit("setTestFinishing", false);
      });
  }

  @Action({ commit: "setExternalTest", rawError: true })
  async getExternalTest(id: number) {
    this.context.commit("setTestLoading", true);

    return axios
      .get(
        `/api/ab-tests/app/${this.context.rootState.application.applicationId}/externalAbtest/configs/${id}`,
        { skipErrors: true } as AxiosSkipErrorsConfig
      )
      .then((result: AxiosResponse<ExternalTestModel>) => result.data)
      .finally(() => {
        this.context.commit("setTestLoading", false);
      });
  }

  @Action({ commit: "setExternalTest" })
  async getExternalTestBySegmentId(segmentId: number) {
    this.context.commit("setTestLoading", true);

    return axios
      .get(
        `/api/ab-tests/app/${this.context.rootState.application.applicationId}/externalAbtest/configs/search`,
        {
          params: { segmentId: segmentId },
        }
      )
      .then((result: AxiosResponse<ExternalTestConfigModel>) => result.data)
      .finally(() => {
        this.context.commit("setTestLoading", false);
      });
  }

  @Action({ commit: "setExternalTestsAfterDelete" })
  async deleteExternalTest(externalTest: ExternalTestModel) {
    this.context.commit("setTestLoading", true);

    return axios
      .delete(
        `/api/ab-tests/app/${this.context.rootState.application.applicationId}/externalAbtest/configs/${externalTest.id}`
      )
      .then(() => {
        this.context.commit("addNotification", {
          message: {
            key: "externalTest.notification.deleteSuccess",
          },
        });
        this.context.commit("updateExternalTestTotals", externalTest);

        return externalTest.id;
      })
      .finally(() => {
        this.context.commit("setTestLoading", false);
      });
  }
}
