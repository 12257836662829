import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import axios from "@/shared/plugins/axios";
import { AxiosResponse } from "axios";

import {
  HealthcheckAlertDestination,
  HealthcheckAlertModel,
} from "@/tools/models/healthcheck-alert/HealthcheckAlertModel";
import { HealthcheckAlertsSettingsModel } from "@/tools/models/healthcheck-alert/HealthcheckAlertsSettingsModel";

@Module
export default class HealthcheckAlertsStore extends VuexModule {
  healthcheckAlertsDestination: HealthcheckAlertDestination | null = null;
  healthcheckAlerts: Array<HealthcheckAlertModel> = [];
  healthcheckAlertsSettings: HealthcheckAlertsSettingsModel | null = null;
  isHealthcheckAlertsLoading = false;
  isHealthcheckAlertsFileLoading = false;
  healthcheckAlertsFileName = "";

  get healthcheckAlertsPath(): string {
    return this.healthcheckAlertsDestination
      ? {
          [HealthcheckAlertDestination.MARKETING]: "marketing-alert",
          [HealthcheckAlertDestination.MONETIZATION]: "monetization-alert",
        }[this.healthcheckAlertsDestination]
      : "";
  }

  @Mutation
  resetHealthcheckAlertsStore() {
    this.healthcheckAlertsDestination = null;
    this.healthcheckAlerts = [];
    this.healthcheckAlertsSettings = null;
    this.isHealthcheckAlertsLoading = false;
    this.isHealthcheckAlertsFileLoading = false;
    this.healthcheckAlertsFileName = "";
  }

  @Mutation
  setHealthcheckAlertsDestination(destination: HealthcheckAlertDestination) {
    this.healthcheckAlertsDestination = destination;
  }

  @Mutation
  setHealthcheckAlertsFileLoading(payload: boolean) {
    this.isHealthcheckAlertsFileLoading = payload;
  }

  @Mutation
  setHealthcheckAlertFileName(fileName: string) {
    this.healthcheckAlertsFileName = fileName;
  }

  @Mutation
  setHealthcheckAlertsSettings(
    healthcheckAlertsSettings: HealthcheckAlertsSettingsModel
  ) {
    this.healthcheckAlertsSettings = HealthcheckAlertsSettingsModel.of(
      healthcheckAlertsSettings
    );
  }

  @Mutation
  setHealthcheckAlerts(healthcheckAlerts: Array<HealthcheckAlertModel>) {
    this.healthcheckAlerts = HealthcheckAlertModel.ofArray(healthcheckAlerts);
  }

  @Mutation
  setHealthcheckAlertsLoading(payload: boolean) {
    this.isHealthcheckAlertsLoading = payload;
  }

  @Action({ commit: "setHealthcheckAlertsSettings" })
  async uploadHealthcheckAlertsFile(payload: File) {
    this.context.commit("setHealthcheckAlertsFileLoading", true);

    const data = new FormData();

    data.append("file", payload);

    return axios
      .post(
        `/api/developer/healthcheck/${this.healthcheckAlertsPath}/upload-csv-file`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(({ data }) => {
        this.context.commit("setHealthcheckAlertFileName", data.fileName);

        return data;
      })
      .finally(() => {
        this.context.commit("setHealthcheckAlertsFileLoading", false);
      });
  }

  @Action({ commit: "setHealthcheckAlerts" })
  async getHealthcheckAlerts() {
    this.context.commit("setHealthcheckAlertsLoading", true);

    return axios
      .get(`/api/developer/healthcheck/${this.healthcheckAlertsPath}/get-all`)
      .then(({ data }: AxiosResponse<Array<HealthcheckAlertModel>>) => data)
      .finally(() => {
        this.context.commit("setHealthcheckAlertsLoading", false);
      });
  }
}
