export enum ReportMetricType {
  ARPU = "ARPU",
  ARPU_CUMULATIVE = "ARPU_CUMULATIVE",
  ARPPU = "ARPPU",
  ARPDAU_COHORT = "ARPDAU_COHORT",
  ARPDAU_DAILY = "ARPDAU_DAILY",
  AVERAGE_SUBSCRIPTION = "AVERAGE_SUBSCRIPTION",
  AVERAGE_PURCHASE = "AVERAGE_PURCHASE",
  INSTALLS_COUNT_ADJUST = "INSTALLS_COUNT_ADJUST",
  INSTALLS_COUNT_NETWORK = "INSTALLS_COUNT_NETWORK",
  CAC = "CAC",
  CONVERSION_TO_SUBSCRIPTION_IN_APP_PAYING_USER = "CONVERSION_TO_SUBSCRIPTION_IN_APP_PAYING_USER",
  CONVERSION_TO_SUBSCRIPTION_USER = "CONVERSION_TO_SUBSCRIPTION_USER",
  CONVERSION_TO_IN_APP_PAYING_USER = "CONVERSION_TO_IN_APP_PAYING_USER",
  CPI_ADJUST = "CPI_ADJUST",
  CPI_NETWORK = "CPI_NETWORK",
  CPS = "CPS",
  DAILY_ACTIVE_USERS_COUNT = "DAILY_ACTIVE_USERS_COUNT",
  IN_APP_PURCHASE_COUNT = "IN_APP_PURCHASE_COUNT",
  IN_APP_PAYING_USERS_COUNT = "IN_APP_PAYING_USERS_COUNT",
  PLAY_TIME = "PLAY_TIME",
  PLAY_TIME_CUMULATIVE = "PLAY_TIME_CUMULATIVE",
  RETURN_RATE = "RETURN_RATE",
  REVENUE_COHORT = "REVENUE_COHORT",
  REVENUE_DAILY = "REVENUE_DAILY",
  REVENUE_AD = "REVENUE_AD",
  REVENUE_SUBSCRIPTION = "REVENUE_SUBSCRIPTION",
  REVENUE_IN_APP = "REVENUE_IN_APP",
  REVENUE_SUBSCRIPTION_IN_APP = "REVENUE_SUBSCRIPTION_IN_APP",
  ROAS = "ROAS",
  ROAS_AD = "ROAS_AD",
  ROAS_SUBSCRIPTION = "ROAS_SUBSCRIPTION",
  ROAS_IN_APP = "ROAS_IN_APP",
  ROAS_SUBSCRIPTION_IN_APP = "ROAS_SUBSCRIPTION_IN_APP",
  SPEND = "SPEND",
  TIME_SPENT = "TIME_SPENT",
  TIME_SPENT_CUMULATIVE = "TIME_SPENT_CUMULATIVE",
  SUBSCRIPTION_COUNT = "SUBSCRIPTION_COUNT",
  SUBSCRIPTION_USERS_COUNT = "SUBSCRIPTION_USERS_COUNT",
  LIFE_TIME_VALUE = "LIFE_TIME_VALUE",
  LIFE_TIME_VALUE_PER_USER = "LIFE_TIME_VALUE_PER_USER",
  CPM = "CPM",
  CR = "CR",
  IR = "IR",
  CTR = "CTR",
  IMPRESSIONS = "IMPRESSIONS",
  CLICKS = "CLICKS",
}
