var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list',{staticClass:"pa-0",attrs:{"dense":"","flat":""}},[_vm._l((_vm.settingsMenuItems),function(menuItem){return [(menuItem.items)?_c('v-list-group',{attrs:{"no-action":"","prepend-icon":menuItem.icon},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$lang(("menu.settings." + (menuItem.name.toLowerCase()))))+" ")])]},proxy:true}],null,true),model:{value:(_vm.isActiveListGroup),callback:function ($$v) {_vm.isActiveListGroup=$$v},expression:"isActiveListGroup"}},_vm._l((menuItem.items),function(ref){
var name = ref.name;
var icon = ref.icon;
return _c('v-list-item',{key:name,staticClass:"pl-6",attrs:{"to":{
          name: name,
          params: { id: _vm.applicationId },
        },"link":"","dense":"","color":"blue"}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(_vm._s(icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$lang(("menu.settings." + (name.toLowerCase())))))])],1)}),1):_c('v-list-item',{attrs:{"color":"blue","to":{
        name: menuItem.name,
        params: {
          id: _vm.applicationId,
        },
      },"link":"","dense":""}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(_vm._s(menuItem.icon))])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$lang(("menu.settings." + (menuItem.name.toLowerCase()))))+" ")])],1)]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }