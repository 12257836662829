import { AxiosResponse } from "axios";
import { uniq } from "lodash";
import qs from "qs";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import {
  Dictionary,
  DictionaryModel,
  DictionaryType,
  ExternalTestDictionary,
  ExternalTestDictionaryModel,
  SegmentDictionary,
  SegmentDictionaryModel,
  TrackerDictionary,
  TrackerDictionaryModel,
  TrackerOrigin,
} from "@/shared/models";
import axios from "@/shared/plugins/axios";

interface DictionariesRequest {
  app?: string;
  dictionaryTypes: Array<DictionaryType>;
  origins?: Array<TrackerOrigin>;
  searchLimit?: number;
}

interface DictionaryRequest {
  app?: string;
  type: DictionaryType;
  search: string | null;
  parentValues?: Array<string>;
  isForLocal?: boolean;
  origins?: Array<TrackerOrigin>;
  searchLimit?: number;
}

type StateDictionaryType =
  | DictionaryType.GROUPED_COUNTRIES
  | DictionaryType.COUNTRIES
  | DictionaryType.SEGMENTS
  | DictionaryType.DEVICE_TYPES
  | DictionaryType.DEVICE_MODELS
  | DictionaryType.GROUPED_APP_VERSIONS
  | DictionaryType.APP_VERSIONS
  | DictionaryType.OS_VERSIONS
  | DictionaryType.LANGUAGES
  | DictionaryType.WATERFALLS_ID
  | DictionaryType.ROOT_AD_NETWORK_NAMES
  | DictionaryType.ALL_AD_NETWORK_NAMES
  | DictionaryType.PRICED_NETWORK_NAMES
  | DictionaryType.EVENTS
  | DictionaryType.REVENUES
  | DictionaryType.ATTRIBUTION_STATUS
  | DictionaryType.EVENT_NAMES
  | DictionaryType.EVENT_PARAM_KEYS
  | DictionaryType.ACTIVITY_KIND
  | DictionaryType.SOURCES
  | DictionaryType.AB_TEST_DAY_NUMBER
  | DictionaryType.EXTERNAL_TEST_PROPOSALS
  | DictionaryType.EVENTS_EXTENDED
  | DictionaryType.CURRENCIES;

const valueToName = (result: Record<any, string>, dictionary: Dictionary) => {
  return {
    ...result,
    [dictionary.value.toLowerCase()]: dictionary.text,
  };
};

@Module
export default class DictionaryStore extends VuexModule {
  [DictionaryType.GROUPED_COUNTRIES] = new DictionaryModel();
  [DictionaryType.COUNTRIES] = new DictionaryModel();
  [DictionaryType.SEGMENTS] = new SegmentDictionaryModel();
  [DictionaryType.DEVICE_TYPES] = new DictionaryModel();
  [DictionaryType.DEVICE_MODELS] = new DictionaryModel();
  [DictionaryType.GROUPED_APP_VERSIONS] = new DictionaryModel();
  [DictionaryType.APP_VERSIONS] = new DictionaryModel();
  [DictionaryType.OS_VERSIONS] = new DictionaryModel();
  [DictionaryType.LANGUAGES] = new DictionaryModel();
  [DictionaryType.WATERFALLS_ID] = new DictionaryModel();
  [DictionaryType.ROOT_AD_NETWORK_NAMES] = new DictionaryModel();
  [DictionaryType.ALL_AD_NETWORK_NAMES] = new DictionaryModel();
  [DictionaryType.PRICED_NETWORK_NAMES] = new DictionaryModel();
  [DictionaryType.EVENTS] = new DictionaryModel();
  [DictionaryType.REVENUES] = new DictionaryModel();
  [DictionaryType.ATTRIBUTION_STATUS] = new DictionaryModel();
  [DictionaryType.EVENT_NAMES] = new DictionaryModel();
  [DictionaryType.EVENT_PARAM_KEYS] = new DictionaryModel();
  [DictionaryType.ACTIVITY_KIND] = new DictionaryModel();
  [DictionaryType.SOURCES] = new TrackerDictionaryModel();
  [DictionaryType.AB_TEST_DAY_NUMBER] = new DictionaryModel();
  [DictionaryType.EXTERNAL_TEST_PROPOSALS] = new ExternalTestDictionaryModel();
  [DictionaryType.EVENTS_EXTENDED] = new DictionaryModel();
  [DictionaryType.CURRENCIES] = new DictionaryModel();

  dictionariesToLoad: Record<string, Array<string>> = {};
  loadingDictionary = false;
  isCacheReloading = false;
  isNetworksCacheReloading = false;
  isDictionaryUpdating = false;

  get countryNameByValue() {
    return this.countries.values.reduce(valueToName, {});
  }

  get eventNameByValue() {
    return this.eventNames.values.reduce(valueToName, {});
  }

  get activityKindNameByValue() {
    return this.activityKind.values.reduce(valueToName, {});
  }

  get languagesNameByValue() {
    return this.languages.values.reduce(valueToName, {});
  }

  get deviceTypesNameByValue() {
    return this.deviceTypes.values.reduce(valueToName, {});
  }

  get segmentsNameByValue() {
    return this.segments.values.reduce(valueToName, {});
  }

  @Mutation
  setLoadingDictionaries(payload: boolean) {
    this.loadingDictionary = payload;
  }

  @Mutation
  setDictionaryIsUpdating(payload: boolean) {
    this.isDictionaryUpdating = payload;
  }

  @Mutation
  clearDictionaries() {
    this[DictionaryType.GROUPED_COUNTRIES] = new DictionaryModel();
    this[DictionaryType.COUNTRIES] = new DictionaryModel();
    this[DictionaryType.SEGMENTS] = new SegmentDictionaryModel();
    this[DictionaryType.DEVICE_TYPES] = new DictionaryModel();
    this[DictionaryType.DEVICE_MODELS] = new DictionaryModel();
    this[DictionaryType.GROUPED_APP_VERSIONS] = new DictionaryModel();
    this[DictionaryType.APP_VERSIONS] = new DictionaryModel();
    this[DictionaryType.OS_VERSIONS] = new DictionaryModel();
    this[DictionaryType.LANGUAGES] = new DictionaryModel();
    this[DictionaryType.WATERFALLS_ID] = new DictionaryModel();
    this[DictionaryType.ROOT_AD_NETWORK_NAMES] = new DictionaryModel();
    this[DictionaryType.ALL_AD_NETWORK_NAMES] = new DictionaryModel();
    this[DictionaryType.PRICED_NETWORK_NAMES] = new DictionaryModel();
    this[DictionaryType.EVENTS] = new DictionaryModel();
    this[DictionaryType.REVENUES] = new DictionaryModel();
    this[DictionaryType.ATTRIBUTION_STATUS] = new DictionaryModel();
    this[DictionaryType.EVENT_NAMES] = new DictionaryModel();
    this[DictionaryType.EVENT_PARAM_KEYS] = new DictionaryModel();
    this[DictionaryType.ACTIVITY_KIND] = new DictionaryModel();
    this[DictionaryType.SOURCES] = new TrackerDictionaryModel();
    this[DictionaryType.AB_TEST_DAY_NUMBER] = new DictionaryModel();
    this[DictionaryType.EXTERNAL_TEST_PROPOSALS] =
      new ExternalTestDictionaryModel();
    this[DictionaryType.EVENTS_EXTENDED] = new DictionaryModel();
    this[DictionaryType.CURRENCIES] = new DictionaryModel();
    this.loadingDictionary = false;
    this.dictionariesToLoad = {};
  }

  @Mutation
  setDictionaries(
    payload: Record<
      DictionaryType,
      | DictionaryModel
      | SegmentDictionaryModel
      | TrackerDictionaryModel
      | ExternalTestDictionaryModel
    >
  ) {
    Object.entries(payload).forEach(
      ([type, { dictionaryValues, isFullResult }]) => {
        switch (type) {
          case DictionaryType.SEGMENTS:
            this[DictionaryType.SEGMENTS].update(
              SegmentDictionary.ofArray(
                dictionaryValues as Array<SegmentDictionary>
              ),
              isFullResult
            );
            break;
          case DictionaryType.SOURCES:
            this[DictionaryType.SOURCES].update(
              TrackerDictionary.ofArray(
                dictionaryValues as Array<TrackerDictionary>
              ),
              isFullResult
            );
            break;
          case DictionaryType.EXTERNAL_TEST_PROPOSALS:
            this[DictionaryType.EXTERNAL_TEST_PROPOSALS].update(
              ExternalTestDictionary.ofArray(
                dictionaryValues as Array<ExternalTestDictionary>
              ),
              isFullResult
            );
            break;
          default:
            (this[type as StateDictionaryType] as DictionaryModel).update(
              type === DictionaryType.EVENT_PARAM_KEYS
                ? [
                    ...this[DictionaryType.EVENT_PARAM_KEYS].values,
                    ...Dictionary.ofArray(
                      dictionaryValues as Array<Dictionary>
                    ),
                  ]
                : Dictionary.ofArray(dictionaryValues as Array<Dictionary>),
              isFullResult
            );
        }
      }
    );
  }

  @Mutation
  setCacheReloading(payload: boolean) {
    this.isCacheReloading = payload;
  }

  @Mutation
  setNetworksCacheReloading(payload: boolean) {
    this.isNetworksCacheReloading = payload;
  }

  @Mutation
  setUsedDictionaries(payload: Record<DictionaryType, DictionaryModel>) {
    Object.entries(payload).forEach(([type, { dictionaryValues }]) => {
      switch (type) {
        case DictionaryType.SEGMENTS:
          this[DictionaryType.SEGMENTS].updateUsedValues(
            SegmentDictionary.ofArray(
              dictionaryValues as Array<SegmentDictionary>
            )
          );
          break;
        case DictionaryType.SOURCES:
          this[DictionaryType.SOURCES].updateUsedValues(
            TrackerDictionary.ofArray(
              dictionaryValues as Array<TrackerDictionary>
            )
          );
          break;
        case DictionaryType.EXTERNAL_TEST_PROPOSALS:
          this[DictionaryType.EXTERNAL_TEST_PROPOSALS].updateUsedValues(
            ExternalTestDictionary.ofArray(
              dictionaryValues as Array<ExternalTestDictionary>
            )
          );
          break;
        default:
          (
            this[type as StateDictionaryType] as DictionaryModel
          ).updateUsedValues(
            Dictionary.ofArray(dictionaryValues as Array<Dictionary>)
          );
      }
    });
  }

  @Mutation
  setDictionariesToLoad(
    dictionaries: Record<StateDictionaryType, Array<string>>
  ) {
    if (!Object.keys(dictionaries).length) {
      this.dictionariesToLoad = {};
    }

    Object.entries(dictionaries).forEach(([dictionaryType, values]) => {
      if (this.dictionariesToLoad[dictionaryType]?.length) {
        this.dictionariesToLoad[dictionaryType] = uniq(
          this.dictionariesToLoad[dictionaryType].concat(values)
        );
      } else {
        this.dictionariesToLoad[dictionaryType] = uniq(values);
      }
    });
  }

  @Action
  async loadDictionaries({
    app,
    dictionaryTypes,
    origins,
    searchLimit,
  }: DictionariesRequest) {
    const url = app
      ? `/api/v2/dictionaries/apps/${app}`
      : "/api/v2/dictionaries/organization/X-FLOW";

    this.context.commit("setLoadingDictionaries", true);

    return axios
      .get(url, {
        params: {
          types: dictionaryTypes.join(","),
          ...(searchLimit ? { searchLimit } : {}),
          ...(origins?.length ? { origins: origins.join(",") } : {}),
        },
      })
      .then(
        ({
          data,
        }: AxiosResponse<Record<StateDictionaryType, DictionaryModel>>) => {
          this.context.commit("setDictionaries", data);

          [
            [DictionaryType.GROUPED_COUNTRIES, DictionaryType.COUNTRIES],
            [DictionaryType.GROUPED_APP_VERSIONS, DictionaryType.APP_VERSIONS],
          ].forEach(([groupedDictionaryType, dictionaryType]) => {
            if (
              !data[groupedDictionaryType as StateDictionaryType] ||
              this[dictionaryType as StateDictionaryType].isFullResult
            ) {
              return;
            }

            const dictionaryValues: Array<Dictionary> = this[
              groupedDictionaryType as
                | DictionaryType.GROUPED_COUNTRIES
                | DictionaryType.GROUPED_APP_VERSIONS
            ].values.flatMap(
              ({ childValues }: Dictionary) => childValues || []
            );

            if (dictionaryValues.length) {
              this.context.commit("setUsedDictionaries", {
                [dictionaryType]: { dictionaryValues },
              });
            }
          });
        }
      )
      .finally(() => {
        this.context.commit("setLoadingDictionaries", false);
      });
  }

  @Action
  loadDictionary({
    app,
    type,
    search,
    parentValues,
    isForLocal,
    origins,
    searchLimit,
  }: DictionaryRequest) {
    if (!isForLocal) {
      this.context.commit("setDictionaryIsUpdating", true);
    }

    const url = app
      ? `/api/v2/dictionaries/apps/${app}/${type}`
      : "/api/v2/dictionaries/organization/X-FLOW";

    return axios
      .get(url, {
        params: {
          ...(app ? {} : { types: type }),
          ...(search ? { nameValueSubstring: encodeURIComponent(search) } : {}),
          ...(parentValues?.length
            ? {
                parentValues: parentValues
                  .map((item: string) => encodeURIComponent(item))
                  .join(","),
              }
            : {}),
          ...(origins?.length ? { origins: origins.join(",") } : {}),
          ...(searchLimit ? { searchLimit } : {}),
        },
        paramsSerializer: (params) => qs.stringify(params, { encode: false }),
      })
      .then(
        ({ data }: AxiosResponse<Record<DictionaryType, DictionaryModel>>) => {
          if (isForLocal) {
            return data[type];
          }

          this.context.commit("setDictionaries", data);
        }
      )
      .finally(() => {
        if (!isForLocal) {
          this.context.commit("setDictionaryIsUpdating", false);
        }
      });
  }

  @Action
  async loadUsedDictionaries({
    app,
    values,
  }: {
    app: string;
    values: Record<DictionaryType, Array<string>>;
  }) {
    if (Object.keys(values).length) {
      this.context.commit("setDictionariesToLoad", values);
    }

    if (!Object.keys(this.dictionariesToLoad).length) {
      return;
    }

    this.context.commit("setLoadingDictionaries", true);

    return axios
      .post(`/api/v2/dictionaries/apps/${app}/convert`, this.dictionariesToLoad)
      .then(
        ({ data }: AxiosResponse<Record<DictionaryType, DictionaryModel>>) => {
          this.context.commit("setUsedDictionaries", data);
          this.context.commit("setDictionariesToLoad", {});
        }
      )
      .finally(() => {
        this.context.commit("setLoadingDictionaries", false);
      });
  }

  @Action
  async reloadDictionariesCache(all = false) {
    this.context.commit("setCacheReloading", true);

    await axios
      .post(
        `/api/dictionaries/developer/cache/reload/bigQueryDictionaries${
          all ? "/all" : ""
        }`
      )
      .then(() => {
        this.context.commit("addNotification", {
          message: {
            key: "appAdmin.dictionaries.cacheReloadSuccess",
          },
        });
      })
      .finally(() => {
        this.context.commit("setCacheReloading", false);
      });
  }

  @Action
  async reloadNetworksCache() {
    this.context.commit("setNetworksCacheReloading", true);

    await axios
      .post("/api/ngac/sadmin/networks/cache/clear")
      .then(() => {
        this.context.commit("addNotification", {
          message: {
            key: "appAdmin.dictionaries.networksCacheReloadSuccess",
          },
        });
      })
      .finally(() => {
        this.context.commit("setNetworksCacheReloading", false);
      });
  }
}
