




















import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import DatesPicker from "@/shared/components/pickers/DatesPicker.vue";
import { DatesFilterModel, FilterPreviewId } from "@/shared/models";

class DatesLocalFilterModel {
  values: Array<string>;
  valid: boolean;

  constructor(values: Array<string> = [], valid: boolean) {
    this.values = values;
    this.valid = valid;
  }

  static of(model: DatesFilterModel): DatesLocalFilterModel {
    return new DatesLocalFilterModel([model.from, model.to], model.valid);
  }
}

@Component({
  components: {
    DatesPicker,
  },
})
export default class DatesFilter extends Vue {
  @Prop() applicationId!: string;
  @Prop() value!: DatesFilterModel;
  @Prop() label!: string;
  @Prop({ default: false }) readonly!: boolean;
  @Prop({ default: true }) validate!: boolean;
  @Prop() presetStartingDate?: string;
  @Prop() max?: string;
  @Prop() filterPreviewId?: FilterPreviewId;
  @Prop() clearable?: boolean;

  localFilter: DatesLocalFilterModel = DatesLocalFilterModel.of(this.value);

  get hasDefaultSlot() {
    return !!this.$slots.default;
  }

  get valid(): boolean {
    return (
      this.localFilter.values.length === 2 &&
      this.localFilter.values.every((value) => !!value) &&
      this.localFilter.values.every((value) => value !== "Invalid date")
    );
  }

  @Watch("value", { immediate: true })
  private watchValue(value: DatesFilterModel) {
    this.localFilter = DatesLocalFilterModel.of(value);
  }

  @Watch("localFilter", { deep: true })
  private watchLocalFilter(localFilter: DatesLocalFilterModel) {
    const filter = new DatesFilterModel(
      this.value.id,
      this.value.required,
      this.value.filled,
      this.localFilter.valid,
      localFilter.values[0],
      localFilter.values[1]
    );

    if (!this.readonly) {
      this.$emit(
        "input",
        Object.assign(this.value, filter, {
          valid: this.validate ? this.localFilter.valid : this.value.valid,
        })
      );
    }
  }

  @Watch("valid", { immediate: true })
  private watchValid(valid: boolean) {
    this.localFilter.valid = this.validate ? valid : this.value.valid;
  }
}
