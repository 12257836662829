export enum ApplicationWorkflowStatus {
  COMPLETED = "COMPLETED",
  ERROR = "ERROR",
  IN_PROGRESS = "IN_PROGRESS",
}

export class ApplicationWorkflowModel {
  constructor(
    public application: string,
    public workflow: Array<ApplicationWorkflowGroup>,
    public executionId: number,
    public workflowTemplate: ApplicationWorkflow
  ) {}

  get jobs(): Array<ApplicationWorkflowStep> {
    return this.workflow.flatMap(({ steps }) => steps);
  }

  get jobStatuses(): Record<ApplicationWorkflowStatus, number> {
    return this.jobs.reduce(
      (result, { details }) => {
        if (details) {
          result[details.status]++;
        }

        return result;
      },
      {
        [ApplicationWorkflowStatus.COMPLETED]: 0,
        [ApplicationWorkflowStatus.ERROR]: 0,
        [ApplicationWorkflowStatus.IN_PROGRESS]: 0,
      }
    );
  }

  get status(): ApplicationWorkflowStatus {
    if (this.jobStatuses[ApplicationWorkflowStatus.ERROR]) {
      return ApplicationWorkflowStatus.ERROR;
    }

    if (
      this.jobStatuses[ApplicationWorkflowStatus.COMPLETED] === this.jobs.length
    ) {
      return ApplicationWorkflowStatus.COMPLETED;
    }

    return ApplicationWorkflowStatus.IN_PROGRESS;
  }

  get completeness(): number {
    return Math.round(
      (this.jobStatuses[ApplicationWorkflowStatus.COMPLETED] * 100) /
        this.jobs.length
    );
  }

  static of({
    application,
    workflow,
    executionId,
    workflowTemplate,
  }: ApplicationWorkflowModel) {
    return new ApplicationWorkflowModel(
      application,
      workflow,
      executionId,
      workflowTemplate
    );
  }

  static ofArray(models: Array<ApplicationWorkflowModel>) {
    return models.map((model: ApplicationWorkflowModel) => this.of(model));
  }
}

export interface ApplicationWorkflowGroup {
  ordinal: number;
  steps: Array<ApplicationWorkflowStep>;
}

export interface ApplicationWorkflowStep {
  jobName: string;
  details: {
    start: string;
    end: string | null;
    msg: string | null;
    status: ApplicationWorkflowStatus;
  } | null;
}

export enum ApplicationWorkflow {
  CREATE_APPLICATION = "CREATE_APPLICATION",
  SET_APPLICATION_TO_ARCHIVED = "SET_APPLICATION_TO_ARCHIVED",
}

export class ApplicationWorkflowRequestModel {
  constructor(
    public applicationId = "",
    public applicationType: "ios" | "android" = "ios",
    public applicationName = "",
    public applicationPackageNames = "",
    public gcpDefaultConfig = "",
    public gcpDefaultProject = "",
    public gcpDefaultLocation = "",
    public abTestsAwsAccessKey = "",
    public abTestsAwsSecret = "",
    public abTestsAwsRegion = "",
    public adjustApplicationToken = "",
    public adjustAuthToken = "",
    public adjustGcpSourceBucket = "",
    public adjustGcpSourceRegion = "",
    public workflowTemplate = ApplicationWorkflow.CREATE_APPLICATION
  ) {}

  get keys(): Array<string> {
    return Object.keys(this).filter((key) => key !== "workflowTemplate");
  }

  static of({
    applicationId,
    applicationType,
    applicationName,
    applicationPackageNames,
    gcpDefaultConfig,
    gcpDefaultProject,
    gcpDefaultLocation,
    abTestsAwsAccessKey,
    abTestsAwsSecret,
    abTestsAwsRegion,
    adjustApplicationToken,
    adjustAuthToken,
    adjustGcpSourceBucket,
    adjustGcpSourceRegion,
    workflowTemplate,
  }: ApplicationWorkflowRequestModel): ApplicationWorkflowRequestModel {
    return new ApplicationWorkflowRequestModel(
      applicationId,
      applicationType,
      applicationName,
      applicationPackageNames,
      gcpDefaultConfig,
      gcpDefaultProject,
      gcpDefaultLocation,
      abTestsAwsAccessKey,
      abTestsAwsSecret,
      abTestsAwsRegion,
      adjustApplicationToken,
      adjustAuthToken,
      adjustGcpSourceBucket,
      adjustGcpSourceRegion,
      workflowTemplate
    );
  }
}
