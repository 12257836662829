import {
  AdditionalMenuLocationType,
  FilterLocationType,
  ThemeType,
} from "./AccountSettingType";
import SpecificFiltersBehaviorModel from "./SpecificFiltersBehaviorModel";

export default class AccountSettingsModel {
  constructor(
    public theme = ThemeType.LIGHT,
    public filterLocation = FilterLocationType.HORIZONTAL,
    public additionalMenuLocation = AdditionalMenuLocationType.COLLAPSED,
    public defaultApplication: string | null = "",
    public favoriteApplications: Array<string> = [],
    public defaultFiltersBehavior: Array<string> = [],
    public specificFiltersBehavior: Array<SpecificFiltersBehaviorModel> = [],
    public pinnedSearchItems: Array<string> = []
  ) {}

  get isReportFilterHorizontal(): boolean {
    return this.filterLocation === FilterLocationType.HORIZONTAL;
  }

  get isAdditionalMenuExpanded(): boolean {
    return this.additionalMenuLocation === AdditionalMenuLocationType.EXPANDED;
  }

  static of(model: AccountSettingsModel): AccountSettingsModel {
    return new AccountSettingsModel(
      model.theme,
      model.filterLocation,
      model.additionalMenuLocation,
      model.defaultApplication,
      model.favoriteApplications.map((item) => item),
      model.defaultFiltersBehavior.map((item) => item),
      SpecificFiltersBehaviorModel.ofArray(model.specificFiltersBehavior),
      model.pinnedSearchItems?.map((item) => item)
    );
  }
}
