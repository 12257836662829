import { CancelTokenSource } from "axios";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

@Module
export default class CancelRequestStore extends VuexModule {
  cancelRequests: Array<CancelTokenSource> = [];

  @Mutation
  setCancelRequest(payload: CancelTokenSource) {
    this.cancelRequests.push(payload);
  }

  @Mutation
  clearCancelRequests() {
    this.cancelRequests = [];
  }

  @Action
  cancelPendingRequests() {
    this.cancelRequests.forEach((controller) => {
      if (controller.cancel) {
        controller.cancel();
      }
    });

    this.context.commit("clearCancelRequests");
  }
}
