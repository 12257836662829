import {
  BaseGradientFlags,
  MultiAppsReportFilter,
  ReportFilter,
  ReportResultItem,
} from "./Report";
import { AggregationPeriod, GroupByFilter } from "./GroupByFilter";
import { ReportHeader, ReportHeaderUtil } from "./ReportHeader";
import { ArpdauHeaders } from "./ArpdauReport";
import { ReportType } from "./ReportType";
import {
  DatesFilterModel,
  FilterId,
  recordToFilterModel,
  FilterModel,
  Application,
  DefaultValues,
} from "@/shared/models";
import { LangService } from "@/shared/types/LangType";
import DateUtil from "@/shared/utils/DateUtil";
import { ReportDataType } from "./ReportVisualization";
import { ReportItemRowObject } from "./ReportItemRowObject";

export class SpendGradientFlags extends BaseGradientFlags {
  constructor(report: ReportFilter) {
    super();

    const dau = ReportHeaderUtil.getActiveUsersName(
      report.groupByFilter.aggregationPeriod
    );
    const isSpendReport = report.reportId === ReportType.SPEND;

    this.flags = {
      [`${ReportResultItem.PREFIX}adjustInstalls`]: true,
      [`${ReportResultItem.PREFIX}${
        isSpendReport ? "installs" : "storeInstalls"
      }`]: true,
      [`${ReportResultItem.PREFIX}paidInstalls`]: true,
      [`${ReportResultItem.PREFIX}organic`]: true,
      [`${ReportResultItem.PREFIX}percentOrganic`]: true,
      [`${ReportResultItem.PREFIX}cpi`]: true,
      [`${ReportResultItem.PREFIX}ecpiAdjust`]: true,
      [`${ReportResultItem.PREFIX}spend`]: true,
      ...(isSpendReport
        ? {}
        : {
            [`${ReportResultItem.PREFIX}${dau}`]: true,
            [`${ReportResultItem.PREFIX}revenue`]: true,
            [`${ReportResultItem.PREFIX}arpdau`]: true,
          }),
    };
  }

  getByKey(key: string | number): boolean {
    const prefix = `${ReportResultItem.PREFIX}`;
    if (key === `${prefix}dau`) {
      return (
        this.flags[`${prefix}dau`] ||
        this.flags[`${prefix}wau`] ||
        this.flags[`${prefix}mau`]
      );
    }
    return this.flags[key];
  }
}

export class SpendFilter extends MultiAppsReportFilter {
  constructor(
    reportId: ReportType,
    availableApps: Array<Application>,
    platforms?: Array<string>,
    apps?: Array<string>,
    includedApps?: boolean,
    filter?: Array<FilterModel>,
    date: DatesFilterModel = DefaultValues.initDatesFilterModel(
      FilterId.EVENTS_DATE,
      30,
      DateUtil.yesterday()
    ),
    groupByFilter: GroupByFilter = new GroupByFilter(AggregationPeriod.DAY)
  ) {
    super(
      reportId,
      availableApps,
      platforms,
      apps,
      includedApps,
      filter,
      date,
      groupByFilter
    );
  }

  static of(
    reportId: ReportType,
    availableApps: Array<Application>,
    requestQuery?: Record<string, any>
  ): SpendFilter {
    return requestQuery
      ? new SpendFilter(
          reportId,
          availableApps,
          requestQuery.platforms as Array<string>,
          requestQuery.apps as Array<string>,
          typeof requestQuery.includedApps === "string"
            ? requestQuery.includedApps === "true"
            : requestQuery.includedApps,
          requestQuery.filter?.map((it: any) => recordToFilterModel(it)) || [],
          DatesFilterModel.ofRecord(FilterId.EVENTS_DATE, requestQuery.date),
          GroupByFilter.of(requestQuery.groupByFilter)
        )
      : new SpendFilter(reportId, availableApps);
  }
}

export class SpendHeaders {
  static readonly PRECISION = 2;
  static init(
    lang: LangService,
    report: SpendFilter,
    reportDataType: ReportDataType
  ): Array<ReportHeader> {
    return [
      ...(![ReportDataType.TOTAL, ReportDataType.SUB_TOTAL].includes(
        reportDataType
      )
        ? [
            ReportHeaderUtil.createDate(
              lang,
              report.groupByFilter.aggregationPeriod
            ),
          ]
        : []),
      ...(reportDataType !== ReportDataType.TOTAL &&
      report.groupByFilter.isNotEmptyGroupBy
        ? ReportHeaderUtil.createGroupBy(lang, report.groupByFilter, 200)
        : []),
      {
        text: lang("components.baseTable.adjustInstalls"),
        align: "end",
        value: `${ReportResultItem.PREFIX}adjustInstalls`,
        hasGradient: true,
        fractionDigits: 0,
      },
      {
        text: lang("components.baseTable.installs"),
        align: "end",
        value: `${ReportResultItem.PREFIX}installs`,
        hasGradient: true,
        fractionDigits: 0,
      },
      {
        text: lang("components.baseTable.paidInstalls"),
        align: "end",
        value: `${ReportResultItem.PREFIX}paidInstalls`,
        hasGradient: true,
        fractionDigits: 0,
      },
      {
        text: lang("components.baseTable.organic"),
        align: "end",
        value: `${ReportResultItem.PREFIX}organic`,
        hasGradient: true,
        fractionDigits: 0,
      },
      {
        text: lang("components.baseTable.percentOrganic"),
        align: "end",
        value: `${ReportResultItem.PREFIX}percentOrganic`,
        hasGradient: true,
        fractionDigits: SpendHeaders.PRECISION,
        class: "text-no-wrap",
      },
      {
        text: lang("components.baseTable.cpi"),
        align: "end",
        value: `${ReportResultItem.PREFIX}cpi`,
        hasGradient: true,
        fractionDigits: SpendHeaders.PRECISION,
        reverseGradient: true,
      },
      {
        text: lang("components.baseTable.ecpiAdjust"),
        align: "end",
        value: `${ReportResultItem.PREFIX}ecpiAdjust`,
        hasGradient: true,
        fractionDigits: SpendHeaders.PRECISION,
        reverseGradient: true,
      },
      {
        text: lang("components.baseTable.spend"),
        align: "end",
        value: `${ReportResultItem.PREFIX}spend`,
        hasGradient: true,
        fractionDigits: 0,
      },
      {
        text: lang("components.baseTable.impressions"),
        align: "end",
        value: `${ReportResultItem.PREFIX}impressions`,
        hasGradient: false,
        fractionDigits: 0,
      },
      {
        text: lang("components.baseTable.clicks"),
        align: "end",
        value: `${ReportResultItem.PREFIX}clicks`,
        hasGradient: false,
        fractionDigits: 0,
      },
      {
        text: lang("components.baseTable.ctr"),
        align: "end",
        value: `${ReportResultItem.PREFIX}ctr`,
        hasGradient: false,
        fractionDigits: SpendHeaders.PRECISION,
        class: "text-no-wrap",
      },
      {
        text: lang("components.baseTable.ir"),
        align: "end",
        value: `${ReportResultItem.PREFIX}ir`,
        hasGradient: false,
        fractionDigits: SpendHeaders.PRECISION,
        class: "text-no-wrap",
      },
      {
        text: lang("components.baseTable.cr"),
        align: "end",
        value: `${ReportResultItem.PREFIX}cr`,
        hasGradient: false,
        fractionDigits: SpendHeaders.PRECISION,
        class: "text-no-wrap",
      },
      {
        text: lang("components.baseTable.cpm"),
        align: "end",
        value: `${ReportResultItem.PREFIX}cpm`,
        hasGradient: false,
        fractionDigits: SpendHeaders.PRECISION,
      },
    ];
  }
}

export class SpendHyperHeaders {
  static init(
    lang: LangService,
    report: SpendFilter,
    reportDataType: ReportDataType
  ): Array<ReportHeader> {
    return [
      ...(![ReportDataType.TOTAL, ReportDataType.SUB_TOTAL].includes(
        reportDataType
      )
        ? [
            ReportHeaderUtil.createDate(
              lang,
              report.groupByFilter.aggregationPeriod
            ),
          ]
        : []),
      ...(reportDataType !== ReportDataType.TOTAL &&
      report.groupByFilter.isNotEmptyGroupBy
        ? ReportHeaderUtil.createGroupBy(lang, report.groupByFilter, 200)
        : []),
      {
        text: lang("components.baseTable.storeInstalls"),
        align: "end",
        value: `${ReportResultItem.PREFIX}storeInstalls`,
        hasGradient: true,
        fractionDigits: 0,
      },
      {
        text: lang("components.baseTable.adjustInstalls"),
        align: "end",
        value: `${ReportResultItem.PREFIX}adjustInstalls`,
        hasGradient: true,
        fractionDigits: 0,
      },
      {
        text: lang("components.baseTable.paidInstalls"),
        align: "end",
        value: `${ReportResultItem.PREFIX}paidInstalls`,
        hasGradient: true,
        fractionDigits: 0,
      },
      {
        text: lang("components.baseTable.organic"),
        align: "end",
        value: `${ReportResultItem.PREFIX}organic`,
        hasGradient: true,
        fractionDigits: 0,
      },
      {
        text: lang("components.baseTable.percentOrganic"),
        align: "end",
        value: `${ReportResultItem.PREFIX}percentOrganic`,
        hasGradient: true,
        fractionDigits: SpendHeaders.PRECISION,
        class: "text-no-wrap",
      },
      {
        text: lang("components.baseTable.cpi"),
        align: "end",
        value: `${ReportResultItem.PREFIX}cpi`,
        hasGradient: true,
        fractionDigits: SpendHeaders.PRECISION,
        reverseGradient: true,
      },
      {
        text: lang("components.baseTable.ecpiAdjust"),
        align: "end",
        value: `${ReportResultItem.PREFIX}ecpiAdjust`,
        hasGradient: true,
        fractionDigits: SpendHeaders.PRECISION,
        reverseGradient: true,
      },
      {
        text: lang("components.baseTable.spend"),
        align: "end",
        value: `${ReportResultItem.PREFIX}spend`,
        hasGradient: true,
        fractionDigits: 0,
      },
      {
        text: lang(
          `components.baseTable.${ReportHeaderUtil.getActiveUsersName(
            report.groupByFilter.aggregationPeriod
          )}`
        ),
        align: "end",
        value: `${ReportResultItem.PREFIX}dau`,
        hasGradient: true,
        fractionDigits: 0,
      },
      {
        text: lang("components.baseTable.revenue"),
        align: "end",
        value: `${ReportResultItem.PREFIX}revenue`,
        hasGradient: true,
        fractionDigits: 0,
      },
      {
        text: lang("components.baseTable.arpdau"),
        align: "end",
        value: `${ReportResultItem.PREFIX}arpdau`,
        hasGradient: true,
        fractionDigits: ArpdauHeaders.PRECISION,
      },
    ];
  }
}

export class SpendMiniHeaders {
  static init(lang: LangService, report: SpendFilter): Array<ReportHeader> {
    return [
      ReportHeaderUtil.createDate(lang, report.groupByFilter.aggregationPeriod),
      ...(report.groupByFilter.isNotEmptyGroupBy
        ? ReportHeaderUtil.createGroupBy(lang, report.groupByFilter, 200)
        : []),
      {
        text: lang("components.baseTable.spend"),
        align: "end",
        value: `${ReportResultItem.PREFIX}spend`,
        hasGradient: false,
        fractionDigits: 0,
      },
    ];
  }
}

export class SpendMiniResultItem extends ReportResultItem {
  constructor(row: ReportItemRowObject, filter: ReportFilter) {
    super();
    const { groupByFilter } = filter;
    this.date = row.getByHeader("date");
    this.setFormattedDate(
      this.date,
      filter.date.from,
      filter.date.to,
      groupByFilter.aggregationPeriod
    );
    this.setGroupByValue(groupByFilter, row);
    this.data["spend"] = Math.round(
      Number.parseFloat(row.getByHeader("spend") ?? 0)
    );
  }
}

export class SpendResultItem extends SpendMiniResultItem {
  constructor(row: ReportItemRowObject, filter: ReportFilter) {
    super(row, filter);
    this.data["adjustInstalls"] = Math.round(
      Number.parseFloat(row.getByHeader("adjustInstalls"))
    );
    this.data["installs"] = Math.round(
      Number.parseFloat(row.getByHeader("installs"))
    );
    this.data["paidInstalls"] = Math.round(
      Number.parseFloat(row.getByHeader("paidInstalls") ?? 0)
    );
    this.data["organic"] = Math.round(
      Number.parseFloat(row.getByHeader("organic"))
    );
    this.data["percentOrganic"] = super.parseFloat(
      row.getByHeader("percentOrganic"),
      SpendHeaders.PRECISION
    );
    this.data["cpi"] = super.parseFloat(
      row.getByHeader("cpi"),
      SpendHeaders.PRECISION
    );
    this.data["ecpiAdjust"] = super.parseFloat(
      row.getByHeader("ecpiAdjust"),
      SpendHeaders.PRECISION
    );
    this.data["impressions"] = Math.round(
      Number.parseFloat(row.getByHeader("impressions"))
    );
    this.data["clicks"] = Math.round(
      Number.parseFloat(row.getByHeader("clicks"))
    );
    this.data["ctr"] = super.parseFloat(
      row.getByHeader("ctr"),
      SpendHeaders.PRECISION
    );
    this.data["ir"] = super.parseFloat(
      row.getByHeader("ir"),
      SpendHeaders.PRECISION
    );
    this.data["cr"] = super.parseFloat(
      row.getByHeader("cr"),
      SpendHeaders.PRECISION
    );
    this.data["cpm"] = super.parseFloat(
      row.getByHeader("cpm"),
      SpendHeaders.PRECISION
    );
  }
}

export class SpendHyperResultItem extends SpendMiniResultItem {
  constructor(row: ReportItemRowObject, filter: ReportFilter) {
    super(row, filter);
    this.data["storeInstalls"] = Math.round(
      Number.parseFloat(row.getByHeader("storeInstalls"))
    );
    this.data["adjustInstalls"] = Math.round(
      Number.parseFloat(row.getByHeader("adjustInstalls"))
    );
    this.data["paidInstalls"] = Math.round(
      Number.parseFloat(row.getByHeader("paidInstalls") ?? 0)
    );
    this.data["cpi"] = super.parseFloat(
      row.getByHeader("cpi"),
      SpendHeaders.PRECISION
    );
    this.data["ecpiAdjust"] = super.parseFloat(
      row.getByHeader("ecpiAdjust"),
      SpendHeaders.PRECISION
    );
    this.data["organic"] = Math.round(
      Number.parseFloat(row.getByHeader("organic"))
    );
    this.data["percentOrganic"] = super.parseFloat(
      row.getByHeader("percentOrganic"),
      SpendHeaders.PRECISION
    );

    this.data["dau"] = Math.round(Number.parseFloat(row.getByHeader("dau")));
    this.data["revenue"] = Math.round(
      Number.parseFloat(row.getByHeader("revenue"))
    );
    this.data["arpdau"] = super.parseFloat(
      row.getByHeader("arpdau"),
      ArpdauHeaders.PRECISION
    );
  }
}
