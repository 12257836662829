

















import { Component, Vue, Prop } from "vue-property-decorator";

import Chart from "@/chart/components/Chart.vue";
import { DashboardChartModel } from "@/dashboard/models/DashboardChartModel";
import { TemplateModel } from "@/templates/models/TemplateModel";

@Component({
  components: { Chart },
})
export default class DashboardChart extends Vue {
  @Prop() chart!: DashboardChartModel;

  get title(): string {
    return `${this.chart.templateName} (${this.chart.name})`;
  }

  get dashboardCharts() {
    return this.$store.state.dashboardStore.dashboardCharts;
  }

  get dashboard() {
    return this.$store.state.dashboardStore.dashboard;
  }

  get isLoading() {
    return this.chart.id && this.dashboardCharts[this.chart.id] === null;
  }

  get chartData() {
    if (!this.chart.id || this.isLoading) {
      return [];
    }

    return this.dashboardCharts[this.chart.id];
  }

  // TODO: temp decision, after update DashboardChartModel will get dayLimit from reportFilter props
  get dayLimit(): number | undefined {
    return this.$store.state.templateStore.reportTemplates.find(
      ({ id }: TemplateModel) => id === this.chart.templateId
    )?.reportFilter.dayLimit;
  }
}
