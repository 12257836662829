export enum ApplicationConfigKeyType {
  NUMBER = "NUMBER",
  STRING = "STRING",
  DATE = "DATE",
  BOOLEAN = "BOOLEAN",
  NUMBER_ARRAY = "NUMBER_ARRAY",
  STRING_ARRAY = "STRING_ARRAY",
}

export interface ApplicationFeatureMissedKey {
  key: string;
  type: ApplicationConfigKeyType;
  values: Array<string>;
}

export interface ApplicationFeatureKey {
  key: string;
  value: Array<string>;
}

export class ApplicationFeatureModel {
  children: Array<ApplicationFeatureModel>;

  constructor(
    public feature: string,
    children: Array<ApplicationFeatureModel> = [],
    public parentFeatures: Array<string> = [],
    public missedKeys: Array<ApplicationFeatureMissedKey> = [],
    public disabled: boolean
  ) {
    this.children = children.map(
      (appFeature) =>
        new ApplicationFeatureModel(
          appFeature.feature,
          appFeature.children,
          [this.feature].concat(this.parentFeatures),
          appFeature.missedKeys,
          appFeature.disabled
        )
    );
  }

  get deepChildren(): Array<string> {
    return this.children.flatMap(({ feature, deepChildren }) =>
      [feature].concat(deepChildren)
    );
  }
}

export interface ApplicationFeaturesResponse {
  featuresIncluded: Array<string>;
  featuresExcluded: Array<string>;
}

export interface ApplicationFeaturesRequest
  extends ApplicationFeaturesResponse {
  appId: string;
}
