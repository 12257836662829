import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import {
  AbTestChartOptions,
  AbTestSeriesType,
} from "@/chart/models/ChartModel";
import { ChartRenderer } from "@/chart/models/ChartRenderer";
import { IXYAxis } from "@amcharts/amcharts5/.internal/charts/xy/series/XYSeries";
import { AbTestPeriodValidationStatus } from "@/ab-tests/models/AbTestConfigurationModel";

export default class AbTestChartRenderer extends ChartRenderer {
  chart: am5xy.XYChart;
  xAxis?: am5xy.CategoryAxis<am5xy.AxisRendererX>;
  yAxis?: am5xy.ValueAxis<am5xy.AxisRendererX>;

  constructor(
    public element: HTMLElement,
    public data: Array<Record<string, any>>,
    public options: AbTestChartOptions,
    public isDarkMode: boolean,
    public periodValidationStatus?: AbTestPeriodValidationStatus
  ) {
    super(element, isDarkMode);

    this.chart = this.root.container.children.push(
      am5xy.XYChart.new(this.root, {
        layout: this.root.verticalLayout,
        ...(options.legend ? { paddingTop: 0 } : {}),
      })
    );
    this.chart.get("colors")?.set("step", 5);
    this.init();
  }

  init() {
    this.addXAxis();
    this.addYAxis();
    this.addSeries();
    this.addCursor();
    super.init();
  }

  addXAxis(): void {
    this.xAxis = this.chart.xAxes.push(
      am5xy.CategoryAxis.new(this.root, {
        categoryField: this.options.xAxis.category,
        renderer: am5xy.AxisRendererX.new(this.root, {
          minGridDistance: 20,
        }) as any,
        tooltip: am5.Tooltip.new(this.root, {}),
      }) as any
    );

    if (!this.xAxis) {
      return;
    }

    this.xAxis.get("renderer").grid.template.setAll({
      location: 0.5,
    });
    this.xAxis.get("renderer").labels.template.setAll({
      multiLocation: 0.5,
    });
    this.xAxis.children.push(
      am5.Label.new(this.root, {
        text: this.options.xAxis.title,
        x: am5.p50,
        centerX: am5.p50,
      })
    );
    this.xAxis.data.setAll(this.data);
    this.addXAxisRange();
  }

  addYAxis() {
    this.yAxis = this.chart.yAxes.push(
      am5xy.ValueAxis.new(this.root, {
        renderer: am5xy.AxisRendererY.new(this.root, {}),
      }) as any
    );

    this.yAxis?.children.unshift(
      am5.Label.new(this.root, {
        rotation: -90,
        text: this.options.yAxis.title,
        y: am5.p50,
        centerX: am5.p50,
      })
    );
  }

  addSeries() {
    this.options.series.forEach((series) => {
      const isRangeAxis = series.type === AbTestSeriesType.CONFIDENCE_INTERVAL;
      const localSeries: am5xy.LineSeries = this.chart.series.push(
        am5xy.LineSeries.new(this.root, {
          name: series.name,
          xAxis: this.xAxis as IXYAxis,
          yAxis: this.yAxis as IXYAxis,
          ...(isRangeAxis ? { openValueYField: series.yOpen } : {}),
          valueYField: series.y,
          categoryXField: series.x,
          ...(isRangeAxis
            ? {}
            : {
                tooltip: am5.Tooltip.new(this.root, {
                  pointerOrientation: "horizontal",
                  labelText: series.tooltipText,
                }),
              }),
        })
      );

      localSeries.strokes.template.setAll({
        strokeWidth: 1.5,
      });

      if (isRangeAxis) {
        localSeries.fills.template.setAll({
          fillOpacity: 0.2,
          visible: true,
        });
        localSeries.strokes.template.set("strokeOpacity", 0);
      }

      if (series.type === AbTestSeriesType.CONTROL_GROUP) {
        localSeries.strokes.template.set("strokeDasharray", [5, 5]);
      }

      if (series.type === AbTestSeriesType.TARGET_GROUP && series.label) {
        const seriesLabel: string = series.label;

        localSeries.bullets.push(() => {
          const label = am5.Label.new(this.root, {
            centerX: am5.p50,
            populateText: true,
            fontWeight: "bold",
            textAlign: "center",
          });

          label.adapters.add("text", (text, target) => {
            const label = (target as any).dataItem.dataContext[seriesLabel];

            return label ? `${label}%` : "";
          });

          label.adapters.add("centerY", (text, target) =>
            (target as any).dataItem.dataContext[seriesLabel] > 0
              ? am5.p100
              : am5.p0
          );

          label.adapters.add("fill", (text, target) =>
            (target as any).dataItem.dataContext[seriesLabel] > 0
              ? am5.color(0x00aa00)
              : am5.color(0xff0000)
          );

          return am5.Bullet.new(this.root, {
            locationX: 0.5,
            locationY: 1,
            sprite: label,
          });
        });
      }

      localSeries.data.setAll(this.data);
    });
  }

  addCursor(): void {
    const cursor = this.chart.set(
      "cursor",
      am5xy.XYCursor.new(this.root, {
        behavior: "zoomX",
      })
    );

    cursor.lineY.set("visible", false);
  }

  addLegend(): void {
    if (!this.options.legend) {
      return;
    }

    const legend = this.chart.children.unshift(
      am5.Legend.new(this.root, {
        x: am5.percent(50),
        centerX: am5.percent(50),
      })
    );

    legend.data.setAll(this.chart.series.values);
  }

  addXAxisRange() {
    if (
      !this.periodValidationStatus ||
      !this.xAxis ||
      !this.options.xAxis.validationRange
    ) {
      return;
    }

    const fill = {
      [AbTestPeriodValidationStatus.VALID]: am5.color(0x00ff00),
      [AbTestPeriodValidationStatus.INVALID]: am5.color(0xff0000),
    }[this.periodValidationStatus];
    const minRangeDataValue = Math.min(
      ...this.data.map((item) => +item[this.options.xAxis.category])
    );
    const rangeDataItem = this.xAxis.makeDataItem({
      category: minRangeDataValue.toString(),
      endCategory: "0",
      endCategoryLocation: 0.5,
    });

    this.xAxis.createAxisRange(rangeDataItem);
    rangeDataItem.get("grid")?.setAll({
      strokeOpacity: 0,
    });
    rangeDataItem.get("axisFill")?.setAll({
      fill,
      fillOpacity: 0.1,
      visible: true,
    });
    rangeDataItem.get("label")?.setAll({
      fill,
      inside: true,
      text: this.periodValidationStatus,
      centerY: am5.p100,
      location: 0.5,
      fontWeight: "bold",
      fontSize: 16,
    });
  }
}
