import Vuetify from "vuetify/lib";
import en from "@/shared/locales/en";

export default new Vuetify({
  lang: {
    locales: { en },
    current: "en",
  },
  theme: {
    dark: false,
  },
});
