import { uniq } from "lodash";

import { CohortFilter } from "./CohortReport";
import { GroupByFilter } from "./GroupByFilter";
import { GroupByWithEventParamFilter } from "./Report";
import { ReportType } from "./ReportType";
import {
  DatesFilterModel,
  EventParamFilterRangeModel,
  FilterId,
  FilterPreview,
  FilterPreviewId,
  recordToFilterModel,
  FilterModel,
  Application,
} from "@/shared/models";
import {
  GeneratedEventReportFilterExtension,
  MeasuresLiteReportTypeReportFilterExtension,
} from "./ReportFilterExtension";
import { ChartName } from "@/chart/models/ChartModel";

export enum MeasuresLiteReportType {
  EVENTS_COUNT = "EVENTS_COUNT",
  EVENTS_DELTA_PER_USER = "EVENTS_DELTA_PER_USER",
  EVENTS_DELTA_PER_USER_CUMULATIVE = "EVENTS_DELTA_PER_USER_CUMULATIVE",
  EVENTS_DELTA_PER_ACTIVE_USER = "EVENTS_DELTA_PER_ACTIVE_USER",
  UNIQUE_USERS_COUNT = "UNIQUE_USERS_COUNT",
  UNIQUE_USERS_PERCENT = "UNIQUE_USERS_PERCENT",
}

export class MeasuresLiteFilter
  extends CohortFilter
  implements
    GroupByWithEventParamFilter,
    MeasuresLiteReportTypeReportFilterExtension,
    GeneratedEventReportFilterExtension
{
  generatedEventFilter?: EventParamFilterRangeModel;
  reportType: MeasuresLiteReportType;
  eventParamKeyForGroupBy?: string;
  generatedEventDateFrom?: string;
  generatedEventDateTo?: string;

  constructor(
    app: Application,
    filter?: Array<FilterModel>,
    date?: DatesFilterModel,
    groupByFilter?: GroupByFilter,
    dayLimit?: number,
    generatedEventFilter?: EventParamFilterRangeModel,
    reportType: MeasuresLiteReportType = MeasuresLiteReportType.EVENTS_DELTA_PER_USER_CUMULATIVE,
    eventParamKeyForGroupBy?: string,
    generatedEventDateFrom?: string,
    generatedEventDateTo?: string
  ) {
    super(ReportType.MEASURES_LITE, app, filter, date, groupByFilter, dayLimit);
    this.generatedEventFilter = generatedEventFilter;
    this.reportType = reportType;
    this.eventParamKeyForGroupBy = eventParamKeyForGroupBy;
    this.generatedEventDateFrom = generatedEventDateFrom;
    this.generatedEventDateTo = generatedEventDateTo;
  }

  get previews(): Array<FilterPreview> {
    return [
      ...super.previews,
      ...(this.generatedEventFilter
        ? this.generatedEventFilter.preview.map((preview) => {
            return {
              ...preview,
              id:
                preview.id === FilterPreviewId.EVENT
                  ? FilterPreviewId.GENERATED_EVENT
                  : FilterPreviewId.GENERATED_EVENT_PARAM,
            };
          })
        : []),
      {
        id: FilterPreviewId.REPORT_TYPE_MEASURES_LITE,
        value: this.reportType,
      },
      ...(this.generatedEventDateFrom && this.generatedEventDateTo
        ? [
            {
              id: FilterPreviewId.GENERATED_EVENT_PARAM_DATE,
              value:
                this.generatedEventDateFrom === this.generatedEventDateTo
                  ? this.generatedEventDateFrom
                  : `${this.generatedEventDateFrom} — ${this.generatedEventDateTo}`,
            },
          ]
        : []),
    ];
  }

  static of(
    app: Application,
    filter?: Record<string, any>
  ): MeasuresLiteFilter {
    return filter
      ? new MeasuresLiteFilter(
          app,
          filter.filter && filter.filter.length > 0
            ? filter.filter.map((it: any) => recordToFilterModel(it))
            : [],
          filter.date
            ? DatesFilterModel.ofRecord(
                FilterId.ATTRIBUTION_DATE_VALUE,
                filter.date
              )
            : undefined,
          filter.groupByFilter
            ? GroupByFilter.of(filter.groupByFilter)
            : undefined,
          filter.dayLimit
            ? Number.parseInt(filter.dayLimit as string)
            : undefined,
          filter.generatedEventFilter
            ? (recordToFilterModel(
                filter.generatedEventFilter
              ) as EventParamFilterRangeModel)
            : undefined,
          Object.values(MeasuresLiteReportType).find(
            (value: string) => value === filter.reportType
          ) ?? MeasuresLiteReportType.EVENTS_DELTA_PER_USER_CUMULATIVE,
          filter.eventParamKeyForGroupBy as string,
          filter.generatedEventDateFrom,
          filter.generatedEventDateTo
        )
      : new MeasuresLiteFilter(app);
  }

  get invalid(): boolean {
    return (
      super.invalid ||
      (this.hasGroupByEventParameter && !this.eventParamKeyForGroupBy) ||
      !this.statsEventFilterName ||
      (this.isGeneratedEventParamValid &&
        !(this.generatedEventDateFrom && this.generatedEventDateTo))
    );
  }

  get isGeneratedEventParamValid(): boolean {
    return !!(
      this.generatedEventFilter?.valid &&
      this.generatedEventFilter?.value?.params?.length
    );
  }

  get statsEventFilterName(): string | undefined {
    return (
      this.filter.find(
        (it) => it.id === FilterId.EVENT
      ) as EventParamFilterRangeModel
    )?.value?.name;
  }

  get hasGroupByEventParameter() {
    return this.groupByFilter.groupBy.indexOf(FilterId.EVENT_PARAMETER) != -1;
  }

  get charts(): Array<ChartName> {
    return this.groupByFilter.isNotEmptyGroupBy
      ? this.getGroupedCohortChartNames("MEASURES_LITE_REPORT", [1, 7, 14])
      : [ChartName.MEASURES_LITE_REPORT_CHART];
  }

  get groupedCharts(): Array<ChartName> {
    return this.groupByFilter.isNotEmptyGroupBy
      ? [ChartName.MEASURES_LITE_REPORT_CHART]
      : [];
  }

  removeGroupByEventParameter() {
    if (this.hasGroupByEventParameter) {
      const index = this.groupByFilter.groupBy.indexOf(
        FilterId.EVENT_PARAMETER
      );
      this.groupByFilter.groupBy.splice(index, 1);
    }
    this.eventParamKeyForGroupBy = undefined;
  }

  clearAndSetApp(app: Application) {
    super.clearAndSetApp(app);
    this.generatedEventFilter = new EventParamFilterRangeModel(FilterId.EVENT);
    this.removeGroupByEventParameter();
  }

  toRequestQuery(): Record<string, any> {
    const result = super.toRequestQuery();
    result["reportType"] = this.reportType;

    if (this.generatedEventFilter && !this.generatedEventFilter.isEmpty()) {
      result["generatedEventFilter"] = this.generatedEventFilter.toRecord();
    }
    if (this.eventParamKeyForGroupBy) {
      result["eventParamKeyForGroupBy"] = this.eventParamKeyForGroupBy;
    }
    if (this.generatedEventDateFrom) {
      result["generatedEventDateFrom"] = this.generatedEventDateFrom;
    }
    if (this.generatedEventDateTo) {
      result["generatedEventDateTo"] = this.generatedEventDateTo;
    }

    return result;
  }

  getUsedDictionaryValues(): Record<string, Array<string>> {
    const usedDictionaryValues: Record<
      string,
      Array<string>
    > = super.getUsedDictionaryValues();

    if (this.generatedEventFilter) {
      this.generatedEventFilter
        .getUsedDictionaryValues()
        .forEach((values, dictionary) => {
          usedDictionaryValues[dictionary] = uniq(
            (usedDictionaryValues[dictionary] || []).concat(values)
          );
        });
    }

    return usedDictionaryValues;
  }
}
