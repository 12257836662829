import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosResponse } from "axios";

import axios from "@/shared/plugins/axios";
import { CurrentUserModel } from "@/shared/models/UserModel";

@Module
export default class UserStore extends VuexModule {
  currentUser = new CurrentUserModel();
  isUserLoading = false;
  notAllNetworksApps: Array<string> | null = null;
  isLoginInProgress = false;

  @Mutation
  setCurrentUser(payload: CurrentUserModel) {
    this.currentUser = CurrentUserModel.of(payload);
  }

  @Mutation
  setUserLoading(payload: boolean) {
    this.isUserLoading = payload;
  }

  @Mutation
  setNotAllNetworksApps(payload: Array<string> | null) {
    this.notAllNetworksApps = payload;
  }

  @Mutation
  setIsLoginInProgress(payload: boolean) {
    this.isLoginInProgress = payload;
  }

  get isSuperAdmin() {
    return this.currentUser.isSuperUser;
  }

  get isDeveloperOrSuperAdmin() {
    return this.currentUser.isSuperUser;
  }

  @Action({ commit: "setCurrentUser" })
  async fetchCurrentUser() {
    this.context.commit("setUserLoading", true);

    return axios
      .get("/api/ngac/user")
      .then(({ data }: AxiosResponse<CurrentUserModel>) => data)
      .finally(() => {
        this.context.commit("setUserLoading", false);
      });
  }

  @Action
  async login(params: Record<string, string>) {
    this.context.commit("setIsLoginInProgress", true);

    return axios
      .post("/api/login", {}, { params })
      .then(({ data }: AxiosResponse<CurrentUserModel>) => data)
      .finally(() => {
        this.context.commit("setIsLoginInProgress", false);
      });
  }

  @Action({ commit: "setNotAllNetworksApps" })
  async fetchNotAllNetworksApps(apps: string) {
    return axios
      .get(`/api/ngac/user/applicationsWithoutAllNetworks/${apps}`)
      .then(({ data }: AxiosResponse<Array<string>>) => data);
  }
}
