export default {
  menu: {
    main: {
      home: "Home",
      report: "Reports",
      tools: "Tools",
      template: "Templates",
      dashboards: "Dashboards",
      segment: "Segments",
      funnel: "Funnels",
      abTests: "AB Tests",
      settings: "Settings",
      applicationManager: "Application manager",
      userAlerts: "Alerts system",
      iam: "IAM",
      accountingPortal: "Accounting portal",
    },
    expand: "Expand menu",
    collapse: "Collapse menu",
    reports: "Reports",
    settings: {
      ad_revenue_events: "Ad Revenue Events",
      dictionaries: "Dictionaries",
      placements: "Placements",
      networks_management: "Networks Management",
      appVersions: "App Versions",
      osVersions: "OS Versions",
      divider: "Divider",
      dictionaries_app_versions: "App Versions",
      dictionaries_os_versions: "OS Versions",
      subscription_events: "Subscription Events",
      tax_percent: "Tax Percents",
    },
    abTests: {
      ab_tests: "AB Test",
      targeted_configs: "Targeted Config",
      default_config: "Default Config",
      external_tests: "External Tests",
      ab_tests_groups_size_recommender: "Groups Size Recommender",
    },
    segments: {
      segments: "Segments",
      custom_static_segments: "Custom static segments",
      custom_dynamic_segments: "Custom dynamic segments",
      ab_test_segments: "AB Test segments",
      external_test_segments: "External Test segments",
      divider_segments: "Divider segments",
      provided_segments: "Provided segments",
      funnel_segments: "Funnel segments",
    },
    dashboards: {
      title: "Dashboards",
      multiapp_dashboards: "Multiapp dashboards",
      singleapp_dashboards: "Singleapp dashboards",
    },
    applicationManager: {
      jurisdictions: "Jurisdictions",
      scenarios: "Scenarios",
      batches: "Batches",
      configurations: "Configurations",
      applications: "Applications",
      application_basic_info: "Application Basic Info",
      application_features_control: "Application Features Control",
      application_validation: "Application Config Validation",
    },
    templates: {
      title: "Templates",
      report_templates: "Report Templates",
      funnel_templates: "Funnel Templates",
    },
    healthcheck: {
      title: "Healthcheck",
      healthcheck_summary: "Summary",
      healthcheck_data_flow: "Data Flow",
      healthcheck_parsing: "Parsing",
      healthcheck_jobs_queue: "Jobs Queue",
    },
    users: {
      newTitle: "New users page",
      title: "Users",
    },
    tools: {
      export_user_flow: "Export user's flow",
      monetization_monitoring: "Monetization monitoring",
      healthcheck_monetization_alert: "Monetization alerts",
      healthcheck_marketing_alert: "Marketing alerts",
    },
    iam: {
      users: "Users",
      aggregators: "Aggregators",
      permissions_browser: "Permissions Browser",
    },
    accountingPortal: {
      banks: "Banks",
      counterparties: "Counterparties",
      invoices: "Invoices",
    },
  },
};
