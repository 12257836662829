import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { AxiosResponse } from "axios";

import axios from "@/shared/plugins/axios";
import JurisdictionResponseModel, {
  JurisdictionValueModel,
} from "@/application-manager/models/JurisdictionResponseModel";
import JurisdictionRequestModel from "@/application-manager/models/JurisdictionRequestModel";

@Module
export default class JurisdictionStore extends VuexModule {
  isLoadingJurisdictions = false;
  isSavingInProgress = false;
  jurisdictions: Array<JurisdictionResponseModel> = [];
  jurisdiction: JurisdictionResponseModel | null = null;
  jurisdictionValues: Array<JurisdictionValueModel> = [];

  @Mutation
  setLoadingJurisdictions(payload: boolean) {
    this.isLoadingJurisdictions = payload;
  }

  @Mutation
  setIsSavingInProgress(payload: boolean) {
    this.isSavingInProgress = payload;
  }

  @Mutation
  setJurisdictions(payload: Array<JurisdictionResponseModel>) {
    this.jurisdictions = JurisdictionResponseModel.ofArray(payload);
  }

  @Mutation
  setJurisdiction(payload: JurisdictionResponseModel) {
    this.jurisdiction = JurisdictionResponseModel.ofSource(payload);
  }

  @Mutation
  setJurisdictionValues(payload: Array<JurisdictionValueModel>) {
    this.jurisdictionValues = JurisdictionValueModel.ofArray(payload);
  }

  @Mutation
  setJurisdictionValue(payload: JurisdictionValueModel) {
    this.jurisdictionValues.push(payload);
  }

  @Mutation
  setJurisdictionValueAfterUpdate(payload: JurisdictionValueModel) {
    const found = this.jurisdictionValues.find(
      (value: JurisdictionValueModel) => value.id === payload.id
    );

    if (found) {
      found.name = payload.name;
      found.value = payload.value;
    }
  }

  @Mutation
  setJurisdictionValuesAfterDelete(payload: string) {
    this.jurisdictionValues = this.jurisdictionValues.filter(
      (value: JurisdictionValueModel) => value.name !== payload
    );
  }

  @Mutation
  clearJurisdictions() {
    this.jurisdictions = [];
  }

  @Mutation
  clearJurisdictionValues() {
    this.jurisdictionValues = [];
  }

  @Action({ commit: "setJurisdictions" })
  async loadJurisdictions() {
    this.context.commit("setLoadingJurisdictions", true);

    return axios
      .get(`/api/infra/profiles`)
      .then((result: AxiosResponse<Array<JurisdictionResponseModel>>) => {
        return result.data;
      })
      .finally(() => {
        this.context.commit("setLoadingJurisdictions", false);
      });
  }

  @Action({ commit: "setJurisdiction" })
  async loadJurisdiction(profileId: number) {
    this.context.commit("setLoadingJurisdictions", true);

    return axios
      .get(`/api/infra/profiles/${profileId}`)
      .then((result: AxiosResponse<JurisdictionResponseModel>) => {
        return result.data;
      })
      .finally(() => {
        this.context.commit("setLoadingJurisdictions", false);
      });
  }

  @Action
  async createJurisdiction(payload: JurisdictionRequestModel) {
    this.context.commit("setIsSavingInProgress", true);

    return axios
      .post("/api/infra/profiles", payload)
      .then((result: AxiosResponse<JurisdictionResponseModel>) => {
        this.context.commit("addNotification", {
          message: {
            key: "applicationManager.jurisdiction.notification.createdSuccess",
          },
        });

        return result.data;
      })
      .finally(() => {
        this.context.commit("setIsSavingInProgress", false);
      });
  }

  @Action({ commit: "setJurisdiction" })
  async updateJurisdiction(payload: JurisdictionRequestModel) {
    this.context.commit("setIsSavingInProgress", true);

    return axios
      .put("/api/infra/profiles", payload)
      .then((result: AxiosResponse<JurisdictionResponseModel>) => {
        this.context.commit("addNotification", {
          message: {
            key: "applicationManager.jurisdiction.notification.updatedSuccess",
          },
        });

        return result.data;
      })
      .finally(() => {
        this.context.commit("setIsSavingInProgress", false);
      });
  }

  @Action({ commit: "setJurisdictionValues" })
  async getJurisdictionValues(profileId: number) {
    return axios
      .get(`/api/infra/profiles/${profileId}/values`)
      .then((result: AxiosResponse<Array<JurisdictionValueModel>>) => {
        return result.data;
      });
  }

  @Action({ commit: "setJurisdictionValue" })
  async addJurisdictionValue({
    payload,
    profileId,
  }: {
    payload: { name: string; value: string };
    profileId: number;
  }) {
    return axios
      .post(`/api/infra/profiles/${profileId}/values`, payload)
      .then((result: AxiosResponse<JurisdictionValueModel>) => {
        return result.data;
      });
  }

  @Action({ commit: "setJurisdictionValueAfterUpdate" })
  async updateJurisdictionValue({
    valueName,
    payload,
    profileId,
  }: {
    valueName: string;
    payload: { name: string; value: string };
    profileId: number;
  }) {
    return axios
      .put(`/api/infra/profiles/${profileId}/values/${valueName}`, payload)
      .then((result: AxiosResponse<JurisdictionValueModel>) => {
        return result.data;
      });
  }

  @Action({ commit: "setJurisdictionValuesAfterDelete" })
  async deleteJurisdictionValue({
    valueName,
    profileId,
  }: {
    valueName: string;
    profileId: number;
  }) {
    return axios
      .delete(`/api/infra/profiles/${profileId}/values/${valueName}`)
      .then(() => {
        return valueName;
      });
  }

  @Action
  async deleteJurisdiction(id: number) {
    return axios.delete(`/api/infra/profiles/${id}`).then(() => {
      this.context.dispatch("loadJurisdictions");
    });
  }
}
