import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import axios from "@/shared/plugins/axios";
import {
  ApplicationWorkflowModel,
  ApplicationWorkflowRequestModel,
  ApplicationWorkflow,
} from "@/application-manager/models/ApplicationWorkflowModel";

@Module
export default class ApplicationWorkflowStore extends VuexModule {
  applicationWorkflows: Array<ApplicationWorkflowModel> = [];
  isApplicationWorkflowsLoading = false;
  isApplicationWorkflowLoading = false;
  isApplicationWorkflowSaving = false;
  applicationWorkflow: ApplicationWorkflowRequestModel | null = null;
  applicationWorkflowId: number | null = null;

  @Mutation
  setApplicationWorkflows(payload: Array<ApplicationWorkflowModel>) {
    this.applicationWorkflows = ApplicationWorkflowModel.ofArray(payload);
  }

  @Mutation
  setApplicationWorkflowsLoading(payload: boolean) {
    this.isApplicationWorkflowsLoading = payload;
  }

  @Mutation
  setApplicationWorkflowId(payload: number | null) {
    this.applicationWorkflowId = payload;
  }

  @Mutation
  setApplicationWorkflowLoading(payload: boolean) {
    this.isApplicationWorkflowLoading = payload;
  }

  @Mutation
  setApplicationWorkflow(payload: ApplicationWorkflowRequestModel | null) {
    this.applicationWorkflow = payload
      ? ApplicationWorkflowRequestModel.of(payload)
      : payload;
  }

  @Mutation
  setApplicationWorkflowSaving(payload: boolean) {
    this.isApplicationWorkflowSaving = payload;
  }

  @Action({ commit: "setApplicationWorkflows" })
  fetchApplicationWorkflows() {
    this.context.commit("setApplicationWorkflowsLoading", true);
    this.context.commit("setApplicationWorkflows", []);

    return axios
      .get("/api/infra/executions/info")
      .then(({ data }) => data)
      .finally(() => {
        this.context.commit("setApplicationWorkflowsLoading", false);
      });
  }

  @Action({ commit: "setApplicationWorkflow" })
  async getApplicationWorkflow() {
    this.context.commit("setApplicationWorkflowLoading", true);

    return axios
      .get(`/api/infra/executions/${this.applicationWorkflowId}/request`)
      .then(({ data }) => data)
      .finally(() => {
        this.context.commit("setApplicationWorkflowLoading", false);
      });
  }

  @Action
  async createApplicationWorkflow(payload: ApplicationWorkflowRequestModel) {
    this.context.commit("setApplicationWorkflowSaving", true);

    await axios
      .post("/api/infra/executions", payload)
      .then(() => {
        this.context.dispatch("fetchApplicationWorkflows");
      })
      .finally(() => {
        this.context.commit("setApplicationWorkflowSaving", false);
      });
  }

  @Action
  async updateApplicationWorkflow(payload: ApplicationWorkflowRequestModel) {
    if (this.applicationWorkflowId === null) {
      return;
    }

    this.context.commit("setApplicationWorkflowSaving", true);

    await axios
      .put(`/api/infra/executions/${this.applicationWorkflowId}`, payload)
      .then(() => {
        this.context.dispatch("fetchApplicationWorkflows");
      })
      .finally(() => {
        this.context.commit("setApplicationWorkflowSaving", false);
      });
  }

  @Action
  async archiveApplication(applicationId: string) {
    this.context.commit("setApplicationArchiving", true);

    await axios
      .post(`/api/infra/executions`, {
        workflowTemplate: ApplicationWorkflow.SET_APPLICATION_TO_ARCHIVED,
        applicationId,
      })
      .then(() => {
        this.context.dispatch("fetchApplicationWorkflows");
      })
      .finally(() => {
        this.context.commit("setApplicationArchiving", false);
      });
  }

  @Action
  async updateApplicationArchiveWorkflow({
    executionId,
    workflowTemplate,
    application: applicationId,
  }: ApplicationWorkflowModel) {
    await axios
      .put(`/api/infra/executions/${executionId}`, {
        workflowTemplate,
        applicationId,
      })
      .then(() => {
        this.context.dispatch("fetchApplicationWorkflows");
      });
  }
}
