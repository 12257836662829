var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-4",attrs:{"fluid":""}},[_c('v-row',{staticClass:"align-center"},[_c('v-col',[(_vm.dashboardId)?_c('v-skeleton-loader',{attrs:{"loading":_vm.isDashboardPreviewsLoading,"type":"card","height":"40"}},[_c('h1',{staticClass:"text-h5 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.dashboard ? _vm.dashboard.name : "")+" ")])]):_c('h1',{staticClass:"text-h5 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$lang("dashboard.title.favorite"))+" ")])],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","md":"4"}},[_c('v-autocomplete',{attrs:{"loading":_vm.isDashboardPreviewsLoading,"disabled":_vm.isDashboardPreviewsLoading,"items":_vm.dashboardItems,"label":_vm.$lang('dashboard.selectLabel'),"outlined":"","dense":"","hide-details":""},model:{value:(_vm.currentDashboardId),callback:function ($$v) {_vm.currentDashboardId=$$v},expression:"currentDashboardId"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-4",attrs:{"color":"primary","small":"","fab":"","disabled":!_vm.isFilterAvailable},on:{"click":function($event){_vm.isFilterVisible = !_vm.isFilterVisible}}},on),[_c('v-icon',[_vm._v("mdi-filter-outline")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.$lang("filterPanel.show"))+" ")])],1),(_vm.dashboardFilterPreviews.length)?_c('report-filter-current-state',{attrs:{"previews":_vm.dashboardFilterPreviews}}):_vm._e()],1),(_vm.isDashboardPreviewsLoading)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):[(_vm.dashboard && _vm.dashboard.warningMessage)?_c('div',{staticClass:"d-flex"},[(_vm.dashboard)?_c('v-alert',{attrs:{"type":"warning","outlined":""}},[_vm._v(" "+_vm._s(_vm.dashboard.warningMessage)+" ")]):_vm._e()],1):_vm._e(),(_vm.dashboard)?_c('v-row',_vm._l((_vm.sortedCharts),function(chart){return _c('dashboard-chart',{key:chart.id,attrs:{"chart":chart}})}),1):_c('p',[_vm._v(" "+_vm._s(_vm.$lang("dashboard.noFavoriteText"))+" "),_c('router-link',{attrs:{"to":{
          name: 'dashboards',
          params: { applicationId: _vm.applicationId },
        }}},[_vm._v(" "+_vm._s(_vm.$lang("dashboard.noFavoriteLink"))+" ")])],1)],_c('v-navigation-drawer',{staticClass:"mt-md-16 elevation-5",attrs:{"fixed":"","right":"","width":"500","height":_vm.$vuetify.breakpoint.mdAndUp ? 'calc(100vh - 64px)' : '100%'},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-toolbar',{staticStyle:{"z-index":"1"}},[_c('v-toolbar-title',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$lang("views.filters"))+" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"shrink ml-auto",attrs:{"fab":"","small":"","elevation":"0"},on:{"click":function($event){_vm.isFilterVisible = false}}},on),[_c('v-icon',[_vm._v("close")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.$lang("filterPanel.close"))+" ")])],1)]},proxy:true},{key:"append",fn:function(){return [_c('v-toolbar',{attrs:{"tag":"footer","bottom":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"shrink"},[_c('v-btn',{attrs:{"color":"primary","loading":_vm.isDashboardPreviewsLoading,"disabled":_vm.isDashboardPreviewsLoading || _vm.isGenerateBtnDisabled,"atid":"generate_button"},on:{"click":_vm.generate}},[_vm._v(" "+_vm._s(_vm.$lang("views.generate"))+" ")])],1)],1)],1)]},proxy:true}]),model:{value:(_vm.isFilterVisible),callback:function ($$v) {_vm.isFilterVisible=$$v},expression:"isFilterVisible"}},[(!_vm.isMultiAppMode)?_c('dashboard-filter',{key:_vm.dashboardId,attrs:{"applicationId":_vm.applicationId},model:{value:(_vm.dashboardFilter),callback:function ($$v) {_vm.dashboardFilter=$$v},expression:"dashboardFilter"}}):_vm._e()],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }