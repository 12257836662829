import { MetricType } from "@/healthcheck/model/MetricType";
import { MetricId } from "@/healthcheck/model/MetricId";

export class MetricWeightResponseModel {
  constructor(
    public id: number,
    public metricType: MetricType,
    public metricId: MetricId,
    public application: string,
    public weight: number
  ) {}

  static of(item: MetricWeightResponseModel) {
    return new MetricWeightResponseModel(
      item.id,
      item.metricType,
      item.metricId,
      item.application,
      item.weight
    );
  }

  static ofArray(items: Array<MetricWeightResponseModel>) {
    return items.map((it) => this.of(it));
  }
}
