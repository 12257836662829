export enum AccountSettingType {
  INTERFACE_THEME = "theme",
  FILTER_LOCATION = "filterLocation",
  ADDITIONAL_MENU_LOCATION = "additionalMenuLocation",
  FILTERS_BEHAVIOR = "filtersBehavior",
  SPECIFIC_FILTERS_BEHAVIOR = "specificFiltersBehavior",
  DEFAULT_APPLICATION = "defaultApplication",
  FAVORITE_APPLICATIONS = "favoriteApplications",
}

export enum ThemeType {
  DARK = "dark",
  LIGHT = "light",
}

export enum FilterLocationType {
  HORIZONTAL = "horizontal",
  VERTICAL = "vertical",
}

export enum AdditionalMenuLocationType {
  COLLAPSED = "collapsed",
  EXPANDED = "expanded",
}

export enum FilterBehaviorOption {
  CLEARABLE = "CLEARABLE",
  CLEAR_SEARCH_VALUE_AFTER_SELECT = "CLEAR_SEARCH_VALUE_AFTER_SELECT",
  CLOSE_AFTER_SELECT = "CLOSE_AFTER_SELECT",
}
