import { intersection } from "lodash";

import { DashboardType } from "@/dashboard/models/DashboardModel";
import { ReportType } from "@/reports/models";
import {
  AppSection,
  MenuItems,
  MenuItemsAbTests,
  MenuItemsApplicationManager,
  MenuItemsTools,
  MULTI_APP,
} from "@/shared/models";
import { CurrentUserModel } from "@/shared/models/UserModel";

export default class UserUtil {
  static getRoutesAccessMap(
    user: CurrentUserModel
  ): Record<string, Map<string, boolean>> {
    return [...user.availableApps, MULTI_APP].reduce((result, appId) => {
      const viewAccess = user.viewAccessEntities[appId] ?? [];
      const createAccess = user.createAccessEntities[appId] ?? [];

      const hasDashboardViewAccess = !!intersection(viewAccess, [
        AppSection.MULTIAPP_DASHBOARDS,
        AppSection.SINGLEAPP_DASHBOARDS,
      ]).length;
      const hasDashboardCreateAccess = !!intersection(createAccess, [
        AppSection.MULTIAPP_DASHBOARDS,
        AppSection.SINGLEAPP_DASHBOARDS,
      ]).length;
      const hasAbTestViewAccess = viewAccess.includes(AppSection.AB_TESTS);
      const hasAbTestCreateAccess = createAccess.includes(AppSection.AB_TESTS);
      const hasTargetedViewAccess = viewAccess.includes(
        AppSection.TARGETED_CONFIGS
      );
      const hasTargetedCreateAccess = createAccess.includes(
        AppSection.TARGETED_CONFIGS
      );
      const hasExternalTestViewAccess = viewAccess.includes(
        AppSection.EXTERNAL_TESTS
      );
      const hasExternalTestCreateAccess = createAccess.includes(
        AppSection.EXTERNAL_TESTS
      );
      const hasSegmentsViewAccess = !!intersection(viewAccess, [
        AppSection.FUNNEL_SEGMENTS,
        AppSection.AB_TEST_SEGMENTS,
        AppSection.DIVIDER_SEGMENTS,
        AppSection.EXTERNAL_TEST_SEGMENTS,
        AppSection.CUSTOM_STATIC_SEGMENTS,
        AppSection.CUSTOM_DYNAMIC_SEGMENTS,
      ]).length;

      return Object.assign(result, {
        [appId]: new Map([
          [MenuItems.HOME, true],
          ["login", true],
          ["404", true],
          ["user", createAccess.includes(AppSection.USERS)],
          ["users", viewAccess.includes(AppSection.USERS)],
          [
            MenuItems.REPORT,
            !!intersection(
              viewAccess,
              Object.values(ReportType).filter(
                (reportType) =>
                  reportType !== ReportType.MONETIZATION_MONITORING
              )
            ).length,
          ],
          ...Object.entries(
            Object.values(ReportType).reduce(
              (result: Record<string, boolean>, reportType) =>
                Object.assign(result, {
                  [reportType]: viewAccess.includes(reportType),
                }),
              {}
            )
          ),
          [MenuItems.FUNNEL, viewAccess.includes(AppSection.FUNNELS)],
          ["funnel_new", createAccess.includes(AppSection.FUNNELS)],
          ["funnel_view", viewAccess.includes(AppSection.FUNNELS)],
          ["funnel_chart_view", viewAccess.includes(AppSection.FUNNELS)],
          [MenuItems.SEGMENT, hasSegmentsViewAccess],
          [
            AppSection.AB_TEST_SEGMENTS,
            viewAccess.includes(AppSection.AB_TEST_SEGMENTS),
          ],
          [
            AppSection.CUSTOM_DYNAMIC_SEGMENTS,
            viewAccess.includes(AppSection.CUSTOM_DYNAMIC_SEGMENTS),
          ],
          [
            AppSection.CUSTOM_STATIC_SEGMENTS,
            viewAccess.includes(AppSection.CUSTOM_STATIC_SEGMENTS),
          ],
          [
            AppSection.DIVIDER_SEGMENTS,
            viewAccess.includes(AppSection.DIVIDER_SEGMENTS),
          ],
          [
            AppSection.EXTERNAL_TEST_SEGMENTS,
            viewAccess.includes(AppSection.EXTERNAL_TEST_SEGMENTS),
          ],
          [
            AppSection.FUNNEL_SEGMENTS,
            viewAccess.includes(AppSection.FUNNEL_SEGMENTS),
          ],
          [
            "segment_new",
            !!intersection(createAccess, [
              AppSection.CUSTOM_STATIC_SEGMENTS,
              AppSection.CUSTOM_DYNAMIC_SEGMENTS,
            ]).length,
          ],
          ["segment_update", hasSegmentsViewAccess],
          [MenuItems.DASHBOARDS, hasDashboardViewAccess],
          [
            DashboardType.MULTI_APP,
            viewAccess.includes(AppSection.MULTIAPP_DASHBOARDS),
          ],
          [
            AppSection.SINGLEAPP_DASHBOARDS,
            viewAccess.includes(AppSection.SINGLEAPP_DASHBOARDS),
          ],
          [MenuItems.DASHBOARD, hasDashboardViewAccess],
          [MenuItems.DASHBOARD_VIEW, hasDashboardViewAccess],
          [MenuItems.DASHBOARD_NEW, hasDashboardCreateAccess],
          [MenuItems.DASHBOARD_UPDATE, hasDashboardViewAccess],
          [
            MenuItems.SETTINGS,
            !!intersection(viewAccess, [
              AppSection.DICTIONARIES_APP_VERSIONS,
              AppSection.DICTIONARIES_OS_VERSIONS,
              AppSection.AD_REVENUE_EVENTS,
              AppSection.DIVIDER,
              AppSection.PLACEMENTS,
              AppSection.NETWORKS_MANAGEMENT,
              AppSection.SUBSCRIPTION_EVENTS,
              AppSection.TAX_PERCENT,
            ]).length,
          ],
          [
            AppSection.DICTIONARIES_APP_VERSIONS,
            viewAccess.includes(AppSection.DICTIONARIES_APP_VERSIONS),
          ],
          [
            AppSection.DICTIONARIES_OS_VERSIONS,
            viewAccess.includes(AppSection.DICTIONARIES_OS_VERSIONS),
          ],
          [AppSection.DIVIDER, viewAccess.includes(AppSection.DIVIDER)],
          [
            MenuItems.AB_TESTS,
            !!intersection(viewAccess, [
              AppSection.AB_TESTS,
              AppSection.TARGETED_CONFIGS,
              AppSection.EXTERNAL_TESTS,
              AppSection.DEFAULT_CONFIG,
              AppSection.AB_TESTS_GROUPS_SIZE_RECOMMENDER,
            ]).length,
          ],
          [
            "networks-management_new",
            createAccess.includes(AppSection.NETWORKS_MANAGEMENT),
          ],
          [MenuItemsAbTests.AB_TEST, hasAbTestViewAccess],
          ["abTest_new", hasAbTestCreateAccess],
          ["abTest_edit_configs", hasAbTestViewAccess],
          ["abTest_edit", hasAbTestViewAccess],
          ["abTest_view", hasAbTestViewAccess],
          [MenuItemsAbTests.TARGETED, hasTargetedViewAccess],
          ["targeted_new", hasTargetedCreateAccess],
          ["targeted_edit_configs", hasTargetedViewAccess],
          ["targeted_edit", hasTargetedViewAccess],
          ["targeted_view", hasTargetedViewAccess],
          [
            AppSection.DEFAULT_CONFIG,
            viewAccess.includes(AppSection.DEFAULT_CONFIG),
          ],
          [AppSection.EXTERNAL_TESTS, hasExternalTestViewAccess],
          ["external_configuration", hasExternalTestViewAccess],
          ["external_new", hasExternalTestCreateAccess],
          ["external_test", hasExternalTestViewAccess],
          [
            MenuItems.TEMPLATE,
            !!intersection(viewAccess, [
              AppSection.FUNNEL_TEMPLATES,
              AppSection.REPORT_TEMPLATES,
            ]).length,
          ],
          [
            AppSection.REPORT_TEMPLATES,
            viewAccess.includes(AppSection.REPORT_TEMPLATES),
          ],
          [
            AppSection.FUNNEL_TEMPLATES,
            viewAccess.includes(AppSection.FUNNEL_TEMPLATES),
          ],
          [
            "used_dashboards",
            !!intersection(createAccess, [
              AppSection.FUNNEL_TEMPLATES,
              AppSection.REPORT_TEMPLATES,
            ]).length,
          ],
          [
            MenuItems.HEALTHCHECK_SUMMARY,
            viewAccess.includes(AppSection.HEALTHCHECK_SUMMARY),
          ],
          [
            MenuItems.HEALTHCHECK_DATAFLOW,
            viewAccess.includes(AppSection.HEALTHCHECK_DATA_FLOW),
          ],
          [
            MenuItems.HEALTHCHECK_JOBS_QUEUE,
            viewAccess.includes(AppSection.HEALTHCHECK_JOBS_QUEUE),
          ],
          [
            MenuItems.HEALTHCHECK_PARSING,
            viewAccess.includes(AppSection.HEALTHCHECK_PARSING),
          ],
          [
            AppSection.HEALTHCHECK_METRIC_WEIGHT,
            viewAccess.includes(AppSection.HEALTHCHECK_METRIC_WEIGHT),
          ],
          [
            MenuItems.APPLICATION_MANAGER,
            !!intersection(viewAccess, [
              AppSection.JURISDICTIONS,
              AppSection.SCENARIOS,
              AppSection.BATCHES,
              AppSection.APPLICATIONS,
              AppSection.APPLICATION_BASIC_INFO,
              AppSection.APPLICATION_FEATURES_CONTROL,
            ]).length,
          ],
          [
            MenuItemsApplicationManager.JURISDICTIONS,
            viewAccess.includes(AppSection.JURISDICTIONS),
          ],
          [
            MenuItemsApplicationManager.JURISDICTION_VIEW,
            viewAccess.includes(AppSection.JURISDICTIONS),
          ],
          [
            MenuItemsApplicationManager.JURISDICTION_NEW,
            createAccess.includes(AppSection.JURISDICTIONS),
          ],
          [
            MenuItemsApplicationManager.SCENARIOS,
            viewAccess.includes(AppSection.SCENARIOS),
          ],
          [
            MenuItemsApplicationManager.SCENARIO_VIEW,
            viewAccess.includes(AppSection.SCENARIOS),
          ],
          [
            MenuItemsApplicationManager.SCENARIO_NEW,
            createAccess.includes(AppSection.SCENARIOS),
          ],
          [
            MenuItemsApplicationManager.BATCHES,
            viewAccess.includes(AppSection.BATCHES),
          ],
          [
            MenuItemsApplicationManager.BATCH_VIEW,
            viewAccess.includes(AppSection.BATCHES),
          ],
          [
            MenuItemsApplicationManager.BATCH_NEW,
            createAccess.includes(AppSection.BATCHES),
          ],
          [
            MenuItemsApplicationManager.APPLICATIONS,
            viewAccess.includes(AppSection.APPLICATIONS),
          ],
          [
            MenuItemsApplicationManager.APPLICATION_NEW,
            createAccess.includes(AppSection.APPLICATIONS),
          ],
          [MenuItems.USER_ALERTS, viewAccess.includes(AppSection.ALERT_SYSTEM)],
          ["alert_view", viewAccess.includes(AppSection.ALERT_SYSTEM)],
          ["alert_new", createAccess.includes(AppSection.ALERT_SYSTEM)],
          [
            MenuItems.TOOLS,
            !!intersection(viewAccess, [
              AppSection.EXPORT_USER_FLOW,
              ReportType.MONETIZATION_MONITORING,
              AppSection.HEALTHCHECK_MARKETING_ALERT,
              AppSection.HEALTHCHECK_MONETIZATION_ALERT,
            ]).length,
          ],
          [
            AppSection.EXPORT_USER_FLOW,
            viewAccess.includes(AppSection.EXPORT_USER_FLOW),
          ],
          [
            MenuItemsTools.MONETIZATION_MONITORING,
            viewAccess.includes(ReportType.MONETIZATION_MONITORING),
          ],
          [
            AppSection.HEALTHCHECK_MONETIZATION_ALERT,
            viewAccess.includes(AppSection.HEALTHCHECK_MONETIZATION_ALERT),
          ],
        ]),
      });
    }, {});
  }
}
