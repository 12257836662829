import { uniq, unset } from "lodash";

import { UserAccess } from "@/shared/models/UserModel";
import AbTestResponseModel from "./AbTestResponseModel";
import { ConfigsStatus } from "./ConfigsStatusModel";
import RuleModel from "./RuleModel";

export enum ParentConfigType {
  Default = "default",
  Targeted = "targeted",
}

export enum AbTestType {
  NEW_USERS = "NEW_USERS",
  AUDIENCE = "AUDIENCE",
}

export enum AbTestStatisticalSignificanceTestType {
  PERMUTATION_TEST = "PERMUTATION_TEST",
  T_TEST = "T_TEST",
  Z_TEST = "Z_TEST",
}

export enum AbTestResultType {
  POSITIVE = "POSITIVE",
  NEGATIVE = "NEGATIVE",
  NEUTRAL = "NEUTRAL",
  UNCERTAIN = "UNCERTAIN",
  INCONSISTENT_POSITIVE = "INCONSISTENT_POSITIVE",
}

export enum AbTestPeriodValidationStatus {
  VALID = "VALID",
  INVALID = "INVALID",
}

export enum AbTestMinUsersCountType {
  DEFAULT = "DEFAULT",
  FOR_SELECT_AUDIENCE = "FOR_SELECT_AUDIENCE",
  FOR_SELECT_AUDIENCE_WITHOUT_VERSIONS = "FOR_SELECT_AUDIENCE_WITHOUT_VERSIONS",
  FOR_ALL_AUDIENCE = "FOR_ALL_AUDIENCE",
}

export class AbTestControlGroupModel {
  constructor(public segmentName = "", public isUniqueSegmentName = true) {}
}

export interface SegmentStatistic {
  id: number;
  name: string;
  usersCount: number | null;
  usersShare: number | null;
}

export class SegmentStatisticModel {
  constructor(
    public id: number,
    public name: string,
    public usersCount: number | null,
    public usersShare: number | null,
    public assignmentTillUsersCount?: number
  ) {}

  get completeness(): number | null {
    if (
      this.usersCount === undefined ||
      this.usersCount === null ||
      !this.assignmentTillUsersCount
    ) {
      return null;
    }

    return (this.usersCount * 100) / this.assignmentTillUsersCount;
  }

  static of(
    segmentStatistic: SegmentStatistic,
    assignmentTillUsersCount?: number
  ) {
    return new SegmentStatisticModel(
      segmentStatistic.id,
      segmentStatistic.name,
      segmentStatistic.usersCount,
      segmentStatistic.usersShare,
      assignmentTillUsersCount
    );
  }
}

export default class AbTestConfigurationModel {
  constructor(
    public id?: number,
    public version?: string,
    public name = "",
    public rules: Array<RuleModel> = [],
    public responses: Array<AbTestResponseModel> = [],
    public segmentStatistics: Array<SegmentStatisticModel> = [],
    public samplingRate: number | null = 1,
    public activeSince?: string,
    public activeTill?: string,
    public assignmentTillDate?: string,
    public parentId?: number,
    public creatorId?: string,
    public creatorName?: string,
    public isActive?: boolean,
    public abTestType?: AbTestType,
    public dayNumber?: number,
    public metric?: AbTestMetricType,
    public status?: ConfigsStatus,
    public controlGroupName = "",
    public editable = false,
    public deletable = false,
    public disableable = false,
    public resultType?: AbTestResultType,
    public hypothesis = "",
    public features: Array<UserAccess> = [],
    public minUsersCount?: AbTestMinUsersCount
  ) {}

  get hasEditAccess(): boolean {
    return this.features.includes(UserAccess.EDIT);
  }

  get hasDeleteAccess(): boolean {
    return this.features.includes(UserAccess.DELETE);
  }

  get hasDeployAccess(): boolean {
    return this.features.includes(UserAccess.DEPLOY);
  }

  get affectedAuditory(): string {
    return `${Number((100 / (this.samplingRate ?? 1)).toFixed(2))}%`;
  }

  get isDisabled(): boolean {
    return this.status === ConfigsStatus.DISABLED;
  }

  get isStarted(): boolean {
    return this.status === ConfigsStatus.STARTED;
  }

  get isStatisticCalculated(): boolean {
    return this.status === ConfigsStatus.STATISTICS_RECALCULATED;
  }

  get isStatusSwitchable(): boolean {
    return (this.disableable || this.isDisabled) && this.hasEditAccess;
  }

  get minUsersCountRequest(): AbTestMinUsersCountRequest | null {
    return (
      (this.abTestType &&
        this.dayNumber &&
        this.metric && {
          abTestType: this.abTestType,
          dayNumber: this.dayNumber,
          metric: this.metric,
          rules: this.rules,
        }) ||
      null
    );
  }

  static of(model: AbTestConfigurationModel): AbTestConfigurationModel {
    return new AbTestConfigurationModel(
      model.id,
      model.version,
      model.name,
      model.rules?.map((it) => RuleModel.of(it)) || [],
      model.responses?.map((it) => AbTestResponseModel.of(it)) || [],
      model.segmentStatistics?.map((it) =>
        SegmentStatisticModel.of(it, model.minUsersCount?.amount)
      ) || [],
      model.samplingRate,
      model.activeSince,
      model.activeTill,
      model.assignmentTillDate,
      model.parentId,
      model.creatorId,
      model.creatorName,
      model.isActive,
      model.abTestType,
      model.dayNumber,
      model.metric,
      model.status,
      model.controlGroupName,
      model.editable,
      model.deletable,
      model.disableable,
      model.resultType,
      model.hypothesis,
      model.features,
      model.minUsersCount
    );
  }

  getPreparedData(): AbTestConfigurationModel {
    // попросили пока что закоментить
    // if (this.abTestType === AbTestType.AUDIENCE) {
    //   unset(this, "dayNumber");
    //   unset(this, "metric");
    //   unset(this, "assignmentTillUsersCount");
    // } else

    const result = {
      ...this,
      responses: this.responses.map(({ segmentName }) => ({
        segmentName,
      })),
    };

    if (this.abTestType === AbTestType.NEW_USERS) {
      unset(result, "activeTill");
      unset(result, "assignmentTillDate");
    }

    return result;
  }

  getUsedDictionaryValues(): Record<string, Array<string>> {
    return this.rules.reduce(
      (result: Record<string, Array<string>>, { usedDictionaryValues }) => {
        Object.entries(usedDictionaryValues).forEach(([dictionary, values]) => {
          result[dictionary] = uniq((result[dictionary] || []).concat(values));
        });

        return result;
      },
      {}
    );
  }
}

export enum AbTestMetricType {
  ARPU = "ARPU",
  RETENTION_RATE = "RETENTION_RATE",
  TIME_SPENT = "TIME_SPENT",
}

export class AbTestChartModel {
  constructor(
    public segmentName: string,
    public testType: AbTestStatisticalSignificanceTestType,
    public chart: Array<Array<any>>,
    public alfa: number,
    public prevValidationChart: Array<Array<any>> | null,
    public prevValidationResult: AbTestPeriodValidationStatus | null
  ) {}

  static of(model: AbTestChartModel) {
    return new AbTestChartModel(
      model.segmentName,
      model.testType,
      model.chart,
      model.alfa,
      model.prevValidationChart,
      model.prevValidationResult
    );
  }

  get items(): Array<AbTestChartDataItemModel> {
    return this.getItems(this.chart);
  }

  get prevValidationItems(): Array<AbTestChartDataItemModel> {
    return this.getItems(this.prevValidationChart);
  }

  protected getItems(
    data: Array<Array<any>> | null
  ): Array<AbTestChartDataItemModel> {
    if (!data) {
      return [];
    }

    const keys = data[0];

    return data.slice(1).flatMap((itemArray) =>
      AbTestChartDataItemModel.of(
        itemArray.reduce((result, item, index) => {
          result[keys[index]] = item;

          return result;
        }, {})
      )
    );
  }
}

export class AbTestChartDataItemModel {
  constructor(
    public day: number,
    public tg: number,
    public cg: number,
    public lower: number,
    public upper: number,
    public relEffect: number | null,
    public pValue: number | null
  ) {}

  get labelText(): string {
    return this.relEffect ? `${this.relEffect}%` : "";
  }

  static of(model: AbTestChartDataItemModel) {
    return new AbTestChartDataItemModel(
      model.day,
      model.tg,
      model.cg,
      model.lower,
      model.upper,
      model.relEffect
        ? Math.round(model.relEffect * 100) / 100
        : model.relEffect,
      model.pValue
    );
  }
}

export interface AbTestResultSummaryPeriod {
  absDiff: number | null;
  absEffect: number | null;
  relDiff: number | null;
  relEffect: number | null;
  result: AbTestPeriodValidationStatus | null;
  targetMetric: number;
}

export interface AbTestResultSummary {
  segmentName: string;
  isControlGroup: boolean;
  usersCount: number;
  prevPeriod: AbTestResultSummaryPeriod | null;
  testPeriod: AbTestResultSummaryPeriod;
}

export interface AbTestResultGroup {
  segmentName: string;
  value: number;
}

export interface AbTestResultMetric {
  metric: AbTestMetricType;
  relEffects: Array<AbTestResultGroup>;
}

export class AbTestResultModel {
  constructor(
    public configId: number,
    public metrics: Array<AbTestResultMetric>
  ) {}

  get groups(): Record<string, Record<AbTestMetricType, number>> {
    const groupMap: Record<string, Record<AbTestMetricType, number>> = {};

    this.metrics.forEach(({ metric, relEffects }) => {
      relEffects.forEach(({ segmentName, value }) => {
        if (!groupMap[segmentName]) {
          groupMap[segmentName] = Object.values(AbTestMetricType).reduce(
            (result, metric) => {
              result[metric] = 0;

              return result;
            },
            {} as Record<AbTestMetricType, number>
          );
        }

        groupMap[segmentName][metric] = value;
      });
    });

    return groupMap;
  }

  static of(model: AbTestResultModel): AbTestResultModel {
    return new AbTestResultModel(model.configId, model.metrics);
  }

  static ofArray(models: Array<AbTestResultModel>): Array<AbTestResultModel> {
    return models.map(
      (model) => new AbTestResultModel(model.configId, model.metrics)
    );
  }
}

export interface AbTestMinUsersCountRequest {
  abTestType: AbTestType;
  dayNumber: number;
  metric: AbTestMetricType;
  rules: Array<RuleModel>;
}

export interface AbTestMinUsersCountValue {
  minimalDetectableRelativeEffect: number;
  amount: number;
}

export interface AbTestMinUsersCountResponse {
  type: AbTestMinUsersCountType;
  defaultUsersCount: number;
  metricValue?: number;
  standardDeviation?: number;
  values?: Array<AbTestMinUsersCountValue>;
}

export interface AbTestMinUsersCount {
  metricValue?: number;
  standardDeviation?: number;
  minimalDetectableRelativeEffect?: number;
  amount: number;
  type: AbTestMinUsersCountType;
}
