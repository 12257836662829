























import { Component, Vue } from "vue-property-decorator";
import { Location, Route } from "vue-router";

@Component
export default class UnsavedChangesDialog extends Vue {
  get isUnsavedChangesDialogVisible(): boolean {
    return this.$store.state.unsavedChangesStore.isUnsavedChangesDialogVisible;
  }

  get nextRoute(): Route {
    return this.$store.state.unsavedChangesStore.nextRoute;
  }

  hideDialog() {
    this.$store.commit("setUnsavedChangesDialogVisibility", false);
  }

  confirm() {
    this.$store.commit("setUnsavedChanges", false);
    this.$router.push(this.nextRoute as Location);
    this.hideDialog();
  }
}
