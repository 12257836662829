




























































































import { Component, Vue, Prop, Watch, Ref } from "vue-property-decorator";
import moment from "moment";
import { delay } from "lodash";

import {
  ValidationRule,
  FilterPreviewId,
  DatePickerPresetModel,
} from "@/shared/models";
import DateFormats from "@/shared/utils/DateFormats";
import DateUtil from "@/shared/utils/DateUtil";
import { VueAutocomplete } from "@/shared/types/ExtendedVueType";
import ValidUtil from "@/shared/utils/ValidUtil";

@Component
export default class DatesPicker extends Vue {
  @Prop() value!: Array<string>;
  @Prop() label?: string;
  @Prop({ default: false }) readonly!: boolean;
  @Prop({ default: false }) clearable!: boolean;
  @Prop({ default: () => DateUtil.yesterday() }) presetStartingDate!: string;
  @Prop({ default: "2018-01-01" }) min!: string;
  @Prop() max?: string;
  @Prop() rules?: Array<ValidationRule>;
  @Prop({ default: () => [] }) errorMessages!: Array<string>;
  @Prop() filterPreviewId?: FilterPreviewId;
  @Prop({ default: true }) required!: boolean;

  @Ref("input") input!: VueAutocomplete;
  @Ref("holder") holder!: HTMLElement;
  @Ref("pickerBody") pickerBody!: Vue;

  readonly DATES_SEPARATOR = " - ";
  localValue: Array<string> = [];
  holderElement: HTMLElement | null = null;
  localValueText = "";
  nudeLeft = 0;
  datePickerKey = 0;
  menuDates = false;
  top = true;
  eager = false;
  isPressedInsert = false;
  localValueTextBeforePressedLetter = "";

  get presets(): Array<DatePickerPresetModel> {
    return [
      new DatePickerPresetModel(
        this.$lang("presets.today"),
        DateUtil.today(),
        DateUtil.today()
      ),
      new DatePickerPresetModel(
        this.$lang("presets.yesterday"),
        DateUtil.yesterday(),
        DateUtil.yesterday()
      ),
      new DatePickerPresetModel(
        this.$lang("presets.lastSeven"),
        DateUtil.subtractDays(this.presetStartingDate, 7),
        this.presetStartingDate
      ),
      new DatePickerPresetModel(
        this.$lang("presets.lastFourteen"),
        DateUtil.subtractDays(this.presetStartingDate, 14),
        this.presetStartingDate
      ),
      new DatePickerPresetModel(
        this.$lang("presets.lastThirty"),
        DateUtil.subtractDays(this.presetStartingDate, 30),
        this.presetStartingDate
      ),
      new DatePickerPresetModel(
        this.$lang("presets.lastNinety"),
        DateUtil.subtractDays(this.presetStartingDate, 90),
        this.presetStartingDate
      ),
      new DatePickerPresetModel(
        this.$lang("presets.thisMonth"),
        DateUtil.startOfMonth(this.presetStartingDate),
        this.presetStartingDate
      ),
      new DatePickerPresetModel(
        this.$lang("presets.lastMonth"),
        DateUtil.startOfLastMonth(this.presetStartingDate),
        DateUtil.endOfLastMonth(this.presetStartingDate)
      ),
      new DatePickerPresetModel(
        this.$lang("presets.year"),
        DateUtil.subtractYears(this.presetStartingDate, 1),
        this.presetStartingDate
      ),
      new DatePickerPresetModel(
        this.$lang("presets.allTime"),
        this.min,
        this.presetStartingDate
      ),
    ].filter((it) => it.from >= this.min);
  }

  get inputRules() {
    return this.required
      ? [ValidUtil.required(this.$lang("validation.required"))]
      : [];
  }

  get clickedFilterPreviewId(): FilterPreviewId {
    return this.$store.state.reportStore.clickedFilterPreviewId;
  }

  get inputErrorMessage(): Array<string> {
    const arrayFromLocalValueText = this.localValueText
      ? this.localValueText
          .split(this.DATES_SEPARATOR)
          .filter((item: string) => !!item)
      : [];
    const hasDateGreaterThanMaxDate = arrayFromLocalValueText.some((item) =>
      moment(DateUtil.formatFromDefaultToDatePicker(item)).isAfter(this.max)
    );
    const hasDateLessThanMinDate = arrayFromLocalValueText.some((item) =>
      moment(DateUtil.formatFromDefaultToDatePicker(item)).isBefore(this.min)
    );
    const hasInvalidDate = arrayFromLocalValueText.some(
      (item) =>
        !DateUtil.isValidDate(DateUtil.formatFromDefaultToDatePicker(item))
    );

    if (this.max && hasDateGreaterThanMaxDate) {
      return [this.$lang("validation.dateGreaterThanMaxDate")];
    }

    if (this.min && hasDateLessThanMinDate) {
      return [this.$lang("validation.dateLessThanMinDate")];
    }

    if (hasInvalidDate) {
      return [this.$lang("validation.invalidDate")];
    }

    return [];
  }

  @Watch("value", { immediate: true })
  watchValue(value: Array<string>) {
    this.localValue = value;
    this.fillLocalValueText();
  }

  @Watch("clickedFilterPreviewId")
  watchPreviewClick(previewId: FilterPreviewId) {
    if (previewId !== this.filterPreviewId) {
      return;
    }

    delay(() => {
      this.input.focus();
      this.menuDates = true;
      this.$store.commit("setClickedFilterPreviewId");
    }, 100);
  }

  mounted() {
    this.holderElement = this.holder;
    this.eager = true;
  }

  handleInputMenu() {
    // After getting error messages we have problem that if we try select something then v-menu closes
    // And this code fixed this problem
    (this.$refs.menuDates as any).isActive = true;
  }

  onFocus() {
    this.input.focus();
    this.menuDates = true;

    const targetElement = this.input.$el as HTMLElement;

    // 373 is the width of the v-card
    // This case fixes the datepicker from overlapping the right side of the window on the funnel page in the filter
    if (window.innerWidth - targetElement.getBoundingClientRect().x < 373) {
      this.nudeLeft =
        window.innerWidth - targetElement.getBoundingClientRect().right;
    } else {
      this.nudeLeft = 0;
    }

    this.top =
      targetElement.getBoundingClientRect().top +
        targetElement.offsetHeight / 2 >
      window.innerHeight / 2;
  }

  handleClickByAppend() {
    if (this.menuDates) {
      this.closeMenu();
    } else {
      this.onFocus();
    }
  }

  onLocalValueTextChange(value: string) {
    if (this.inputErrorMessage.length) {
      this.$emit("input", []);
      (this.$refs.menuDates as any).save(this.localValue);

      return;
    }

    const arrayFromValue = value
      ? value.split(this.DATES_SEPARATOR).filter((date: string) => !!date)
      : [];

    if (arrayFromValue.length === 1) {
      arrayFromValue.push(arrayFromValue[0]);
      this.localValueText = arrayFromValue[0];
    } else if (
      arrayFromValue.length === 2 &&
      DateUtil.isAfter(arrayFromValue[0], arrayFromValue[1])
    ) {
      this.localValueText = `${arrayFromValue[0]} - ${arrayFromValue[0]}`;
      arrayFromValue.splice(1, 1, arrayFromValue[0]);
    }

    this.localValue = arrayFromValue.map((date: string) =>
      DateUtil.formatFromDefaultToDatePicker(date)
    );

    this.$emit("input", this.localValue);
    (this.$refs.menuDates as any).save(this.localValue);
    this.datePickerKey++;
    this.closeMenu();
  }

  clearLocalValue() {
    this.localValueText = "";
    this.localValue = [];
    this.$emit("input", this.localValue);
  }

  fillLocalValueText() {
    if (this.localValue.every((item) => item)) {
      this.localValueText = this.localValue
        .map((it) => DateUtil.format(it, DateFormats.DATES_PICKER_VIEW_FORMAT))
        .join(this.DATES_SEPARATOR);
    } else {
      this.localValueText = "";
    }
  }

  onLocalValueChange(value: Array<string>) {
    if (value && value.length === 2 && value[0] > value[1]) {
      value.sort();
    }

    this.fillLocalValueText();
    (this.$refs.menuDates as any).save(this.localValue);
    this.$emit("input", value);
    this.closeMenu();
  }

  async changeLocalValueByPreset(preset: DatePickerPresetModel) {
    this.$emit("input", preset.dates);
    (this.$refs.menuDates as any).save(preset.dates);
    await this.$nextTick();
    this.fillLocalValueText();
    this.closeMenu();
  }

  closeMenu() {
    this.menuDates = false;
    this.input?.blur();
  }

  onOutsideClick(event: PointerEvent) {
    if (this.pickerBody?.$el.contains(event.target as HTMLElement)) {
      return;
    }

    this.closeMenu();
  }

  handleClickByVTextField(event: PointerEvent) {
    event.preventDefault();

    const excludedPositions = [3, 6, 11, 12, 13, 16, 19];
    const el = event.target as HTMLInputElement;
    let pos = (el.selectionStart ?? 0) + 1;

    if (excludedPositions.includes(pos)) {
      this.$nextTick(() => {
        this.setSelectionRangeForInput(
          pos === 11 ? 9 : pos,
          pos + 1 === 12 ? 10 : pos + 1
        );
      });
    } else {
      this.$nextTick(() => {
        this.setSelectionRangeForInput(pos - 1, pos);
      });
    }
  }

  onInput(value: string) {
    if (
      this.localValueTextBeforePressedLetter &&
      /[ A-Za-z ]/.test(value) &&
      !this.isPressedInsert
    ) {
      this.$nextTick(() => {
        this.localValueText = this.localValueTextBeforePressedLetter;
        this.localValueTextBeforePressedLetter = "";
      });
    }

    if (this.isPressedInsert) {
      this.onLocalValueTextChange(this.localValueText);
      this.isPressedInsert = false;
    }
  }

  onPaste() {
    this.isPressedInsert = true;
  }

  onKeyDown(event: KeyboardEvent) {
    const key = String.fromCharCode(
      (event as any).which || (event as any).charCode || (event as any).keyCode
    );
    const el = event.target as HTMLInputElement;
    const oldValue = el.value.trim();
    const enteredChar = event.key;
    let pos = (el.selectionStart ?? 0) + 1;
    const excludedPositions = [3, 6, 11, 12, 13, 16, 19];
    const FULL_VALUE_LENGTH = 23;

    if (el.value.trim().length !== FULL_VALUE_LENGTH) {
      this.localValueText = el.value;
    } else {
      if (
        (excludedPositions.includes(pos) || pos > FULL_VALUE_LENGTH) &&
        event.key.length === 1
      ) {
        event.preventDefault();
        return;
      }

      if (event.code === "ArrowLeft" || event.code === "Backspace") {
        event.preventDefault();
        this.$nextTick(() => {
          this.setSelectionRangeForInput(pos - 2, pos - 1, event.code);
        });
      } else if (event.code === "ArrowRight" || event.code === "Delete") {
        event.preventDefault();
        this.$nextTick(() => {
          this.setSelectionRangeForInput(pos, pos + 1, event.code);
        });
      }

      if (/[ 0-9 ]/.test(key)) {
        event.preventDefault();
        const newValue = this.setCharAt(oldValue, pos, enteredChar);

        this.localValueText = newValue;
        this.$nextTick(() => {
          if (pos + 1 === 11) {
            pos = 13;
          } else if (excludedPositions.includes(pos + 1)) {
            pos++;
          }

          this.setSelectionRangeForInput(pos, pos + 1);
        });
      } else if (/[ A-Za-z ]/.test(key)) {
        let newValue = this.setCharAt(oldValue, pos, oldValue[pos - 1]);
        this.localValueTextBeforePressedLetter = this.localValueText;
        this.localValueText = newValue;
      } else {
        event.preventDefault();
      }
    }
  }

  setSelectionRangeForInput(from = 0, to = 1, keyPressCode?: string) {
    const excludedPositions = [3, 6, 11, 12, 13, 16, 19];
    const input: any = this.input.$el.querySelector("#input");
    let tempFrom = from;
    let tempTo = to;

    if (tempFrom === 23 && tempTo === 24) {
      tempFrom = 22;
      tempTo = 23;
    } else if (tempFrom === -1 && tempTo === 0) {
      tempFrom = 0;
      tempTo = 1;
    } else if (tempTo === 11) {
      tempFrom = 13;
      tempTo = 14;
    } else if (tempTo === 13) {
      tempFrom = 9;
      tempTo = 10;
    } else if (
      excludedPositions.includes(tempTo) &&
      (keyPressCode === "ArrowRight" || keyPressCode === "Delete")
    ) {
      tempFrom = tempFrom + 1;
      tempTo = tempTo + 1;
    } else if (
      excludedPositions.includes(tempTo) &&
      (keyPressCode === "ArrowLeft" || keyPressCode === "Backspace")
    ) {
      tempFrom = tempFrom - 1;
      tempTo = tempTo - 1;
    }

    input.setSelectionRange(tempFrom, tempTo);
  }

  setCharAt(oldValue: string, index: number, enteredChar: string) {
    const before = oldValue.substring(0, index - 1);
    const after = oldValue.substring(index);

    return before + enteredChar + after;
  }
}
