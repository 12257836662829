export default {
  template: {
    viewTitle: "Templates",
    name: "Name",
    description: "Description",
    createdAt: "Created at",
    report: "Report type",
    owner: "Owner",
    newTitle: "New template",
    relative: "Relative",
    saveTemplate: "Save template",
    cancelTemplate: "Cancel",
    title: {
      report_templates: "Report Templates",
      funnel_templates: "Funnel Templates",
      newFunnel: "New funnel template",
      usedDashboards: "Dashboards dependent on template: ",
    },
    actions: {
      generate: "Generate report by template",
      goToFunnel: "Go to Funnel",
      delete: "Delete template",
    },
    notification: {
      createdSuccess: `The template "{0}" was created successfully`,
      deletedSuccess: `Template "{0}" was deleted successfully`,
      route: "Go to template list",
      routeToDependentDashboardsList: "To dependent Dashboards list",
      cantDelete: "Can't delete template that attached to any dashboard",
    },
    deleteDialogHeading: `Delete template "{0}"`,
    deleteDialogContent: `Are you sure you want to delete template with name: "{0}"?`,
    usageInInaccessibleDashboards:
      "This template is still used in inaccessible dashboards",
    reportMode: "Report mode",
    multiapp: "Multiapp",
    singleapp: "Singleapp",
  },
};
