















































































































































import { Component, Vue, Watch } from "vue-property-decorator";

import DashboardChart from "@/dashboard/components/DashboardChart.vue";
import DashboardFilter from "@/dashboard/components/DashboardFilter.vue";
import ReportFilterCurrentState from "@/reports/components/ReportFilterCurrentState.vue";

import {
  DashboardPreview,
  DashboardType,
} from "@/dashboard/models/DashboardModel";
import DashboardFilterModel from "@/dashboard/models/DashboardFilterModel";
import { AppSection, FilterPreview, NotificationType } from "@/shared/models";
import { DashboardChartModel } from "@/dashboard/models/DashboardChartModel";

@Component({
  components: {
    DashboardChart,
    DashboardFilter,
    ReportFilterCurrentState,
  },
})
export default class DashboardView extends Vue {
  isFilterVisible = false;
  dashboardFilter: DashboardFilterModel = new DashboardFilterModel();
  dashboardFilterPreviews: Array<FilterPreview> = [];

  get applicationId() {
    return this.$store.state.application.applicationId;
  }

  get dashboardId(): string | undefined {
    return this.$route.params.dashboardId;
  }

  get dashboards(): Array<DashboardPreview> {
    return this.$store.state.dashboardStore.dashboardPreviews;
  }

  get dashboard() {
    return this.$store.state.dashboardStore.dashboard;
  }

  get isFilterAvailable(): boolean {
    return this.dashboard?.type === DashboardType.SINGLE_APP;
  }

  get isMultiAppMode(): boolean {
    return this.$store.getters.isMultiAppMode;
  }

  get dashboardItems(): Array<Record<string, any>> {
    return this.dashboards.reduce(
      (
        result: Array<Record<string, any>>,
        { type, name, id }: DashboardPreview
      ) =>
        !this.isMultiAppMode || type === DashboardType.MULTI_APP
          ? [...result, { text: name, value: id }]
          : result,
      []
    );
  }

  get currentDashboardId(): number | undefined {
    return this.dashboardId
      ? +this.dashboardId
      : this.dashboards.find(({ isDefault }: DashboardPreview) => isDefault)
          ?.id;
  }

  set currentDashboardId(dashboardId: number | undefined) {
    const route =
      dashboardId === undefined
        ? {
            name: "404",
          }
        : {
            name: "dashboard_view",
            params: {
              id: this.applicationId,
              dashboardId: dashboardId.toString(),
            },
          };

    this.$router.push(route);
  }

  get availableApps() {
    return this.$store.state.application.apps;
  }

  get sortedCharts(): Array<DashboardChartModel> {
    return this.dashboard
      ? [...this.dashboard.charts].sort(
          (chartA, chartB) => chartA.id - chartB.id
        )
      : [];
  }

  get isGenerateBtnDisabled(): boolean {
    return this.dashboardFilter.filters.some((it) => !it.valid);
  }

  get isDashboardPreviewsLoading(): boolean {
    return this.$store.state.dashboardStore.isDashboardPreviewsLoading;
  }

  @Watch("applicationId", { immediate: true })
  @Watch("availableApps")
  async loadDashboard() {
    if (!this.availableApps.length) {
      return;
    }

    this.$store.commit("clearDashboardPreviews");
    this.$store.commit("clearDashboard");
    this.dashboardFilter.filters = [];
    this.dashboardFilterPreviews = [];
    await this.$store.dispatch("fetchDashboardPreviews");

    if (this.currentDashboardId === undefined) {
      document.title = this.$lang("documentTitleDefault");
      return;
    }

    this.getDashboardAndCharts();
  }

  @Watch("dashboardId")
  updateDashboard() {
    this.$store.commit("clearDashboard");
    this.dashboardFilter.filters = [];
    this.dashboardFilterPreviews = [];
    this.getDashboardAndCharts();
  }

  beforeDestroy() {
    this.$store.commit("closeDashboardChartsEventSource");
    this.$store.commit("clearDashboard");
    this.$store.commit("clearDashboardCharts");
  }

  async getDashboardAndCharts() {
    if (!this.currentDashboardId) {
      return;
    }

    await this.$store.dispatch("getDashboard", this.currentDashboardId);
    this.setPageTitle();
    this.getDashboardCharts();
  }

  setPageTitle() {
    document.title = this.$lang(
      "documentTitle",
      this.$lang(`dashboard.title.${this.dashboardId ? "default" : "favorite"}`)
    );
  }

  generate() {
    this.dashboardFilterPreviews = [...this.dashboardFilter.previews];
    this.getDashboardCharts();
    this.isFilterVisible = false;
  }

  getDashboardCharts() {
    this.$store
      .dispatch("getDashboardCharts", this.dashboardFilter)
      // TODO: temp solution for deleted funnel template
      .catch(({ response }) => {
        if (response.status === 404) {
          this.$store.commit("addNotification", {
            message: {
              key: "dashboard.notification.unavailable",
              params: [response.data.msg],
            },
            type: NotificationType.ERROR,
          });
          this.$router.replace({
            name: AppSection.MULTIAPP_DASHBOARDS,
            params: {
              id: this.applicationId,
            },
          });
        } else {
          this.$store.dispatch("addError", response.message);
        }
      });
  }
}
