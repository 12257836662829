

























import { Component, Vue, Prop } from "vue-property-decorator";

import ReportFilterCurrentStateItem from "./ReportFilterCurrentStateItem.vue";
import { FilterPreview } from "@/shared/models/FilterModel";

@Component({
  components: {
    ReportFilterCurrentStateItem,
  },
})
export default class ReportFilterCurrentState extends Vue {
  @Prop() previews!: Array<FilterPreview>;
  @Prop({ default: true }) isClickable!: boolean;
  @Prop() title?: string;
}
