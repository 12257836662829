import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosResponse } from "axios";

import axios from "@/shared/plugins/axios";
import NetworkResponseModel from "@/settings/models/networks-management/NetworkResponseModel";
import NetworkRequestModel from "@/settings/models/networks-management/NetworkRequestModel";
import NetworkIntegrationResponseModel, {
  NetworkType,
} from "@/settings/models/networks-management/NetworkIntegrationResponseModel";
import NetworkIntegrationRequestModel from "../models/networks-management/NetworkIntegrationRequestModel";
import NetworkUrlRequestModel from "../models/networks-management/NetworkUrlRequestModel";
import NetworkUrlResponseModel from "../models/networks-management/NetworkUrlResponseModel";
import NetworkRequestParamResponseModel from "../models/networks-management/NetworkRequestParamResponseModel";
import NetworkRequestParamRequestModel, {
  BasicAuthenticationRequestModel,
  BodyRequestModel,
} from "../models/networks-management/NetworkRequestParamRequestModel";
import RequestSpecialParamRequestModel from "../models/networks-management/RequestSpecialParamRequestModel";
import RequestSpecialParamResponseModel from "../models/networks-management/RequestSpecialParamResponseModel";
import ParsingRuleRequestModel from "../models/networks-management/ParsingRuleRequestModel";
import ParsingRuleResponseModel from "../models/networks-management/ParsingRuleResponseModel";
import AccountGroupResponseModel from "../models/networks-management/AccountGroupResponseModel";
import AccountGroupRequestModel from "../models/networks-management/AccountGroupRequestModel";
import AccountResponseModel from "../models/networks-management/AccountResponseModel";
import AccountRequestModel from "../models/networks-management/AccountRequestModel";
import AccountParamRequestModel from "../models/networks-management/AccountParamRequestModel";
import AccountParamResponseModel from "../models/networks-management/AccountParamResponseModel";
import AccountGroupStatisticModel from "../models/networks-management/AccountGroupStatisticModel";
import AccountGroupApplicationRequestModel from "../models/networks-management/AccountGroupApplicationRequestModel";
import AccountGroupApplicationResponseModel from "../models/networks-management/AccountGroupApplicationResponseModel";
import NetRevenueResponseModel from "../models/networks-management/NetRevenueResponseModel";
import NetRevenueRequestModel from "../models/networks-management/NetRevenueRequestModel";
import HistoricalRevenueNetworkResponseModel from "../models/networks-management/HistoricalRevenueNetworkResponseModel";
import HistoricalRevenueNetworkRequestModel from "../models/networks-management/HistoricalRevenueNetworkRequestModel";
import NetworkIntegrationShortResponseModel from "../models/networks-management/NetworkIntegrationShortResponseModel";
import { NetworkIntegrationFilterType } from "../models/networks-management/NetworkIntegrationFilterType";

@Module
export default class LineItemStore extends VuexModule {
  networks: Array<NetworkResponseModel> = [];
  networkIntegrations: Array<NetworkIntegrationResponseModel> = [];
  accountGroupApplications: Array<AccountGroupApplicationResponseModel> = [];
  networkRequests: Array<NetworkUrlResponseModel> = [];
  requestParams: Array<NetworkRequestParamResponseModel> = [];
  requestSpecialParams: Array<RequestSpecialParamResponseModel> = [];
  accountsGroups: Array<AccountGroupResponseModel> = [];
  accounts: Array<AccountResponseModel> = [];
  accountsParams: Array<AccountParamResponseModel> = [];
  accountGroupStatistics: Array<AccountGroupStatisticModel> = [];
  netRevenues: Array<NetRevenueResponseModel> = [];
  historicalRevenueNetwork: Array<HistoricalRevenueNetworkResponseModel> = [];
  shortNetworkIntegrations: Array<NetworkIntegrationShortResponseModel> = [];
  childNetworks: Array<NetworkIntegrationShortResponseModel> = [];
  network: NetworkResponseModel | null = null;
  requestParam: NetworkRequestParamResponseModel | null = null;
  requestSpecialParam: RequestSpecialParamResponseModel | null = null;
  parsingRules: ParsingRuleResponseModel | null = null;
  accountGroup: AccountGroupResponseModel | null = null;
  isSavingInProgress = false;
  isCopyInProgress = false;
  isLoading = false;
  networkIntegrationsIsLoading = false;
  networkRequestsIsLoading = false;
  requestParamsIsLoading = false;
  requestSpecialParamsIsLoading = false;
  parsingRulesIsLoading = false;
  accountGroupApplicationsIsLoading = false;
  accountsGroupsIsLoading = false;
  accountGroupIsLoading = false;
  accountsIsLoading = false;
  accountsParamsIsLoading = false;
  accountGroupStatisticsIsLoading = false;
  netRevenuesIsLoading = false;
  historicalRevenueNetworkIsLoading = false;
  childNetworksIsLoading = false;
  savingIntegrationInProgress = false;
  deletingIntegrationInProgress = false;
  savingApplicationBindingsInProgress = false;
  savingNetworkRequestInProgress = false;
  savingRequestParamInProgress = false;
  savingRequestSpecialParamInProgress = false;
  savingParsingRulesInProgress = false;
  savingAccountGroupInProgress = false;
  savingAccountInProgress = false;
  savingAccountParamInProgress = false;
  savingNetRevenuesInProgress = false;
  savingHistoricalRevenueNetworkInProgress = false;
  savingChildNetworkInProgress = false;
  networkNameIsUnique = true;
  accountGroupNameIsUnique = true;
  networkIntegrationResyncLoading = false;
  accountGroupResyncLoading = false;
  isApplicationIdUnique = true;
  deletingChildNetwork: number | null = null;

  @Mutation
  setNetworksLoading(payload: boolean) {
    this.isLoading = payload;
  }

  @Mutation
  setNetworkIntegrationsLoading(payload: boolean) {
    this.networkIntegrationsIsLoading = payload;
  }

  @Mutation
  setNetworkRequestsLoading(payload: boolean) {
    this.networkRequestsIsLoading = payload;
  }

  @Mutation
  setRequestParamsLoading(payload: boolean) {
    this.requestParamsIsLoading = payload;
  }

  @Mutation
  setRequestSpecialParamsLoading(payload: boolean) {
    this.requestSpecialParamsIsLoading = payload;
  }

  @Mutation
  setParsingRulesLoading(payload: boolean) {
    this.parsingRulesIsLoading = payload;
  }

  @Mutation
  setAccountGroupApplicationsLoading(payload: boolean) {
    this.accountGroupApplicationsIsLoading = payload;
  }

  @Mutation
  setAccountsGroupsIsLoading(payload: boolean) {
    this.accountsGroupsIsLoading = payload;
  }

  @Mutation
  setAccountGroupIsLoading(payload: boolean) {
    this.accountGroupIsLoading = payload;
  }

  @Mutation
  setAccountsLoading(payload: boolean) {
    this.accountsIsLoading = payload;
  }

  @Mutation
  setAccountsParamsLoading(payload: boolean) {
    this.accountsParamsIsLoading = payload;
  }

  @Mutation
  setAccountGroupStatisticsLoading(payload: boolean) {
    this.accountGroupStatisticsIsLoading = payload;
  }

  @Mutation
  setNetRevenuesLoading(payload: boolean) {
    this.netRevenuesIsLoading = payload;
  }

  @Mutation
  setHistoricalRevenueNetworkLoading(payload: boolean) {
    this.historicalRevenueNetworkIsLoading = payload;
  }

  @Mutation
  setChildNetworksLoading(payload: boolean) {
    this.childNetworksIsLoading = payload;
  }

  @Mutation
  setSavingInProgress(payload: boolean) {
    this.isSavingInProgress = payload;
  }

  @Mutation
  setCopyInProgress(payload: boolean) {
    this.isCopyInProgress = payload;
  }

  @Mutation
  setSavingIntegrationInProgress(payload: boolean) {
    this.savingIntegrationInProgress = payload;
  }

  @Mutation
  setDeletingIntegrationInProgress(payload: boolean) {
    this.deletingIntegrationInProgress = payload;
  }

  @Mutation
  setSavingApplicationBindingsInProgress(payload: boolean) {
    this.savingApplicationBindingsInProgress = payload;
  }

  @Mutation
  setSavingNetworkRequestInProgress(payload: boolean) {
    this.savingNetworkRequestInProgress = payload;
  }

  @Mutation
  setSavingRequestParamInProgress(payload: boolean) {
    this.savingRequestParamInProgress = payload;
  }

  @Mutation
  setSavingParsingRulesInProgress(payload: boolean) {
    this.savingParsingRulesInProgress = payload;
  }

  @Mutation
  setSavingRequestSpecialParamInProgress(payload: boolean) {
    this.savingRequestSpecialParamInProgress = payload;
  }

  @Mutation
  setSavingAccountGroupInProgress(payload: boolean) {
    this.savingAccountGroupInProgress = payload;
  }

  @Mutation
  setSavingAccountInProgress(payload: boolean) {
    this.savingAccountInProgress = payload;
  }

  @Mutation
  setSavingAccountParamInProgress(payload: boolean) {
    this.savingAccountParamInProgress = payload;
  }

  @Mutation
  setSavingNetRevenuesInProgress(payload: boolean) {
    this.savingNetRevenuesInProgress = payload;
  }

  @Mutation
  setSavingHistoricalRevenueNetworkInProgress(payload: boolean) {
    this.savingHistoricalRevenueNetworkInProgress = payload;
  }

  @Mutation
  setSavingChildNetworkInProgress(payload: boolean) {
    this.savingChildNetworkInProgress = payload;
  }

  @Mutation
  setNetworks(payload: Array<NetworkResponseModel>) {
    this.networks = NetworkResponseModel.ofArray(payload);
  }

  @Mutation
  setNetwork(payload: NetworkResponseModel) {
    this.network = NetworkResponseModel.ofSource(payload);
  }

  @Mutation
  updateNetworksAfterDelete(id: number) {
    this.networks = this.networks.filter((network) => network.id !== id);
  }

  @Mutation
  setNetworkNameIsUnique(payload: boolean) {
    this.networkNameIsUnique = payload;
  }

  @Mutation
  setNetworkIntegrations(payload: Array<NetworkIntegrationResponseModel>) {
    this.networkIntegrations = NetworkIntegrationResponseModel.ofArray(payload);
  }

  @Mutation
  updateNetworkIntegrations(payload: NetworkIntegrationResponseModel) {
    const tempNetworkIntegrations: any = [...this.networkIntegrations];
    const foundIndex = tempNetworkIntegrations.findIndex(
      (networkIntegration: any) => networkIntegration.id === payload.id
    );

    if (foundIndex >= 0) {
      tempNetworkIntegrations[foundIndex] = { ...payload };
    }

    this.networkIntegrations = NetworkIntegrationResponseModel.ofArray(
      tempNetworkIntegrations
    );
  }

  @Mutation
  updateNetworkIntegrationsAfterDelete(payload: number) {
    this.networkIntegrations = this.networkIntegrations.filter(
      ({ id }) => id !== payload
    );
  }

  @Mutation
  setAccountGroupApplications(
    payload: Array<AccountGroupApplicationResponseModel>
  ) {
    this.accountGroupApplications =
      AccountGroupApplicationResponseModel.ofArray(payload);
  }

  @Mutation
  updateAccountGroupApplications(
    payload: AccountGroupApplicationResponseModel
  ) {
    this.accountGroupApplications =
      AccountGroupApplicationResponseModel.ofArray([
        ...this.accountGroupApplications,
        payload,
      ]);
  }

  @Mutation
  updateAccountGroupApplicationsAfterUpdate(
    payload: AccountGroupApplicationResponseModel
  ) {
    const foundIndex = this.accountGroupApplications.findIndex(
      (account) => account.id === payload.id
    );

    if (foundIndex >= 0) {
      this.accountGroupApplications.splice(
        foundIndex,
        1,
        AccountGroupApplicationResponseModel.of(payload)
      );
    }
  }

  @Mutation
  updateAccountGroupApplicationsAfterDeleting(id: number) {
    this.accountGroupApplications = this.accountGroupApplications.filter(
      (accountGroupApplication) => accountGroupApplication.id !== id
    );
  }

  @Mutation
  setApplicationIdUniqueness(payload: boolean) {
    this.isApplicationIdUnique = payload;
  }

  @Mutation
  setNetworkRequests(payload: Array<NetworkUrlResponseModel>) {
    this.networkRequests = NetworkUrlResponseModel.ofArray(payload);
  }

  @Mutation
  updateNetworkRequests(payload: NetworkUrlResponseModel) {
    this.networkRequests = NetworkUrlResponseModel.ofArray([
      ...this.networkRequests,
      payload,
    ]);
  }

  @Mutation
  updateNetworkRequestsAfterUpdate(payload: NetworkUrlResponseModel) {
    const foundIndex = this.networkRequests.findIndex(
      (account) => account.id === payload.id
    );

    if (foundIndex >= 0) {
      this.networkRequests.splice(
        foundIndex,
        1,
        NetworkUrlResponseModel.ofSource(payload)
      );
    }
  }

  @Mutation
  setRequestParams({
    payload,
    isCustomDataRequests,
  }: {
    payload: Array<NetworkRequestParamResponseModel>;
    isCustomDataRequests: boolean;
  }) {
    if (isCustomDataRequests) {
      this.requestParams.push(
        ...NetworkRequestParamResponseModel.ofArray(payload)
      );
    } else {
      this.requestParams = NetworkRequestParamResponseModel.ofArray(payload);
    }
  }

  @Mutation
  setRequestParam(payload: NetworkRequestParamResponseModel) {
    this.requestParam = NetworkRequestParamResponseModel.ofSource(payload);
  }

  @Mutation
  updateRequestParamsAfterAdding(payload: NetworkRequestParamResponseModel) {
    this.requestParams = NetworkRequestParamResponseModel.ofArray([
      ...this.requestParams,
      payload,
    ]);
  }

  @Mutation
  updateRequestParamsAfterDeleting(id: number) {
    this.requestParams = this.requestParams.filter(
      (requestParam) => requestParam.id !== id
    );
  }

  @Mutation
  updateRequestParamsAfterSave(payload: NetworkRequestParamResponseModel) {
    const tempRequestParams = [...this.requestParams];
    const foundIndex = tempRequestParams.findIndex(
      (tempRequestParam) => tempRequestParam.id === payload.id
    );

    if (foundIndex >= 0) {
      tempRequestParams[foundIndex] = { ...payload };
    }

    this.requestParams =
      NetworkRequestParamResponseModel.ofArray(tempRequestParams);
  }

  @Mutation
  clearRequestParams() {
    this.requestParams = [];
  }

  @Mutation
  setRequestSpecialParams(payload: Array<RequestSpecialParamResponseModel>) {
    this.requestSpecialParams =
      RequestSpecialParamResponseModel.ofArray(payload);
  }

  @Mutation
  setRequestSpecialParam(payload: RequestSpecialParamResponseModel) {
    this.requestSpecialParam =
      RequestSpecialParamResponseModel.ofSource(payload);
  }

  @Mutation
  updateRequestSpecialParamsAfterAdding(
    payload: RequestSpecialParamResponseModel
  ) {
    this.requestSpecialParams = RequestSpecialParamResponseModel.ofArray([
      ...this.requestSpecialParams,
      payload,
    ]);
  }

  @Mutation
  updateRequestSpecialParamsAfterSave(
    payload: RequestSpecialParamResponseModel
  ) {
    const tempRequestSpecialParams = [...this.requestSpecialParams];
    const foundIndex = tempRequestSpecialParams.findIndex(
      (requestSpecialParam) => requestSpecialParam.id === payload.id
    );

    if (foundIndex >= 0) {
      tempRequestSpecialParams[foundIndex] = { ...payload };
    }

    this.requestSpecialParams = RequestSpecialParamResponseModel.ofArray(
      tempRequestSpecialParams
    );
  }

  @Mutation
  updateRequestSpecialParamsAfterDeleting(id: number) {
    this.requestSpecialParams = this.requestSpecialParams.filter(
      (requestSpecialParam) => requestSpecialParam.id !== id
    );
  }

  @Mutation
  setParsingRules(payload: ParsingRuleResponseModel | null) {
    this.parsingRules = payload
      ? ParsingRuleResponseModel.ofSource(payload)
      : null;
  }

  @Mutation
  setAccountsGroups(payload: Array<AccountGroupResponseModel>) {
    this.accountsGroups = AccountGroupResponseModel.ofArray(payload);
  }

  @Mutation
  setAccountGroup(payload: AccountGroupResponseModel) {
    this.accountGroup = AccountGroupResponseModel.of(payload);
  }

  @Mutation
  updateAccountsGroups(payload: AccountGroupResponseModel) {
    this.accountsGroups = AccountGroupResponseModel.ofArray([
      ...this.accountsGroups,
      payload,
    ]);
  }

  @Mutation
  updateAccountsGroupsAfterUpdate(payload: AccountGroupResponseModel) {
    const foundIndex = this.accountsGroups.findIndex(
      (account) => account.id === payload.id
    );

    if (foundIndex >= 0) {
      this.accountsGroups.splice(
        foundIndex,
        1,
        AccountGroupResponseModel.of(payload)
      );
    }
  }

  @Mutation
  updateAccountGroupAfterUpdate(payload: AccountGroupResponseModel) {
    this.accountGroup = AccountGroupResponseModel.of(payload);
  }

  @Mutation
  updateAccountsGroupsAfterDeletion(id: number) {
    this.accountsGroups = this.accountsGroups.filter((item) => item.id !== id);
  }

  @Mutation
  setAccountGroupNameIsUnique(payload: boolean) {
    this.accountGroupNameIsUnique = payload;
  }

  @Mutation
  setAccounts(payload: Array<AccountResponseModel>) {
    this.accounts = AccountResponseModel.ofArray(payload);
  }

  @Mutation
  updateAccounts(payload: AccountResponseModel) {
    this.accounts = AccountResponseModel.ofArray([...this.accounts, payload]);
  }

  @Mutation
  updateAccountsAfterUpdate(payload: AccountResponseModel) {
    const foundIndex = this.accounts.findIndex(
      (account) => account.id === payload.id
    );

    if (foundIndex >= 0) {
      this.accounts.splice(foundIndex, 1, AccountResponseModel.of(payload));
    }
  }

  @Mutation
  updateAccountsAfterDeletion(id: number) {
    this.accounts = this.accounts.filter((item) => item.id !== id);
  }

  @Mutation
  setAccountsParams(payload: Array<AccountParamResponseModel>) {
    this.accountsParams = AccountParamResponseModel.ofArray(payload);
  }

  @Mutation
  updateAccountsParamsAfterDeletion(id: number) {
    this.accountsParams = this.accountsParams.filter((item) => item.id !== id);
  }

  @Mutation
  setAccountGroupStatistics(payload: Array<AccountGroupStatisticModel>) {
    this.accountGroupStatistics = AccountGroupStatisticModel.ofArray(payload);
  }

  @Mutation
  setNetRevenues(payload: Array<NetRevenueResponseModel>) {
    this.netRevenues = NetRevenueResponseModel.ofArray(payload);
  }

  @Mutation
  setHistoricalRevenueNetwork(
    payload: Array<HistoricalRevenueNetworkResponseModel>
  ) {
    this.historicalRevenueNetwork =
      HistoricalRevenueNetworkResponseModel.ofArray(payload);
  }

  @Mutation
  updateHistoricalRevenueNetworkAfterCreate(
    payload: HistoricalRevenueNetworkResponseModel
  ) {
    this.historicalRevenueNetwork.push(
      HistoricalRevenueNetworkResponseModel.of(payload)
    );
  }

  @Mutation
  updateHistoricalRevenueNetworkAfterUpdate(
    payload: HistoricalRevenueNetworkResponseModel
  ) {
    const foundIndex = this.historicalRevenueNetwork.findIndex(
      (item) => item.id === payload.id
    );

    if (foundIndex >= 0) {
      this.historicalRevenueNetwork.splice(
        foundIndex,
        1,
        HistoricalRevenueNetworkResponseModel.of(payload)
      );
    }
  }

  @Mutation
  updateHistoricalRevenueNetworkAfterDeletion(id: number) {
    this.historicalRevenueNetwork = this.historicalRevenueNetwork.filter(
      (item) => item.id !== id
    );
  }

  @Mutation
  setShortNetworkIntegrations(
    payload: Array<NetworkIntegrationShortResponseModel>
  ) {
    this.shortNetworkIntegrations =
      NetworkIntegrationShortResponseModel.ofArray(payload);
  }

  @Mutation
  setChildNetworks(payload: Array<NetworkIntegrationShortResponseModel>) {
    this.childNetworks = NetworkIntegrationShortResponseModel.ofArray(payload);
  }

  @Mutation
  clearAccountsParams() {
    this.accountsParams = [];
  }

  @Mutation
  clearNetworksManagementData() {
    this.networkIntegrations = [];
    this.accountGroupApplications = [];
    this.networkRequests = [];
    this.network = null;
  }

  @Mutation
  clearAllInformationForNetworkIntegration() {
    this.accountGroupApplications = [];
    this.networkRequests = [];
    this.requestParams = [];
    this.requestSpecialParams = [];
    this.parsingRules = null;
  }

  @Mutation
  setNetworkIntegrationResyncLoading(payload: boolean) {
    this.networkIntegrationResyncLoading = payload;
  }

  @Mutation
  setAccountGroupResyncLoading(payload: boolean) {
    this.accountGroupResyncLoading = payload;
  }

  @Mutation
  setDeletingChildNetwork(payload: number | null) {
    this.deletingChildNetwork = payload;
  }

  @Action({ commit: "setNetworks" })
  async loadNetworks() {
    this.context.commit("setNetworksLoading", true);
    return axios
      .get("/api/third-party/developer/networks")
      .then((result: AxiosResponse<Array<NetworkResponseModel>>) => result.data)
      .finally(() => {
        this.context.commit("setNetworksLoading", false);
      });
  }

  @Action({ commit: "setNetwork" })
  async loadNetworkById(id: string) {
    this.context.commit("setNetworksLoading", true);

    return axios
      .get(`/api/third-party/developer/networks/${id}`)
      .then((result: AxiosResponse<NetworkResponseModel>) => result.data)
      .finally(() => {
        this.context.commit("setNetworksLoading", false);
      });
  }

  @Action({ commit: "updateNetworksAfterDelete" })
  async deleteNetwork(id: number) {
    this.context.commit("setNetworksLoading", true);

    return axios
      .delete(`/api/third-party/developer/networks/${id}`)
      .then(() => {
        this.context.commit("addNotification", {
          message: {
            key: "appAdmin.networksManagement.notification.deletedSuccess",
          },
        });

        return id;
      })
      .finally(() => {
        this.context.commit("setNetworksLoading", false);
      });
  }

  @Action({ commit: "setNetwork" })
  async createNetwork(payload: NetworkRequestModel) {
    this.context.commit("setSavingInProgress", true);
    return axios
      .post("/api/third-party/developer/networks", payload)
      .then((result: AxiosResponse<NetworkResponseModel>) => {
        this.context.commit("addNotification", {
          message: {
            key: "appAdmin.networksManagement.notification.createdSuccess",
          },
        });
        return result.data;
      })
      .finally(() => {
        this.context.commit("setSavingInProgress", false);
      });
  }

  @Action({ commit: "setNetwork" })
  async copyNetwork({
    id,
    payload,
  }: {
    id: number;
    payload: NetworkRequestModel;
  }) {
    this.context.commit("setCopyInProgress", true);

    return axios
      .post(`/api/third-party/developer/networks/copy/${id}`, payload)
      .then((result: AxiosResponse<NetworkResponseModel>) => {
        this.context.commit("addNotification", {
          message: {
            key: "appAdmin.networksManagement.notification.createdSuccess",
          },
        });
        return result.data;
      })
      .finally(() => {
        this.context.commit("setCopyInProgress", false);
      });
  }

  @Action({ commit: "setNetworkNameIsUnique" })
  async checkNetworkNameUniqueness(networkName: string) {
    if (!networkName) {
      return true;
    }

    return axios
      .post("/api/third-party/developer/networks/unique-network-name", {
        networkName,
      })
      .then(
        (result: AxiosResponse<{ networkName: string; unique: boolean }>) =>
          result.data.unique
      );
  }

  @Action({ commit: "setNetworkIntegrations" })
  async loadNetworkIntegrationsByNetworkId(networkId: string) {
    this.context.commit("setNetworkIntegrationsLoading", true);

    return axios
      .get(
        `/api/third-party/developer/network-integrations/network-id/${networkId}`
      )
      .then(
        (result: AxiosResponse<Array<NetworkIntegrationResponseModel>>) =>
          result.data
      )
      .finally(() => {
        this.context.commit("setNetworkIntegrationsLoading", false);
      });
  }

  @Action
  async addIntegration(payload: NetworkIntegrationRequestModel) {
    this.context.commit("setSavingIntegrationInProgress", true);

    return axios
      .post("/api/third-party/developer/network-integrations", payload)
      .finally(() => {
        this.context.commit("setSavingIntegrationInProgress", false);
      });
  }

  @Action({ commit: "updateNetworkIntegrations" })
  async updateIntegration({
    id,
    payload,
  }: {
    id: number;
    payload: NetworkIntegrationRequestModel;
  }) {
    this.context.commit("setSavingIntegrationInProgress", true);

    return axios
      .patch(`/api/third-party/developer/network-integrations/${id}`, payload)
      .then(
        (result: AxiosResponse<NetworkIntegrationResponseModel>) => result.data
      )
      .finally(() => {
        this.context.commit("setSavingIntegrationInProgress", false);
      });
  }

  @Action({ commit: "updateNetworkIntegrationsAfterDelete" })
  async deleteIntegration(id: number) {
    this.context.commit("setDeletingIntegrationInProgress", true);

    return axios
      .delete(`/api/third-party/developer/network-integrations/${id}`)
      .then(() => {
        this.context.commit("addNotification", {
          message: {
            key: "appAdmin.networksManagement.notification.deletedSuccess",
          },
        });

        return id;
      })
      .finally(() => {
        this.context.commit("setDeletingIntegrationInProgress", false);
      });
  }

  @Action({ commit: "setAccountGroupApplications" })
  async loadAccountGroupApplicationsByAccountGroupId(accountGroupId: string) {
    this.context.commit("setAccountGroupApplicationsLoading", true);
    return axios
      .get(
        `/api/third-party/developer/network-integration-applications/account-group-id/${accountGroupId}`
      )
      .then(
        (result: AxiosResponse<Array<AccountGroupApplicationResponseModel>>) =>
          result.data
      )
      .finally(() => {
        this.context.commit("setAccountGroupApplicationsLoading", false);
      });
  }

  @Action({ commit: "updateAccountGroupApplications" })
  async createAccountGroupApplication(
    payload: AccountGroupApplicationRequestModel
  ) {
    this.context.commit("setSavingApplicationBindingsInProgress", true);
    const createdApplication =
      AccountGroupApplicationRequestModel.ofRequest(payload);

    return axios
      .post(
        `/api/third-party/developer/app/${payload.applicationId}/network-integration-applications`,
        createdApplication
      )
      .then(
        (result: AxiosResponse<AccountGroupApplicationResponseModel>) =>
          result.data
      )
      .finally(() => {
        this.context.commit("setSavingApplicationBindingsInProgress", false);
      });
  }

  @Action
  async updateAccountGroupApplication({
    id,
    payload,
    isTransfer,
  }: {
    id: number;
    payload: AccountGroupApplicationRequestModel;
    isTransfer?: boolean;
  }) {
    this.context.commit("setSavingApplicationBindingsInProgress", true);
    const updatedApplication =
      AccountGroupApplicationRequestModel.ofRequest(payload);

    return axios
      .patch(
        `/api/third-party/developer/network-integration-applications/${id}`,
        updatedApplication
      )
      .then((result: AxiosResponse<AccountGroupApplicationResponseModel>) => {
        if (isTransfer) {
          this.context.commit(
            "updateAccountGroupApplicationsAfterDeleting",
            id
          );
        } else {
          this.context.commit(
            "updateAccountGroupApplicationsAfterUpdate",
            result.data
          );
        }
      })
      .finally(() => {
        this.context.commit("setSavingApplicationBindingsInProgress", false);
      });
  }

  @Action({ commit: "updateAccountGroupApplicationsAfterDeleting" })
  async deleteAccountGroupApplication(id: number) {
    this.context.commit("setAccountGroupApplicationsLoading", true);

    return axios
      .delete(
        `/api/third-party/developer/network-integration-applications/${id}`
      )
      .then(() => id)
      .finally(() => {
        this.context.commit("setAccountGroupApplicationsLoading", false);
      });
  }

  @Action({ commit: "setApplicationIdUniqueness" })
  checkApplicationIdUniqueness(payload: {
    applicationId: string;
    accountGroupId: number;
  }) {
    if (!payload.applicationId) {
      return true;
    }

    return axios
      .post(
        `/api/third-party/developer/network-integration-applications/unique-application-id`,
        payload
      )
      .then((result: AxiosResponse<{ unique: boolean }>) => result.data.unique);
  }

  @Action({ commit: "setNetworkRequests" })
  async loadNetworkRequestsByNetworkIntegrationId(
    networkIntegrationId: string
  ) {
    this.context.commit("setNetworkRequestsLoading", true);

    return axios
      .get(
        `/api/third-party/developer/network-requests/network-integration-id/${networkIntegrationId}`
      )
      .then(
        (result: AxiosResponse<Array<NetworkUrlResponseModel>>) => result.data
      )
      .finally(() => {
        this.context.commit("setNetworkRequestsLoading", false);
      });
  }

  @Action({ commit: "updateNetworkRequests" })
  async createNetworkRequest(payload: NetworkUrlRequestModel) {
    this.context.commit("setSavingNetworkRequestInProgress", true);
    const createdNetworkRequest = NetworkUrlRequestModel.ofRequest(payload);

    return axios
      .post(
        "/api/third-party/developer/network-requests",
        createdNetworkRequest
      )
      .then((result: AxiosResponse<NetworkUrlResponseModel>) => result.data)
      .finally(() => {
        this.context.commit("setSavingNetworkRequestInProgress", false);
      });
  }

  @Action({ commit: "updateNetworkRequestsAfterUpdate" })
  async updateNetworkRequest({
    payload,
    id,
  }: {
    payload: NetworkUrlRequestModel;
    id: number;
  }) {
    this.context.commit("setSavingNetworkRequestInProgress", true);
    const updatedNetworkRequest = NetworkUrlRequestModel.ofRequest(payload);

    return axios
      .patch(
        `/api/third-party/developer/network-requests/${id}`,
        updatedNetworkRequest
      )
      .then((result: AxiosResponse<NetworkUrlResponseModel>) => result.data)
      .finally(() => {
        this.context.commit("setSavingNetworkRequestInProgress", false);
      });
  }

  @Action({ commit: "setRequestParams" })
  async loadRequestParamsByRequestId({
    id,
    isCustomDataRequests,
  }: {
    id: string;
    isCustomDataRequests?: boolean;
  }) {
    this.context.commit("setRequestParamsLoading", true);

    return axios
      .get(`/api/third-party/developer/request-params/request-id/${id}`)
      .then(
        (result: AxiosResponse<Array<NetworkRequestParamResponseModel>>) => ({
          payload: result.data,
          isCustomDataRequests,
        })
      )
      .finally(() => {
        this.context.commit("setRequestParamsLoading", false);
      });
  }

  @Action({ commit: "setRequestParam" })
  async loadRequestParamById(id: string) {
    return axios
      .get(`/api/third-party/developer/request-params/${id}`)
      .then(
        (result: AxiosResponse<NetworkRequestParamResponseModel>) => result.data
      );
  }

  @Action({ commit: "updateRequestParamsAfterAdding" })
  async addRequestParam(payload: NetworkRequestParamRequestModel) {
    this.context.commit("setSavingRequestParamInProgress", true);

    return axios
      .post("/api/third-party/developer/request-params", payload)
      .then(
        (result: AxiosResponse<NetworkRequestParamResponseModel>) => result.data
      )
      .finally(() => {
        this.context.commit("setSavingRequestParamInProgress", false);
      });
  }

  @Action({ commit: "updateRequestParamsAfterAdding" })
  async addRequestParamBasicAuthentication(
    payload: BasicAuthenticationRequestModel
  ) {
    this.context.commit("setSavingRequestParamInProgress", true);

    return axios
      .post(
        "/api/third-party/developer/request-params/basic-authentication",
        payload
      )
      .then((result: AxiosResponse<any>) => result.data)
      .finally(() => {
        this.context.commit("setSavingRequestParamInProgress", false);
      });
  }

  @Action({ commit: "updateRequestParamsAfterAdding" })
  async addRequestParamBody(payload: BodyRequestModel) {
    this.context.commit("setSavingRequestParamInProgress", true);

    return axios
      .post("/api/third-party/developer/request-params/body", payload)
      .then(
        (result: AxiosResponse<NetworkRequestParamResponseModel>) => result.data
      )
      .finally(() => {
        this.context.commit("setSavingRequestParamInProgress", false);
      });
  }

  @Action({ commit: "updateRequestParamsAfterSave" })
  async updateRequestParam({
    id,
    payload,
  }: {
    id: number;
    payload: NetworkRequestParamRequestModel;
  }) {
    this.context.commit("setSavingRequestSpecialParamInProgress", true);

    return axios
      .patch(`/api/third-party/developer/request-params/${id}`, payload)
      .then(
        (result: AxiosResponse<NetworkRequestParamResponseModel>) => result.data
      )
      .finally(() => {
        this.context.commit("setSavingRequestSpecialParamInProgress", false);
      });
  }

  @Action({ commit: "updateRequestParamsAfterSave" })
  async updateRequestParamBody({
    id,
    payload,
  }: {
    id: number;
    payload: NetworkRequestParamRequestModel;
  }) {
    this.context.commit("setSavingRequestSpecialParamInProgress", true);

    return axios
      .patch(`/api/third-party/developer/request-params/body/${id}`, payload)
      .then(
        (result: AxiosResponse<NetworkRequestParamResponseModel>) => result.data
      )
      .finally(() => {
        this.context.commit("setSavingRequestSpecialParamInProgress", false);
      });
  }

  @Action({ commit: "updateRequestParamsAfterDeleting" })
  async deleteRequestParam(id: number) {
    this.context.commit("setRequestParamsLoading", true);

    return axios
      .delete(`/api/third-party/developer/request-params/${id}`)
      .then(() => id)
      .finally(() => {
        this.context.commit("setRequestParamsLoading", false);
      });
  }

  @Action({ commit: "setRequestSpecialParams" })
  async loadRequestSpecialParamsByRequestId(id: string) {
    this.context.commit("setRequestSpecialParamsLoading", true);

    return axios
      .get(`/api/third-party/developer/request-special-params/request-id/${id}`)
      .then(
        (result: AxiosResponse<Array<RequestSpecialParamResponseModel>>) =>
          result.data
      )
      .finally(() => {
        this.context.commit("setRequestSpecialParamsLoading", false);
      });
  }

  @Action({ commit: "setRequestSpecialParam" })
  async loadRequestSpecialParamById(id: string) {
    return axios
      .get(`/api/third-party/developer/request-special-params/${id}`)
      .then(
        (result: AxiosResponse<RequestSpecialParamResponseModel>) => result.data
      );
  }

  @Action({ commit: "updateRequestSpecialParamsAfterAdding" })
  async addRequestSpecialParam(payload: RequestSpecialParamRequestModel) {
    this.context.commit("setSavingRequestSpecialParamInProgress", true);

    return axios
      .post("/api/third-party/developer/request-special-params", payload)
      .then(
        (result: AxiosResponse<RequestSpecialParamResponseModel>) => result.data
      )
      .finally(() => {
        this.context.commit("setSavingRequestSpecialParamInProgress", false);
      });
  }

  @Action({ commit: "updateRequestSpecialParamsAfterSave" })
  async updateRequestSpecialParam({
    id,
    payload,
  }: {
    id: number;
    payload: RequestSpecialParamRequestModel;
  }) {
    this.context.commit("setSavingRequestSpecialParamInProgress", true);

    return axios
      .patch(`/api/third-party/developer/request-special-params/${id}`, payload)
      .then(
        (result: AxiosResponse<RequestSpecialParamResponseModel>) => result.data
      )
      .finally(() => {
        this.context.commit("setSavingRequestSpecialParamInProgress", false);
      });
  }

  @Action({ commit: "updateRequestSpecialParamsAfterDeleting" })
  async deleteRequestSpecialParam(id: number) {
    this.context.commit("setRequestSpecialParamsLoading", true);

    return axios
      .delete(`/api/third-party/developer/request-special-params/${id}`)
      .then(() => id)
      .finally(() => {
        this.context.commit("setRequestSpecialParamsLoading", false);
      });
  }

  @Action({ commit: "setParsingRules" })
  async loadParsingRulesByNetworkIntegrationId(networkIntegrationId: string) {
    this.context.commit("setParsingRulesLoading", true);

    return axios
      .get(
        `/api/third-party/developer/parsing-rules/network-integration-id/${networkIntegrationId}`
      )
      .then((result: AxiosResponse<ParsingRuleResponseModel>) => result.data)
      .finally(() => {
        this.context.commit("setParsingRulesLoading", false);
      });
  }

  @Action({ commit: "setParsingRules" })
  async createParsingRules(payload: ParsingRuleRequestModel) {
    this.context.commit("setSavingParsingRulesInProgress", true);
    const createdParsingRules = ParsingRuleRequestModel.ofRequest(payload);

    return axios
      .post("/api/third-party/developer/parsing-rules", createdParsingRules)
      .then((result: AxiosResponse<ParsingRuleResponseModel>) => {
        this.context.commit("addNotification", {
          message: {
            key: "appAdmin.networksManagement.notification.createdSuccess",
          },
        });

        return result.data;
      })
      .finally(() => {
        this.context.commit("setSavingParsingRulesInProgress", false);
      });
  }

  @Action
  async updateParsingRules({
    id,
    payload,
  }: {
    id: number;
    payload: ParsingRuleRequestModel;
  }) {
    this.context.commit("setSavingParsingRulesInProgress", true);
    const updatedParsingRules = ParsingRuleRequestModel.ofRequest(payload);

    return axios
      .put(
        `/api/third-party/developer/parsing-rules/${id}`,
        updatedParsingRules
      )
      .then((result: AxiosResponse<any>) => {
        this.context.commit("addNotification", {
          message: {
            key: "appAdmin.networksManagement.notification.updatedSuccess",
          },
        });

        return result.data;
      })
      .finally(() => {
        this.context.commit("setSavingParsingRulesInProgress", false);
      });
  }

  @Action
  resyncNetworkIntegration(id: number) {
    this.context.commit("setNetworkIntegrationResyncLoading", true);

    axios
      .post(`/api/third-party/developer/network-integrations/resync/${id}`)
      .then(() => {
        this.context.commit("addNotification", {
          message: {
            key: "appAdmin.networksManagement.notification.resyncSuccess",
          },
        });
      })
      .finally(() => {
        this.context.commit("setNetworkIntegrationResyncLoading", false);
      });
  }

  @Action({ commit: "setAccountsGroups" })
  async loadAccountsGroupsByNetworkIntegrationId(networkIntegrationId: string) {
    this.context.commit("setAccountsGroupsIsLoading", true);

    return axios
      .get(
        `/api/third-party/developer/accounts-groups/network-integration-id/${networkIntegrationId}`
      )
      .then(
        (result: AxiosResponse<Array<AccountGroupResponseModel>>) => result.data
      )
      .finally(() => {
        this.context.commit("setAccountsGroupsIsLoading", false);
      });
  }

  @Action({ commit: "setAccountGroup" })
  async loadAccountGroupById(id: number) {
    this.context.commit("setAccountGroupIsLoading", true);
    return axios
      .get(`/api/third-party/developer/accounts-groups/${id}`)
      .then((result: AxiosResponse<AccountGroupResponseModel>) => result.data)
      .finally(() => {
        this.context.commit("setAccountGroupIsLoading", false);
      });
  }

  @Action({ commit: "updateAccountsGroups" })
  async createAccountGroup(payload: AccountGroupRequestModel) {
    this.context.commit("setSavingAccountGroupInProgress", true);

    return axios
      .post("/api/third-party/developer/accounts-groups", payload)
      .then((result: AxiosResponse<AccountGroupResponseModel>) => result.data)
      .finally(() => {
        this.context.commit("setSavingAccountGroupInProgress", false);
      });
  }

  @Action
  async updateAccountGroup({
    id,
    payload,
    isForList = false,
  }: {
    id: number;
    payload: AccountGroupRequestModel;
    isForList: boolean;
  }) {
    this.context.commit("setSavingAccountGroupInProgress", true);

    return axios
      .patch(`/api/third-party/developer/accounts-groups/${id}`, payload)
      .then((result: AxiosResponse<AccountGroupResponseModel>) => {
        if (!isForList) {
          this.context.commit("updateAccountGroupAfterUpdate", result.data);
        }

        this.context.commit("updateAccountsGroupsAfterUpdate", result.data);
      })
      .finally(() => {
        this.context.commit("setSavingAccountGroupInProgress", false);
      });
  }

  @Action({ commit: "setAccountGroupNameIsUnique" })
  async checkAccountGroupNameUniqueness(payload: {
    accountGroupName: string;
    networkIntegrationId: number;
  }) {
    if (!payload.accountGroupName) {
      return true;
    }

    return axios
      .post(
        "/api/third-party/developer/accounts-groups/unique-account-group-name",
        payload
      )
      .then(
        (
          result: AxiosResponse<{ accountGroupName: string; unique: boolean }>
        ) => result.data.unique
      );
  }

  @Action({ commit: "updateAccountsGroupsAfterDeletion" })
  async deleteAccountGroup(id: number) {
    this.context.commit("setAccountsGroupsIsLoading", true);

    return axios
      .delete(`/api/third-party/developer/accounts-groups/${id}`)
      .then(() => {
        this.context.commit("addNotification", {
          message: {
            key: "appAdmin.networksManagement.notification.deletedSuccess",
          },
        });

        return id;
      })
      .finally(() => {
        this.context.commit("setAccountsGroupsIsLoading", false);
      });
  }

  @Action
  resyncAccountGroup(accountGroupId: number) {
    this.context.commit("setAccountGroupResyncLoading", true);

    axios
      .post(
        `/api/third-party/developer/accounts-groups/resync/${accountGroupId}`
      )
      .then(() => {
        this.context.commit("addNotification", {
          message: {
            key: "appAdmin.networksManagement.notification.resyncSuccess",
          },
        });
      })
      .finally(() => {
        this.context.commit("setAccountGroupResyncLoading", false);
      });
  }

  @Action({ commit: "setAccounts" })
  async loadAccountsByAccountGroupId(accountGroupId: string) {
    this.context.commit("setAccountsLoading", true);

    return axios
      .get(
        `/api/third-party/developer/accounts/account-group-id/${accountGroupId}`
      )
      .then((result: AxiosResponse<Array<AccountResponseModel>>) => result.data)
      .finally(() => {
        this.context.commit("setAccountsLoading", false);
      });
  }

  @Action({ commit: "updateAccounts" })
  async createAccount(payload: AccountRequestModel) {
    this.context.commit("setSavingAccountInProgress", true);

    return axios
      .post("/api/third-party/developer/accounts", payload)
      .then((result: AxiosResponse<AccountResponseModel>) => result.data)
      .finally(() => {
        this.context.commit("setSavingAccountInProgress", false);
      });
  }

  @Action
  async updateAccount({
    id,
    payload,
    isTransfer,
  }: {
    id: number;
    payload: AccountRequestModel;
    isTransfer?: boolean;
  }) {
    this.context.commit("setSavingAccountInProgress", true);

    return axios
      .patch(`/api/third-party/developer/accounts/${id}`, payload)
      .then((result: AxiosResponse<AccountResponseModel>) => {
        if (isTransfer) {
          this.context.commit("updateAccountsAfterDeletion", id);
        } else {
          this.context.commit("updateAccountsAfterUpdate", result.data);
        }
      })
      .finally(() => {
        this.context.commit("setSavingAccountInProgress", false);
      });
  }

  @Action({ commit: "updateAccountsAfterDeletion" })
  async deleteAccount(id: number) {
    this.context.commit("setAccountsLoading", true);

    return axios
      .delete(`/api/third-party/developer/accounts/${id}`)
      .then(() => {
        this.context.commit("addNotification", {
          message: {
            key: "appAdmin.networksManagement.notification.deletedSuccess",
          },
        });

        return id;
      })
      .finally(() => {
        this.context.commit("setAccountsLoading", false);
      });
  }

  @Action({ commit: "setAccountsParams" })
  async loadAccountsParamsByAccountId(accountId: string) {
    this.context.commit("setAccountsParamsLoading", true);

    return axios
      .get(`/api/third-party/developer/accounts-params`, {
        params: {
          accountId,
        },
      })
      .then(
        (result: AxiosResponse<Array<AccountParamResponseModel>>) => result.data
      )
      .finally(() => {
        this.context.commit("setAccountsParamsLoading", false);
      });
  }

  @Action
  async createAccountParam(payload: AccountParamRequestModel) {
    this.context.commit("setSavingAccountParamInProgress", true);

    return axios
      .post("/api/third-party/developer/accounts-params", payload)
      .then((result: AxiosResponse<AccountParamResponseModel>) => {
        this.context.dispatch(
          "loadAccountsParamsByAccountId",
          result.data.accountId
        );
      })
      .finally(() => {
        this.context.commit("setSavingAccountParamInProgress", false);
      });
  }

  @Action
  async updateAccountParam({
    id,
    payload,
  }: {
    id: number;
    payload: AccountParamRequestModel;
  }) {
    this.context.commit("setSavingAccountParamInProgress", true);

    return axios
      .patch(`/api/third-party/developer/accounts-params/${id}`, payload)
      .then((result: AxiosResponse<AccountParamResponseModel>) => {
        this.context.dispatch(
          "loadAccountsParamsByAccountId",
          result.data.accountId
        );
      })
      .finally(() => {
        this.context.commit("setSavingAccountParamInProgress", false);
      });
  }

  @Action({ commit: "updateAccountsParamsAfterDeletion" })
  async deleteAccountParam(id: number) {
    this.context.commit("setAccountsParamsLoading", true);

    return axios
      .delete(`/api/third-party/developer/accounts-params/${id}`)
      .then(() => {
        this.context.commit("addNotification", {
          message: {
            key: "appAdmin.networksManagement.notification.deletedSuccess",
          },
        });

        return id;
      })
      .finally(() => {
        this.context.commit("setAccountsParamsLoading", false);
      });
  }

  @Action({ commit: "setAccountGroupStatistics" })
  async loadAccountGroupStatisticsByAccountGroupId(accountGroupId: number) {
    this.context.commit("setAccountGroupStatisticsLoading", true);

    return axios
      .get(
        `/api/third-party/developer/account-group-statistics/account-group-id/${accountGroupId}`
      )
      .then(
        (result: AxiosResponse<Array<AccountGroupStatisticModel>>) =>
          result.data
      )
      .finally(() => {
        this.context.commit("setAccountGroupStatisticsLoading", false);
      });
  }

  @Action({ commit: "setNetRevenues" })
  async loadNetRevenues(networkIntegrationId: string) {
    this.context.commit("setNetRevenuesLoading", true);

    return axios
      .get(
        `/api/third-party/developer/net-revenue/network-integration-id/${networkIntegrationId}`
      )
      .then(
        (result: AxiosResponse<Array<NetRevenueResponseModel>>) => result.data
      )
      .finally(() => {
        this.context.commit("setNetRevenuesLoading", false);
      });
  }

  @Action({ commit: "setNetRevenues" })
  async createNetRevenues(payload: NetRevenueRequestModel) {
    this.context.commit("setSavingNetRevenuesInProgress", true);

    return axios
      .post(`/api/third-party/developer/net-revenue`, payload)
      .then((result: AxiosResponse<NetRevenueResponseModel>) => result.data)
      .finally(() => {
        this.context.commit("setSavingNetRevenuesInProgress", false);
      });
  }

  @Action({ commit: "setNetRevenues" })
  async updateNetRevenues({
    networkIntegrationId,
    payload,
  }: {
    networkIntegrationId: number;
    payload: NetRevenueRequestModel;
  }) {
    this.context.commit("setSavingNetRevenuesInProgress", true);

    return axios
      .put(
        `/api/third-party/developer/net-revenue/network-integration-id/${networkIntegrationId}`,
        payload
      )
      .then(
        (result: AxiosResponse<Array<NetRevenueResponseModel>>) => result.data
      )
      .finally(() => {
        this.context.commit("setSavingNetRevenuesInProgress", false);
      });
  }

  @Action({ commit: "setNetRevenues" })
  async deleteAllNetRevenues(networkIntegrationId: string) {
    this.context.commit("setNetRevenuesLoading", true);

    return axios
      .delete(
        `/api/third-party/developer/net-revenue/network-integration-id/${networkIntegrationId}`
      )
      .then(() => [])
      .finally(() => {
        this.context.commit("setNetRevenuesLoading", false);
      });
  }

  @Action({ commit: "setHistoricalRevenueNetwork" })
  async loadHistoricalRevenueNetwork() {
    this.context.commit("setHistoricalRevenueNetworkLoading", true);

    return axios
      .get("/api/third-party/developer/historical-revenue-network")
      .then(
        (result: AxiosResponse<Array<HistoricalRevenueNetworkResponseModel>>) =>
          result.data
      )
      .finally(() => {
        this.context.commit("setHistoricalRevenueNetworkLoading", false);
      });
  }

  @Action({ commit: "updateHistoricalRevenueNetworkAfterCreate" })
  async createHistoricalRevenueNetwork(
    payload: HistoricalRevenueNetworkRequestModel
  ) {
    this.context.commit("setSavingHistoricalRevenueNetworkInProgress", true);

    return axios
      .post("/api/third-party/developer/historical-revenue-network", payload)
      .then(
        (result: AxiosResponse<HistoricalRevenueNetworkResponseModel>) =>
          result.data
      )
      .finally(() => {
        this.context.commit(
          "setSavingHistoricalRevenueNetworkInProgress",
          false
        );
      });
  }

  @Action({ commit: "updateHistoricalRevenueNetworkAfterUpdate" })
  async updateHistoricalRevenueNetwork({
    id,
    payload,
  }: {
    id: number;
    payload: HistoricalRevenueNetworkRequestModel;
  }) {
    this.context.commit("setSavingHistoricalRevenueNetworkInProgress", true);

    return axios
      .patch(
        `/api/third-party/developer/historical-revenue-network/${id}`,
        payload
      )
      .then(
        (result: AxiosResponse<HistoricalRevenueNetworkResponseModel>) =>
          result.data
      )
      .finally(() => {
        this.context.commit(
          "setSavingHistoricalRevenueNetworkInProgress",
          false
        );
      });
  }

  @Action({ commit: "updateHistoricalRevenueNetworkAfterDeletion" })
  async deleteHistoricalRevenueNetwork(id: number) {
    this.context.commit("setHistoricalRevenueNetworkLoading", true);

    return axios
      .delete(`/api/third-party/developer/historical-revenue-network/${id}`)
      .then(() => id)
      .finally(() => {
        this.context.commit("setHistoricalRevenueNetworkLoading", false);
      });
  }

  @Action({ commit: "setShortNetworkIntegrations" })
  async loadShortNetworkIntegrations(params: {
    networkType: NetworkType;
    filter: NetworkIntegrationFilterType;
  }) {
    this.context.commit("setChildNetworksLoading", true);

    return axios
      .get(`/api/third-party/developer/network-integrations`, { params })
      .then(
        ({
          data,
        }: AxiosResponse<Array<NetworkIntegrationShortResponseModel>>) => data
      )
      .finally(() => {
        this.context.commit("setChildNetworksLoading", false);
      });
  }

  @Action({ commit: "setChildNetworks" })
  async loadChildNetworks(networkIntegrationId: number) {
    this.context.commit("setChildNetworksLoading", true);

    return axios
      .get(
        `/api/third-party/developer/network-integrations/${networkIntegrationId}/children`
      )
      .then(
        ({
          data,
        }: AxiosResponse<Array<NetworkIntegrationShortResponseModel>>) => data
      )
      .finally(() => {
        this.context.commit("setChildNetworksLoading", false);
      });
  }

  @Action
  async linkChildNetwork({
    payload,
    networkIntegrationId,
  }: {
    payload: { childrenNetworkIntegrationIds: Array<number> };
    networkIntegrationId: number;
  }) {
    this.context.commit("setSavingChildNetworkInProgress", true);

    return axios
      .post(
        `/api/third-party/developer/network-integrations/${networkIntegrationId}/children`,
        payload
      )
      .finally(() => {
        this.context.commit("setSavingChildNetworkInProgress", false);
      });
  }

  @Action
  async deleteChildNetwork({ childNetworkId }: { childNetworkId: number }) {
    this.context.commit("setDeletingChildNetwork", childNetworkId);

    return axios
      .delete(
        `/api/third-party/developer/network-integrations/${childNetworkId}/delete-parent`
      )
      .finally(() => {
        this.context.commit("setDeletingChildNetwork", null);
      });
  }
}
