export default {
  funnel: {
    funnel: "Funnel",
    title: "Funnels",
    search: "Search",
    showOnlyMy: "Only My",
    newTitle: "New funnel",
    detailsTitle: 'Funnel "{0}" details',
    viewTitle: "{0} - Funnel details",
    chartTitle: 'Funnel "{0}" chart',
    exclusions: "Exclusions",
    newStep: "New step",
    copyCurrentStep: "Copy current step",
    tabs: {
      info: "Info",
      filters: "Filters",
      steps: "Steps",
      breakdowns: "Breakdowns",
      chart: "Chart",
    },
    steps: "Steps",
    name: "Name",
    description: "Description",
    eventDates: "Events date",
    breakdowns: "Breakdowns",
    initialFilters: "Initial filters",
    activeFilters: "Active additional filters",
    goToFunnelList: "Go to funnel list",
    goToFunnelDetails: "Go to funnel details",
    goToFunnelView: "Go to funnel's view",
    chartMode: {
      funnel: "Funnel",
      column: "Column",
      table: "Table",
    },
    chartPercentMode: {
      relative: "Relative",
      first_step: "First step",
      total: "Total",
    },
    collapseSteps: "Collapse steps",
    expandSteps: "Expand steps",
    status: {
      title: "Status",
      created: "Created",
      recalculation: "Recalculation",
      requested_for_recalculation: "Requested For Recalculation",
      delete: "Deleted",
      active: "Active",
      failed: "Failed",
    },
    step: {
      number: "Step: {0}",
      name: "Step name",
      filterType: "Filter type",
      description: "Description",
      exclude: "Exclude",
      periodType: {
        title: "Period type",
        second: "Second",
        minute: "Minute",
        hour: "Hour",
        day: "Day",
      },
      periodValue: "Period value",
      actions: {
        delete: "Delete step",
      },
    },
    chart: {
      actions: {
        generate: "Generate",
        loadMore: "Load more",
        addSegment: "Add segment",
        export: "Export to CSV",
      },
      modes: {
        title: "Chart mode",
        funnel: "Funnel",
        column: "Column",
        table: "Table",
      },
      percentModes: {
        title: "Chart percent mode",
        total: "Total",
        relative: "Relative",
        first_step: "First Step",
      },
      loading: "Loading...",
      total: "Total",
      empty: "Sorry, but there is no users for this funnel",
    },
    type: {
      title: "Type",
      user_conversion: "User conversion",
      repeated_conversion: "Repeated conversion",
    },
    owner: "Owner",
    lastRecalculation: "Last recalculation",
    actions: {
      title: "Actions",
      view: {
        hint: "Display funnel details",
        title: "Funnel details",
      },
      chart: {
        hint: "Display funnel chats",
      },
      copy: {
        hint: "Copy funnel",
      },
      recalculate: {
        hint: "Recalculate funnel",
        title: 'Recalculate funnel "{0}"',
        message:
          'Are you sure you want to recalculate funnel with name: "{0}"?',
      },
      delete: {
        hint: "Delete funnel",
        title: 'Delete funnel "{0}"',
        message: 'Are you sure you want to delete funnel with name: "{0}"?',
      },
      createSegment: {
        title: "Create segment",
      },
      saveAsTemplate: "Save as Template",
    },
    segment: {
      name: "Segment name",
      description: "Description",
      descriptionPlaceholder: "If empty, will be replaced by default",
      descriptionPattern: "Funnel: {0}; Breakdown: {1}; Steps: {2}-{3}; {4}",
      stepFrom: "Step from",
      stepTo: "Step to",
      conversion: {
        title: "Conversion",
        converted: "Converted",
        notConverted: "Not converted",
      },
    },
    showDescription: "Show description",
    hideDescription: "Hide description",
    notification: {
      createdSuccess: `The funnel "{0}" was created successfully`,
      updatedSuccess: `The funnel "{0}" was updated successfully`,
    },
    infoSubtitle: "Set up basic settings and privacy rules.",
    stepsSubtitle:
      "Set up steps sequence, time bounds and exclude rules for the funnel.",
    filtersSubtitle:
      "Select filters to limit audience/events for the funnel's calculation.",
    breakdownsSubtitle:
      "Select the fields you want to use for additional filtering/grouping later on the view page.",
  },
};
