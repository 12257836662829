import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import { ChartRenderer } from "@/chart/models/ChartRenderer";
import { SlicedChartOptions } from "@/chart/models/ChartModel";

export default class SlicedChartRenderer extends ChartRenderer {
  chart: am5percent.SlicedChart;
  series?: am5percent.FunnelSeries;

  constructor(
    public element: HTMLElement,
    public data: Array<Record<string, any>>,
    public options: SlicedChartOptions,
    public isDarkMode: boolean,
    public horizontal = true
  ) {
    super(element, isDarkMode);

    this.chart = this.root.container.children.push(
      am5percent.SlicedChart.new(this.root, {
        layout: this.root.verticalLayout,
        ...(options.legend ? { paddingTop: 0 } : {}),
      })
    );
    this.chart.get("colors")?.set("step", 5);
    this.init();
  }

  init() {
    this.addSeries();
    super.init();
  }

  addSeries() {
    this.series = this.chart.series.push(
      am5percent.FunnelSeries.new(this.root, {
        alignLabels: false,
        orientation: this.horizontal ? "horizontal" : "vertical",
        bottomRatio: 1,
        valueField: this.options.series.value,
        categoryField: this.options.series.category,
        ...(this.options.series.legendLabelText
          ? { legendLabelText: this.options.series.legendLabelText }
          : {}),
        ...(this.options.series.legendValueText
          ? { legendValueText: this.options.series.legendValueText }
          : {}),
      })
    );

    this.series.data.setAll(this.data);
    this.series.labels.template.setAll({
      text: this.options.series.labelText,
    });
    this.series.slices.template.set(
      "tooltipText",
      this.options.series.tooltipText
    );
  }

  addLegend(): void {
    if (!this.options.legend || !this.series) {
      return;
    }

    const legend = this.chart.children.unshift(
      am5.Legend.new(this.root, {
        x: am5.percent(50),
        centerX: am5.percent(50),
      })
    );

    legend.data.setAll(this.series.dataItems);
  }
}
