import { AccessType, FilterModel, FilterId } from "@/shared/models";

export class FunnelUniqueNameRequestModel {
  name: string;

  constructor(name: string) {
    this.name = name;
  }
}

export class FunnelUniqueNameResponseModel {
  unique: boolean;

  constructor(unique: boolean) {
    this.unique = unique;
  }
}

export class GetFunnelRequestModel {
  id: number;
  applicationId: string;

  constructor(id: number, applicationId: string) {
    this.id = id;
    this.applicationId = applicationId;
  }
}

export class GetFunnelChartRequestModel {
  applicationId: string;
  id: number;
  breakdowns: Array<FilterId>;
  filter: Array<FilterModel>;

  constructor(
    applicationId: string,
    id: number,
    breakdowns: Array<FilterId> = [],
    filter: Array<FilterModel> = []
  ) {
    this.id = id;
    this.applicationId = applicationId;
    this.breakdowns = breakdowns;
    this.filter = filter;
  }
}

export class UpdateFunnelRequestModel {
  description?: string;
  accessType?: AccessType;

  constructor(description?: string, accessType?: AccessType) {
    this.description = description;
    this.accessType = accessType;
  }
}

export class CreateFunnelSegmentModel {
  applicationId: string;
  funnelId: number;
  filter: Array<FilterModel>;
  stepFrom: number;
  stepTo: number;
  converted: boolean;
  accessType: AccessType;
  name?: string;
  description?: string;

  constructor(
    applicationId: string,
    funnelId: number,
    filter: Array<FilterModel> = [],
    stepFrom = 1,
    stepTo = 1,
    converted = true,
    accessType: AccessType = AccessType.PRIVATE,
    name?: string,
    description?: string
  ) {
    this.applicationId = applicationId;
    this.funnelId = funnelId;
    this.filter = filter;
    this.stepFrom = stepFrom;
    this.stepTo = stepTo;
    this.converted = converted;
    this.accessType = accessType;
    this.name = name;
    this.description = description;
  }
}
