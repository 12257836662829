export default class JsonUtil {
  private static mergeJson(target: any, sources: Array<any>): any {
    if (!sources.length) {
      return target;
    }

    const result = target || {};

    if (JsonUtil.isObject(result)) {
      const len: number = sources.length;

      for (let i = 0; i < len; i += 1) {
        const elm: any = sources[i];

        if (JsonUtil.isObject(elm)) {
          for (const key in elm) {
            if (Object.prototype.hasOwnProperty.call(elm, key)) {
              if (JsonUtil.isObject(elm[key])) {
                if (!result[key] || !JsonUtil.isObject(result[key])) {
                  result[key] = {};
                }
                JsonUtil.mergeJson(result[key], [elm[key]]);
              } else {
                result[key] = elm[key];
              }
            }
          }
        }
      }
    }

    return result;
  }

  private static isObject(item: any): boolean {
    return item === Object(item) && !Array.isArray(item);
  }

  private static _formatting(val: any): string {
    return JSON.stringify(val, null, 2);
  }

  static merge(json: Array<string>): string {
    if (!json || json.length === 0) {
      return "";
    }

    const jsonVal = json.map((it) => JSON.parse(it));
    return JsonUtil._formatting(JsonUtil.mergeJson({}, jsonVal));
  }

  static formatting(val: string): string {
    try {
      return JsonUtil._formatting(JSON.parse(val));
    } catch (e) {
      return val;
    }
  }

  static formattingToString(val: any): string {
    return JSON.stringify(val, null, 2);
  }

  static formattingFromJson(val: string): any {
    try {
      return JSON.parse(val);
    } catch (e) {
      return val;
    }
  }
}
