import {
  BaseGradientFlags,
  BaseReportFilter,
  ReportFilter,
  ReportResultItem,
} from "./Report";
import { AggregationPeriod, GroupByFilter } from "./GroupByFilter";
import { ReportHeader, ReportHeaderUtil } from "./ReportHeader";
import { ReportType } from "./ReportType";
import {
  DatesFilterModel,
  FilterId,
  FilterPreview,
  FilterPreviewId,
  FilterModel,
  DefaultValues,
  Application,
} from "@/shared/models";
import { LangService } from "@/shared/types/LangType";
import { ReportDataType } from "./ReportVisualization";
import { ChartName } from "@/chart/models/ChartModel";
import { ReportItemRowObject } from "./ReportItemRowObject";

export class CohortGradientFlags extends BaseGradientFlags {
  constructor() {
    super();

    this.flags = {
      gradient: true,
    };
  }

  getByKey(): boolean {
    return super.getByKey("gradient");
  }
}

export abstract class CohortFilter extends BaseReportFilter {
  dayLimit: number;

  protected constructor(
    reportId: ReportType,
    app: Application,
    filter?: Array<FilterModel>,
    date: DatesFilterModel = DefaultValues.initDatesFilterModel(
      FilterId.ATTRIBUTION_DATE_VALUE
    ),
    groupByFilter: GroupByFilter = new GroupByFilter(AggregationPeriod.DAY),
    dayLimit: number = DefaultValues.DAYS_LIMIT
  ) {
    super(reportId, app, filter, date, groupByFilter);
    this.dayLimit = dayLimit;
  }

  get previews(): Array<FilterPreview> {
    return [
      ...super.previews,
      {
        id: FilterPreviewId.DAY_LIMIT,
        value: this.dayLimit,
      },
    ];
  }

  get invalid(): boolean {
    return (
      !this.app ||
      !this.date.valid ||
      this.dayLimit < 1 ||
      this.dayLimit > DefaultValues.MAX_DAYS_LIMIT
    );
  }

  toRequestQuery(): Record<string, any> {
    const result = super.toRequestQuery();
    result["dayLimit"] = this.dayLimit;
    return result;
  }

  protected getGroupedCohortChartNames(
    prefix: string,
    days: Array<number>
  ): Array<ChartName> {
    const result: Array<ChartName> = [];

    days.every((day) => {
      if (day < this.dayLimit) {
        result.push(
          ChartName[`${prefix}_DAY_${day}_CHART` as keyof typeof ChartName]
        );

        return true;
      }

      return false;
    });

    return result;
  }
}

export class CohortHeaders {
  static readonly PRECISION = 2;
  static readonly PRECISION_FOR_ARPU = 5;

  static createInstalls(lang: LangService): ReportHeader {
    return {
      text: lang("components.baseTable.installs"),
      align: "end",
      value: ReportResultItem.PREFIX + "installs",
      fractionDigits: 0,
      width: 75,
      class: "px-1",
    };
  }

  static createDay(
    lang: LangService,
    dayLimit: number,
    result: Array<ReportHeader>,
    hasGradient: boolean,
    fractionDigits: number
  ) {
    const days =
      dayLimit > DefaultValues.MAX_DAYS_LIMIT
        ? DefaultValues.MAX_DAYS_LIMIT
        : dayLimit;

    for (let i = 0; i < days; i++) {
      result.push({
        text: lang("components.baseTable.day", i < 10 && i !== 0 ? `0${i}` : i),
        align: "end",
        value: `${ReportResultItem.PREFIX}${i}`,
        fractionDigits: fractionDigits,
        width: 70,
        class: "px-1",
        hasGradient,
      });
    }
  }

  static init(
    lang: LangService,
    report: CohortFilter,
    reportDataType: ReportDataType,
    fractionDigits: number = this.PRECISION
  ): Array<ReportHeader> {
    const result = [
      ...(![ReportDataType.TOTAL, ReportDataType.SUB_TOTAL].includes(
        reportDataType
      )
        ? [ReportHeaderUtil.createDate(lang)]
        : []),
      ...(report.groupByFilter.isNotEmptyGroupBy &&
      reportDataType !== ReportDataType.TOTAL
        ? ReportHeaderUtil.createGroupBy(lang, report.groupByFilter)
        : []),
      this.createInstalls(lang),
    ];

    this.createDay(lang, report.dayLimit, result, true, fractionDigits);

    return result;
  }
}

export class CohortResultItem extends ReportResultItem {
  constructor(
    row: ReportItemRowObject,
    filter: ReportFilter,
    precision: number
  ) {
    super();
    const { groupByFilter, dayLimit } = filter as CohortFilter;
    if (row.getByHeader("installDate")) {
      this.date = row.getByHeader("installDate");
      this.setFormattedDate(
        this.date,
        filter.date.from,
        filter.date.to,
        groupByFilter.aggregationPeriod
      );
    }
    this.setGroupByValue(groupByFilter, row);

    this.data["installs"] = row.getByHeader("installs") ?? 0;
    for (let i = 0; i < dayLimit; i++) {
      this.data[i.toString()] = super.parseFloat(
        row.getByHeader(`_${i.toString()}`),
        precision
      );
    }
  }

  get installs(): number {
    return this.data["installs"];
  }
  set installs(val: number) {
    this.data["installs"] = val;
  }
}
