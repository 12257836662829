import {
  BaseGradientFlags,
  BaseReportFilter,
  ReportFilter,
  ReportResultItem,
} from "./Report";
import { AggregationPeriod, GroupByFilter } from "./GroupByFilter";
import { ReportHeader, ReportHeaderUtil } from "./ReportHeader";
import { ReportType } from "./ReportType";
import {
  DatesFilterModel,
  FilterId,
  recordToFilterModel,
  FilterModel,
  Application,
} from "@/shared/models";
import { LangService } from "@/shared/types/LangType";
import { ReportDataType } from "./ReportVisualization";
import { ChartName } from "@/chart/models/ChartModel";
import { ReportItemRowObject } from "./ReportItemRowObject";

export class CtrCalendarGradientFlags extends BaseGradientFlags {
  constructor() {
    super();

    this.flags = {
      [`${ReportResultItem.PREFIX}bannerClicks`]: true,
      [`${ReportResultItem.PREFIX}bannerCtr`]: true,
      [`${ReportResultItem.PREFIX}interstitialClicks`]: true,
      [`${ReportResultItem.PREFIX}interstitialCtr`]: true,
      [`${ReportResultItem.PREFIX}rewardedClicks`]: true,
      [`${ReportResultItem.PREFIX}rewardedCtr`]: true,
    };
  }
}

export class CtrCalendarFilter extends BaseReportFilter {
  constructor(
    app: Application,
    filter?: Array<FilterModel>,
    date?: DatesFilterModel,
    groupByFilter = new GroupByFilter(AggregationPeriod.DAY, [], false)
  ) {
    super(ReportType.CALENDAR_CTR, app, filter, date, groupByFilter);
  }

  get invalid(): boolean {
    return !(this.app && this.date.valid);
  }

  get charts(): Array<ChartName> {
    return this.groupByFilter.isNotEmptyGroupBy
      ? [
          ChartName.CALENDAR_CTR_BANNER_CHART,
          ChartName.CALENDAR_CTR_INTERSTITIAL_CHART,
          ChartName.CALENDAR_CTR_REWARDED_CHART,
        ]
      : [ChartName.CALENDAR_CTR_CHART];
  }

  get groupedCharts(): Array<ChartName> {
    return this.groupByFilter.isNotEmptyGroupBy
      ? [ChartName.CALENDAR_CTR_CHART]
      : [];
  }

  static of(
    app: Application,
    requestQuery?: Record<string, any>
  ): CtrCalendarFilter {
    return new CtrCalendarFilter(
      app,
      requestQuery?.filter?.map((it: any) => recordToFilterModel(it)) || [],
      DatesFilterModel.ofRecord(FilterId.EVENTS_DATE, requestQuery?.date),
      new GroupByFilter(
        AggregationPeriod.DAY,
        requestQuery?.groupByFilter?.groupBy,
        false
      )
    );
  }
}

export class CtrCalendarHeaders {
  static init(
    lang: LangService,
    report: CtrCalendarFilter,
    reportDataType: ReportDataType
  ): Array<ReportHeader> {
    return [
      ...(![ReportDataType.TOTAL, ReportDataType.SUB_TOTAL].includes(
        reportDataType
      )
        ? [
            ReportHeaderUtil.createDate(
              lang,
              report.groupByFilter.aggregationPeriod
            ),
          ]
        : []),
      ...(report.groupByFilter.isNotEmptyGroupBy &&
      reportDataType !== ReportDataType.TOTAL
        ? ReportHeaderUtil.createGroupBy(lang, report.groupByFilter)
        : []),
      {
        text: lang("components.baseTable.bannerClicks"),
        align: "end",
        value: `${ReportResultItem.PREFIX}bannerClicks`,
        hasGradient: true,
      },
      {
        text: lang("components.baseTable.bannerCtr"),
        align: "end",
        value: `${ReportResultItem.PREFIX}bannerCtr`,
        hasGradient: true,
        fractionDigits: 2,
      },
      {
        text: lang("components.baseTable.interstitialClicks"),
        align: "end",
        value: `${ReportResultItem.PREFIX}interstitialClicks`,
        hasGradient: true,
      },
      {
        text: lang("components.baseTable.interstitialCtr"),
        align: "end",
        value: `${ReportResultItem.PREFIX}interstitialCtr`,
        hasGradient: true,
        fractionDigits: 2,
      },
      {
        text: lang("components.baseTable.rewardedClicks"),
        align: "end",
        value: `${ReportResultItem.PREFIX}rewardedClicks`,
        hasGradient: true,
      },
      {
        text: lang("components.baseTable.rewardedCtr"),
        align: "end",
        value: `${ReportResultItem.PREFIX}rewardedCtr`,
        hasGradient: true,
        fractionDigits: 2,
      },
    ];
  }
}

export class CtrCalendarResultItem extends ReportResultItem {
  constructor(row: ReportItemRowObject, filter: ReportFilter) {
    const { groupByFilter } = filter;

    super();
    this.date = row.getByHeader("date");
    this.setFormattedDate(
      this.date,
      filter.date.from,
      filter.date.to,
      groupByFilter.aggregationPeriod
    );
    this.setGroupByValue(groupByFilter, row);
    this.data["bannerClicks"] = row.getByHeader("bannerClicks");
    this.data["bannerCtr"] = row.getByHeader("bannerCtr");
    this.data["interstitialClicks"] = row.getByHeader("interstitialClicks");
    this.data["interstitialCtr"] = row.getByHeader("interstitialCtr");
    this.data["rewardedClicks"] = row.getByHeader("rewardedClicks");
    this.data["rewardedCtr"] = row.getByHeader("rewardedCtr");
  }
}
