import {
  BaseGradientFlags,
  MultiAppsReportFilter,
  ReportResultItem,
  ReportHeader,
  ReportHeaderUtil,
  ReportFilter,
  NetRevenueReportFilterExtension,
  ReportItemRowObject,
} from "@/reports/models";
import { ReportType } from "@/reports/models/ReportType";
import { AggregationPeriod, GroupByFilter } from "./GroupByFilter";
import {
  DatesFilterModel,
  FilterId,
  recordToFilterModel,
  FilterModel,
  Application,
  DefaultValues,
  FilterPreviewId,
} from "@/shared/models";
import { LangService } from "@/shared/types/LangType";
import { ChartName } from "@/chart/models/ChartModel";

export class ProfitGradientFlags extends BaseGradientFlags {
  constructor(report: ReportFilter) {
    super();

    const dau = ReportHeaderUtil.getActiveUsersName(
      report.groupByFilter.aggregationPeriod
    );

    this.flags = {
      [`${ReportResultItem.PREFIX}spend`]: true,
      [`${ReportResultItem.PREFIX}revenue`]: true,
      [`${ReportResultItem.PREFIX}profit`]: true,
      [`${ReportResultItem.PREFIX}${dau}`]: true,
      [`${ReportResultItem.PREFIX}installs`]: true,
    };
  }

  getByKey(key: string | number): boolean {
    const prefix = `${ReportResultItem.PREFIX}`;
    if (key === `${prefix}dau`) {
      return (
        this.flags[`${prefix}dau`] ||
        this.flags[`${prefix}wau`] ||
        this.flags[`${prefix}mau`]
      );
    }
    return this.flags[key];
  }
}

export class ProfitFilter
  extends MultiAppsReportFilter
  implements NetRevenueReportFilterExtension
{
  constructor(
    availableApps: Array<Application>,
    platforms?: Array<string>,
    apps?: Array<string>,
    includedApps?: boolean,
    filter?: Array<FilterModel>,
    date: DatesFilterModel = DefaultValues.initDatesFilterModel(
      FilterId.EVENTS_DATE,
      90
    ),
    groupByFilter: GroupByFilter = new GroupByFilter(AggregationPeriod.DAY, []),
    public netRevenue = true
  ) {
    super(
      ReportType.PROFIT,
      availableApps,
      platforms,
      apps,
      includedApps,
      filter,
      date,
      groupByFilter
    );
  }

  get previews() {
    return [
      ...super.previews,
      {
        id: FilterPreviewId.NET_REVENUE,
        value: this.netRevenue,
      },
    ];
  }

  get charts(): Array<ChartName> {
    return [ChartName.PROFIT_CHART];
  }

  static of(
    availableApps: Array<Application>,
    requestQuery?: Record<string, any>
  ): ProfitFilter {
    return new ProfitFilter(
      availableApps,
      requestQuery?.platforms as Array<string>,
      requestQuery?.apps as Array<string>,
      typeof requestQuery?.includedApps === "string"
        ? requestQuery?.includedApps === "true"
        : requestQuery?.includedApps,
      requestQuery?.filter?.map((it: any) => recordToFilterModel(it)) || [],
      DatesFilterModel.ofRecord(FilterId.EVENTS_DATE, requestQuery?.date),
      GroupByFilter.of(requestQuery?.groupByFilter)
      // TODO: NET_REVENUE need to uncomment later
      // typeof requestQuery?.netRevenue === "string"
      //   ? requestQuery?.netRevenue === "true"
      //   : requestQuery?.netRevenue,
    );
  }

  toRequestQuery(): Record<string, any> {
    return {
      ...super.toRequestQuery(),
      // TODO: NET_REVENUE need to uncomment later
      // netRevenue: this.netRevenue,
    };
  }
}

export class ProfitHeaders {
  static readonly PRECISION = 0;
  static init(lang: LangService, report: ProfitFilter): Array<ReportHeader> {
    return [
      ReportHeaderUtil.createDate(lang),
      {
        text: lang("components.baseTable.spend"),
        align: "end",
        value: `${ReportResultItem.PREFIX}spend`,
        hasGradient: true,
        fractionDigits: ProfitHeaders.PRECISION,
        class: "text-no-wrap",
      },
      {
        text: lang("components.baseTable.revenue"),
        align: "end",
        value: `${ReportResultItem.PREFIX}revenue`,
        hasGradient: true,
        fractionDigits: ProfitHeaders.PRECISION,
        class: "text-no-wrap",
      },
      {
        text: lang("components.baseTable.profit"),
        align: "end",
        value: `${ReportResultItem.PREFIX}profit`,
        hasGradient: true,
        fractionDigits: ProfitHeaders.PRECISION,
        class: "text-no-wrap",
      },
      {
        text: lang(
          `components.baseTable.${ReportHeaderUtil.getActiveUsersName(
            report.groupByFilter.aggregationPeriod
          )}`
        ),
        align: "end",
        value: `${ReportResultItem.PREFIX}dau`,
        hasGradient: true,
        fractionDigits: ProfitHeaders.PRECISION,
        class: "text-no-wrap",
      },
      {
        text: lang("components.baseTable.installs"),
        align: "end",
        value: `${ReportResultItem.PREFIX}installs`,
        hasGradient: true,
        fractionDigits: ProfitHeaders.PRECISION,
        class: "text-no-wrap",
      },
    ];
  }
}

export class ProfitResultItem extends ReportResultItem {
  constructor(row: ReportItemRowObject, filter: ReportFilter) {
    super();
    this.date = row.getByHeader("date");
    this.setFormattedDate(
      this.date,
      filter.date.from,
      filter.date.to,
      filter.groupByFilter.aggregationPeriod
    );
    this.data["spend"] =
      super.parseFloat(row.getByHeader("spend"), ProfitHeaders.PRECISION) ?? 0;
    this.data["revenue"] = super.parseFloat(
      row.getByHeader("revenue"),
      ProfitHeaders.PRECISION
    );
    this.data["profit"] = super.parseFloat(
      row.getByHeader("profit"),
      ProfitHeaders.PRECISION
    );
    this.data["dau"] = super.parseFloat(
      row.getByHeader("dau"),
      ProfitHeaders.PRECISION
    );
    this.data["installs"] = Math.round(
      Number.parseFloat(row.getByHeader("installs"))
    );
  }
}
