export default {
  tools: {
    exportEvents: {
      title: "Export user's flow",
      exportEvents: "Export events",
      dateRange: "Date range",
      saveIds: "Save Ids",
    },
    monetizationMonitoring: {
      title: "Monetization monitoring",
    },
    healthcheckAlerts: {
      title: {
        marketing: "Marketing alerts",
        monetization: "Monetization alerts",
      },
      faq: "Click here to open {0} FAQ",
      upload: {
        title: "{0} settings upload",
        fileInputText: "Alerts CSV",
      },
      uploadDialog: {
        title: "Upload new alerts",
        message: "Do you want to upload new {0} settings file?",
      },
      uploadFileStatisticsDialog: {
        title: "File upload statistics",
        originalLines: "File lines: ",
        processedLines: "Processed lines: ",
        notProcessedLines: "Not processed lines: ",
      },
      table: {
        title: "Current {0} settings",
        noData: "{0} settings are empty",
      },
      tableColumns: {
        applicationId: "Application id",
        metricId: "Metric",
        country: "Country",
        adType: "Ad type",
        absoluteDiff: "Absolute difference",
        percentDiff: "Percent difference",
        operator: "operator",
        observedPeriod: "Obs date",
        basePeriod: "Base date",
      },
    },
  },
};
