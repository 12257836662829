export enum AlertType {
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
  SUCCESS = "success",
}

export enum AlertBorder {
  LEFT = "left",
  RIGHT = "right",
  TOP = "top",
  BOTTOM = "bottom",
  NONE = "none",
}

export enum AlertFeature {
  REPORTS = "REPORTS",
  DASHBOARDS = "DASHBOARDS",
  FUNNELS = "FUNNELS",
  SEGMENTS = "SEGMENTS",
  AB_TESTS = "AB_TESTS",
}

export enum AlertDisplayTime {
  CONSTANT = "CONSTANT",
  DATETIME_LIMITED = "DATETIME_LIMITED",
  FEATURE_TIME_LIMITED = "FEATURE_TIME_LIMITED",
}

export default class AlertResponseModel {
  constructor(
    public id: number,
    public text: string,
    public feature: AlertFeature,
    public subFeatures: Array<string> = [],
    public displayTime: AlertDisplayTime,
    public displayDates: Array<string>,
    public enabled: boolean,
    public isDisplayableOnChart: boolean,
    public title: string,
    public details: DetailsModel,
    public applications: Array<string> = [],
    public visible = true
  ) {}

  static ofSource(model: AlertResponseModel): AlertResponseModel {
    return new AlertResponseModel(
      model.id,
      model.text,
      model.feature,
      model.subFeatures ? [...model.subFeatures] : [],
      model.displayTime,
      model.displayDates,
      model.enabled,
      model.isDisplayableOnChart,
      model.title,
      model.details,
      model.applications,
      model.visible
    );
  }

  static ofArray(items: Array<AlertResponseModel>) {
    return items.map(
      (item) =>
        new AlertResponseModel(
          item.id,
          item.text,
          item.feature,
          item.subFeatures ? [...item.subFeatures] : [],
          item.displayTime,
          item.displayDates,
          item.enabled,
          item.isDisplayableOnChart,
          item.title,
          item.details,
          item.applications,
          item.visible
        )
    );
  }
}

export class DetailsModel {
  constructor(public type: AlertType, public border: AlertBorder) {}
}

export class AlertFilterModel {
  constructor(
    public applications: string,
    public feature = AlertFeature.REPORTS,
    public subFeature?: string,
    public from?: Date | string,
    public to?: Date | string
  ) {}
}
