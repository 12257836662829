import { AxiosResponse } from "axios";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import axios, { AxiosSkipErrorsConfig } from "@/shared/plugins/axios";

import BankResponseModel from "@/accounting-portal/models/banks/BankResponseModel";
import BankShortResponseModel from "@/accounting-portal/models/banks/BankShortResponseModel";
import BankRequestModel from "@/accounting-portal/models/banks/BankRequestModel";

@Module
export default class BankStore extends VuexModule {
  banks: Array<BankResponseModel> = [];
  bank: BankResponseModel | null = null;
  shortBanks: Array<BankShortResponseModel> = [];
  isBanksLoading = false;
  isBankLoading = false;
  isBankSaving = false;

  @Mutation
  setIsBanksLoading(payload: boolean) {
    this.isBanksLoading = payload;
  }

  @Mutation
  setIsBankLoading(payload: boolean) {
    this.isBankLoading = payload;
  }

  @Mutation
  setIsBankSaving(payload: boolean) {
    this.isBankSaving = payload;
  }

  @Mutation
  setBanks(payload: Array<BankResponseModel>) {
    this.banks = BankResponseModel.ofArray(payload);
  }

  @Mutation
  setBank(payload: BankResponseModel) {
    this.bank = BankResponseModel.of(payload);
  }

  @Mutation
  setShortBanks(payload: Array<BankShortResponseModel>) {
    this.shortBanks = BankShortResponseModel.ofArray(payload);
  }

  @Mutation
  setBanksAfterCreate(payload: BankResponseModel) {
    this.banks.push(BankResponseModel.of(payload));
  }

  @Mutation
  setBanksAfterUpdate(payload: BankResponseModel) {
    if (this.bank) {
      this.bank = BankResponseModel.of(payload);
    }

    const indexOfUpdatedEntity = this.banks.findIndex(
      ({ id }) => id === payload.id
    );
    this.banks.splice(indexOfUpdatedEntity, 1, BankResponseModel.of(payload));
  }

  @Mutation
  setBanksAfterDelete(id: number) {
    this.banks = this.banks.filter((item) => item.id !== id);
  }

  @Mutation
  clearBank() {
    this.bank = null;
  }

  @Action({ commit: "setBanks" })
  async loadBanks() {
    this.context.commit("setIsBanksLoading", true);

    return axios
      .get(`/api/accounting/banks`)
      .then(
        (response: AxiosResponse<Array<BankResponseModel>>) => response.data
      )
      .finally(() => this.context.commit("setIsBanksLoading", false));
  }

  @Action({ commit: "setBank" })
  async loadBank(id: string) {
    this.context.commit("setIsBankLoading", true);

    return axios
      .get(`/api/accounting/banks/${id}`)
      .then((response: AxiosResponse<BankResponseModel>) => response.data)
      .finally(() => this.context.commit("setIsBankLoading", false));
  }

  @Action({ commit: "setShortBanks" })
  async loadShortBanks() {
    this.context.commit("setIsBanksLoading", true);

    return axios
      .get(`/api/accounting/banks/short`)
      .then(
        (response: AxiosResponse<Array<BankShortResponseModel>>) =>
          response.data
      )
      .finally(() => this.context.commit("setIsBanksLoading", false));
  }

  @Action({ commit: "setBanksAfterCreate" })
  async createBank(payload: BankRequestModel) {
    this.context.commit("setIsBankSaving", true);

    return axios
      .post(`/api/accounting/banks`, payload)
      .then((result: AxiosResponse<BankResponseModel>) => {
        this.context.commit("addNotification", {
          message: {
            key: "accountingPortal.banks.notification.createdSuccess",
            params: [payload.name],
          },
        });

        return result.data;
      })
      .finally(() => this.context.commit("setIsBankSaving", false));
  }

  @Action({ commit: "setBanksAfterUpdate" })
  async updateBank({ payload, id }: { payload: BankRequestModel; id: number }) {
    this.context.commit("setIsBankSaving", true);

    return axios
      .put(`/api/accounting/banks/${id}`, payload)
      .then((result: AxiosResponse<BankResponseModel>) => {
        this.context.commit("addNotification", {
          message: {
            key: "accountingPortal.banks.notification.updatedSuccess",
            params: [result.data.name],
          },
        });

        return result.data;
      })
      .finally(() => this.context.commit("setIsBankSaving", false));
  }

  @Action({ commit: "setBanksAfterDelete" })
  async deleteBank(id: number) {
    return axios.delete(`/api/accounting/banks/${id}`).then(() => {
      this.context.commit("addNotification", {
        message: {
          key: "accountingPortal.banks.notification.deletedSuccess",
        },
      });

      return id;
    });
  }

  @Action
  async validateBank(payload: BankRequestModel & { id: number | undefined }) {
    this.context.commit("setIsBankSaving", true);

    return axios
      .post("/api/accounting/banks/validate", payload, {
        isValidation: true,
      } as AxiosSkipErrorsConfig)
      .finally(() => this.context.commit("setIsBankSaving", false));
  }
}
